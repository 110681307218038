import { busI } from "./busI";
import { callI } from "./callI";
import { langI } from "./langI";
import { networkI } from "./networkI";
import { streamI } from "./streamI";
import { transcriptionI } from "./transcriptionI";
import { userI } from "./userI";

let ParticipantI = function () {
    let service = {};
    let _inner = {};
    _inner.participantStreamKeysToKeep = ['is_paused', 'is_muted', 'currentVolume', 'is_speaker_muted', 'is_raisehand'];
    _inner.INTERNAL_SERVICE_PARTICIPANT_NAME = "internal_service";
    _inner.isFoundProfilesActive = false;
    _inner.participants = {};
    _inner.ui = {};
    service.getUIBind = ()=> _inner.ui;
    _inner.notifyUI = ()=>{
    }
    _inner.updateParticipant = function(participant) {
        if(!participant)
            return;

        participant.is_host = service.isParticipantHost(participant)? true: false;
        participant.avatar_url = participant.avatar_url? participant.avatar_url: userI.getDefaultAvatar();
        participant.name = participant.name? participant.name: '';

        if(participant.is_active)
            userI.setFriendInCall(participant.account_profile_id);
        participant.isOnCall = participant.is_active&&!participant.disconnected? true: false;

        participant.is_paused = +participant.is_paused===1? true: false;
        participant.is_muted = +participant.is_paused===1? true: false;
        participant.is_speaker_muted = +participant.is_paused===1? true: false;

        if(_inner.participants[participant.mre_call_participant_id]) {
            for(let k in _inner.participants[participant.mre_call_participant_id]) {
                if(!(k in participant) || _inner.participantStreamKeysToKeep.indexOf(k)>-1) {
                    participant[k] = _inner.participants[participant.mre_call_participant_id][k];
                }
            }
            for(let k in participant)
                _inner.participants[participant.mre_call_participant_id][k] = participant[k];
        } else {
            _inner.participants[participant.mre_call_participant_id] = participant;
        }
    }

    service.isParticipantHost = function(participant) {
        if(!participant)
            return;
        return participant.role_id === userI.ROLE_HOST_PARTICIPANT? true: false;
    }

    service.updateParticipants = function(participants) {
        if(!participants || !Array.isArray(participants))
            return;
        let notify = false;
        for (let i = 0; i < participants.length; i++) {
            let old = _inner.participants[participants[i].mre_call_participant_id];
            if(old && +old.updated_at >= +participants[i].updated_at) {
                continue;
            }
            if(participants[i].mre_call_id === callI.getActiveCallId()
            || participants[i].mre_call_participant_id === callI.getActiveParticipantId()) {
                if(old && old.avatar_url !== participants[i].avatar_url) {
                    busI.notifyEvent(busI.EVENTS.ON_CHANGE_TMP_AVATAR,{
                        id: participants[i].mre_call_participant_id,
                        url: participants[i].avatar_url,
                    })
                }
                service.decorateParticipantLanguage(participants[i]);
            }
            notify = true;
            _inner.updateParticipant(participants[i]);
        }
        _inner.notifyUI();
        if(notify) {
            clearTimeout(_inner.notifyUpdateParticipantsTimer);
            _inner.notifyUpdateParticipantsTimer = setTimeout(()=>{
                busI.notifyEvent(busI.EVENTS.UPDATE_PARTICIPANTS);
            }, 80);
        }
    }
    service.decorateParticipantLanguage = function(participant) {
        if(!participant || !participant.mre_call_participant_id | !participant.language_id)
            return;
        let language = langI.getUserLanguageById(participant.language_id);
        participant.languageName = (language)? language.name: '';
        participant.languageIcon = (language)? language.icon_url: '';
        participant.languageCode = langI.getUserLanguageCodeById(participant.language_id);
        participant.canShowLanguageCode = true;
        busI.notifyEvent(busI.EVENTS.ON_PARTICIPANT_LANGUAGE_UPDATED, participant.mre_call_participant_id);
    }
    service.getCurrentUserLanguage = function(obj) {
        let participant = null;
        if(!obj)
            obj = callI.getActiveParticipantId();
        if(obj && obj.mre_call_participant_id)
            participant = obj;
        else if(typeof obj === 'string')
            participant = service.getParticipantById(obj);
        let language_id = participant && participant.language_id? participant.language_id: langI.getUserSelectedLanguageId();
        return langI.getUserLanguageById(language_id);
    }
    service.getCurrentUserLanguageCode = function(obj) {
        let lang = service.getCurrentUserLanguage(obj);
        return lang && lang.code? lang.code: langI.getUserSelectedLanguageCode();
    }
    service.getCurrentUserLanguageName = function(obj) {
        let lang = service.getCurrentUserLanguage(obj);
        return lang && lang.name? lang.name: langI.getUserSelectedLanguageName();
    }
    service.getCurrentUserLanguageId = function(obj) {
        let lang = service.getCurrentUserLanguage(obj);
        return lang && lang.language_id? lang.language_id: langI.getUserSelectedLanguageId();
    }
    service.getActiveParticipantById = function(id) {
        if(!id || !id)
            return;
        return _inner.participants[id]&&_inner.participants[id].isOnCall? _inner.participants[id]: null;
    }
    service.getParticipantNameById = function(id) {
        if(!id || !id)
            return;
        let participant = service.getParticipantById(id);
        return participant && participant.name? participant.name: '';
    }
    service.getParticipantById = function(id) {
        if(!id)
            return;
        return _inner.participants[id]? _inner.participants[id]: null;
    }
    service.getParticipantByIdWithoutHyphen = function(id) {
        if(!id || !_inner.participants)
            return;
        for(let k in _inner.participants) {
            if(k.split('-').join('')===id)
                return _inner.participants[k];
        }
        return null;
    }
    service.getParticipantByAccountProfileAndCallId = function(profileId, callId) {
        if(!profileId || !callId)
            return;
        for(let p in _inner.participants) {
            if(_inner.participants[p].account_profile_id === profileId && _inner.participants[p].mre_call_id===callId)
                return _inner.participants[p];
        }
        return null;
    }
    service.getParticipantIdByAccountProfileAndCallId = function(profileId, callId) {
        let p = service.getParticipantByAccountProfileAndCallId(profileId, callId);
        return p && p.mre_call_participant_id? p.mre_call_participant_id: null;
    }
    service.extractParticipantByAccountProfileId = function(id, arr = null) {
        if(!id || !Array.isArray(arr))
            return null;
        for(let i=0; i<arr.length; i++) {
            if(arr[i].account_profile_id === id) {
                let p = service.getParticipantById(arr[i].mre_call_participant_id);
                return p? p: arr[i];
            }
        }
        return null;
    }
    service.getActiveCallParticipants = function() {
        let filtered = [];
        for(let p in _inner.participants)
            if(_inner.participants[p].isOnCall)
                filtered.push(_inner.participants[p]);
        return filtered;
    }
    service.getParticipantsByCallId = function(id) {
        let filtered = [];
        for(let p in _inner.participants)
            if(_inner.participants[p].mre_call_id === id)
                filtered.push(_inner.participants[p]);
        return filtered;
    }
    service.getActiveCallParticipantsByCallId = function(id) {
        if(!id)
            id = callI.getActiveCallId();
        if(!id)
            return [];
        let filtered = [];
        if(_inner.participants)
            for(let p in _inner.participants) {
                let item = _inner.participants[p];
                if(!item || item.mre_call_id!==id)
                    continue;
                if(!item.is_in_waiting_room && item.isOnCall && !item.is_internal && item.name !== service.getInternalParticipantName()) {
                    filtered.push(_inner.participants[p]);
                }
            }
        return filtered;
    }
    service.notifyChangeLanguage = function() {
        if(callI.getActiveCallId()) {
            let participants = service.getParticipantsByCallId(callI.getActiveCallId());
            if(Array.isArray(participants) && participants.length>0) {
                for(let i=0; i<participants.length; i++) {
                    service.decorateParticipantLanguage(participants[i]);
                }
            }
            _inner.notifyUI();
        }
    }
    service.isInternalParticipant = function(p) {
        if(!p)
            return;
        return +p.is_internal===1? true: false;
    }
    service.getParticipants = function() {
        let filtered = [];
        for(let p in _inner.participants)
            filtered.push(_inner.participants[p]);
        return filtered;
    }
    service.updateParticipantHost = function(participant, isHost) {
        if(!participant || !_inner.participants[participant.mre_call_participant_id])
            return;
        _inner.participants[participant.mre_call_participant_id].is_host = +isHost===1? true: false;
        busI.notifyEvent(busI.EVENTS.UPDATE_PARTICIPANTS);
    }
    service.getParticipantAvatar = function(id) {
        return _inner.participants[id]&&_inner.participants[id].avatar_url? _inner.participants[id].avatar_url: userI.getDefaultAvatar();
    }

    service.clear = function() {
        _inner.participants = {};
        busI.notifyEvent(busI.EVENTS.UPDATE_PARTICIPANTS);
    }
    service.decorateParticipant = function(participant) {
        if(!participant)
            return;
        let decoratedParticipant = {
            "account_profile_id": participant.account_profile_id? participant.account_profile_id: null,
            "screen_name":participant.screen_name ? participant.screen_name : participant.name,
            "first_name":participant.first_name,
            "last_name":participant.last_name,
            "avatar_url":participant.avatar_url? participant.avatar_url: userI.getDefaultAvatar(),
            "email_address":participant.email_address,
            "phone_number":participant.phone_number,
            "private_mode_vendor_id":participant.private_mode_vendor_id,
            "is_pending":participant.is_pending,
            "is_approved":participant.is_approved,
            "is_active":participant.is_active,
            "mre_call_provider_id":participant.mre_call_provider_id,
            "mre_call_participant_id":participant.mre_call_participant_id,
            "is_online":participant.is_online,
            "created_on":participant.created_on,
            "billing_account_id":participant.billing_account_id,
            "is_registered": participant.is_registered || participant.account_profile_id? true: false
        }
        return decoratedParticipant;
    }
    service.setFoundProfilesActive = function(val) {
        _inner.isFoundProfilesActive = +val===1? true: false;
    }
    service.isFoundProfilesActive = function() {
        return +_inner.isFoundProfilesActive===1? true: false;
    }
    service.getInternalParticipantName = ()=> _inner.INTERNAL_SERVICE_PARTICIPANT_NAME;

    service.isParticipantLegitForWaitingRoom = function(p) {
        return p && p.is_in_waiting_room && p.joined? true: false;
    }
    service.getParticipantsOnWaitingRoomByCallId = function(id) {
        if(!id)
            return [];
        let filtered = [];
        let participants = service.getParticipants();
        for(let i=0; i<participants.length; i++) {
            if(participants[i].mre_call_id !== id)
                continue;
            if(!participants[i].isJustDeclined && service.isParticipantLegitForWaitingRoom(participants[i]))
                filtered.push(participants[i]);
        }
        return filtered;
    }
    service.isParticipantLegitToEnterCall = function(p) {
        return p && !p.is_in_waiting_room && p.joined && !p.is_declined? true: false;
    }
    service.onUpdateParticipant = function(obj) {
        return new Promise((resolve, reject)=>{
            if(!obj || !obj.callId || !obj.participantId || !obj.data) {
                reject();
                return;
            }
            let payload = {
                MreCallParticipant: obj.data
            }
            networkI.put({url: '/api/call/'+obj.callId+'/participant/'+obj.participantId, data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                service.updateParticipants([res.MreCallParticipant]);
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onUpdateTranscription = function(obj) {
        return new Promise((resolve, reject)=>{
            if(!obj || !obj.callId || !obj.participantId) {
                reject();
                return;
            }
            obj.data = {has_stt_active: obj.isActive? true: false};
            //we do not allow turning off transcription if note is enabled
            if(!obj.data.has_stt_active && obj.callId===callI.getActiveCallId() && callI.isActiveCallHasNoteEnabled()) {
                reject();
                return;
            }
            service.onUpdateParticipant(obj)
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                service.updateParticipants([res.MreCallParticipant]);
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onUpdateSts = function(obj) {
        return new Promise((resolve, reject)=>{
            if(!obj || !obj.callId || !obj.participantId) {
                reject();
                return;
            }
            obj.data = {has_sts_active: obj.isActive? true: false};
            service.onUpdateParticipant(obj)
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                service.updateParticipants([res.MreCallParticipant]);
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    return service;
}

export const participantI = ParticipantI();