import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
import { busI } from "./busI";
import { endpointI } from "./endpointI";
import { screenI } from "./screenI";
import { userI } from "./userI";
import { serviceProviderI } from "./serviceProviderI";
import { chatI } from "./chatI";
import { networkI } from "./networkI";
var MessagingI = function () {

    let service = {};

    let _inner = {};
    _inner.firebaseConf = {};

    _inner.events = {};
    service.isEventHandled = function(id) {
        return id && _inner.events[id]? true: false;
    }
    service.storeEventId = function(id) {
        if(!id)
            return;
        if(!_inner.events)
            _inner.events = {};
        _inner.events[id] =  true;
    }
    service.clearMessageEvents = function() {
        _inner.events = {};
    }

    service.init = function() {
        screenI.loadScript('./firebase-conf.js', null, null)
        .then((res)=>{
            _inner.firebaseConf = typeof FirebaseConfI === 'object' && typeof FirebaseConfI.config==='object'? FirebaseConfI.config: null;
            service.onRegister().then(()=>{}, ()=>{});
        }, ()=>{});
        chatI.syncUi();
    }

    service.askNotification = function() {
        return new Promise(function(resolve, reject) {
            if(Notification.permission === 'granted') {
                resolve();
                return;
            }
            Notification.requestPermission().then(function(permission) {
                if (permission === 'granted') {
                    resolve();
                } else {
                    reject();
                }
            }).catch(function(err) {
                reject();
            });
        });
    }

    service.handleMessageEvent = (msg) => {
        if(!msg)
            return;
        try {
            let obj = {
                eventId: msg.eventID,
                event: msg.event,
                body: typeof msg.body==='string'? JSON.parse(msg.body): msg.body
            }
            busI.notifyEvent(busI.EVENTS.ON_MESSAGE_EVENT, obj);
        } catch (error) {
            console.log('Error parsing message ', error);
        }
    }

    service.onRegister = ()=>{
        return new Promise((resolve, reject) => {
            if (!('serviceWorker' in navigator)) {
                reject();
                return;
            }
            if(!_inner.firebaseConf || !_inner.firebaseConf.config) {
                reject();
                return;
            }
            try {
                initializeApp(_inner.firebaseConf.config);
                _inner.messaging = getMessaging();
                navigator.serviceWorker.register('/sw.js')
                .then((registration) => {
                    navigator.serviceWorker.ready.then((registration) => {
                        if (!registration) {
                            reject();
                            return;
                        }
                        registration.active.postMessage({setting: {endpoint: endpointI.getSelectedEndpointName(), appName: serviceProviderI.getName(), iconURL: serviceProviderI.getLogo()}});
                        messagingI.askNotification().then(function() {
                            service.checkTokenUpdated(registration);
                            navigator.serviceWorker.onmessage = function(payload) {
                                if(!userI.getAccessToken())
                                    return;
                                if(payload && payload.data && payload.data.data && payload.data.data.event) {
                                    service.handleMessageEvent(payload.data.data);
                                }
                            }
                            resolve();
                        }, function() {
                            reject();
                        });
                    }).catch((err) => {
                        reject(err)
                    })
                }, (error)=>{
                    reject(error);
                });
            } catch (error) {
                reject(error);
            }
        })
    }

    service.checkTokenUpdated = function(registration) {
        if(!_inner.firebaseConf || !_inner.firebaseConf.vapidKey)
            return;
        _inner.messagingToken = null;
        getToken(_inner.messaging, {serviceWorkerRegistration: registration, vapidKey: _inner.firebaseConf.vapidKey})
        .then((token)=>{
            _inner.messagingToken = token;
            if(token)
                service.onRegisterDeviceToken(token).then(()=>{}, ()=>{});
        }, (error)=>{
        });
    }
    service.onRegisterDeviceToken = function(token) {
        return new Promise((resolve, reject)=>{
            if(!userI.getAccessToken()) {
                reject();
                return;
            }
            let payload = {
                Token: token
            };
            networkI.post({url: '/api/user/messaging/token', data: payload})
            .then((res)=>{
                if(res && res.MessagingToken)
                    resolve(res);
                else
                    reject();
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onInvalidateToken = function() {
        return new Promise((resolve, reject)=>{
            if(!userI.getAccessToken() || !_inner.messagingToken) {
                reject();
                return;
            }
            let payload = {
                Token: _inner.messagingToken
            };
            networkI.delete({url: '/api/user/messaging/token', data: payload})
            .then((res)=>{
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.invalidateToken = function() {
        service.onInvalidateToken().then(()=>{}, ()=>{});
    }
    service.postMessageToServiceWorker = (message)=>{
        if(!message) return
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(function(registration) {
              registration.active.postMessage(JSON.stringify(message));
            },()=>{});
          }
    }
    return service;
}

export const messagingI = MessagingI();