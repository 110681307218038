import { callI } from "./callI";
import { debugI } from "./debugI";
import { langI } from "./langI";
import { livekitI } from "./livekitI";
import { networkI } from "./networkI";
import { participantI } from "./participantI";
import { serviceProviderI } from "./serviceProviderI";
import { streamI } from "./streamI";
import { toastI } from "./toastI";
import { transcriptionI } from "./transcriptionI";
import { userI } from "./userI";
import { utilI } from "./utilI";

const SipI = function(){
    let _inner = {}
    _inner.ENDPOINT = `/api/manager/sip/config`;
    _inner.TMP_SIP_INVITE_PHONE_KEY = "SIP_PHONE_TMP_INVITE";
    _inner.isAddingNewItem = false;
    _inner.isLoading = false;
    _inner.defaultPhoneCode = null;
    _inner.tmpItem = {
        username:null,
        authId:null,
        domain:null,
        defaultRegisterExpiry:null,
        transport:null,
        outboundProxy:null
    }
    _inner.countries = [];
    _inner.phoneNumberToCall = '';
    _inner.ongoingInvites = {};
    _inner.ongoingCalls = {};
    _inner.avgAnsweringPhoneCallInSeconds = 28;
    _inner.useMethod = (method,endpoint,body={})=>{
        return new Promise((resolve,reject)=>{
            if(typeof method !== "string" || !endpoint || body && typeof body !== "object"){
                reject();
                return
            }
            let verb = method.toLowerCase();
            if(typeof networkI[verb] !== 'function') {
                reject();
                return;
            }
            let opt = {url: endpoint};
            if(verb !== 'get')
                opt.data = body;
            networkI[verb](opt)
            .then((res)=>{
                if(Array.isArray(res.errors) || res.error) {
                    let message = networkI.getErrorMessageFromArr(res.errors);
                    if(!message)
                        message = res.error;
                    reject(message);
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        })
    }
    _inner.config = []
    _inner.DEBUG = false;
    _inner.sip = {}
    _inner.notifyUI = ()=>{
        service.getUIBind();
        _inner.ui.config = _inner.config;
        _inner.sip.FiltredAvailablePhoneNumbers = [];
        if(Array.isArray(_inner.sip.AvailablePhoneNumbers)){
            _inner.sip.FiltredAvailablePhoneNumbers = _inner.sip.AvailablePhoneNumbers.filter(({phone_number})=>!_inner.config.find(({username})=>username === phone_number))
        }
        _inner.ui.sip = _inner.sip;
        _inner.ui.isAddingNewItem = _inner.isAddingNewItem;
        _inner.ui.isLoading = _inner.isLoading;
        _inner.ui.tmpItem = _inner.tmpItem;
        _inner.ui.countries = _inner.countries;
        _inner.ui.defaultPhoneCode = _inner.defaultPhoneCode
    }
    let service = {}
    service.updateParticipant = (arr)=>{
        if(!Array.isArray(arr))
            return;
        for(let i=0; i<arr.length; i++) {
            let sipParticipant =  arr[i];
            let participant = participantI.getParticipantById(arr[i].mre_call_participant_id);
            if(!sipParticipant || !participant)
                continue;
            //handle connection
            if(!_inner.ongoingCalls[sipParticipant.destination_number])
                _inner.ongoingCalls[sipParticipant.destination_number] = {};
            else {
                if(_inner.ongoingCalls[sipParticipant.destination_number].isHistorical)
                    continue;
            }

            _inner.ongoingCalls[sipParticipant.destination_number].sipParticipant = {};

            for(let k in sipParticipant)
                _inner.ongoingCalls[sipParticipant.destination_number].sipParticipant[k] = sipParticipant[k];

            service.handleSipParticipantUpdate(sipParticipant);

        }
    }
    service.sipParticipantKicked = (phone) => {
        if(!phone || !_inner.ongoingCalls[phone])
            return;
        _inner.ongoingCalls[phone].isHistorical = true;
    }
    service.isPhoneConnected = (phone)=>{
        if(!phone || !_inner.ongoingCalls[phone])
            return;
        let obj = _inner.ongoingCalls[phone];
        return !obj.isHistorical && obj.sipParticipant
                && !obj.sipParticipant.disconnected_at && obj.sipParticipant.connected_at? true: false;
    }
    service.getConnectedSipCalls = () => {
        if(!_inner.ongoingCalls)
            return [];
        let filtered = [];
        for(let k in _inner.ongoingCalls) {
            // if(!service.isPhoneConnected(k))
            //     continue;
            let obj = _inner.ongoingCalls[k];
            if(!obj.sipParticipant)
                continue;
            if(!livekitI.getParticipant(obj.sipParticipant.mre_call_participant_id))
                continue;
            filtered.push(obj);
        }
        return filtered;
    }
    service.getPhoneConnectedAt = (phone)=>{
        if(!phone || !_inner.ongoingCalls[phone])
            return;
        let obj = _inner.ongoingCalls[phone];
        return obj && service.isPhoneConnected(phone) && obj.sipParticipant.connected_at? obj.sipParticipant.connected_at: 0;
    }
    service.registerCounterSipInit = (phone, initCallback)=>{
        if(!phone || typeof initCallback !== 'function')
            return;
        if(!_inner.ongoingCalls[phone])
            _inner.ongoingCalls[phone] = {};
        _inner.ongoingCalls[phone].initCounter = initCallback;

        if(service.isPhoneConnected(phone))
            _inner.ongoingCalls[phone].initCounter(phone);
    }

    service.checkSipParticipantsTranscriptionStateByIds = (arr)=>{
        if(!Array.isArray(arr)) {
            arr = service.getConnectedSipCalls().filter(item => item.sipParticipant && item.sipParticipant.mre_call_participant_id).map(item=>item.sipParticipant.mre_call_participant_id);
        }
        if(arr.length<1)
            return;
        for(let i=0; i<arr.length; i++) {
            let id = arr[i];
            let participant = participantI.getParticipantById(id);
            if(!participant || !participant.is_sip || !participant.is_active)
                continue;
            //whenever a sip participant joins
            if(participant && !participant.has_stt_active && transcriptionI.hasAnyActiveParticipantEnabled()) {
                setTimeout(()=>{
                    participantI.onUpdateTranscription({callId: participant.mre_call_id, participantId: participant.mre_call_participant_id, isActive: true}).then(()=>{}, ()=>{});
                }, 150);
            }
        }
    }

    service.handleSipParticipantUpdate = (sipParticipant)=>{
        if(!sipParticipant || !sipParticipant.destination_number)
            return;
        let phone = sipParticipant.destination_number;
        if(!_inner.ongoingCalls[phone])
            return;
        let obj = _inner.ongoingCalls[phone];

        if(service.isPhoneConnected(phone)) {
            obj.isHistorical = false;
            if(typeof obj.initCounter==='function')
                obj.initCounter(phone);
            service.checkSipParticipantsTranscriptionStateByIds([sipParticipant.mre_call_participant_id]);
            return;
        }
        if(sipParticipant.disconnected_at) {
            setTimeout(()=>{
                transcriptionI.stopInstance(transcriptionI.getInstanceById(sipParticipant.mre_call_participant_id));
            });
            //that means call was stopped from client side and no need to handle it more
            if(obj.isHistorical)
                return;
            service.kickPhone(phone, sipParticipant.mre_call_participant_id);
        }
    }
    service.getUIBind = ()=>{
        if(_inner.ui) return _inner.ui
        _inner.ui = {
            config:_inner.config,
            countries: _inner.countries,
            sip:_inner.sip,
            save:service.save,
            add:service.add,
            delete:service.delete,
            isAddingNewItem:_inner.isAddingNewItem,
            isLoading:_inner.isLoading,
            back:service.back,
            tmpItem:_inner.tmpItem,
            addNewItem:service.addNewItem,
            saveNewItem:service.saveNewItem,
            toggleAdvancedOptions:service.toggleAdvancedOptions,
            defaultPhoneCode:_inner.defaultPhoneCode
        }
        return _inner.ui;
    }
    service.onGet = ()=>{
        return new Promise((resolve,reject)=>{
            if(!userI.isAdmin()){
                reject();
                return
            }
            _inner.isLoading = true;
            _inner.notifyUI();
            service.onLoad().then(()=>{},()=>{})
            .finally(()=>{
                _inner.isLoading = false;
                _inner.notifyUI();
                resolve({});
                // _inner.useMethod('GET',_inner.ENDPOINT,null)
                // .then(res=>{
                //     if(res && Array.isArray(res.value)){
                //         _inner.config = res.value;
                //     }
                //     resolve(res)
                // },err=>{
                //     reject(err);
                // })
                // .finally(()=>{
                //     _inner.isLoading = false;
                //     _inner.notifyUI();
                // })
            })
        })
    }
    service.onDelete = (id)=>{
        return new Promise((resolve,reject)=>{
            if(!userI.isAdmin() || !id){
                reject();
                return
            }
            _inner.useMethod('DELETE',_inner.ENDPOINT+'/'+id,null)
            .then(res=>{
                resolve(res)
            },err=>{
                reject(err)
            })
        })
    }
    service.onPost = (data={})=>{
        return new Promise((resolve,reject)=>{
            if(!userI.isAdmin()){
                reject();
                return
            }
            resolve({});
            // _inner.useMethod('POST',_inner.ENDPOINT,data)
            // .then(res=>{
            //     resolve(res)
            // },err=>{
            //     reject(err)
            // })
        })
    }
    service.onPut = (item={})=>{
        return new Promise((resolve,reject)=>{
            if(!userI.isAdmin() || typeof item !== "object"){
                reject();
                return
            }
            resolve({});
            // _inner.useMethod('PUT',_inner.ENDPOINT+'/'+item.id,{
            //     PhoneNumber:item.username
            // })
            // .then(res=>{
            //     resolve(res)
            // },err=>{
            //     reject(err)
            // })
        })
    }
    service.onLoad = ()=>{
        return new Promise((resolve,reject)=>{
            if(!userI.isAdmin()){
                reject();
                return
            }
            userI.onLoadSettings()
            .then((res)=>{
                _inner.sip = res.sip ? res.sip : {};
                resolve(res);
            }, (err)=>{
                reject(err)
            }).finally(()=>{
                _inner.notifyUI();
            });
        })
    }
    service.save = (item)=>{
        if(typeof item !== "object") return
        if(item.isSaving) return
        item.isSaving = true;
        _inner.notifyUI();
        service.onPut(item).then(()=>{
            toastI.success(langI.get('SIP_ITEM_SAVED'))
        },()=>{
            toastI.error(langI.get('ERROR_UNK'));
        })
        .finally(()=>{
            item.isSaving = false;
            _inner.notifyUI();
        })
    }
    service.saveNewItem = ()=>{
        if(_inner.tmpItem.isSaving) return
        _inner.tmpItem.isSaving = true;
        _inner.notifyUI();
        service.onPost({
            PhoneNumber:_inner.tmpItem.username
        })
        .then((res)=>{
            toastI.success(langI.get('SIP_ITEM_CREATED'))
            _inner.config.push(res);
        },()=>{
            toastI.error(langI.get('ERROR_UNK'));
        })
        .finally(()=>{
            service.clearTmpItem();
            _inner.tmpItem.isSaving = false;
            _inner.notifyUI();
            service.back();
        })
    }
    service.clearTmpItem = ()=>{
        for(let key in _inner.tmpItem){
            _inner.tmpItem[key] = null;
        }
    }
    service.addNewItem = ()=>{
        if(_inner.isAddingNewItem) return
        service.clearTmpItem();
        _inner.isAddingNewItem = true;
        _inner.notifyUI();
    }
    service.back = ()=>{
        if(!_inner.isAddingNewItem) return
        _inner.isAddingNewItem = false;
        service.clearTmpItem();
        _inner.notifyUI();
    }
    service.delete = (item)=>{
        if(typeof item !== "object" || item.isDeleting) return
        item.isDeleting = true;
        service.onDelete(item.id)
        .then(()=>{
            toastI.success(langI.get('SIP_ITEM_DELETED'))
            _inner.config = _inner.config.filter(c=>c.id !== item.id)
        },()=>{
            toastI.error(langI.get('ERROR_UNK'));
        })
        .finally(()=>{
            item.isDeleting = false;
            _inner.notifyUI();
        })
    }
    service.toggleAdvancedOptions = (item)=>{
        if(typeof item !== "object") return
        if(typeof item.isAdvanced !== "boolean"){
            item.isAdvanced = true;
            return
        }
        item.isAdvanced = !item.isAdvanced;
    }
    if(debugI.canDebug()){
        service.getInner = ()=> _inner;
        window.sipI = service;
    }
    service.setPhoneNumberToCall = (obj)=>{
        _inner.phoneNumberToCall = obj;
    }
    service.getPhoneNumberToCall = ()=>{
        return _inner.phoneNumberToCall;
    }
    service.clearPhoneNumberToCall = ()=>{
        _inner.phoneNumberToCall = null;
    }
    service.shouldStartCallWithSIP = ()=>{
        return _inner.startCallWithSIP;
    }
    service.setStartCallWithSIP = (val)=>{
        _inner.startCallWithSIP = +val===1? true: false;
    }
    service.onGetCountries = ()=>{
        return new Promise((resolve, reject)=>{
            userI.onLoadSettings()
            .then((res)=>{
                _inner.countries = res && Array.isArray(res.Countries)? res.Countries: [];
                service.sortCountries();
                _inner.defaultPhoneCode = _inner.countries.length > 0 && _inner.countries[0]?.phone_code ? _inner.countries[0]?.phone_code : null;
                _inner.notifyUI();
            }, ()=>{
            }).finally(()=>{
                resolve(_inner.countries);
            });
        });
    }
    service.sortCountries = ()=>{
        if(!Array.isArray(_inner.countries)) return
        try{
            _inner.countries = _inner.countries.sort((a,b)=>+a.phone_code-+b.phone_code)
        }catch{}
        _inner.notifyUI();
    }
    service.clearOngoingInvite = function(phone) {
        if(!phone || !_inner.ongoingInvites[phone])
            return;
        if(_inner.ongoingInvites[phone].containerCallback && typeof _inner.ongoingInvites[phone].containerCallback.close === 'function')
            _inner.ongoingInvites[phone].containerCallback.close();
        delete _inner.ongoingInvites[phone];
    }
    service.isOnGoingInviteExists = function(phone) {
        if(typeof phone === "number"){
            phone = phone.toString();
        }
        if(typeof phone !== "string") return false
        return _inner.ongoingInvites && _inner.ongoingInvites[phone.replace(/^\+/, '')] ? true: false;
    }
    service.stopOngoingInvite = function(phone) {
        if(!phone || !_inner.ongoingInvites[phone])
            return;
        if(phone.startsWith("+")){
            phone = phone.slice(1);
        }
        service.kickPhone(phone);
        callI.onStoreEvent(callI.getActiveCallId(), {ActionId: callI.EVENTS.PHONE_INVITE_END, Action: JSON.stringify({phone: phone})}).then(()=>{}, ()=>{});
        service.clearOngoingInvite(phone);
    }
    service.stopAllOngoingInvites = function() {
        if(!_inner.ongoingInvites)
            return;
        for(let k in _inner.ongoingInvites)
            service.stopOngoingInvite(k);
    }
    service.updateOngoingInvite = (obj)=>{
        if(!obj || !_inner.ongoingInvites)
            return;
        let phone = '';
        for(let k in _inner.ongoingInvites) {
            phone = k;
            break;
        }
        if(!phone || !_inner.ongoingInvites[phone] || !_inner.ongoingInvites[phone].containerCallback)
            return;
        if(typeof _inner.ongoingInvites[phone].containerCallback.update === 'function')
            _inner.ongoingInvites[phone].containerCallback.update(obj);
    }
    service.getLimitInSecondsByPhone = (phone)=>{
        return _inner.limitsInSeconds && phone && _inner.limitsInSeconds[phone]? _inner.limitsInSeconds[phone]: null;
    }
    service.clear = ()=>{
        service.stopAllOngoingInvites();
    }
    service.onAddSipParticipant = function(phone, languageId) {
        return new Promise((resolve, reject)=>{
            if(!phone || typeof phone!=='string' || !callI.getActiveCallId()) {
                reject();
                return;
            }
            if(!phone) {
                reject();
                return;
            }
            languageId = languageId? languageId: null;
            if(!languageId)
                try {
                    let storedInvite = JSON.parse(utilI.getFromLocalStorage(_inner.TMP_SIP_INVITE_PHONE_KEY));
                    if(storedInvite && storedInvite.phone === phone) {
                        if(storedInvite.language && storedInvite.language.language_id)
                            languageId = storedInvite.language.language_id
                    }
                } catch (error) {}
            let payload = {
                Name: phone,
                MreCallProviderID: serviceProviderI.getServiceProviderId(),
                AvatarUrl: userI.getSipDefaultAvatar()
            }
            if(languageId)
                payload.LanguageID = languageId;

            networkI.post({url: `/api/call/${callI.getActiveCallId()}/sip/participant`, data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                participantI.updateParticipants([res.MreCallParticipant]);
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.onRemoveSipParticipant = function(participant) {
        return new Promise((resolve, reject)=>{
            if(!participant || !participant.is_sip || !participant.mre_call_participant_id) {
                reject();
                return;
            }
            let payload = {
                MreCallParticipant: {mre_call_participant_id: participant.mre_call_participant_id},
                MreCallProviderID: serviceProviderI.getServiceProviderId()
            }
            networkI.put({url: `/api/call/${callI.getActiveCallId()}/sip/participant/remove`, data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                participantI.updateParticipants([res.MreCallParticipant]);
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.storeToastWarning = function(phone, container) {
        if(!phone || !container)
            return;
        if(!_inner.toastWarnings)
            _inner.toastWarnings = {};
        _inner.toastWarnings[phone] = container;
    }
    service.kickPhone = function(phone, participantId) {
        if(!phone) return
        if(_inner.toastWarnings && _inner.toastWarnings[phone]) {
            if(_inner.toastWarnings[phone].containerRef)
            toastI.closeToast(_inner.toastWarnings[phone].containerRef);
        }
        clearTimeout(_inner.stopSipCallTimer);
        _inner.stopSipCallTimer = setTimeout(()=>{
            livekitI.onStopSipCall(phone, participantId)
            .then(()=>{
                if(phone) {
                    if(_inner.toastWarnings && _inner.toastWarnings[phone]) {
                        if(_inner.toastWarnings[phone].containerRef)
                            toastI.closeToast(_inner.toastWarnings[phone].containerRef);
                    }
                    service.sipParticipantKicked(phone);
                }
            },(error)=>{
                toastI.error(error && typeof error==='string'? error: langI.get('ERROR_UNK'));
            });
        }, 200);
    }
    service.kickSipConnection = function(id) {
        if(!id)
            return;
        let sipParticipants = participantI.getParticipantsByCallId(callI.getActiveCallId()).filter(item=>item.is_sip);
        if(sipParticipants.length>0) {
            let participant = null;
            for(let i=0; i<sipParticipants.length; i++) {
                if(sipParticipants[i].mre_call_participant_id==id) {
                    participant = sipParticipants[i];
                }
            }
            if(participant)
                service.onRemoveSipParticipant(participant).then(()=>{}, ()=>{});
        }
        service.kickPhone(livekitI.getParticipantNameById(id), id);
    }
    service.onCheckAuth = function() {
        return new Promise((resolve, reject) => {
            networkI.get({url: '/api/user/phone/auth'})
            .then((response)=>{
                if(Array.isArray(response.errors)) {
                    reject(networkI.getErrorMessageFromArr(response.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                if(response.IsAuthorized)
                    resolve(response);
                else
                    reject(response);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.storeLimitCounter = (phone, limit) => {
        if(typeof phone !== 'string' || +limit<0 || +limit!==+limit)
            return;
        if(!_inner.limitsInSeconds)
            _inner.limitsInSeconds = {};
        _inner.limitsInSeconds[phone] = limit;
    }
    service.setParticipantConnected = (participant)=>{
        if(!participant || !participant.destination_number)
            return;
        if(service.isPhoneConnected(participant.destination_number))
            return;
        callI.onStoreEvent(callI.getActiveCallId(), {ActionId: callI.EVENTS.PHONE_INVITE_START, Action: JSON.stringify({phone: participant.destination_number})}).then(()=>{}, ()=>{});
        participant.connected_at = userI.getCurrentTimestamp();
        service.updateParticipant([participant]);
    }
    service.runStartCounter = (participant) => {
        if(!participant || !participant.destination_number)
            return;
        if(service.isPhoneConnected(participant.destination_number))
            return;
        setTimeout(() => {
            service.setParticipantConnected(participant);
        }, _inner.avgAnsweringPhoneCallInSeconds * 1000);
    }
    service.onInvitePhoneNumber = (phoneObj)=>{
        return new Promise((resolve, reject) => {
          if(!phoneObj || !phoneObj.phone || !userI.hasSIPEnabled() || _inner.ongoingInvites[phoneObj.phone]) {
            reject();
            return;
          }
          utilI.removeFromLocalStorage(_inner.TMP_SIP_INVITE_PHONE_KEY);
          let phone = phoneObj.phone;
          service.onCheckAuth()
          .then((response)=>{
            service.storeLimitCounter(phone, response.LimitInSeconds);
            callI.onStoreEvent(callI.getActiveCallId(), {ActionId: callI.EVENTS.PHONE_INVITE, Action: JSON.stringify({phone: phone})}).then(()=>{}, ()=>{});
            let languageId = phoneObj.language && phoneObj.language.language_id? phoneObj.language.language_id: null;
            service.onAddSipParticipant(phone, languageId)
            .then((response)=>{
                let participantId = response && response.MreCallParticipant? response.MreCallParticipant.mre_call_participant_id: '';
                if(!participantId) {
                    reject();
                    return;
                }
                let obj = {
                    name: '+'+phone,
                    status: 'Calling',
                    state: 'info',
                    container: {},
                    containerCallback: {},
                    enableUpdateContent: true
                    };
                _inner.ongoingInvites[phone] = {};
                _inner.ongoingInvites[phone].containerCallback = obj.containerCallback;
                toastI.displayPopup(obj, 'infinit', {icon: '<i class="fas fa-phone-alt"></i>', state: 'info', action: '', cancel: 'Cancel', onClose: (container) => {
                    if(obj.container)
                        toastI.closeToast(obj.container);
                }}).then(()=>{
                    //handle retry
                    service.stopOngoingInvite(phone);
                    if(obj.container)
                        toastI.closeToast(obj.container);
                    setTimeout(()=>{
                        service.onInvitePhoneNumber(phoneObj).then(()=>{}, ()=>{});
                    }, 1500);
                }, ()=>{
                    service.stopOngoingInvite(phone);
                    if(obj.container)
                        toastI.closeToast(obj.container);
                });
                livekitI.onStartSipCall({number: phone, identity: participantId})
                .then(()=>{
                    //if we are on an environment that doesn't have sip provider notification enabled - we run counter here to stop by limitation
                    if(!userI.isSipProviderConnected()) {
                        service.runStartCounter({
                            mre_call_participant_id: participantId,
                            destination_number: phone
                        });
                    }
                    resolve();
                },(error)=>{
                    service.stopOngoingInvite(phone);
                    toastI.error(error && typeof error==='string'? error: langI.get('ERROR_UNK'));
                    reject();
                })
            }, (error)=>{
                reject(error && typeof error==='string'? error: langI.get('ERROR_UNK'));
            });
          }, (error)=>{
            service.stopOngoingInvite(phone);
            toastI.error(error && typeof error==='string'? error: langI.get('ERROR_UNK'));
            reject();
          });
        });
      }
    return service
}
export const sipI = SipI();