const DomSanitizerI = ()=>{
    const _inner = {};
    const service = {};
    service.init = (sanitizer)=>{
        if(!sanitizer) return
        _inner.sanitizer = sanitizer;
    }
    service.bypassSecurityTrustResourceUrl = (url)=>{
        if(_inner.sanitizer && typeof _inner.sanitizer.bypassSecurityTrustResourceUrl === "function"){
            return _inner.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
        return url;
    }
    return service;
}
export const domSanitizerI = DomSanitizerI();