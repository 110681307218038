import { participantI } from './participantI';
import { callI } from './callI';
import {environment} from 'src/environments/environment'
import { busI } from './busI';
import {Device} from 'mediasoup-client'
import {serviceProviderI} from './serviceProviderI'
import {endpointI} from './endpointI'
import { streamI } from './streamI';
import { modalI } from './modals';
import { toastI } from './toastI';
import { langI } from './langI';
import { userI } from './userI';
import { networkI } from './networkI';
var RecorderI = function () {
  const DEV = false;
  class MreCallParticipant{
    constructor({userId,is_host,avatar,username}){
      this.userId = userId ? userId : service.uuidv4();
      this.is_host = +is_host === 1 ? true : false;
      this.isScreenShare = this.userId.indexOf(_inner.screenShareIdSuffix) > -1 ? true : false;
      if(this.isScreenShare) {
        this.userId += '_'+service.uuidv4();
      }
      this.active = false,
      this.type = null;
      this.avatar = typeof avatar==="string" && avatar.length>0 ? avatar : userI.getDefaultAvatar();
      this.step = null;
      this.recording = false;
      this.recording_allowed = false;
      this.paused = false;
      this.resumed = false;
      this.active = false;
      this.username = this.isScreenShare ? `${username}'s Screen` : username;
      this.screenName = this.username;
      if(this.is_host){
        _inner.consentRecording[this.userId] = true;
      }
      if(this.isScreenShare && !this.is_host){
        const participantUserId = this.userId.replace(service.getScreenShareIdSuffix(),'');
        const isConsentRecording = _inner.consentRecording[participantUserId] ? true : false;
        _inner.consentRecording[this.userId] = isConsentRecording;
      }
      if(!_inner.producers[this.userId]){
        _inner.producers[this.userId] = [];
      }
    }
    onStartRecording(){
      return new Promise((resolve,reject)=>{
        if(!service.isOpen() || true){
          reject();
          return
        }
        service.onPrepareTransports(this.userId).then(()=>{},()=>{})
        .finally(()=>{
          this.type = 'mix';
          this.active = false;
          switch(this.type){
            case "avatar":
              (this.step ? this.step.onStop() : Promise.resolve()).then(()=>{},()=>{})
              .finally(()=>{
                this.step = new MreCallParticipantStep(this)
                this.recorder = null;
                this.step.onStart().then(()=>{},()=>{}).finally(resolve)
              })
              break;
            case "audio":
              (this.step ? this.step.onStop() : Promise.resolve()).then(()=>{},()=>{})
              .finally(()=>{
                this.step = new MreCallParticipantStep(this)
                this.step.onStart().then(()=>{},()=>{})
                .finally(()=>{
                  this.onRecordStream().then(()=>{},()=>{}).finally(resolve)
                })
              })
              break;
            case "video":
              (this.step ? this.step.onStop() : Promise.resolve()).then(()=>{},()=>{})
              .finally(()=>{
                this.step = new MreCallParticipantStep(this)
                this.step.onStart().then(()=>{},()=>{})
                .finally(()=>{
                  this.onRecordStream().then(()=>{},()=>{}).finally(resolve)
                })
              })
              break;
            case "mix":
              (this.step ? this.step.onStop() : Promise.resolve()).then(()=>{},()=>{})
              .finally(()=>{
                this.step = new MreCallParticipantStep(this)
                this.step.onStart().then(()=>{},()=>{})
                .finally(()=>{
                  this.onRecordStream().then(()=>{},()=>{}).finally(resolve)
                })
              })
              break;
          }
        })
      })
    }
    onStopRecording(leaving){
      return new Promise((resolve,reject)=>{
        if(!service.isOpen() || !this.active || !this.type) {
          reject();
          return
        }
        this.step.onStop(leaving?true:false).then(()=>{},()=>{})
        .finally(()=>{
          this.active = false;
          this.type = null;
          if(this.isScreenShare){
            setTimeout(()=>{
              service.removeParticipantById(this.userId)
            })
          }
          resolve();
        })
      })
    }
  onRecordStream(){
    return new Promise((resolve,reject)=>{
        if(!service.isOpen()){
          reject();
          return
        };
        this.active=true;
        this.type = 'mix';
        this.recorder = new MreCallParticipantMediaStepRecorder(this);
        this.recorder.onStart().then(()=>{},()=>{});
        resolve();
    })
  }
  }
  class MreCallParticipantStep{
    constructor(participant){
      this.participant = participant;
    }
    onStart(type='mix'){
      return new Promise((resolve,reject)=>{
        if(!this.participant || this.participant.active || (typeof _inner.consentRecording[this.participant.userId] !== "boolean" && !this.participant.is_host)){
          reject();
          return
        }
        this.participant.active = true;
        this.participant.id = service.uuidv4();
        let payload={
          callId:callI.getActiveCallId(),
          id:this.participant.id,
          type:type,
          username:this.participant?.username,
          screenName:this.participant?.screenName,
          avatar:this.participant?.avatar,
          is_host:this.participant?.is_host,
          isScreenShare:this.participant.isScreenShare,
          recording:service.isConsentRecording(this.participant.userId)?true:false,
          userId:this.participant.userId,
          tag:service.getConnectionById[this.participant.userId]?.remoteConnectionInfo?.getTag(),
          ids:{
            participant:_inner.transportInfo[this.participant.userId]?.sessionId,
            screenShare:_inner.transportInfo[this.participant.userId+_inner.screenShareIdSuffix]?.sessionId,
            userId:this.participant.userId
          },
          paused:this.participant.paused?true:false,
          resumed:this.participant.resumed?true:false
        }
        service.httpRequest('startStep',payload).then(()=>{},()=>{})
        .finally(()=>{ resolve() })
      })
    }
    onStop(leaving){
      return new Promise((resolve,reject)=>{
        if((!this.participant)){
          reject();
          return
        }
        (this.participant.recorder ? this.participant.recorder.onStop() : Promise.resolve()).then(()=>{},()=>{})
        .finally(()=>{
          let payload={
            callId:callI.getActiveCallId(),
            id:this.participant.id,
            userId:this.participant.userId,
            leaving:leaving?true:false,
            paused:this.participant.paused
          }
          service.httpRequest('stopStep',payload).then(()=>{},(error)=>{})
          .finally(()=>{
            this.participant.active=false;
            this.participant.type=null;
            resolve();
            return
          })
        })
      })
    }
  }
  class MreCallParticipantMediaStepRecorder{
    constructor(participant){
      this.participant = participant;
      this.active = false;
    }
    onStart=()=>{
      return new Promise(async (resolve,reject)=>{
        this.participant.step.onStart().then(()=>{},()=>{})
        .finally(async ()=>{
          this.participant.active = true;
          this.active = true;
          const transportInfo = _inner.transportInfo[this.participant.userId];
          const sendTransport = _inner.sendTransports[this.participant.userId];
          if(!sendTransport || !transportInfo){
            reject();
            return
          }
          const callback = async ()=>{
            try{
              const tracks = service.getRemoteMediaStreamTracks(this.participant.userId);
              const audioTrack = tracks&&tracks.audioTrack ? tracks.audioTrack : null;
              const videoTrack = tracks&&tracks.videoTrack ? tracks.videoTrack : null;
              if((this.participant.type === "video" || this.participant.type === "mix") && videoTrack){
                try{
                  const videoProducer = await sendTransport.produce({
                    track: videoTrack.clone()
                  })
                  _inner.producers[this.participant.userId]?.push(videoProducer)
                }catch(err){
                  _inner.debug(err)
                  reject();
                  return
                }
              }
              if((this.participant.type === "audio" || this.participant.type === "mix") && audioTrack){
                try{
                  const audioProducer = await sendTransport.produce({
                    track:audioTrack.clone()
                  })
                  _inner.producers[this.participant.userId]?.push(audioProducer)
                }catch(err){
                  _inner.debug(err)
                  reject();
                  return
                }
              }
              }catch(err){
                _inner.debug(err)
              }
              const payload = {
                callId:callI.getActiveCallId(),
                id:this.participant.id,
                userId:this.participant.userId,
                sessionId:transportInfo.sessionId
              }
              service.httpRequest('startRecordStep',payload).then(()=>{},()=>{})
              .finally(()=>{
                resolve();
              })
          }
          let attempts = 0;
          const waitForTracks = ()=>{
            attempts++;
            let tracks = service.getRemoteMediaStreamTracks(this.participant.userId);
            if(tracks && (tracks.audioTrack || tracks.videoTrack) || attempts > 1000) {
              callback();
              attempts = 0 ;
              return
            }
            setTimeout(waitForTracks, 20)
          }
          waitForTracks();
        })
      })
    }
    onStop=()=>{
      return new Promise(async (resolve,reject)=>{
        if(!this.active){
          reject();
          return
        }
        const producers = _inner.producers[this.participant.userId];
        const transportInfo = _inner.transportInfo[this.participant.userId];
        if(Array.isArray(producers)){
          for(let i = 0 ; i < producers.length ; i++){
            const producer = producers[i];
            try{
              await producer.close();
            }catch(err){
              _inner.debug(err)
            }
          }
        }
        const payload = {
          callId:callI.getActiveCallId(),
          id:this.participant.id,
          userId:this.participant.userId,
          sessionId:transportInfo?.sessionId,
          paused:this.participant.paused,
          resumed:this.participant.resumed
        }
        service.httpRequest('stopRecordStep',payload).then(()=>{},()=>{})
        .finally(()=>{
          this.active = false;
          resolve();
        })
      })
    }
  }
  var _inner = {
    isRecorderService:false,
    isOpen:false,
    device:null,
    transportInfo:{},
    sendTransports:{},
    producers:{},
    state:{
      recorder:"inactive",
      participant:false,
      screenShare:false
    },
    paused:{
      participant:{},
      screenShare:{}
    },
    disconnected:false,
    socket:null,
    options:{
      socket_url :environment.TMU_RECORD_SOCKET_URL,
      socket_options :{
        autoConnect:false,
        reconnection:false,
        reconnectionDelay:1000,
        reconnectionDelayMax:5000,
        extraHeaders:{}
      }
    },
    callInfo:{
      callId:null,
      subject:null,
      description:null
    },
    callback:{},
    participants:[],
    connections:{},
    consentRecording:{},
    attempts: {
      stop: 0
    },
    screenShareIdSuffix:"_screen_share",
    isReady:false,
    modal:null,
    recorderState: {}
  };
  var service = {};
  service.init = ()=>{
    if(!_inner.callInfo)
      _inner.callInfo = {};
    _inner.callInfo.callId = callI.getActiveCallId();
    _inner.callInfo.subject = callI.getActiveCall()?.call_subject;
    _inner.callInfo.description = callI.getActiveCall()?.description;
  }
  service.EVENTS={
    ON_RECORDER_OPEN:'ON_RECORDER_OPEN',
    ON_RECORDER_CLOSE:'ON_RECORDER_CLOSE',
    ON_RECORDER_STATE_CHANGE:'ON_RECORDER_STATE_CHANGE',
    ON_RECORDER_ERROR:'ON_RECORDER_ERROR',
    ON_REMOTE_RECORDING_START:'ON_REMOTE_RECORDING_START',
    ON_REMOTE_RECORDING_STOP:'ON_REMOTE_RECORDING_STOP',
    ON_PARTICIPANT_RECORD_STATE_CHANGE:'ON_PARTICIPANT_RECORD_STATE_CHANGE',
    ON_PARTICIPANT_RECORD_OPTION_CHANGE:'ON_PARTICIPANT_RECORD_OPTION_CHANGE',
    ON_PARTICIPANT_BECAME_HOST:'ON_PARTICIPANT_BECAME_HOST',
    ON_STEP_START:'ON_STEP_START',
    ON_STEP_STOP:'ON_STEP_STOP',
    ON_PARTICIPANT_RESUMED:'ON_PARTICIPANT_RESUMED',
    ON_PARTICIPANT_PAUSED:'ON_PARTICIPANT_PAUSED',
    ON_SCREENSHARE_PAUSED:'ON_SCREENSHARE_PAUSED',
    ON_SCREENSHARE_RESUMED:'ON_SCREENSHARE_RESUMED',
    ON_RECORDER_STATE_CHANGED:'ON_RECORDER_STATE_CHANGED',
    ON_LOADING_STATE_CHANGED:'ON_LOADING_STATE_CHANGED',
    ON_RECORDER_READY:'ON_RECORDER_READY',
    ON_START_LIVE_STREAM:'ON_START_LIVE_STREAM',
    ON_STOP_LIVE_STREAM:'ON_STOP_LIVE_STREAM',
    ON_CONSENT_RECORDING:'ON_CONSENT_RECORDING',
    ON_TOGGLE_PARTICIPANT_RECORDING:'ON_TOGGLE_PARTICIPANT_RECORDING',
    ON_REQUEST_START_RECORDER_SERVICE:'ON_REQUEST_START_RECORDER_SERVICE',
    ON_REQUEST_STOP_RECORDER_SERVICE:'ON_START_RECORDER_SERVICE'
  }

  service.initRecorderEvents = () => {
    if(service.isRecorderService()) return
    service.clearEvents();
    service.on(service.EVENTS.ON_RECORDER_OPEN,()=>{
      if(!_inner.recorderState)
        _inner.recorderState = {};
      _inner.recorderState.isOpen = true;
      _inner.recorderState.isWaiting = false
      _inner.recorderState.isClosing = false;
      service.setIsRady(true);
      if(callI.isActiveCallByHost()){
        _inner.recorderState.isUserBeingRecorded = true;
        const buttons = Array.from(document.querySelectorAll("[toggle-recording]"));
        if(!Array.isArray(buttons))
          for(let i = 0 ; i < buttons.length ; i++){
            buttons[i]["recording"]=false
            buttons[i]["loading"]=true;
            buttons[i].dispatchEvent(new Event('update'))
            buttons[i]["style"].display = 'inherit'
          }
      }
      if(_inner.recorderState.isUserBeingRecorded){
        toastI.info(langI.get('RECORDING_START'));
      }
    });
    service.on(service.EVENTS.ON_RECORDER_CLOSE,()=>{
      toastI.info(langI.get('RECORDING_STOP'));
      _inner.recorderState.isUserBeingRecorded=false;
      _inner.recorderState.isOpen = false;
      _inner.recorderState.isWaiting = false;
      _inner.recorderState.isClosing = false;
      if(callI.isActiveCallByHost()) {
        const buttons = Array.from(document.querySelectorAll("[toggle-recording]"));
        if(Array.isArray(buttons))
          for(let i = 0 ; i < buttons.length ; i++){
            buttons[i]["style"].display = 'none'
          }
      }
    })
    if(callI.isActiveCallByHost()){
      service.once(service.EVENTS.ON_RECORDER_ERROR,(err)=>{
        _inner.recorderState.isUserBeingRecorded=false;
        _inner.recorderState.isOpen = false;
        _inner.recorderState.isWaiting = false;
        _inner.recorderState.isClosing = false;
        service.setIsRady(false);
        toastI.error(`${langI.get('ERROR_RECORDING_UNK')} ${langI.get('TRY_AGAIN_OR_CONTACT')}`, 0, {fontsize: ".85rem"});
      })
    }
  }
  _inner.notifyUI = ()=>{
    if(!_inner.ui)
        return;
    _inner.ui.isOpen = _inner.isOpen;
    _inner.ui.isRecorderService = _inner.isRecorderService;
    _inner.ui.state = _inner.recorderState;
  }
  _inner.open = ()=>{
    _inner.isOpen = true;
    callI.setIsActiveCallRecording(1);
    busI.notifyEvent(busI.EVENTS.RECORDING_CHANGED_STATE,true);
    _inner.notifyUI();
  }
  _inner.stop = ()=>{
    _inner.isOpen = false;
    callI.setIsActiveCallRecording(0);
    busI.notifyEvent(busI.EVENTS.RECORDING_CHANGED_STATE,false);
    _inner.notifyUI();
  }
  service.isOpen = ()=>{
    return _inner.isOpen || _inner.recorderState && _inner.recorderState.isOpen? true: false;
  }
  service.open = ()=>{
    _inner.open();
  }
  service.stop = ()=>{
    _inner.stop();
  }
 service.onConsentRecording= async function(consentRecording,isResumed){
   return new Promise((resolve,reject)=>{
    if(typeof consentRecording !== "boolean"){
      reject();
    }
    reject();
    return;
    service.httpRequest('setConsentRecording',{
      callId:callI.getActiveCallId(),
      userId:callI.getActiveParticipantId(),
      consentRecording:consentRecording?true:false
    }).then(()=>{
      try{
        const message = {
          event:"record",
          action:service.EVENTS.ON_CONSENT_RECORDING,
          consentRecording:consentRecording?true:false,
          isHost:service.isHost(),
          isResumed:isResumed?true:false,
        }
        service.send(message)
        resolve();
      }catch(err){
        reject(err)
      }
    },(err)=>{
      reject(err)
    });
   })
}
 service.once=function(name,callback){
   if(!name||typeof callback !== "function") return;
   let wrapperFunction=function(){
          callback(...arguments);
          _inner.callback[name] = null
   }
   _inner.callback[name] = wrapperFunction;
 }
 service.on=function(name,callback){
  if(!name||typeof callback !== "function") return;
  _inner.callback[name] = callback;
 }
 service.clearEvents = ()=>{
  _inner.callback={}
}
service.httpRequest = function(endPoint,body={},method='POST'){
  return new Promise((resolve,reject)=>{
    // disable here
    if(service) {
      reject();
      return;
    }
        const baseURL = `${environment.TMU_RECORD_API_URL}`
        const payload = {};
        if(method==="POST"){
          payload.method = method;
          payload.body = JSON.stringify(body);
          payload.headers = {'Content-Type':'application/json'}
        }
        fetch(`${baseURL}/media/${endPoint}`,payload)
        .then(response=>response.json())
        .then((response)=>{resolve(response)})
        .catch(error=>{reject(error)})
  })
}
service.onLoadDevice=function(routerRtpCapabilities){
  return new Promise((resolve,reject)=>{
    if(typeof routerRtpCapabilities !== "object"){
      reject();
      return
    }
    if(_inner.device&&_inner.device.loaded){
      resolve();
      return
    }
    _inner.device = new Device();
    _inner.device.load({routerRtpCapabilities:routerRtpCapabilities})
    .then((res)=>{
      resolve()
    },()=>{
      reject()
    })
  })
}
  service.onStopRecording = ()=>{
    return new Promise((resolve, reject)=>{
        if(!service.isOpen() || !callI.isActiveCallByHost() || service.isRecorderService()) {
            resolve();
            return;
        }
        let participant = callI.getActiveParticipant();
        if(!participant || !callI.isActiveCallByHost() || !service.isOpen()) {
            reject();
            return;
        }
        let payload = {
            MreCallProvider: serviceProviderI.getServiceProviderForApi(),
            MreCallParticipant: participant
        };
        if(_inner.attempts.stop>1) {
          resolve();
          return;
        }
        _inner.stop();
        resolve();
    });
}
service.onStartRecording = ()=>{
    return new Promise((resolve, reject)=>{
        reject();
    });
}
service.updateRecordingFlashingButton = (val)=>{
  if(!_inner.recorderState)
    service.getUIBind();
  _inner.recorderState.isOpen = +val===1? true: false;
}
service.getUIBind = ()=>{
    if(_inner.ui)
        return _inner.ui;

    _inner.recorderState = { isOpen:false, isLoading:false, isWaiting:false, isClosing:false , isUserBeingRecorded:false };

    _inner.ui = {
        isOpen: _inner.isOpen,
        open: _inner.open,
        stop: _inner.stop,
        isRecorderService: _inner.isRecorderService,
        isReady: _inner.isReady,
        state: _inner.recorderState,
        toggle: ()=>{
          if(!_inner.recorderState || _inner.recorderState.isClosing || _inner.recorderState.isWaiting)
            return;
          _inner.recorderState.isWaiting = true;
          if(!service.isOpen()) {
            callI.onStartRecording().then(()=>{
              _inner.recorderState.isOpen = true;
            }, ()=>{})
            .finally(()=>{
              _inner.recorderState.isWaiting = false;
              _inner.notifyUI();
            })
            // if(_inner.recorderState.isWaiting) return
            // _inner.recorderState.isWaiting = true;
            // service.initRecorderEvents();
            // service.onRequestStartRecorderService().then(()=>{},()=>{
            //   service.exec(service.EVENTS.ON_RECORDER_ERROR);
            // })
          } else {
            callI.onStopRecording().then(()=>{
              _inner.recorderState.isOpen = false;
            }, ()=>{})
            .finally(()=>{
              _inner.recorderState.isWaiting = false;
              _inner.notifyUI();
            });
            _inner.notifyUI();
            // if(_inner.recorderState.isWaiting) return
            // _inner.recorderState.isWaiting = true;
            // service.onRequestStopRecorderService().then(()=>{},()=>{})
          }
        }
    };
    return _inner.ui;
}
  service.clear = function(){
    _inner.connections = {}
    _inner.isReady = false;
    _inner.isOpen = false;
    _inner.participants = [];
    service.clearEvents();
    _inner.notifyUI();
    _inner.ui = null;
  };
  service.uuidv4 = function() {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
              var r = Math.random() * 16 | 0,
                  v = c == 'x' ? r : (r & 0x3 | 0x8);
              return v.toString(16);
          });
  }
  service.exec=function(eventName,args={}){
    if(!eventName) return;
    let _function = _inner.callback[eventName];
    if(_function&&typeof _function ==="function"){
      _function(args)
    }
  }
  service.getURL=function(callId){
    let RECORD_APP_URL = environment.TMU_RECORD_APP_URL;
    if(!RECORD_APP_URL) return;
    callId = callId ? callId : _inner.callInfo.callId ? _inner.callInfo.callId : null;
    if(!callId)
      callId = '{{callId}}';
    return `${RECORD_APP_URL}/#/play/${callId}`;
  }
  service.stopParticipant = function(participant,leaving=false,paused=false) {
    return new Promise((resolve, reject)=>{
      if(!participant) {
        reject();
        return;
      }
      if(participant.recorder&&participant.recorder.recording){
        participant.recorder.stop();
        resolve();
        return
      }
      participant["paused"] = paused;
      participant["resumed"] = false;
      participant.step.onStop(leaving).then().finally(()=>{
        resolve()
      })
    });
  }
  service.onRequestStartRecorderService = ()=>{
    return new Promise((resolve,reject)=>{
      if(service.isRecorderService() || service.isOpen()){
        reject();
        return
      }
      service.onStartRecording().then(()=>{},()=>{})
      .finally(()=>{
        if(_inner.isReady){
          service.send({
            event:"record",
            action:service.EVENTS.ON_REQUEST_START_RECORDER_SERVICE
          })
          resolve();
          return
        }
        const body = {
          origin:window.origin,
          call_id:callI.getActiveCallId(),
          token:callI.getJoinCode(),
          endpoint:endpointI.getSelectedEndpointName(),
          provider:serviceProviderI.getServiceProviderId()
        }
        service.httpRequest('recorderService',body)
        .then((response)=>{
          if(response && response.success)
            resolve();
          else
            reject();
        },()=>{
          reject();
        });
      })
    })
  }
  service.onRequestStopRecorderService = (leaving)=>{
    return new Promise((resolve,reject)=>{
      if(!_inner.isOpen || service.isRecorderService() || !service.isHost()){
        reject();
        return
      }
      service.onStopRecording().then(()=>{},()=>{})
      .finally(()=>{
        service.send({
          event:"record",
          action:service.EVENTS.ON_REQUEST_STOP_RECORDER_SERVICE
        })
        resolve();
      })
    })
  }
  service.isParticipantExist = (userId)=>{
    return service.getParticipantById(userId) ? true : false;
  }
  service.getParticipantById = (userId)=>{
    if(!userId || !Array.isArray(_inner.participants)) return;
    for(let i=0; i<_inner.participants.length; i++) {
      let p = _inner.participants[i];
      if(p.userId === userId) {
        return p;
      }
      if(streamI.isScreenShareConnection(p.userId)) {
        let participantIds = p.userId.split('_');
        let userIds = userId.split('_');
        if(participantIds.length>1 && userIds.length>1 && participantIds[0]===userIds[0]) {
          return p;
        }
      }
    }
    return _inner.participants.find(p=>p.userId === userId);
  }
  service.getScreenShareById = (userId)=>{
    return service.getParticipantById(userId+service.getScreenShareIdSuffix())
  }
  service.removeParticipantById = (userId)=>{
    if(!userId) return
    const index = _inner.participants.findIndex(p=>p.userId === userId);
    if(index === -1) return
    _inner.participants.splice(index,1);
  }
  service.onParticipantLeaveCall = (userId)=>{
    if(!userId || !service.isParticipantExist(userId) || !service.isOpen()) return
    const arr = [userId,userId+service.getScreenShareIdSuffix()];
    for(let key of arr){
      const participant = service.getParticipantById(key)
      if(participant){
        (participant.step? participant.step.onStop() : Promise.resolve()).then(()=>{},()=>{})
        .finally(async ()=>{
          if(_inner.sendTransports[key]){
              try{ _inner.sendTransports[key].close(); }catch{}
          }
          const producers = _inner.producers[key];
          if(Array.isArray(producers)){
            for(let i = 0 ; i < producers.length ; i++){
              const producer = producers[i];
              try{
                await producer.close();
              }catch(err){
                _inner.debug(err)
              }
            }
          }
          delete _inner.producers[key];
          service.removeParticipantById(key)
          delete _inner.consentRecording[key]
        })
      }
    }
  }
  service.onStartRecorderService = ()=>{
    return new Promise((resolve,reject)=>{
      if(_inner.isOpen){
        resolve();
        return
      }
      service.httpRequest('startRecord',{
        callId:_inner.callInfo.callId,
        subject:_inner.callInfo.subject,
        description:_inner.callInfo.description,
        start_time:callI.getActiveCall()?.start_time,
        url:service.getURL()
      })
      .then((response)=>{
        if(response.routerRtpCapabilities){
          service.onLoadDevice(response.routerRtpCapabilities).then(()=>{},()=>{})
          .finally(async ()=>{
            _inner.isOpen = true;
            const participants = service.getParticipants();
            for(let i  = 0 ; i < participants.length ; i++){
              const participant = participants[i];
              if(participant.is_host){
                service.setConsentRecording(participant.mre_call_participant_id,true)
              }
              service.onParticipantCanBeRecorded(participant)
              .then(p=>{
                participant.ready = true;
                p.onStartRecording().then(()=>{},()=>{})
              },()=>{
                participant.ready = false;
                service.onRecordParticipantStep(participant.mre_call_participant_id)
                .then(()=>{},()=>{})
              })
            }
            resolve();
          })
        }else{
          _inner.isOpen = false
          service.exec(service.EVENTS.ON_RECORDER_ERROR, 'backend_error');
          reject();
        }
      },(err)=>{
        _inner.isOpen = false;
        service.exec(service.EVENTS.ON_RECORDER_ERROR, 'backend_error');
        reject(err)
      })
    })
  }
  service.onStopRecorderService = (leaving=false)=>{
    return new Promise(async (resolve,reject)=>{
      if(!_inner.isOpen){
        resolve();
        return
      }
      for(let i = 0 ; i < _inner.participants.length; i++){
        try{
          await _inner.participants[i].step?.onStop(leaving,true);
        }catch(err){
          _inner.debug(err)
        }
      }
      if(leaving){
        for(let id in _inner.sendTransports){
          try{
            await _inner.sendTransports[id].close()
          }catch{}
        }
      }
      service.httpRequest('stopRecord',{
        callId:service.getCallId(),
        leaving:leaving?true:false
        }).then(()=>{},()=>{})
        .finally(()=>{
        _inner.isOpen = false;
        _inner.consentRecording = {}
        service.send({
          event:"record",
          action:service.EVENTS.ON_RECORDER_CLOSE
        })
        resolve();
        });
    })
  }
  service.updateConnections = (connections)=>{
    if(!connections) return
    _inner.connections = connections;
  }
  service.onToggleParticipantRecording = (id,recording)=>{
    return new Promise((resolve,reject)=>{
      reject();
    })
  }
  service.getConnectionById = (id)=>{
    if(!id || !_inner.connections) return
    if(streamI.isScreenShareConnection(id)) {
      for(let k in _inner.connections) {
        if(!streamI.isScreenShareConnection(k))
          continue;
        let ids = id.split('_');
        let kIds = k.split('_');
        if(ids.length>1 && kIds.length>1 && ids[0]===kIds[0]) {
          return _inner.connections[k];
        }
      }
    }
    return _inner.connections[id+'_mix'] || _inner.connections[id+'_video'] || _inner.connections[id];
  }
  service.getRemoteMediaStreamTracks = (id)=>{
    if(!id) return
    let connection = service.getConnectionById(id);
    if(!connection) return
    const audioTrack = connection?.remoteMedia?.getAudioTrack()._internal._mediaStreamTrack;
    const videoTrack = connection?.remoteMedia?.getVideoTrack()._internal._mediaStreamTrack;
    return {
      audioTrack:audioTrack?audioTrack:null,
      videoTrack:videoTrack?videoTrack:null
    }
  }
  service.onPrepareTransports = (userId)=>{
    return new Promise((resolve,reject)=>{
      if(!userId || !service.isRecorderService()){
        reject();
        return
      }
      if(_inner.sendTransports[userId]){
        resolve();
        return
      }
      service.httpRequest('peer',{
        callId:callI.getActiveCallId(),
        userId:userId
      }).then((response)=>{
        _inner.transportInfo[userId] = response.transport;
        service.onLoadDevice(response.routerRtpCapabilities).then(()=>{},()=>{})
        .finally(async ()=>{
          const transportInfo = _inner.transportInfo[userId];
          const sendTransport = await _inner.device.createSendTransport(transportInfo);
          sendTransport.on('connect',({dtlsParameters},callback,errback)=>{
            const payload = {
                  sessionId:transportInfo?.sessionId,
                  transportId:transportInfo?.id,
                  dtlsParameters:dtlsParameters
                }
            service.httpRequest('connect',payload).then((response)=>{ callback(response.mid); }).catch(()=>{ errback(); })
          })
          sendTransport.on('connectionstatechange',(state)=>{})
          sendTransport.on("produce",({ kind, rtpParameters },callback,errback) => {
                const payload = {
                      sessionId : transportInfo?.sessionId,
                      transportId : transportInfo?.id,
                      kind : kind,
                      rtpParameters : rtpParameters
                    }
                service.httpRequest('produce',payload).then((response)=>{ callback(response.mid); }).catch(()=>{ errback(); })
          })
          _inner.sendTransports[userId] = sendTransport;
          resolve();
        })
      },reject)
    })
  }
  service.onParticipantCanBeRecorded = (participant)=>{
    return new Promise((resolve,reject)=>{
      if(!participant || !service.isRecorderService()){
        reject();
        return
      }
      service.onPrepareTransports(participant.mre_call_participant_id).then(()=>{},()=>{})
      .finally(()=>{
        const userId = participant.mre_call_participant_id;
        let _participant = service.getParticipantById(userId)
        if(_participant){
          if(service.isConsentRecording(_participant.userId)){
            resolve(_participant)
            return
          }
          reject()
          return
        }
        _inner.participants.push(
          new MreCallParticipant({
          userId:userId,
          username:participant.name,
          is_host:participant?.is_host,
          avatar:participant.avatar_url
        }));
        if(!service.getConnectionById[userId]){
          reject();
          return
        }
        if(service.isConsentRecording(userId)){
          resolve(service.getParticipantById(userId))
          return
        }
        reject();
      })
    })
  }
  service.onScreenShareCanBeRecorded = (connectionId)=>{
    return new Promise((resolve,reject)=>{
      if(!connectionId || !service.isRecorderService()){
        reject();
        return
      }
      const participant = service.getParticipantById(connectionId.replace(/_screen_share|_video|_mix/g,''));
      if(!participant) {
        reject();
        return
      }
      const screenShare = {
        userId:participant.userId+service.getScreenShareIdSuffix(),
        username:participant.username,
        avatar:participant.avatar,
        is_host:participant.is_host
      }
      if(!service.getParticipantById(screenShare.userId)){
        _inner.participants.push(new MreCallParticipant(screenShare))
      }
      const isConsentRecording = service.isConsentRecording(participant.userId);
      if(!isConsentRecording){
        reject();
        return
      }
      let p = service.getScreenShareById(participant.userId);
      if(p)
        resolve(p);
      else
        reject();
    })
  }
  _inner.debug = function(){
    if(!DEV) return
    console.log(...arguments);
  }
  service.onPauseParticipant = (id)=>{
      return new Promise((resolve,reject)=>{
        if(!service.isOpen() || !id) {
          reject();
          return
        }
        const _participant = service.getParticipantById(id);
        const _screenShare = service.getParticipantById(id+_inner.screenShareIdSuffix);
        if(!_participant){
          reject();
          return
        }
        const arr = [_participant]
        if(_screenShare) {arr.push(_screenShare)}
        for(let i = 0 ; i < arr.length ; i++){
          const participant = arr[i];
          participant.paused = true;
          participant.resumed = false;
          (participant.step ? participant.step.onStop() : Promise.resolve()).then(()=>{},()=>{})
          .finally(()=>{
            if(!participant.isScreenShare){
              participant.step = new MreCallParticipantStep(_participant);
              participant.type = "avatar";
              participant.step.onStart("avatar").then(()=>{},()=>{})
              .finally(()=>{
                participant.paused = false;
                participant.resumed = false;
                service.setConsentRecording(participant.userId,false)
              })
            }
          })
        }
        resolve();
      })
  }
  service.onResumeParticipant = (id)=>{
    return new Promise((resolve,reject)=>{
      if(!service.isOpen() || !id){
        reject();
        return
      }
      const _participant = service.getParticipantById(id);
      const _screenShare = service.getScreenShareById(id);
      if(!_participant){
        reject();
        return
      }
      const arr = [_participant];
      if(_screenShare){arr.push(_screenShare)}
      for(let i = 0 ; i < arr.length ; i++){
        const participant = arr[i];
        participant.paused = false;
        participant.resumed = true;
        participant.onStartRecording().then(()=>{},()=>{})
        .finally(()=>{
          participant.paused = false;
          participant.resumed = false;
        })
      }
      resolve();
    })
  }
  service.onRecordParticipantStep=(id)=>{
    return new Promise((resolve,reject)=>{
      if(!id){
        reject();
        return
      }
      const participant = service.getParticipantById(id);
      if(!participant || (participant && participant.isScreenShare)){
        reject();
        return
      }
      participant.step = new MreCallParticipantStep(participant);
      participant.type = "avatar";
      participant.step.onStart("avatar").then(()=>{},()=>{})
      .finally(resolve)
    })
  }
  service.isConsentRecording = (id)=> id && _inner.consentRecording[id] === true ? true : false;
  service.setConsentRecording = (id,value)=>{
    if(!id || typeof value !== "boolean") return
    _inner.consentRecording[id] = value;
    if(id.indexOf(service.getScreenShareIdSuffix()) === -1){
      _inner.consentRecording[id+service.getScreenShareIdSuffix()] = value;
    }
  }
  service.canPauseParticipantStream = (participant)=>{
    if(!participant) return
    return participant.step && ['mix','video','audio'].indexOf(participant.step.type) > -1 ? true : false;
  }
  service.getScreenShareIdSuffix = ()=> _inner.screenShareIdSuffix;
  service.onParticipantBecameHost = (userId)=>{
    return new Promise((resolve,reject)=>{
      if(!userId || !service.isRecorderService()){
        reject();
        return
      }
      service.httpRequest('promote',{
        callId:callI.getActiveCallId(),
        userId:userId
      }).then(resolve,reject)
    })
  }
  service.isHost = ()=>{
    return callI.isActiveCallByHost();
  }
  service.setIsRecorderService = (value)=>{
    _inner.isRecorderService = +value === 1 ? true : false;
    _inner.notifyUI();
  }
  service.setIsRady = (value)=>{
    _inner.isReady = +value === 1 ? true : false;
    _inner.isOpen = +value === 1 ? true : false;
    _inner.notifyUI();
  }
  service.setIsOpen = (value)=>{
    _inner.isOpen = +value === 1 ? true : false;
    _inner.notifyUI();
  }
  service.isReady = ()=> _inner.isReady;
  service.isRecorderService = ()=> _inner.isRecorderService;
  service.send = (message)=>{}
  service.getCallId = ()=> _inner.callInfo.callId;
  service.getParticipants = ()=>{
    const participants = participantI.getActiveCallParticipants();
    let result = [];
    for(let i = 0 ; i < participants.length ; i++){
      const participant = participants[i];
      result.push(participant);
      const mre_call_participant_id_screen_share = participant.mre_call_participant_id+_inner.screenShareIdSuffix;
      if(service.getConnectionById[mre_call_participant_id_screen_share]){
        const screenShare = {
          mre_call_participant_id:mre_call_participant_id_screen_share,
          name:participant.name,
          is_host:participant.is_host,
          avatar_url:avatar_url
        }
        result.push(screenShare)
      }
    }
    return result;
  }
  service.isHostParticipantExist = ()=>{
    return participantI.getActiveCallParticipants().find(p=>p.is_host);
  }
  service.onGetRecordStatus = ()=>{
    return new Promise((resolve,reject)=>{
      service.httpRequest('status',{callId:callI.getActiveCallId()})
      .then(response=>{
        if(response && typeof response === "object" && response.status){
          const {ready,consentRecording} = response.status;
          if(typeof consentRecording === "object"){
            for(let key in consentRecording){
              service.setConsentRecording(key,consentRecording[key])
            }
          }
        }
      },()=>{})
      .finally(()=>{
        resolve()
      })
    })
  }
  service.isRecorderServiceParticipantExist=()=>{
    return participantI.getActiveCallParticipants().find(p=>p.name === participantI.getInternalParticipantName()) ? true : false;
  }
  if(DEV){
    service.getInner = ()=> _inner;
    window.recorderI = service;
  }
  service.runRecordIfNeeded = () => {
    if(callI.isActiveCallRecording() && !service.isOpen() && !service.isRecorderService()){
      service.onGetRecordStatus().then(()=>{},()=>{})
      .finally(async ()=>{
        if(service.isHost()){
          service.onConsentRecording(true).then(()=>{},()=>{});
          if(service.isRecorderServiceParticipantExist()){
            service.exec(service.EVENTS.ON_RECORDER_OPEN);
          }else{
            let recorder = service.getUIBind();
            recorder.toggle();
          }
          setTimeout(()=>{
              streamI.removeToggleButtons();
              let elements = streamI.getElements();
              if(elements)
                for(let id in elements)
                  streamI.renderToggleButtons(elements[id]["div"], id, true);
          })
         }else{
           if(service.isHostParticipantExist() || participantI.getActiveCallParticipants().length > 1) {
            modalI.onDisplayRecordingConfirmationModal()
            .then((consentRecording)=>{
              service.onConsentRecording(consentRecording).then(()=>{},()=>{});
              _inner.recorderState.isUserBeingRecorded = consentRecording;
              if(consentRecording){
                service.exec(service.EVENTS.ON_RECORDER_OPEN);
              }
            }, ()=>{
              _inner.recorderState.isUserBeingRecorded = false;
            });
          } else {
            service.onRequestStartRecorderService().then(()=>{},()=>{})
          }
         }
      });
    }
  }
  service.initRecorderService = () => {
    if(!service.isRecorderService()) return
    streamI.onStopSpeaker().then(()=>{}, ()=>{});
    service.onStartRecorderService().then(()=>{
      service.send({
        event: "record",
        action: service.EVENTS.ON_RECORDER_OPEN
      })
    },()=>{
      service.send({
        event: "record",
        action: service.EVENTS.ON_RECORDER_ERROR
      })
    })
  }
  service.stopRecorderService = (leaving=false) => {
    if(!service.isRecorderService()) return
    service.onStopRecorderService(leaving).then(()=>{},()=>{})
    .finally(()=>{
      setTimeout(()=>{
        if(typeof window["stopRecorderService"] === "function" && leaving){
          window["stopRecorderService"](service.getCallId())
        }
      },50)
    })
  }
  service.onStopRecorder = ()=>{
    return new Promise((resolve, reject)=>{
      if(_inner.recorderState.isUserBeingRecorded && service.isOpen()){
        service.exec(service.EVENTS.ON_RECORDER_CLOSE)
      }
      resolve();
    });
  }
  return service;
};
export const recorderI = RecorderI();