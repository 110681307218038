import { busI } from "src/assets/js/busI";
import '@interactjs/auto-start'
import '@interactjs/actions/drag'
import '@interactjs/actions/resize'
import '@interactjs/modifiers'
import '@interactjs/dev-tools'
import interact from '@interactjs/interact'
import { micI } from "./micI";
import { screenI } from "./screenI";
import { videoShareI } from "./videoShare";
import { endpointI } from "./endpointI";
import { streamI } from "./streamI";
import { toastI } from "./toastI";
import { userI } from "./userI";
import { callI } from "./callI";
import { langI } from "./langI";
import { ttsI } from "./ttsI";
import {serviceProviderI} from "./serviceProviderI";
import { networkI } from "./networkI";
import { utilI } from "./utilI";
import { participantI } from "./participantI";
import { speakerI } from "./speakerI";
import { sipI } from "./sipI";
import { mediaI } from "./mediaI";
import { livekitI } from "./livekitI";

let TranscriptionI = function () {
    let service = {};
    let _inner = {};
    _inner.instances = {};
    _inner.activeParticipantsLanguages = null;
    _inner.isActive = false;
    _inner.isOpen = false;
    _inner.isPinOpen = false;
    _inner.container = null;
    _inner.interactInstance = null;
    _inner.transcriptionLinesContainer = null;
    _inner.callTranscriptions = {};
    _inner.DARK_MODE_THEME_KEY = 'transcription-dark-mode';
    _inner.FONT_SIZE_CONTAINER = 'transcription-font-size';
    _inner.STORAGE_SELECTED_LAYOUT_KEY = 'transcription-selected-layout-mode';
    _inner.FULL_SCREEN_ELEMENT_ID = "fullscreen-transcription-lines-container"

    _inner.createWsURL = function(obj) {
        let url = "";
        if (_inner.endpoint == "alpha") {
            url = "wss://alpha-rest-api.mresence.com/api/stt/v2/";
        } else if (_inner.endpoint == "dev") {
            url = "wss://dev-rest-api.mresence.com/api/stt/v2/";
        } else {
            url = "ws://localhost:8000/api/stt/v2/";
        }
        url += _inner.mreCallId + "/" + _inner.mreParticipantId;
        let params = [];
        if(obj) {
          if(obj.sampleRate)
            params.push("sample_rate=" + obj.sampleRate);
          if(obj.encoding)
            params.push("encoding=" + obj.encoding);
          if(obj.punctuate)
            params.push("punctuate=" + obj.punctuate);
          if(obj.lang)
            params.push("language=" + obj.lang);
          if(obj.interim)
            params.push("interim_results=true");
          if(obj.isVideo && +obj.isVideo===1)
            params.push("isvideotranscription=1")
        }
        if(params.length>0)
            url += ('?' + params.join('&'));
        return url;
    }
    _inner.initWS = function(instance) {
        if (!instance || !instance.socketURL) return;
        if(+instance.attempts>=3) {
            return;
        }
        instance.socket = new WebSocket(instance.socketURL, ['Authorization', userI.getAccessToken()]);
        instance.socket.onmessage = (wsdata) => {
            if (wsdata && wsdata.data) {
                try {
                    let results = JSON.parse(wsdata.data);
                    if (results.channel) {
                        let obj = {
                            CustomID: results.CustomID,
                            IsFinal: results.is_final,
                            Transcription: results.channel.alternatives[0].transcript,
                            IsVideoTranscription: !instance.isMic? true: false
                        };
                        service.onTranscriptionMessageSent(obj);
                    }
                } catch (error) {
                }
            }
        };
        instance.socket.onerror = (error)=>{
            //TODO handle error
        }
        instance.socket.onclose = (res)=>{
            //TODO handle close
            if(!res.wasClean) {
                if(!instance.attempts)
                    instance.attempts = 1;
                instance.attempts++;
            }
        }
    }
    service.setIsOpen = function(isOpen) {
        _inner.isOpen = +isOpen===1? true: false;
        if(!_inner.isOpen)
            _inner.container = null;
        service.initFullScreenContainer();
        if(_inner.fullscreenTranscriptionContainer)
            _inner.fullscreenTranscriptionContainer.style.display = _inner.isOpen && screenI.isOnFullScreen()? 'flex': 'none';
    }
    service.isOpen = function() {
        return _inner.isOpen? true: false;
    }
    service.activate = function() {
        _inner.isActive = true;
    }
    service.deactivate = function() {
        _inner.isActive = false
    }
    service.isActive = function() {
        return _inner.isActive || service.hasInstanceRunning()? true: false;
    }
    service.hasInstanceRunning = function() {
        for(let i in _inner.instances)
            if(service.isInstanceActiveById(i))
                return true;
        return false;
    }
    service.getInstances = function() {
        return _inner.instances;
    }
    service.hasAnyActiveParticipantEnabled = function() {
        let participants = participantI.getParticipantsByCallId(callI.getActiveCallId());
        let enable = false;
        if(Array.isArray(participants) && participants.length>0) {
            for(let i=0; i<participants.length; i++) {
                let participant = participants[i];
                if(!participant.is_active)
                    continue;
                //no need to take into consideration sip participant, since he's just emitting and shouldn't cause to enable transcription for other participants
                if(participant.is_sip)
                    continue;
                if(participant.has_sts_active || participant.has_stt_active) {
                    enable = true;
                    break;
                }
            }
        }
        return enable;
    }
    service.updateParticipantVolumeBasedOnSTS = function(p) {
        if(!p || (
            callI.isSpeechToSpeechEnabled() &&
            (
                p.language_id === participantI.getCurrentUserLanguageId(callI.getActiveParticipantId())
            ||
                langI.areLanguageInExceptionForSTS(p.language_id, participantI.getCurrentUserLanguageId(callI.getActiveParticipantId()))
            )))
            return;
        livekitI.getOrSetParticipantVolume(p.mre_call_participant_id, callI.isSpeechToSpeechEnabled()? 0.1: 1);
    }
    service.updateStateBaseOnParticipants = function() {
        if(!callI.getActiveCallId())
            return;
        clearTimeout(_inner.updateStateTimer);
        _inner.updateStateTimer = setTimeout(()=>{
            let enable = service.hasAnyActiveParticipantEnabled();
            if(service.isActive() && !enable) {
                service.stop();
            } else if(enable) {
                setTimeout(()=>{
                    let participants = participantI.getParticipantsByCallId(callI.getActiveCallId());
                    participants = Array.isArray(participants)? participants: [];
                    if(participants.length>0) {
                        if(!_inner.activeParticipantsLanguages)
                            _inner.activeParticipantsLanguages = {};
                        let reconnect = false;
                        for(let i=0; i<participants.length; i++) {
                            let p = participants[i];
                            if(!p)
                                continue;
                            let has_active = p.has_sts_active || p.has_stt_active? true: false;
                            if(p.is_sip && !has_active) {
                                service.stopInstance(service.getInstanceById(p.mre_call_participant_id));
                            }
                            if(!reconnect && has_active) {
                                if(!_inner.activeParticipantsLanguages[p.mre_call_participant_id]
                                    ||
                                    _inner.activeParticipantsLanguages[p.mre_call_participant_id] !== p.language_id)
                                    reconnect = true;
                            }
                            if(_inner.activeParticipantsLanguages[p.mre_call_participant_id])
                                delete _inner.activeParticipantsLanguages[p.mre_call_participant_id];
                            if(has_active) {
                                _inner.activeParticipantsLanguages[p.mre_call_participant_id] = p.language_id;
                            }
                        }
                        //check if needs to reconnect or restart
                        if(reconnect || (service.shouldRun() && service.canTranscribe())) {
                            service.onRestartTranscription().then(()=>{}, ()=>{});
                        }
                    } else {
                        _inner.activeParticipantsLanguages = null;
                    }
                });
            }
        }, 250);
    }
    service.updateTranscriptionStateBasedOnCurrentParticipant = function(res) {
        if(!res)
            return;
        clearTimeout(_inner.updateParticipantTranscriptionStateTimer);
        _inner.updateParticipantTranscriptionStateTimer = setTimeout(()=>{
            let call = res.MreCall? res.MreCall: callI.getActiveCall();
            let participant = res.MreCallParticipant? res.MreCallParticipant: callI.getActiveParticipant();
            if((!call || call.mre_call_id!==callI.getActiveCallId()) || (!participant || participant.mre_call_participant_id!==callI.getActiveParticipantId()))
                return;
            if(call.has_note_enabled) {
                if(!participant.has_stt_active && participant.mre_call_participant_id == callI.getActiveParticipantId()) {
                    setTimeout(()=>{
                        participantI.onUpdateTranscription({callId: call.mre_call_id, participantId: callI.getActiveParticipantId(), isActive: true}).then(()=>{}, ()=>{});
                    }, 150);
                }
            } else {
                if(participant.has_stt_active && !transcriptionI.isOpen() && participant.mre_call_participant_id == callI.getActiveParticipantId()) {
                    setTimeout(()=>{
                        participantI.onUpdateTranscription({callId: call.mre_call_id, participantId: callI.getActiveParticipantId(), isActive: false}).then(()=>{}, ()=>{});
                    }, 150);
                }
            }
        }, 1500);
    }
    service.shouldRun = function() {
        return (_inner.isOpen||service.hasAnyActiveParticipantEnabled()) && !service.isActive()? true: false;
    }
    service.shouldRunInstanceById = function(id) {
        return (_inner.isOpen||service.hasAnyActiveParticipantEnabled()) && !service.isInstanceActiveById(id)? true: false;
    }
    service.isInstanceActiveById = function(id) {
        if(!id || !_inner.instances[id])
            return;
        return _inner.instances[id].socket? true: false;
    }
    service.getInstanceById = function(id) {
        if(!id || !_inner.instances[id])
            return;
        return _inner.instances[id];
    }
    _inner.clearInstance = function(instance) {
        if(!instance)
            return;
        instance.socket = null;
        instance.pcmWorker = null;
        instance.audioStream = null;
        instance.audioContext = null;
        if(instance.id)
            delete _inner.instances[instance.id];
    }
    service.stopInstance = function(instance) {
        if(!instance)
            return;
        try {
            if (instance.socket) {
                if(instance.socket.OPEN)
                    instance.socket.send(new Uint8Array(0));
                instance.socket.close();
            }
            if (instance.pcmWorker) {
                instance.pcmWorker.disconnect();
            }
            if (instance.audioStream) {
                instance.audioStream.disconnect()
            }
            _inner.clearInstance(instance);
            if(!service.hasInstanceRunning())
                service.deactivate();
        } catch (error) {
            _inner.clearInstance(instance);
        }
    }
    service.stopMicInstances = function() {
        for(let i in _inner.instances) {
            if(_inner.instances[i].isMic)
                service.stopInstance(_inner.instances[i]);
        }
    }
    service.restartMicInstances = function() {
        for(let i in _inner.instances) {
            if(_inner.instances[i].isMic)
                service.stopInstance(_inner.instances[i]);
        }
        setTimeout(()=>{
            _inner.start();
        }, 500);
    }
    service.stopAudioTTS = function() {
        if(!_inner.ttsAudio || typeof _inner.ttsAudio.pause !== 'function')
            return;
        _inner.isPlaying = false;
        _inner.ttsAudio.pause();
        _inner.ttsAudio.currentTime = 0;
        setTimeout(()=>{
            if(_inner.ttsAudio)
                _inner.ttsAudio.src = '';
            _inner.ttsAudio = null;
        });
    }
    service.stop = function() {
        service.stopAudioTTS();
        _inner.speechRequests = [];
        _inner.speechToPlay = {};
        busI.unregisterEvent(busI.EVENTS.TRANSCRIPTION_MESSAGE_SENT, service.onTranscriptionMessageSent);
        clearTimeout(_inner.reconnectSocketTimer);
        for(let i in _inner.instances) {
            service.stopInstance(_inner.instances[i]);
        }
        service.deactivate();
    }
    service.hasVideoToTranscribe = function() {
        return videoShareI.isActive() && videoShareI.isOwner()? true: false;
    }
    service.hasSipParticipantsToTranscribe = function() {
        let arr = sipI.getConnectedSipCalls();
        return Array.isArray(arr) && arr.length>0 && arr.filter(item=> item.sipParticipant && !service.isInstanceActiveById(item.sipParticipant.mre_call_participant_id)).length>0? true: false;
    }
    service.canTranscribe = function() {
        return service.hasVideoToTranscribe() || micI.isEnabled() || service.hasSipParticipantsToTranscribe()? true: false;
    }
    _inner.start = function() {
        clearTimeout(_inner.startTranscriptionTimer);
        _inner.startTranscriptionTimer = setTimeout(()=>{
            let transcriptions = [];
            if(service.hasVideoToTranscribe() && !service.isInstanceActiveById(videoShareI.getId())) {
                let obj = {};
                let player = videoShareI.getPlayerById(videoShareI.getSharedFileId()) || videoShareI.getPlayerById(videoShareI.getSharedVideoId());
                if(!player)
                    return;
                obj.lang = player.selectedLanguageCode? player.selectedLanguageCode: participantI.getCurrentUserLanguageCode();
                let sharedVideoContainer = document.getElementById(player.id);
                if(sharedVideoContainer && langI.isLanguageHasTTS(langI.getUserLanguageIdByCode(obj.lang))) {
                    let videoContainer = sharedVideoContainer.querySelector('video');
                    try {
                        if(player.isYoutubePlayer())
                            throw '';
                        videoContainer.setAttribute('crossOrigin', 'anonymous');
                        let stream = player.stream? player.stream: null;
                        if(stream) {
                            if(!stream.active) {
                                videoShareI.reshareStreamFileForTranscription(videoShareI.getSharedFileId());
                                return;
                            }
                        }
                        if(!stream) {
                            stream = videoContainer.captureStream();
                        }
                        if(!stream)
                            throw '';
                        let audioTracks = stream.getAudioTracks();
                        let audioTrack = audioTracks[0];
                        let constraint = audioTrack.getConstraints();
                        obj['stream'] = stream;
                        obj['constraint'] = constraint;
                        obj['constraint'] = {sampleSize: 16, channelCount: transcriptions.length++, echoCancellation: true, sampleRate: 16000}
                        obj['id'] = player.id;
                        obj['isVideo'] = true;
                        obj['isMic'] = false;
                        transcriptions.push(obj);
                    } catch (error) {
                        if(!videoShareI.isPlayerOnError(player.id))
                            toastI.warning(langI.get('TRANSCRIPTION_WARN_VIDEO'));
                        videoShareI.setPlayerOnError(player.id);
                    }
                }
            }
            if(micI.isEnabled() && !service.isInstanceActiveById(micI.getSelectedDeviceId())
            && langI.isLanguageHasTTS(participantI.getCurrentUserLanguageId())) {

                service.stopMicInstances();

                let audioTrack = micI.getTrack();
                if(audioTrack) {
                    let deviceId = micI.getSelectedDeviceId();
                    const audioConstraint = {
                        ...mediaI.audioOptions,
                        sampleRate:16000,
                        sampleSize:16,
                        deviceId:deviceId
                    }
                    if(audioConstraint.sampleSize>0)
                    audioConstraint.sampleRate = audioConstraint.sampleSize*1000;
                    let obj = {};
                    obj['id'] = deviceId;
                    obj['constraint'] = audioConstraint;
                    obj['stream'] = micI.getStream();
                    obj['isMic'] = true;
                    transcriptions.push(obj);
                }
            }
            //handle sip participants
            if(callI.isActiveCallByHost() && service.hasSipParticipantsToTranscribe()) {
                let sipCalls = sipI.getConnectedSipCalls() || [];
                for(let i=0; i<sipCalls.length; i++) {
                    let sipCall = sipCalls[i];
                    if(!sipCall || !sipCall.sipParticipant)
                        continue;
                    let participant = participantI.getParticipantById(sipCall.sipParticipant.mre_call_participant_id);
                    if(!participant || !participant.has_stt_active)
                        continue;
                    let id = sipCall.sipParticipant.mre_call_participant_id;
                    let obj = {};
                    if(service.isInstanceActiveById(id))
                        continue;
                    obj['id'] = id;
                    obj['constraint'] = {sampleSize: 16, channelCount: transcriptions.length++, echoCancellation: true, sampleRate: 16000};
                    //TMP WORK - check media stream track here for sip participant
                    obj['stream'] = livekitI.getParticipantAudioMediaStream(id);
                    if(!obj['stream'])
                        continue;
                    obj['isMic'] = false;
                    obj['lang'] = langI.getUserLanguageCodeById(participant? participant.language_id: null);
                    obj['participantId'] = sipCall.sipParticipant.mre_call_participant_id;
                    transcriptions.push(obj);
                }
            }

            if(transcriptions.length>0) {
                service.handleTranscriptionContainer();

                for(let i=0; i<transcriptions.length; i++){
                    let obj = transcriptions[i];
                    if(!obj || !obj.id)
                        continue;
                    service.onStartTranscription({
                        id: obj.id,
                        stream: obj.stream,
                        isMic: obj.isMic,
                        isVideo: obj.isVideo,
                        audioConstraint: obj.constraint,
                        mreCallId: callI.getActiveCallId(),
                        mreParticipantId: obj.participantId? obj.participantId: callI.getActiveParticipantId(),
                        accessToken: userI.getAccessToken(), endpoint: endpointI.getSelectedEndpointName(),
                        lang: obj.lang? obj.lang: participantI.getCurrentUserLanguageCode(callI.getActiveParticipantId())})
                    .then(()=>{
                        service.activate();
                        busI.unregisterEvent(busI.EVENTS.TRANSCRIPTION_MESSAGE_SENT, service.onTranscriptionMessageSent);
                        busI.registerEvent(busI.EVENTS.TRANSCRIPTION_MESSAGE_SENT, service.onTranscriptionMessageSent);
                    }, ()=>{
                        toastI.error(langI.get('TRANSCRIPTION_ERROR'));
                    });
                }
            }
        }, 50);
    }
    service.onStartTranscription = function(obj) {
        return new Promise(async (resolve, reject) => {
            if(!obj.id || !obj) {
                reject();
                return;
            }
            if(_inner.instances[obj.id]) {
                service.stopInstance(_inner.instances[obj.id]);
            }
            setTimeout(async ()=>{
                _inner.instances[obj.id] = {};
                let instance = _inner.instances[obj.id];
                instance.id = obj.id;
                instance.isMic = obj.isMic? true: false;
                instance.isVideo = obj.isVideo? true: false;
                let constraints = obj.audioConstraint? obj.audioConstraint: null;
                _inner.mreCallId = obj.mreCallId? obj.mreCallId: null;
                _inner.mreParticipantId = obj.mreParticipantId? obj.mreParticipantId: null;
                _inner.accessToken = obj.accessToken? obj.accessToken: null;
                _inner.endpoint = obj.endpoint? obj.endpoint: 'dev';
                instance.socketURL = _inner.createWsURL({encoding: 'linear16', sampleRate: constraints&&constraints.sampleRate? constraints.sampleRate: 0, lang: obj.lang, interim: true, isVideo: !instance.isMic && instance.isVideo? true: false});

                try {
                    let stream = obj.stream;
                    if(stream) {
                        let AudioContext = window.AudioContext || window['webkitAudioContext'];
                        if(navigator.userAgent.indexOf("Firefox")>-1)
                            delete constraints.sampleRate;
                        instance.audioContext = constraints && typeof constraints==='object'? new AudioContext(constraints): new AudioContext();
                        await instance.audioContext.audioWorklet.addModule("./assets/js/pcmWorker.js");
                    }
                    _inner.initWS(instance);
                    if(instance.socket) {
                        if(instance.audioContext) {
                            instance.audioStream = instance.audioContext.createMediaStreamSource(stream);
                            instance.pcmWorker = new AudioWorkletNode(instance.audioContext, "pcm-worker", {
                                outputChannelCount: [1],
                            });
                            const filter = instance.audioContext.createBiquadFilter();
                            const TYPES = {
                                LOW_SHELF:"lowshelf",
                                PEAKING:"peaking"
                            }
                            filter.type = TYPES.PEAKING;
                            instance.pcmWorker = new AudioWorkletNode(instance.audioContext, "pcm-worker", {
                                outputChannelCount: [1],
                            });
                            instance.audioStream.connect(filter);
                            filter.connect(instance.pcmWorker)
                            instance.pcmWorker.port.onmessage = (event) => {
                                if (instance.socket && instance.socket.readyState == instance.socket.OPEN && (!instance.isMic || micI.isEnabled())) {
                                    instance.socket.send(event.data)
                                };
                                if(!callI.getActiveCallId()) {
                                    service.stop();
                                }
                            };
                            instance.pcmWorker.port.start();
                        }
                        _inner.runReconnectTranscriptionSocketHandler();
                        resolve(1);
                    } else
                        reject();
                } catch(e) {
                    reject();
                }
            });
        });
    }

    service.onTranscriptionInstanceStateChanged = function(obj) {
        if(!obj || !obj.id)
            return;

        if(obj.active) {
            if(!service.isActive() && !service.shouldRun() && !service.hasAnyActiveParticipantEnabled())
                return;
            if((!service.getInstanceById(obj.id) || !service.isInstanceActiveById(obj.id)))
                _inner.start();
            return;
        }
        if(service.isInstanceActiveById(obj.id) && !obj.active) {
            service.stopInstance(service.getInstanceById(obj.id));
            return;
        }
    }
    _inner.onResize = function() {
        clearTimeout(_inner.onResizeTimer);
        _inner.onResizeTimer = setTimeout(()=>{
            if(_inner.ui)
                _inner.ui.handleResize();
        }, 50);
    }
    service.clear = function() {
        service.stop();
        clearTimeout(_inner.onResizeTimer);
        window.removeEventListener("resize", _inner.onResize);
        let linesContainer = service.getTranscriptionLinesContainer();
        if(linesContainer)
            linesContainer.removeEventListener('scroll', _inner.syncscrollstatus);
        _inner.isActive = false;
        _inner.isPlaying = false;
        _inner.isOpen = false;
        if(_inner.ui)
            _inner.ui.isOpen = false;
        _inner.isPinOpen = false;
        _inner.ui = null;
        _inner.activeParticipantsLanguages = null;

        _inner.transcriptionHandler = {};
        _inner.transcriptionHandler.lines = [];
        _inner.active_speaker_id = '';
        _inner.transcriptions = new Map();
        _inner.transcriptionLocations = new Map();
        _inner.fullscreenTranscriptionContainer = null;
        _inner.linesContainer = null;
        _inner.linesParentContainer = null;
        _inner.linesParentContainerId = 'transcription-lines-container';
        let container = service.getTranscriptionLinesContainer();
        if(container)
            container.innerHTML = '';
        busI.unregisterEvent(busI.EVENTS.TRANSCRIPTION_INSTANCE_STATE_CHANGED, service.onTranscriptionInstanceStateChanged);
        _inner.container = null;
    }
    service.init = function() {
        service.clear();
        busI.unregisterEvent(busI.EVENTS.TRANSCRIPTION_INSTANCE_STATE_CHANGED, service.onTranscriptionInstanceStateChanged);
        busI.registerEvent(busI.EVENTS.TRANSCRIPTION_INSTANCE_STATE_CHANGED, service.onTranscriptionInstanceStateChanged);

        ttsI.init();

        _inner.notifyUI();
    }
    service.getHandler = function() {
        return _inner.transcriptionHandler;
    }
    service.onTranscriptionReceived = function(payload) {
        if(!payload)
          return;
        let message = payload.MreCallTranscription;
        if(!message)
            return;

        if (!_inner.transcriptionHandler.lines)
            _inner.transcriptionHandler.lines = [];

        const IsVideoTranscription = (payload.IsVideoTranscription || payload.is_video || message.is_video) ? true : false;

        if(IsVideoTranscription && payload.MreCallParticipant)
            payload.MreCallParticipant.name = `${payload.MreCallParticipant.name} (media)`;

        let prefix = IsVideoTranscription? 'video': 'mic';
        let transResult = message.text;
        let senderID = payload.MreCallParticipant.mre_call_participant_id + prefix;
        let senderName = payload.MreCallParticipant.name;
        let isFinal = message.is_finished;
        let transID = message.custom_id + prefix;
        let activeTranscription = _inner.transcriptions.get(transID);
        if(message.Speech) {
            service.sendNotifyLog(message);
        }
        if((message.Speech && callI.isSpeechToSpeechEnabled()) || (langI.isLanguageHasTTS(message.language_id) && callI.isTextToSpeechForTranscriptionEnabled() && message.is_translation && isFinal
            && message.language_id === participantI.getCurrentUserLanguageId(callI.getActiveParticipantId())
            && (message.is_video || payload.MreCallParticipant.mre_call_participant_id!==callI.getActiveParticipantId()))) {
            let speechObj = {id: message.id, lang: langI.getUserLanguageCodeById(message.language_id), participantId: payload.MreCallParticipant.mre_call_participant_id};
            if(!message.Speech)
                speechObj.msg = transResult;
            else
                speechObj.base64 = 'data:audio/wav;base64,' + message.Speech;
            service.appendToSpeechRequests(speechObj);
        }

        if(activeTranscription != null) {
            if (!activeTranscription.IsFinal) {
              activeTranscription.Sender = senderName;
              if (activeTranscription.AddParticipantName) {
                activeTranscription.Content = transResult;
              } else {
                activeTranscription.Content = transResult;
              }
            }
        } else {
            let call = callI.getCallById(payload.MreCallID);
            let start = 0;
            if(call && call.start_time) {
                let sentTime = new Date(message.time_sent);
                let callStartTime = new Date(call.start_time);
                start = Math.round((sentTime.getTime() - callStartTime.getTime()) / 1000);
            }
            let transcription = {
              Sender: "",
              Content: "",
              MreCallParticipantID: senderID,
              Index: start,
              IsFinal: isFinal,
              AddParticipantName: false,
            };
            transcription.Sender = senderName;
            if (_inner.active_speaker_id == "") {
              transcription.Content = transResult;
              transcription.AddParticipantName = true;
            } else if (_inner.active_speaker_id == senderID) {
              transcription.Content = transResult;
            } else {
              transcription.Content = transResult;
              transcription.AddParticipantName = true;
            }
            _inner.active_speaker_id = senderID;
            _inner.transcriptions.set(transID, transcription);
            activeTranscription = transcription;
        }
        let at = _inner.transcriptionLocations.get(transID);
        if(at != null) {
            let l = _inner.transcriptionHandler.lines[at.LineIndex];
            l.messages.set(transID, activeTranscription);
            service.onTranscriptionMessageReceived();
            return;
        }
        let lastElement = _inner.transcriptionHandler.lines.length > 0 ? _inner.transcriptionHandler.lines[_inner.transcriptionHandler.lines.length - 1] : null;
        let handled = false;
        if(lastElement) {
            //keep same line if less than 25s and same participant
            let sameLine = activeTranscription.Index - lastElement.Index < 25 && activeTranscription.MreCallParticipantID === lastElement.MreCallParticipantID ? true : false;
            if (sameLine) {
              // let lastMessage = lastElement.messages[lastElement.messages.length - 1];
              let lastMessage = {};
              lastMessage = Array.from(lastElement.messages.values()).pop();
              if (!lastMessage || lastMessage.Index !== activeTranscription.Index || lastMessage.Content !== activeTranscription.Content) {
                lastElement.messages.set(transID, activeTranscription);
              }
              handled = true;
            }
        }
        if (!handled) {
            _inner.transcriptionHandler.lines.push({
                Sender: activeTranscription.Sender,
                Index: activeTranscription.Index,
                MreCallParticipantID: activeTranscription.MreCallParticipantID,
                messages: new Map().set(transID, activeTranscription),
            });
        }
        _inner.transcriptionLocations.set(transID, {
            LineIndex: _inner.transcriptionHandler.lines.length - 1,
        });
        service.onTranscriptionMessageReceived();
    }

    _inner.runReconnectTranscriptionSocketHandler = function () {
        clearTimeout(_inner.reconnectSocketTimer);
        _inner.reconnectSocketTimer = setTimeout(() => {
          if (service.isActive()) {
            for(let i in _inner.instances) {
                let instance = _inner.instances[i];
                if(!instance.socket)
                    continue;
                if(instance.socket.readyState !== instance.socket.OPEN) _inner.initWS(instance);
            }
            _inner.runReconnectTranscriptionSocketHandler();
          }
        }, 10000);
    };
    service.initContainer = function(container) {
        _inner.fullscreenTranscriptionContainer = null;
        _inner.linesParentContainer = null;
        _inner.linesContainer = null;
        _inner.container = container;
        if(service.canResize())
            service.loadInteractTranscription();
    }
    service.getContainer = function() {
        if(!_inner.container)
            _inner.container = document.getElementById(_inner.ui.containerId);
        return _inner.container;
    }
    service.initFullScreenContainer = function() {
        if(!_inner.fullscreenTranscriptionContainer && !document.getElementById(_inner.FULL_SCREEN_ELEMENT_ID)) {
            let container =  document.createElement('div');
            container.setAttribute('id', _inner.FULL_SCREEN_ELEMENT_ID);
            container.classList.add("transcription-fullscreen");
            let tools = document.createElement("div");
            tools.classList.add("transcription-tools");
            let expand = document.createElement("button");
            expand.innerHTML = '<i class="fas fa-plus"></i>';
            let shrink = document.createElement("button");
            shrink.innerHTML = '<i class="fas fa-minus"></i>';
            [expand,shrink].forEach( button => {
                tools.appendChild(button);
            });
            container.appendChild(tools);

            expand.addEventListener('click', ()=>{
                if (container && container.clientHeight < 220) {
                    container.style.height = (container.clientHeight + 50) + 'px';
                }
            })
            shrink.addEventListener('click', ()=>{
                if (container && container.clientHeight > 62) {
                    container.style.height = (container.clientHeight - 50) + 'px';
                }
            })

            _inner.fullscreenTranscriptionContainer = container;
        }
    }
    service.getFullScreenContainer = function () {
        service.initFullScreenContainer();
        return _inner.fullscreenTranscriptionContainer;
    }

    service.updateTranscriptionLinesParentContainer = function(container) {
        if(!container || container.id !== _inner.linesParentContainerId)
            return;
        _inner.linesParentContainer = container;
        _inner.linesParentContainer.style.overflow = "auto";
        _inner.linesParentContainer.style.marginBottom = '32px';
    }

    service.getTranscriptionLinesParentContainer = function() {
        if(!_inner.linesParentContainer)
            _inner.linesParentContainer = document.getElementById(_inner.linesParentContainerId);
        service.updateTranscriptionLinesParentContainer(_inner.linesParentContainer);
        return _inner.linesParentContainer;
    }
    service.getTranscriptionLinesContainer = function() {
        if(!_inner.linesContainer) {
            let container = service.getTranscriptionLinesParentContainer();
            if(!container)
                return;
            let linesContainer = document.querySelector('.transcription-lines');
            if(!linesContainer)
                return;
            _inner.linesContainer = linesContainer;
        }
        return _inner.linesContainer;
    }

    service.handleTranscriptionContainer = () => {
        if(!_inner.linesParentContainer)
            _inner.linesParentContainer = service.getTranscriptionLinesParentContainer();

        if(!_inner.linesParentContainer)
            return;
        let container = service.getContainer();
        if(!container)
            return;

        service.initFullScreenContainer();

        if(_inner.fullscreenTranscriptionContainer) {
            _inner.fullscreenTranscriptionContainer.style.display = _inner.isOpen && screenI.isOnFullScreen()? 'flex': 'none';
        }
        if(!screenI.isOnFullScreen()) {
            if(container.children && container.children.length>0) {
                for(let i=0; i<container.children.length; i++) {
                    if(container.children[i].id == _inner.linesParentContainerId) {
                        service.updateTranscriptionLinesParentContainer(container.children[i]);
                        container.children[i].remove();
                        break;
                    }
                }
            }
            container.append(_inner.linesParentContainer);
        } else if(screenI.isOnFullScreen()) {
            let el = screenI.getFullScreenElement();
            if(el && el.bottomRowWrapper) {
                if(el.bottomRowWrapper.isSharedVideo) {
                    el.bottomRowWrapper.append(_inner.fullscreenTranscriptionContainer);
                    _inner.fullscreenTranscriptionContainer.style.position = 'absolute';
                    _inner.fullscreenTranscriptionContainer.style.bottom = '0';
                    _inner.fullscreenTranscriptionContainer.style.left = '0';
                    _inner.fullscreenTranscriptionContainer.style.right = '0';
                    _inner.fullscreenTranscriptionContainer.style.zIndex = '2147483647';
                } else {
                    el.bottomRowWrapper.prepend(_inner.fullscreenTranscriptionContainer);
                    _inner.fullscreenTranscriptionContainer.style.position = 'relative';
                    _inner.fullscreenTranscriptionContainer.style.bottom = 'auto';
                }
                if(_inner.fullscreenTranscriptionContainer.children && _inner.fullscreenTranscriptionContainer.children.length>0) {
                    for(let i=0; i<_inner.fullscreenTranscriptionContainer.children.length; i++) {
                        if(_inner.fullscreenTranscriptionContainer.children[i].id == _inner.linesParentContainerId) {
                            service.updateTranscriptionLinesParentContainer(_inner.fullscreenTranscriptionContainer.children[i]);
                            _inner.fullscreenTranscriptionContainer.children[i].remove();
                            break;
                        }
                    }
                }
                _inner.fullscreenTranscriptionContainer.append(_inner.linesParentContainer);
            }
        }
    }

    service.sanitizeContainerPosition = () => {
        if(!service.canResize())
            return;
        clearTimeout(_inner.onSanitizeContainerPositionTimer);
        _inner.onSanitizeContainerPositionTimer = setTimeout(()=>{
            if(!_inner.container || !service.isOpen() || _inner.ui.isDockModeActive)
                return;
            if(!service.canResize())
                return;
            let rect = _inner.container.getBoundingClientRect();
            let edge = rect.top + rect.height + 10;
            if(edge > window.innerHeight) {
                let y = (window.innerHeight * .2) - rect.height;
                if(Math.abs(window.innerHeight - edge)>y)
                    y = window.innerHeight - edge - 10;
                _inner.container.style.transform = `translate(${0}px, ${y}px)`;
            }
        }, 250);
    }

    service.canResize = function() {
        return _inner.ui && _inner.ui.selectedLayoutMode && _inner.ui.selectedLayoutMode.isFloating? true: false;
    }

    service.loadInteractTranscription = () => {
        let position = { x: 0, y: 0 }
        if (_inner.container && !_inner.interactInstance){
            _inner.interactInstance = interact('.draggable')
                .draggable({
                    listeners: {
                        move(event) {
                            if(!_inner.ui || _inner.ui.isDockModeActive)
                                return;
                            position.x += event.dx;
                            position.y += event.dy;
                            _inner.container.style.transform = `translate(${position.x}px, ${position.y}px)`
                        },
                    },
                    inertia: true,
                    modifiers: [
                        interact.modifiers.restrictRect({
                            restriction: document.querySelector('.callpage-wrapper')
                        })
                    ]
                })
            _inner.container.resetPosition = () => {
                position = { x: 0, y: 0 };
                _inner.container.style.transform = `translate(${position.x}px, ${position.y}px)`;
                service.sanitizeContainerPosition();
            }

        }
    }

    service.scrollToBottom = (event) => {
        if(!_inner.ui || !_inner.ui.selectedLayoutMode)
            return;
        let linesContainer = service.getTranscriptionLinesContainer();
        if(!linesContainer)
            return;
        let parentLinesContainer = service.getTranscriptionLinesParentContainer();
        if(!parentLinesContainer)
            return;
        if(!_inner.fullscreenTranscriptionContainer || !screenI.isOnFullScreen()) {
            let container = service.getContainer();
            if(_inner.ui.selectedLayoutMode.isDrawer) {
                container.scrollTop = linesContainer.scrollHeight;
            }
            if(_inner.ui.selectedLayoutMode.isPlaceHolder) {
                parentLinesContainer.scrollTop = linesContainer.scrollHeight;
            }
            if(_inner.ui.selectedLayoutMode.isFloating) {
                linesContainer.scrollTop = linesContainer.scrollHeight;
            }
        } else if(_inner.fullscreenTranscriptionContainer) {
            _inner.fullscreenTranscriptionContainer.scrollTop = linesContainer.scrollHeight;
        }
    }

    service.onTranscriptionMessageReceived = () => {
        if(!service.isOpen())
          return;

        if(_inner.isPinOpen && !screenI.isOnFullScreen())
            return;
        service.scrollToBottom();
    }
    service.onTranscriptionMessageSent = (obj) => {
        if(!obj)
          return;
        let payload = {
          IsTranscription: true,
          MreCallParticipant: callI.getActiveParticipant(),
          Content: obj.Transcription,
          MreCall: callI.getActiveCall(),
          IsFinal: obj.IsFinal,
          CustomID: obj.CustomID
        };
    }
    service.onRestartTranscription = () => {
        return new Promise((resolve, reject)=>{
            service.stop();
            setTimeout(()=>{
                _inner.start();
                resolve();
            });
        });
    }
    service.onRestartInstance = (id) => {
        return new Promise((resolve, reject)=>{
            if(!id) {
                reject();
                return;
            }
            let instance = service.getInstanceById(id);
            if(!instance) {
                reject();
                return;
            }
            service.stopInstance(instance);
            setTimeout(()=>{
                _inner.start();
                resolve();
            }, 500);
        });
    }
    _inner.speechRequests = [];
    _inner.speechToPlay = {};
    service.appendToSpeechRequests = function(obj) {
        if(!obj || !obj.id || (!obj.msg && !obj.base64))
            return;

        let exists = false;
        if(Array.isArray(_inner.speechRequests))
            for(let i=0; i<_inner.speechRequests.length; i++) {
                if(_inner.speechRequests[i].id===obj.id) {
                    exists = true;
                    break
                }
            }

        if(!exists && speakerI.isEnabled()) {
            _inner.speechRequests.push(obj);
            service.onAppendToSpeech(obj)
            .then(()=>{}, ()=>{});
        }
    }
    service.rerunPlaySpeech = function() {
        clearTimeout(_inner.rerunSpeechTimer);
        _inner.rerunSpeechTimer = setTimeout(()=>{
            _inner.isPlaying = false;
            service.playSpeech();
        }, 50);
    }
    service.playSpeech = function() {
        if(!callI.getActiveCallId() || _inner.isPlaying || !Array.isArray(_inner.speechRequests) || _inner.speechRequests.length<1 || !_inner.speechToPlay) {
            return;
        }
        let obj = _inner.speechRequests.shift();
        if(!obj || !_inner.speechToPlay[obj.id] || (!callI.isTextToSpeechForTranscriptionEnabled() && !callI.isSpeechToSpeechEnabled())) {
            return;
        }
        if(!speakerI.isEnabled())
            return;
        obj = _inner.speechToPlay[obj.id];
        _inner.isPlaying = true;
        if(obj.lang && obj.msg && obj.participantId && !obj.base64 && ttsI.isTTSSupported() && ttsI.isLanguageSupported(obj.lang)) {
            ttsI.changeLanguage(obj.lang);
            ttsI.speak(obj.msg, obj.participantId)
            .then(() => {}).catch((error) => { console.log(error) }).finally(()=>{
                service.rerunPlaySpeech();
            });
        } else if(obj.base64 || obj.url) {
            _inner.ttsAudio = new Audio(obj.base64? obj.base64: obj.url);
            _inner.ttsAudio.onended = ()=>{
                service.rerunPlaySpeech();
            }
            _inner.ttsAudio.onsuspend = ()=>{
                service.rerunPlaySpeech();
            }
            _inner.ttsAudio.onstalled = ()=>{
                service.rerunPlaySpeech();
            }
            _inner.ttsAudio.onemptied = ()=>{
                service.rerunPlaySpeech();
            }
            _inner.ttsAudio.onerror = ()=>{
                service.rerunPlaySpeech();
            }
            _inner.ttsAudio.play().then((res) => {}, (error)=>{
                service.rerunPlaySpeech();
            });
        } else {
            service.rerunPlaySpeech();
        }
    }
    service.onAppendToSpeech = function(obj) {
        return new Promise((resolve, reject) => {
            if(!obj || !obj.id || !serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }

            if(obj.base64 || (callI.isTTSSynthesisEnabled() && ttsI.isTTSSupported() && ttsI.isLanguageSupported(obj.lang))) {
                if(!_inner.speechToPlay)
                    _inner.speechToPlay = {};
                if(obj.base64)
                    _inner.speechToPlay[obj.id] = obj;
                service.playSpeech();
                return;
            }

            let payload = {
              MreCallProvider: serviceProviderI.getServiceProviderForApi(),
              MreCallChatID: obj.id
            };
            networkI.post({url: '/api/texttospeech', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                if(!_inner.speechToPlay)
                    _inner.speechToPlay = {};
                if(res.FileBytes)
                    _inner.speechToPlay[obj.id] = {base64: 'data:audio/wav;base64,' + res.FileBytes};
                else if(res.ChatAttachment && res.ChatAttachment.is_text_to_speech && res.ChatAttachment.attachment_url)
                    _inner.speechToPlay[obj.id] = {url: res.ChatAttachment.attachment_url};
                service.playSpeech();
                resolve();
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.isDrawerOpen = () => {
        return _inner.ui && _inner.ui.selectedLayoutMode && _inner.ui.selectedLayoutMode.isDrawer
                && _inner.ui.isDrawerOpen? true: false;
    }
    service.getUIBind = ()=>{
        if(_inner.ui)
            return _inner.ui;
        let streamUI = streamI.getUIBind();
        let layoutModes = [
            {key: 'floating', label: 'Float', description: 'Will be displayed floating anc can be moved around', cssClass: [], containerId: streamUI.callContentContainerId, isFloating: true},
            {key: 'box', label: 'Box', description: 'Will be displayed part of the participants', cssClass: [streamI.getVideoCssClass(), 'max-height-100per'], isPinned: false, containerId: streamUI.mainCallsContainerId, isPlaceHolder: true},
            {key: 'drawer', label: 'Drawer', description: 'Will be displayed as an independent drawer', cssClass: [], containerId: streamUI.transcriptionDrawerContainerId, isDrawer: true},
        ]
        let selectedLayout = null;
        try {
            selectedLayout = utilI.getFromLocalStorage(_inner.STORAGE_SELECTED_LAYOUT_KEY);
            if(selectedLayout) {
                selectedLayout = JSON.parse(selectedLayout);
                if(layoutModes.filter(item=>item.key===selectedLayout.key && (item.cssClass.join() == selectedLayout.cssClass.join()) && item.containerId===selectedLayout.containerId).length<1)
                    selectedLayout = null;
            }
        } catch (error) {
            selectedLayout = null;
        }

        if(!selectedLayout) {
            selectedLayout = !screenI.isSmallScreen()? layoutModes.filter(item=>item.isFloating)[0]: layoutModes.filter(item=>item.isPlaceHolder)[0];
        }
        _inner.ui = {
            containerId: 'transcription-container',
            linesParentContainerId: _inner.linesParentContainerId,
            layoutModes: layoutModes,
            selectedLayoutMode: selectedLayout,
            selectLayoutMode: (mode)=>{
                if(!mode || !mode.key)
                    return;
                if(_inner.ui.selectedLayoutMode && _inner.ui.selectedLayoutMode.key===mode.key)
                    return;
                let wasOpen = service.isOpen();
                _inner.isOpen = false;
                _inner.ui.isOpen = _inner.isOpen;
                _inner.notifyUI();
                _inner.ui.selectedLayoutMode = mode;
                setTimeout(()=>{
                    if(wasOpen) {
                        _inner.isOpen = true;
                        _inner.ui.isOpen = _inner.isOpen;
                        _inner.notifyUI();
                    }
                    if(service.isOpen()) {
                        _inner.ui.handleLayoutUpdate();
                    }
                    try {
                        utilI.updateLocalStorage(_inner.STORAGE_SELECTED_LAYOUT_KEY, JSON.stringify(_inner.ui.selectedLayoutMode));
                    } catch (error) {}
                });
            },
            togglePinPlaceHolder: ()=>{
                if(!_inner.ui.selectedLayoutMode || !_inner.ui.selectedLayoutMode.isPlaceHolder)
                    return;
                _inner.ui.selectedLayoutMode.isPinned = _inner.ui.selectedLayoutMode.isPinned? false: true;
                if(_inner.ui.selectedLayoutMode.isPinned)
                    streamI.pinView(service.getContainer());
                else
                    streamI.unpinView(service.getContainer());
            },
            isDrawerOpen: false,
            closeDrawer: ()=>{
                if(_inner.ui)
                    _inner.ui.isDrawerOpen = false;
                let streamUI = streamI.getUIBind();
                if(streamUI)
                    streamUI.notifyCloseTranscriptionDrawer();
            },
            openDrawer: () => {
                if(_inner.ui)
                    _inner.ui.isDrawerOpen = true;
                let streamUI = streamI.getUIBind();
                if(streamUI)
                    streamUI.notifyOpenTranscriptionDrawer();
            },
            handleLayoutUpdate: () => {
                if(_inner.ui.selectedLayoutMode.isDrawer) {
                    if(!_inner.isOpen)
                        _inner.ui.closeDrawer();
                    else {
                        _inner.ui.openDrawer();
                    }
                }
                if(_inner.ui.selectedLayoutMode.isFloating) {
                    if(_inner.isOpen) {
                        if(!_inner.ui.isDockLeftActive && !_inner.ui.isDockTopActive
                            && !_inner.ui.isDockRightActive && !_inner.ui.isDockBottomActive) {
                                _inner.ui.setDefaultMode();
                        }
                        _inner.ui.updateFontSizeBy(0);
                    }
                }
            },
            handleResize: ()=>{
                if(!_inner.ui || !_inner.ui.selectedLayoutMode || !service.canResize())
                    return;
                let container = service.getContainer();
                if(container) {
                    container.style.maxHeight = window.innerHeight + 'px';
                    container.style.maxWidth = window.innerWidth + 'px';
                    if(!_inner.ui.isDockModeActive)
                        _inner.ui.setDefaultMode();
                }
            },
            setHeight: (height)=>{
                let value = '';
                if(typeof height==='string' && height.indexOf('%')>-1) {
                    value = height;
                } else {
                    height = +height===+height? height: 0;
                    height = height>=200 && height<=window.innerHeight * .8? height: height>window.innerHeight * .8? window.innerHeight * .8: 0;
                    if(!(height>0))
                        height = 200;
                    value = height+'px';
                }
                if(!value)
                    return;
                let container = service.getContainer();
                if (container) {
                    container.style.height = value;
                    container.style.maxHeight = window.innerHeight + 'px';
                }
            },
            setWidth: (width)=>{
                let value = '';
                if(typeof width==='string' && width.indexOf('%')>-1) {
                    value = width;
                } else {
                    width = +width===+width? width: 0;
                    width = width>=200 && width <= window.innerWidth * .9? width: width>window.innerWidth * .9? window.innerWidth * .9: 0;
                    if(!(width>0))
                        width = 200;
                    value = width+'px';
                }
                if(!value)
                    return;
                let container = service.getContainer();
                if(container) {
                    container.style.width = value;
                    container.style.maxWidth = window.innerWidth + 'px';
                }
            },
            isDockLeftActive: false,
            isDockTopActive: false,
            isDockRightActive: false,
            isDockBottomActive: false,
            isOpen: _inner.isOpen,
            isDarkModeActive: +utilI.getFromLocalStorage(_inner.DARK_MODE_THEME_KEY)===1? true: false,
            fontSize: utilI.getFromLocalStorage(_inner.FONT_SIZE_CONTAINER) || 1,
            handler: service.getHandler(),
            toggle: ()=>{
                _inner.isOpen = !_inner.isOpen;
                _inner.ui.update();
                livekitI.updateMetadata();
                if(!_inner.isOpen){
                    if(screenI.isOnFullScreen()){
                        service.clearFullScreenElement();
                    }else if(_inner.ui.selectedLayoutMode.isPlaceHolder){
                        streamI.unpinView(service.getContainer());
                    }
                }
            },
            update: () => {
                service.setIsOpen(_inner.isOpen);
                participantI.onUpdateTranscription({callId: callI.getActiveCallId(), participantId: callI.getActiveParticipantId(), isActive: _inner.isOpen}).then(()=>{}, ()=>{})
                .finally(()=>{
                    service.updateStateBaseOnParticipants();
                    sipI.checkSipParticipantsTranscriptionStateByIds();
                    setTimeout(()=>{
                        service.handleTranscriptionContainer();
                    })
                });
                _inner.notifyUI();
            },
            toggleTheme: ()=>{
                _inner.ui.isDarkModeActive = !_inner.ui.isDarkModeActive;
                utilI.updateLocalStorage(_inner.DARK_MODE_THEME_KEY, _inner.ui.isDarkModeActive? 1: 0);
            },
            updateFontSizeBy: (val)=>{
                setTimeout(()=>{
                    if(+val!==+val)
                        return;
                    let container = service.getContainer();
                    if(container) {
                        if(!_inner.ui.fontSize || +_inner.ui.fontSize!==+_inner.ui.fontSize)
                            _inner.ui.fontSize = 1;
                        else
                            _inner.ui.fontSize = +_inner.ui.fontSize;
                        val = _inner.ui.fontSize + val;
                        if(val>3 || val<.5)
                            return;
                        _inner.ui.fontSize = val;
                        if(typeof _inner.ui.fontSize.toFixed === 'function')
                            _inner.ui.fontSize = _inner.ui.fontSize.toFixed(1);
                        container.style.fontSize = _inner.ui.fontSize+'rem';
                        utilI.updateLocalStorage(_inner.FONT_SIZE_CONTAINER, _inner.ui.fontSize);
                    }
                });
            },
            increaseFont: () => {
                _inner.ui.updateFontSizeBy(0.1);
            },
            decreaseFont: () => {
                _inner.ui.updateFontSizeBy(-0.1);
            },
            initView: ()=>{
                clearTimeout(_inner.initViewTimer);
                _inner.initViewTimer = setTimeout(()=>{
                    if(_inner.ui)
                        _inner.ui.destroyView();
                    let container = service.getContainer();
                    service.initContainer(container);
                    let linesContainer = service.getTranscriptionLinesContainer();
                    if(linesContainer) {
                        linesContainer.removeEventListener('scroll', _inner.syncscrollstatus);
                        if(service.canResize())
                            linesContainer.addEventListener('scroll', _inner.syncscrollstatus);
                    }
                    window.removeEventListener("resize", _inner.onResize);
                    if(service.canResize())
                        window.addEventListener("resize", _inner.onResize);
                    _inner.ui.handleLayoutUpdate();
                    service.runTestMode();
                }, 150);
            },
            destroyView: ()=>{
                _inner.container = null;
                _inner.linesContainer = null;
                _inner.linesParentContainer = null;
                _inner.fullscreenTranscriptionContainer = null;
            },
            setDefaultMode: ()=>{
                _inner.ui.clearDocks();
                _inner.ui.isDockModeActive = false;
                let container = service.getContainer();
                if(container) {
                    container.style.borderRadius = '10px';
                    container.style.left = '0';
                    container.style.right = '0';
                    container.style.margin = 'auto';
                    container.style.top = 'unset';
                    container.style.bottom = 'unset';
                    _inner.ui.setHeight(200);
                    _inner.ui.setWidth(window.innerWidth * .8);
                    container.classList.remove('has-border');
                    if(typeof container.resetPosition === 'function'){
                        container.resetPosition();
                    }
                }
            },
            clearDocks: ()=>{
                _inner.ui.isDockLeftActive = false;
                _inner.ui.isDockTopActive = false;
                _inner.ui.isDockRightActive = false;
                _inner.ui.isDockBottomActive = false;
                _inner.ui.isDockModeActive = false;
            },
            dockLeft: ()=>{
                if(_inner.ui.isDockLeftActive) {
                    _inner.ui.setDefaultMode();
                    return;
                }
                let container = service.getContainer();
                if(container) {
                    if(typeof container.resetPosition==='function')
                        container.resetPosition();
                    container.classList.add('has-border');
                    _inner.ui.setHeight('100%');
                    _inner.ui.setWidth('30%');
                    container.style.borderRadius = '0';
                    container.style.left = '0';
                    container.style.top = '0';
                    container.style.bottom = '0';
                    _inner.ui.clearDocks();
                    _inner.ui.isDockLeftActive = true;
                    _inner.ui.isDockModeActive = true;
                }
            },
            dockTop: ()=>{
                if(_inner.ui.isDockTopActive) {
                    _inner.ui.setDefaultMode();
                    return;
                }
                let container = service.getContainer();
                if(container) {
                    if(typeof container.resetPosition==='function')
                        container.resetPosition();
                    container.classList.add('has-border');
                    _inner.ui.setHeight('20%');
                    _inner.ui.setWidth('100%');
                    container.style.borderRadius = '0';
                    container.style.left = '0';
                    container.style.right = '0';
                    container.style.top = '0';
                    container.style.bottom = 'unset';
                    _inner.ui.clearDocks();
                    _inner.ui.isDockTopActive = true;
                    _inner.ui.isDockModeActive = true;
                }
            },
            dockRight: ()=>{
                if(_inner.ui.isDockRightActive) {
                    _inner.ui.setDefaultMode();
                    return;
                }
                let container = service.getContainer();
                if(container) {
                    if(typeof container.resetPosition==='function')
                        container.resetPosition();
                    container.classList.add('has-border');
                    _inner.ui.setHeight('100%');
                    _inner.ui.setWidth('30%');
                    container.style.borderRadius = '0';
                    container.style.left = 'unset';
                    container.style.right = '0';
                    container.style.top = '0';
                    container.style.bottom = '0';
                    _inner.ui.clearDocks();
                    _inner.ui.isDockRightActive = true;
                    _inner.ui.isDockModeActive = true;
                }
            },
            dockBottom: ()=>{
                if(_inner.ui.isDockBottomActive) {
                    _inner.ui.setDefaultMode();
                    return;
                }
                let container = service.getContainer();
                if(container) {
                    if(typeof container.resetPosition==='function')
                        container.resetPosition();
                    container.classList.add('has-border');
                    _inner.ui.setHeight('20%');
                    _inner.ui.setWidth('100%');
                    container.style.borderRadius = '0';
                    container.style.left = '0';
                    container.style.right = '0';
                    container.style.top = 'unset';
                    container.style.bottom = '0';
                    _inner.ui.clearDocks();
                    _inner.ui.isDockBottomActive = true;
                    _inner.ui.isDockModeActive = true;
                }
            },
            expandVertically: ()=>{
                let container = service.getContainer();
                if (container)
                    _inner.ui.setHeight(container.clientHeight + 50);
            },
            compressVertically: ()=>{
                let container = service.getContainer();
                if (container)
                    _inner.ui.setHeight(container.clientHeight - 50);
            },
            expandHorizontally: ()=>{
                let container = service.getContainer();
                if (container)
                    _inner.ui.setWidth(container.clientWidth + 50);
            },
            compressHorizontally: ()=>{
                let container = service.getContainer();
                if (container)
                    _inner.ui.setWidth(container.clientWidth - 50);
            },
            togglePin: ()=>{
                _inner.isPinOpen = !_inner.isPinOpen;
                _inner.notifyUI();
            }
        };
        return _inner.ui;
    }
    _inner.syncscrollstatus = (event) => {
        clearTimeout(_inner.onScrollContainerTimer);
        _inner.onScrollContainerTimer = setTimeout(()=>{
            if(((parseInt(event.target.scrollHeight) - parseInt(event.target.offsetHeight)) - parseInt(event.target.scrollTop)) < 100) {
                _inner.isPinOpen = false;
            } else {
                _inner.isPinOpen = true;
            }
            _inner.notifyUI();
        }, 150);
    }
    _inner.notifyUI = ()=>{
        if(!_inner.ui) {
            service.getUIBind();
        }
        _inner.ui.isOpen = _inner.isOpen;
        _inner.ui.isPinOpen = _inner.isPinOpen;
    }
    _inner.TEST_MODE = false;
    _inner.TEST_MODE_COUNTER = 0;
    service.runTestMode = ()=>{
        clearTimeout(_inner.testTimer);
        if(!_inner.TEST_MODE || _inner.TEST_MODE_COUNTER>=3)
            return;
        if(service.isOpen()) {
            function onRun() {
                return new Promise((resolve, reject)=>{
                    clearTimeout(_inner.testTimer);
                    _inner.testTimer = setTimeout(()=>{
                        let obj = JSON.parse('{"MreCallID":"a95491c1-58e7-4b96-a055-fbf307c3ae57","MreCallParticipant":{"mre_call_participant_id":"8498c0c2-cee0-4417-aff3-e6ff24088e61","mre_call_id":"a95491c1-58e7-4b96-a055-fbf307c3ae57","account_profile_id":"92d85be8-9d07-4dda-bf3e-211ac53ed2be","role_id":"af7c0f9c-5966-46f2-9c6d-eb1f0e4fe6b2","name":"mouadeco","avatar_url":"https://s3.us-east-2.amazonaws.com/web-resources.mresence.com/default_avatar.svg","last_active_time":"2024-03-01T13:54:10Z","is_accepted":true,"is_active":true,"language_id":"e6bb4f03-7d84-11e9-b97d-02a204316c84","created_on":"2024-03-01T13:46:39Z","became_inactive":"2024-03-01T13:54:05Z","has_stt_active":true,"audio_muted":true,"is_declined":false,"joined":true,"is_in_waiting_room":false,"updated_at":1709301254286},"IsVideoTranscription":true,"MreCallTranscription":{"id":"5903cb9a-b82d-4c12-8745-4c61002db22d","mre_call_participant_id":"8498c0c2-cee0-4417-aff3-e6ff24088e61","mre_call_id":"a95491c1-58e7-4b96-a055-fbf307c3ae57","text":"ever wonder why so many people say they want to learn guitar but very few actually learn to play it\'s simple","time_sent":"2024-03-01T13:54:40.383277Z","language_id":"e6bb4f03-7d84-11e9-b97d-02a204316c84","is_video":true,"custom_id":"b96720f5-0961-47fe-9cb9-8fb775ee165b","is_finished":false,"updated_at":1709301280383,"created_at":1709301280383}}');
                        obj.MreCallID = callI.getActiveCallId();
                        obj.MreCallParticipant = JSON.parse(JSON.stringify(callI.getActiveParticipant()));
                        let total = 50;
                        let arr = [];
                        for(let i=0; i<total; i++) {
                            arr.push(obj);
                        }
                        function pop(arr) {
                            let el = arr.pop();
                            if(!el) {
                                resolve();
                                return;
                            }
                            el.MreCallTranscription.id = arr.length;
                            el.MreCallTranscription.is_finished = true;
                            el.MreCallTranscription.custom_id = arr.length;
                            el.MreCallParticipant.name = callI.getActiveParticipantName();
                            service.onTranscriptionReceived(el);
                            setTimeout(()=>{
                                pop(arr);
                            }, 250);
                        }
                        pop(arr);
                    });
                });
            }
            setTimeout(()=>{
                onRun().then(()=>{}, ()=>{}).finally(()=>{
                    _inner.TEST_MODE_COUNTER++;
                    setTimeout(()=>{
                        service.runTestMode();
                    }, 2500);
                });
            }, 2500);
        }
    }
    service.onEditCallTranscriptionMessage = (callId, messageId, newMessage)=>{
        return new Promise((resolve,reject)=>{
            if(!callId || !messageId || !newMessage){
                reject();
                return
            }
            if(!_inner.callTranscriptions[callId]) {
                reject();
                return;
            }
            let payload = {
                Content:newMessage.trim(),
                MreCallChatID: messageId
            }
            networkI.put({url: `/api/transcription/message/${messageId}`, data: payload})
            .then((res)=>{
                let transcriptions = Array.isArray(res.Transcriptions)? res.transcriptions: res.MreCallTranscription? [res.MreCallTranscription]: null;
                if(transcriptions) {
                    service.updateCallTranscriptions(callId, transcriptions);
                    resolve(res)
                }else{
                    reject(res)
                }
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.updateCallTranscriptions = (id, transcriptions)=>{
        if(!id || !Array.isArray(transcriptions) || transcriptions.length<1) return;
        let userLanguage = participantI.getCurrentUserLanguageName();
        transcriptions = transcriptions.map((item)=>{
          item.user_language_name = userLanguage;
          item.user_message = item.original_language_name==item.user_language_name? item.original_message:
                              item.translated_language_name==item.user_language_name? item.translated_message: '';
          return item;
        });
        if(!_inner.callTranscriptions[id]) {
            _inner.callTranscriptions[id] = {};
            _inner.callTranscriptions[id].transcriptions = {};
        }
        for(let i=0; i<transcriptions.length; i++) {
            let transcription = transcriptions[i];
            if(!transcription.mre_call_transcription_id)
                continue;
            _inner.callTranscriptions[id].transcriptions[transcription.mre_call_transcription_id] = transcription;
        }
    }
    service.getCallTranscriptions = (id) => {
        if(!id || !_inner.callTranscriptions[id] || !_inner.callTranscriptions[id].transcriptions) return [];
        let filtered = [];
        let obj = _inner.callTranscriptions[id].transcriptions;
        for(let k in obj) {
            filtered.push(obj[k]);
        }
        return filtered;
    }
    service.onGetCallTranscriptions = function(id) {
        return new Promise((resolve, reject)=>{
            if(!id) {
                reject();
                return;
            }
            let transcriptions = service.getCallTranscriptions(id);
            if(transcriptions.length>0) {
                resolve(transcriptions);
                return;
            }
            service.onLoadCallTranscriptions(id)
            .then(()=>{
                resolve(service.getCallTranscriptions(id));
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.clearCallTranscriptions = (id)=>{
        if(!id) return
        delete _inner.callTranscriptions[id];
    }
    service.onLoadCallTranscriptions = (id)=>{
        return new Promise((resolve,reject)=>{
            if(!id){
                reject();
                return
            }
            networkI.get({url: `/api/call/${id}/transcription`})
            .then((res)=>{
                if(res && Array.isArray(res.Transcriptions)) {
                    service.updateCallTranscriptions(id, res.Transcriptions);
                    resolve(res.Transcriptions);
                } else {
                    reject();
                }
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.onLogTranscriptionReceived = function(message) {
        return new Promise((resolve, reject)=>{
            if(!message || !message.mre_call_id) {
                reject();
                return;
            }
            let data = {};
            data.id = message.id? message.id: '';
            data.mre_call_participant_id = message.mre_call_participant_id? message.mre_call_participant_id: '';
            data.speech = message.Speech? 'received': '';
            data.language_id = message.language_id? message.language_id: '';
            data.current_user_language_id = participantI.getCurrentUserLanguageId(callI.getActiveParticipantId())
            data.is_s2s_enabled = callI.isSpeechToSpeechEnabled();
            data.text = message.text? message.text: '';
            if(message.is_video)
                data.is_video = 1;
            let payload = {
                Data: JSON.stringify(data),
                MreCallID: message.mre_call_id
            }
            networkI.post({url: `/api/transcription/log`, data: payload})
            .then((res)=>{
                resolve();
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.sendNotifyLog = function(message) {
        setTimeout(()=>{
            service.onLogTranscriptionReceived(message).then(()=>{}, (error)=>{console.log(error)});
        })
    }
    service.clearFullScreenElement = ()=>{
        const element = document.getElementById(_inner.FULL_SCREEN_ELEMENT_ID);
        if(!element) return
        element.parentNode.removeChild(element)
    }
    service.isTranscriptionContainer = (container)=> container instanceof HTMLElement ? (container.id === service.getUIBind().containerId) : false
    return service;
}

export const transcriptionI = TranscriptionI();
