import { utilI } from "./utilI";

let TtsI = function () {
    let service = {};
    let isDEBUG = false;
    let _inner = {
        nativeSpeechSynthesisKey: "tmu.WebApp.nativeSpeechSynthesis",
        defaultVoiceKey: "tmu.WebApp.defaultVoice",
        speechStack: {},
    };

    service.config = {
        nativeSpeechSynthesis: false,
        defaultVoice: null,
        isTTSSupported: ('speechSynthesis' in window)
    }
    service.voices = [];

    service.isTTSSupported = () => {
        return 'speechSynthesis' in window
    }

    service.getVoices = ()=>{
        return new Promise(
            function (resolve, reject) {
                let id;
                id = setInterval(() => {
                    if (speechSynthesis.getVoices().length !== 0) {
                        resolve(speechSynthesis.getVoices());
                        clearInterval(id);
                    }
                }, 10);
            }
        )
    }

    service.init = (language) => { // language got to be in the format 'ln-CT' ex : 'en-US', 'fr-FR'...
        if(isDEBUG) { console.log("**-- INITED", language); }
        setTimeout(()=>{
            if(language){
                service.getVoices().then((res)=>{
                    service.voices = res.filter(voice => voice.lang == language);
                });
            } else {
                service.getVoices().then((res)=>{
                    service.voices = res
                });
            }
        })
        if(utilI.getFromLocalStorage(_inner.nativeSpeechSynthesisKey)) {
            service.config.nativeSpeechSynthesis = utilI.getFromLocalStorage(_inner.nativeSpeechSynthesisKey) == 'true' ? true : false;
        } else {
            utilI.updateLocalStorage(_inner.nativeSpeechSynthesisKey, 'false');
        }
        if(utilI.getFromLocalStorage(_inner.defaultVoiceKey)) {
            if(utilI.getFromLocalStorage(_inner.defaultVoiceKey) == 'default') {
                service.config.defaultVoice = null;
            } else {
                service.getVoices().then((res)=>{
                    service.config.defaultVoice = res.filter(voice => voice.voiceURI == utilI.getFromLocalStorage(_inner.defaultVoiceKey))[0];
                });
            }
        } else {
            utilI.updateLocalStorage(_inner.defaultVoiceKey, 'default');
        }
    }

    service.changeLanguage = (language) => {
        if(isDEBUG) { console.log("**-- CHANGE LANGUAGE", language); }
        if (language) {
            service.voices = speechSynthesis.getVoices().filter(voice => voice.lang == language);
            if (service.voices.length > 0) {
                service.updateNativeVoice(service.voices[0].voiceURI);
                if(isDEBUG) { console.log("**-- CHANGED LANGUAGE", service.config.defaultVoice); }
            } else {
                service.updateNativeVoice('');
                if(isDEBUG) { console.log("**-- CHANGED LANGUAGE", service.config.defaultVoice); }
            }
        }
    }

    service.updateNativeSpeechSynthesis = (newState) => {
        service.config.nativeSpeechSynthesis = newState;
        utilI.updateLocalStorage(_inner.nativeSpeechSynthesisKey, newState);
    }

    service.updateNativeVoice = (voiceUri) => {
        if (speechSynthesis.getVoices().filter(voice => voice.voiceURI == voiceUri).length > 0) {
            service.config.defaultVoice = speechSynthesis.getVoices().filter(voice => voice.voiceURI == voiceUri)[0];
            utilI.updateLocalStorage(_inner.defaultVoiceKey, voiceUri);
        } else {
            service.config.defaultVoice = null;
            utilI.updateLocalStorage(_inner.defaultVoiceKey, '');
        }
    }

    service.previewPlay = () => {
        service.speak("Hello TelemeetUp");
    }
    
    service.status = {
        playing: false,
        paused: false,
        speaking: false,
    }
    service.speak = (msg, participantId) => {
        return new Promise((resolve, reject)=>{
            if(typeof SpeechSynthesisUtterance !== 'function') {
                reject();
                return;
            }
            const message = new SpeechSynthesisUtterance();
            message.volume = 1;
            message.rate = 1;
            message.pitch = 1;
            message.voice = service.config.defaultVoice;
            message.text = msg;
            if(participantId) {
                if(_inner.speechStack[participantId] && _inner.speechStack[participantId].length) _inner.speechStack[participantId].push(message)
                else {
                    _inner.speechStack[participantId] = [message];
                    speechSynthesis.speak(message);
                }
            } else speechSynthesis.speak(message);
            message.onend = ()=> {
                if(participantId){
                    if(_inner.speechStack[participantId] && _inner.speechStack[participantId].length){
                        _inner.speechStack[participantId] = _inner.speechStack[participantId].filter(m => m !== message)
                        if(_inner.speechStack[participantId][0]) speechSynthesis.speak(_inner.speechStack[participantId][0]);
                    }
                }
                resolve();
            };
            message.onerror = (err) =>{
                reject(err);
            };
        });
    }
    service.pause = () => {
        speechSynthesis.pause();
    }
    service.resume = () => {
        speechSynthesis.resume();
    }
    service.stop = () => {
        speechSynthesis.cancel();
    }

    service.isLanguageSupported = (langCode) =>{
        if(!langCode)
            return false;
        if(speechSynthesis.getVoices().filter(voice => voice.lang == langCode).length > 0){
            return true;
        } else {
            return false;
        }
    }

    service.isPaused = () => {return speechSynthesis.paused};
    service.isSpeaking = () => {return speechSynthesis.speaking};

    return service;
}
export const ttsI = TtsI();
