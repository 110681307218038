<div class="modal-wrapper" [attr.data-modalid]="containerId">
    <div class="modal-backdrop"></div>
    <div class="modal-container md-fit min-width-desktop-550">
        <div class="modal-header">
            <span class="heading">Call Recording</span>
            <button class="transparent close-btn">
                <span class="material-icons">close</span>
            </button>
        </div>
        <div class="modal-body md-padding-bottom body-container">
            <div class="flex-col p-4">
                <div class="alert pb-4">
                    <div class="alert-icon-container">
                        <span class="material-icons md-12">info</span>
                    </div>
                    <div class="alert-message-container">
                        <p>This call is being recorded</p>
                    </div>
                </div>
                <div class="flex-row gap-10">
                    <button title="My presence would be recorder without microphone, camera nor screen sharing." class="large transparent" (click)="declineRecording()">Continue without recording</button>
                    <button class="primary large" (click)="consentRecording()" >Accept</button>
                </div>
            </div>
        </div>
    </div>
</div>