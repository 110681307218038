<div class="modal-wrapper" [attr.data-modalid]="containerId" [ngClass]="!isReady ? 'display-none' : ''">
    <div class="modal-backdrop" [ngClass]="!isReady ? 'display-none' : ''"></div>
    <div class="modal-container md-fit">
        <div class="modal-header">
            <span class="heading">Rejoin</span>
            <button class="transparent close-btn">
                <span class="material-icons">close</span>
            </button>
        </div>
        <div class="modal-body md-padding-bottom">
            <div class="flex-col p-4">
                <h3 class="title mb-3">Do you want to rejoin to your last active call?</h3>
                <div class="flex-row gap-10">
                    <button class="large" (click)="dismiss()">Cancel</button>
                    <button class="primary large" (click)="rejoin()" >Yes, rejoin.</button>
                </div>
            </div>
        </div>
    </div>
</div>