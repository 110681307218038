import { serviceProviderI } from "./serviceProviderI"
import { userI } from "./userI"
var PermissionI = function () {
    let _inner = {}
    _inner.mre_call_provider_id = null;
    _inner.ui = {};
    _inner.updateUI = (payload)=>{
        if(typeof payload !== "object") return
        for(let key in _inner.ui){
            if(!payload[key]){
                _inner.ui[key] = false;
            }
        }
        for(let key in payload){
            _inner.ui[key] = service.convertValue(payload[key]);
        }
    }
    let service ={}
    service.init=function(serviceProvider){
        if(!serviceProvider) return
        if(serviceProvider.mre_call_provider_id && _inner.mre_call_provider_id && serviceProvider.mre_call_provider_id === _inner.mre_call_provider_id) return
        _inner.mre_call_provider_id = serviceProvider.mre_call_provider_id ? serviceProvider.mre_call_provider_id : null;
        _inner.updateUI(serviceProvider);
    }
    service.convertValue=function(value){
        if(!value) return false;
        if(typeof value === "boolean" || typeof value !== 'string') return value;
        if(userI.isModerator()) return value.substring(2, 3) == "1"
        return userI.isUser() ? value.substring(1, 2) == "1" : value.substring(0, 1) == "1";
    }
    service.getUIBind=function(){
        if(Object.keys(_inner.ui).length === 0) {
            service.init(serviceProviderI.getServiceProvider())
        }
        return _inner.ui;
    }
    return service;
}
export const permissionI = PermissionI();