
let ToastI = function () {
    let service = {
        toastsContainer: document.querySelector('.toasts-container'),
    };
    let _inner = {
        activeToasts:[]
    };
    service.init = function() {
        service.toastsContainer = document.querySelector('.toasts-container');
    }

    service.display = (msg, seconds, options = {}) => {
        return new Promise((resolve, reject)=>{
            let toast = service.factory({
                message: msg,
                state: options.state ? options.state : null,
                icon: options.icon ? options.icon : null,
                action: typeof options.action == 'string' ? options.action : false,
                cancel: typeof options.cancel == 'string' ? options.cancel : false,
                class: options.class,
                closable: options.closable ? true : false,
                id: options.id ? options.id : null,
                fontsize: options.fontsize ? options.fontsize : null
            }, options);
            if(options.id && document.getElementById(options.id)){
                reject();
                return;
            }
            if(options.container){
                let container = options.container instanceof HTMLElement ? options.container : document.querySelector(options.container);
                if(container){
                    container.style.position = "relative";
                    let customContainer = container.querySelector('.custom-toast-container');
                    if(!customContainer){
                        customContainer = document.createElement('div');
                        customContainer.classList.add('custom-toast-container');
                        container.append(customContainer);
                    }
                    customContainer.prepend(toast.toastWrapper);
                } else {
                    console.log("container not found");
                }
            } else {
                service.toastsContainer.prepend(toast.toastWrapper);
            }
            //means we give back container to options
            if(options.containerRef)
                options.containerRef = toast.toastWrapper;

            if (typeof options.action == 'string'){
                toast.actionBtn.addEventListener('click', ()=>{
                    service.closeToast(toast.toastWrapper);
                    resolve();
                })
            }
            if (options.cancel){
                toast.cancelBtn.addEventListener('click', ()=>{
                    service.closeToast(toast.toastWrapper);
                    reject();
                })
            }
            if (options.closable){
                toast.closeBtn.addEventListener('click', ()=>{
                    if(typeof options.onClose==='function')
                        options.onClose(toast.toastWrapper);
                    service.closeToast(toast.toastWrapper);
                })
            }
            setTimeout(()=>{
                toast.toastWrapper.classList.add('active');
            })
            if(seconds != 'infinit'){
                setTimeout(()=>{
                    service.closeToast(toast.toastWrapper);
                    reject();
                }, seconds && typeof seconds == "number" ? seconds : 7000)
            }
        })
    }
    service.getActiveToastById=(id)=>{
        if(!id) return
        return _inner.activeToasts.find(toastWrapper=>toastWrapper.getAttribute('id') === id);
    }
    service.displayPopup = (toastData, seconds = 'infinit', options = {}) => {
        return new Promise((resolve, reject)=>{
            let toast = service.factory({
                state: options.state? options.state: null,
                icon: options.icon ? options.icon : null,
                action: typeof options.action == 'string' ? options.action : false,
                cancel: typeof options.cancel == 'string' ? options.cancel : false,
                class: 'popup',
                closable: options.closable ? options.closable : true,
                isPopup: true,
                fontsize: options.fontsize ? options.fontSize : null
            }, toastData);
            if(options.container){
                let container = options.container instanceof HTMLElement ? options.container : document.querySelector(options.container);
                if(container){
                    container.style.position = "relative";
                    let customContainer = container.querySelector('.custom-toast-container');
                    if(!customContainer){
                        customContainer = document.createElement('div');
                        customContainer.classList.add('custom-toast-container');
                        container.append(customContainer);
                    }
                    customContainer.prepend(toast.toastWrapper);
                } else {
                    console.log("container not found");
                }
            } else { 
                service.toastsContainer.prepend(toast.toastWrapper);
            }

            if (typeof options.action === 'string' && toast.actionBtn){
                toast.actionBtn.addEventListener('click', ()=>{
                    service.closeToast(toast.toastWrapper);
                    resolve();
                })
            }
            if (options.cancel){
                toast.cancelBtn.addEventListener('click', ()=>{
                    service.closeToast(toast.toastWrapper);
                    reject();
                })
            }
            if (options.closable == true || options.closable === undefined){
                toast.closeBtn.addEventListener('click', ()=>{
                    if(typeof options.onClose==='function')
                        options.onClose(toast.toastWrapper);
                    else
                        service.closeToast(toast.toastWrapper);
                })
            }
            if(toastData.enableUpdateContent) {
                if(toastData.containerCallback) {
                    toastData.containerCallback.close = ()=>{
                        if(toast.closeBtn && typeof toast.closeBtn.click === 'function')
                            toast.closeBtn.click();
                        else
                            service.closeToast(toast.toastWrapper);
                    }
                    toastData.containerCallback.update = (obj)=>{
                        if(!obj || !toast.toastWrapper)
                            return;
                        if(obj.status && typeof obj.status === 'string') {
                            let el = toast.toastWrapper.querySelector('.status-message');
                            if(el)
                                el.textContent = obj.status;
                        }
                        if(obj.icon) {
                            let el = toast.toastWrapper.querySelector('.toast-icon');
                            if(el)
                                el.innerHTML = obj.icon;
                        }
                        if(obj.state) {
                            toast.toastWrapper.classList.remove('success', 'info', 'warn', 'error');
                            toast.toastWrapper.classList.add(obj.state);
                        }
                        //update action btn
                        let el = toast.actionBtn || toast.toastWrapper.actionBtn;
                        let action = obj.action && typeof obj.action === 'string'? obj.action: '';
                        if(el) {
                            el.classList.remove('display-none');
                            if(action)
                                el.innerHTML = action;
                            else
                                el.classList.add('display-none');
                        } else if(action) {
                            toast.toastWrapper.createActionButton(action);
                        }
                        //update cancel btn
                        el = toast.cancelBtn || toast.toastWrapper.cancelBtn;
                        let cancel = obj.cancel && typeof obj.cancel === 'string'? obj.cancel: '';
                        if(el) {
                            el.classList.remove('display-none');
                            if(cancel)
                                el.innerHTML = cancel;
                            else
                                el.classList.add('display-none');
                        } else if(cancel) {
                            toast.toastWrapper.createCancelButton(cancel);
                        }
                    }
                }
            }
            setTimeout(()=>{
                toast.toastWrapper.classList.add('active');
            })
            if(seconds != 'infinit'){
                setTimeout(()=>{
                    service.closeToast(toast.toastWrapper);
                }, seconds && typeof seconds == "number" ? seconds : 7000)
            }
            if(toast.toastWrapper){
                if(options.id){
                    toast.toastWrapper.setAttribute('id',options.id)
                }
                _inner.activeToasts.push(toast.toastWrapper);
            }
        })
    }

    service.success = (msg) =>{
        if(typeof msg !== 'string' || !msg)
            return;
        service.display(msg, 7000, {state: 'success'}).then(()=>{}, ()=>{});
    }
    service.error = (msg, ms, options)=>{
        if(typeof msg !== 'string' || !msg)
            return;
        if(msg && typeof msg==='string' && msg.indexOf('TMUH')>-1)
            return;
        msg = msg.replace('Error:', '').replace('- not allowed', '');
        let opt = {state: 'error'};
        if(typeof options === 'object') {
            options.state = opt.state;
            opt = options;
        }
        service.display(msg, !ms? 7000: ms, opt).then(()=>{}, ()=>{});
    }
    service.warning = (msg, ms, options) => {
        if(typeof msg !== 'string' || !msg)
            return;
        let opt = {state: 'warn'};
        if(typeof options === 'object') {
            options.state = opt.state;
            opt = options;
        }
        service.display(msg, !ms? 7000: ms, opt).then(()=>{}, ()=>{});
    }
    service.info = (msg, ms, options) => {
        if(typeof msg !== 'string' || !msg)
            return;
        let opt = {state: 'info'};
        if(typeof options === 'object') {
            options.state = opt.state;
            opt = options;
        }
        service.display(msg, !ms? 7000: ms, opt).then(()=>{}, ()=>{});
    }
    service.closeToast = (toastWrapper) => {
        if(!toastWrapper || !toastWrapper.classList)
            return;
        toastWrapper.classList.remove('active');
        const id = toastWrapper.getAttribute('id');
        _inner.activeToasts = _inner.activeToasts.filter(toast=>toast.getAttribute('id') !== id)
        setTimeout(()=>{
            toastWrapper.remove();
            toastWrapper = null;
        }, 250)
    }
    service.closeToastById = (id)=>{
        if(!id) return
        const toastWrapper = service.getActiveToastById(id)
        if(!toastWrapper) return
        service.closeToast(toastWrapper)
        _inner.activeToasts = _inner.activeToasts.filter(toast=>toast.getAttribute('id') !== id)
    }
    service.factory = (options, popupdata={}) => {
        let toastWrapper = document.createElement('div');
        toastWrapper.classList.add('toast-wrapper');
        let toastIcon, toastContentWrapper, toastContent, toastAction, toastPopupInfo;
        let actionBtn, cancelBtn, closeBtn;
        if(options.class){
            toastWrapper.classList.add(options.class.split(' '));
        }

        if(options.state){
            toastWrapper.classList.add(options.state)
            if(!options.icon){
                if (options.state == 'success')
                    options.icon = '<span class="material-icons-outlined">done</span>';
                if (options.state == 'info')
                    options.icon = '<span class="material-icons-outlined">info</span>';
                if (options.state == 'warn')
                    options.icon = '<span class="material-icons-outlined">report_problem</span>';
                if (options.state == 'error')
                    options.icon = '<span class="material-icons-outlined">error_outline</span>';
            }
        }
        if(options.icon){
            toastIcon = document.createElement('div');
            toastIcon.classList.add('toast-icon');
            toastIcon.innerHTML = options.icon;
            toastWrapper.append(toastIcon);
        }
        toastContentWrapper = document.createElement('div');
        toastContentWrapper.classList.add('toast-content-wrapper');
        if(options.message){
            toastContent = document.createElement('div');
            toastContent.classList.add('toast-content');
            toastContent.innerHTML = options.message;
            if(options.fontsize){
                toastContent.style.fontSize = options.fontsize;
            }
            if(popupdata.updateMessage) {
                popupdata.updateMessage = (message)=> {
                    if(message && typeof message == 'string')
                        toastContent.innerHTML = message;
                }
            }
        }
        if(options.isPopup){
            popupdata.container = toastWrapper;
            toastPopupInfo = document.createElement('div');
            toastPopupInfo.classList.add('toast-popup-info');
                if(popupdata.image){
                    let participantImage = document.createElement('img');
                    participantImage.src = popupdata.image ? popupdata.image : 'null';
                    toastPopupInfo.append(participantImage);
                }
                if(popupdata.name){
                    let participantName = document.createElement('span');
                    participantName.classList.add('toast-popup-username');
                    participantName.innerHTML= popupdata.name ? popupdata.name: 'John Doe';
                    toastPopupInfo.append(participantName);
                }
                if(popupdata.status){
                    let statusMsg = document.createElement('small');
                    statusMsg.classList.add('status-message');
                    statusMsg.innerText = popupdata.status;
                    toastPopupInfo.append(statusMsg);
                }
        }
        toastWrapper.createActionButton = (txt)=>{
            if(typeof txt !== 'string')
                return;
            let css = 'toast-action-btn';
            let el = toastAction.querySelector('.'+css);
            if(el) {
                return el;
            }
            actionBtn = document.createElement('button');
            actionBtn.classList.add(css, 'primary');
            if(!txt)
                actionBtn.classList.add('display-none');
            actionBtn.innerHTML = txt;
            toastAction.append(actionBtn);
            toastWrapper.actionBtn = actionBtn;
            return actionBtn;
        }
        toastWrapper.createCancelButton = (txt)=>{
            if(typeof txt !== 'string')
                return;
            let css = 'toast-cancel-btn';
            let el = toastAction.querySelector('.'+css);
            if(el) {
                return el;
            }
            cancelBtn = document.createElement('button');
            cancelBtn.classList.add(css);
            if(!txt)
                cancelBtn.classList.add('display-none');
            cancelBtn.innerHTML = txt;
            toastAction.append(cancelBtn);
            toastWrapper.cancelBtn = cancelBtn;
            return cancelBtn;
        }
        if(options.action || options.cancel || options.closable){
            toastAction = document.createElement('div');
            toastAction.classList.add('toast-action');
            if(typeof options.action == 'string'){
                toastWrapper.createActionButton(options.action);
            }
            if(typeof options.cancel == 'string'){
                toastWrapper.createCancelButton(options.cancel);
            }
            if(options.closable){
                closeBtn = document.createElement('button');
                closeBtn.classList.add('toast-close');
                closeBtn.innerHTML = '<span class="material-icons">close</span>';
                toastAction.append(closeBtn);
            }
        }
        if(options.id){
            toastWrapper.setAttribute('id',options.id);
            _inner.activeToasts.push(toastWrapper);
        }
        let cnt = [toastContent, toastPopupInfo, toastAction];
        cnt.forEach(element => {
            if(element){
                toastContentWrapper.append(element);
            }
        });
        toastWrapper.append(toastContentWrapper);

        return {toastWrapper, actionBtn, cancelBtn, closeBtn};
    }
    return service;
}
export const toastI = ToastI();
