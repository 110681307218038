
<div class="modal-wrapper" [attr.data-modalid]="containerId">
    <div class="modal-backdrop"></div>
    <div class="modal-container md-fit">
        <div class="modal-header">
            <span class="heading">Exit</span>
            <button class="transparent close-btn">
                <span class="material-icons">close</span>
            </button>
        </div>
        <div class="modal-body md-padding-bottom">
            <div class="flex-col p-4">
                <h3 class="title mb-3">Are you sure you want to leave the call ?</h3>
                <div class="width-full gap-20 flex-row space-between align-center mb-2 p-4">
                    <div *ngIf="isHost">
                        <button id="stop-call-button" class="danger large capitalize" (click)="stop()">Stop</button>
                    </div>
                    <div class="width-full gap-10 flex-row space-between align-center">
                        <button class="large" (click)="dismiss()">Cancel</button>
                        <button id="leave-call-button" class="danger large capitalize" (click)="leave()">Leave</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>