import { Component, OnInit } from '@angular/core';
import { pwaI } from 'src/assets/js/pwaI';
import { serviceProviderI } from 'src/assets/js/serviceProviderI';

@Component({
  selector: 'app-install-prompt',
  templateUrl: './app-install-prompt.component.html',
  styleUrls: ['./app-install-prompt.component.scss']
})
export class AppInstallPromptComponent implements OnInit {
  spUI: any = serviceProviderI.getUIBind();
  pwaUI: any = pwaI.getUIBind();
  show: boolean = false;

  constructor() {}

  dismissInstall() {
    this.show = false;
    setTimeout(()=>{
      pwaI.dismissInstall();
    }, 300);
  }

  install() {
    pwaI.installPWA();
  }

  ngOnInit(): void {
    setTimeout(()=>{
      this.show = pwaI.shouldInstall();
    }, 1000);
  }

}
