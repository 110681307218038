import { modalI } from "./modals";
import { callI } from "./callI";
import { themeI } from "./themeI";
import { langI } from "./langI";
import { networkI } from "./networkI";
import { endpointI } from "./endpointI";
import { toastI } from "./toastI";
import { serviceProviderI } from "./serviceProviderI";
import { participantI } from "./participantI";
import { userI } from "./userI";
import { navbarI } from "./navbarI";
import { chatI } from "./chatI";
import { streamI } from "./streamI";
function deepArrayDiff(arr1, arr2) {
  function deepEqual(obj1, obj2) {
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return obj1 === obj2;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  if (Array.isArray(arr1) && Array.isArray(arr2)) {
    if(arr1.length === arr2.length){
      for(let i = 0 ; i < arr1.length; i++){
        const item1 = arr1[i];
        const item2 = arr2.find(item=>item.id === item1.id);
        if(item2 && !deepEqual(item1,item2)) return true
      }
      return false
    }
    return true
  }
  return true;
}
function convertDateFormat(inputDate) {
  const date = new Date(inputDate);
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  return `${year}${month}${day}${hours}${minutes}`;
}
function removeSpacesAndSpecialChars(inputString) {
  if (typeof inputString !== "string") return "";
  inputString = inputString.replace(/[^\w\s]/g, '').replace(/\s/g, '_');
  return inputString.replace(/_+$/, '');
}
function WhiteBoardI(){
  const DEBUG = ['127.0.0.1','localhost','192.168'].some(item=>window.origin.indexOf(item)>-1);
  const _inner = {};
  const service = {};
  _inner.tools = {
    TEXT: "TEXT",
    LINE: "line",
    ARROW: "arrow",
    SELECTION: "selection",
    RECTANGLE: "rectangle",
    DIAMOND: "diamond",
    ELLIPSE: "ellipse",
    FREEDRAW: "freedraw",
    FRAME: "frame",
    EMBEDDABLE: "embeddable",
    ERASER: "eraser",
    HAND: "hand",
    LASER: "laser",
  };
  _inner.defaultTool = _inner.tools.FREEDRAW;
  _inner.iframe = null;
  _inner.theme = null;
  _inner.IFRAME_EVENTS = {
    ELEMENTS_CHANGE:"onElementsChange",
    SOCKET_CONNECTED:"onSocketConnect",
    SOCKET_DISCONNECTED:"onSocketDisconnect",
    SOCKET_ERROR:"onSocketError",
    THEME_CHANGE:"onThemeChange"
  }
  _inner.isOpen = false;
  _inner.active = null;
  _inner.active_id = null;
  _inner.WhiteBoardsData = [];
  _inner.saveDataTimeouts = {};
  _inner.saveDataTimeout = 700;
  _inner.loadingState = {
    FETCHING_WHITEBOARDS:false,
    FETCHING_WHITEBOARDS_DATA:false,
    SAVING_WHITEBOARDS_DATA:false,
    CREATING_WHITEBOARD:false,
    DELETING_WHITEBOARD:false,
    UPDATING_WHITEBOARD:false,
    LOADING_IFRAME:false
  }
  _inner.inCall = false
  _inner.call = {}
  _inner.participant = {};
  _inner.lastActive = null;
  const key = "Z8Fy4SF1ecN0PL4cwSw2nQ"
  _inner.whiteBoards = [];
  _inner.isAdding = false;
  class MreCallWhiteBoard{
    constructor(data){
      if(typeof data === "object" && data.mre_call_white_board_id && data.name && data.created_by && data.created_at && data.updated_at){
        this.mre_call_white_board_id = data.mre_call_white_board_id;
        this.mre_call_id = data.mre_call_id;
        this.name = data.name;
        this.description = data.description;
        this.created_by = data.created_by;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
        this.isOwner = this.created_by === callI.getActiveParticipantId();
        this.data = {
          elements:[]
        }
        this.isEditEnabled = false;
        this.isDeleteConfirm = false;
        this.isDeleting = false;
        this.isEditing = false;

        this.old_data = {
          name:null,
          description:null
        }
        this.is_valid = {
          name:false,
          description:false
        }
        this.is_active = data.is_active;
        this.is_loading = false;
        this.storeOldData();
        this.updateIsValid();
      }
    }
    updateIsValid(){
      this.is_valid.name = this.name.length > 0 && this.name !== this.old_data.name;
      this.is_valid.description = this.description !== this.old_data.description ;
    }
    setName(name){
      this.name = name
      this.updateIsValid();
    }
    setDescription(description){
      this.description = description
      this.updateIsValid();
    }
    updateData(){
      this.data.elements = [];
      service.sort();
      const result =  _inner.WhiteBoardsData.filter(obj=>obj.mre_call_white_board_id === this.mre_call_white_board_id);
      const whiteboardData = result[0];
      if(typeof whiteboardData !== "object") return
      try{
        const data = JSON.parse(whiteboardData.data);
        if(data && Array.isArray(data.elements)){
          this.data.elements = data.elements.sort((a,b)=>a.updated-b.updated)
        }
      }catch(err){
        service.debug(err)
      }
    }
    handleEditWhiteboard(){
      if(this.isEditing || !this.isOwner) return
      if(!this.is_valid.name && !this.is_valid.description)
        return;
      let obj = {
        mre_call_white_board_id:this.mre_call_white_board_id
      };
      if(this.is_valid.name)
        obj.name = this.name;
      if(this.is_valid.description)
        obj.description = this.description;
      this.isEditing = true;
      this.toggleEditWhiteBoard();
      service.useWhiteBoardEndpointForCall(service.getCallId(),'PUT',{
        WhiteBoard: obj
      })
      .then((res)=>{
        if(Array.isArray(res?.errors)){
          toastI.error(networkI.getErrorMessageFromArr(res.errors) || langI.get('WHITEBOARD_UPDATED_ERROR'));
          return;
        }
        if(res.WhiteBoard){
          this.name = res.WhiteBoard.name? res.WhiteBoard.name: '';
          this.description = res.WhiteBoard.description? res.WhiteBoard.description: '';
          this.updated_at = res.updated_at;
          this.old_data.name = this.name;
          this.old_data.description = this.description;
        }
      },err=>{
        toastI.error(langI.get('ERROR_UNK'));
      })
      .finally(()=>{
        this.isEditing = false;
      })
    }
    handleDeleteWhiteboard(){
      if(this.isDeleting || !this.isOwner) return
      if(!this.isDeleteConfirm) {
        this.isDeleteConfirm = true;
        setTimeout(()=>{
          this.isDeleteConfirm = false;
        }, 15000);
        return;
      }
      this.isDeleting = true;
      service.useWhiteBoardEndpointForCall(service.getCallId(),'DELETE',{},`/${this.mre_call_white_board_id}`)
      .then((res)=>{
        if(Array.isArray(res?.errors)){
          toastI.error(networkI.getErrorMessageFromArr(res.errors) || langI.get('WHITEBOARD_DELETED_ERROR'));
          return;
        }
        if(res?.IsHandled){
          setTimeout(()=>{
            _inner.whiteBoards = _inner.whiteBoards.filter(obj=>obj.mre_call_white_board_id !== this.mre_call_white_board_id);
            if(_inner.whiteBoards.length){
              service.select();
            }else{
              _inner.active = null;
              _inner.notifyUI();
            }
          })
        }
      },(err)=>{
        toastI.error(langI.get('ERROR_UNK'));
      })
      .finally(()=>{
        this.isDeleting = false;
        if(!service.getWhiteBoardsCount()) service.create();
      });
    }
    storeOldData(){
      this.old_data = {
        name:this.name,
        description:this.description
      }
    }
    toggleEditWhiteBoard(){
      // this.storeOldData();
      this.isEditEnabled = !this.isEditEnabled;
      if(!this.isEditEnabled){
        this.isDeleteConfirm = false;
        this.name = this.old_data.name;
        this.description = this.old_data.description;
      }
    }
    unselect(){
      clearTimeout(_inner.saveDataTimeouts[this.mre_call_white_board_id])
      this.isEditEnabled = false;
      this.is_loading = false;
      const elementsToSave = service.getElements();
      this.onSaveData({elements:elementsToSave}).then(()=>{},()=>{})
      service.updateScene({appState:{openDialog:null}})
    }
    onSaveData(data){
      return new Promise((resolve,reject)=>{
        const Data = {
          elements:typeof data === "object" && Array.isArray(data.elements) ? data.elements : this.data.elements
        }
        service.useWhiteBoardEndpointForCall(service.getCallId(),'POST',{
          MreCallParticipantID:this.created_by,
          WhiteBoardID:this.mre_call_white_board_id,
          Data:JSON.stringify(Data)
        },'/data')
        .then((res)=>{
          resolve(res);
        },(err)=>{
          reject(err);
        })
      })
    }
    select(){
      if(!userI.getWhiteBoardServer()) return
      this.is_loading = true;
      if(_inner.lastActive && _inner.lastActive.mre_call_white_board_id !== this.mre_call_white_board_id){
        service.sendEvent(service.EVENTS.WHITEBOARD_SWITCH,{
          whiteBoardName:this.name
        });
      }
      _inner.active = this;
      _inner.lastActive = _inner.active;
      _inner.active_id = this.mre_call_white_board_id;
      service.onRady().then(()=>{
        service.getExcalidrawAPI()?.setActiveTool?.({type:_inner.defaultTool})
        service.updateScene({ appState: { isLoading: true,viewModeEnabled:!_inner.inCall } });
        service.clean();
        if(_inner.inCall) service.getCollabApi()?.stopCollaboration();
        service.onGetData().then(()=>{},()=>{})
        .finally(()=>{
          this.is_loading = false;
          this.updateData();
          service.updateScene({elements:this.data.elements,appState: { isLoading: false }})
          service.sync();
          if(_inner.inCall) service.getCollabApi()?.startCollaboration({roomId:this.mre_call_white_board_id,roomKey:key,username:service.getParticipantName()});
        })
      },()=>{})
    }
  }
  service.onGetData = ()=>{
    return new Promise((resolve,reject)=>{
      if(_inner.loadingState.FETCHING_WHITEBOARDS_DATA) return reject();
      _inner.loadingState.FETCHING_WHITEBOARDS_DATA = true;
      _inner.notifyUI();
      service.useWhiteBoardEndpointForCall(service.getCallId(),'GET',{},`/data?callId=${service.getCallId()}`,false)
      .then((res)=>{
        if(res && Array.isArray(res.WhiteBoardsData)){
          _inner.WhiteBoardsData = res.WhiteBoardsData;
          resolve(res)
        }else{
          reject();
        }
      },(err)=>{
        reject(err);
      })
      .finally(()=>{
        _inner.loadingState.FETCHING_WHITEBOARDS_DATA = false;
        _inner.notifyUI();
      })
    })
  }
  service.onSaveData = (obj)=>{
    return new Promise((resolve,reject)=>{
      if(!(obj instanceof MreCallWhiteBoard)) return reject();
      obj.onSaveData().then(resolve,reject)
    })
  }
  service.updateScene = (data) =>{
    if(typeof data !== "object") return
    const obj = {};
    if(Array.isArray(data.elements)){
      obj.elements = data.elements
    }
    if(typeof data.appState === "object"){
      obj.appState = data.appState;
    }
    if(Object.keys(obj).length === 0) return
    service.getExcalidrawAPI().updateScene(obj);
  }
  service.onRady = ()=>{
    return new Promise((resolve,reject)=>{
        if(!_inner.iframe || !userI.getWhiteBoardServer()) return reject();
        let checkTimeout = null;
        let timout = setTimeout(()=>{
          clearTimeout(checkTimeout)
          reject();
        },10*1000);
        const check = ()=>{
          if(service.getApi() && service.getExcalidrawAPI() && service.getCollabApi() && (_inner.inCall ? (callI.getActiveCall() && callI.getActiveParticipant()) : true)){
            clearTimeout(timout)
            resolve();
          }else{
            checkTimeout = setTimeout(check,500)
          }
        }
        check();
      })
  }
  service.useWhiteBoardEndpointForCall = (mre_call_id,method="GET",payload={},suffix="",useCallID=true)=>{
    return new Promise((resolve,reject)=>{
      if(typeof mre_call_id !== "string" || typeof method !== "string" || typeof payload !== "object" || typeof suffix !== "string") return reject();
      let func = null;
      let url = useCallID ? `/api/call/${mre_call_id}/whiteboard${suffix.trim('/')}` : `/api/call/whiteboard${suffix.trim('/')}`;
      switch(method.toUpperCase()){
        case 'GET':
          func = networkI.get;
          payload = null;
          break;
        case 'POST':
          func = networkI.post
          break;
        case 'DELETE':
          func = networkI.delete
          break;
        case 'PUT':
          func = networkI.put
          break;
      }
      if(typeof func !== "function") return reject();
      if(payload && typeof payload === "object"){
        payload.MreCallProvider = serviceProviderI.getServiceProviderForApi();
        payload.MreCallParticipant = {
          mre_call_participant_id:service.getParticipantId()
        }
      }
      func({url:url,data:payload,headers:endpointI.getAuthHeaders()})
      .then(res=>{
        if(Array.isArray(res.errors)) return reject(res);
        resolve(res)
      },err=>{
        reject(err)
      })
    })
  }
  service.onUploadAttachment = (file) => {
    return new Promise((resolve, reject)=>{
      let whiteboardId = service.getCurrentWhiteboardId();
      if(!file || !service.getCurrentWhiteboardCallId() || !whiteboardId) {
        reject();
        return;
      }
      let fileName = file.name || '';
      let url = `/api/call/${service.getCurrentWhiteboardCallId()}/whiteboard/${whiteboardId}/attachment/file`;
      let params = [];
      let extension = '';
      if(fileName.indexOf('.')>-1) {
        let info = fileName.split('.');
        if(info.length==2)
          extension = info[1];
      }
      if(fileName)
        params.push('fileName='+fileName);
      if(extension)
        params.push('extension='+extension);

      url += '?';
      url += params.join('&');

      let opt = {};
      opt.isFile = true;
      opt.data = file;
      opt.url = url;
      opt.headers = {};
      opt.headers['Content-Type'] = 'application/octet-stream';
      networkI.post(opt)
      .then((res)=>{
        if(Array.isArray(res.errors)) {
          reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
          return;
        }
        if(res.WhiteBoardAttachments && res.WhiteBoardAttachments.length>0) {
          resolve(res.WhiteBoardAttachments[0].ID);
        } else {
          reject();
        }
      }, (error)=>{
          reject(error);
      });
    });
  }
  service.onFetchImage = (fileId)=>{
    return new Promise((resolve,reject)=>{
      const img = new Image();
      service.onLoadAttachment(fileId).then(obj=>{
        if(obj)
          img.src = obj.url? obj.url: obj? window.URL.createObjectURL(obj): '';
      },()=>{
        reject(img)
      })
      img.onload = ()=>{
        resolve(img)
      }
      img.onerror = ()=>{
        reject(img)
      }
    })
  }
  service.onLoadAttachment = function(id) {
    return new Promise((resolve, reject) => {
      let whiteboardId = service.getCurrentWhiteboardId();
      if(!id || typeof id == 'object' || !whiteboardId || !service.getCurrentWhiteboardCallId()) {
        reject();
        return;
      }
      let callId = service.getCurrentWhiteboardCallId();
      let url = `/api/call/${service.getCurrentWhiteboardCallId()}/whiteboard/${whiteboardId}/attachment`;
      if(callId !== callI.getActiveCallId())
        url += '/file'
      url += `/${id}`;
      networkI.get({url: url})
      .then((res)=>{
        //means that we have a blob in this response
        if(res && res.url) {
          res.blob()
          .then((blob)=>{
              resolve(blob);
          }, (error)=>{
              reject()
          });
        } else {
          if(Array.isArray(res.errors)) {
            reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
            return;
          } 
          if(res.WhiteBoardAttachments && res.WhiteBoardAttachments.length>0) {
            resolve({url: res.WhiteBoardAttachments[0].url});
          } else {
            reject();
          }
        }
      }, (error)=>{
          reject(error);
      });
    });
  }
  service.sort = ()=>{
    _inner.whiteBoards = _inner.whiteBoards.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    _inner.WhiteBoardsData = _inner.WhiteBoardsData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    _inner.notifyUI();
  }
  service.select = ()=>{
    const whiteboard = service.getWhiteBoards().find(obj=>obj.created_by === service.getParticipantId()) || service.getWhiteBoards()[0];
    if(!(whiteboard instanceof MreCallWhiteBoard)){
      return
    }
    whiteboard.select();
  }
  service.onLoad = ()=>{
    return new Promise((resolve,reject)=>{
      if(_inner.loadingState.FETCHING_WHITEBOARDS ||!_inner.iframe || !userI.getWhiteBoardServer()) return reject();
      _inner.loadingState.FETCHING_WHITEBOARDS = true;
      _inner.notifyUI();
      const arr = [];
      service.useWhiteBoardEndpointForCall(service.getCallId()).then(res=>{
        if(Array.isArray(res?.errors)){
          toastI.error(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
          return reject();
        }
        if(Array.isArray(res?.WhiteBoards)){
          for(let i = 0 ; i < res.WhiteBoards.length; i++){
            arr.push(new MreCallWhiteBoard(res.WhiteBoards[i]))
          }
          _inner.whiteBoards = arr;
          service.sort();
          _inner.notifyUI();
        }
        resolve();
      },err=>{
        toastI.error(langI.get('ERROR_UNK'));
        reject()
      }).finally(()=>{
        _inner.loadingState.FETCHING_WHITEBOARDS = false;
        _inner.notifyUI();
      })
    })
  }
  service.getElements = ()=>{
    return service.getCollabApi()?.getElements() || [];
  }
  service.getDefaultWhiteBoardData = ()=>{
    return {
      name:`Whiteboard ${service.getWhiteBoardsCount()+1}`,
      description:""
    }
  }
  service.onCreate = ()=>{
    return new Promise((resolve,reject)=>{
      if(_inner.loadingState.CREATING_WHITEBOARD || !_inner.iframe || !userI.getWhiteBoardServer() || !_inner.inCall) return reject();
      _inner.loadingState.CREATING_WHITEBOARD = true
      _inner.notifyUI();
      const defaultData = service.getDefaultWhiteBoardData();
      service.useWhiteBoardEndpointForCall(service.getCallId(),'POST',{
        name:defaultData.name,
        description:defaultData.description
      }).then(res=>{
        if(Array.isArray(res?.errors)){
          toastI.error(networkI.getErrorMessageFromArr(res.errors) || langI.get('WHITEBOARD_CREATED_ERROR'));
          return reject(res.errors);
        }
        resolve(res.WhiteBoard);
      },err=>{
        reject(err);
      }).finally(()=>{
        _inner.loadingState.CREATING_WHITEBOARD = false
        _inner.notifyUI();
      })
    })
  }
  service.deleteWhiteBoard = (mre_call_white_board_id)=>{
    if(!mre_call_white_board_id) return
    _inner.whiteBoards = _inner.whiteBoards.filter(obj=>obj.mre_call_white_board_id !== mre_call_white_board_id);
  }
  service.sync = ()=>{
    if(!_inner.iframe) return
    service.setLangCode(participantI.getCurrentUserLanguageCode())
    service.setTheme(_inner.theme || themeI.getMainappTheme())
    service.getCollabApi()?.setUsername(service.getParticipantName())
    _inner.iframe.contentWindow.callI = callI;
    _inner.iframe.contentWindow.whiteBoardI = whiteBoardI;
    _inner.iframe.contentWindow.TMU_WHITEBOARD_WS_SERVER = userI.getWhiteBoardServer()
    _inner.iframe.contentWindow.console.log = ()=>{}
    _inner.iframe.contentWindow.console.error = ()=>{}
    _inner.iframe.contentWindow.onSaveImage = service.onSaveImage;
    _inner.iframe.contentWindow.onFetchImage = service.onFetchImage;
    service.updateName();
    _inner.notifyUI();
  }
  service.updateName = ()=>{
    if(_inner.active instanceof MreCallWhiteBoard && typeof _inner.call === "object"){
      const whteboardname = removeSpacesAndSpecialChars(_inner.active.name);
      const callsubject = removeSpacesAndSpecialChars((_inner.call.subject || "no subject"));
      const starttime = convertDateFormat(_inner.call.start_time);
      const name = [whteboardname,callsubject,starttime].filter(string=>typeof string == "string" && string.trim()).join("_")
      service.updateScene({appState: {name:name}})
    }
  }
  service.setLangCode = (langCode)=>{
    if(!langCode) return
    service.getApi()?.setLangCode?.(langCode);
  }
  service.setTheme = (theme)=>{
    if(!theme) return
    service.getExcalidrawAPI()?.updateScene({ appState: { theme: theme } });
  }
  service.updateCurrentWhiteBoard = (data)=>{
    if(typeof data !== "object") return
  }
  service.getExcalidrawAPI = ()=>{
    return service.getCollabApi()?.excalidrawAPI
  }
  service.clean = ()=>{
    service.getExcalidrawAPI()?.history.clear();
    service.getExcalidrawAPI()?.updateScene({ elements:[] })
    for (var key in localStorage) {
      if (key.startsWith('excalidraw')) {
          localStorage.removeItem(key);
      }
    }
  }
  service.getApi = ()=>{
    if(!_inner.iframe) return
    return _inner.iframe.contentWindow.api
  }
  service.getCollabApi = ()=>{
    return service.getApi()?.collab;
  }
  service.getCallId = ()=>{
    return _inner.inCall ? callI.getActiveCallId() : _inner.call.mre_call_id
  }
  service.getParticipantId = ()=>{
    return callI.getActiveParticipantId() || _inner.participant.mre_call_participant_id;
  }
  service.getParticipantName = ()=>{
    return callI.getActiveParticipantName() || _inner.participant.name;
  }
  service.handleIframeMessages = (message)=>{
    if(!_inner.iframe || typeof message !== "object" || !message.data || message.source !== _inner.iframe.contentWindow || !_inner.isOpen) return
    switch(message.data.type){
      case _inner.IFRAME_EVENTS.ELEMENTS_CHANGE:
        const elements = message.data.elements;
        const roomId = message.data.roomId;
        if(Array.isArray(elements) && roomId && elements.length > 0){
          const whiteboard = _inner.whiteBoards.find(obj=>obj.mre_call_white_board_id === roomId);
          if(whiteboard instanceof MreCallWhiteBoard){
            const elementsToSave = elements.filter(el=>!el.isDeleted).sort((a,b)=>a.updated-b.updated)
            if(!deepArrayDiff(whiteboard.data.elements,elementsToSave)) return
            window.clearTimeout(_inner.saveDataTimeouts[whiteboard.mre_call_white_board_id])
            _inner.saveDataTimeouts[whiteboard.mre_call_white_board_id] = setTimeout(()=>{
              service.debug("Saving WhiteBoard Data")
              whiteboard.onSaveData({elements:elementsToSave}).then(()=>{},()=>{})
              .finally(()=>{
                whiteboard.data.elements = elementsToSave;
              })
            },_inner.saveDataTimeout)
          }
        }
        break;
      case _inner.IFRAME_EVENTS.THEME_CHANGE:
        _inner.theme = message.data.theme;
        break;
      case _inner.IFRAME_EVENTS.SOCKET_CONNECTED:
        break;
      case _inner.IFRAME_EVENTS.SOCKET_DISCONNECTED:
        break;
      case _inner.IFRAME_EVENTS.SOCKET_ERROR:
        break;
    }
  }
  service.addEventListener = ()=>{
    if(!_inner.iframe) return
    window.removeEventListener('message',service.handleIframeMessages);
    window.addEventListener('message',service.handleIframeMessages);
  }
  service.init = (options)=>{
    if(typeof options !== "object") return
    const {iframe,call} = options;
    if(!(iframe instanceof HTMLIFrameElement)) return
    _inner.call = _inner.inCall ? callI.getActiveCall() : call;
    _inner.participant = callI.getActiveParticipant() || {};
    _inner.iframe = iframe;
    _inner.loadingState.LOADING_IFRAME = true;
    _inner.notifyUI();
    setTimeout(()=>{
      const callback = ()=>{
        service.onRady().then(()=>{},()=>{})
        .finally(()=>{
          service.sync();
          setTimeout(()=>{
            _inner.loadingState.LOADING_IFRAME = false
            _inner.notifyUI();
            service.addEventListener();
          },500)
        })
      }
      _inner.iframe.addEventListener('load',()=>{
        callback();
      })
      _inner.iframe.addEventListener('error',()=>{
        callback();
      })
      _inner.iframe.src = "/whiteboard-app/index.html"
      service.onLoad().then(()=>{},()=>{})
      .finally(()=>{
        const callback = ()=>{
          service.select();
          _inner.notifyUI();
        }
        if(!service.getWhiteBoardsCount() && _inner.inCall){
          service.onCreate().then((res)=>{},(err)=>{})
          .finally(()=>{
            service.onLoad().then(()=>{},()=>{})
            .finally(()=>{
              callback();
            });
          })
        }else{
          callback();
        }
      })
    })
  }
  service.openModal = ()=>{
    _inner.inCall = navbarI.getActivePage() === "call";
    _inner.notifyUI();
    service.sync();
    _inner.isOpen = true;
    _inner.notifyUI();
    service.sendEvent(service.EVENTS.OPEN_WHITEBOARD)
    setTimeout(()=>{
      modalI.init();
      modalI.openModal({
        id:'whiteboard-modal',
        onClose:()=>{
          const elements = service.getElements();
          if(_inner.active && _inner.active instanceof MreCallWhiteBoard){ 
            _inner.active.onSaveData({elements:elements}).then(()=>{},()=>{}) // fix data not saved when close the modal
            _inner.active.unselect();
           }
           setTimeout(()=>{
            _inner.isOpen = false;
            _inner.notifyUI();
            service.clear();
           })
        }
      })
    });
  }
  service.getWhiteBoardIndex = ()=>{
    return _inner.whiteBoards.filter(obj=>obj.created_by === callI.getActiveParticipantId() && obj.is_active).length + 1;
  }
  service.getWhiteBoardsCount = ()=> service.getWhiteBoards().length;
  service.getWhiteBoards = ()=>{
    return _inner.whiteBoards.filter(obj=>obj.mre_call_white_board_id && obj.is_active)
  }
  service.clear = ()=>{
    window.removeEventListener('message',service.handleIframeMessages)
    service.getCollabApi()?.stopCollaboration();
    service.clean();
    _inner.iframe = null;
    _inner.isOpen = false;
    _inner.active = null;
    _inner.active_id = null;
    _inner.WhiteBoardsData = [];
    _inner.call = {}
    _inner.participant = {};
    _inner.onLoadingChangeCallback = null
    _inner.saveDataTimeouts = {};
    _inner.inCall = false;
    _inner.lastActive = null;
    for(let key in _inner.loadingState){
      _inner.loadingState[key] = false
    }
    _inner.notifyUI();
  }
  service.onSelect = ()=>{
    if(!_inner.ui) service.getUIBind();   
    if(!_inner.ui.active_id) return
    if(_inner.active && _inner.active.mre_call_white_board_id === _inner.ui.active_id) return
    if(_inner.active && _inner.active instanceof MreCallWhiteBoard){
      _inner.active.unselect();
    }
    service.getWhiteBoards().find(obj=>obj.mre_call_white_board_id === _inner.ui.active_id)?.select();
  }
  service.getCurrentWhiteboardId = ()=>{
    return _inner.ui && _inner.active && _inner.active.mre_call_white_board_id && _inner.active.mre_call_white_board_id === _inner.ui.active_id? _inner.active.mre_call_white_board_id: '';
  }
  service.getCurrentWhiteboardCallId = ()=>{
    return _inner.ui && _inner.active && _inner.active.mre_call_white_board_id && _inner.active.mre_call_white_board_id === _inner.ui.active_id? _inner.active.mre_call_id: service.getCallId();
  }
  service.create = ()=>{
    service.onCreate().then(()=>{},()=>{})
    .finally(()=>{
      service.onLoad().then(()=>{},()=>{})
      .finally(()=>{
        service.select();
        _inner.notifyUI();
      })
    })
  }
  service.getTextObject = ()=>{
    return {
      DELETE:langI.get('DELETE'),
      DELETE_CONFIRM:langI.get('DELETE_CONFIRM'),
      NO_DESCRIPTION:langI.get('NO_DESCRIPTION')
    }
  }
  service.getUIBind = ()=>{
    if(_inner.ui) return _inner.ui;
    _inner.ui = {
      openModal:service.openModal,
      isOpen:_inner.isOpen,
      isLoading:service.isLoading(),
      whiteBoards:_inner.whiteBoards,
      active:_inner.active,
      active_id:_inner.active_id,
      create:service.create,
      onSelect:service.onSelect,
      TEXT:service.getTextObject(),
      loadingState:_inner.loadingState,
      inCall:_inner.inCall
    }
    return _inner.ui;
  }
  service.isLoading = ()=>{
    return _inner.loadingState.LOADING_IFRAME
  }
  _inner.notifyUI = ()=>{
    service.getUIBind();
    _inner.ui.isLoading = service.isLoading();
    _inner.ui.whiteBoards = _inner.whiteBoards;
    _inner.ui.active = _inner.active;
    _inner.ui.isOpen = _inner.isOpen
    _inner.ui.active_id = _inner.active_id;
    _inner.ui.TEXT = service.getTextObject()
    _inner.ui.loadingState = _inner.loadingState
    _inner.ui.inCall = _inner.inCall;
    if(typeof _inner.onLoadingChangeCallback === "function"){
      _inner.onLoadingChangeCallback(service.isLoading())
    }
  }
  service.handleSocketMessage = (whiteBoards)=>{
    if(!Array.isArray(whiteBoards)) return
    for(let i = 0; i < whiteBoards.length ; i++){
      const whiteboard = whiteBoards[i];
      const participant = participantI.getParticipantById(whiteboard.created_by);
      const isOwner = callI.getActiveParticipantId() === whiteboard.created_by;
      if(whiteboard.is_active){
        const ref = service.getWhiteBoards().find(obj=>obj.mre_call_white_board_id === whiteboard.mre_call_white_board_id);
        if(!ref){
          _inner.whiteBoards.push(new MreCallWhiteBoard(whiteboard))
          if(!_inner.active){
            service.select();
          }
        }else{
          if(!ref.isOwner){
            ref.name = whiteboard.name
            ref.description = whiteboard.description
            ref.created_at = ref.created_at;
            ref.updated_at = ref.updated_at;
          }
        }
      }else{
        if(participant && _inner.isOpen){
          if(!isOwner)
            toastI.info(langI.get('WHITEBOARD_DELETED_BY',[participant.name]));
        }
        _inner.whiteBoards = _inner.whiteBoards.filter(obj=>obj.mre_call_white_board_id !== whiteboard.mre_call_white_board_id)
        if(_inner.whiteBoards.length){
          service.select();
        }else{
          _inner.active = null;
          _inner.notifyUI();
        }
      }
    }
    const isDeleted = !_inner.whiteBoards.find(obj=>obj.mre_call_white_board_id === _inner.active.mre_call_white_board_id)? true : false;
    if(isDeleted) service.select()
    _inner.notifyUI();
  }
  // service.useWhiteBoardEndpoint = useWhiteBoardEndpoint
  if(DEBUG){
    service.getInner = ()=> _inner;
    window.whiteBoardI = service;
  }
  _inner.onLoadingChangeCallback = null
  service.setOnLoadingChange = (callback)=>{
    if(typeof callback !== "function") return
    _inner.onLoadingChangeCallback = callback
  }
  service.debug = function(){
    if(!DEBUG) return
    console.log(`[+] WhiteBoard:`,...arguments)
  }
  service.onSaveImage = (file)=>{
    return service.onUploadAttachment(file);
  }
  service.EVENTS = {
    OPEN_WHITEBOARD:'OPEN_WHITEBOARD',
    WHITEBOARD_SWITCH:'WHITEBOARD_SWITCH'
  }
  service.getWhiteBoardEventName = ()=> "whiteboard";
  service.sendEvent = (action,payload={})=>{
    if(!action || typeof payload !== "object") return
    streamI.sendDataChannel({
      event:service.getWhiteBoardEventName(),
      action:action,
      ...payload
    })
  }
  service.handleEvent = (obj)=>{
    if(typeof obj !== "object") return
    const {participantId,action,whiteBoardName} = obj;
    if(participantId === callI.getActiveParticipantId()) return
    const participantName = participantI.getParticipantNameById(participantId);
    switch(action){
      case service.EVENTS.OPEN_WHITEBOARD:
        // if(_inner.isOpen) return
        toastI.info(langI.get('WHITEBOARD_STARTED', [participantName]));
        break;
      case service.EVENTS.WHITEBOARD_SWITCH:
        // if(!_inner.isOpen) return
        toastI.info(langI.get('WHITEBOARD_SWITCH', [participantName,whiteBoardName]));
        break;
    }
  }
  return service;
}
export const whiteBoardI = WhiteBoardI();