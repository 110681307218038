import { Component, Input } from "@angular/core";
import { toastI } from "src/assets/js/toastI";
import { userI } from "src/assets/js/userI";
import { guestI } from "src/assets/js/guestI";
import { callI } from "src/assets/js/callI";
import { navbarI } from "src/assets/js/navbarI";
import { langI } from "src/assets/js/langI";
import { modalI } from "src/assets/js/modals";
@Component({
  selector: "app-email-verification-modal",
  templateUrl: "./email-verification-modal.html",
  styleUrls: ["./email-verification-modal.scss"],
})
export class EmailVerificationModal {
  containerId: string = '';
  credentials: any = null;
  verification_code: string = "";
  isRequestingCode: boolean = false;
  isLoading:boolean = false;
  constructor() {}

  ngOnInit() {
    this.containerId = modalI.ID.EMAIL_VERIFICATION_MODAL;
    let data = modalI.getModalData(this.containerId);
    this.credentials = data && data.credentials? data.credentials: '';
  }

  public dismiss() {//check dismiss usage
    let response = {};
    if (navbarI.getActivePage() == "profile") {
      response = {result: false};
    }
    modalI.closeModal(modalI.ID.EMAIL_VERIFICATION_MODAL, true, response);
  }
  async verificateEmail() {
    if(!this.verification_code || this.isLoading)
      return;
    this.isLoading = true;
    if (navbarI.getActivePage() != "profile") {
      guestI.onSubmitVerificationCode(this.credentials.email_address, this.verification_code)
      .then((res)=>{
        if(res && res.IsAccepted) {
            toastI.success(langI.get('VERIFICATION_SUCCESSFUL'));
            setTimeout(()=>{
              modalI.closeModal(modalI.ID.EMAIL_VERIFICATION_MODAL, true, {result: true, code: this.verification_code});
            });
        } else {
          toastI.error(res.ReplyContent? res.ReplyContent: langI.get('SEND_VERIFICATION_CODE'));
        }
      }, (error)=>{
        toastI.error(typeof error==='string'? error: langI.get('SEND_VERIFICATION_CODE'));
      }).finally(()=>{
        this.isLoading = false
      })
    } else {
      if(this.credentials.email_address && this.credentials.email_address!==userI.getEmail()) {
        modalI.closeModal(modalI.ID.EMAIL_VERIFICATION_MODAL, true, {result: true, code: this.verification_code});
        this.isLoading = false;
        return;
      }
      let obj = {
        language: langI.getUserSelectedLanguage(),
        verificationCode: this.verification_code
      }
      userI.onUpdateAccountProfile(obj)
      .then(()=>{
        toastI.success(langI.get('ACCOUNT_UPDATE_SUCCESS'));
        if (!callI.getActiveCallId()) {
          navbarI.navForward("calls");
        } else {
          modalI.closeModal(modalI.ID.EMAIL_VERIFICATION_MODAL, true,  {result: true});
        }
      }, ()=>{})
      .finally(()=>{
        this.isLoading = false;
      })
    }
  }
  requestCode() {
    if(!this.credentials || !this.credentials.email_address || this.isRequestingCode)
      return;
    this.isRequestingCode = true;
    guestI.onRequestEmailVerificationCode(this.credentials.email_address)
    .then((res)=>{
      if(res.ReplyContent)
        toastI.success(res.ReplyContent);
    }, (error)=>{
      toastI.error(typeof error ==='string'? error: langI.get('ACCOUNT_UPDATE_FAIL_VERIFY'));
    }).finally(()=>{
      this.isRequestingCode = false;
    });
  }
}
