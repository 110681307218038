import { Component } from "@angular/core";
import { callI } from "src/assets/js/callI";
import { modalI } from "../../../assets/js/modals";
@Component({
  selector: "app-existing-call-modal",
  templateUrl: "./existing-call-modal.html",
  styleUrls: ["./existing-call-modal.scss"],
})
export class ExistingCallModalComponent {
  containerId: string = '';
  isReady: boolean = false;
  constructor() {}

  ngOnInit() {
    this.containerId = modalI.ID.EXISTING_CALL_MODAL;
    setTimeout(()=>{
      this.isReady = true;
    }, 150);
  }

  rejoin() {
    modalI.closeModal(modalI.ID.EXISTING_CALL_MODAL, true, {rejoin: true});
  }

  public dismiss() {
    modalI.closeModal(modalI.ID.EXISTING_CALL_MODAL);
  }
}
