import { callI } from "./callI";
import { cameraI } from "./cameraI";
import { langI } from "./langI";
import { networkI } from "./networkI";
import { toastI } from "./toastI";
import { userI } from "./userI";
import { debugI } from './debugI'
import {LocalAudioTrack,Room,RoomEvent,setLogLevel,LogLevel, Participant, Track, ParticipantEvent, ConnectionState, TrackPublication, ConnectionQuality, VideoQuality, VideoPreset, VideoPresets, ScreenSharePresets} from 'livekit-client'
import { streamI } from "./streamI";
import { micI } from "./micI";
import { screenI } from "./screenI";
import { screenshareI } from "./screenshareI";
import { backgroundEffectI } from "./backgroundEffectI";
import { speakerI } from "./speakerI";
import { videoShareI } from "./videoShare";
import { transcriptionI } from "./transcriptionI";
import { modalI } from "./modals";
import { participantI } from "./participantI";
import { busI } from "./busI";
import { handTrackerI } from "./handTrackerI";
import { sipI } from "./sipI";
import hark from './hark'
import { navbarI } from "./navbarI";
import { streamerI } from "./streamerI";
function LiveKitI(){
    const encoder = new TextEncoder()
    const decoder = new TextDecoder()
    const configured = new Map();
    const CONSTANTS = {
        SCREEN_SHARE_ID_SUFFIX:"_screen_share",
        FILE_SHARE_ID_SUFFIX:"_file_share",
        RESOLUTIONS: {
            h90: { width: 160, height: 90, maxBitrate: 90000, maxFramerate: 20 },
            h180: { width: 320, height: 180, maxBitrate: 160000, maxFramerate: 20 },
            h216: { width: 384, height: 216, maxBitrate: 180000, maxFramerate: 20 },
            h360: { width: 640, height: 360, maxBitrate: 450000, maxFramerate: 20 },
            h540: { width: 960, height: 540, maxBitrate: 800000, maxFramerate: 25 },
            h720: { width: 1280, height: 720, maxBitrate: 1700000, maxFramerate: 30 },
            h1080: { width: 1920, height: 1080, maxBitrate: 3000000, maxFramerate: 30 },
            h1440: { width: 2560, height: 1440, maxBitrate: 5000000, maxFramerate: 30 },
            h2160: { width: 3840, height: 2160, maxBitrate: 8000000, maxFramerate: 30 }
        },
        SOURCES:{
            CAMERA:Track.Source.Camera,
            AUDIO:Track.Source.Microphone,
            // SCREEN_SHARE_VIDEO:Track.Source.ScreenShare,
            // SCREEN_SHARE_AUDIO:Track.Source.ScreenShareAudio,
            // UNKNOWN:Track.Source.Unknown,
            FILE_SHARE:'file_share',
            SCREEN_SHARE:'screen_share'
        }
    }
    const getSimulcastObject = (resolution)=>{
        if(typeof resolution !== "object") return {}
        return {
            width: resolution.width,
            height: resolution.height,
            encoding:{
                maxFramerate:resolution.maxFramerate,
                maxBitrate:resolution.maxBitrate
            }
        }
    }
    CONSTANTS.ROOM_OPTIONS = {
        audioCaptureDefaults: {
            autoGainControl: true,
            deviceId: '',
            echoCancellation: true,
            noiseSuppression: true,
        },
        // videoCaptureDefaults: {
        //     deviceId: '',
        //     facingMode: 'user',
        //     resolution: {
        //         width: 1280,
        //         height: 720,
        //         frameRate: 30,
        //     },
        // },
        publishDefaults: {
            videoSimulcastLayers: [
                getSimulcastObject(CONSTANTS.RESOLUTIONS.h1080)
            ],
            // videoSimulcastLayers: [getSimulcastObject(VideoPresets.h1080)],
            screenShareEncoding: {
                maxBitrate: 1_500_000,
                maxFramerate: 30,
            },
            audioBitrate: 20_000
        }
    }
    const _inner = {};
    _inner.pinTimeout = null
    const PINS = {
        SCREEN_SHARE:1,
        FILE_SHARE:2,
        STREAM_SHARE:3
    }
    const PIN_ORDER = [PINS.SCREEN_SHARE,PINS.STREAM_SHARE,PINS.FILE_SHARE]
    const IGNORE_ORNDER_FOR_NEW_PINS = true
    const DBEUG = ['localhost','127.0.0.1','192.168'].some(value=>window.origin.indexOf(value) > -1);
    setLogLevel(DBEUG ? LogLevel.info : LogLevel.silent)
    const startRecordingLog = "START_RECORDING"
    const endRecordingLog = "END_RECORDING"
    const rtmpPattern = /(rtmps|rtmp):\/\/(?:([^\s@\/]+?)[@])?([^\s\/:]+)(?:[:]([0-9]+))?(?:(\/[^\s?#]+)([?][^\s#]+)?)?([#]\S*)?/
    _inner.rtmp_url = null
    _inner.isValid = false
    _inner.DIST = {
        YOUTUBE:"youtube",
        FACEBOOK:"facebook",
        TWITCH:"twitch",
        INSTAGRAM:"instagram",
        OTHER:"other"
    }
    _inner.streams = {}
    _inner.speechEvents = {}
    _inner.ENDPOINTS = {
        STREAMING:{
            LIST:"/api/call/{callId}/broadcast/list?callId={callId}&isIngress={isIngress}",
            PUBLISH:"/api/call/{callId}/broadcast/publish",
            STOP:"/api/call/{callId}/broadcast/stop"
        },
        RECORDING:{
            START:"/api/call/{callId}/recording/start",
            STOP:"/api/call/{callId}/recording/stop"
        },
        ROOM:{
            TOKEN:"/api/call/{callId}/channel/token"
        },
        SIP:{
            CALL:'/api/call/{callId}/live/stream/sip',
            STOP:'/api/call/{callId}/live/stream/sip'
        }
    }
    _inner.RTMP_OPTIONS = [
        {
            dist:_inner.DIST.FACEBOOK,
            url:"rtmps://live-api-s.facebook.com:443/rtmp"
        },
        {
            dist:_inner.DIST.INSTAGRAM,
            url:"rtmps://edgetee-upload-lis1-1.xx.fbcdn.net:443/rtmp"
        },
        {
            dist:_inner.DIST.TWITCH,
            url:"rtmp://live.twitch.tv/app"
        },
        {
            dist:_inner.DIST.YOUTUBE,
            url:"rtmp://a.rtmp.youtube.com/live2"
        },
        {
            dist:_inner.DIST.OTHER,
            url:null
        }
    ]
    _inner.joinedAt = {}
    _inner.api = {};
    _inner.api.post = {}
    _inner.api.post.publish = (url)=>{
        return new Promise((resolve,reject)=>{
            if(!url) return reject()
            const body = {
                call_id: callI.getActiveCallId(),
                url,
                participant:callI.getActiveParticipant()
            }
            if(!url) return reject();
            networkI.post({
                headers:service.getCustomHeaders(),
                url:service.replaceEndpointPlaceholders(_inner.ENDPOINTS.STREAMING.PUBLISH,{callId:callI.getActiveCallId()}),
                data:body
            }).then(res=>{
                if(Array.isArray(res.errors)){
                    toastI.error(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return reject(res);
                }
                resolve(res)
            },err=>{
                reject(err)
            })
        })
    }
    _inner.api.post.unpublish = (id)=>{
        return new Promise((resolve,reject)=>{
            if(!id) return reject();
            const body = {
                call_id: callI.getActiveCallId(),
                id: id,
                participant: callI.getActiveParticipant()
            }
            networkI.post({
                headers:service.getCustomHeaders(),
                url:service.replaceEndpointPlaceholders(_inner.ENDPOINTS.STREAMING.STOP,{callId:callI.getActiveCallId()}),
                data:body
            }).then(res=>{
                if(Array.isArray(res.errors)){
                    toastI.error(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return reject(res);
                }
                resolve(res)
            },err=>{
                reject(err)
            })
        })
    }
    _inner.api.post.startSipCall = (obj)=>{
        return new Promise((resolve,reject)=>{
            if(!obj || !obj.number || !obj.identity) return reject();
            const body = {
                room:callI.getActiveCallId(),
                number: obj.number,
                identity: obj.identity
            }
            networkI.post({
                headers:service.getCustomHeaders(),
                url:service.replaceEndpointPlaceholders(_inner.ENDPOINTS.SIP.CALL),
                data:body
            }).then(res=>{
                if(Array.isArray(res.errors)){
                    toastI.error(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return reject(res);
                }
                resolve(res)
            },err=>{
                reject(err)
            })
        })
    }
    _inner.api.delete = {}
    _inner.api.delete.stopSipCall = (number, participantId)=>{
        return new Promise((resolve,reject)=>{
            if(!number) return reject();
            const body = {
                room:callI.getActiveCallId(),
                number:number
            }
            if(participantId)
                body.participantId = participantId;
            networkI.delete({
                headers:service.getCustomHeaders(),
                url:service.replaceEndpointPlaceholders(_inner.ENDPOINTS.SIP.STOP),
                data:body
            }).then(res=>{
                if(Array.isArray(res.errors)){
                    toastI.error(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return reject(res);
                }
                resolve(res)
            },err=>{
                reject(err)
            })
        })
    }
    _inner.api.get = {}
    _inner.api.get.list = ()=>{
        return new Promise((resolve,reject)=>{
            networkI.get({
                headers:service.getCustomHeaders(),
                url:service.replaceEndpointPlaceholders(_inner.ENDPOINTS.STREAMING.LIST,{callId:callI.getActiveCallId()})
            })
            .then(res=>{
                if(Array.isArray(res.errors)){
                    toastI.error(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return reject(res);
                }
                resolve(res)
            },err=>{
                reject(err)
            })
        })
    }
    _inner.api.put = {};
    _inner.api.put.startRecording = ()=>{
        return new Promise((resolve,reject)=>{
            networkI.put({
                url:service.replaceEndpointPlaceholders(_inner.ENDPOINTS.RECORDING.START),
                data:{
                    ForwardData:{
                        ...callI.getActiveCallTicketPayload(),
                        participantId: callI.getActiveParticipantId(),
                        isHost:callI.isActiveCallByHost()
                    }
                },
                // headers:service.getCustomHeaders()
            }).then(res=>{
                if(Array.isArray(res.errors)){
                    toastI.error(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return reject(res);
                }
                resolve(res?.ForwardData)
            },err=>{
                reject(err)
            })
        })
    }
    _inner.api.put.stopRecording = ()=>{
        return new Promise((resolve,reject)=>{
            networkI.put({
                url:service.replaceEndpointPlaceholders(_inner.ENDPOINTS.RECORDING.STOP),
                data:{
                    ForwardData:{
                        call_id:callI.getActiveCallId(),
                        participantId: callI.getActiveParticipantId(),
                        isHost:callI.isActiveCallByHost()
                    }
                },
                // headers:service.getCustomHeaders()
            }).then(res=>{
                if(Array.isArray(res.errors)){
                    toastI.error(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return reject(res);
                }
                resolve(res?.ForwardData)
            },err=>{
                reject(err)
            })
        })
    }
    _inner.ui = null
    _inner.loadingState = {
        start:false,
        stop:false,
        list:false,
        recording:false
    }
    _inner.isLoading = false;
    const DEMO = false;
    _inner.result = null
    _inner.isRecording = false;
    _inner.LAYOUTS = {
        GRID:'grid',
        SPEAKER:'speaker',
        SINGLE_SPEAKER:'single'
    }
    _inner.layout = _inner.LAYOUTS.GRID;
    const service = {}
    service.replaceEndpointPlaceholders = (endpoint,obj={})=>{
        if(typeof endpoint !== "string" || typeof obj !== "object") return
        obj = {
            callId:callI.getActiveCallId(),
            ...obj
        }
        return endpoint.replace(/{(\w+)}/g, (match, key) => obj[key] || false);
    }
    service.getEndpoints = ()=> _inner.ENDPOINTS
    service.getCustomHeaders = ()=>{
        return {
            'X-PARTICIPANT-ID':callI.getActiveParticipantId()
        }
    }
    service.isActive = ()=> _inner.result && typeof _inner.result === "object" && Array.isArray(_inner.result.urls) && _inner.result.urls.length > 0
    service.isRecording = ()=> _inner.isRecording
    service.getUIBind = ()=>{
        if(_inner.ui) return _inner.ui;
        _inner.ui = {
            isRecording:service.isRecording(),
            isLoading:_inner.isLoading,
            LAYOUTS:_inner.LAYOUTS,
            layout:_inner.layout,
            selectLayout:service.selectLayout,
            isActive:service.isActive(),
            publish:service.publish,
            outputs:service.getOutputs(),
            DIST:_inner.DIST,
            RTMP_OPTIONS:_inner.RTMP_OPTIONS,
            unpublish:service.unpublish,
            isValid:_inner.isValid,
            rtmp_url:_inner.rtmp_url,
            validate:service.validate,
            list:service.list,
            toggleRecording:service.toggleRecording,
            loadingState:_inner.loadingState,
            isStreamEnabled: service.isRoomConnected(),
            volumes: _inner.volumes,
            getOrSetParticipantVolume: service.getOrSetParticipantVolume,
            muted: _inner.muted,
            getOrSetParticipantMuted: service.getOrSetParticipantMuted,
            paused: _inner.paused,
            getOrSetParticipantPaused: service.getOrSetParticipantPaused,
            isPausing: _inner.isPausing,
            isMuting: _inner.isMuting,
            toggleMute: service.toggleMute,
            togglePause: service.togglePause,
            tsEnabled: _inner.tsEnabled,
            stsEnabled: _inner.stsEnabled,
            isLoadingTranscription: _inner.isLoadingTranscription,
            isLoadingSTS: _inner.isLoadingSTS,
            getOrSetParticipantTranscription: service.getOrSetParticipantTranscription,
            toggleTranscription: service.toggleTranscription,
            toggleSpeechToSpeech: service.toggleSpeechToSpeech,
            selectParticipantLanguageModalOpen: _inner.selectParticipantLanguageModalOpen,
            languages: _inner.languages,
            openSelectLanguageParticipant: service.openSelectLanguageParticipant,
            displayLangImage: true,
            canShowLanguageCode: true,
            moveToWaitingRoom: service.moveToWaitingRoom,
            inaudible: _inner.inaudible,
            toggleInaudible: service.toggleInaudible
        };
        return _inner.ui;
    }
    service.selectLayout = (layout)=>{
        if(!layout) return
        if(_inner.layout === layout) return
        _inner.layout = layout;
        _inner.notifyUI();
        _inner.api.post.layout(callI.getActiveCallId(),_inner.layout)
        .then((res)=>{
            if(res.success){
                toastI.success('Layout updated successfully')
            }else{
                toastI.error('Error while updating the layout')
            }
        },()=>{
            toastI.error('Error while updating the layout')
        })
        .finally(()=>{
            _inner.notifyUI();
        })
    }
    _inner.notifyUI = ()=>{
        service.getUIBind();
        _inner.ui.layout = _inner.layout;
        _inner.ui.isActive = service.isActive()
        _inner.ui.outputs = service.getOutputs();
        _inner.ui.isValid = _inner.isValid
        _inner.ui.loadingState = _inner.loadingState;
        _inner.ui.isLoading = _inner.isLoading;
        _inner.ui.isRecording = service.isRecording();
        _inner.ui.isStreamEnabled = service.isRoomConnected();
        _inner.ui.volumes = _inner.volumes;
        _inner.ui.muted = _inner.muted;
        _inner.ui.paused = _inner.paused;
        _inner.ui.isMuting = _inner.isMuting;
        _inner.ui.isPausing = _inner.isPausing;
        _inner.ui.tsEnabled = _inner.tsEnabled;
        _inner.ui.stsEnabled = _inner.stsEnabled;
        _inner.ui.isLoadingTranscription = _inner.isLoadingTranscription
        _inner.ui.isLoadingSTS = _inner.isLoadingSTS;
        _inner.ui.selectParticipantLanguageModalOpen = _inner.selectParticipantLanguageModalOpen
        _inner.ui.languages = _inner.languages
        _inner.ui.inaudible = _inner.inaudible;
    }
    service.clear = ()=>{
        _inner.isLoading = false;
        for(let key in _inner.loadingState){
            _inner.loadingState[key] = false
        }
        _inner.result = null
        _inner.rtmp_url = null
        if(_inner.ui){
            _inner.ui.rtmp_url = null
        }
        _inner.isValid = false
        _inner.volumes = {}
        // tmp work clear muted and paused
        _inner.paused = {}
        _inner.muted = {};
        _inner.tsEnabled = {};
        _inner.stsEnabled = {};
        _inner.isPausing = {}
        _inner.isMuting = {};
        _inner.isLoadingTranscription = {};
        _inner.isLoadingSTS = {};
        _inner.languages = {};
        _inner.inaudible = {};
        _inner.isRecording = false;
        _inner.joinedAt = {}
        configured.clear();
        service.stopSpeechEvents()
        _inner.notifyUI();
    }
    service.publish = ()=>{
        if(!_inner.isValid || _inner.loadingState.start) return
        const opt = {containerRef:{}};
        toastI.info('Publishing the stream ...', 'infinit', opt);
        service.getUIBind();
        _inner.loadingState.start = true;
        _inner.notifyUI();
        let handleEndPublish = ()=>{
            if(opt.containerRef) toastI.closeToast(opt.containerRef);
            if(_inner.loadingState.errorMessage)
                toastI.error(_inner.loadingState.errorMessage);
            _inner.loadingState.errorMessage = '';
            _inner.loadingState.start = false;
            _inner.notifyUI();
        }
        clearTimeout(_inner.publishTimeoutTimer);
        _inner.publishTimeoutTimer = setTimeout(()=>{
            if(!_inner.loadingState.start)
                return;
            //after this timeout, it means there is an issue - tmp work
            _inner.loadingState.errorMessage = langI.get('STREAM_OUT_PUBLISH_ERROR');
            handleEndPublish();
        }, 60000);
        _inner.api.post.publish(_inner.ui.rtmp_url)
        .then((res)=>{
            if(res.success && !res.timeout) {
                toastI.success(langI.get('STREAM_OUT_PUBLISHED'))
                _inner.result = res.result;
                _inner.ui.rtmp_url = null
                _inner.isValid = false
                _inner.notifyUI();
            }else{
                _inner.loadingState.errorMessage = res&&res.alreadyUsed ? "The stream URL has already been utilized" : langI.get('STREAM_OUT_PUBLISH_ERROR');
            }
        },(err)=>{
            _inner.loadingState.errorMessage = langI.get('STREAM_OUT_PUBLISH_ERROR');
        }).finally(()=>{
            handleEndPublish();
        })
    }
    service.start = ()=>{
        if(!callI.getIsInternal()) return
        console.log(startRecordingLog)
    }
    service.getActiveStreamIds = (all=false)=>{
        if(!_inner.result) return [];
        if(!Array.isArray(_inner.result.urls)) return []
        return _inner.result.urls.filter(obj=>(all || obj.participantId === callI.getActiveParticipantId())).map(obj=>obj.id)
    }
    service.stop = (isStop=false)=>{
        if(!callI.getIsInternal()){
            service.list(()=>{
                const ids = service.getActiveStreamIds(isStop && callI.isActiveCallByHost())
                Promise.all(ids.map(id=>_inner.api.post.unpublish(id)))
                .then(()=>{},()=>{})
                .finally(()=>{
                    service.clear();
                })
            })
            if(isStop)
                service.stopRecording();
            return
        }
        console.log(endRecordingLog)
    }
    service.getOutputs = ()=>{
        if(!service.isActive()) return []
        return _inner.result.urls.map(({participantId,url,id})=>{
            return {
                id:id,
                isOwner: callI.isActiveCallByHost() || participantId && participantId === callI.getActiveParticipantId() ? true : false,
                participantId:participantId||"",
                url:url||"",
                dist:service.getDistFromUrl(url)
            }
        })
    }
    service.getDistFromUrl = (url)=>{
        if(typeof url !== "string" || !url.length) return _inner.DIST.OTHER
        const hostname = url.slice(0,url.lastIndexOf('/'))
        if(!hostname) return _inner.DIST.OTHER
        if(hostname.toLowerCase().includes('youtube')) return _inner.DIST.YOUTUBE
        if(hostname.toLowerCase().includes('facebook')) return _inner.DIST.FACEBOOK
        if(hostname.toLowerCase().includes('twitch')) return _inner.DIST.YOUTUBE
        if(hostname.toLowerCase().includes('fbcdn')) return _inner.DIST.INSTAGRAM
        return _inner.DIST.OTHER
    }
    service.unpublish = (output)=>{
        if(typeof output !== "object") return
        const opt = {containerRef:{}};
        toastI.info('Stopping the stream ...', 'infinit', opt);
        output.isLoading = true
        _inner.api.post.unpublish(output.id).then((res)=>{
            if(res?.success){
                _inner.result = res.result;
                _inner.notifyUI();
                toastI.success(langI.get('STREAM_OUT_STOPPED'))
            }else{
                toastI.error(langI.get('STREAM_OUT_STOP_ERROR'))
            }
        },()=>{
            toastI.error(langI.get('STREAM_OUT_STOP_ERROR'))
        })
        .finally(()=>{
            if(opt.containerRef) toastI.closeToast(opt.containerRef)
            output.isLoading = false
        })
    }
    service.list = (callback)=>{
        if(_inner.loadingState.list) return
        _inner.loadingState.list = true
        _inner.notifyUI();
        _inner.api.get.list().then(res=>{
            if(res?.success){
                _inner.result = res.result
            }else{
                _inner.result = null
            }
        },err=>{})
        .finally(()=>{
            _inner.loadingState.list = false
            _inner.notifyUI()
            typeof callback === "function" && callback()
        })
    }
    service.validate = ()=>{
        service.getUIBind();
        _inner.isValid = rtmpPattern.test(_inner.ui.rtmp_url)
        _inner.notifyUI();
    }
    service.startRecording = ()=>{
        if(_inner.loadingState.recording) return
        _inner.loadingState.recording = true
        _inner.notifyUI();
        _inner.api.put.startRecording().then((res)=>{
            const isRecording = !!(res && res.success);
            if(callI.isActiveCallByHost() && !res.isAlreadyRecording){
                if(!isRecording){
                    toastI.info(langI.get('TRY_AGAIN_OR_CONTACT'))
                }
            }
        },()=>{})
        .finally(()=>{
            _inner.loadingState.recording = false
            _inner.notifyUI();
        })
    }
    service.stopRecording = ()=>{
        if(_inner.loadingState.recording || !_inner.isRecording) return
        _inner.loadingState.recording = true
        _inner.notifyUI();
        _inner.api.put.stopRecording().then((res)=>{
            const isRecording = !!!(res && res.success);
            //tmp work - should handle exact state here (isRecording)
            _inner.isRecording = false;
            if(isRecording){
                toastI.info(langI.get('TRY_AGAIN_OR_CONTACT'))
            }
        },()=>{
            toastI.info(langI.get('TRY_AGAIN_OR_CONTACT'))
        })
        .finally(()=>{
            _inner.loadingState.recording = false
            _inner.notifyUI();
        })
    }
    service.toggleRecording = ()=>{
        if(service.isRecording()){
            service.stopRecording();
        }else{
            service.startRecording();
        }
    }
    if(DBEUG){
        service.getInner = ()=> _inner;
        (window).livekitI = service;
    }
    _inner.room = null;
    // rooms related
    _inner.api.post.token = ()=>{
        return new Promise((resolve,reject)=>{
            if(!callI.getActiveCall()) return reject();
            networkI.post({
                headers:service.getCustomHeaders(),
                url:service.replaceEndpointPlaceholders(_inner.ENDPOINTS.ROOM.TOKEN,{callId:callI.getActiveCallId()}),
                data:{
                    room: callI.getActiveCallId(),
                    metadata:service.getMetatada(false)
                }
            }).then(res=>{
                if(Array.isArray(res.errors)){
                    toastI.error(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return reject(res);
                }
                resolve(res)
            },err=>{
                reject(err)
            })
        })
    }
    service.sendData = (id,payload)=>{
        if(!payload) return
        const room = service.getOrInitRoom();
        if(!room) return
        payload.identity = callI.getActiveParticipantId();
        if(id) payload.targetId = id;
        payload = typeof payload === "object" ? JSON.stringify(payload) : payload;
        if(typeof payload !== "string") return
        room.localParticipant.publishData(encoder.encode(payload), {reliable: true}).then(()=>{},()=>{})
    }
    service.clearRoomEvents = ()=>{
        const room = service.getOrInitRoom(false);
        if(room instanceof Room){
            for(let event in RoomEvent){
                room.listeners(event).forEach(l=>room.removeListener(event,l))
            }
        }
    }
    service.handleSpeakChange = (identity,speaking)=>{
        if(!identity || (service.isScreenShareParticipant(identity) && !service.isScreenShareWithAudio(identity))) return
        const mic_status = streamI.getElementById(identity)?.mic_status;
        if(!mic_status) return
        if(speaking) {
            mic_status.active?.();
        } else {
            mic_status.inactive?.();
        }
    }
    service.onRoomMetadataChanged = (metadata)=>{
        if(typeof metadata !== "string") return
        try{
            const obj = JSON.parse(metadata)
            if(typeof obj.isRecording === "boolean"){
                const newIsRecording = obj.isRecording
                const shouldNotify = _inner.isRecording !== newIsRecording
                if(shouldNotify){
                    if(newIsRecording){
                        toastI.info(langI.get('RECORDING_START'))
                    }else{
                        toastI.info(langI.get('RECORDING_STOP'))
                    }
                }
                _inner.isRecording = newIsRecording
                _inner.notifyUI()
            }
        }catch(err){}
    }
    service.initRoomEvents = ()=>{
        const room = service.getOrInitRoom();
        if(!room) return
        service.clearRoomEvents();
        room.on(RoomEvent.RoomMetadataChanged,(metadata)=>{
            service.onRoomMetadataChanged(metadata)
        })
        room.on(RoomEvent.ActiveDeviceChanged, () => {});
        room.on(RoomEvent.AudioPlaybackStatusChanged, () => {});
        room.on(RoomEvent.Connected, () => {
            service.list()
            service.onRoomMetadataChanged(room.metadata)
            _inner.notifyUI();
            const micUI = micI.getUIBind();
            const cameraUI = cameraI.getUIBind();
            let hasAudio = false;
            let hasVideo = false;
            const promises = [];
            if(micI.isEnabled() && !service.isTrackEnabled(room.localParticipant.identity,'audio')){
                hasAudio = true;
                promises.push(service.onTogglePublishTrack('audio',true,micI.getTrack(),CONSTANTS.SOURCES.AUDIO))
            }
            if(cameraI.isEnabled() && !service.isTrackEnabled(room.localParticipant.identity,'video')){
                hasVideo = true;
                const track = backgroundEffectI.isEnabled() ? backgroundEffectI.getTrack() : cameraI.getTrack();
                promises.push(service.onTogglePublishTrack('video',true,track,CONSTANTS.SOURCES.CAMERA))
            }
            micUI.setIsWaiting(hasAudio)
            cameraUI.setIsWaiting(hasVideo)
            Promise.all(promises).then((res)=>{},(err)=>{})
            .finally(()=>{
                if(hasAudio) micUI.setIsWaiting(false)
                if(hasVideo) cameraUI.setIsWaiting(false)
            })
            setTimeout(()=>{
                service.getParticipants()
                .forEach(service.handleParticipantMetadata)
            },100)
            service.onParticipantsUpdate();
            service.onSpeakerStateChange();
        });
        room.on(RoomEvent.ConnectionStateChanged, () => {
            _inner.notifyUI();
        });
        room.on(RoomEvent.DCBufferStatusChanged, () => {});
        room.on(RoomEvent.DataReceived, (payload,participant,kind) => {
            payload = decoder.decode(payload)
            try{
                payload = JSON.parse(payload)
                streamI.onUserMessage(payload.identity,payload)
                streamI.onMessage(payload.identity,payload)
            }catch(err){
                console.log(err)
            }
        });
        // room.on(RoomEvent.Disconnected, () => {});
        room.on(RoomEvent.EncryptionError, () => {});
        room.on(RoomEvent.LocalAudioSilenceDetected, () => {});
        room.on(RoomEvent.LocalTrackPublished, (publication,participant) => {
            service.onTrackChange(publication.track,publication,participant,true)
            service.onParticipantsUpdate();
            service.togglePublicationUI(publication,true)
        });
        room.on(RoomEvent.LocalTrackUnpublished, (publication,participant) => {
            service.onTrackChange(publication.track,publication,participant,false)
            service.onParticipantsUpdate();
            service.togglePublicationUI(publication,false)
        });
        room.on(RoomEvent.MediaDevicesChanged, () => {});
        room.on(RoomEvent.MediaDevicesError, () => {});
        room.on(RoomEvent.ParticipantConnected, function(participant){
            delete _inner.streams[participant.identity]
            service.handleParticipantMetadata(participant)
            service.onParticipantsUpdate();
        });
        room.on(RoomEvent.ParticipantDisconnected,function(participant,b){
            // sip
            setTimeout(()=>{
                if(service.isSipParticipant(participant.identity)){
                    const name = service.getParticipantName(participant);
                    if(sipI.isOnGoingInviteExists(name)){
                        sipI.updateOngoingInvite({
                            action:"Retry",
                            cancel:"",
                            state:"error"
                        })
                    }
                }
            })
            service.onParticipantsUpdate();
        });
        room.on(RoomEvent.ParticipantEncryptionStatusChanged, () => {});
        room.on(RoomEvent.ParticipantMetadataChanged, (oldMetadata,participant) => {
            service.handleParticipantMetadata(participant)
        });
        room.on(RoomEvent.ParticipantNameChanged, () => {});
        room.on(RoomEvent.ParticipantPermissionsChanged, () => {});
        room.on(RoomEvent.Reconnected, () => {});
        room.on(RoomEvent.Reconnecting, () => {});
        room.on(RoomEvent.RecordingStatusChanged, () => {});
        room.on(RoomEvent.RoomMetadataChanged, () => {});
        room.on(RoomEvent.SignalConnected, () => {});
        room.on(RoomEvent.TrackMuted, () => {
        });
        room.on(RoomEvent.TrackPublished, () => {
            service.onParticipantsUpdate();
        });
        room.on(RoomEvent.TrackStreamStateChanged, () => {
        });
        room.on(RoomEvent.TrackSubscribed, (track,publication,participant) => {
            // if(publication && !service.isScreenShareParticipant(publication.trackName)) {
            //     // publication.setVideoDimensions(CONSTANTS.RESOLUTIONS.h720)
            //     publication.setVideoQuality(VideoQuality.HIGH)
            // }
            service.onTrackChange(track,publication,participant,true)
            service.updateVideoStream(participant.identity)
        });
        room.on(RoomEvent.TrackSubscriptionFailed, () => {
        });
        room.on(RoomEvent.TrackSubscriptionPermissionChanged, () => {});
        room.on(RoomEvent.TrackSubscriptionStatusChanged, () => {});
        room.on(RoomEvent.TrackUnmuted, () => {
        });
        room.on(RoomEvent.TrackUnpublished, function(publication,participant){
            service.onTrackChange(publication.track,publication,participant,false)
            service.updateVideoStream(participant.identity)
        });
        room.on(RoomEvent.TrackUnsubscribed, () => {
        });
    }
    service.updateVideoStream = (identity)=>{
        if(!identity) return
        const video = service.getVideoElement(identity);
        if(!video) return
        video.srcObject = service.getOrInitStream(identity)
        video.play().then(()=>{},()=>{})
    }
    service.togglePublicationUI = (publication, enable)=>{
        if(!(publication instanceof TrackPublication)) return
        if(!(publication.source === service.getSources().CAMERA || publication.source === service.getSources().AUDIO)) return
        switch (publication.kind) {
            case 'audio':
                let micUI = micI.getUIBind();
                if (enable) {
                    micUI.open?.(true)
                }else{
                    micUI.stop?.(true);
                }
                break;
            case 'video':
                let cameraUI = cameraI.getUIBind();
                if (enable) {
                    cameraUI.open?.(true);
                }else{
                    cameraUI.stop?.(true);
                }
                break;
        }
    }
    service.updateDropdownItems = ()=>{
        const participants = service.getParticipants();
        for(let i = 0 ; i < participants.length; i++){
            const participant = participants[i];
            const dropdownItems = streamI.getElementById(participant.identity)?.dropdownItems;
            if(Array.isArray(dropdownItems)){
                dropdownItems.forEach(element=>{
                    if(element && typeof element.init === "function"){
                        element.init();
                    }
                })
            }
        }
    }
    service.handleParticipantMetadata = (participant,clear=true)=>{
        try{
            const lastMuted = service.getOrSetParticipantMuted(participant.identity)
            const lastPaused = service.getOrSetParticipantPaused(participant.identity)
            const lastTsEnabled = service.getOrSetParticipantTranscription(participant.identity)
            const lastStSEnabled = service.getOrSetParticipantSpeechToSpeech(participant.identity)
            const parsedMetadata = JSON.parse(participant.metadata);
            _inner.muted[participant.identity] = parsedMetadata.isMuted;
            _inner.paused[participant.identity] = parsedMetadata.isPaused;
            _inner.tsEnabled[participant.identity] = parsedMetadata.isTSEnabled;
            _inner.stsEnabled[participant.identity] = parsedMetadata.isSTSEnabled;
            _inner.languages[participant.identity] = parsedMetadata.language;
            if(clear && lastMuted !== service.getOrSetParticipantMuted(participant.identity)){
                delete _inner.isMuting[participant.identity]
            }
            if(clear && lastPaused !== service.getOrSetParticipantPaused(participant.identity)){
                delete _inner.isPausing[participant.identity]
            }
            if(clear && lastTsEnabled !== service.getOrSetParticipantTranscription(participant.identity)){
                delete _inner.isLoadingTranscription[participant.identity]
            }
            if(clear && lastStSEnabled !== service.getOrSetParticipantSpeechToSpeech(participant.identity)){
                delete _inner.isLoadingSTS[participant.identity]
            }
            service.updateDropdownItems();
        }catch(err){}
        _inner.notifyUI();
    }
    service.isRoomActive = ()=>{
        //here maybe we need to check connected state, will need to confirm
        return _inner.room && _inner.room.roomInfo && _inner.room.roomInfo.name? true: false;
    }
    service.getOrInitRoom = (initialize=false,options={})=>{
        if(_inner.room instanceof Room) return _inner.room
        if(!initialize) return
        options = typeof options === "object" ? options : {};
        _inner.room = new Room({
            adaptiveStream:false,
            ...CONSTANTS.ROOM_OPTIONS,
            ...options
        });
        return _inner.room instanceof Room ? _inner.room : null;
    }
    service.getTracks = (identity)=>{
        const participant = service.getParticipants().find(p=>p.identity === identity);
        if(!(participant instanceof Participant)) return []
        const tracks = participant.getTrackPublications().map(publication=>publication.track.mediaStreamTrack)
        return tracks;
    }
    service.getParticipant = (identity,includeScreenShareAndFileShare=false)=>{
        if(!identity) return
        let participants = []
        if(includeScreenShareAndFileShare){
            participants = service.getParticipants(true)
        }else{
            const room = service.getOrInitRoom(false);
            participants = room? Array.from(room.remoteParticipants).map(([key,value])=>value).concat(room.localParticipant) : []
        }
        return participants.find(p=>p.identity === identity)
    }
    service.getOwnerIdentity = (identity)=>{
        if(typeof identity !== "string") return
        if(service.isFileShareParticipant(identity))
            return identity.split(CONSTANTS.FILE_SHARE_ID_SUFFIX)[0]
        if(service.isScreenShareParticipant(identity))
            return identity.split(CONSTANTS.SCREEN_SHARE_ID_SUFFIX)[0]
        return identity
    }
    service.isOwner = (identity)=>{
        if(typeof identity !== "string") return false
        return identity.indexOf(callI.getActiveParticipantId()) > -1;
    }
    service.getParticipants = (includeLocalParticipant=true)=>{
        const room = service.getOrInitRoom(false);
        if(!room) return []
        let participants = Array.from(room.remoteParticipants).map(([key,value])=>value)
        if(includeLocalParticipant && room.localParticipant){
            participants.push(room.localParticipant);
        }
        let newParticipants = [];
        for(let i = 0 ; i < participants.length; i++){
            const participant = participants[i];
            const publications = participant.getTrackPublications();
            if(publications.length > 0 && !service.isOwner(participant.identity)){
                const hasFileShare = publications.some(publication=>service.isFileSharePublication(publication))
                const hasScreenShare = publications.some(publication=>service.isScreenSharePublication(publication))
                const newIdentity = {
                    file_share:participant.identity+CONSTANTS.FILE_SHARE_ID_SUFFIX,
                    screen_share:participant.identity+CONSTANTS.SCREEN_SHARE_ID_SUFFIX
                }
                if(hasFileShare && !newParticipants.find(p=>p.identity === newIdentity.file_share) && !videoShareI.getPlayerById(newIdentity.file_share)){
                    const obj = {
                        ...participant,
                        identity:newIdentity.file_share,
                        isFileShare:true,
                        joinedAt:new Date()
                    }
                    obj.isAudioShare = service.isAudioShareParticipant(obj)
                    if(obj.isAudioShare){
                        obj.name+="'s audio share"
                    }else{
                        obj.name+="'s video share"
                    }
                    newParticipants.push(obj)
                }
                if(hasScreenShare && !newParticipants.find(p=>p.identity === newIdentity.screen_share)){
                    const obj = {
                        ...participant,
                        identity:newIdentity.screen_share,
                        isScreenShare:true
                    }
                    obj.name+="'s Screen"
                    newParticipants.push(obj)
                }
            }
        }
        return participants.concat(newParticipants)
    }
    service.getOrInitStream = (identity)=>{
        if(!identity) return
        if(!_inner.streams[identity]){
            _inner.streams[identity] = new MediaStream([])
        }
        return _inner.streams[identity];
    }
    service.onTogglePublishFileShareStream = (publish,stream)=> service.onTogglePublishStream(publish,stream,CONSTANTS.SOURCES.FILE_SHARE)
    service.onTogglePublishScreenShareStream = (publish,stream)=> service.onTogglePublishStream(publish,stream,CONSTANTS.SOURCES.SCREEN_SHARE)
    service.getPublishedFileShareTracks = ()=>{
        const room = service.getOrInitRoom(false);
        const audioTrack = Array.from(room.localParticipant.audioTrackPublications).map(([id,publication])=>publication).find((publication)=>publication.trackName === CONSTANTS.SOURCES.FILE_SHARE)?.track?.mediaStreamTrack
        const videoTrack = Array.from(room.localParticipant.videoTrackPublications).map(([id,publication])=>publication).find((publication)=>publication.trackName === CONSTANTS.SOURCES.FILE_SHARE)?.track?.mediaStreamTrack
        return {
            audioTrack,
            videoTrack
        }
    }
    service.onTogglePublishStream = async (publish=true,stream,name)=>{
        if(!(stream instanceof MediaStream) || !name) return
        let videoTrack;
        let audioTrack;
        if(name === CONSTANTS.SOURCES.FILE_SHARE){
            const publishedFileShareTracks = service.getPublishedFileShareTracks();
            videoTrack = stream.getVideoTracks()[0] || publishedFileShareTracks.videoTrack
            audioTrack = stream.getAudioTracks()[0] || publishedFileShareTracks.audioTrack
        }else{
            videoTrack = stream.getVideoTracks()[0];
            audioTrack = stream.getAudioTracks()[0];
        }
        if(videoTrack){
            try{
                await service.onTogglePublishTrack(name,publish,videoTrack,name)
            }catch(err){
                console.log(err)
            }
        }
        if(audioTrack){
            try{
                await service.onTogglePublishTrack(name,publish,audioTrack,name)
            }catch(err){
                console.log(err)
            }
        }
    }
    service.getActiveLocalAudioTrack = ()=>{
        return service.getOrInitRoom(false)?.localParticipant?.getTrackPublications().find(p=>p.kind === "audio")?.track
    }
    service.onTogglePublishTrack = async (kind,publish=true,track,name)=>{
        if(typeof kind !== "string") return
        const room = service.getOrInitRoom();
        if(!room) return
        let tracks = [];
        if(track){
            // track.muted = !speakerI.isEnabled();
            tracks.push(track)
        }else{
            const videoTrack = Array.from(room.localParticipant.videoTrackPublications).map(([key,value])=>value).find(pub=>pub.trackName !== CONSTANTS.SOURCES.SCREEN_SHARE)?.track?.mediaStreamTrack;
            const audioTrack = Array.from(room.localParticipant.audioTrackPublications).map(([key,value])=>value)[0]?.track?.mediaStreamTrack;
            switch(kind.toLowerCase()){
                case 'video':
                    if(publish){
                        tracks.push(cameraI.getTrack())
                    }else{
                        tracks.push(videoTrack)
                    }
                    break;
                case 'audio':
                    if(publish){
                        tracks.push(micI.getTrack())
                    }else{
                        tracks.push(audioTrack)
                    }
                    break;
            }
        }
        tracks = tracks.filter(track=>track instanceof MediaStreamTrack || track instanceof LocalAudioTrack)
        if(tracks.length === 0) return
        try{
            if(publish){
                for(let i = 0 ; i < tracks.length; i++){
                    const mediaStreamTrack = tracks[i]
                    try{
                        await room.localParticipant.publishTrack(mediaStreamTrack,{
                            name:name,
                            simulcast:true,
                            source:service.getTrackSource(mediaStreamTrack)
                        })
                    }catch(err){}
                }
            }else{
                for(let i = 0 ; i < tracks.length; i++){
                    try{
                        await room.localParticipant.unpublishTrack(tracks[i],{
                            name:name
                        })
                    }catch(err){}
                }
            }
            service.updateMetadata();
        }catch(err){
            throw err
        }
    }
    service.updateAllMicStatus = ()=>{
        const participants = service.getParticipants(true);
        for(let i = 0 ; i < participants.length; i++){
            service.updateMicStatus(participants[i].identity)
        }
    }
    service.hasVideo = (participant)=>{
        if(participant instanceof Participant){
            return participant.getTrackPublications().filter(pub=>{
                return pub.kind === "video" && pub.trackName !== CONSTANTS.SOURCES.SCREEN_SHARE
            }).length > 0;
        }
        return false
    }
    service.hasAudio = (participant)=>{
        if(participant instanceof Participant){
            return participant.getTrackPublications().filter(pub=>{
                return pub.kind === "audio" && pub.trackName !== CONSTANTS.SOURCES.FILE_SHARE
            }).length > 0;
        }
        return false
    }
    service.handleParticipantEvents = (participant)=>{
        if(!(participant instanceof Participant) || participant.has_events) return
        participant.has_events = true;
        const events = {
            [ParticipantEvent.IsSpeakingChanged]:(speaking)=>{
                service.handleSpeakChange(participant.identity,speaking)
            },
            [ParticipantEvent.ConnectionQualityChanged]:(quality)=>{
                const container = document.getElementById(participant.identity);
                if(!container) return
                if(quality === ConnectionQuality.Lost){
                    container.classList.add('op-5')
                }else{
                    container.classList.remove('op-5')
                }
            }
        }
        for(let key in events){
            participant.listeners(key).forEach(l=>participant.removeListener(key,l))
            participant.on(key,events[key])
        }
    }
    service.onParticipantsUpdate = async ()=>{
        if(streamI.isLeavingCall()) return
        streamI.onParticipantsUpdate();
        const mainCallContainer = streamI.getMainContainer(document.querySelector('#MainCallsContainer-wrapper'))
        if(!mainCallContainer) return
        const participants = service.getParticipants(true);
        for(let i = 0 ; i < participants.length; i++){
            const participant = participants[i];
            const update = ()=>{
                _inner.volumes[participant.identity] = service.getOrSetParticipantVolume(participant.identity);
            }
            update();
            if((participant.isScreenShare || participant.isFileShare) && service.isOwner(participant.identity)){
                continue;
            }
            if(!participant.isFileShare && !participant.isScreenShare){
                service.handleParticipantEvents(participant)
            }
            // todo handle hand tracking
            // const handTrackerPublication = participant.getTrackPublications().find(p=>p.trackName.indexOf(handTrackerI.getSuffix()) >-1);
            // if(handTrackerPublication instanceof TrackPublication && handTrackerPublication.trackName.indexOf(handTrackerI.getTargetId()) === -1){
            //     const id = handTrackerPublication.trackName.split(handTrackerI.getSuffix())[0];
            //     const video = service.getVideoElement(id);
            //     const stream = service.getOrInitStream(handTrackerPublication.trackName);
            //     if(stream.getVideoTracks().length === 0){
            //         stream.addTrack()
            //     }
            //     if(video && stream && video.srcObject !== stream){
            //         video.srcObject = stream;
            //     }
            // }else{
            //     const video = service.getVideoElement(participant.identity);
            //     const stream = service.getOrInitStream(participant.identity);
            //     if(video && stream && video.srcObject !== stream){
            //         video.srcObject = stream;
            //     }
            // }
            if(service.isConfigured(participant.identity)) continue;
            videoShareI.resume()
            service.setConfigured(participant.identity)
            const container = service.getView(participant);
            try{
                await streamI.configureElements(participant.identity,container,service.getParticipantName(participant))
                if(container) mainCallContainer.appendChild(container)
                service.onParticipantJoin(participant.identity)
            }catch(err){}
            // sip
            setTimeout(()=>{
                service.checkSIPParticipant(participant)
            })
            //
            update();
        }
        streamI.MainCallsContainer();
        const containers = Array.from(document.querySelectorAll(".eco-video"));
        for(let i = 0; i < containers.length; i++){
            const container = containers[i];
            if(container.hasAttribute('data-video-share') || transcriptionI.isTranscriptionContainer(container)) continue;
            if(!participants.find(p=>p.identity === container.id)){
                service.stopSpeechEvents(container.id)
                service.clearConfigured(container)
                container.parentNode.removeChild(container)
                delete _inner.volumes[container.id]
                delete _inner.paused[container.id]
                delete _inner.streams[container.id]
                streamI.unpinView(container)
                service.onParticipantLeft(container.id)
            }
        }
        streamI.MainCallsContainer();
        _inner.notifyUI()
    }
    service.checkSIPParticipant = (participant)=>{
        if(!participant) return
        if(!service.isSipParticipant(participant.identity))
            return;
        setTimeout(()=>{
            const name = service.getParticipantName(participant);
            if(sipI.isOnGoingInviteExists(name)){
                sipI.updateOngoingInvite({
                    action:"",
                    cancel:"",
                    state:"success"
                })
            }
            if(participant.audioTrackPublications?.size > 0){
                if(!userI.isSipProviderConnected()) {
                    sipI.setParticipantConnected({
                        mre_call_participant_id: participant.identity,
                        destination_number: name
                    });
                }
                sipI.stopOngoingInvite(name);
                //run check on participant transcription
                setTimeout(()=>{
                    transcriptionI.updateStateBaseOnParticipants();
                })
            }
        })
    }
    service.setConfigured = (containerOrId)=>{
        const id = containerOrId?.id || containerOrId;
        configured.set(id,true)
    }
    service.isConfigured = (containerOrId)=>{
        const id = containerOrId?.id || containerOrId;
        return configured.get(id)
    }
    service.clearConfigured = (containerOrId)=>{
        const id = containerOrId?.id || containerOrId;
        return configured.delete(id)
    }
    service.isFileSharePublication = (publication)=>{
        if(!publication) return false;
        return publication.trackName === CONSTANTS.SOURCES.FILE_SHARE
    }
    service.isHandTrackPublication = (publication)=>{
        if(!publication) return
        return publication.trackName.indexOf(handTrackerI.getSuffix()) > -1;
    }
    service.isScreenSharePublication = (publication)=>{
        if(!publication) return false;
        return publication.trackName === CONSTANTS.SOURCES.SCREEN_SHARE
    }
    service.onTrackChange = (track,publication,participant,published=false)=>{
        if(!publication || !participant) return
        let identity = participant.identity;
        service.updateAllMicStatus();
        const isScreenShare = service.isScreenSharePublication(publication);
        const isVieoShare = service.isFileSharePublication(publication)
        const isHandTrack = service.isHandTrackPublication(publication);
        if(isScreenShare){
            identity+=CONSTANTS.SCREEN_SHARE_ID_SUFFIX
            service.onParticipantsUpdate();
        }
        if(isVieoShare){
            identity+=CONSTANTS.FILE_SHARE_ID_SUFFIX
            if(!service.isOwner(participant.identity)){
                if(published){
                    videoShareI.onNewVideoFileShare(identity);
                }else{
                    videoShareI.destroy(identity);
                }
            }
            service.onParticipantsUpdate();
        }
        if(isHandTrack){
            identity+=handTrackerI.getSuffix();
        }
        const elements = streamI.getElementById(identity);
        if(!isScreenShare && !isVieoShare && !isHandTrack){
                    if (elements && publication.kind === "video" && elements.video && elements.avatar) {
                        elements.video.style.display = published ? "block" : "none";
                        elements.avatar.style.display = published ? "none" : "";
            setTimeout(() => {
                if(participant.identity !== callI.getActiveParticipantId()) return
                const cameraUI = cameraI.getUIBind();
                cameraUI.setIsWaiting(false);
            });
        }
        if(publication.kind === "audio"){
            setTimeout(()=>{
                if(identity !== callI.getActiveParticipantId()) return
                const micUI = micI.getUIBind();
                micUI.setIsWaiting(false)
            })
        }
        }
        if(track){
            const stream = service.getOrInitStream(identity);
            if(!(stream instanceof MediaStream)) return
            const mediaStreamTrack = track.mediaStreamTrack;
            const oldTrack = track.kind === "video" ? stream.getVideoTracks()[0] : stream.getAudioTracks()[0];
            if(oldTrack) stream.removeTrack(oldTrack)
            if(mediaStreamTrack.enabled){
                if(track.kind === "audio" && identity === callI.getActiveParticipantId()) return
                stream.addTrack(mediaStreamTrack)
                if(published && track.kind === "audio" && (identity.endsWith(CONSTANTS.FILE_SHARE_ID_SUFFIX) || identity.endsWith(CONSTANTS.SCREEN_SHARE_ID_SUFFIX)) && !_inner.speechEvents[identity]){
                    const speechEvents = hark(stream,{
                        // interval:450,
                        // threshold:-100,
                        play:false
                    })
                    speechEvents.on('speaking',()=>{
                        service.handleSpeakChange(identity,true)
                    })
                    speechEvents.on('stopped_speaking',()=>{
                        service.handleSpeakChange(identity,false)
                    })
                    _inner.speechEvents[identity] = speechEvents;
                }
            }
        }
        setTimeout(()=>{
            service.checkSIPParticipant(participant)
            service.onSpeakerStateChange()
            if(isHandTrack){
                const update = ()=>{
                    const container = document.getElementById(participant.identity);
                    if(!container) return
                    container.classList[published ? "add" : "remove"]?.("has-swistwit")
                }
                if(service.isOwner(participant.identity)) return update();
                const mainVideo = service.getVideoElement(participant.identity)
                const handTrackerVideo = service.getHandTrackerVideoElement(participant.identity)
                if(!mainVideo || !handTrackerVideo) return
                if(published){
                    toastI.info(langI.get('SWISTWIT_STARTED',[participant.name]))
                    handTrackerVideo.srcObject = service.getOrInitStream(identity)
                    handTrackerVideo.style.display = 'block'
                    mainVideo.style.display = 'none'
                    update();
                }else{
                    toastI.info(langI.get('SWISTWIT_STOPPED',[participant.name]))
                    handTrackerVideo.style.display = 'none'
                    handTrackerVideo.srcObject = null;
                    mainVideo.style.display = service.isCameraEnabled(participant.identity)? 'block' : 'none'
                    update();
                }
            }
        })
    }
    service.onConnectRoom = async (options={retries:4})=>{
        if(_inner.room && _inner.room.state === ConnectionState.Connected) return
        let retryCount = 0;
        let connected = false;
        let error;
        while(!connected && retryCount < options.retries){
            service.removeContainers();
            try{
                const result = await _inner.api.post.token();
                if(result && result.token){
                    try{
                        const room = service.getOrInitRoom(true,{});
                        service.initRoomEvents();
                        await room.connect(userI.getLivekitSocketServer(),result.token,{autoSubscribe:true})
                        connected = true;
                    }catch(err){
                        error = err;
                    }
                }
            }catch(err){
                error = err;
            }
            retryCount++;
            await service.sleep(4*1000)
        }
        if(error) throw error
        if(!connected) throw ''
        return true;
    }
    service.sleep = (ms)=>{
        return new Promise((resolve)=>{
            setTimeout(()=>resolve(),ms)
        })
    }
    service.isFileShareParticipant = (identity)=>{
        if(typeof identity !== "string") return identity
        return identity.endsWith(CONSTANTS.FILE_SHARE_ID_SUFFIX)
    }
    service.isAudioShareParticipant = (participant)=>{
        if(!participant) return false
        if(service.isFileShareParticipant(participant.identity)){
            const ownerIdentity = service.getOwnerIdentity(participant.identity);
            const owner = service.getParticipant(ownerIdentity);
            if(owner instanceof Participant){
                const videoTracks = Array.from(owner.videoTrackPublications).map(([key,value])=>value);
                const isAudio = videoTracks.filter(track=>track.trackName === CONSTANTS.SOURCES.FILE_SHARE).length === 0;
                return isAudio;
            }
        }
        return false
    }
    service.isScreenShareParticipant = (identity)=>{
        if(typeof identity !== "string") return identity
        return identity.endsWith(CONSTANTS.SCREEN_SHARE_ID_SUFFIX)
    }
    service.getView = (participant)=>{
        if(!participant) return
        if(service.isInternalParticipant(participant)) return
        const container = document.createElement('div');
        container.style.position = 'relative';
        container.style.overflow = 'hidden';
        container.id = participant.identity;
        container.classList.add('eco-video',container.id === callI.getActiveParticipantId() ? "eco-video-local" : "eco-video-remote");
        if(participant.isAudioShare){
            const icon = videoShareI.getAudioIcon(true)
            container.appendChild(icon)
        }
        const handTrackerVideo = document.createElement('video');
        handTrackerVideo.id = service.getHandTrackerVideoId(participant.identity);
        handTrackerVideo.style.width = handTrackerVideo.style.height = "100%";
        handTrackerVideo.style.position = 'absolute'
        handTrackerVideo.style.objectFit = 'object-fit'
        handTrackerVideo.style.zIndex = 2;
        handTrackerVideo.style.display = 'none'
        handTrackerVideo.autoplay = true;
        container.appendChild(handTrackerVideo)
        return container;
    }
    service.getHandTrackerVideoId = (identity)=> "hand-tracker-video-"+identity
    service.getHandTrackerVideoElement = (identity)=> document.getElementById(service.getHandTrackerVideoId(identity))
    service.isTrackEnabled = (identity,type)=>{
        const participant = service.getParticipants(true).find(p=>p.identity === identity);
        if(!participant) return false;
        if(participant.isFileShare || participant.isScreenShare){
            return true;
        }
        const publications = participant.getTrackPublications();
        if(publications.length === 0 ) return false;
        if(type === "audio"){
            return publications.some(publication=>publication.kind === "audio")
        }
        if(type === "video"){
            return publications.some(publication=>publication.kind === "video")
        }
        return false
    }
    service.isMicEnabled = (identity)=> {
        if(!identity) return false
        if(service.isScreenShareParticipant(identity)) return service.isScreenShareWithAudio(identity)
        return service.isTrackEnabled(identity,'audio')
    }
    service.isInaudible = (identity)=> !!_inner.inaudible[identity]
    service.isCameraEnabled = (identity)=> service.isTrackEnabled(identity,'video')
    service.updateMicStatus = (identity)=>{
        if(!identity) return
        const mic_status = streamI.getElementById(identity)?.mic_status
        if(!mic_status) return
        if(service.isMicEnabled(identity)){
            mic_status?.reset?.();
        }else{
            mic_status?.disable?.();
        }
    }
    service.removeContainers = ()=>{
        document.querySelectorAll(".eco-video")
        .forEach(container=>{
            container.parentNode.removeChild(container)
        })
    }
    service.stopSpeechEvents = (identity)=>{
        if(identity){
            const speechEvents = _inner.speechEvents[identity];
            speechEvents?.stop?.();
            delete _inner.speechEvents[identity];
        }else{
            for(let key in _inner.speechEvents){
                const speechEvents = _inner.speechEvents[key];
                speechEvents?.stop?.();
            }
            _inner.speechEvents = {};
        }
    }
    service.onLeaveRoom = async ()=>{
        const room = service.getOrInitRoom();
        if(!room) return
        service.stopSpeechEvents();
        service.clearRoomEvents();
        room.localParticipant.trackPublications.forEach(publication=>{
            publication.track?.stop?.();
        })
        try{
            await cameraI.onStopDeviceStream()
        }catch(err){}
        try{
            await micI.onStopDeviceStream()
        }catch(err){}
        try{
            await service.onDisableScreenShare();
        }catch(err){}
        service.removeContainers();
        _inner.room = null
        service.clear();
        room.disconnect(true).then(()=>{},()=>{})
        try{
            await new Promise((resolve,reject)=>{
                let timeout = setTimeout(reject,10*1000)
                room.on(RoomEvent.Disconnected,()=>{
                    clearTimeout(timeout)
                    resolve();
                })
            })
        }catch(err){}
    }
    _inner.screenShareStream = null;
    service.onToggleScreenShare = async (enable,options={})=>{
        if(!screenI.isDesktop()) {
            langI.get('SCREENSHARE_PCONLY')
            throw ""
        }
        const room = service.getOrInitRoom();
        options = typeof options === "object"? options : {}
        if(!room) throw ""
        try{
            if(!!enable){
                const stream = await navigator.mediaDevices.getDisplayMedia({
                    video:{
                        displaySurface: "browser",
                    },
                    audio:{
                        // autoGainControl:false,
                        // latency:0,
                        // noiseSuppression:false,
                        // sampleRate:0,
                        // sampleSize:false,
                        echoCancellation: true,
                        channelCount: 2
                    }
                })
                try{
                    await streamI.onStartScreenShareStream();
                }catch(err){}
                await service.onTogglePublishScreenShareStream(true,stream)
                stream.getVideoTracks()[0].addEventListener('ended',()=>{
                    service.onToggleScreenShare(false)
                })
                _inner.screenShareStream = stream;
                service.onParticipantsUpdate();
            }else{
                if(_inner.screenShareStream){
                    const screenShareUI = screenshareI.getUIBind();
                    screenShareUI.stop();
                    try{
                        await service.onTogglePublishScreenShareStream(false,_inner.screenShareStream)
                    }catch(err){}
                    _inner.screenShareStream.getTracks().forEach(track=>track.stop())
                }
                try{
                    await streamI.onStopScreenShareStream();
                }catch(err){}
            }
        }catch(err){
            throw err
        }
        return
    }
    service.onEnableScreenShare = ()=> service.onToggleScreenShare(true)
    service.onDisableScreenShare = ()=> service.onToggleScreenShare(false)
    service.getConstants = ()=> CONSTANTS;
    // service.getResolutions = ()=> service.getConstants().RESOLUTIONS;
    service.isRoomConnected = ()=> service.getOrInitRoom()?.state === ConnectionState.Connected
    service.isOncall = ()=> navbarI.getActiveRoute() === "app/call" || navbarI.getActiveRoute() === "call" ? true : false;
    // service.getCameraResolution = ()=> service.getResolutions().h720
    service.getSources = ()=> CONSTANTS.SOURCES;
    _inner.volumes = {};
    _inner.muted = {}
    _inner.tsEnabled = {};
    _inner.stsEnabled = {};
    _inner.isLoadingTranscription = {};
    _inner.isLoadingSTS = {};
    _inner.paused = {};
    _inner.isMuting = {}
    _inner.isPausing = {};
    _inner.languages = {};
    _inner.inaudible = {};
    service.toggleInaudible = (identity)=>{
        if(!identity) return
        const inaudible = !(_inner.inaudible[identity]);
        service.getOrSetParticipantInaudible(identity,inaudible);
        _inner.notifyUI();
    }
    service.getOrSetParticipantInaudible = (identity,inaudible)=>{
        if(!identity) return false;
        if(typeof inaudible === "boolean"){
            _inner.inaudible[identity] = inaudible;
            const video = service.getVideoElement(identity)
            if(video instanceof HTMLVideoElement){
                const isMuted = _inner.muted[identity];
                if(inaudible || isMuted || !speakerI.isEnabled()){
                    video.muted = true
                }else{
                    video.muted = false;
                }
            }
            service.updateDropdownItems();
        }
        return _inner.inaudible[identity];
    }
    service.getOrSetParticipantVolume = (identity,volume,update=true)=>{
        if(!identity) return -1;
        const video = streamI.getElementById(identity)?.video;
        if(!(video instanceof HTMLVideoElement)) return -1
        if(volume){
            volume = parseFloat(volume);
            if(isNaN(volume) || volume < 0) return -1;
            video.volume = volume;
            if(update) _inner.volumes[identity] = video.volume;
            service.updateDropdownItems();
        }
        return video.volume;
    }
    service.getOrSetParticipantMuted = (identity,muted,update=true)=>{
        if(!identity) return false;
        const video = service.getVideoElement(identity);
        if(!(video instanceof HTMLVideoElement)) return false
        if(typeof muted === "boolean"){
            if(!service.isOwner(identity)){
                if(!callI.isActiveCallByHost()) return toastI.warning(langI.get('ACTION_UNAUTHORISED'));
                const participant = service.getParticipant(identity)
                if(!muted) toastI.info(langI.get('PARTICIPANT_ASK_OPEN_MIC_NOTIFY_SENDER', [participant.name]));
                _inner.isMuting[identity] = true
                _inner.notifyUI();
                if(muted){
                    streamI.onMuteParticipantMic(identity).then(()=>{},()=>{})
                    .finally(()=>{
                        // handlers.onParticipantsUpdate();
                    })
                }else{
                    streamI.onUnMuteParticipantMic(identity).then(()=>{},()=>{})
                    .finally(()=>{
                        // handlers.onParticipantsUpdate();
                    })
                }
                service.sendData(identity, {event: streamI.EVENTS.MIC_CHANGE});
                setTimeout(()=>{
                    service.handleParticipantMetadata(participant,false);
                    delete _inner.isMuting[identity]
                    _inner.notifyUI();
                },4*1000)
                return
            }else{
                const micUI = micI.getUIBind();
                if(micUI.isWaiting) return
                _inner.isMuting[identity] = true;
                const done = ()=>{
                    delete _inner.isMuting[identity];
                    _inner.notifyUI();
                }
                if(muted){
                    micUI.onStop?.().then(()=>{},()=>{})
                    .finally(()=>done())
                }else{
                    micUI.onOpen?.().then(()=>{},()=>{})
                    .finally(()=>done())
                }
            }
            _inner.muted[identity] = muted;
            const inaudible = _inner.inaudible[identity];
            if(inaudible || muted || !speakerI.isEnabled()){
                video.muted = true
            }else{
                video.muted = false;
            }
        }
        return video.muted;
    }
    service.getOrSetParticipantPaused = (identity,paused)=>{
        if(!identity) return false;
        if(typeof paused === "boolean"){
            if(!service.isOwner(identity)){
                if(!callI.isActiveCallByHost()) return toastI.warning(langI.get('ACTION_UNAUTHORISED'));
                const participant = service.getParticipant(identity)
                if(!paused) toastI.info(langI.get('PARTICIPANT_ASK_OPEN_CAMERA_NOTIFY_SENDER', [participant.name]));
                _inner.isPausing[identity] = true
                _inner.notifyUI();
                if(paused){
                    streamI.onPauseParticipantCamera(identity).then(()=>{}, ()=>{})
                    .finally(()=>{
                        // handlers.onParticipantsUpdate();
                    })
                }
                service.sendData(identity, {event: streamI.EVENTS.CAMERA_CHANGE});
                setTimeout(()=>{
                    service.handleParticipantMetadata(participant,false);
                    delete _inner.isPausing[identity]
                    _inner.notifyUI();
                },4*1000)
                return
            }else{
                const cameraUI = cameraI.getUIBind();
                if(cameraUI.isWaiting) return
                _inner.isPausing[identity] = true;
                const done = ()=>{
                    delete _inner.isPausing[identity];
                    _inner.notifyUI();
                }
                if(paused){
                    cameraUI.onStop?.().then(()=>{},()=>{})
                    .finally(()=>done())
                }else{
                    cameraUI.onOpen?.().then(()=>{},()=>{})
                    .finally(()=>done())
                }
            }
            _inner.paused[identity] = paused;
        }
        return !!_inner.paused[identity]
    }
    service.getOrSetParticipantTranscription = (identity,enable)=>{
        if(!identity) return false;
        if(typeof enable === "boolean"){
            if(!service.isOwner(identity)){
                if(!callI.isActiveCallByHost()) return toastI.warning(langI.get('ACTION_UNAUTHORISED'));
                const participant = service.getParticipant(identity)
                // todo handle sip
                if(participant.is_sip) {
                    //no need to send event if participant on sip
                //    participantI.onUpdateTranscription({callId: callI.getActiveCallId(), participantId: mre_call_participant_id, isActive: !!!participant.has_stt_active}).then(()=>{}, ()=>{})
                //     .finally(()=>{
                //         transcriptionI.updateStateBaseOnParticipants();
                //     });
                    return;
                }
                _inner.isLoadingTranscription[identity] = true
                _inner.notifyUI();
                service.sendData(identity, {event: streamI.EVENTS.TOGGLE_TRANSCRIPTION});
                setTimeout(()=>{
                    service.handleParticipantMetadata(participant,false);
                    delete _inner.isLoadingTranscription[identity]
                    _inner.notifyUI();
                },4*1000)
                return
            }else{
                transcriptionI.getUIBind()?.toggle?.();
            }
            _inner.tsEnabled[identity] = enable;
        }
        return !!_inner.tsEnabled[identity]
    }
    service.getOrSetParticipantSpeechToSpeech = (identity,enable)=>{
        if(!identity) return false;
        if(typeof enable === "boolean"){
            if(!service.isOwner(identity)){
                if(!callI.isActiveCallByHost()) return toastI.warning(langI.get('ACTION_UNAUTHORISED'));
                const participant = service.getParticipant(identity)
                _inner.isLoadingSTS[identity] = true
                _inner.notifyUI();
                service.sendData(identity, {event: streamI.EVENTS.TOGGLE_SPEECH_TO_SPEECH});
                setTimeout(()=>{
                    service.handleParticipantMetadata(participant,false);
                    delete _inner.isLoadingSTS[identity]
                    _inner.notifyUI();
                },4*1000)
                return
            }else{
                callI.toggleSTS(!callI.isSpeechToSpeechEnabled());
            }
            _inner.stsEnabled[identity] = enable;
        }
        return !!_inner.stsEnabled[identity]
    }
    service.toggleMute = (identity)=>{
        if(!identity) return
        const muted = !(!!_inner.muted[identity]);
        service.getOrSetParticipantMuted(identity,muted)
    }
    service.toggleTranscription = (identity)=>{
        if(!identity) return
        const enabled = !(!!_inner.tsEnabled[identity]);
        service.getOrSetParticipantTranscription(identity,enabled)
    }
    service.toggleSpeechToSpeech = (identity)=>{
        if(!identity) return
        const enabled = !(!!_inner.stsEnabled[identity]);
        service.getOrSetParticipantSpeechToSpeech(identity,enabled)
    }
    service.togglePause = (identity)=>{
        if(!identity) return
        const paused = !(!!_inner.paused[identity])
        service.getOrSetParticipantPaused(identity,paused)
    }
    service.getMetatada = (stringify)=>{
        const participant = callI.getActiveParticipant();
        if(!participant) return
        const metadata = {
            ...participant,
            isMuted:!micI.isEnabled(),
            isPaused:!cameraI.isEnabled(),
            isTSEnabled:transcriptionI.isOpen(),
            isSTSEnabled:callI.isSpeechToSpeechEnabled(),
            language:langI.getUserLanguageById(participant.language_id),
            canShowLanguageCode: true
        }
        return +stringify === 1 ? JSON.stringify(metadata) : metadata;
    }
    service.updateMetadata = ()=>{
        const room = service.getOrInitRoom(false);
        if(!room) return
        room.localParticipant.setMetadata(service.getMetatada(true))
    }
    _inner.selectParticipantLanguageModalOpen = false;
    service.openSelectLanguageParticipant = (participant) => {
        if(!participant)
          return;
        let participantId = participant.mre_call_participant_id;
        if(!participantId)
          return;
        modalI.registerData({
          id: langI.MODALS.SELECT_LANGUAGE_MODAL_ID,
          data: {
            languages: langI.getActiveUserLanguages()
          }
        });
        _inner.selectParticipantLanguageModalOpen = true;
        _inner.notifyUI();
        setTimeout(()=>{
          modalI.init();
          modalI.openModal({
            id: langI.MODALS.SELECT_LANGUAGE_MODAL_ID,
            onClose: (data) => {
                setTimeout(()=>{
                    _inner.selectParticipantLanguageModalOpen = false;
                    _inner.notifyUI();
                }, 250);
                if(data && data.selectedLang && data.selectedLang.code) {
                  if(participant.is_sip || participantId === callI.getActiveParticipantId()) {
                    let obj = {language_id: data.selectedLang.language_id};
                    //in case of sip participant and we change his language, and transcription is already enabled, we need to enable for him transcription
                    if(participant.is_sip && transcriptionI.hasAnyActiveParticipantEnabled())
                      obj.has_stt_active = true;
                    participantI.onUpdateParticipant({callId: callI.getActiveCallId(), participantId: participantId, data: obj})
                    .then((res)=>{
                        if(Array.isArray(res.errors)) {
                            reject(networkI.getErrorMessageFromArr(res.errors));
                            return;
                        }
                        if(participantId === callI.getActiveParticipantId())
                          busI.notifyEvent(busI.EVENTS.CHANGE_LANGUAGE, {isChanged: true});
                    }, (error)=>{});
                  } else {
                    service.sendData(participantId, {language_id: data.selectedLang.language_id, event: streamI.EVENTS.CHANGE_PARTICIPANT_LANGUAGE});
                  }
                  if(participantId === callI.getActiveParticipantId()) return
                  toastI.info(langI.get('PARTICIPANT_CHANGING_LANGUAGE_NOTIFY_SENDER', [participant.name]));
                }
            }
          })
        });
    }
    service.moveToWaitingRoom = (id)=>{
        if(!id || !callI.isActiveCallByHost()) return
        let participant = participantI.getParticipantById(id);
        if(!participant || participant.isLoadingChangeWaitingRoom)
          return;
        participant.isLoadingChangeWaitingRoom = true;
        participant.sent_to_waiting_room = true;
        service.sendData(null,{event:streamI.EVENTS.MOVE_TO_WAITING_ROOM, id:id});
        toastI.info(langI.get('PARTICIPANT_MOVED_INTO_WAITING_ROOM'));
    }
    service.getVideoElementId = (identity)=>{
        if(typeof identity !== "string") return ""
        return "video_element_"+identity;
    }
    service.getVideoElement = (identity)=>{
        return document.getElementById(service.getVideoElementId(identity))
    }
    service.extractId = (value)=>{
        if(typeof value !== "string") return
        const regex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
        const match = value.match(regex);
        return Array.isArray(match) && match[0] ? match[0] : ""
    }
    service.onSpeakerStateChange = ()=>{
        clearTimeout(_inner["onSpeakerStateChangeTimeout"])
        _inner["onSpeakerStateChangeTimeout"] = setTimeout(()=>{
            const isSpeakerEnabled = speakerI.isEnabled();
            const participants = service.getParticipants();
            for(let i = 0 ; i < participants.length; i++){
                const participant = participants[i];
                let shouldMute = false;
                if(isSpeakerEnabled){
                    shouldMute = _inner.muted[participant.identity]
                }else{
                    shouldMute = true
                }
                const video = service.getVideoElement(participant.identity);
                if(video){
                    video.muted = shouldMute
                }
            }
            const players = videoShareI.getPlayers();
            for(let id in players){
                const player = videoShareI.getPlayerById(id);
                if(!player) continue;
                player.setMuted(isSpeakerEnabled?false:true)
            }
        },250)
    }
    service.getParticipantById = (id)=>{
        if(!id) return
        return service.getParticipants().find(p=>p.identity === id)
    }
    service.isSipParticipant = (identity)=>{
        if(!identity) return
        // TODO add check on metadata as well
        // let participant = service.getParticipantById(identity);
        let participant = participantI.getParticipantById(identity)
        return participant && participant.name && participant.is_sip? true: false;
    }
    service.getParticipantName = (p)=>{
        if(!p)
            return p;
        if(!(p instanceof Participant)) {
            return p.name? p.name: '';
        }
        let name = p.name;
        if(!name) {
            name = participantI.getParticipantNameById(p.identity);
        }
        return name || p.identity;
    }
    service.getParticipantNameById = (id)=>{
        return service.getParticipantName(service.getParticipant(id));
    }
    service.onStartSipCall = (obj)=>{
        return new Promise((resolve,reject)=>{
            if(!obj || !obj.number || typeof obj.number !== "string") return reject();
            _inner.api.post.startSipCall(obj)
            .then(res=>{
                if(res && res.success){
                    resolve();
                }else{
                    reject(res)
                }
            },err=>{
                reject(err)
            })
        })
    }
    service.onStopSipCall = (number, pId)=>{
        return new Promise((resolve,reject)=>{
            if(typeof number !== "string") return reject();
            _inner.api.delete.stopSipCall(number, pId)
            .then(res=>{
                if(res && res.success){
                    resolve();
                }else{
                    reject(res)
                }
            },err=>{
                reject(err)
            })
        })
    }
    service.hasVideoById = (id)=>{
        if(typeof id !== "string") return
        if(id.endsWith(CONSTANTS.SCREEN_SHARE_ID_SUFFIX)) return true
        if(id.endsWith(CONSTANTS.FILE_SHARE_ID_SUFFIX)) return service.getParticipantById(id)?.videoTrackPublications.size > 0
        return service.hasVideo(service.getParticipantById(id))
    }
    service.isScreenShareWithAudio = (id)=>{
        if(typeof id !== "string") return false
        const owner = service.getParticipantById(id.replace(CONSTANTS.SCREEN_SHARE_ID_SUFFIX,''));
        if(!owner) return false;
        return !!owner.getTrackPublications().find(p=>p.kind === "audio" && p.trackName === CONSTANTS.SOURCES.SCREEN_SHARE)
    }
    service.isInternalParticipant = (participant)=>{
        if(typeof participant !== "object") return false
        return participantI.isInternalParticipant(participantI.getParticipantById(participant.identity))
    }
    service.playStreams = ()=>{
        setTimeout(()=>{
            service.getParticipants()
            .forEach(participant=>{
                const videoELement = service.getVideoElement(participant.identity)
                if(videoELement && videoELement.play) videoELement.play().then(()=>{},()=>{})
            })
            streamI.onResize()
        })
    }
    service.getLocalParticipant = ()=>{
        return service.getOrInitRoom(false)?.localParticipant
    }
    service.replaceFileShareTracks = (stream)=>{
        if(!(stream instanceof MediaStream)) return
        const {audioTrack,videoTrack} = service.getPublishedFileShareTracks()
        service.getLocalParticipant().unpublishTracks([audioTrack,videoTrack])
        .then(()=>{},()=>{})
        .finally(()=>{
            service.onTogglePublishFileShareStream(true,stream).then(()=>{},()=>{})
        })
    }
    service.getTrackSource = (track)=>{
        if(!track) return
        if(cameraI.getTrack()?.id === track.id) return service.getSources().CAMERA
        if(micI.getTrack()?.id === track.id) return service.getSources().AUDIO
        if(_inner.screenShareStream?.getTracks().find(t=>t.id === track.id)) return service.getSources().SCREEN_SHARE
        return
    }
    service.getScreenShareOwner = (id)=>{
        if(typeof id !== "string" || !id.length) return
        return service.getParticipantById(service.getOwnerIdentity(id))
    }
    service.handleScreenShareMessage = (id,enabled)=>{
        if(typeof id !== "string" || !id.length || !service.isScreenShareParticipant(id) || typeof enabled !== "boolean") return
        const owner = service.getScreenShareOwner(id);
        if(typeof owner !== "object" || !owner.name?.length) return
        toastI.info(langI.get(enabled ? 'SCREENSHARE_STARTED' : 'SCREENSHARE_STOPPED',[owner.name]))
    }
    service.onParticipantJoin = (id)=>{
        if(!id) return
        _inner.joinedAt[id] = new Date().getTime()
        service.handlePinParticipant(id)
        service.handleScreenShareMessage(id,true)
        streamerI.handleStreamShareMessage(id,true)
    }
    service.onParticipantLeft = (id)=>{
        if(!id) return
        delete _inner.joinedAt[id]
        service.handlePinParticipant(id)
        service.handleScreenShareMessage(id,false)
        streamerI.handleStreamShareMessage(id,false)
    }
    service.getJoinedAt = (id)=>{
        return _inner.joinedAt[id] || 0
    }
    service.getScreenShares = ()=>{
        return  service.getParticipants(false).filter(p=>service.isScreenShareParticipant(p.identity))
    }
    service.getFileShares = ()=>{
        return service.getParticipants(false).filter(p=>service.isFileShareParticipant(p.identity))
    }
    service.getStreamShares = ()=>{
        return service.getParticipants(false).filter(p=>streamerI.isStreamParticipant(p.identity))
    }
    service.getNewestScreenShare = ()=>{
        const screenShares = service.getScreenShares().sort((a,b)=>service.getJoinedAt(b.identity)-service.getJoinedAt(a.identity))
        return screenShares[0]
    }
    service.getNewestFileShare = ()=>{
        const fileShares = service.getFileShares().sort((a,b)=>service.getJoinedAt(b.identity)-service.getJoinedAt(a.identity))
        return fileShares[0]
    }
    service.getNewestStreamShare = ()=>{
        const streamShares = service.getStreamShares().sort((a,b)=>service.getJoinedAt(b.identity)-service.getJoinedAt(a.identity))
        return streamShares[0]
    }
    service.handlePinParticipant =(id)=>{
        if(typeof id !== "string" || !id.length || !service.isScreenShareParticipant(id) && !service.isFileShareParticipant(id) && !streamerI.isStreamParticipant(id)) return
        const newestScreenShare = service.getNewestScreenShare()
        const newestFileShare = service.getNewestFileShare()
        const newestStreamShare = service.getNewestStreamShare()
        if(!newestScreenShare && !newestFileShare && !newestStreamShare){
            streamI.fullMode()
            return
        }
        let arr = []
        if(IGNORE_ORNDER_FOR_NEW_PINS){
            arr = [newestScreenShare,newestFileShare,newestStreamShare].filter(obj=>obj && typeof obj === "object").sort((a,b)=>service.getJoinedAt(b.identity)-service.getJoinedAt(a.identity))
        }else{
            if(newestScreenShare){
                arr[PIN_ORDER.findIndex(p=>p === PINS.SCREEN_SHARE)] = newestScreenShare
            }
            if(newestFileShare){
                arr[PIN_ORDER.findIndex(p=>p === PINS.FILE_SHARE)] = newestFileShare
            }
            if(newestStreamShare){
                arr[PIN_ORDER.findIndex(p=>p === PINS.STREAM_SHARE)] = newestStreamShare
            }
        }
        const participantToPin = arr.find(obj=>obj && typeof obj === "object")
        if(!participantToPin) return
        streamI.unpinAllViews();
        clearTimeout(_inner.pinTimeout)
        _inner.pinTimeout = setTimeout(()=>{
            streamI.pinView(document.getElementById(participantToPin.identity))
        },50)
    }
    service.getParticipantAudioMediaStream = (identity)=>{
        const participant = service.getParticipant(identity)
        if(!participant || !participant.audioTrackPublications) return
        return Array.from(participant.audioTrackPublications).map(([id,publication])=>publication).find((publication)=>publication.source === Track.Source.Microphone)?.track?.mediaStream
    }
    return service;
}
export const livekitI = LiveKitI();