<div class="main-app-container">
    <div class="app-router-container">
        <router-outlet></router-outlet>
    </div>
    <div class="modals-container"></div>
    <div class="toasts-container"></div>
    <div class="loading-wrapper blur" id="tmu-spinner" [ngClass]="loadingUi.isVisible ? 'enabled': ''">
        <img [src]="spUI.logo" alt="" class="loading_logo">
        <div class="loading-icon" *ngIf="!loadingUi.hasMessage">
            <i class='bx bx-loader bx-spin'></i>
        </div>
        <div class="loading-msg" *ngIf="loadingUi.hasMessage">
            <i class='bx bx-loader bx-spin'></i>
            <span>{{loadingUi.message ? loadingUi.message : 'Loading'}}</span>
        </div>
    </div>
    <app-install-prompt *ngIf="pwaUI.showPrompt"></app-install-prompt>
    <app-existing-call-modal *ngIf="modalUI.isExistingCallModalOpen"></app-existing-call-modal>
    <app-rating-modal *ngIf="modalUI.isRatingModalOpen"></app-rating-modal>
    <app-exit-call-page *ngIf="modalUI.isExitCallModalOpen"></app-exit-call-page>
    <app-email-verification-modal *ngIf="modalUI.isEmailVerificationModalOpen"></app-email-verification-modal>
    <app-recording-confirmation-modal *ngIf="modalUI.isConsentRecordingModalOpen"></app-recording-confirmation-modal>
</div>
