let WidgetI = function () {
    let service = {};
    let _inner =  {}
    _inner.parent = window.parent;
    _inner.callbacks = {};
    _inner.isInitialized = false;
    _inner.isTMUNoticesRemoved = false;
    _inner.messageHandler = (event) => {
        if (!event || !event.data || event.data.requester !== 'tmu.widget.support.mresence') { return; }
        if (event.data.action === 'joinCall') {
            if(typeof _inner.callbacks['onReady'] === "function"){
                _inner.callbacks['onReady']();
                delete _inner.callbacks['onReady'];
            }
        }
        if (event.data.action === 'videoGranted') {
            if(typeof _inner.callbacks['grantCam'] === "function"){
                _inner.callbacks['grantCam'](event.data.status === 'success');
                delete _inner.callbacks['grantCam'];
            }
        }
        if (event.data.action === 'audioGranted') {
            if(typeof _inner.callbacks['grantMic'] === "function"){
                _inner.callbacks['grantMic'](event.data.status === 'success');
                delete _inner.callbacks['grantMic'];
            }
        }
        if (event.data.action === 'videoRevoked') {
            if(typeof _inner.callbacks['revokeCam'] === "function"){
                _inner.callbacks['revokeCam']();
                delete _inner.callbacks['revokeCam'];
            }
        }
        if (event.data.action === 'audioRevoked') {
            if(typeof _inner.callbacks['revokeMic'] === "function"){
                _inner.callbacks['revokeMic']();
                delete _inner.callbacks['revokeMic'];
            }
        }
    }
    _inner.removeTMUNotices = () => {
        if (document.body.contains(document.querySelector('.cc_css_reboot.cc_dialog'))) {
            document.querySelector('.cc_css_reboot.cc_dialog').parentElement.removeChild(document.querySelector('.cc_css_reboot.cc_dialog'));
            _inner.isTMUNoticesRemoved = true;
        }
    }
    service.isWidget = false;
    service.init = () => {
        if(_inner.isInitialized) return;
        service.isWidget = true;
        window.removeEventListener('message', _inner.messageHandler);
        window.addEventListener('message', _inner.messageHandler);
        _inner.isInitialized = true;

        const inv = setInterval(() => {
            if(!_inner.isTMUNoticesRemoved) _inner.removeTMUNotices();
            if(_inner.isTMUNoticesRemoved) clearInterval(inv);
        }, 200);
    }
    service.getMicPermission = (cb) => {
        _inner.parent.postMessage({requester: 'tmu.widget.support.mresence', eventName: 'startAudioPermission' }, '*');
        _inner.callbacks['grantMic'] = cb;
    }
    service.stopMicPermission = (cb) => {
        _inner.parent.postMessage({requester: 'tmu.widget.support.mresence', eventName: 'stopAudioPermission' }, '*');
        _inner.callbacks['revokeMic'] = cb;
    }
    service.getCamPermission = (cb) => {
        _inner.parent.postMessage({requester: 'tmu.widget.support.mresence', eventName: 'startVideoPermission' }, '*');
        _inner.callbacks['grantCam'] = cb;
    }
    service.stopCamPermission = (cb) => {
        _inner.parent.postMessage({requester: 'tmu.widget.support.mresence', eventName: 'stopVideoPermission' }, '*');
        _inner.callbacks['revokeCam'] = cb;
    }
    service.onReady = (cb) => {
        _inner.parent.postMessage({requester: 'tmu.widget.support.mresence', eventName: 'onReady' }, '*');
        _inner.callbacks['onReady'] = cb;
    }

    service.onFinish = () => {
        _inner.parent.postMessage({requester: 'tmu.widget.support.mresence', eventName: 'onFinish' }, '*');
    }

    return service;
}

export const widgetI = WidgetI();
