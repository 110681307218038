import { callI } from "./callI";
import { langI } from "./langI";
import { networkI } from "./networkI";
import { notificationI } from "./notificationI";
import { participantI } from "./participantI";
import { toastI } from "./toastI";

let PollI = function () {

    let service = {};

    let _inner = {};

    _inner.polls = {};

    _inner.notifyUI = () => {
        if(!_inner.ui)
          service.getUIBind();
    }

    service.getPollById = function(id) {
        return _inner.polls[id]? _inner.polls[id]: null;
    }

    service.updatePoll = function(poll) {
        if(!poll || !poll.id)
            return;
        let index = poll.parent_id? poll.parent_id: poll.id;
        let old = _inner.polls[index]? _inner.polls[index]: null;
        let obj = null;
        let participant = participantI.getParticipantByAccountProfileAndCallId(poll.created_by, poll.mre_call_id);
        if(!participant)
            return;
        try {
            obj = JSON.parse(poll.survey);
            obj.userName = participant.name || '-';
            obj.id = poll.id;
            obj.parent_id = poll.parent_id;
            obj.canVote = old && old.canVote? true: false;
            obj.is_anonymous = poll.is_anonymous? true: false;
            obj.showDetails = old && old.showDetails && !obj.is_anonymous? true: false;
            obj.votes = [];
            obj.allowMultipleAnswers = poll.allow_multiple_answers ? true : false;
            _inner.ui.list.push(obj);
        } catch (error) {
            obj = null;
        }
        try {
            let answers = JSON.parse(poll.answers);
            obj.answers = answers;
            if(Array.isArray(obj.options))
                for(let i=0; i<obj.options.length; i++) {
                    let option = obj.options[i];
                    option.totalVotes = 0;
                    option.voters = [];
                    for(let j=0; j<answers.length; j++) {
                        let answer = answers[j];
                        if(answer.key === option.key) {
                            let participants = answer.participant_id;
                            option.totalVotes = participants.length>0? ((participants.length / answers.length) * 100): 0;
                            for(let j=0; j<participants.length; j++) {
                                let participant = participantI.getParticipantById(participants[j]);
                                if(!participant)
                                    continue;
                                if(participants[j]===callI.getActiveParticipantId()) {
                                    option.selected = true;
                                }
                                option.voters.push({name: participant.name, img: participant.avatar_url||''});
                            }
                        }
                    }
                }
        } catch (error) {
            obj = null;
        }
        if(obj) {
            _inner.polls[index] = obj;
            let arr = [];
            for(let k in _inner.polls) {
                arr.push(_inner.polls[k]);
            }
            _inner.ui.list = arr;
        }
    }

    service.onLoadPollById = function(id) {
        return new Promise((resolve, reject) => {
            if(!callI.getActiveCallId()) {
                reject();
                return;
            }
            networkI.get({url: '/api/call/' + callI.getActiveCallId()+ '/poll/'+id})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                if(res && res.Poll && res.Poll.id) {
                    service.updatePoll(res.Poll);
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }

    service.onLoadPolls = function() {
        return new Promise((resolve, reject)=>{
            if(!callI.getActiveCallId()) {
                reject();
                return;
            }
            networkI.get({url: '/api/call/' + callI.getActiveCallId()+ '/poll'})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                _inner.ui.list = [];
                if(res && res.Polls) {
                    let langId = participantI.getCurrentUserLanguageId();
                    for(let i=0; i<res.Polls.length; i++) {
                        if(res.Polls[i].language_id !== langId)
                            continue;
                        service.updatePoll(res.Polls[i]);
                    }
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        })
    }

    service.loadPolls = function() {
        if(_inner.isLoading)
            return;
        _inner.isLoading = true;
        service.onLoadPolls().then(()=>{}, (error)=>{
            toastI.error(error && typeof error==='string'? error: langI.get('ERROR_UNK'));
        }).finally(()=>{
            _inner.isLoading = false;
        });
    }

    service.handlePollReceived = function(response) {
        if(!response || !response.Poll || !response.Poll.survey)
            return;
        if(response.Poll.language_id !== participantI.getCurrentUserLanguageId()) {
            service.onLoadPollById(response.Poll.id)
            .then((response)=>{
                service.updatePoll(response.Poll);
            }, ()=>{});
        } else
            service.updatePoll(response.Poll);

        setTimeout(()=>{
            if(!_inner.ui.isDrawerOpen && response && response.Poll && callI.getActiveParticipantId() !== participantI.getParticipantIdByAccountProfileAndCallId(response.Poll.created_by, response.Poll.mre_call_id)) {
                if(!_inner.ui.counter)
                    _inner.ui.counter = 0;
                _inner.ui.counter++;
                callI.updateGlobalCounter();
                notificationI.playAudio(notificationI.AUDIO_KEYS.NEW_MESSAGE);
            }
        });
    }

    service.onSubmitPoll = function(obj, anonymous,allowMultipleAnswers) {
        // _inner.ui.list.push(obj) // demo only
        return new Promise((resolve, reject)=>{
            if(!obj || typeof obj !== 'object' || !callI.getActiveCallId()) {
                reject();
                return;
            }
            let payload = {
                Survey: obj,
                is_anonymous: anonymous? true: false,
                AllowMultipleAnswers: allowMultipleAnswers? true : false
            }
            networkI.post({url: '/api/call/' + callI.getActiveCallId()+ '/poll', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }

    service.onVote = function(id, obj) {
        return new Promise((resolve, reject) => {
            if(!id || !obj || !Array.isArray(obj) || !callI.getActiveCallId()) {
                reject();
                return;
            }
            let payload = {
                Answers: obj
            }
            networkI.put({url: '/api/call/' + callI.getActiveCallId()+ '/poll/'+id, data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                if(res && res.Poll && res.Poll.id) {
                    service.updatePoll(res.Poll);
                    let poll = service.getPollById(res.Poll.parent_id? res.Poll.parent_id: res.Poll.id);
                    if(poll) {
                        poll.canVote = false;
                    }
                    resolve();
                } else
                    reject();
            }, (error)=>{
                reject(error);
            });
        });
    }

    service.notifyChangeLanguage = () => {
        clearTimeout(_inner.onChangeLanguageTimer);
        _inner.onChangeLanguageTimer = setTimeout(()=>{
            service.loadPolls();
        }, 50);
    }

    service.getUIBind = ()=>{
        if(_inner.ui) {
          _inner.notifyUI();
          return _inner.ui;
        }
        _inner.ui = {
            counter: 0,
            isCreating: false,
            list: [],
            formData: {
                question:'',
                anonymous: false,
                allowMultipleAnswers:true,
                options: [{answer:''},{answer:''}]
            },
            create: () => {
                _inner.ui.reset();
                _inner.ui.isCreating = true;
            },
            addOption: () => {
                _inner.ui.formData.options.push({
                    answer: ''
                })
            },
            removeOption: (index) => {
                if(typeof index !== 'number')
                    return;
                _inner.ui.formData.options.splice(index, 1);
            },
            reset: () => {
                _inner.ui.formData.question = '';
                _inner.ui.formData.options = [{answer: ''},{answer:''}];
                _inner.ui.formData.anonymous = false;
                _inner.ui.formData.allowMultipleAnswers = true
            },
            cancel: () => {
                _inner.ui.isCreating = false;
                _inner.ui.reset();
            },
            submit: ()=>{
                if(_inner.ui.isSubmitting || !_inner.ui.formData.question)
                    return;
                let obj = {};
                obj.question = _inner.ui.formData.question;
                obj.anonymous = _inner.ui.formData.anonymous;
                obj.allowMultipleAnswers = _inner.ui.formData.allowMultipleAnswers;
                obj.options = [];
                for(let i=0; i<_inner.ui.formData.options.length; i++) {
                    let option = _inner.ui.formData.options[i];
                    if(!option.answer)
                        continue;
                    let o = {key: ''+i, answer: option.answer, selected: false};
                    obj.options.push(o);
                }
                if(obj.options.length<2)
                    return;
                _inner.ui.isSubmitting = true;
                service.onSubmitPoll({options: obj.options, question: obj.question}, obj.anonymous,obj.allowMultipleAnswers)
                .then((response)=>{
                    if(response.Poll)
                        service.updatePoll(response.Poll);
                    else
                        toastI.error(langI.get('TRY_AGAIN_OR_CONTACT'));
                }, (error)=>{
                    toastI.error(error && typeof error==='string'? error: langI.get('TRY_AGAIN_OR_CONTACT'));
                }).finally(()=>{
                    _inner.ui.isSubmitting = false;
                    _inner.ui.isCreating = false;
                });
            },
            submitVote: (poll) => {
                if(_inner.ui.isSubmitting || !poll || !poll.id || !Array.isArray(poll.options) || !callI.getActiveParticipantId())
                    return;
                _inner.ui.isSubmitting = true;
                let answers = [];
                for(let i=0; i<poll.options.length; i++) {
                    if(typeof poll.options[i].key !== 'string' && !poll.options[i].key)
                        continue;
                    let obj = {key: poll.options[i].key, participant_id: []};
                    if(poll.options[i].selected)
                        obj.participant_id.push(callI.getActiveParticipantId());
                    answers.push(obj);
                }
                service.onVote(poll.id, answers).then(()=>{}, (error)=>{
                    toastI.error(error && typeof error==='string'? error: langI.get('TRY_AGAIN_OR_CONTACT'));
                }).finally(()=>{
                    _inner.ui.isSubmitting = false;
                });
            },
            showDetails:(poll) => {
                if(!poll || poll.is_anonymous || _inner.ui.isSubmitting) return;
                poll.showDetails = !poll.showDetails;
            },
            changeVote: (poll) => {
                if(!poll || _inner.ui.isSubmitting) return;
                poll.canVote = true;
            },
            skipVote: (poll) => {
                if(!poll || _inner.ui.isSubmitting) return;
                poll.canVote = false;
                if(Array.isArray(poll.answers) && Array.isArray(poll.options)) {
                    for(let i=0; i<poll.answers.length; i++) {
                        for(let j=0; j<poll.options.length; j++) {
                            if(poll.answers[i].key === poll.options[j].key) {
                                poll.options[j].selected = Array.isArray(poll.answers[i].participant_id) && poll.answers[i].participant_id.indexOf(callI.getActiveParticipantId())>-1? true: false;
                            }
                        }
                    }
                }
            },
            isDrawerOpen: false,
            toggleDrawer: () => {
                _inner.ui.isDrawerOpen = !_inner.ui.isDrawerOpen;
                if(_inner.ui.isDrawerOpen && _inner.ui.list.length==0) {
                    service.loadPolls();
                }
                if(_inner.ui.isDrawerOpen) {
                    _inner.ui.counter = 0;
                }
            },
            closeDrawer: ()=>{
                _inner.ui.isDrawerOpen = false;
            },
            onOptionClicked(poll,index,checked){
                if(!poll) return
                const options =  Array.isArray(poll.options) ? poll.options : [];
                if(!poll.allowMultipleAnswers){
                    options.forEach(option=>{
                        option.selected = false;
                    })
                }
                const option = options[index];
                if(!option) return
                option.selected = +checked === 1 ? true : false;
            }
        };
        return _inner.ui;
    }
    service.clear = ()=>{
        _inner.polls = {};
        if(_inner.ui) {
            _inner.ui.counter = 0;
            _inner.ui.list = [];
            _inner.ui.reset();
            _inner.ui = null;
        }
    }
    service.getCounter = ()=> _inner.ui ? _inner.ui.counter : 0
    return service;
}
export const pollI = PollI();