import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { callI } from "src/assets/js/callI";
import { langI } from "src/assets/js/langI";
import { modalI } from "src/assets/js/modals";
import { toastI } from "src/assets/js/toastI";
import { userI } from "src/assets/js/userI";
@Component({
  selector: "app-rating-modal",
  templateUrl: "./rating-modal.component.html",
  styleUrls: ["./rating-modal.component.scss"],
})
export class RatingModalComponent {
  @Input() call;
  containerId: string = '';
  rating: number = 0;
  feedback: string = "";
  schedule_demo: boolean = false;
  guest_name: any;
  email_address: any;
  isGuest: boolean = true;
  isSending: boolean = false;
  constructor(public router: Router) {}

  ngOnInit() {
    this.containerId = modalI.ID.RATING_CALL_MODAL;
    this.isGuest = userI.isGuest();
    callI.clearStoredActiveCall();
  }

  public submit() {
    if(this.isSending)
      return;
    this.isSending = true;
    if (this.schedule_demo && userI.isGuest()) {
      if (!this.email_address) {
        toastI.error(langI.get('FIELD_REQUIRED', ['Email Address']));
        return;
      }
      if (!this.guest_name) {
        toastI.error(langI.get('FIELD_REQUIRED', ['Name']));
        return;
      }
    }
    let payload  = {
      scheduleDemo: this.schedule_demo,
      rating: this.rating,
      feedback: this.feedback,
      name: this.guest_name,
      email: this.email_address
    }

    userI.onSendFeedback(payload)
    .then(()=>{
      toastI.success(langI.get(this.schedule_demo? 'FEEDBACK_MSG': 'FEEDBACK_SUCCESS'));
      this.dismiss();
    }, (error)=>{
      toastI.error(error);
    }).finally(()=>{
      this.isSending = false;
    });

  }

  public dismiss() {
    modalI.closeModal(modalI.ID.RATING_CALL_MODAL);
    callI.clearStoredActiveCall();
  }

}
