import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { APP_BASE_HREF, DatePipe } from "@angular/common";
import { RouteReuseStrategy } from '@angular/router'
import { RouteCacheStrategy } from './services/route-cache-service/route-cache.service'

import { LoadingPage } from "./loading/loading.page";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { SideMenuComponentModule } from "./side-menu/side-menu.module";

import { RecordingConfirmationModal } from "./modals/recording-confirmation-modal/recording-confirmation-modal";
import { EmailVerificationModal } from "./modals/email-verification-modal/email-verification-modal";
import { ExitCallModalPage } from "./call/exit-call/exit-call";
import { RatingModalComponent } from "./modals/rating-modal/rating-modal.component";

import { callI } from "src/assets/js/callI";
import { chatI } from "src/assets/js/chatI";
import { participantI } from "src/assets/js/participantI";
import { loadingI } from "../assets/js/loadingI";
import { toastI } from "src/assets/js/toastI";
import { serviceProviderI } from "src/assets/js/serviceProviderI";
import { busI } from "src/assets/js/busI";
import { userI } from "src/assets/js/userI";
import { navbarI } from "../assets/js/navbarI";
import { widgetI } from "src/assets/js/widgetI";
import { langI } from "src/assets/js/langI";
import { notificationI } from "src/assets/js/notificationI";
import { alertI } from "src/assets/js/alertI";
import { CallGuard} from "./router-guards/call.guard";
import { UserGuard } from "./router-guards/user.guard";
import { ConnectionGuard } from "./router-guards/connection.guard";
import { GuestGuard } from "./router-guards/guest.guard"
import { AppDashboardComponent } from './app-dashboard/app-dashboard.component';
import { AppInstallPromptComponent } from './app-install-prompt/app-install-prompt.component';
import { ExistingCallModalComponent } from './modals/existing-call-modal/existing-call-modal';
import moment from "moment";
import { streamI } from "src/assets/js/streamI";
import { messagingI } from "src/assets/js/messagingI";
import { screenI } from "src/assets/js/screenI";
import notificationEventI from "../notificationEventI";
import { endpointI } from "../assets/js/endpointI";
import { transcriptionI } from "src/assets/js/transcriptionI";
import { smartExpertiseI } from "src/assets/js/smartExpertiseI";
import { whiteBoardI } from "../assets/js/whiteBoardI";
import { roomI } from "src/assets/js/roomI";
import { sipI } from "src/assets/js/sipI";
import { modalI } from "../assets/js/modals";
import { livekitI } from "src/assets/js/livekitI";
@NgModule({
    declarations: [
        AppComponent,
        ExitCallModalPage,
        RecordingConfirmationModal,
        EmailVerificationModal,
        AppDashboardComponent,
        AppInstallPromptComponent,
        RatingModalComponent,
        ExistingCallModalComponent
    ],
    imports: [
        SideMenuComponentModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        BrowserModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        DatePipe,
        LoadingPage,
        CallGuard,
        UserGuard,
        ConnectionGuard,
        GuestGuard,
        { provide: RouteReuseStrategy, useClass: RouteCacheStrategy },
        { provide: APP_BASE_HREF, useValue: '/' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    protected loadingPage: LoadingPage
  ) {
    alertI.init();
    toastI.init();
    callI.setCallInvites([]);
    notificationI.initAudio();
    busI.unregisterEvent(busI.EVENTS.ON_MESSAGE_EVENT, this.onMessage);
    busI.registerEvent(busI.EVENTS.ON_MESSAGE_EVENT, this.onMessage);
    busI.registerEvent(busI.EVENTS.ON_USER_CONNECTED, this.onUserConnected);
    busI.registerEvent(busI.EVENTS.ON_SOCKET_DISCONNECTED, this.onSocketDisconnected);
    document.removeEventListener('fullscreenchange', this.onFullScreenChange);
    document.addEventListener('fullscreenchange', this.onFullScreenChange);
  }

  onUserConnected = ()=>{
    clearTimeout(this['onUserConnectedTimer']);
    this['onUserConnectedTimer'] = setTimeout(()=>{
      screenI.removeWidget();
      messagingI.init();
    }, 50);
  }

  onMessage = (msg) => {
    if(!msg || !msg.event || !msg.body)
      return;
    let body = msg.body;
    if(messagingI.isEventHandled(msg.eventId))
      return;
    messagingI.storeEventId(msg.eventId);
    const canShowNotification = !document.hasFocus() || document.hidden || document.visibilityState === 'hidden';
    const notificationTitle = serviceProviderI.getServiceProviderName() || 'TeleMeetUp';
    let iconUrl = serviceProviderI.getLogo() || '/assets/icons/icon-512x512.png';
    if(iconUrl.indexOf('/')===0) iconUrl = iconUrl.substring(1, iconUrl.length);
    switch(msg.event) {
      case 'notificationClicked':
        if(msg.body.type === "chatmessage") {
          chatI.setTmpActiveChatId(msg.body.tag)
          return
        }
        callI.setCallInviteNotificationClicked(msg.body.tag);
        setTimeout(()=>{
          if(msg.body.tag){
            callI.getCallInvites().forEach(invite=>{
              if(invite.call?.mre_call_id === msg.body.tag){
                toastI.closeToast(invite.popup?.container);
                notificationI.stopAudio(notificationI.AUDIO_KEYS.INCOMING_CALL);
              }
            })
          }
        })
        break;
      case 'onlineOfflineStatus':
        let friend = userI.getFriendById(body.account_profile_id);
        if(!friend)
          return;
        if(+friend.is_online !== +body.is_online) {
          friend.is_online = +body.is_online===1? true: false;
          userI.updateFriend(friend);
          toastI.info(langI.get(friend.is_online? 'USER_CONNECTED': 'USER_DISCONNECTED', [friend.screen_name]));
          busI.notifyEvent(busI.EVENTS.ON_FRIENDS_STATE_UPDATED);
        }
        break;
      case 'call':
        if(!body.AccountProfile || (body.MreCall&&body.MreCall.is_chat_only))
          return;
        if(body.IsInviteAccepted) {
          if(!body.AccountProfile || body.AccountProfile.account_profile_id === userI.getAccountProfileId()) return;
          let friendsList = userI.getFriends();
          if(body.AccountProfile)
            for (let i = 0; i < friendsList.length; i++) {
              if (friendsList[i].account_profile_id == body.AccountProfile.account_profile_id && friendsList[i].is_invited) {
                friendsList[i].is_invited = false;
              }
            }
          let subject = body.MreCall && body.MreCall.call_subject && body.MreCall.mre_call_id!==callI.getActiveCallId()? (' for ' + body.MreCall.call_subject): '';
          toastI.success(langI.get('CALL_INVITE_ACCEPTED', [body.AccountProfile.screen_name, subject]), 0, {fontsize: '.9rem'});
          return;
        }
        if(body.IsDeclined) {
          if(!body.AccountProfile || body.AccountProfile.account_profile_id === userI.getAccountProfileId()) return;
          let friendsList = userI.getFriends();
          if(body.AccountProfile)
            for (let i = 0; i < friendsList.length; i++) {
              if (friendsList[i].account_profile_id == body.AccountProfile.account_profile_id && friendsList[i].is_invited) {
                friendsList[i].is_invited = false;
              }
            }
          let subject = body.MreCall && body.MreCall.call_subject && body.MreCall.mre_call_id!==callI.getActiveCallId()? (' ' + body.MreCall.call_subject): '';
          toastI.warning(langI.get('CALL_INVITE_DECLINED', [body.AccountProfile.screen_name, subject]), 0, {fontsize: '.9rem'});
          return;
        }
        if(body.IsInviteCancelled) {
          if(body.MreCall && body.MreCall.mre_call_id===callI.getActiveCallId())
            return;
          if(!body.AccountProfile || body.AccountProfile.account_profile_id === userI.getAccountProfileId()) return;
          let friendsList = userI.getFriends();
          if(body.AccountProfile)
            for (let i = 0; i < friendsList.length; i++) {
              if (friendsList[i].account_profile_id == body.AccountProfile.account_profile_id && friendsList[i].is_invited) {
                friendsList[i].is_invited = false;
              }
            }
          let subject = body.MreCall && body.MreCall.call_subject && body.MreCall.mre_call_id!==callI.getActiveCallId()? (' for ' + body.MreCall.call_subject): '';
          toastI.success(langI.get('CALL_INVITE_CANCELLED', [body.AccountProfile.screen_name, subject]), 0, {fontsize: '.9rem'});
          callI.removeCall(body.MreCall.mre_call_id);
          busI.notifyEvent(busI.EVENTS.ON_CALLS_UPDATED);
          return;
        }
        if(body.MreCall && body.MreCall.is_scheduled && body.MreCall.is_active) {
          if(body.MreCall.scheduled_date && body.MreCall.join_code && !callI.getCallById(body.MreCall.mre_call_id)) {
            let time = moment(body.MreCall.scheduled_date).format('dddd, D MMMM YYYY hh:mm A (Z)') ?? '-';
            let sentence = body.AccountProfile.account_profile_id !== userI.getAccountProfileId()? 'You are invited to': 'You have';
            toastI.info(`${sentence} a Call <br/> At: <b>${time}.</b><br/> Join code: <b>${body.MreCall.join_code}.</b>`);
          }
        } else if(body.MreCall && body.MreCall.is_active) {
          if(callI.isRoom(body.MreCall))
            return;
          if(callI.getActiveCallId() === body.MreCall.mre_call_id) return
          if(body.AccountProfile.account_profile_id !== userI.getAccountProfileId() && !callI.isCallInviteNotificationClicked(body.MreCall.mre_call_id)) {
            let username = body.AccountProfile.screen_name;
            let image = body.AccountProfile&&body.AccountProfile.avatar_url? body.AccountProfile.avatar_url: userI.getDefaultAvatar();
            notificationI.playAudio(notificationI.AUDIO_KEYS.INCOMING_CALL);
            if(canShowNotification){
              notificationEventI.onShowNotification(body.MreCall?.mre_call_id,notificationTitle,{
                icon:iconUrl,
                body:`Call Invite from ${username}`,
                tag:body.MreCall?.mre_call_id,
                data:{
                  url:location.origin+'?endpoint='+endpointI.getSelectedEndpointName()+'&token='+body.MreCall?.join_code+'&providerId='+body.MreCall?.mre_call_provider_id
                }
              }).then(()=>{},()=>{})
            }
            let obj  = {image: image, name: "You have a call from" + " " + username + ".", status: 'Ringing', container: {}};
            toastI.displayPopup(obj, 20000, {action: 'Accept', cancel: 'Decline', onClose: (container)=>{
              notificationI.stopAudio(notificationI.AUDIO_KEYS.INCOMING_CALL);
              if(container)
                toastI.closeToast(container);
            }})
            .then(async ()=>{
              notificationI.stopAudio(notificationI.AUDIO_KEYS.INCOMING_CALL);
              if (livekitI.isRoomActive()) {
                let res = await this.presentExitCallModal();
                if (res && typeof res.isStop==='boolean') {
                  let limit = 10;
                  let run = () => {
                    limit--;
                    //we wait until we don't have a room active
                    if(limit>0 && livekitI.isRoomActive()) {
                      setTimeout(()=>{
                        run();
                      }, 500)
                      return;
                    }
                    setTimeout(()=>{
                      this.getMreCallForInvites(body.MreCall);
                    }, 1000);
                  }
                  run();
                }
              } else {
                this.getMreCallForInvites(body.MreCall);
              }
            }, ()=>{
              notificationI.stopAudio(notificationI.AUDIO_KEYS.INCOMING_CALL);
              callI.onDeclineInvite({id: body.MreCall.mre_call_id})
              .then(()=>{}, (error)=>{
                toastI.error(typeof error==='string'? error: langI.get('ERROR_UNK'));
              });
            });
            callI.addInvite({popup: obj, call: body.MreCall});
          }
        }
        callI.updateCall(body.MreCall, body);
        busI.notifyEvent(busI.EVENTS.ON_CALLS_UPDATED);
        break;
      case 'generalmessage':
        if(body.Content && body.IsFromAdmin)
          toastI.info(body.Content);
        break;
      case 'providerupdated':
        if(body.MreCallProvider)
          serviceProviderI.updateServiceProvider(body.MreCallProvider);
        break;
      case 'accountdeleted':
        if(body.AccountProfile && body.AccountProfile.account_profile_id === userI.getAccountProfileId()) {
          userI.logout();
          toastI.warning(langI.get('ACCOUNT_SUSPENDED'));
        }
        break;
      case 'rolechanged':
        if(body.Role && body.AccountProfile && body.AccountProfile.account_profile_id === userI.getAccountProfileId()) {
          userI.updateWhoAmIRole(body.Role);
          userI.setRole(body.Role);
        }
        break;
      case 'checkalive':
        if(body.MreCallID && body.MreCallID === callI.getActiveCallId()) {
          userI.onPing().then(()=>{}, ()=>{});
        }
        break;
      case 'callupdated':
        if(body.MreCall) {
          setTimeout(()=>{
            if(body.MreCall.is_chat_only){
              chatI.updateChat(body.MreCall)
              return
            }
            callI.updateCall(body.MreCall, body);
            smartExpertiseI.sync(callI.getActiveCall())
            busI.notifyEvent(busI.EVENTS.ON_CALLS_UPDATED);
            transcriptionI.updateTranscriptionStateBasedOnCurrentParticipant(body);
          });
        }
        break;
      case 'chatstarted':
        if(body.MreCall&&body.MreCall.is_chat_only) {
          chatI.onHandleNewChat(body.MreCall.mre_call_id).then(()=>{
            busI.notifyEvent(busI.EVENTS.ON_CHATS_UPDATED);
            let name = body.MreCallParticipant && body.MreCallParticipant.name? body.MreCallParticipant.name: 'Contact';
            toastI.info(langI.get('CONVERSATION_CHAT_STARTED', [name]));
          }, ()=>{});
        }
        break;
      case 'chatmessage':
        if(canShowNotification){
          notificationEventI.onShowNotification(body.ChatMessage?.mre_call_id,notificationTitle,{
            icon: iconUrl,
            body:`Message from ${body.MreCallParticipant.name}` ,
            tag: body.ChatMessage?.mre_call_id,
            data:{
              url: location.origin+'?endpoint='+endpointI.getSelectedEndpointName() + '&chatId='+body.ChatMessage?.mre_call_id
            }
          }).then(()=>{},()=>{})
        }
        chatI.handleChatReceived(body);
        break;
      case 'callstopped':
        if(widgetI.isWidget){ return widgetI.onFinish(); }
        if(body.MreCall && body.MreCall.is_chat_only) {
          if(userI.getAccountProfileId()
          !== body.MreCallParticipant.account_profile_id) {
            let name = body.MreCallParticipant && body.MreCallParticipant.name? body.MreCallParticipant.name: 'Contact';
            toastI.info(langI.get('CONVERSATION_CHAT_STOPPED', [name]));
          }
          chatI.deleteChatById(body.MreCall.mre_call_id);
          busI.notifyEvent(busI.EVENTS.ON_CHATS_UPDATED);
          return;
        }
        let invites = callI.getCallInvites();
        for (let i = 0; i < invites.length; i++) {
          if (invites[i].call.mre_call_id == body.MreCall.mre_call_id) {
            if(invites[i].popup && invites[i].popup.container)
              toastI.closeToast(invites[i].popup.container);
            notificationI.stopAudio(notificationI.AUDIO_KEYS.INCOMING_CALL);
          }
        }
        roomI.leaveRoomIfNeeded(body?.MreCall?.mre_call_id)
        if(body.MreCall && (body.MreCall.mre_call_id === callI.getActiveCallId()))
          busI.notifyEvent(busI.EVENTS.ON_REQUEST_LEAVE_CALL, 'leave');
        setTimeout(()=>{
          callI.updateCall(body.MreCall, body);
          busI.notifyEvent(busI.EVENTS.ON_CALLS_UPDATED);
        });
        break;
      case 'participantupdated':
        // handle participant removed / added from / to chat
        if(body.MreCallParticipant && body.MreCallParticipant.joined && body.MreCallParticipant.is_declined && body.MreCallParticipant.mre_call_participant_id === callI.getActiveParticipantId()){
          toastI.warning(langI.get('PARTICIPANT_DECLINED'))
          busI.notifyEvent(busI.EVENTS.ON_REQUEST_LEAVE_CALL, 'leave');
          return
        }
        if(body.MreCall && body.MreCall.is_chat_only && body.MreCallParticipant){
          chatI.handleChatParticipantAddedOrRemoved(body.MreCall.mre_call_id,body.MreCallParticipant);
          return
        }
        if(!callI.getActiveCallId() || !body.MreCallParticipant || !callI.getActiveParticipantId()) {
          return;
        }
        participantI.updateParticipants([body.MreCallParticipant]);

        if(!body.MreCallParticipant || body.MreCallParticipant.mre_call_id !== callI.getActiveCallId())
          return;
        transcriptionI.updateStateBaseOnParticipants();
        if(body.MreCallParticipant && body.MreCallParticipantSip
          && body.MreCallParticipant.mre_call_id === callI.getActiveCallId()
          && body.MreCallParticipantSip.mre_call_participant_id) {
            sipI.updateParticipant([body.MreCallParticipantSip]);
          return;
        }
        let participant = participantI.getParticipantById(body.MreCallParticipant.mre_call_participant_id);
        if(callI.isActiveCallByHost() && body.MreCallParticipant.joined &&
          participantI.isParticipantLegitForWaitingRoom(body.MreCallParticipant)
          &&
          participantI.isInternalParticipant(body.MreCallParticipant)) {
          streamI.onParticipantLeaveWaitingRoom(body.MreCallParticipant.mre_call_participant_id);
          streamI.admitParticipant(participant.mre_call_participant_id);
          return;
        }
        if(callI.isActiveCallByHost() && participant && streamI.isUserOnDisconnecting(participant.mre_call_participant_id)
        && participantI.isParticipantLegitForWaitingRoom(body.MreCallParticipant)) {
          streamI.onParticipantLeaveWaitingRoom(body.MreCallParticipant.mre_call_participant_id);
          streamI.admitParticipant(participant.mre_call_participant_id);
          return;
        }
        const toastId = `admit-toast-${body.MreCallParticipant.mre_call_participant_id}`;
        if(!body.MreCallParticipant.joined) {
          streamI.removeParticipantFromWaitingRoom(body.MreCallParticipant.mre_call_participant_id)
          toastI.closeToastById(toastId)
          return
        }
        if(participant && participant.sent_to_waiting_room) {
          streamI.onNewParticipantInWaitingRoom(participant);
          participant.sent_to_waiting_room = false;
          return;
        }
        if(!livekitI.getParticipant(body.MreCallParticipant.mre_call_participant_id) && !callI.isActiveCallByHost() && body.MreCallParticipant.mre_call_participant_id === callI.getActiveParticipantId() && participantI.isParticipantLegitToEnterCall(body.MreCallParticipant)) {
          streamI.notifyActiveParticipantToEnterCall();
          return
        }
        if(participantI.isParticipantLegitForWaitingRoom(body.MreCallParticipant) && body.MreCallParticipant.mre_call_participant_id !== callI.getActiveParticipantId() && callI.isActiveCallByHost()) {
          const exists = streamI.isParticipantInWaitingRoom(body.MreCallParticipant.mre_call_participant_id)
          if(!exists){
            streamI.onNewParticipantInWaitingRoom(body?.MreCallParticipant);
            let obj  = {image: body.MreCallParticipant.avatar_url , name:`${body.MreCallParticipant.name} is in waiting room`, status: '', container: {}};
            notificationI.playAudio(notificationI.AUDIO_KEYS.WAITING_ROOM);
            toastI.displayPopup(obj, 20000, {action: 'Admit', cancel: 'Decline',id:toastId, onClose:(container)=>{
              if(container)
              toastI.closeToast(container);
            }})
            .then(()=>{
              streamI.admitParticipant(body.MreCallParticipant?.mre_call_participant_id);
            },()=>{
              streamI.declineParticipant(body.MreCallParticipant?.mre_call_participant_id)
            })
          } else {
            toastI.closeToastById(toastId)
          }
        }
        break;
      case 'contact':
        if(!body.AccountProfile || !body.AccountProfile.account_profile_id)
          return;
        if(body.IsRequest) {
          userI.addFriendRequest(body.AccountProfile);
          let username = body.AccountProfile.screen_name;
          let image = body.AccountProfile.avatar_url? body.AccountProfile.avatar_url: userI.getDefaultAvatar();
          let obj  = {image: image, name: username + " sent you a friend request.", status: 'Waiting', container: {}};
          toastI.displayPopup(obj, 20000, {action: 'Accept', cancel: 'Decline'})
          .then(()=>{
            userI.onAcceptFriendRequest(body.AccountProfile)
            .then((profile)=>{
              if(profile)
                toastI.success(langI.get('USER_FRIEND_INVITE_ADDED', [profile.screen_name]));
            }, (msg)=>{
              toastI.error(msg);
            });
          }, ()=>{
            userI.onDeclineFriendRequest(body.AccountProfile)
            .then(()=>{}, (msg)=>{
              toastI.error(msg);
            });
          });
          return;
        }
        if(body.IsDeleted) {
          let friendsList = userI.getFriends();
          for (let i = 0; i < friendsList.length; i++) {
            if(friendsList[i].account_profile_id == body.AccountProfile.account_profile_id) {
              toastI.info(langI.get('USER_FRIEND_REMOVED', [friendsList[i].screen_name]));
              friendsList.splice(i, 1);
              break;
            }
          }
          let receivedFriendRequests = userI.getFriendRequests();
          for (let i = 0; i < receivedFriendRequests.length; i++) {
            if (receivedFriendRequests[i].account_profile_id == body.AccountProfile.account_profile_id) {
              toastI.info(langI.get('USER_FRIEND_INVITE_CANCELED', [receivedFriendRequests[i].screen_name]));
              receivedFriendRequests.splice(i, 1);
            }
          }
          busI.notifyEvent(busI.EVENTS.ON_FRIEND_REQUESTS_UPDATED);
          busI.notifyEvent(busI.EVENTS.ON_FRIENDS_STATE_UPDATED);
          return;
        }
        if(body.IsAccepted) {
          userI.updateFriend(body.AccountProfile);
          let sentFriendRequests = userI.getSentFriendRequests();
          for (let i = 0; i < sentFriendRequests.length; i++) {
            if (sentFriendRequests[i].account_profile_id == body.AccountProfile.account_profile_id) {
              sentFriendRequests.splice(i, 1);
              break;
            }
          }
          toastI.success(langI.get('USER_FRIEND_INVITE_ACCEPTED', [body.AccountProfile.email_address]));
          busI.notifyEvent(busI.EVENTS.ON_FRIEND_REQUESTS_UPDATED);
          setTimeout(()=>{
            busI.notifyEvent(busI.EVENTS.ON_FRIENDS_STATE_UPDATED);
          });
          return;
        }
        if(body.IsDeclined) {
          let sentFriendRequests = userI.getSentFriendRequests();
          for (let i = 0; i < sentFriendRequests.length; i++) {
            if (sentFriendRequests[i].account_profile_id == body.AccountProfile.account_profile_id) {
              sentFriendRequests.splice(i, 1);
              break;
            }
          }
          toastI.warning(langI.get('USER_FRIEND_INVITE_DECLINED', [body.AccountProfile.email_address]));
          busI.notifyEvent(busI.EVENTS.ON_FRIEND_REQUESTS_UPDATED);
          return;
        }
        break;
      case 'roomupdated':
        if(body.AccountProfile && body.MreCallRoom) {
          roomI.runCheckRooms(body.MreCallRoom.mre_call_room_id);
        }
        break;
      default: break;
    }
  }

  onSocketDisconnected = () => {
    clearTimeout(this['onSocketDisconnectedTimer']);
    this['onSocketDisconnectedTimer'] = setTimeout(()=>{
      busI.notifyEvent(busI.EVENTS.ON_DISPATCH_SOCKET_DISCONNECTED);
    }, 10);
  }

  async  presentExitCallModal() {
    return modalI.onDisplayExitCallModal();
  }

  onMreCallInviteOk(res) {
    if(!res.MreCall)
      return;
    loadingI.showLoading(langI.get('PREPARING_CALL'));
    callI.setActive({participant: res.MreCallParticipant, call: res.MreCall});
    callI.onLoadExpandedCall(res.MreCall.mre_call_id, {participants: 1})
    .then((response)=>{
      participantI.updateParticipants(response.MreCallParticipants);
      callI.JoinCall();
      navbarI.navForward('calls');
    }, (error)=>{
      toastI.error(error);
    }).finally(()=>{
      loadingI.hideLoading();
    });
  }
  async getMreCallForInvites(obj) {
    if(!obj || !obj.join_code)
      return;
    callI.onLoadCallByIdOrToken(obj.join_code, obj.mre_call_id)
    .then(async (call)=>{
      if(call && call.is_active) {
        loadingI.showLoading();
        callI.onAcceptInvite({id: call.mre_call_id})
        .then((res)=>{
          loadingI.hideLoading();
          this.onMreCallInviteOk(res);
        }, (error)=>{
          toastI.error(typeof error=='string'? error: langI.get('ERROR_JOIN_CALL'));
          loadingI.hideLoading();
        });
      } else {
        toastI.error(langI.get('ERROR_INVALID_TOKEN'));
      }
    }, (error)=>{
      toastI.error(error);
    });
  }
  onFullScreenChange () {
    clearTimeout(this['fullscreenchangeTimer']);
    this['fullscreenchangeTimer'] = setTimeout(()=>{
      busI.notifyEvent(busI.EVENTS.ON_DISPATCH_FULL_SCREEN_CHANGE);
    }, 50);
  }
}
