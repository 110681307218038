import { livekitI } from "./livekitI";
import { streamI } from "./streamI";
import { toastI } from "./toastI";

var ScreenshareI = function () {
    let _inner = {};
    _inner.isOpen = false;

    let service = {};
    service.init = ()=>{
    }
    service.isEnabled = ()=>{
        return _inner.isOpen||_inner.ui.isOpen? true: false;
    }
    _inner.onOpenDevice = ()=>{
        return new Promise((resolve, reject)=>{
            _inner.isOpen = true;
            _inner.notifyUI();
            resolve();
        });
    }
    _inner.onStopDevice = ()=>{
        return new Promise((resolve, reject)=>{
            _inner.isOpen = false;
            _inner.notifyUI();
            resolve();
        });
    }
    _inner.notifyUI = ()=>{
        if(!_inner.ui)
            return;
        _inner.ui.isOpen = _inner.isOpen;
    }
    _inner.open = ()=>{
        _inner.isOpen = true;
        _inner.notifyUI();
    }
    _inner.stop = ()=>{
        _inner.isOpen = false;
        _inner.notifyUI();
    }
    _inner.toggle = () => {
        if(screenshareI.isEnabled()) {
            livekitI.onDisableScreenShare().then(()=>{}, ()=>{})
            .finally(()=>{
                _inner.stop();
            })
        } else {
            livekitI.onEnableScreenShare().then(()=>{
                _inner.open();
            }, (msg)=>{
                livekitI.onDisableScreenShare().then(()=>{}, ()=>{})
                .finally(()=>{
                    _inner.stop();
                })
                toastI.error(msg);
            });
        }
    }
    service.getUIBind = ()=>{
        if(_inner.ui)
            return _inner.ui;
        _inner.ui = {
            isOpen: _inner.isOpen,
            onOpen: _inner.onOpenDevice,
            open: _inner.open,
            onStop: _inner.onStopDevice,
            stop: _inner.stop,
            toggle: _inner.toggle
        };
        return _inner.ui;
    }
    return service;
}

export const screenshareI = ScreenshareI();
