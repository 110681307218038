<div class="modal-wrapper" [attr.data-modalid]="containerId">
    <div class="modal-backdrop"></div>
    <div class="modal-container md-fit min-width-desktop-550">
        <div class="modal-header">
            <span class="heading">Verification</span>
            <button class="transparent close-btn">
                <span class="material-icons">close</span>
            </button>
        </div>
        <div class="modal-body md-padding-bottom">
            <div class="flex-col p-4 gap-30">
                <div class="width-full gap-20 flex-row space-between align-center">
                    <div class="c-input">
                        <div class="form-item">
                            <div class="input-wrapper">
                                <input type="text" name="verification_code" id="verification_code" [(ngModel)]="verification_code"
                                placeholder="Verification code"
                                autocomplete="off"
                                (keyup.enter)="verificateEmail()"
                                required>
                            </div>
                        </div>
                    </div>
                    <div class="c-button">
                        <button class="large ws-nowrap" (click)="requestCode()">
                            <span class="btn-loader" *ngIf="isRequestingCode"><i class='bx bx-loader-alt bx-spin'></i></span>
                            <span *ngIf="!isRequestingCode">Resend code</span>
                        </button>
                    </div>
                </div>
                <div class="width-full gap-20 flex-row space-between align-center ws-nowrap">
                    <button class="large" (click)="dismiss()">Cancel</button>
                    <button class="large primary" [disabled]="!verification_code || isLoading" (click)="verificateEmail()">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</div>