import { busI } from "./busI";
import { callI } from "./callI";
import { chatI } from "./chatI";
import { toastI } from "./toastI";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { streamI } from "./streamI";
import { modalI } from "./modals";
import { domSanitizerI } from "./domSanitizerI";
import { notificationI } from "./notificationI";
import { serviceProviderI } from "./serviceProviderI";
let SmartExpertiseI = function () {
    const DEBUG = ['127.0.0.1','localhost','192.168'].some(item=>window.origin.indexOf(item)>-1);
    let service = {};
    let _inner = {};
    _inner.KEYS = {
        EXTRA_DATA: 'TMU.extra-call-data',
        EXTRA_DAMANAGES: 'TMU.extra-damages',
        SIGN_CMD: 'smartexpertisesign'
    }
    _inner.isOpen = false;
    _inner.ui = null;
    _inner.data = {
        call:null,
        insuredName: '',
        totalPrice: 0,
        attachements: [],
        tmpData:{
            totalPrice:null,
            enableEditPrice:false,
            savedPrice:null
        },
        signature: {
            safe_url:null,
            blob_url:null
        },
        extraData: {
            insuredName:"",
            // Information dossier
            dossier_date_sinistre:"",
            dossier_date_mission:"",
            dossier_ref_sinsitre:"",
            dossier_req_compte:"",
            dossier_garantie_invoke:"",
            dossier_intermediaire:"",
            // Assure
            assure_nom_et_prenom:"",
            assure_immatriculation:"",
            assure_email:"",
            assure_phone:"",
            assure_assurance_key:"",
            assure_num_police:"",
            assure_intermediaire:"",
            // Adversaire
            adversaire_nom_et_prenom:"",
            adversaire_immatriculation:"",
            adversaire_assurance_key:"",
            adversaire_num_police:"",
            adversaire_intermediaire:"",
            // Réparateur
            rep_id_garage:"",
            rep_social_reason:"",
            rep_assurance_key:"",
            rep_phone:"",
            rep_address:"",
            // Caractéristique Technique du Véhicule Expertisé
            veh_brand:"",
            veh_etatgeneral:"",
            veh_modele:"",
            veh_immatriculation:"",
            veh_typemine:"",
            veh_nserie:"",
            veh_puissance:"",
            veh_energie:"",
            veh_dmc:"",
            veh_kilometrage:""
        },
        extraDamages: []
    }
    _inner.downloadsCount = 0;
    _inner.defaultGuestName = "Client";
    _inner.extension = "png";
    _inner.format = `image/${_inner.extension}`
    let DEV = true;

    service.getChatCommand = function () {
        return _inner.KEYS.SIGN_CMD;
    }
    service.handleCommand = function (arr) {
        if (!Array.isArray(arr) || arr.length < 3)
            return;
        if (arr[1] === _inner.KEYS.SIGN_CMD) {
            let name = arr[2].replaceAll('_', ' ');
            _inner.data.insuredName = name;
            _inner.data.totalPrice = arr.length > 3 ? arr[3] : 0;
            busI.notifyEvent(busI.EVENTS.ON_REQUEST_WHITE_BOARD, arr);
        }
    }
    _inner.PAYLOAD_TYPES = {
        REQUEST:'REQUEST',
        RESPONSE:'RESPONSE'
    }
    service.handlePayload = (payload)=>{
        if(!serviceProviderI.hasMissionForm())
            return;
        if(typeof payload !== "object") return
        const isHost = callI.isActiveCallByHost();
        switch(payload.type){
            case _inner.PAYLOAD_TYPES.REQUEST:
                if(!isHost){
                    notificationI.playAudio(notificationI.AUDIO_KEYS.WAITING_ROOM);
                    if(payload.totalPrice) _inner.data.tmpData.totalPrice = payload.totalPrice
                    service.clearSignature();
                    if(!_inner.isOpen){
                        service.openMissionSignatureModal();
                    }else{
                        busI.notifyEvent(busI.EVENTS.ON_CLEAR_SIGNATURE)
                    }
                }
                break;
            case _inner.PAYLOAD_TYPES.RESPONSE:
                if(isHost){

                }
                break;
        }
    }
    service.getPayloadEventName = ()=>{
        return 'smartExpertise'
    }
    service.setIsRequesting = (value)=>{
        _inner.isRequesting = +value === 1;
        service.notifyUI();
    }
    service.requestSignature = function () {
        _inner.isRequesting = true;
        service.notifyUI();
        streamI.sendDataChannel({
            event:'smartExpertise',
            type:_inner.PAYLOAD_TYPES.REQUEST,
            totalPrice:_inner.data.tmpData.totalPrice
        })
    }
    service.getInsuredName = function () {
        if (_inner.data.insuredName)
            return _inner.data.insuredName;
        let data = service.getExtraData();
        return data && data.insuredName ? data.insuredName.trim() : '';
    }
    service.emptyExtra = function () {
        for(let key in _inner.data.extraData){
            _inner.data.extraData[key] = ""
        }
        _inner.data.extraDamages = []
    }
    service.getExtraData = function () {
        return _inner.data.extraData;
    }
    service.storeExtraDamages = function (obj) {
        _inner.data.extraDamages = obj;
        _inner.data.totalPrice = service.getTotalDamages();
        service.notifyUI();
    }
    service.getExtraDamages = function () {
        return _inner.ui?.extraDamages;
    }
    service.getTotalDamages = function () {
        if(_inner.data.tmpData.savedPrice) return _inner.data.tmpData.savedPrice;
        let damages = service.getExtraDamages();
        let total = 0;
        if (!Array.isArray(damages))
            return total;
        damages.forEach(element => {
            total = total + parseInt(element.prixTtc ? element.prixTtc : 0);
        });
        return total;
    }
    service.storeAttachement = (payload) => {
        if (!payload) return
        payload.uniqueId = Date.now().toString(16) + Math.random().toString(16)
        payload.selected = false
        _inner.data.attachements.push(payload);
    }
    service.storeSignature = (url) => {
        if (!url) return
        if(_inner.data.signature.blob_url && _inner.data.signature.blob_url !== url){
            window.URL.revokeObjectURL(_inner.data.signature.blob_url)
        }
        _inner.data.signature.safe_url = domSanitizerI.bypassSecurityTrustResourceUrl(url);
        _inner.data.signature.blob_url = url;
        service.notifyUI();
    }
    service.getAttachmenets = () => _inner.data.attachements.filter(obj => obj.type === "screenshot");
    service.getSignature = () => _inner.data.signature
    service.saveDataAsPdf = (options) => {
        if(!serviceProviderI.hasMissionForm())
            return;
        try {
            _inner.downloadsCount++;
            const doc = new jsPDF('p', 'pt', 'a4')
            const fileName = _inner.data.call ? `Rapport_${_inner.data.call.mre_call_id}` : `Rapport_` + Date.now();
            const commonOptions = {
                margin: { horizontal: 7, top: 10 },
                styles: { cellWidth: 'auto', overflow: "linebreak" },
                columnStyles: { text: { cellWidth: 'auto' }, },
                tableWidth: "auto",
                theme: "striped"
            }
            autoTable(doc, {
                head: [[{ content: 'Information de dossier', colSpan: 6, styles: { halign: 'center', fillColor: "#fff", textColor: "#000" } }], ['Date sinistre', 'Date de mission', 'Requête pour le compte', "Réf sinistre", "Garantie invoquée", "Intermédiaire"]],
                body: [[_inner.data.extraData?.dossier_date_sinistre, _inner.data.extraData?.dossier_date_mission, _inner.data.extraData?.dossier_req_compte, _inner.data.extraData?.dossier_ref_sinsitre, _inner.data.extraData?.dossier_garantie_invoke, _inner.data.extraData?.dossier_intermediaire]],
                ...commonOptions,
            })
            autoTable(doc, {
                head: [[{ content: 'Assuré', colSpan: 7, styles: { halign: 'center', fillColor: "#fff", textColor: "#000" } }], ['Nom et prénom', 'Immatriculation', 'Clé d’assurance', "N° Police", "Intermédiaire", "Adresse mail", "Numéro téléphone"]],
                body: [[_inner.data.extraData?.assure_nom_et_prenom, _inner.data.extraData?.assure_immatriculation, _inner.data.extraData?.assure_assurance_key, _inner.data.extraData?.assure_num_police, _inner.data.extraData?.assure_intermediaire, _inner.data.extraData?.assure_email, _inner.data.extraData?.assure_phone]],
                ...commonOptions
            })
            autoTable(doc, {
                head: [[{ content: 'Adversaire', colSpan: 5, styles: { halign: 'center', fillColor: "#fff", textColor: "#000" } }], ["Nom et prénom", "Immatriculation", "Clé d’assurance", "N° Police", "Intermédiaire"]],
                body: [[_inner.data.extraData?.adversaire_nom_et_prenom, _inner.data.extraData?.adversaire_immatriculation, _inner.data.extraData?.adversaire_assurance_key, _inner.data.extraData?.adversaire_num_police, _inner.data.extraData?.adversaire_intermediaire]],
                ...commonOptions
            })
            autoTable(doc, {
                head: [[{ content: 'Réparateur', colSpan: 5, styles: { halign: 'center', fillColor: "#fff", textColor: "#000" } }], ["Identification du garage réparateur", "Raison sociale", "Clé d’assurance", "Tel", "Adresse"]],
                body: [[_inner.data.extraData?.rep_id_garage, _inner.data.extraData?.rep_social_reason, _inner.data.extraData?.rep_assurance_key, _inner.data.extraData?.rep_phone, _inner.data.extraData?.rep_address]],
                ...commonOptions
            })
            autoTable(doc, {
                head: [[{ content: 'Caractéristique Technique du Véhicule Expertisé', colSpan: 10, styles: { halign: 'center', fillColor: "#fff", textColor: "#000" } }], ['Marque', 'Modèle', 'Type Mine', "Puissance", "D.M.C", "Etat général", "Immatriculation", "N° Série", "Energie", "Kilométrage"]],
                body: [[_inner.data.extraData?.veh_brand, _inner.data.extraData?.veh_modele, _inner.data.extraData?.veh_typemine, _inner.data.extraData?.veh_puissance, _inner.data.extraData?.veh_dmc, _inner.data.extraData?.veh_etatgeneral, _inner.data.extraData?.veh_immatriculation, _inner.data.extraData?.veh_nserie, _inner.data.extraData?.veh_energie, _inner.data.extraData?.veh_kilometrage]],
                ...commonOptions
            })
            const damages = [];
            for (let i = 0; i < _inner.data.extraDamages.length; i++) {
                const damage = _inner.data.extraDamages[i];
                damages.push([damage?.naturePiece, damage?.operation, damage?.qte, damage?.description, damage?.prix, damage?.vetuste, damage?.remise, damage?.prixTtc])
            }
            autoTable(doc, {
                head: [[{ content: 'Dommages', colSpan: 8, styles: { halign: 'center', fillColor: "#fff", textColor: "#000" } }], ["Nature Piece", "Operation", "Qte", "Description", "Prix", "Vetuste", "Remise", "Prix TTC"]],
                body: [...damages, ["", "", "", "", "", "", "Total", service.getTotalDamages()]],
                ...commonOptions
            })
            if (_inner.data.attachements.length > 0 && (_inner.data.attachements.some(a => a.selected) || options?.includeAttachments)) {
                doc.addPage();
                autoTable(doc, { head: [[{ content: 'Pièce jointe', colSpan: 5, styles: { halign: 'center', fillColor: "#fff", textColor: "#000" } }]], ...commonOptions })
                let x = 40;
                let y = 40;
                let gap = 10;
                let imageWidth = 200;
                let imageHeight = 200;
                let counter = 0;
                for (let i = 0; i < _inner.data.attachements.length; i++) {
                    const attachement = _inner.data.attachements[i];
                    if ((attachement.selected && attachement.url) || options?.includeAttachments) {
                        doc.addImage(attachement.url, 'jpeg', x, y, imageWidth, imageHeight);
                        x += imageWidth + gap
                        counter++;
                        if (counter % 2 === 0) {
                            y += imageHeight + gap;
                            x = 40;
                        }
                        if (counter % 6 === 0) {
                            doc.addPage();
                            y = 40;
                            x = 40;
                        }
                    }
                }
            }
            const downloadCallback = ()=>{
                doc.save(`${fileName}.pdf`)
            }
            if (_inner.data.signature.blob_url) {
                const img = new Image();
                img.onload = ()=>{
                    autoTable(doc, { head: [[{ content: 'Signature', colSpan: 5, styles: { halign: 'center', fillColor: "#fff", textColor: "#000" } }]], ...commonOptions })
                    doc.addImage(img, "png", (doc.internal.pageSize.getWidth() / 2) - 125, doc.autoTable.previous.finalY+7, 250, 250);
                    setTimeout(downloadCallback)
                }
                img.onerror = ()=>{
                    setTimeout(downloadCallback)
                }
                img.src = _inner.data.signature.blob_url;
            }else{
                downloadCallback();
            }
        } catch (err) {
            console.log(err)
        }
    }
    service.clear = () => {
        for(let key in _inner.data.extraData){
            _inner.data.extraData[key] = ""
        }
        _inner.data.attachements = [];
        _inner.data.extraDamages = [];
        _inner.data.signature.safe_url = null;
        _inner.data.signature.blob_url = null;
        _inner.data.totalPrice = 0;
        _inner.downloadsCount = 0;
        _inner.data.tmpData.savedPrice = null;
        _inner.data.call = null;
        service.notifyUI();
    }
    service.getDownloadsCount = () => _inner.downloadsCount;
    service.getDefaultGuestName = () => _inner.defaultGuestName;
    _inner.isRequesting = false;
    service.isRequesting = ()=> _inner.isRequesting;
    service.getUIBind = ()=>{
        if(!_inner.ui){
            _inner.ui = {
                extraData:_inner.data.extraData,
                extraDamages:_inner.data.extraDamages,
                totalPrice:service.getTotalDamages(),
                isRequesting:_inner.isRequesting,
                signature:service.getSignature(),
                tmpData:_inner.data.tmpData,
                isOpen:_inner.isOpen,
                openMissionSignatureModal:service.openMissionSignatureModal
            }
        }
        return _inner.ui;
    }
    service.notifyUI = ()=>{
        service.getUIBind();
        if(!serviceProviderI.hasMissionForm())
            return;
        _inner.ui.extraData = _inner.data.extraData;
        _inner.ui.extraDamages = _inner.data.extraDamages;
        _inner.ui.totalPrice = service.getTotalDamages();
        _inner.ui.isRequesting = _inner.isRequesting
        _inner.ui.signature = service.getSignature();
        _inner.ui.tmpData = _inner.data.tmpData;
        _inner.ui.isOpen = _inner.isOpen;
        _inner.ui.isMissionDetailsModalOpen = _inner.isMissionDetailsModalOpen;
    }
    service.saveDataAsPdfIfNeeded = ()=>{
        if(!serviceProviderI.hasMissionForm())
            return;
        if(_inner.downloadsCount <= 0){
            service.saveDataAsPdf();
        }
        service.clear();
    }
    service.onStoreIntoCall = ()=>{
        return new Promise((resolve,reject)=>{
            if(typeof callI.getActiveCall() !== "object" || !callI.isActiveCallByHost()) return reject();
            service.getUIBind();
            callI.onEditCall({
                MreCallID: callI.getActiveCallId(),
                ExtraData:service.generateMissionPayload()
            }).then((res)=>{
                callI.updateActiveCall(res.MreCall);
            },(error)=>{
                toastI.error(error && typeof error==='string'? error: langI.get('ERROR_UNK'));
            })
            .finally(()=>{
                service.sync();
                resolve();
            })
        })
    }
    service.generateMissionPayload = ()=>{
        service.getUIBind();
        try{
            return JSON.stringify({
                mission:{
                    ..._inner.ui.extraData,
                    damages:_inner.ui.extraDamages,
                    price:_inner.data.tmpData.savedPrice
                }
            })
        }catch{}
        return JSON.stringify({})
    }
    service.sync = (customCall)=>{
        const call = callI.getActiveCall() || customCall;
        if(!call || typeof call !== "object") return
        _inner.data.call = call;
        service.getUIBind();
        if(call.extra_data){
            try{
                const extra_data = typeof call.extra_data === "string" ? JSON.parse(call.extra_data) : call.extra_data;
                if(typeof extra_data !== "object") return
                const mission = extra_data.mission;
                if(typeof mission === "object"){
                    const damages = mission.damages;
                    if(Array.isArray(damages)){
                        _inner.data.extraDamages = damages;
                    }
                    if(mission.price){
                        _inner.data.tmpData.savedPrice = mission.price
                    }
                    _inner.data.extraData = {
                        ..._inner.data.extraData,
                        ...mission
                    }
                }
            }catch{}
            service.notifyUI();
        }
    }
    service.addDamage = (obj)=>{
        _inner.data.extraDamages.push({
            id:Date.now(),
            ...obj
        })
        service.notifyUI();
    }
    service.removeDamage = (id)=>{
        _inner.data.extraDamages = _inner.data.extraDamages.filter(obj=>obj.id !== id);
        service.notifyUI();
    }
    service.getSignatureMessageName = ()=> 'signature'
    service.getExtension = ()=> _inner.extension;
    service.getFormat = ()=> _inner.format;
    service.getFileName = ()=> service.getSignatureMessageName()+'.'+service.getExtension();
    service.onSendSignature = (blob)=>{
        return new Promise((resolve,reject)=>{
            if(!(blob instanceof Blob) || blob.size <= 0) return reject();
            if(!callI.isActiveCallByHost()){
                service.storeSignature(window.URL.createObjectURL(blob))
            }
            try {
                // toastI.info('Sending ...');
                const file = new File([blob], service.getFileName() , { type: service.getFormat() });
                chatI.onSendMreCallChat({MreCallID: callI.getActiveCallId(), MreCallParticipantID: callI.getActiveParticipantId(), message: service.getSignatureMessageName(), fileName: service.getFileName(), file: file, speech_to_text: null})
                .then(()=>{
                //   toastI.success('Sent');
                }, (msg)=>{
                  toastI.error(msg);
                }).finally(()=>{
                    resolve();
                })
              } catch (error) {
                reject();
              }
        })
    }
    service.isSignatureMessage = (message)=>{
        if(message && message.message_text === smartExpertiseI.getSignatureMessageName()) return true;
        return false;
    }
    service.clearTmpData = ()=>{
        _inner.data.tmpData.totalPrice = service.getTotalDamages();
        _inner.data.tmpData.enableEditPrice = false;
        service.notifyUI();
    }
    service.clearSignature = ()=>{
        _inner.data.signature.blob_url = null;
        _inner.data.signature.safe_url = null;
        service.notifyUI();
    }
    service.enableEditPrice = ()=>{
        _inner.data.tmpData.enableEditPrice = true;
        service.notifyUI();
    }
    service.disableEditPrice = ()=>{
        _inner.data.tmpData.enableEditPrice = false;
        service.notifyUI();
    }
    service.savePrice = ()=>{
        _inner.data.tmpData.savedPrice = _inner.data.tmpData.totalPrice;
        service.notifyUI();
    }
    service.toggleEdit = ()=>{
        if(_inner.data.tmpData.enableEditPrice){
            service.disableEditPrice();
            service.savePrice();
        }
        else service.enableEditPrice();
    }
    service.isEditEnabled = ()=>{
        return _inner.data.tmpData.enableEditPrice;
    }
    if(DEBUG){
        service.getInner = ()=> _inner;
        window.smartExpertiseI = service;
    }
    service.openMissionSignatureModal = ()=>{
        _inner.isOpen = true;
        service.notifyUI();
        setTimeout(()=>{
            modalI.init();
            setTimeout(()=>{
                modalI.openModal({
                    id:modalI.ID.MISSION_SIGNATURE_MODAL,
                    onClose:()=>{
                        _inner.isOpen = false;
                        service.notifyUI();
                        service.setIsRequesting(false);
                        service.clearTmpData();
                    }
                })
            },300)
        }, 150)
    }
    service.openMissionDetailsModal = ()=>{
        _inner.isMissionDetailsModalOpen = true;
        service.notifyUI();
        setTimeout(()=>{
            modalI.init();
            setTimeout(()=>{
                modalI.openModal({
                    id: modalI.ID.MISSION_DETAILS_MODAL,
                    onClose:()=>{
                        _inner.isMissionDetailsModalOpen = false;
                        service.notifyUI();
                    }
                })
            },300)
        })
    }
    return service;
}
export const smartExpertiseI = SmartExpertiseI();