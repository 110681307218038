import {environment} from 'src/environments/environment';
import { endpointI } from './endpointI';
import { recorderI } from './recorderI';
import { transcriptionI } from './transcriptionI';

let ScreenI = function () {
    let service = {};
    let _inner = {};
    _inner.parentElementBeforeFullscreen = null;
    _inner.tmpFullscreenContainerId = '';
    service.widgetScriptId = 'tmu-widget-script';
    _inner.isDesktop = false;
    service.setIsDesktop = (val)=>{
        _inner.isDesktop = +val===1? true: false;
    }
    service.isDesktop = ()=>{
        return +_inner.isDesktop===1? true: false;
    }

    service.isFullScreenAvailable = ()=>{
        return service.getFullScreenExitVerb() || _inner.tmpFullscreenContainerId? true: false;
    }

    service.isSmallScreen = ()=>{
        return window.innerWidth < 650? true: false;
    }

    service.getFullScreenRequestVerb = (element)=>{
        if(!element)
            return;
        let verb = typeof element.requestFullscreen==='function'? 'requestFullscreen':
                    typeof element.webkitRequestFullScreen==='function'? 'webkitRequestFullScreen':
                    typeof element.webkitRequestFullscreen==='function'? 'webkitRequestFullscreen':
                    typeof element.webkitEnterFullScreen==='function'? 'webkitEnterFullScreen':
                    typeof element.msRequestFullscreen==='function'? 'msRequestFullscreen':
                    typeof element.mozRequestFullScreen==='function'? 'mozRequestFullScreen': null;
        return verb;
    }
    service.getFullScreenExitVerb = ()=>{
        let verb = typeof document.exitFullscreen==='function'? 'exitFullscreen':
                typeof document.webkitExitFullscreen==='function'? 'webkitExitFullscreen':
                typeof document.mozCancelFullScreen==='function'? 'mozCancelFullScreen':
                typeof document.webkitExitFullScreen==='function'? 'webkitExitFullScreen':
                typeof document.msExitFullscreen==='function'? 'msExitFullscreen':
                typeof document.mozExitFullscreen==='function'? 'mozExitFullscreen': null;
        return verb;
    }

    service.requestFullscreen = (element) => {
        return new Promise((resolve, reject)=>{
            _inner.parentElementBeforeFullscreen = null;
            element = element? element: document.body;
            let verb = service.getFullScreenRequestVerb(element);

            if(!verb && _inner.tmpFullscreenContainerId) {
                _inner.parentElementBeforeFullscreen = element.parentElement;
                let parent = document.createElement('DIV');
                parent.id = _inner.tmpFullscreenContainerId;
                parent.style.position = 'fixed';
                parent.style.left = 0;
                parent.style.right = 0;
                parent.style.bottom = 0;
                parent.style.top = 0;
                parent.style.height = '100%';
                parent.style.width = '100%';
                parent.style.background = 'black';
                parent.style.display = 'grid';
                parent.style.textAlign = 'center';
                parent.style.alignItems = 'center';
                element.style.margin = 'auto';
                parent.append(element);
                document.body.appendChild(parent);
            }
            if(!verb || !element[verb]) {
                reject();
                return;
            }
            let fct = element[verb]();
            if(fct && fct.then) {
                fct.then(()=>{
                    resolve();
                }, (err)=>{
                    reject();
                })
            } else {
                resolve();
            }
        })
    }

    service.exitFullscreen = function() {
        transcriptionI.clearFullScreenElement();
        let verb = service.getFullScreenExitVerb();
        if(!verb) {
            let parent = service.getFullScreenElement();
            if(parent && _inner.parentElementBeforeFullscreen && parent.children.length>0) {
                _inner.parentElementBeforeFullscreen.appendChild(parent.children[0]);
                parent.remove();
                _inner.parentElementBeforeFullscreen = null;
            }
            return;
        }
        document[verb]();
    }
    service.isOnFullScreen = function() {
        let el = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullscreenElement? true: false;
        if(el)
            return true;
        if(_inner.tmpFullscreenContainerId && document.getElementById(_inner.tmpFullscreenContainerId))
            return true;
        return false;
    }
    service.getFullScreenElement = function() {
        let el = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullscreenElement;
        if(el)
            return el;
        if(_inner.tmpFullscreenContainerId)
            return document.getElementById(_inner.tmpFullscreenContainerId);
        return null;
    }
    service.loadScript = function(path, attrs=null, parent=null) {
        return new Promise(function(resolve, reject) {
            if(!path && !attrs) {
                reject();
                return;
            }
            let script = document.createElement('script');
            script.type = 'text/javascript';
            if(attrs && typeof attrs === 'object') {
                for(let k in attrs) {
                    if(k && attrs[k] && typeof attrs[k]==='string')
                        script.setAttribute(k, attrs[k]);
                }
            }
            if(path && typeof path==='string')
                script.src = path;
            script.onload = function() {
                resolve(script);
            }
            script.onerror = function() {
                reject();
            }
            parent = parent && typeof parent==='string'
                        && document[parent] && typeof document[parent].appendChild==='function'? parent: 'body';
            document[parent].appendChild(script);
        });
    }
    service.addGoogleAnalytics = function() {
        let measurementId = environment.TMU_GTAG_MEASUREMENT_ID || '';
        if(!measurementId)
            return;
        service.loadScript('https://www.googletagmanager.com/gtag/js?id='+measurementId, {'cookie-consent': 'tracking'}, 'head')
        .then(()=>{
            window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', measurementId);
        }, ()=>{});
    }
    service.addCookieConsent = function() {
        service.loadScript('./assets/termsfeed/3.0.0/cookie-consent.js', null, null)
        .then(()=>{
            let attempts = 4;
            let runCookies = function() {
            setTimeout(()=>{
                if(recorderI.isRecorderService()) return
                let cookiesSelectorId = '#changePreferences';
                if(attempts>0 && !document.querySelector(cookiesSelectorId)) {
                attempts--;
                runCookies();
                return;
                }
                window['cookieconsent'].run({"notice_banner_type":"simple","consent_type":"express","palette":"light","change_preferences_selector":cookiesSelectorId,"language":"en","website_name":"www.telemeetup.com","cookies_policy_url":"https://www.telemeetup.com/privacy.php"})
            }, 500);
            }
            runCookies();
        }, ()=>{});
    }
    service.getWidget = function() {
        let container = document.querySelector('.tmu-iframe-bar');
        if(!container)
            container = document.querySelector('.tmu-iframe-avatar');
        if(container)
            container = container.parentElement;
        return container;
    }
    service.addWidgetScript = ()=>{
        if(endpointI.isOnDev())
          return;
        let container = document.getElementById(service.widgetScriptId);
        if(container)
          return;
        let url = endpointI.getWidgetScriptUrl();
        if(!url)
          return;
        container = document.createElement('script');
        container.id = service.widgetScriptId;
        container['src'] = url;
        document.body.appendChild(container);
    }
    service.hideWidget = () => {
        setTimeout(()=>{
            let container = service.getWidget();
            if(container)
            container['style'].display = 'none';
        });
    }
    service.showWidget = () => {
        setTimeout(()=>{
            let container = service.getWidget();
            if(container)
            container['style'].display = '';
        });
    }
    service.removeWidget = () => {
        let container = document.getElementById(service.widgetScriptId);
        if(container && container.parentElement)
            container.parentElement.removeChild(container);
        let widget = service.getWidget();
        if(widget && widget.parentElement)
            widget.parentElement.removeChild(widget);
    }
    return service;
}

export const screenI = ScreenI();