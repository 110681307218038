import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { navbarI } from "src/assets/js/navbarI";
import { serviceProviderI } from "src/assets/js/serviceProviderI";
@Component({
  selector: "app-loading",
  templateUrl: "./loading.page.html",
  styleUrls: ["./loading.page.scss"],
})
export class LoadingPage implements OnInit{
  spUI: any = serviceProviderI.getUIBind();
  constructor(router:Router) {
    if(!router.navigated) return
    navbarI.goToLastActivePage();
  }
  ngOnInit() {}
}
