<div class="modal-wrapper" [attr.data-modalid]="containerId">
    <div class="modal-backdrop"></div>
    <div class="modal-container md-fit">
        <div class="modal-header">
            <span class="heading">Enjoying the app?</span>
            <button class="transparent close-btn">
                <span class="material-icons">close</span>
            </button>
        </div>
        <div class="modal-body md-padding-bottom">
            <div class="flex-col p-4">
                <p class="title mb-3">Please let us know how we're doing.</p>
                <div class="mb-2">
                    <div class="rating mb-4 mt-2">
                        <i class='bx' (click)="rating = 1" [ngClass]="rating < 1 ? 'bx-star' : 'bxs-star'"></i>
                        <i class='bx' (click)="rating = 2" [ngClass]="rating < 2 ? 'bx-star' : 'bxs-star'"></i>
                        <i class='bx' (click)="rating = 3" [ngClass]="rating < 3 ? 'bx-star' : 'bxs-star'"></i>
                        <i class='bx' (click)="rating = 4" [ngClass]="rating < 4 ? 'bx-star' : 'bxs-star'"></i>
                        <i class='bx' (click)="rating = 5" [ngClass]="rating < 5 ? 'bx-star' : 'bxs-star'"></i>
                    </div>
                    <div class="input-wrapper description">
                        <textarea name="" id="" rows="3" placeholder="Leave us some feedback" [(ngModel)]="feedback"></textarea>
                    </div>
                    <div class="form-row my-2" *ngIf="isGuest">
                        <div class="align-row">
                            <input type="checkbox" [(ngModel)]="schedule_demo" id="scheduledemo" class="mx-2">
                            <label for="scheduledemo">Schedule a demo</label>
                        </div>
                    </div>
                    <div class="flex-col"  *ngIf="schedule_demo">
                        <div class="input-wrapper m-1 mt-2">
                            <input type="email" [(ngModel)]="email_address" placeholder="Email Address" autocomplete="off">
                        </div>
                        <div class="input-wrapper m-1 mt-2">
                            <input type="text" [(ngModel)]="guest_name" placeholder="Name" autocomplete="off">
                        </div>
                    </div>
                </div>
                <div class="flex-row gap-10">
                    <button class="primary large" (click)="submit()">
                        <span class="btn-loader" *ngIf="isSending"><i class='bx bx-loader-alt bx-spin'></i></span>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>