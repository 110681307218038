const DebugI = function(){
    const DEBUG = ['localhost','127.0.0.1','192.168'].some(value=>window.origin.indexOf(value) > -1);
    const service = {};
    service.canDebug = ()=> DEBUG;
    service.debug = function(method,...rest){
        if(typeof method !== "string") return
        const func = console[method.toLowerCase()];
        if(typeof func !== "function") return
        service.canDebug() && func.apply(console, rest);
    }
    service.log = function(){
        service.debug('log',...arguments)
    }
    service.info = function(){
        service.debug('info',...arguments)
    }
    service.error = function(){
        service.debug('error',...arguments)
    }
    service.warn = function(){
        service.debug('warn',...arguments)
    }
    if(DEBUG){
        window.debugI = service;
    }
    return service;
}
export const debugI = DebugI();