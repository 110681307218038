import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import tippy from "tippy.js";
import { callI } from 'src/assets/js/callI';
import { navbarI } from 'src/assets/js/navbarI';
import { themeI } from "../../assets/js/themeI";
import { userI } from "src/assets/js/userI";
import { busI } from "src/assets/js/busI";
import { chatI } from "src/assets/js/chatI";
import { BroadCastService } from "../services/broadcast-service/broadcast-service.service";
import { streamI } from "src/assets/js/streamI";
import { serviceProviderI } from "src/assets/js/serviceProviderI";
import { triggerOnClickOutSide } from "src/assets/js/emoji";
import { modalI } from "../../assets/js/modals";
import { livekitI } from "src/assets/js/livekitI";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"]
})
export class SideMenuComponent implements OnInit {

  themeUi: any = themeI;
  account: any = {};
  isGuest: boolean = true;
  isOnCall : boolean = false;
  activeRoute: string = '';
  friendRequestsCounter: number = 0;
  ui: any = {};
  spUI: any = {};
  chatUI: any = {};
  callUI: any = {};
  sp: any = {};
  navUI: any = navbarI.getUIBind();
  constructor(
    private cd: ChangeDetectorRef,
    private broadCastService:BroadCastService
  ) {
    if(!navbarI.getActiveRoute())
      navbarI.setActiveRoute('calls');
    this.activeRoute = navbarI.getActiveRoute();
    this.ui = userI.getUIBind();
    this.chatUI = chatI.getUIBind();
    this.callUI = callI.getUIBind();
    this.spUI = serviceProviderI.getUIBind();
    this.sp = serviceProviderI.getServiceProvider();
    busI.unregisterEvent(busI.EVENTS.ON_ACTIVE_ROUTE_CHANGED, this.onRouteChange);
    busI.registerEvent(busI.EVENTS.ON_ACTIVE_ROUTE_CHANGED, this.onRouteChange);
  }

  onRouteChange = (triggerClickOutSide=true) => {
    if(navbarI.isLoading() || !navbarI.getActiveRoute()) return
    clearTimeout(this['onRouteChangeTimer']);
    this['onRouteChangeTimer'] = setTimeout(()=>{
      this.activeRoute = navbarI.getActiveRoute()?.replace(navbarI.getDashboardRouteName(),'')?.trim()
      this.isOnCall = callI.getActiveCall() ? true : false;
      if(triggerClickOutSide){
        triggerOnClickOutSide();
      }
    }, 0);
  }

  onResize() {
    clearTimeout(this['onResizeTimer']);
    this['onResizeTimer'] = setTimeout(()=>{
      let topContainer = document.querySelector('#nav-top-container');
      let contentContainer = document.querySelector('#nav-content-container');
      let bottomContainer = document.querySelector('#nav-bottom-container');
      if(!contentContainer || !topContainer || !contentContainer || !bottomContainer)
        return;
      let calculatedheight = window.innerHeight - bottomContainer.clientHeight - topContainer.clientHeight - 40;
      contentContainer['style'].maxHeight = `${calculatedheight}px`;
    }, 150);
  }

  ngOnInit() {
    window.removeEventListener('resize', this.onResize);
    window.addEventListener('resize', this.onResize);
    this.onProfileUpdated();
    busI.registerEvent(busI.EVENTS.ON_ACCOUNT_PROFILE_UPDATED, this.onProfileUpdated);
    busI.registerEvent(busI.EVENTS.ON_FRIEND_REQUESTS_UPDATED, this.onFriendRequestsUpdated);
    this.onRouteChange(false)
  }

  ngAfterViewInit(){
    this.cd.detectChanges();
  }

  onFriendRequestsUpdated = ()=>{
    clearTimeout(this['onFriendRequestsUpdatedTimer']);
    this['onFriendRequestsUpdatedTimer'] = setTimeout(()=>{
      this.friendRequestsCounter = userI.getFriendRequests().length;
    }, 150);
  }

  onProfileUpdated = () =>{
    clearTimeout(this['onProfileUpdatedTimer']);
    this['onProfileUpdatedTimer'] = setTimeout(()=>{
      this.account = userI.getAccountProfile();
      this.isGuest = userI.isGuest();
      tippy('[data-tippy-content]', {
        delay: 300,
      });
    }, 150);
  }

  onLeaveCallDone() {
    busI.unregisterEvent(busI.EVENTS.ON_LEFT_CALL_DONE, this.onLeaveCallDone);
    userI.logout();
  }

  onCheckExitActiveCall() {
    return new Promise((resolve, reject) => {
      if(livekitI.isRoomActive() || callI.isOncall()) {
        modalI.onDisplayExitCallModal()
        .then((res)=>{
          if(res && typeof res.isStop==='boolean')
            resolve({wasOnCall: true});
          else
            reject();
        }, ()=>{
          reject();
        });
      } else {
        resolve({wasOnCall: false});
      }
    })
  }

  public async logout() {
    busI.unregisterEvent(busI.EVENTS.ON_LEFT_CALL_DONE, this.onLeaveCallDone);
    busI.registerEvent(busI.EVENTS.ON_LEFT_CALL_DONE, this.onLeaveCallDone);
    this.onCheckExitActiveCall()
    .then(()=>{
      window.removeEventListener('resize', this.onResize);
      busI.unregisterEvent(busI.EVENTS.ON_ACCOUNT_PROFILE_UPDATED, this.onProfileUpdated);
      if(livekitI.isRoomActive() || callI.isOncall()) {
        this.broadCastService.postMessage(this.broadCastService.EVENTS.LEAVE_CALL, 'logout');
      } else {
        this.onLeaveCallDone();
      }
    }, ()=>{
      busI.unregisterEvent(busI.EVENTS.ON_LEFT_CALL_DONE, this.onLeaveCallDone);
    });
  }

  routeTo(pageName) {
    if (pageName == "calls" && callI.getActiveCallId()) {
      if (navbarI.getActivePage() == "call") {
        navbarI.setActiveRoute(pageName);
        this.activeRoute = navbarI.getActiveRoute();
      } else {
        navbarI.setActiveRoute(pageName);
        this.activeRoute = navbarI.getActiveRoute();
        pageName = "call";
        navbarI.navForward(pageName);
      }
    } else {
      navbarI.setActiveRoute(pageName);
      this.activeRoute = navbarI.getActiveRoute();
      navbarI.navForward(pageName);
    }
    navbarI.closeSideMenu();
    if(pageName == "contacts")
      this.friendRequestsCounter = 0;
  }

  profileRoute() {
    if(!userI.getAccountProfileId())
      return;
    this.routeTo(userI.isGuest()? "upgrade-profile": "profile");
  }

  home() {
    callI.setNavToDefault();
    this.onCheckExitActiveCall()
    .then((res)=>{
      setTimeout(()=>{
        navbarI.navForward(navbarI.getLandingPage());
      }, res && res['wasOnCall']? 2000: 0);
    }, ()=>{});
  }

  gotoTutorial() {
    window.open('https://www.telemeetup.com/video-tutorials.html');
  }

  gotoYoutube() {
    window.open('https://www.youtube.com/channel/UCRKY7yB2i9tnfFuvrrIrq6w');
  }

  gotoFacebook() {
    window.open('https://www.facebook.com/TMUCall');
  }

  gotoTwitter() {
    window.open('https://twitter.com/TMUCall');
  }

  gotoLinkedin() {
    window.open('https://www.linkedin.com/company/telemeetup');
  }
}
