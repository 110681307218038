import { utilI } from "./utilI";
import { roomI } from "./roomI";
import { userI } from "./userI";
import { widgetI } from "./widgetI";
import { callI } from "./callI";
import { streamI } from "./streamI";
import { busI } from "./busI";
import { navbarI } from "./navbarI";

var ModalUI = function () {
    let isDEBUG = false;

    let _inner = {
        modals: null ,
        modalsBag: {}
    }
    //let modals;
    let modalsContainer;
    let modalsui = {};

    modalsui.ID = {
        EXISTING_CALL_MODAL: 'existing-call-modal',
        RECOVER_ACCOUNT_MODAL: 'recover-account-modal',
        RATING_CALL_MODAL: 'rating-call-modal',
        EXIT_CALL_MODAL: 'exit-call-modal',
        CALL_SETTINGS_MODAL: 'call-settings-modal',
        CALL_INVITE_MODAL: 'call-invite-modal',
        EMAIL_VERIFICATION_MODAL: 'email-verification-modal',
        CONSENT_RECORDING_MODAL: 'consent-recording-modal',
        PRE_CALL_LOGIN_MODAL: 'precall-login',
        CHANGE_LANGUAGE_MODAL: 'change-language',
        MISSION_DETAILS_MODAL: 'mission-details',
        MISSION_SIGNATURE_MODAL: 'mission-signature'
    }

    modalsui.ON_OPEN = 'onOpen';
    modalsui.ON_CLOSE = 'onClose';
    modalsui.ON_SUBMIT = 'onSubmit';

    modalsui.init = () => {
        modalsContainer = document.querySelector('.modals-container');
        let existingModals = modalsContainer.childNodes;
        _inner.modals= document.querySelectorAll(':not(.modals-container) > .modal-wrapper');
        _inner.modals.forEach(modal => {
            let exists = false;
            if(existingModals) {
                existingModals.forEach(exModal => {
                    if(exModal.dataset.modalid == modal.dataset.modalid) {
                        exists = true;
                        exModal.remove();
                        if (isDEBUG) { console.log('%cInfo: %cPrevented to bind modal that already exists', 'color:red;font-weight:bold;', 'color:black;font-wight:regular;'); }
                    }
                })
            }
            modalsContainer.appendChild(modal);
            let modalCloseBtn = modal.querySelector('.close-btn');
            let modalBackdrop = modal.querySelector('.modal-backdrop');
            let modalSubmitBtn = modal.querySelector('.modal-submit');
            let closeOthersClose = (modal.dataset.closeothersclose == 'true') || false; // close others when closed;
            let modalId = modal.dataset.modalid;

            if(modalId != undefined){
                // close a modal from the button
                if (modalCloseBtn) {
                    modalCloseBtn.addEventListener('click', (event)=>{
                        modalsui.closeModal(modalId, closeOthersClose);
                    });
                }
                // close a modal when cliking elsewhere
                if (modalBackdrop) {
                    modalBackdrop.addEventListener('click', (event)=>{
                        modalsui.closeModal(modalId, closeOthersClose, null, true);
                    });
                }

                // when modal-submit is clicked
                if(modalSubmitBtn){
                    modalSubmitBtn.addEventListener('click', (event)=>{
                        modalsui.submitModal(modalId); 
                    });
                    if (isDEBUG) { console.log('%cInfo: %cSubmit event Binded', 'color:red;font-weight:bold;', 'color:black;font-wight:regular;'); }
                }

            } else {
                if (isDEBUG) { console.log('%cError: %cThe Modal require an id', 'color:red;font-weight:bold;', 'color:black;font-wight:regular;'); }
            }
        });
    }

    modalsui.openModal = (infos) =>{
        if(!infos) return;
        if(!infos.closeOthers) infos.closeOthers = false;
        if(!infos.clearInputs) infos.clearInputs = false;
        if(!infos.preventCloseOutside) infos.preventCloseOutside = false;
        if(modalsContainer){ modalsContainer.classList.add('active') }
        let existingModals = modalsContainer&&modalsContainer.childNodes ? modalsContainer.childNodes: [];
        existingModals.forEach(modal=>{
            let autofocuson = modal.querySelector('.autofocuson');
            if(infos.closeOthers){
                modal.classList.remove('active');
            }
            if(modal.dataset.modalid == infos.id){
                modal.dataset.preventCloseOutside = infos.preventCloseOutside;
                if(infos.clearInputs){
                    modal.querySelectorAll('.input-wrapper input').forEach(input => {
                        input.value = '';
                    })
                }
                if(modal.parentElement !== modalsContainer)
                    modalsContainer.appendChild(modal);
                modal.classList.add('active');
                modal.onClose = infos.onClose;
                modal.onSubmit = infos.onSubmit;
                //register onOpen fct on events if available
                if(typeof infos.onOpen == 'function' && !infos.events) {
                    infos.events = {};
                    infos.events[modalI.ON_OPEN] = infos.onOpen;
                    modalsui.registerEvent(infos);
                }
                if(infos.data)
                    modalsui.registerData(infos);
                setTimeout(()=>{
                    if(autofocuson){
                        autofocuson.focus();
                    }
                });
            }
        });
        if(_inner.modalsBag && _inner.modalsBag[infos.id] && _inner.modalsBag[infos.id].events && typeof _inner.modalsBag[infos.id].events[modalI.ON_OPEN] === 'function')
            _inner.modalsBag[infos.id].events[modalI.ON_OPEN]();
    }
    modalsui.registerEvent = (infos) => {
        if (!infos.id) {
            if (isDEBUG) { console.log('%cError: %cNo id provided', 'color:red;font-weight:bold;', 'color:black;font-wight:regular;'); }
            return;
        }
        if (!infos.events) {
            if (isDEBUG) { console.log('%cError: %cNo event provided', 'color:red;font-weight:bold;', 'color:black;font-wight:regular;'); }
            return;
        }

        if(!_inner.modalsBag[infos.id]){
            _inner.modalsBag[infos.id] = {};
        }
        if(!_inner.modalsBag[infos.id].events){
            _inner.modalsBag[infos.id].events = {};
        }
        for (let k in infos.events){
            _inner.modalsBag[infos.id].events[k] = infos.events[k];
        }
    }

    modalsui.registerData = (infos) => {
        if (!infos.id) {
            if (isDEBUG) { console.log('%cError: %cNo id provided', 'color:red;font-weight:bold;', 'color:black;font-wight:regular;'); }
            return;
        }
        if (!infos.data) {
            if (isDEBUG) { console.log('%cError: %cNo data provided', 'color:red;font-weight:bold;', 'color:black;font-wight:regular;'); }
            return;
        }
        if(!_inner.modalsBag[infos.id]){
            _inner.modalsBag[infos.id] = {};
        }
        if(!_inner.modalsBag[infos.id].data){
            _inner.modalsBag[infos.id].data = {};
        }
        for (let k in infos.data){
            _inner.modalsBag[infos.id].data[k] = infos.data[k];
        }
    }

    modalsui.notifyEvent = (infos) => {
        if (!infos.id) {
            if (isDEBUG) { console.log('%cError: %cNo id provided', 'color:red;font-weight:bold;', 'color:black;font-wight:regular;'); }
            return;
        }
        if (!infos.name) {
            if (isDEBUG) { console.log('%cError: %cNo Event name provided', 'color:red;font-weight:bold;', 'color:black;font-wight:regular;'); }
            return;
        }
        if(_inner.modalsBag[infos.id] && _inner.modalsBag[infos.id].events && typeof _inner.modalsBag[infos.id].events[infos.name] === "function"){
            _inner.modalsBag[infos.id].events[infos.name]();
        }
    }

    modalsui.getModalData = (id) => {
        if (_inner.modalsBag[id]&&_inner.modalsBag[id].data){
            return _inner.modalsBag[id].data;
        }
        return;
    }

    modalsui.closeModal = (id, closeOthers = false, data = null, isFromOutside = null) =>{
        let existingModals = modalsContainer ? modalsContainer.childNodes: [];
        let mdl = modalsContainer? modalsContainer.querySelector(`[data-modalid='${id}']`): null;
        if(mdl && !(isFromOutside && mdl.dataset.preventCloseOutside == 'true')){
            existingModals.forEach(modal=>{
                if(modal.dataset.modalid == id){
                    modal.classList.remove('active');
                    if(_inner.modalsBag[id] && _inner.modalsBag[id].events && _inner.modalsBag[id].events[modalI.ON_CLOSE]) {
                        if (typeof _inner.modalsBag[id].events[modalI.ON_CLOSE] == 'function') {
                            _inner.modalsBag[id].events[modalI.ON_CLOSE](data)
                        }
                    }
                    if(typeof modal.onClose == 'function'){
                        modal.onClose(data);
                    }
                    if (isDEBUG) { console.log('%cInfo: %cClosed Modal:'+id, 'color:blue;font-weight:bold;', 'color:black;font-wight:regular;'); }
                }
                if(closeOthers == true){
                    modal.classList.remove('active');
                }
            })
            // setTimeout(()=>{
                if(!modalsContainer) return
                const isInitialModal = modalsContainer.children[0] && modalsContainer.children[0].dataset.modalid === id ? true : false;
                if(!isInitialModal) return;
                modalsContainer.classList.remove('active');
            // },200)
        }
    }
    modalsui.removeModal = (id) => {
        let mdl = modalsContainer? modalsContainer.querySelector(`[data-modalid='${id}']`): null;
        if(mdl && typeof mdl.remove === 'function')
            mdl.remove();
    }

    // Get Triggered when modal-submit is clicked
    modalsui.submitModal = (id) => {

        let existingModals = modalsContainer ? modalsContainer.childNodes: [];
        existingModals.forEach(modal=>{
            if(modal.dataset.id == id || modal.dataset.modalid == id) {
                if(typeof modal.onSubmit == 'function') {
                    modal.onSubmit()
                    if (isDEBUG) { console.log('%cInfo: %cModal Submited', 'color:blue;font-weight:bold;', 'color:black;font-wight:regular;'); }
                } else {
                    if (isDEBUG) { console.log('%cError: %cNot a valid on Submit Event', 'color:red;font-weight:bold;', 'color:black;font-wight:regular;'); }
                }
            }
        })
    }

    modalsui.invalidate = (id) => {
        let existingModals = modalsContainer ? modalsContainer.childNodes: [];
        existingModals.forEach(modal => {
            if(modal.dataset.id == id || modal.dataset.modalid == id) {
                let wrapper = modal.querySelector('.modal-container');
                wrapper.classList.add('shake');
                setTimeout(()=>{
                    wrapper.classList.remove('shake');
                }, 1000);
            }
        })
    }

    modalsui.DEBUG = () => {
        if(isDEBUG)
            console.log('modalI', _inner);
    }

    modalsui.closeAll = ()=>{
        if(modalsContainer)
            Array.from(modalsContainer.querySelectorAll('[data-modalid]'))
            .forEach(element=>{
                if(element && element.classList.contains('active')) {
                    const id = element.getAttribute('data-modalid');
                    modalsui.closeModal(id);
                }
            })
    }
    modalsui.displayRatingModal = () => {
        if(roomI.isOnActiveRoom() || callI.getIsInternal()) return
        const modalStorageKey = "TMU.WebApp.RatingModal";
        const showAfterDaysCount = userI.isGuest()? 3: 15;
        const showAfterCallsCount = userI.isGuest()? 5: 20;
        const elapsedDays = (timestamp)=>{
            return typeof timestamp === "number" ?  Math.floor((new Date().getTime() - timestamp)/(1000 * 60 * 60 * 24)) : -1;
        }
        const resetModalStorage = (lastCallsCount=1)=>{
            const data = { lastCallsCount:lastCallsCount,lastShowTimestamp:new Date().getTime() };
            utilI.updateLocalStorage(modalStorageKey,JSON.stringify(data));
        }
        const removeModalStorage = ()=>{
            utilI.removeFromLocalStorage(modalStorageKey);
        }
        const item = utilI.getFromLocalStorage(modalStorageKey);
        if(item){
            try{
                const data = JSON.parse(item);
                data.lastCallsCount = typeof data.lastCallsCount === "number" ? data.lastCallsCount+1 : -1;
                const diff = data.lastShowTimestamp ? elapsedDays(data.lastShowTimestamp) : -1;
                if(diff === -1 || data.lastCallsCount === -1){ removeModalStorage(); return}
                resetModalStorage(data.lastCallsCount);
                if(diff < showAfterDaysCount && data.lastCallsCount <= showAfterCallsCount) return
                resetModalStorage();
            } catch(err){
                removeModalStorage();
            }
        }else{
            resetModalStorage();
        }
        modalsui.getUIBind();
        _inner.ui.isRatingModalOpen = true;
        modalI.closeAll();
        setTimeout(()=>{
            modalI.init();
            modalI.openModal({
                id: modalI.ID.RATING_CALL_MODAL,
                onClose: (response)=>{
                    if(_inner.ui)
                        _inner.ui.isRatingModalOpen = false;
                    setTimeout(()=>{
                        modalsui.removeModal(modalI.ID.RATING_CALL_MODAL);
                    })
                }
            })
        }, 1500);
    }
    modalsui.onDisplayExitCallModal = () => {
        return new Promise((resolve, reject) => {
            if(callI.getIsInternal()) {
                resolve(true);
                return;
            }
            modalI.closeAll();
            modalsui.getUIBind();
            _inner.ui.isExitCallModalOpen = true;
            setTimeout(()=>{
                modalI.init();
                modalI.openModal({
                    id: modalI.ID.EXIT_CALL_MODAL,
                    onClose: (data)=>{
                        setTimeout(()=>{
                            if(_inner.ui)
                                _inner.ui.isExitCallModalOpen = false;
                        }, 250);
                        setTimeout(()=>{
                            resolve(data);
                        })
                    }
                })
            });
        });
    }
    modalsui.onDisplayEmailVerificationModal = (obj) => {
        return new Promise((resolve, reject) => {
            modalI.closeAll();
            modalsui.getUIBind();
            modalI.registerData({
                id: modalI.ID.EMAIL_VERIFICATION_MODAL,
                data: {credentials: obj},
            });
            _inner.ui.isEmailVerificationModalOpen = true;
            setTimeout(()=>{
                modalI.init();
                modalI.openModal({
                    id: modalI.ID.EMAIL_VERIFICATION_MODAL,
                    onClose: (res)=>{
                        setTimeout(()=>{
                            if(_inner.ui)
                                _inner.ui.isEmailVerificationModalOpen = false;
                        }, 250);
                        setTimeout(()=>{
                            resolve(res);
                        })
                    }
                })
            });
        });
    }
    modalsui.onDisplayRecordingConfirmationModal = () => {
        return new Promise((resolve, reject) => {
            if(callI.isActiveCallByHost() || widgetI.isWidget || callI.getIsInternal()) { 
                resolve(true);
                return;
            }
            modalI.closeAll();
            modalsui.getUIBind();
            modalI.registerData({
                id: modalI.ID.CONSENT_RECORDING_MODAL
            });
            _inner.ui.isConsentRecordingModalOpen = true;
            setTimeout(()=>{
                modalI.init();
                modalI.openModal({
                    id: modalI.ID.CONSENT_RECORDING_MODAL,
                    onClose: (res)=>{
                        setTimeout(()=>{
                            if(_inner.ui)
                                _inner.ui.isConsentRecordingModalOpen = false;
                        }, 250);
                        setTimeout(()=>{
                            resolve(res && res.consent? true: false);
                        })
                    }
                })
            });
        });
    }
    modalsui.displayExistingCallModal = (mreCall) => {
        if(roomI.isOnActiveRoom() || !mreCall)
          return;
        modalsui.closeAll();
        modalsui.getUIBind();
        _inner.ui.isExistingCallModalOpen = true;
        setTimeout(()=>{
            modalsui.init();
            modalsui.openModal({
              id: modalsui.ID.EXISTING_CALL_MODAL,
              onClose: (data)=>{
                setTimeout(()=>{
                    if(_inner.ui)
                        _inner.ui.isExistingCallModalOpen = false;
                }, 250);
                if(data && data.rejoin && mreCall) {
                  callI.clearOnCall();
                  callI.setActive({call: mreCall});
                  busI.notifyEvent(busI.EVENTS.ON_LOGIN);
                  callI.setNavToDefault();
                  navbarI.navForward('call');
                }
              }
            })
        })
      }
    _inner.notifyUI=()=>{
        if(!_inner.ui)
            modalsui.getUIBind();
    }
    modalsui.getUIBind=()=>{
        if(_inner.ui) {
            _inner.notifyUI();
            return _inner.ui;
        }
        _inner.ui = {
            isRatingModalOpen: false,
            isExitCallModalOpen: false,
            isConsentRecordingModalOpen: false,
            isExistingCallModalOpen: false
        }
        return _inner.ui;
    }
    return modalsui;
}

export const modalI = ModalUI();
