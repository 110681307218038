import { callI } from "./callI";
import { endpointI } from "./endpointI";
import { langI } from "./langI";
import { livekitI } from "./livekitI";
import { networkI } from "./networkI";
import { participantI } from "./participantI";
import { speakerI } from "./speakerI";
import { streamI } from "./streamI";
import { toastI } from "./toastI";
import { userI } from "./userI";
function StreamerI(){
    const _inner = {};
    _inner.isDrawerOpen = false;
    _inner.shared = []
    _inner.ui = null;
    _inner.stream_url = null;
    _inner.isValid = false;
    _inner.is_loading = false;
    _inner.validate = ()=>{
        _inner.isValid = /^(https?|rtsp|rtmp):\/\/[^\s/$.?#][^\s]*$/.test(_inner.ui.stream_url)
        _inner.notifyUI();
    }
    _inner.notifyUI = ()=>{
        service.getUIBind();
        for(let key in _inner.ui){
            if(typeof _inner.ui[key] === "function") continue;
            _inner.ui[key] = _inner[key] || service[key]
        }
        _inner.ui.owner_id = callI.getActiveParticipantId()
    }
    _inner.streams = [];
    const STREAM_SHARE_SUFFIX = '_stream_share'
    const classes = {
        SharedStream: class {
            constructor(stream_url){
                this.stream_url = stream_url
                this.is_active = false;
                this.index = null;
                _inner.notifyUI();
            }
        }
    }
    const service = {};
    service.parseIfNeeded = (response)=>{
        if(!response || typeof response === "object") return response
        try{
            return JSON.parse(response)
        }catch(err){
            console.log(err)
        }
    }
    service.toggleDrawer = ()=>{
        if(_inner.isDrawerOpen){
            service.closeDrawer();
        }else{
            service.openDrawer();
        }
        streamI.updateIsDrawerOpen();
    }
    service.openDrawer = ()=>{
        livekitI.list();
        _inner.isDrawerOpen = true;
        _inner.notifyUI();
        streamI.updateIsDrawerOpen();
    }
    service.closeDrawer = ()=>{
        _inner.isDrawerOpen = false;
        _inner.notifyUI();
        streamI.updateIsDrawerOpen();
    }
    service.getUIBind = ()=>{
        if(_inner.ui) return _inner.ui;
        _inner.ui = {
            owner_id: callI.getActiveParticipantId(),
            list:service.list,
            isDrawerOpen:_inner.isDrawerOpen,
            openDrawer:service.openDrawer,
            closeDrawer:service.closeDrawer,
            toggleDrawer:service.toggleDrawer,
            shared:_inner.shared,
            stream_url:_inner.stream_url,
            is_loading:_inner.is_loading,
            add:service.add,
            isValid:_inner.isValid,
            validate:_inner.validate,
            streams:_inner.streams,
            handleStop: service.handleClickStop
        }
        return _inner.ui;
    }
    service.onStop = (obj) => {
        return new Promise((resolve,reject)=>{
            if(typeof obj !== "object") return reject()
            const participant = callI.getActiveParticipant()
            if(obj.participant?.mre_call_participant_id !== participant?.mre_call_participant_id) return reject()
            _inner.notifyUI();
            networkI.post({
                url:livekitI.replaceEndpointPlaceholders(livekitI.getEndpoints().STREAMING.STOP,{callId:callI.getActiveCallId()}),
                data:{
                    isIngress:true,
                    id:obj.id,
                    call_id:callI.getActiveCallId(),
                    participant:participant
                }
            })
            .then((res)=>{
                const response = service.parseIfNeeded(res);
                if(Array.isArray(response.errors)) {
                    reject(networkI.getErrorMessageFromArr(response.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                resolve(response);
                _inner.notifyUI();
            }, (error)=>{
                reject(error);
            }).finally(()=>{
                _inner.notifyUI();
            })
        })
    }
    service.onHandleStop = (obj)=>{
        return new Promise((resolve, reject) => {
            if(!obj) {
                reject();
                return;
            }
            // const instance = _inner.shared.find(obj=>+obj.index === +item.index);
            // const streamObject = _inner.streams.find(obj=>obj.id === item.id);
            const ok = () => {
                // if(instance)
                //     _inner.shared = _inner.shared.filter(obj=>obj.stream_url !== instance.stream_url && +obj.index!==+instance.index);
                // if(streamObject) {
                //     _inner.streams = _inner.streams.filter(obj=>obj.id !== streamObject.id);
                //     service.removeStreamConnection(streamObject.id);
                // }
                resolve();
            }
            const ko = (msg) => {
                reject(msg);
            }
            //if we have a matching instance we proceed with the stop, otherwise we request to stop the url given on the param
            // let streamUrl = instance? instance.stream_url: item && item.stream_url? item.stream_url: null;
            service.onStop(obj).then((response)=>{
                //TODO - check if we need to stop the participant stream's explicitely
                resolve(response)
            },(error)=>{
                reject(error);
            });
        });
    }
    service.onHandleStart = (stream_url)=>{
        return new Promise((resolve, reject)=>{
            if(_inner.is_loading) return
            _inner.is_loading = true;
            _inner.notifyUI();
            let counter = 0;
            function done() {
                _inner.is_loading = false;
                _inner.notifyUI();
            }
            function run() {
                counter++;
                networkI.post({
                    url:livekitI.replaceEndpointPlaceholders(livekitI.getEndpoints().STREAMING.PUBLISH,{callId:callI.getActiveCallId()}),
                    data:{
                        isIngress:true,
                        stream_url:stream_url,
                        call_id:callI.getActiveCallId(),
                        participant:callI.getActiveParticipant()
                    }
                })
                .then((res)=>{
                    const response = service.parseIfNeeded(res)
                    if(Array.isArray(response.errors)) {
                        toastI.error(networkI.getErrorMessageFromArr(response.errors) || langI.get('ERROR_UNK'));
                        done();
                        reject();
                        return;
                    }
                    _inner.notifyUI();
                    if(response.success){
                        if(response.isAlreadyStarted) {
                            if(response.call_id === callI.getActiveCallId() && counter<2) {
                                let obj = {stream_url: response.stream_url, isOwner: true};
                                service.onHandleStop(obj).then(()=>{
                                    run();
                                }, ()=>{
                                    done();
                                    reject(langI.get('STREAM_URL_NOT_SHARED_ERROR'));
                                });
                            } else {
                                done();
                                reject();
                            }
                            return;
                        }
                        done();
                        toastI.success(langI.get('STREAM_URL_SHARED_SUCCESSFULLY'));
                        resolve(response);
                    } else {
                        toastI.error(langI.get('STREAM_URL_NOT_SHARED_ERROR') + (response.message ? ` (${response.message})` : ''))
                        done();
                        reject();
                    }
                }, (error)=>{
                    done();
                    reject(langI.get('STREAM_URL_NOT_SHARED_ERROR'));
                });
            }
            run();
        })
    }
    service.handleClickStop = (item)=>{
        if(!item || item.isLoading)
            return;
        item.isLoading = true
        service.onHandleStop(item).then((response)=>{
            if(response && Array.isArray(response.result)) {
                // let instance = new classes.SharedStream(stream_url);
                // instance.is_active = response.success?true:false;
                // instance.index = +response.index;
                // _inner.shared.push(instance);
                _inner.shared = response.result
                _inner.notifyUI()
            }
            toastI.success(langI.get('STREAM_URL_STOPPED'))
        }, (error)=>{
            toastI.error(error && typeof error==='string'? error: langI.get('STREAM_URL_STOP_ERROR'));
        })
        .finally(()=>{
            item.isLoading = false
        })
    }
    service.handleStart = (stream_url)=>{
        service.onHandleStart(stream_url).then((response)=>{
            if(response && Array.isArray(response.result)) {
                // let instance = new classes.SharedStream(stream_url);
                // instance.is_active = response.success?true:false;
                // instance.index = +response.index;
                // _inner.shared.push(instance);
                _inner.shared = response.result
                _inner.notifyUI()
            }
        }, (error)=>{
            toastI.error(error & typeof error === 'string'? error: '');
            _inner.shared = _inner.shared.filter(obj=>obj.stream_url !== stream_url);
        }).finally(()=>{
            _inner.notifyUI();
        });
    }
    service.add = (streamUrlInput)=>{
        if(_inner.is_loading) return
        if(!(streamUrlInput instanceof HTMLElement)) return
        const stream_url = streamUrlInput.value;
        streamUrlInput.value = ''
        _inner.stream_url = ""
        _inner.notifyUI();
        if(!_inner.isValid) return
        service.handleStart(stream_url);
    }
    service.stopAllMyStreams = ()=>{
        if(_inner.streams && _inner.streams.length>0) {
            for(let i=0; i<_inner.streams.length; i++) {
                if(_inner.streams[i].isOwner) {
                    service.onHandleStop(_inner.streams[i]).then(()=>{}, ()=>{});
                }
            }
        }
    }
    service.clear = ()=>{
        service.stopAllMyStreams();
        _inner.streams = [];
        _inner.shared = [];
        _inner.stream_url = null;
        _inner.isValid = false;
        _inner.notifyUI();
        service.closeDrawer();
    }
    service.handleStreamDisconnected = (id)=>{
        if(!id) return
        if(id.indexOf(STREAM_SHARE_SUFFIX) === -1) return
        const obj = _inner.streams.find(o=>o.id === id);
        if(!obj.isOwner && obj.participant){
            toastI.info(langI.get('PARTICIPANT_STOPPED_STREAM_URL',[obj.participant.name]))
        }
        service.removeStreamConnection(id)
    }
    service.removeStreamConnection = (id)=>{
        if(!id) return
        streamI.clearUser(id);
        setTimeout(()=>{
            streamI.MainCallsContainer();
        })
    }
    service.getIsDrawerOpen = ()=> _inner.isDrawerOpen;
    service.list = ()=>{
        if(_inner.is_loading) return
        _inner.is_loading = true
        _inner.notifyUI()
        service.onList().then((response)=>{
            if(response && Array.isArray(response.result)){
                _inner.shared = response.result
                _inner.notifyUI()
            }
        },()=>{})
        .finally(()=>{
            _inner.is_loading = false
            _inner.notifyUI()
        })
    }
    service.onList = ()=>{
        return new Promise((resolve,reject)=>{
            networkI.get({
                headers:livekitI.getCustomHeaders(),
                url:livekitI.replaceEndpointPlaceholders(livekitI.getEndpoints().STREAMING.LIST,{callId:callI.getActiveCallId(),isIngress:true})
            })
            .then(res=>{
                if(Array.isArray(res.errors)){
                    toastI.error(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return reject(res);
                }
                if(res && res.success){
                    resolve(res)
                }else{
                    reject(res)
                }
            },err=>{
                reject(err)
            })
        })
    }
    service.isStreamParticipant = (id)=>{
        if(typeof id !== "string") return false
        return id.endsWith(STREAM_SHARE_SUFFIX)
    }
    service.handleStreamShareMessage = (id,enabled)=>{
        if(typeof id !== "string" || typeof enabled !== "boolean" ||  !service.isStreamParticipant(id)) return
        const obj = _inner.shared.find(_obj=>_obj.participantIdentity === id);
        if(obj && !enabled) service.onStop(obj).then((response)=>{
            if(response && Array.isArray(response.result)){
                _inner.shared = response.result;
                _inner.notifyUI()
            }
        },()=>{})
        if(id.startsWith(callI.getActiveParticipantId())) return
        const name = participantI.getParticipantNameById(id.split(STREAM_SHARE_SUFFIX)[0])
        if(!name) return
        toastI.info(langI.get(enabled ? 'PARTICIPANT_SHARED_STREAM_URL' : 'PARTICIPANT_STOPPED_STREAM_URL',[name]))
    }
    return service;
}
export const streamerI = StreamerI();