let BusI = function () {
    let service = {};
    service.EVENTS = {
        CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
        UPDATE_PARTICIPANTS : 'UPDATE_PARTICIPANTS',
        ON_PARTICIPANT_LANGUAGE_UPDATED: 'ON_PARTICIPANT_LANGUAGE_UPDATED',
        CONFIRMATION_LEAVE_PARTICIPANTS: 'CONFIRMATION_LEAVE_PARTICIPANTS',
        GET_START_PARTICIPANTS : 'GET_START_PARTICIPANTS',
        TRANSCRIPTION_MESSAGE_SENT: 'TRANSCRIPTION_MESSAGE_SENT',
        TRANSCRIPTION_INSTANCE_STATE_CHANGED: 'TRANSCRIPTION_INSTANCE_STATE_CHANGED',
        ON_SP_CHANGED: 'ON_SP_CHANGED',
        ON_START_SIP_CALL: 'ON_START_SIP_CALL',
        ON_SERVICE_PROVIDERS_UPDATED: 'ON_SERVICE_PROVIDERS_UPDATED',
        ON_CHAT_MSG_RECEIVED: 'ON_CHAT_MSG_RECEIVED',
        ON_REQUEST_SEND_MESSAGE: 'ON_REQUEST_SEND_MESSAGE',
        ON_ACCOUNT_PROFILE_UPDATED: 'ON_ACCOUNT_PROFILE_UPDATED',
        ON_GUEST_CONNECTED: 'ON_GUEST_CONNECTED',
        ON_FRIEND_REQUESTS_UPDATED: 'ON_FRIEND_REQUESTS_UPDATED',
        ON_FRIENDS_STATE_UPDATED: 'ON_FRIENDS_STATE_UPDATED',
        ON_USER_CONNECTED: 'ON_USER_CONNECTED',
        ON_USER_RECONNECTED: 'ON_USER_RECONNECTED',
        ON_SOCKET_DISCONNECTED: 'ON_SOCKET_DISCONNECTED',
        ON_DISPATCH_SOCKET_DISCONNECTED: 'ON_DISPATCH_SOCKET_DISCONNECTED',
        ON_CHATS_UPDATED: 'ON_CHATS_UPDATED',
        ON_CALLS_UPDATED: 'ON_CALLS_UPDATED',
        ON_NOTIFY_ACTIVE_CALL: 'ON_NOTIFY_ACTIVE_CALL',
        ON_ACTIVE_CALL_UPDATED: 'ON_ACTIVE_CALL_UPDATED',
        ON_LOGIN: 'ON_LOGIN',
        ON_LOGOUT: 'ON_LOGOUT',
        ON_REQUEST_LEAVE_CALL: 'ON_REQUEST_LEAVE_CALL',
        ON_LEFT_CALL_DONE: 'ON_LEFT_CALL_DONE',
        ON_OPEN_EXISTING_CALL_MODAL: 'ON_OPEN_EXISTING_CALL_MODAL',
        ON_MEDIA_DEVICE_CHANGE: 'ON_MEDIA_DEVICE_CHANGE',
        ON_REQUEST_STOP_DEVICE: 'ON_REQUEST_STOP_DEVICE',
        ON_REQUEST_RESTORE_DEVICE: 'ON_REQUEST_RESTORE_DEVICE',
        ON_WAITING_ROOM_CHANGE: 'ON_WAITING_ROOM_CHANGE',
        ON_RUN_CALL: 'ON_RUN_CALL',
        ON_ACTIVE_ROUTE_CHANGED: 'ON_ACTIVE_ROUTE_CHANGED',
        ON_ADD_INTEGRATION: 'ON_ADD_INTEGRATION',
        ON_NETWORK_STATE_CHANGE: 'ON_NETWORK_STATE_CHANGE',
        ON_DISPATCH_DMQUEUE_READY: 'ON_DISPATCH_DMQUEUE_READY',
        ON_PARTICIPANT_RECORD_STATE_CHANGE:'ON_PARTICIPANT_RECORD_STATE_CHANGE',
        ON_PARTICIPANT_CONTROLS_STATE_CHANGE:'ON_PARTICIPANT_CONTROLS_STATE_CHANGE',
        ON_LOAD_CALL_CHAT:'ON_LOAD_CALL_CHAT',
        ON_DISPATCH_FULL_SCREEN_CHANGE: 'ON_DISPATCH_FULL_SCREEN_CHANGE',
        ON_MESSAGE_EVENT: 'ON_MESSAGE_EVENT',
        ON_REQUEST_CHECK_EXISTING_CALL: 'ON_REQUEST_CHECK_EXISTING_CALL',
        ON_REQUEST_MOVE_TO_WAITING_ROOM: 'ON_REQUEST_MOVE_TO_WAITING_ROOM',
        ON_CHANGE_TMP_AVATAR:'ON_CHANGE_TMP_AVATAR',
        ON_EMOJI_SELECT:'ON_EMOJI_SELECT',
        ON_TOGGLE_EMOJI:'ON_TOGGLE_EMOJI',
        ON_THEME_CHANGE:'ON_THEME_CHANGE',
        ON_SIGNATURE_RECEIVED: 'ON_SIGNATURE_RECEIVED',
        ON_CLEAR_SIGNATURE: 'ON_CLEAR_SIGNATURE',
        ON_REQUEST_SORT_CHATS: 'ON_REQUEST_SORT_CHATS'
    }
    let _inner = {};
    _inner.events = {};
    service.registerEvent = function(evt, fct) {
        if(!service.EVENTS[evt] || typeof fct !== 'function')
            return;
        if(!_inner.events[evt])
            _inner.events[evt] = [];
        let exists = false;
        for(let i=0; i<_inner.events[evt].length; i++) {
            if(_inner.events[evt][i].fct === fct) {
                exists = true;
                break;
            }
        }
        if(!exists)
            _inner.events[evt].push({fct: fct});
    }
    service.unregisterEvent = function(evt, fct) {
        if(!_inner.events[evt] || _inner.events[evt].length < 1 || typeof fct !== 'function')
            return;
        for(let i=0; i<_inner.events[evt].length; i++) {
            if(_inner.events[evt][i].fct === fct) {
                _inner.events[evt].splice(i, 1);
                break;
            }
        }
    }
    service.notifyEvent = function(evt, data){
        if(!service.EVENTS[evt] || !_inner.events[evt] || _inner.events[evt].length<1)
            return;
        for(let i=0; i<_inner.events[evt].length; i++) {
            if(typeof _inner.events[evt][i].fct !== 'function')
                continue;
            setTimeout(()=>{
                if(_inner.events[evt][i] && typeof _inner.events[evt][i].fct === 'function')
                    _inner.events[evt][i].fct(data);
            });
        }
    }
    return service;
}
export const busI = BusI();