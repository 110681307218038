const NotificationEventI = function(){
    const _inner = {};
    _inner.handledEvents = {};
    const service = {};
    service.onShowNotification = async (id,title,options)=>{
        return new Promise(async (resolve,reject)=>{
            if(id &&_inner.handledEvents[id] || !title || typeof options !== "object") return reject();
                let registration = null;
                if(self && self.registration){
                    registration = self.registration;
                }else if(navigator.serviceWorker){
                    try{
                        registration = await navigator.serviceWorker.getRegistration();
                    }catch(err){}
                }
                if(registration && registration.showNotification){
                    registration.showNotification(title,{
                        ...options,
                        requireInteraction:true
                    }).then(()=>{},()=>{});
                    if(id){
                        _inner.handledEvents[id] = true;
                        setTimeout(()=>{
                            delete _inner.handledEvents[id];
                        },1000)
                    }
                    return resolve();
                }else{
                    return reject();
                }
        })
    }
    return service;
}
const notificationEventI = NotificationEventI();
if(typeof module === "object"){
    module.exports = notificationEventI;
}
