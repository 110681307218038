import { Injectable } from '@angular/core';
import { utilI } from 'src/assets/js/utilI';
@Injectable({
  providedIn: 'root'
})
export class BroadCastService {
  private METHODS = {BROAD_CAST_API:'BROAD_CAST_API',LOCAL_STORAGE_API:'LOCAL_STORAGE_API'}
  public EVENTS = {USER_LOGIN:'USER_LOGIN',USER_LOGOUT:'USER_LOGOUT',LEAVE_CALL:'LEAVE_CALL', CHECK_ON_CALL: 'CHECK_ON_CALL', CHECK_ON_CALL_ANSWER: 'CHECK_ON_CALL_ANSWER'}
  private KEYS = {LOCAL_STORAGE:'TMU.WebApp.BroadCastMessage',BROAD_CAST_CHANNEL:'TMU.WebApp'};
  private UNIQUE_ID = null;
  private method: any = null
  private broadCastChannel: any = null;
  private callbacks:any = {};
  private timeout = { id:null ,func:null ,interval: 1500}
  private initialized : boolean = false;
  constructor() {}
  public init(){
    if(this.initialized) return;
    if(!utilI.getFromSessionStorage('UNIQUE_ID')){
       utilI.updateSessionStorage('UNIQUE_ID',Date.now().toString(36));
    }
    this.UNIQUE_ID = utilI.getFromSessionStorage('UNIQUE_ID');
    if(window.BroadcastChannel){
      this.handleBroadCastChannel();
    }else{
      this.handleLocalStorage();
    }
    this.initialized = true;
  }
  private handleBroadCastChannel(){
    if(this.broadCastChannel) return
    this.method = this.METHODS.BROAD_CAST_API;
    this.broadCastChannel = new BroadcastChannel(this.KEYS.BROAD_CAST_CHANNEL);
    this.broadCastChannel.addEventListener('message',(event)=>{
      this.executeEventCallback(event.data);
    })
  }
  public clearLocalStorage(){
    let item = utilI.getFromLocalStorage(this.KEYS.LOCAL_STORAGE);
    if(!item) return
    try{
      const uniqueId = JSON.parse(item).uniqueId;
      if(uniqueId === this.UNIQUE_ID) return;
    }catch{}
    utilI.removeFromLocalStorage(this.KEYS.LOCAL_STORAGE);
  }
  private handleLocalStorage(){
    this.clearLocalStorage();
    this.method = this.METHODS.LOCAL_STORAGE_API;
    window.clearTimeout(this.timeout.id);
    this.timeout.func = ()=>{
      let item = utilI.getFromLocalStorage(this.KEYS.LOCAL_STORAGE);
      if(item){
        try{
          const payload = JSON.parse(item);
          if(payload.uniqueId && payload.uniqueId !== this.UNIQUE_ID && payload.data){
            utilI.removeFromLocalStorage(this.KEYS.LOCAL_STORAGE);
            this.executeEventCallback(payload)
          }
        }catch(e){}
      }
      this.timeout.id = setTimeout(this.timeout.func,this.timeout.interval)
    }
    this.timeout.func();
  }
  public postMessage(message, obj = null){
    if(!message) return
    switch(this.method){
      case this.METHODS.BROAD_CAST_API:
        if(this.broadCastChannel && this.broadCastChannel.postMessage)
           this.broadCastChannel.postMessage({key: message, data: obj});
        break;
      case this.METHODS.LOCAL_STORAGE_API:
        const payload = { key: message, data: obj, uniqueId:this.UNIQUE_ID}
        utilI.updateLocalStorage(this.KEYS.LOCAL_STORAGE,JSON.stringify(payload));
        break;
    }
  }
  public registerEvent(event,callback){
    if(!event || typeof callback !== "function") return
    this.callbacks[event] = callback;
  }
  private executeEventCallback(event){
    if(!event || !event.key) return;
    if(typeof this.callbacks[event.key] !== "function") return
    this.callbacks[event.key](event.data);
  }
}
