import { endpointI } from "./endpointI";
import { langI } from "./langI";
import { networkI } from "./networkI";
import { serviceProviderI } from "./serviceProviderI";
import { toastI } from "./toastI";
import { userI } from "./userI";
let IntegrationI = function () {
    let service = {};
    let _inner = {};
    _inner.tempUserPassword = null;
    _inner.integrations = {};
    _inner.activeIntegrations = {};
    _inner.config = {};
    service.SLACK = {
        ROUTE: 'slack',
        LABEL: 'Slack',
        CLIENT_ID: '304532609607.1357197230561',
        ENABLED: false,
        requireIntegration: false,
        workspaces: []
    }
    _inner.CONFIG_WORKFLOW_URL = 'https://7989abdc-7811-46b8-9bd3-eb172f5ca17f.trayapp.io';
    _inner.CHANGE_PASSWORD_WORKFLOW_URL = 'https://14cf60d6-9d69-433e-9697-f70b5fb4b0dc.trayapp.io';
    _inner.slackExternalId = 'external_slack_authentication';
    _inner.tmuExternalId = 'external_tmu_authentication';
    service.updateIntegrations = function(integrations) {
        if(!integrations || !Array.isArray(integrations))
            return;
        for(let i = 0; i < integrations.length; i++) {
            if(integrations[i].name.includes(service.SLACK.ROUTE)) {
                integrations[i].src = "assets/logos/slack_hash_256.png";
                integrations[i].route = service.SLACK.ROUTE;
            }
            if(integrations[i].name.includes("SalesForce")) {
                integrations[i].src = "assets/logos/salesforce-logo.png";
                integrations[i].route = "salesforce";
            }
            if(integrations[i].name.includes("Zendesk")) {
                integrations[i].src = "assets/logos/zendesk.png";
                integrations[i].route = "zendesk";
            }
            if(integrations[i].name.includes("Twilio")) {
                integrations[i].src = "assets/logos/twilio-mark-red.png";
                integrations[i].route = "twilio";
            }
            _inner.integrations[integrations[i].integration_id] = integrations[i];
        }
    }
    service.setTempUserPassword = function(pwd) {
        _inner.tempUserPassword = pwd? pwd: '';
    }
    service.getTempUserPassword = function() {
        return _inner.tempUserPassword? _inner.tempUserPassword: '';
    }
    service.getById = function(id) {
        return _inner.integrations? _inner.integrations[id]: null;
    }
    service.decorateIntegration = function(integration) {
        if(!integration)
            return;
        let obj = _inner.integrations[integration.integration_id];
        integration.is_active = obj&&integration.is_active? true: false;
        integration.name = obj && obj.name? obj.name: '';
        integration.route = obj && obj.route? obj.route: '';
        integration.src = obj && obj.src? obj.src: '';
    }
    service.addActiveIntegration = function(integration) {
        if(!integration || !integration.integration_id)
            return;
        service.decorateIntegration(integration);
        _inner.activeIntegrations[integration.integration_id] = integration;
    }
    service.updateProfileIntegrations = function(integrations, pIntegrations) {
        if(!pIntegrations || !Array.isArray(pIntegrations))
            return;
        clearTimeout(_inner.updateProfileIntegrationsTimer);
        _inner.updateProfileIntegrationsTimer = setTimeout(()=>{
            service.onGet(()=>{}, ()=>{})
            .finally(()=>{
                if(Array.isArray(pIntegrations))
                    for(let i = 0; i < pIntegrations.length; i++)
                        service.addActiveIntegration(pIntegrations[i]);
                if(Array.isArray(integrations))
                    for(let i = 0; i < integrations.length; i++) {
                        if(!_inner.activeIntegrations[integrations[i].integration_id])
                            continue;
                        _inner.activeIntegrations[integrations[i].integration_id].is_active = true;
                    }
            });
        }, 150);
    }
    service.getActiveIntegrations = function() {
        let filtered = [];
        for(let k in _inner.activeIntegrations)
            if(_inner.activeIntegrations[k].route)
                filtered.push(_inner.activeIntegrations[k]);
        return filtered;
    }
    service.get = function() {
        let filtered = [];
        for(let k in _inner.integrations)
            if(_inner.integrations[k].route)
                filtered.push(_inner.integrations[k]);
        return filtered;
    }
    service.onGet = function() {
        return new Promise((resolve, reject)=>{
            let integrations = service.get();
            if(integrations.length>0) {
                resolve(integrations);
                return;
            }
            serviceProviderI.onLoadProviderDetails(serviceProviderI.getServiceProviderId())
            .then((res)=>{
                resolve(service.get());
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.getTMUAccountName = function() {
        return integrationI.getAccountName()+'_'+_inner.tmuExternalId;
    }
    service.getSlackAccountName = function() {
        return integrationI.getAccountName()+'_'+_inner.slackExternalId;
    }
    _inner.onConfigTraySlackUser = function(obj) {
        return new Promise((resolve, reject)=>{
            var xhr = new XMLHttpRequest();

            let serviceIdAuthTmu = "47000422-f499-4871-a4b1-9a9214ca7ca2";
            let serviceEnvironmentIdAuthTmu = "e6215a04-ce85-4407-a834-5a1645bb05be";
            let serviceIdAuthSlack = "3e21b055-1597-40ae-bcc2-72af0843b283";
            let serviceEnvironmentIdAuthSlack = "35cf89a9-5d49-44b3-ba10-7c78324da260";
            let solutionId = '5cf399fc-bd36-4db6-b2fc-0b408ce2f364';

            let authTmu = { "name": service.getTMUAccountName(), "serviceId": serviceIdAuthTmu, "serviceEnvironmentId": serviceEnvironmentIdAuthTmu,
            "data" : { "email_address": userI.getEmail(), "password": obj.password, "environment": endpointI.getSelectedEndpointName(),
            "mre_call_provider_id": serviceProviderI.getServiceProviderId()} , "externalId": _inner.tmuExternalId };
            let authSlack = { "name": service.getSlackAccountName(), "serviceId": serviceIdAuthSlack, "serviceEnvironmentId": serviceEnvironmentIdAuthSlack,
            "externalId": _inner.slackExternalId };

            let token = (_inner.config.userBearerToken && typeof _inner.config.userBearerToken==='string')? _inner.config.userBearerToken.toLowerCase().replace('bearer', '').trim(): '';
            let data = { "userToken": token, "solutionId": solutionId, "solutionInstanceId": _inner.config.solutionInstanceId, "authSlack": authSlack, "authTmu": authTmu };
            xhr.addEventListener("readystatechange", function() {
            if(this.readyState === 4) {
                if(xhr.status == 200) {
                    resolve();
                } else
                    toastI.error(`${langI.get('ERROR_RECORDING_UNK')} ${langI.get('TRY_AGAIN_OR_CONTACT')}`, 0, {fontsize: ".85rem"});
            }
            });
            xhr.open("POST", _inner.CONFIG_WORKFLOW_URL);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(data));
        });
    }
    _inner.onChangeTrayUserPassword = function(obj) {
        return new Promise((resolve, reject)=>{
            let xhr = new XMLHttpRequest();
            let solutionId = '5cf399fc-bd36-4db6-b2fc-0b408ce2f364';
            let authTmu = { "data" : { "email_address": userI.getEmail(), "password": obj.password, "environment": endpointI.getSelectedEndpointName(),
            "mre_call_provider_id": serviceProviderI.getServiceProviderId()} , "externalId": _inner.tmuExternalId };
            let token = (_inner.config.userBearerToken && typeof _inner.config.userBearerToken==='string')? _inner.config.userBearerToken.toLowerCase().replace('bearer', '').trim(): '';
            let data = { "userToken": token, "solutionId": solutionId, "solutionInstanceId": _inner.config.solutionInstanceId, "authTmu": authTmu };

            xhr.addEventListener("readystatechange", function() {
                if(this.readyState === 4) {
                    if(xhr.status == 200) {
                        resolve();
                    } else
                        toastI.error(`${langI.get('ERROR_RECORDING_UNK')} ${langI.get('TRY_AGAIN_OR_CONTACT')}`, 0, {fontsize: ".85rem"});
                }
            });
            xhr.open("POST", _inner.CHANGE_PASSWORD_WORKFLOW_URL);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(data));
        });
    }
    service.onInit = function() {
        return new Promise((resolve, reject)=>{

        });
    }
    service.clear = function() {
        _inner.integrations = {};
        _inner.activeIntegrations = {};
    }
    service.onUpdateIntegration = function(obj) {
        return new Promise((resolve, reject) => {
            if(!obj || !userI.getAccountProfileId()) {
                reject();
                return;
            }
            let payload = {
                AccountProfile: {
                    account_profile_id: userI.getAccountProfileId()
                }
            };

            if('integration' in obj)
                payload.Integration = obj.integration;
            if('integrationDestinationAddress' in obj)
                payload.DestinationAddress = obj.integrationDestinationAddress;
            if('profileIntegration' in obj)
                payload.ProfileIntegration = obj.profileIntegration;
            if('update' in obj)
                payload.Update = obj.update;
            if('profileIntegrationActive' in obj)
                payload.IsActive = obj.profileIntegrationActive;
            if('dataConfig' in obj)
                payload.ConfigData = obj.dataConfig;

            userI.onUpdateAccountProfile(payload)
            .then(()=>{
                userI.onLoadMyAccount()
                .then((response)=>{
                    resolve(response);
                }, (err)=>{
                    reject(err);
                });
            }, (err)=>{
                reject(err);
            });
        });
    }
    service.getAccountName = function() {
        let provider = serviceProviderI.getServiceProviderName().toLowerCase().split(' ').join('_');
        let account = userI.getWhoAmIAccount();
        return account.screen_name + '_' + endpointI.getSelectedEndpointName() + '_' + provider;
    }
   service.onGetAuthUser = function() {
        return new Promise((resolve, reject)=>{
            let data = JSON.stringify({
                query: `query {
                viewer {
                authentications {
                    edges {
                    node {
                        id
                        name
                        customFields
                        service {
                        id,
                        name,
                        icon,
                        title,
                        version
                        }
                    }
                    }
                    pageInfo{
                    hasNextPage
                    hasPreviousPage
                    }
                }
                }
            }`,
                variables: {}
            });
            let headers = {
                Authorization: _inner.config.userBearerToken
            }
            networkI.post({url: '/integrations/usergraphql', data: data, headers: headers})
            .then((res)=>{
                try {
                    let response = JSON.parse(res);
                    resolve(response['data']['viewer'].authentications.edges[0].node.id);
                } catch (error) {
                    reject(error);
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onCreateAuthTrayUser = function(obj) {
        return new Promise((resolve, reject)=>{
            if(!obj || !obj.name || !obj.email || !obj.password) {
                reject();
                return;
            }
          let xhr = new XMLHttpRequest();
          let serviceId = "47000422-f499-4871-a4b1-9a9214ca7ca2";
          let serviceEnvironmentId = "e6215a04-ce85-4407-a834-5a1645bb05be";
          let token = _inner.config.authorizationCode;
          let data = { "userToken": token, "name": obj.name, "serviceId": serviceId, "serviceEnvironmentId": serviceEnvironmentId,
          "data" : { "email_address": obj.email, "password": obj.password, "environment": endpointI.getSelectedEndpointName(),
          "mre_call_provider_id": serviceProviderI.getServiceProviderId()} };
          xhr.addEventListener("readystatechange", () => {
            if(xhr.readyState === 4) {
              if(xhr.status == 200) {
                service.onGetAuthUser()
                .then((res)=>{
                  resolve(res);
                }, (error)=>{
                  reject(error);
                });
              } else
                reject('');
            }
          });
          xhr.open("POST", "https://6b882b2a-0582-4ac5-8d0e-3a282fb6cf54.trayapp.io");
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.send(JSON.stringify(data));
        });
    }
    service.onGetConfig = function(route) {
        return new Promise((resolve, reject) => {
            if(_inner.config && _inner.config.authorizationCode) {
                service.handleConfigResponse(_inner.config);
                resolve(_inner.config);
                return;
            }
            service.onLoadConfig(route)
            .then(()=>{
                resolve(_inner.config);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onLoadConfig = function(route) {
        return new Promise((resolve, reject)=>{
            if(!route || !userI.getWhoAmIAccount() || !serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            let headers = {
                'Content-Type': 'application/json',
            };
            let lastCredentials = userI.getLastCredentials();
            if(lastCredentials) {
                headers['username'] = lastCredentials.email_address,
                headers['password'] = lastCredentials.password
            }
            let account = userI.getWhoAmIAccount();
            let payload = {
                name: integrationI.getAccountName(),
                externalUserId: account.account_profile_id
            }
            networkI.post({url: '/integrations/' + route, data: payload, headers: headers})
            .then((res)=>{
                try {
                    let response = JSON.parse(res);
                    service.handleConfigResponse(response);
                    resolve(_inner.config);
                } catch (error) {
                    reject(error);
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.handleConfigResponse = function(res) {
        _inner.config = res;
        service.SLACK.workspaces = Array.isArray(res.workspaces)? res.workspaces.filter(item=>item&&item.authSlack&&item.auth&&item.workspace): [];
        service.SLACK.ENABLED = service.SLACK.workspaces.length>0;
    }
    service.getConfig = function() {
        return _inner.config;
    }
    service.onOpenConfigureTrayAuth = function (url) {
        return new Promise((resolve, reject) => {
          if(!_inner.config.authorizationCode) {
            reject();
            return;
          }
          let popup = window.open(url, 'Authentication', 'width=500,height=500');
          window.addEventListener('message', (message)=>{
            if(!message) {
              reject();
              return;
            }
            if(message.isTrusted && message.data && message.data.type == 'tray.configPopup.finish') {
              resolve(message);
              popup.close();
            }
          });
        });
    }
    service.onOpenConfigWindow = function(url) {
        return new Promise((resolve, reject)=>{
            let popup = window.open(url, 'Authentication', 'width=500,height=500');
            let check = function() {
                setTimeout(()=>{
                    let isDone = false;
                    try {
                        isDone = popup.location.href.indexOf('enjoy')>-1? true: false;
                    } catch (e) {}
                    if(isDone) {
                        resolve();
                        return;
                    }
                    if(popup.closed)
                        reject();
                    else
                        check();
                }, 500);
            }
            setTimeout(()=>{
                check();
            }, 500);
        });
    }
    service.OnRunAuth = () =>{
        return new Promise((resolve, reject)=>{
            service.onOpenConfigureTrayAuth('https://embedded.tray.io/external/solutions/ecocarrier/configure/'+_inner.config.solutionInstanceId+'?code='+_inner.config.authorizationCode)
            .then(()=>{
                let scope = 'users:read,users:read.email,groups:write,channels:join,channels:manage,channels:read,commands,incoming-webhook,chat:write,chat:write.public,groups:read,channels:history,groups:history,im:history,mpim:history';
                let userScope = 'channels:read,groups:read,channels:write,groups:write,chat:write';
                let url = 'https://slack.com/oauth/v2/authorize?client_id='+service.SLACK.CLIENT_ID+'&scope='+scope+'&user_scope='+userScope+'&redirect_uri=https://4f5662f7-29fa-410a-b6b1-f06d4003337f.trayapp.io';
                service.onOpenConfigWindow(url)
                .then(()=>{
                    resolve();
                }, ()=>{
                    reject();
                });
            }, ()=>{
                reject();
            });
        });
    }
    service.onRunConfig = (password) => {
        return new Promise((resolve, reject)=>{
            if(!password) {
                reject();
                return;
            }
            let route = service.SLACK.ROUTE;
            service.onLoadConfig(route).then((response)=>{
                _inner.onConfigTraySlackUser({password: password})
                .then((res)=>{
                    service.onLoadConfig(route)
                    .then(()=>{
                        resolve();
                    }, (err)=>{
                        reject(err);
                    });
                }, (err)=>{
                    reject(err);
                });
              }, (error)=>{
                reject(error);
                });
        });
    }
    service.onChangePassword = (password)=>{
        return new Promise((resolve, reject)=>{
            if(!password) {
                reject();
                return;
            }
            if(endpointI.isOnDev()) {
                resolve();
                return;
            }
            let route = service.SLACK.ROUTE;
            service.onGetConfig(route)
            .then(()=>{
                if(!service.SLACK.ENABLED) {
                    resolve();
                    return;
                }
                _inner.onChangeTrayUserPassword({password: password})
                .then(()=>{
                    resolve();
                }, (err)=>{
                    reject(err);
                });
            }, ()=>{
                reject();
            });
        });
    }
    service.onRemoveIntegration = function(name) {
        return new Promise((resolve, reject)=>{
            if(!_inner.config.solutionInstanceId || !name) {
                resolve();
                return;
            }
            let xhr = new XMLHttpRequest();
            let token = (_inner.config.userBearerToken && typeof _inner.config.userBearerToken==='string')? _inner.config.userBearerToken.toLowerCase().replace('bearer', '').trim(): '';
            let solutionId = "5cf399fc-bd36-4db6-b2fc-0b408ce2f364";
            let data = { "userToken": token, "solutionInstanceId": _inner.config.solutionInstanceId, "solutionId": solutionId, "workspace": name };
            xhr.addEventListener("readystatechange", () => {
                if(xhr.readyState === 4) {
                  if(xhr.status == 200) {
                    resolve();
                  } else
                    reject('');
                }
            });
            xhr.open("POST", "https://e96fe8e9-5e98-4ec0-b625-04435a8abcc6.trayapp.io");
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(data));
        });
    }
    service.onRemoveSlackChannel = function(callId) {
        return new Promise((resolve, reject)=>{
            if(!_inner.config.solutionInstanceId || !callId) {
                resolve();
                return;
            }
            let xhr = new XMLHttpRequest();
            let data = { "solutionInstanceId": _inner.config.solutionInstanceId, "call": callId };
            xhr.addEventListener("readystatechange", function() {
                if(xhr.readyState === 4) {
                    resolve();
                }
            });
            xhr.open("POST", "https://d215148e-e1f7-4292-a836-2cfade139d33.trayapp.io");
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(data));
        });
    }
    service.requireIntegration = function(param) {
        service.SLACK.requireIntegration = service.SLACK.ROUTE==param? true: false;
    }
    service.isIntegrationRequired = function() {
        return service.SLACK.requireIntegration? true: false;
    }
    service.getRequiredIntegrationLabel = function() {
        return service.SLACK.requireIntegration? service.SLACK.LABEL: '';
    }
    return service;
}
export const integrationI = IntegrationI();