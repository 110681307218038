import { busI } from "src/assets/js/busI";
import { billingI } from "./billingI";
import { serviceProviderI } from "./serviceProviderI";
import { endpointI } from "./endpointI.js";
import { callI } from "./callI.js";
import { chatI } from "./chatI.js";
import { navbarI } from "./navbarI.js";
import { guestI } from "./guestI.js";
import { integrationI } from "./integrationI.js";
import { messagingI } from "./messagingI.js";
import { langI } from "./langI";
import { utilI } from "./utilI";
import { participantI } from "./participantI";
import { networkI } from "./networkI";
import { roomI } from "./roomI";
import { transcriptionI } from "./transcriptionI";

let UserI = function () {
    let service = {};
    service.ROLE_HOST_PARTICIPANT = 'af7c0f9c-5966-46f2-9c6d-eb1f0e4fe6b2';
    service.ROLE_SUB_HOST = 'd1f044c2-964b-483d-b417-6365e0058b82';
    service.ROLE_PARTICIPANT = '4ac4bfda-4f2c-4bd9-b0b9-e5069af456c5';
    service.ROLE_SPEAKER = '8a25b325-8de3-46c4-a3eb-dcda3fef4a2e';
    let _inner = {};
    _inner.AUTH_STORED_KEY = 'tmu.WebApp.AuthToken';
    _inner.accessToken = null;
    _inner.subscriber = null;
    _inner.accountProfile = null;
    _inner.WhoAmI = null;
    _inner.friends = [];
    _inner.friendRequests = [];
    _inner.sentFriendRequests = [];
    _inner.lastCredentials = null;
    _inner.appSettings = {}
    _inner.socket = {
        onConnected: null,
        lastDisconnectionAt: null,
        lastConnectedAt: null,
        connectedCounter: 0,
        errorCounter: 0
    }
    _inner.clientCallbacks = {
        error: null,
        connected: null
    }
    _inner.ui = null;
    _inner.is_avatar_uploading = false;
    _inner.contactAction = null

    service.setLastCredentials = function(obj) {
        if(!obj || typeof obj !=='object')
            return;
        if(!_inner.lastCredentials)
            _inner.lastCredentials = {};
        for(let k in obj)
            _inner.lastCredentials = obj[k];
    }
    service.getLastCredentials = function() {
        return _inner.lastCredentials && _inner.lastCredentials.email_address? _inner.lastCredentials: null;
    }
    service.setIsOnFreeBilling = function(val) {
        _inner.isOnFreeBilling = +val===1? true: false;
    }
    service.isOnFreeBilling = function() {
        return _inner.isOnFreeBilling? true: false;
    }
    service.hasSIPEnabled = function() {
        return serviceProviderI.hasSIP();
    }
    service.updateSentFriendRequests = function(profiles) {
        if(!Array.isArray(profiles))
            return;
        for(let i=0; i<profiles.length; i++) {
            profiles[i].avatar_url = profiles[i].avatar_url? profiles[i].avatar_url: service.getDefaultAvatar();
        }
        _inner.sentFriendRequests = profiles;
    }
    service.getSentFriendRequests = function() {
        return _inner.sentFriendRequests? _inner.sentFriendRequests: [];
    }
    service.addSentFriendRequest = function(friend) {
        if(!friend)
            return;
        if(!_inner.sentFriendRequests)
            _inner.sentFriendRequests = [];
        friend.avatar_url = friend.avatar_url? friend.avatar_url: service.getDefaultAvatar();
        _inner.sentFriendRequests.push(friend);
    }
    service.removeSentFriendRequest = function(friend) {
        if(!friend || !_inner.sentFriendRequests)
            return;
        _inner.sentFriendRequests.forEach((request, index) => {
            if(request.account_profile_id == friend.account_profile_id) {
                _inner.sentFriendRequests.splice(index, 1);
            }
        });
    }
    service.updateFriendRequests = function(profiles) {
        if(!Array.isArray(profiles))
            return;
        for(let i=0; i<profiles.length; i++) {
            profiles[i].avatar_url = profiles[i].avatar_url? profiles[i].avatar_url: service.getDefaultAvatar();
        }
        _inner.friendRequests = profiles;
        busI.notifyEvent(busI.EVENTS.ON_FRIEND_REQUESTS_UPDATED);
    }
    service.addFriendRequest = function(friend) {
        if(!friend)
            return;
        if(!_inner.friendRequests)
            _inner.friendRequests = [];
        friend.avatar_url = friend.avatar_url? friend.avatar_url: service.getDefaultAvatar();
        _inner.friendRequests.push(friend);
        busI.notifyEvent(busI.EVENTS.ON_FRIEND_REQUESTS_UPDATED);
    }
    service.removeFriendRequest = function(friend) {
        if(!friend || !_inner.friendRequests)
            return;
        _inner.friendRequests.forEach((request, index) => {
            if(request.account_profile_id == friend.account_profile_id) {
                _inner.friendRequests.splice(index, 1);
            }
        });
        busI.notifyEvent(busI.EVENTS.ON_FRIEND_REQUESTS_UPDATED);
    }
    service.getFriendRequests = function() {
        return _inner.friendRequests? _inner.friendRequests: [];
    }
    service.updateFriends = function(profiles) {
        if(!Array.isArray(profiles))
            return;
        for(let i=0; i<profiles.length; i++) {
            profiles[i].avatar_url = profiles[i].avatar_url? profiles[i].avatar_url: service.getDefaultAvatar();
        }
        _inner.friends = profiles;
    }
    service.updateFriend = function(friend) {
        if(!friend)
            return;
        if(!_inner.friends)
            _inner.friends = [];
        friend.avatar_url = friend.avatar_url? friend.avatar_url: service.getDefaultAvatar();
        _inner.friends = _inner.friends.filter(item=>item.account_profile_id!==friend.account_profile_id);
        _inner.friends.push(friend);
    }
    service.getFriends = function() {
        return _inner.friends? _inner.friends: [];
    }
    service.getFriendById = function(id) {
        if(!id)
            return;
        let friend = null;
        for(let i=0; i<_inner.friends.length; i++) {
            if(_inner.friends[i].account_profile_id === id) {
                friend = _inner.friends[i];
                break;
            }
        }
        return friend;
    }
    service.getFriendsByEmail = function(email) {
        if(!email || typeof email !== 'string')
            return [];
        let friends = [];
        for(let i=0; i<_inner.friends.length; i++) {
            if(!_inner.friends[i].email_address)
                continue;
            if(_inner.friends[i].email_address.toLowerCase().trim().indexOf(email.toLowerCase().trim())>-1) {
                friends.push(_inner.friends[i]);
            }
        }
        return friends;
    }
    service.setFriendOutOfCall = function(id) {
        if(!id)
            return;
        for(let i=0; i<_inner.friends.length; i++) {
            if(_inner.friends[i].account_profile_id === id) {
                delete _inner.friends[i].is_invited;
                delete _inner.friends[i].is_in_call;
                break;
            }
        }
    }
    service.setFriendInCall = function(id) {
        if(!id)
            return;
        for(let i=0; i<_inner.friends.length; i++) {
            if(_inner.friends[i].account_profile_id === id) {
                _inner.friends[i].is_invited = false;
                _inner.friends[i].is_in_call = true;
                break;
            }
        }
    }
    service.getLocalStringSeparator = function() {
        let arr = ["a", "b"];
        if(typeof arr.toLocaleString==='function') {
            let str = arr.toLocaleString();
            if (str.indexOf(';') > 0 && str.indexOf(',') == -1) {
                return ';';
            }
        }
        return ',';
    }
    service.getCurrentDate = ()=>{
        if(_inner.currentDate)
            return new Date(_inner.currentDate.date);
        return new Date();
    }
    service.getCurrentTimestamp = () => {
        return  _inner.currentDate && _inner.currentDate.timestamp? _inner.currentDate.timestamp: 0;
    }
    _inner.runCurrentTimestampUpdate = ()=>{
        _inner.currentTimestampTimer = setTimeout(()=>{
            if(_inner.currentDate && _inner.currentDate.timestamp)
                _inner.currentDate.timestamp += 1000;
            _inner.runCurrentTimestampUpdate();
        }, 1000);
    }
    service.setCurrentDate = (obj)=>{
        if(!obj)
            return;
        _inner.currentDate = obj;
        clearTimeout(_inner.currentTimestampTimer);
        _inner.runCurrentTimestampUpdate();
    }
    service.updateAppConfig = (obj) =>{
        if(!obj)
            return;
        try {
            if(!_inner.appConfig)
                _inner.appConfig = {};
            let config = JSON.parse(obj);
            for(let k in config)
                _inner.appConfig[k] = config[k];
            _inner.notifyUI();
        } catch (error) {
            _inner.appConfig = {};
        }
    }
    service.getDefaultAvatar = ()=>{
        return _inner.appConfig && _inner.appConfig.default_avatar? _inner.appConfig.default_avatar: `/assets/icons/default_avatar.svg`;
    }
    service.getTmuStreamApi = ()=>{
        let url = _inner.appConfig?.channel_service_server?.replace(/\/$/, "")
        if(typeof url == "string" && !url.endsWith('api')){
            url+="/api"
        }
        return url
    }
    service.getLivekitSocketServer = ()=>{
        return _inner.appConfig?.channel_service_socket_server || "ws://127.0.0.1:7880"
    }
    service.getWhiteBoardServer = ()=>{
        return _inner.appConfig?.whiteboard_server;
    }
    service.getSipDefaultAvatar = ()=>{
        return _inner.appConfig && _inner.appConfig.default_sip_avatar? _inner.appConfig.default_sip_avatar: `/assets/icons/default_avatar_phone_gray.svg`;
    }
    service.isSipProviderConnected = ()=>{
        return _inner.appConfig && +_inner.appConfig.isSipProviderDisabled===1? false: true;
    }
    _inner.reconnectOnFocusDocument = () => {
        service.reconnectSocket();
    }
    _inner.onVisibilityChange = function() {
        clearTimeout(_inner.documentFocusTimer);
        _inner.documentFocusTimer = setTimeout(()=>{
            if(_inner.hasFocus() && _inner.reconnectOnFocusDocument && typeof _inner.reconnectOnFocusDocument === 'function')
                _inner.reconnectOnFocusDocument();
        }, 500);
    }
    _inner.hasFocus = function() {
        return !document.visibilityState || document.visibilityState!=='hidden'? true: false;
    }
    _inner.notifyUI = function() {
        clearTimeout(_inner.notityUITimer);
        _inner.notityUITimer = setTimeout(()=>{
            if(!_inner.ui)
                service.getUIBind();
            _inner.ui.hasCustomJoinCode = service.getJoinCode()? true: false;
            _inner.ui.customJoinCode = service.getJoinCode();
            _inner.ui.isGuest = service.isGuest();
            _inner.ui.is_avatar_uploading = _inner.is_avatar_uploading;
        }, 50);
    }
    service.getUIBind = function() {
        if(_inner.ui) {
            _inner.notifyUI();
            return _inner.ui;
        }
        _inner.ui = {
            default_avatar: service.getDefaultAvatar(),
            hasCustomJoinCode: service.getJoinCode()? true: false,
            customJoinCode: service.getJoinCode(),
            isGuest: service.isGuest(),
            is_avatar_uploading:_inner.is_avatar_uploading
        }
        return _inner.ui;
    }
    service.onPing = function() {
        return new Promise((resolve, reject)=>{
            if(!service.getAccessToken() || !callI.getActiveParticipant()) {
                reject();
                return;
            }
            let payload = {
                MreCallParticipant: callI.getActiveParticipant()
            };
            networkI.post({url: '/api/user/ping', data: payload})
            .then((res)=>{
                if(res&&res.MreCallParticipant) {
                    participantI.updateParticipants([res.MreCallParticipant]);
                    if(res && res.MreCall) {
                        callI.updateCall(res.MreCall, res);
                        if(callI.isCallStoppedById(res.MreCall.mre_call_id)) {
                            busI.notifyEvent(busI.EVENTS.ON_REQUEST_LEAVE_CALL);
                        } else if(res.MreCall.mre_call_id === callI.getActiveCallId()) {
                            transcriptionI.updateTranscriptionStateBasedOnCurrentParticipant(res);
                        }
                    }
                    resolve();
                }
                else
                    reject(networkI.getErrorMessageFromArr(res? res.errors: ''));
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.connectSocket = function() {
        if(!service.getAccountProfileId() || (service.isGuest() && !callI.getActiveCallId()))
            return;
        if(service.isSocketConnected() || !userI.getAccessToken())
            return;

        let url = endpointI.getWSHost();
        _inner.socket = new WebSocket(url + '/api/user/ws/'+ service.getAccountProfileId(), ['Authorization', userI.getAccessToken()]);
        _inner.socket.onmessage = (wsdata) => {
            if (wsdata && wsdata.data) {
                try {
                    messagingI.handleMessageEvent(JSON.parse(wsdata.data));
                } catch (error) {
                }
            }
        };
        _inner.socket.onerror = (error) =>{
            clearTimeout(_inner.socketReconnectTimer);
            _inner.socketReconnectTimer = setTimeout(()=>{
                if(_inner.hasFocus())
                    service.reconnectSocket();
            }, 1000);
        }
        _inner.socket.onopen = (res) =>{
            clearTimeout(_inner.socketReconnectTimer);
            document.removeEventListener('visibilitychange', _inner.onVisibilityChange);
            document.addEventListener('visibilitychange', _inner.onVisibilityChange);
            if(callI.getActiveCallId())
                service.sendSocketMessage({IsActive: true, MreCallID: callI.getActiveCallId()});
        }
        _inner.socket.onclose = (res) =>{
            clearTimeout(_inner.socketReconnectTimer);
            _inner.socketReconnectTimer = setTimeout(()=>{
                if(_inner.hasFocus())
                    service.reconnectSocket();
            }, 1000);
        }
    }
    service.isSocketConnected = function() {
        return _inner.socket && _inner.socket.readyState == _inner.socket.OPEN
        && _inner.socket.url && _inner.socket.url.indexOf(service.getAccountProfileId())>-1? true: false;
    }
    service.sendSocketMessage = function(obj) {
        if(typeof obj !== 'object')
            return;
        if (_inner.socket && _inner.socket.readyState == _inner.socket.OPEN) {
            _inner.socket.send(JSON.stringify(obj));
        }
    }
    service.reconnectSocket = function() {
        if(service.isSocketConnected())
            return;
        service.disconnectSocket();
        setTimeout(()=>{
            service.connectSocket();
        }, 300);
    }
    service.disconnectSocket = function() {
        if(!_inner.socket || !_inner.socket.url)
            return;
        if(_inner.socket.readyState !== _inner.socket.CLOSED && _inner.socket.readyState !== _inner.socket.CLOSING)
            _inner.socket.close();
        _inner.socket = null;
    }
    service.setWhoAmI = function(who) {
        if(!who || typeof who !== 'object')
            return;
        if(!_inner.WhoAmI)
            _inner.WhoAmI = {};
        for(let k in who)
            _inner.WhoAmI[k] = who[k];
    }
    service.clearWhoAmi = function() {
        _inner.WhoAmI = null;
    }
    service.isWhoAmiGuest = function() {
        return _inner.WhoAmI && _inner.WhoAmI.Roles && _inner.WhoAmI.Roles.length>0 && _inner.WhoAmI.Roles[0] && _inner.WhoAmI.Roles[0].name == "TMUGuest"? true: false;
    }
    service.getWhoAmILangId = function() {
        return _inner.WhoAmI && _inner.WhoAmI.Language && _inner.WhoAmI.Language.language_id? _inner.WhoAmI.Language.language_id: null;
    }
    service.updateWhoAmIRole = function(role) {
        if(!role || !_inner.WhoAmI || !_inner.WhoAmI.Roles)
            return;
        if(_inner.WhoAmI.Roles.length<1)
            _inner.WhoAmI.Roles = [];
        _inner.WhoAmI.Roles[0] = role;
    }
    service.getWhoAmIAccount = function() {
        return _inner.WhoAmI && _inner.WhoAmI.AccountProfile? _inner.WhoAmI.AccountProfile: null;
    }
    service.setRole = function(role = null) {
        if(!role && _inner.WhoAmI && _inner.WhoAmI.Roles && _inner.WhoAmI.Roles.length>0 && _inner.WhoAmI.Roles[0]) {
            role = _inner.WhoAmI.Roles[0];
        }
        service.setIsModerator(role? role.name == "TMUModerator" || role.name == "TMUHost": false);
        service.setIsUser(!service.isGuest());
        service.updateRoleLabel();
    }
    service.isAdmin = function() {
        return _inner.WhoAmI&&_inner.WhoAmI.IsAdmin? true: false;
    }
    service.setAccessToken = function(token) {
        _inner.accessToken = (token)? token: null;
    }
    service.getAccessToken = function() {
        return _inner.accessToken;
    }
    service.setRefreshToken = function(token) {
        _inner.refreshToken = (token)? token: null;
    }
    service.getRefreshToken = function() {
        let storeAuth = service.getStoredAuth();
        return _inner.refreshToken? _inner.refreshToken: storeAuth && storeAuth.RefreshToken? storeAuth.RefreshToken: null;
    }
    service.updateAccountProfile = (profile) => {
        if(!profile)
            return;
        if(profile && _inner.accountProfile && _inner.accountProfile.account_profile_id) {
            for(let p in profile)
                _inner.accountProfile[p] = profile[p];
        } else
            _inner.accountProfile = (profile&&profile.account_profile_id)? profile: {};

        if(!_inner.accountProfile.screen_name)
            _inner.accountProfile.screen_name = ((_inner.accountProfile.first_name)? _inner.accountProfile.first_name: '')+ ' ' + ((_inner.accountProfile.last_name)? _inner.accountProfile.last_name: '');
        _inner.accountProfile.screen_name = _inner.accountProfile.screen_name? _inner.accountProfile.screen_name.trim(): '';
        _inner.accountProfile.avatar_url = _inner.accountProfile.avatar_url? _inner.accountProfile.avatar_url: service.getDefaultAvatar();
        busI.notifyEvent(busI.EVENTS.ON_ACCOUNT_PROFILE_UPDATED);
        _inner.notifyUI();
    }
    service.getAccountProfile = () => {
        return _inner.accountProfile;
    }
    service.getAccountProfileForApi = () => {
        return {account_profile_id: service.getAccountProfileId()};
    }
    service.prepareAccountProfilesForApi = (arr) => {
        return Array.isArray(arr) && arr.length>0? arr.map(item=> {return {account_profile_id: item.account_profile_id}}).filter(item=>item.account_profile_id): [];
    }
    service.getAccountProfileId = () => {
        return _inner.accountProfile && _inner.accountProfile.account_profile_id? _inner.accountProfile.account_profile_id: null;
    }
    service.getAccountCallRecordingPass = () => {
        let profile = service.getAccountProfile();
        return profile && profile.call_recording_pass? profile.call_recording_pass: '';
    }
    service.getEmail = () => {
        return _inner.accountProfile && _inner.accountProfile.email_address? _inner.accountProfile.email_address: '';
    }
    service.getGuestEmail = () => {
        return _inner.guestEmail? _inner.guestEmail: '';
    }
    service.getTempCallToken = () => {
        return _inner.tempCallToken? _inner.tempCallToken: '';
    }
    service.getScreenName = () => {
        let name = _inner.accountProfile && _inner.accountProfile.screen_name? _inner.accountProfile.screen_name.trim(): '';
        return name? name: (_inner.tempScreenName)? _inner.tempScreenName: '';
    }
    service.setTempScreenName = (name) => {
        _inner.tempScreenName = (name && typeof name==='string')? name.trim(): '';
    }
    service.setTempCallToken = (token) => {
        _inner.tempCallToken = (token)? token: '';
    }
    service.setGuestEmail = (email) => {
        _inner.guestEmail = (email)? email: '';
    }
    service.updateRoleLabel = () => {
        if(!_inner.accountProfile) return
        _inner.accountProfile.user_role = service.isModerator()? 'Moderator': service.isUser()? 'User': 'Guest';
    }
    service.setIsGuest = (value) => {
        if(!_inner.accountProfile)
            _inner.accountProfile = {};
        _inner.accountProfile.is_guest = +value===1? true: false;
        _inner.notifyUI();
    }
    service.setIsModerator = (value) => {
        if(!_inner.accountProfile)
            return;
        _inner.accountProfile.is_moderator = +value===1? true: false;
        _inner.notifyUI();
    }
    service.setIsUser = (value) => {
        if(!_inner.accountProfile)
            return;
        _inner.accountProfile.is_user = +value===1? true: false;
        _inner.notifyUI();
    }
    service.isGuest = () => {
        return !_inner.accountProfile || (!service.isUser()&&!service.isModerator()&&!service.getAccountProfileId()) || +_inner.accountProfile.is_guest===1? true: false;
    }
    service.isModerator = () => {
        if(_inner.WhoAmI && Array.isArray(_inner.WhoAmI.Roles)) {
            for(let i=0; i<_inner.WhoAmI.Roles.length; i++) {
                if(_inner.WhoAmI.Roles[i].name == 'TMUModerator')
                    return true;
            }
        }
        return _inner.accountProfile && +_inner.accountProfile.is_moderator===1? true: false;
    }
    service.isUser = () => {
        return _inner.accountProfile && +_inner.accountProfile.is_user===1? true: false;
    }
    service.updateAccountJoinCode = (code) => {
        if(!_inner.accountProfile)
            return
        _inner.accountProfile.mre_call_join_code = typeof code==='string'? code: '';
    }
    service.getJoinCode = () => {
        return _inner.accountProfile && _inner.accountProfile.mre_call_join_code? _inner.accountProfile.mre_call_join_code: '';
    }
    service.decorateUnregistredUser = function(user) {
        if(!user || !user.email_address)
            return;
        user.email_address = user.email_address;
        user.screen_name = '';
        user.emailToSendNotice = 'Recipient will be emailed.';
        user.is_registered = false;
        user.is_friend = false;
        user.avatar_url = service.getDefaultAvatar();
        return user;
    }
    service.onUpdateAccountProfile = function(obj) {
        return new Promise((resolve, reject) => {
            if(!obj) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi()
            };
            if('joinCode' in obj && obj.joinCode !== service.getJoinCode())
                payload.MreCallJoinCode = obj.joinCode? obj.joinCode: "";

            payload.AccountProfile = obj.account? obj.account: service.getAccountProfile();

            if(!payload.AccountProfile) {
                reject();
                return;
            }
            if('callRecordingPass' in obj && typeof obj.callRecordingPass==="string")
                payload.AccountProfile.call_recording_pass = obj.callRecordingPass;
            if(payload.AccountProfile.new_avatar)
                payload.FileBytes = btoa(payload.AccountProfile.new_avatar);
            if('password' in obj)
                payload.Password = obj.password;
            if('language' in obj)
                payload.LanguageObject = obj.language;
            if('verificationCode' in obj)
                payload.Code = obj.verificationCode;
            if('content' in obj)
                payload.Content = obj.content;
            if('country_id' in obj)
                payload.country_id = obj.country_id;
            networkI.put({url: endpointI.getMyAccountEndpoint(), data: payload})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                } else {
                    service.updateAccountProfile(res.AccountProfile);
                    if ('MreCallJoinCode' in res && obj.flag != -1)
                        service.updateAccountJoinCode(res.MreCallJoinCode? res.MreCallJoinCode: '');
                    resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onUpdateUserAvatar = function(file) {
        return new Promise((resolve, reject) => {
            if(!file) {
                reject();
                return;
            }
            let formData = new FormData();
            formData.append('file', file);
            networkI.put({url: endpointI.getMyAccountEndpoint() + '/avatar', formData: formData})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return
                }
                service.updateAccountProfile(res.AccountProfile);
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onChangeLanguageProfile = function(obj) {
        return new Promise((resolve, reject) => {
            if(!obj || !obj.language) {
                reject();
                return;
            }
            let payload = {
                AccountProfile: {account_profile_id: service.getAccountProfileId()},
                language: obj.language
            };
            service.onUpdateAccountProfile(payload)
            .then((res)=>{
                if(res.LanguageObject)
                    userI.setWhoAmI({Language: res.LanguageObject});
                service.updateAccountProfile(res.AccountProfile);
                resolve(res.LanguageObject);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onLoadMyAccount = function() {
        return new Promise((resolve, reject) => {
            if(!serviceProviderI.getServiceProviderId()) {
                reject()
                return;
            }
            let url = endpointI.getMyAccountEndpoint()+'?';
            url += 'providerId='+serviceProviderI.getServiceProviderId();
            url += '&includebilling=0';
            networkI.get({url: url})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                } else {

                    service.updateAppConfig(res.AppConfig);

                    if(Array.isArray(res.BillingPlans))
                        for (let i = 0; i < res.BillingPlans.length; i++) {
                            let plan = res.BillingPlans[i];
                            plan.planCharges = [];
                            for (let k = 0; k < res.BillingPlanCharges.length; k++) {
                                let planCharge = res.BillingPlanCharges[k];
                                planCharge.name = planCharge.name.substring(planCharge.name.indexOf("-") + 1, planCharge.name.length);
                                if (plan.billing_plan_id == planCharge.billing_plan_id) {
                                    plan.planCharges.push(planCharge);
                                }
                                if (planCharge.charge_category_id == "49562119-f496-4927-be52-2e27426da1cd" && planCharge.billing_plan_id == plan.billing_plan_id) {
                                    plan.monthly_rate = planCharge.rate;
                                }
                            }
                        }
                    service.setIsOnFreeBilling(res.BillingFreeDebugEnabled);
                    service.updateAccountJoinCode(res.MreCallJoinCode);
                    service.updateAccountProfile(res.AccountProfile);
                    integrationI.updateProfileIntegrations(res.Integrations, res.ProfileIntegrations);
                    billingI.setSelectedPlan(res.BillingPlan);
                    billingI.setSelectedPlanCharges(res.BillingPlanCharges);
                    billingI.setInvoiceCategoryCharges(res.BillingInvoiceCategoryCharges);
                    billingI.setAgreement(res.BillingAgreement);
                    billingI.setAccount(res.BillingAccount);
                    resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onChangePassword = function(password) {
        return new Promise((resolve, reject)=>{
            if(!password) {
                reject();
                return;
            }
            let payload = {
                Password: password
            }
            networkI.put({url: '/api/user/password', data: payload})
            .then((res)=>{
                if(res&&+res.IsAccepted===1)
                    resolve();
                else {
                    reject(networkI.getErrorMessageFromArr(res? res.errors: ''));
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onValidatePassword = function(password) {
        return new Promise((resolve, reject)=>{
            if(!password) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                Password: password
            }
            networkI.post({url: '/api/user/validatepassword', data: payload})
            .then((res)=>{
                if(res&&+res.IsAccepted===1)
                    resolve(res);
                else {
                    reject(networkI.getErrorMessageFromArr(res? res.errors: '') || langI.get('ERROR_UNK'));
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onSendFeedback = function(obj) {
        return new Promise((resolve, reject)=>{
            if(!obj) {
                reject();
                return;
            }
            let payload  = {
                Url: window.origin,
                Rating: obj.rating,
                Content: obj.feedback
            }
            payload.Os = (navigator && navigator.userAgent)? navigator.userAgent: '';
            if(obj.scheduleDemo) payload.ScheduleDemo = obj.scheduleDemo;
            if(obj.name) payload.SenderName = obj.name;
            if(obj.email) payload.EmailAddress = obj.email;

            payload.Endpoint = endpointI.getSelectedEndpointName();
            networkI.post({url: '/api/guest/feedback', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onDeclineFriendRequest = function(friend) {
        return new Promise((resolve, reject)=>{
            if(!friend || !service.getAccessToken()) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                AccountProfile: friend
            };
            networkI.put({url: '/api/user/friend/decline', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors))
                    reject(networkI.getErrorMessageFromArr(res.errors));
                else {
                    service.removeFriendRequest(res.AccountProfile);
                    resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onAcceptFriendRequest = function(friend) {
        return new Promise((resolve, reject)=>{
            if(!friend || !service.getAccessToken()) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                AccountProfile: friend
            };
            networkI.put({url: '/api/user/friend/accept', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors))
                    reject(networkI.getErrorMessageFromArr(res.errors));
                else {
                    service.updateFriend(res.AccountProfile);
                    service.removeFriendRequest(res.AccountProfile);
                    resolve(res.AccountProfile);
                    setTimeout(()=>{
                        busI.notifyEvent(busI.EVENTS.ON_FRIEND_REQUESTS_UPDATED);
                        setTimeout(()=>{
                            busI.notifyEvent(busI.EVENTS.ON_FRIENDS_STATE_UPDATED);
                        });
                    })
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onRemoveFriend = function(friend) {
        return new Promise((resolve, reject)=>{
            if(!friend || !service.getAccessToken()) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                AccountProfile: friend
            };
            networkI.post({url: '/api/user/friend/remove', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors))
                    reject(networkI.getErrorMessageFromArr(res.errors));
                else {
                    resolve(res);
                }
                setTimeout(()=>{
                    busI.notifyEvent(busI.EVENTS.ON_FRIEND_REQUESTS_UPDATED);
                    setTimeout(()=>{
                        busI.notifyEvent(busI.EVENTS.ON_FRIENDS_STATE_UPDATED);
                    });
                })
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onAddFriend = function(friend) {
        return new Promise((resolve, reject)=>{
            if(!friend || !service.getAccessToken()) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                AccountProfile: friend
            };
            networkI.post({url: '/api/user/friend', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors))
                    reject(networkI.getErrorMessageFromArr(res.errors));
                else {
                    resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.sendFriendInvite = function(email) {
        return new Promise((resolve, reject)=>{
            if(!email || !service.getAccessToken()) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                EmailAddress: email
            };
            networkI.post({url: '/api/user/provider/invite', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors))
                    reject(networkI.getErrorMessageFromArr(res.errors));
                else {
                    resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.saveAuth = function() {
        if(service.getAccessToken()) {
            let authToken = {
                AccessToken: service.getAccessToken(),
                RefreshToken: service.getRefreshToken(),
                Endpoint: endpointI.getSelectedEndpointName(),
                MreCallProvider: serviceProviderI.getServiceProviderId(),
            };
            try {
                utilI.updateLocalStorage(_inner.AUTH_STORED_KEY, JSON.stringify(authToken));
            } catch (error) {}
        }
    }
    service.getStoredAuth = function() {
        return utilI.getFromLocalStorage(_inner.AUTH_STORED_KEY) || null;
    }
    service.clearAuth = function() {
        service.setAccessToken(null);
        utilI.removeFromLocalStorage(_inner.AUTH_STORED_KEY);
        utilI.removeFromLocalStorage('TMU.WebApp.Authenticated');
        service.disconnectSocket();
    }
    service.onLogin = function(credentials, code, access_token) {
        return new Promise((resolve, reject) => {
            let payload = {};
            if (credentials) payload.Credentials = credentials;
            if (access_token) payload.AccessToken = access_token;
            else if (code) payload.Code = code;
            payload.Notify = true;
            guestI.onLogin(payload)
            .then((res)=>{
                if(!res || Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || guestI.MESSAGES.FAILED_LOGIN);
                    return;
                }
                if(res.Message && res.CanReactivate) {
                    reject(res.Message);
                    return;
                }
                if(res.AuthToken) {
                    service.setAccessToken(res.AuthToken);
                    service.setRefreshToken(res.RefreshToken);
                }

                if(res.NeedsToVerifyEmail) {
                    resolve(false);
                } else {
                    service.updateAccountProfile(res.AccountProfile);
                    service.updateAccountJoinCode(res.MreCallJoinCode);
                    service.setWhoAmI({AccountProfile: res.AccountProfile, Roles: res.Roles, Language: res.Language, IsAdmin: res.IsAdmin});
                    service.setRole();
                    service.onLoadAppSetting().then(()=>{},()=>{})
                    .finally(()=>{
                        langI.onLoadUserLanguages()
                        .then(()=>{
                            service.onLoadSettings().then(()=>{}, ()=>{});
                            service.setIsGuest(0);
                            langI.initSelectedLanguage();
                            if(integrationI.isIntegrationRequired() && credentials && credentials.password)
                                integrationI.setTempUserPassword(credentials.password);
                            setTimeout(()=>{
                                service.onLoadFriends().then(()=>{}, ()=>{}).finally(()=>{
                                    service.onLoadFriendsRequests().then(()=>{}, ()=>{});
                                });
                                setTimeout(()=>{
                                    if(!roomI.isOnActiveRoom())
                                        chatI.onGetChats().then(()=>{}, ()=>{});
                                }, 250);
                            }, 250);
                            service.saveAuth();
                            service.onLoadMyAccount().then(()=>{}, ()=>{});
                            busI.notifyEvent(busI.EVENTS.ON_USER_CONNECTED);
                            resolve(res);
                        }, (error)=>{
                            reject();
                        });
                    })
                }
            }, ()=>{
                reject(guestI.MESSAGES.FAILED_LOGIN);
            });
        });
    }
    service.onRecover = function(info, account) {
        return new Promise((resolve, reject)=>{
            if(!info || !account || !serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            let payload = {
                Credentials: info,
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                AccountProfile: account
            };
            networkI.post({url: '/api/user/recover', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                if(res.ReplyContent) {
                    resolve(res);
                } else {
                    reject();
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onLogout = function() {
        return new Promise((resolve, reject)=>{
            if(service.isGuest() || !serviceProviderI.getServiceProviderId()) {
                service.completeLogout();
                resolve();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi()
            };
            networkI.put({url: '/api/user/logout', data: payload})
            .then((res)=>{
                resolve(res);
            }, (error)=>{
                reject(error);
            }).finally(()=>{
                service.completeLogout();
            });
        });
    }
    service.onUpgradeAccount = function(account, profile) {
        return new Promise((resolve, reject)=>{
            if(!account || !profile || !serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            if(!billingI.getSelectedPlan())
                billingI.setSelectedPlan({ billing_plan_id: billingI.getFreeTierPlanId() });
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                Credentials: account,
                AccountProfile: profile,
                BillingPlan: billingI.getSelectedPlan()
            };
            networkI.put({url: '/api/user/upgrade', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return;
                }

                if(res.AuthToken || res.AccessToken) {
                    service.setAccessToken(res.AuthToken || res.AccessToken);
                    service.setRefreshToken(res.RefreshToken);
                    service.saveAuth();
                }
                if(!res.NeedsToVerifyEmail) {
                    service.setIsGuest(0);
                }
                service.updateAccountProfile(res.AccountProfile);
                service.setWhoAmI({AccountProfile: res.AccountProfile, Roles: res.Roles, Language: res.Language, IsAdmin: res.IsAdmin});
                service.setRole();
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onLoadFriends = function() {
        return new Promise((resolve, reject)=>{
            if(!serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            if(_inner.friends.length>0) {
                resolve({AccountProfiles: _inner.friends});
                return;
            }
            networkI.get({url: '/api/friends?providerId='+serviceProviderI.getServiceProviderId()})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                service.updateFriends(res.AccountProfiles);
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onLoadFriendsRequests = function() {
        return new Promise((resolve, reject)=>{
            if(!serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            networkI.get({url: '/api/friendrequests?providerId='+serviceProviderI.getServiceProviderId()})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                service.updateFriendRequests(res.AccountProfiles);
                service.updateSentFriendRequests(res.SentFriendRequests);
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onSearchForUsers = function(search, params = null) {
        return new Promise((resolve, reject)=>{
            if(!search || !serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            let url = '/api/user/search?SearchTerm='+search+'&providerId='+serviceProviderI.getServiceProviderId();

            if(params)
                for(let k in params)
                    url += ('&'+k+'='+params[k])

            networkI.get({url: url})
            .then((res)=>{
                let accounts = Array.isArray(res.AccountProfiles)? res.AccountProfiles: [];
                let invites = Array.isArray(res.Invites)? res.Invites: [];
                let userEmail = service.getEmail();
                accounts = accounts.filter(item => item.email_address && item.email_address != userEmail);
                //in case duplicate are sent from backend
                let accountsObj = {};
                accounts.forEach((item)=>{
                    item.avatar_url = item.avatar_url? item.avatar_url: service.getDefaultAvatar();
                    accountsObj[item.email_address] = item;
                });
                accounts = [];
                for(let k in accountsObj)
                    accounts.push(accountsObj[k]);
                let accountEmails = accounts.map(item => item.email_address);
                let emailInvites = invites.map(item=>item.email_address);
                resolve({accounts: accounts, invites: invites.filter((item, i) => item && item.email_address && item.email_address != userEmail && accountEmails.indexOf(item.email_address)==-1 && emailInvites.indexOf(item.email_address)==i)});
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.clear = function() {
        clearTimeout(_inner.reconnectTimer);
        navbarI.setLastActivePage('calls');
        _inner.accountProfile = null;
        _inner.notifyUI();
        service.clearAuth();
        integrationI.clear();
        service.clearWhoAmi();
    }
    service.completeLogout = function() {
        let path = serviceProviderI.isECO123() || serviceProviderI.isMorshid() || serviceProviderI.hasBotExperience()? navbarI.getLandingPage() : '/login';
        service.disconnectSocket();
        callI.clearStoredActiveCall();
        messagingI.invalidateToken();
        if(!service.getAccountProfileId()) {
            service.clear();
            location.replace(path);
            return;
        }
        messagingI.clearMessageEvents();
        service.setIsGuest(1);
        service.setIsUser(0);
        chatI.clear();
        callI.clear();
        service.clear();
        busI.notifyEvent(busI.EVENTS.ON_LOGOUT);
        location.replace(path);
    }
    service.logout = function() {
        clearTimeout(_inner.logoutTimer);
        _inner.logoutTimer = setTimeout(()=>{
            service.onLogout().then(()=>{}, ()=>{});
        }, 150);
    }
    service.onLoadAppSetting = ()=>{
        return new Promise((resolve,reject)=>{
            if(!service.isAdmin()) {
                reject();
                return;
            }
            networkI.get({url: '/api/admin/setting'})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                } else {
                    const AppSetting = Array.isArray(res.AppSetting) ? res.AppSetting[0] : null
                    if(AppSetting && AppSetting.config){
                        try{
                            AppSetting.config = JSON.parse(AppSetting.config)
                        }catch{}
                    }
                    _inner.appSettings = AppSetting ? AppSetting : {};
                    resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.getAppSettings = ()=>{
        return _inner.appSettings? _inner.appSettings: null;
    }
    service.onLoadSettings = ()=>{
        return new Promise((resolve,reject)=>{
            if(!service.getAccessToken()) {
                reject();
                return;
            }
            if(_inner.settings) {
                resolve(_inner.settings);
                return;
            }
            networkI.get({url: '/api/setting'})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                } else {
                    if(!_inner.settings)
                        _inner.settings = {};
                    for(let k in res) {
                        if(!_inner.settings[k])
                            _inner.settings[k] = {};
                        _inner.settings[k] = res[k];
                    }
                    userI.setCurrentDate(_inner.settings.CurrentDate);
                    resolve(_inner.settings);
                }
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.isAvatarUploading = ()=> _inner.is_avatar_uploading;
    service.setIsAvatarUploading = (value)=>{
        _inner.is_avatar_uploading = +value === 1 ? true : false;
        _inner.notifyUI();
    }
    service.onHandleDeleteProfile = (password)=>{
        return new Promise((resolve,reject)=>{
            (password ? userI.onValidatePassword(password) : Promise.resolve({IsAccepted:true}))
            .then(res=>{
                if(res && res.IsAccepted){
                    networkI.post({url: '/api/user/delete', headers:endpointI.getAuthHeaders()})
                    .then((res)=>{
                        if(Array.isArray(res.errors)) return reject(networkI.getErrorMessageFromArr(res? res.errors: ''));
                        resolve(res)
                    }, (error)=>{
                        reject(error);
                    });
                }else{
                    reject();
                }
            },err=>{
                reject(err)
            })
        })
    }
    service.CONTACT_ACTIONS = {
        REQUEST:"Request",
        DELETED:"Supression",
        ACCEPTED:"Accepted",
        DECLINED:"Decline"
    }
    service.isValidContactAction = (value)=>{
        if(typeof value !== "string") return
        return Object.keys(service.CONTACT_ACTIONS).find(key=>service.CONTACT_ACTIONS[key] === value) ? true : false;
    }
    service.setContactAction = (value)=>{
        if(typeof value !== "string") return
        _inner.contactAction = value;
    }
    service.clearContactAction = ()=>{
        _inner.contactAction = null
    }
    service.getContactAction = ()=> _inner.contactAction;
    window.userI = service;
    return service;
}
export const userI = UserI();