import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { navbarI } from "src/assets/js/navbarI";
import { userI } from "../../assets/js/userI";
@Injectable() export class GuestGuard {
    constructor(public router:Router){}
    canActivate():any{
         if(!this.router.navigated){
            navbarI.navForward('loading')
            return false;
         }
         return userI.isGuest();
    }
}