import interact from "interactjs";
import { busI } from "./busI";
import { callI } from "./callI";
import { langI } from "./langI";
import { loadingI } from "./loadingI";
import { micI } from "./micI";
import { navbarI } from "./navbarI";
import { networkI } from "./networkI";
import { participantI } from "./participantI";
import { serviceProviderI } from "./serviceProviderI";
import { toastI } from "./toastI";
import { cameraI } from "./cameraI";
import { userI } from "./userI";
import { modalI } from "./modals";
import { debugI } from "./debugI";

let RoomI = function () {
    let service = {};
    let _inner = {};
    _inner.ui = null;
    _inner.mreCallRooms = [];
    const MreCallRoom = function(obj){
        // data
        this.obj = obj.room;
        const sizes = {
            wrapper:{
                width:400,
                height:400
            },
            draggableButton:{
                width:10,
                height:10
            }
        }
        const styles = {
            wrapper:{
                width:sizes.wrapper.width+'px',
                height:sizes.wrapper.height+'px',
                position:'fixed',
                top:'10px',
                left:'10px',
                zIndex:100,
                borderRadius:"8px",
                boxShadow:"0px 0px 1px 1px rgba(0,0,0,0.5)",
                backgroundColor:"var(--ui-background)",
                transition: 'opacity 0.3s ease',
                display:'flex',
                flexDirection:'column'
            },
            iframe:{
                width:"100%",
                height:"100%",
                border:"none",
                borderRadius:"0px 0px 8px 8px",
            },
            controlsButton:{
                width:sizes.draggableButton.width+'px',
                height:sizes.draggableButton.height+'px',
                fontSize:"15px",
                display: 'grid',
                placeContent:'center',
                cursor: 'pointer'
            },
            loader:{
                position:'absolute',
                inset:"0",
                width:"100%",
                height:"100%",
                display:"grid",
                placeContent:"center",
                fontSize:"25px",
                backgroundColor:"var(--ui-background)",
                transition: 'opacity 0.3s ease-in-out',
                zIndex:10
            },
            controlsWrapper:{
                display:'flex',
                width:'100%',
                padding:'10px 15px',
                alignItems:"center",
                gap:"5px",
                justifyContent:"end",
                zIndex:20,
                cursor:"default"
            }
        }
        this.isLoaded = false;
        this.isMinimized = false;
        this.isClosed = false;
        this.isMouseDown = false;
        this.title = `Private ${_inner.mreCallRooms.length+1}`
        /// helpers
        const applyStyles = (target,styles)=>{
            if(!(target instanceof HTMLElement) || typeof styles !== "object") return
            Object.keys(styles).forEach(key=>target.style[key] = styles[key])
        }
        const applyEvent = (target,event,callback)=>{
            if(!(target instanceof HTMLElement) || typeof event !== "string" || typeof callback !== "function") return
            target.addEventListener(event,callback)
        }
        const toggleShow = (target,show)=>{
            applyStyles(target,{
                opacity:+show === 1? "1" : "0",
                pointerEvents:+show === 1? 'all' : 'none'
            })
        }
        const toggleMinimize = (minimize)=>{
            this.isMinimized = +minimize === 1;
            [wrapper,iframe,controlsWrapper]
            .forEach(el=>toggleShow(el,!this.isMinimized))
            postMessage({
                type:this.isMinimized ? service.ROOM_EVENTS.ROOM_MINIMIZED : service.ROOM_EVENTS.ROOM_MAXIMIZED
            })
            _inner.notifyUI();
        }
        const initListeners = ()=>{
            if(!contentWindow) return
            contentWindow.addEventListener('message',(event)=>{
                switch(event.data.type){
                    case service.ROOM_EVENTS.ROOM_READY:
                        toggleShow(loader,false)
                        toggleShow(iframe,true)
                        break;
                }
            })
        }
        const getHandlerButton = (position)=>{
            if(!position) return
            const button = document.createElement('button');
            button.id = `handlers-${position}`
            let innerHTML;
            const sharedStyles = {
                position:'absolute',
                padding:"4px",
                // width:"20px",
                // height:"20px",
                display:"grid",
                placeContent:"center",
                cursor:"pointer",
                fontSize:"15px",
                zIndex:30
            }
            let customStyles = {};
            switch(position){
                case 'top':
                    customStyles = {
                        top:"-10px",
                        left:"50%",
                        transform:"translateX(-50%)",
                        cursor:'ns-resize'
                    }
                    innerHTML = `<i class='bx bx-grid-horizontal'></i>`
                    break;
                case 'bottom':
                    customStyles = {
                        bottom:"-10px",
                        left:"50%",
                        transform:"translateX(-50%)",
                        cursor:'ns-resize'
                    }
                    innerHTML = `<i class='bx bx-grid-horizontal'></i>`
                    break;
                case 'left':
                    customStyles = {
                        top:"50%",
                        left:"-10px",
                        transform:"translateY(-50%)",
                        cursor:"ew-resize"
                    }
                    innerHTML = `<i class='bx bx-grid-vertical'></i>`
                    break;
                case 'right':
                    customStyles = {
                        top:"50%",
                        right:"-10px",
                        transform:"translateY(-50%)",
                        cursor:"ew-resize"
                    }
                    innerHTML = `<i class='bx bx-grid-vertical'></i>`
                    break;
            }
            applyStyles(button,{
                ...sharedStyles,
                ...customStyles
            })
            button.innerHTML = innerHTML;
            return button
        }
        const getControlsButton = ({ innerHTML, onClick, extraStyles })=>{
            const button = document.createElement('button');
            button.innerHTML = innerHTML;
            if(typeof onClick === "function"){
                applyEvent(button,'click',onClick)
            }
            applyStyles(button,typeof extraStyles === "object" ? ({...styles.controlsButton,...extraStyles}) :styles.controlsButton)
            return button
        }
        const postMessage = (message)=>{
            this.onload(()=>{
                contentWindow.postMessage(message,window.origin)
            })
        }
        /// elements
        const wrapper = document.createElement('div')
        const iframe = document.createElement('iframe');
        iframe.setAttribute('data-room-id',this.obj?.mre_call_room_id)
        // controls wrapper
        const controlsWrapper = document.createElement('div');
        const draggableButton = getControlsButton({ innerHTML:"<i class='bx bx-move'></i>", extraStyles:{ cursor:"move" } })
        const minimizeButton = getControlsButton({ innerHTML:"<i class='bx bx-minus'></i>", onClick:()=>{ this.minimize() } })
        const closeButton = getControlsButton({ innerHTML:"<i class='bx bx-x'></i>", onClick:()=>{ this.close() } })
        controlsWrapper.appendChild(draggableButton)
        controlsWrapper.appendChild(minimizeButton)
        controlsWrapper.appendChild(closeButton)
        // loader
        const loader = document.createElement('div');
        loader.innerHTML = `<i class='fa fa-circle-o-notch fa-spin'></i>`
        wrapper.appendChild(controlsWrapper)
        wrapper.appendChild(iframe)
        wrapper.appendChild(loader)
        /// methods
        this.onmessage = (callback)=>{
            if(typeof callback !== "function") return
            this.onload(()=>{
                if(!contentWindow) return
                contentWindow.addEventListener('message',callback)
            })
        }
        this.attach = (target)=>{
            (target instanceof HTMLElement? target : document.body).appendChild(wrapper)
        }
        this.detach = ()=>{
            wrapper.parentNode.removeChild(wrapper)
        }
        this.onload = (callback)=>{
            if(typeof callback !== "function") return
            if(this.isLoaded) return callback();
            iframe.addEventListener('load',()=>{
                callback(this)
            },{once:true})
        }
        this.hide = ()=> toggleShow(wrapper,false)
        this.triggerLeaveRoom = (hideWrapper=true)=>{
            if(hideWrapper) toggleShow(wrapper,false)
            postMessage({
                type:   service.ROOM_EVENTS.REQUEST_LEAVE_ROOM
            })
        }
        this.minimize = ()=> toggleMinimize(true)
        this.maximize = ()=> toggleMinimize(false)
        this.close = (event)=>{
            if(event) event.stopPropagation();
            this.isClosed = true;
            this.triggerLeaveRoom();
        }
        this.handlers = {
            bottom:getHandlerButton('bottom'),
            top:getHandlerButton('top'),
            right:getHandlerButton('right'),
            left:getHandlerButton('left')
        }
        applyEvent(draggableButton,'mousedown',()=>{
            this.isMouseDown = true
        })
        applyEvent(draggableButton,'mouseup',()=>{
            this.isMouseDown = false
        })
        Object.keys(this.handlers).forEach(key=>{
            wrapper.appendChild(this.handlers[key])
            applyEvent(this.handlers[key],'mousedown',()=>{
                this.isMouseDown = true
            })
            applyEvent(this.handlers[key],'mouseup',()=>{
                this.isMouseDown = false
            })
        })
        let contentWindow = null;
        applyStyles(wrapper,styles.wrapper)
        applyStyles(iframe,styles.iframe)
        applyStyles(loader,styles.loader)
        applyStyles(controlsWrapper,styles.controlsWrapper)
        toggleShow(iframe,false)
        iframe.addEventListener('load',()=>{
            this.isLoaded = true;
            contentWindow = iframe.contentWindow;
            // contentWindow.MreCallRoom = this.obj;
            iframe["MreCallRoom"] = this.obj;
            initListeners();
        })
        const url = new URL(window.origin);
        url.hash = `roomId=${this.obj.mre_call_room_id}`
        iframe.src = url.toString();
        interact(wrapper).resizable({
            edges: { left: "#handlers-left", right: "#handlers-right", bottom: "#handlers-bottom", top: "#handlers-top" },
            listeners: {
                move :(event)=>{
                  if(!this.isMouseDown) return
                  const target = event.target
                  let x = (parseFloat(target.getAttribute('data-x')) || 0)
                  let y = (parseFloat(target.getAttribute('data-y')) || 0)
                  target.style.width = event.rect.width + 'px'
                  target.style.height = event.rect.height + 'px'
                  x += event.deltaRect.left
                  y += event.deltaRect.top
                  target.style.transform = 'translate(' + x + 'px,' + y + 'px)'
                  target.setAttribute('data-x', x)
                  target.setAttribute('data-y', y)
                }
              },
              modifiers: [
                interact.modifiers.restrictEdges({
                  outer: 'parent'
                }),
                interact.modifiers.restrictSize({
                  min: { width: sizes.wrapper.width, height: sizes.wrapper.height },
                  max: { width: window.innerWidth, height: window.innerHeight}
                })
              ],
              inertia: true
            })
        interact(wrapper).draggable({
            inertia:true,
            modifiers:[
                interact.modifiers.restrictRect({
                    restriction:"parent"
                })
            ],
            autoScroll:true,
            listeners:{
                move:(event)=>{
                    if(!this.isMouseDown) return
                    const target = event.target;
                    const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
                    const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
                    target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
                    target.setAttribute('data-x', x)
                    target.setAttribute('data-y', y)
                }
            }
        })
        this.update = (data)=>{
            if(typeof data !== "object") return
            let participants = Array.isArray(data.participants) ? data.participants : [];
            let title = data && data.room && data.room.call_subject? data.room.call_subject: 'Room';
            this.title = participants.length > 0? participants.map(p=>p.name).join(','): title;
        }
        this.update(obj);
        return this;
    }
    service.closeRooms = ()=>{
        _inner.mreCallRooms.forEach(instance=>instance.triggerLeaveRoom())
        _inner.mreCallRooms = [];
        service.clearAllPrompted();
        _inner.notifyUI();
    }
    service.ROOM_EVENTS = {
        REQUEST_LEAVE_ROOM:"REQUEST_LEAVE_ROOM",
        ROOM_LEFT:"ROOM_LEFT",
        ROOM_READY:"ROOM_READY",
        ROOM_MINIMIZED:"ROOM_MINIMIZED",
        ROOM_MAXIMIZED:"ROOM_MAXIMIZED"
    }
    service.addPrompted = (id, obj)=>{
        if(!_inner.prompted)
            _inner.prompted = {};
        _inner.prompted[id] = obj;
    }
    service.removePrompted = (id)=>{
        if(!_inner.prompted)
            return;
        if(_inner.prompted[id] && _inner.prompted[id].container)
            toastI.closeToast(_inner.prompted[id].container);
        delete _inner.prompted[id];
    }
    service.clearAllPrompted = () => {
        if(!_inner.prompted)
            return;
        for(let k in _inner.prompted)
            service.removePrompted(k);
    }
    service.onOpenRoomWithParticipant = (id)=>{
        return new Promise((resolve,reject)=>{
            if(!id) return reject();
            if(id === callI.getActiveParticipantId()) return reject();
            const participant = participantI.getParticipantById(id);
            if(!participant) return reject();
            const account_profile_id = participant.account_profile_id;
            if(!account_profile_id) return reject();
            const callId = callI.getActiveCallId();
            if(!callId) return reject();
            networkI.post({
                url:`/api/call/${callId}/room`,
                data:{
                    MreCallProvider:serviceProviderI.getServiceProviderForApi(),
                    AccountProfiles:[
                        {
                            account_profile_id
                        }
                    ]
                }
            })
            .then((response)=>{
                if(Array.isArray(response.errors)) {
                    reject(networkI.getErrorMessageFromArr(response.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                resolve(response);
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.handleOpenMreCallRoomIframe = (obj)=>{
        if(typeof obj !== "object") return
        const instance = new MreCallRoom(obj);
        instance.onmessage((event)=>{
            switch(event.data.type){
                case service.ROOM_EVENTS.ROOM_LEFT:
                    instance.hide();
                    setTimeout(instance.detach,400)
                    _inner.mreCallRooms = _inner.mreCallRooms.filter(room=>room !== instance)
                    _inner.notifyUI()
                    break;
            }
        })
        instance.attach();
        _inner.mreCallRooms.push(instance)
    }
    service.updateRoom = (obj)=>{
        if(!Array.isArray(_inner.mreCallRooms))
            return;
        for(let i=0; i<_inner.mreCallRooms.length; i++) {
            if(!_inner.mreCallRooms[i].obj || _inner.mreCallRooms[i].obj.mre_call_room_id!==obj.mre_call_room_id)
                continue;
            if(typeof _inner.mreCallRooms[i].update === 'function')
                _inner.mreCallRooms[i].update(obj);
        }
    }
    service.isRoomAlreadyOpen = (obj) => {
        if(!obj || !Array.isArray(_inner.mreCallRooms)) {
            return false;
        }
        return _inner.mreCallRooms.filter(room => room.obj && room.obj.mre_call_room_id === obj.mre_call_room_id).length>0;
    }
    service.getActiveRoomId = ()=> {
        return window.frameElement?.getAttribute('data-room-id')
    }
    service.isOnActiveRoom = ()=> service.getActiveRoomId() && window.parent !== window;
    service.handleMreCallRoom = ()=>{
        if(!service.isOnActiveRoom()) return
        window.addEventListener('message',(event)=>{
            if(event.data){
                switch(event.data.type){
                    case service.ROOM_EVENTS.REQUEST_LEAVE_ROOM:
                        busI.notifyEvent(busI.EVENTS.ON_REQUEST_LEAVE_CALL,{
                            isStop: callI.isActiveCallByHost()
                        })
                        break;
                }
            }
        })
        setTimeout(()=>{
            modalI.init();
            setTimeout(()=>{
                const mre_call_room_id = service.getActiveRoomId();
                loadingI.showLoading();
                callI.onLoadExpandedCall(mre_call_room_id, {participants: 1})
                .then(response=>{
                    callI.setActive({call: response.MreCall})
                    participantI.updateParticipants(response.MreCallParticipants)
                    cameraI.onStopDeviceStream().then(()=>{}, ()=>{})
                    .finally(()=>{
                      micI.onStopDeviceStream().then(()=>{}, ()=>{}).finally(()=>{
                        navbarI.navForward("call");
                        callI.setNavToDefault();
                      });
                    });
                },err=>{
                    console.log(err)
                }).finally(()=>{
                    loadingI.hideLoading()
                })
            })
        })
    }
    service.handleRoomToOpen = (obj) => {
        if(!obj || !callI.getActiveCallId() || !obj.room || !obj.room.mre_call_room_id || !obj.room.account_profile_id)
            return;
        const hostParticipant = participantI.getParticipantByAccountProfileAndCallId(obj.room.account_profile_id, callI.getActiveCallId())
        if(!hostParticipant)
            return;
        if(hostParticipant.account_profile_id !== callI.getActiveParticipantAccountId()){
          if(typeof hostParticipant !== "object") return
          let toastObj  = {image: hostParticipant.avatar_url, name: "You have a private call from " + hostParticipant.name + ".", status: 'Ringing', container: {}};
          service.removePrompted(obj.room.mre_call_room_id);
          service.addPrompted(obj.room.mre_call_room_id, toastObj);
          toastI.displayPopup(toastObj, 20000, {action: 'Accept', cancel: 'Decline', onClose: (container)=>{
            if(container)
              toastI.closeToast(container);
          }}).then(()=>{
            service.handleOpenMreCallRoomIframe(obj);
          },()=>{
            if(obj.me && obj.me.mre_call_participant_id
                && obj.me.mre_call_id===obj.room.mre_call_room_id) {
                let payload = {};
                payload.participantId = obj.me.mre_call_participant_id;
                payload.callId = obj.me.mre_call_id;
                payload.data = {
                    is_declined:true
                };
                participantI.onUpdateParticipant(payload)
                .then((res)=>{}, (error)=>{});
            }
          }).finally(()=>{
            service.removePrompted(obj.room.mre_call_room_id);
          });
        } else {
          service.handleOpenMreCallRoomIframe(obj);
        }
    }
    service.runCheckRooms = function(roomId) {
        if(!callI.getActiveCallId() || callI.isRoom(callI.getActiveCall()))
          return;
        let timer = roomId? 0: _inner.checkRoomsTimer? 18000: 10000;
        clearTimeout(_inner.checkRoomsTimer);
        _inner.checkRoomsTimer = setTimeout(()=>{
          clearTimeout(_inner.checkRoomsTimer);
          service.onCheckMyRooms().then((arr)=>{
            if(!Array.isArray(arr) || !callI.getActiveCallId() || callI.isRoom(callI.getActiveCall()))
              return;
            if(roomId)
                arr = arr.filter(item=> item.room && item.room.mre_call_room_id===roomId);
            let toOpen = [];
            for(let i=0; i<arr.length; i++) {
              if(!arr[i].room)
                continue;
              if(service.isRoomAlreadyOpen(arr[i].room)) {
                service.updateRoom(arr[i]);
              } else {
                toOpen.push(arr[i]);
              }
            }
            for(let i=0;i<toOpen.length; i++) {
              setTimeout(()=>{
                service.handleRoomToOpen(toOpen[i]);
              }, timer>0? 2500: 0);
            }
          }, ()=>{}).finally(()=>{
            service.runCheckRooms();
          });
        }, timer);
      }
      service.onCheckMyRooms = function() {
        return new Promise((resolve, reject) => {
          if(!callI.getActiveCallId() || callI.isRoom(callI.getActiveCall())) {
              reject();
              return;
          }
          let url = '/api/call/'+callI.getActiveCallId()+'/room';
          networkI.get({url: url})
          .then((res)=>{
            let rooms = Array.isArray(res.MreCallRooms)? res.MreCallRooms: [];
            rooms = rooms.filter(item=>item.mre_call_id===callI.getActiveCallId());
            let myRooms = [];
            let run=(arr)=>{
              if(!Array.isArray(arr) || arr.length<1) {
                resolve(myRooms);
                return;
              }
              let el = arr.pop();
              if(!el) {
                resolve(myRooms);
                return;
              }
              if(!el.mre_call_room_id) {
                run(arr);
                return;
              }
              callI.onLoadExpandedCall(el.mre_call_room_id, {participants: 1})
              .then((response)=>{
                if(response && response.MreCall && Array.isArray(response.MreCallParticipants)) {
                  if(response.MreCall.is_active && response.MreCall.mre_call_id !== callI.getActiveCallId() && callI.isRoom(response.MreCall)) {
                    let me = response.MreCallParticipants.filter((item)=>{ return item.account_profile_id===userI.getAccountProfileId()});
                    me = me.length===1? me[0]: null;
                    if(me && !me.became_inactive && !me.is_declined) {
                      myRooms.push({room: response.MreCall, participants: response.MreCallParticipants, me: me});
                    }
                  }
                }
              }, (error)=>{}).finally(()=>{
                setTimeout(()=>{
                  run(arr);
                }, 250);
              });
            }
            run(rooms);
          }, (error)=>{
            reject(error);
          });
        });
    }
    service.getUIBind=()=>{
        if(_inner.ui) {
            _inner.notifyUI();
            return _inner.ui;
        }
        _inner.ui = {
            mreCallRooms: []
        }
        return _inner.ui;
    }
    _inner.notifyUI=()=>{
        if(!_inner.ui)
            service.getUIBind();
        _inner.ui.mreCallRooms = _inner.mreCallRooms;
    }
    service.getParsedHash = ()=>{
        if(!window.location.hash) return {}
        const cleanedFragment = window.location.hash.substring(1);
        const keyValuePairs = cleanedFragment.split('&');
        const parsedObject = {};
        keyValuePairs.forEach(function(pair) {
            var keyValueArray = pair.split('=');
            var key = keyValueArray[0];
            var value = keyValueArray[1];
            parsedObject[key] = value;
        });
        return parsedObject;
    }
    service.getRoom = (mre_call_id)=>{
        if(!mre_call_id || !Array.isArray(_inner.mreCallRooms)) return
        const room = _inner.mreCallRooms.find(item=>item?.obj?.mre_call_id === mre_call_id)
        if(room instanceof MreCallRoom) return room
    }
    service.leaveRoomIfNeeded = (mre_call_id)=>{
        const room = service.getRoom(mre_call_id);
        if(!room) return
        room.triggerLeaveRoom?.(false)
    }
    if(debugI.canDebug()){
        service.getInner = ()=> _inner;
        window.roomI = service;
    }
    return service;
}
export const roomI = RoomI();