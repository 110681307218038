import { guestI } from "src/assets/js/guestI";
import { serviceProviderI } from "./serviceProviderI";
import { userI } from "./userI";
import { endpointI } from "./endpointI.js";
import { langI } from "./langI";
import { networkI } from "./networkI";
let BillingI = function () {
    let service = {};
    let _inner = {};
    _inner.selectedPlan = null;
    _inner.selectedPlanCharges = [];
    _inner.invoiceCategoryCharges = [];
    _inner.plans = [];
    _inner.invoices = [];
    _inner.agreement = null;
    _inner.account = null;
    _inner.providers = [];
    _inner.selectedBillindProvider = null;
    _inner.billingInformation = {};

    service.getFreeTierPlanId = function() {
        return '9b51773a-bbcf-4765-a6a9-3532120c4063';
    }
    service.setSelectedPlan = function(plan) {
        _inner.selectedPlan = plan;
    }
    service.getSelectedPlan = function() {
        return _inner.selectedPlan? _inner.selectedPlan: null;
    }
    service.getSelectedPlanId = function() {
        return _inner.selectedPlan&&_inner.selectedPlan.billing_plan_id? _inner.selectedPlan.billing_plan_id: null;
    }
    service.getPlans = function() {
        return _inner.plans? _inner.plans: [];
    }
    service.hasPlans = function() {
        return _inner.plans&&_inner.plans.length>0? true: false;
    }
    service.setAgreement = function(agreement) {
        _inner.agreement = agreement;
    }
    service.getAgreement = function() {
        return _inner.agreement? _inner.agreement: [];
    }
    service.setAccount = function(account) {
        _inner.account = account;
    }
    service.getAccount = function() {
        return _inner.account? _inner.account: [];
    }
    service.setSelectedPlanCharges = function(charges) {
        _inner.selectedPlanCharges = charges;
    }
    service.getSelectedPlanCharges = function() {
        return _inner.selectedPlanCharges? _inner.selectedPlanCharges: [];
    }
    service.setInvoiceCategoryCharges = function(charges) {
        _inner.invoiceCategoryCharges = charges;
        if(_inner.invoiceCategoryCharges&&Array.isArray(_inner.invoiceCategoryCharges))
            _inner.invoiceCategoryCharges.forEach((charge) => {
                charge.usage_percentage = ((charge.amount_sub_total / charge.amount_limit) * 100).toFixed(2);
                charge.name = charge.name.substring(charge.name.indexOf("-") + 1, charge.name.length);
                if (charge.charge_category_id == "49562119-f496-4927-be52-2e27426da1cd")
                    _inner.invoiceCategoryCharges.monthly_rate = charge.rate;
            });
    }
    service.getInvoiceCategoryCharges = function() {
        return _inner.invoiceCategoryCharges? _inner.invoiceCategoryCharges: [];
    }
    service.setSelectedBillindProvider = function(provider) {
        _inner.selectedBillindProvider = provider;
    }
    service.getSelectedBillingProvider = function() {
        return _inner.selectedBillindProvider;
    }
    service.getSelectedBillingProviderId = function() {
        return _inner.selectedBillindProvider && _inner.selectedBillindProvider.billing_provider_id? _inner.selectedBillindProvider.billing_provider_id: '';
    }
    service.getUserBillingInformation = function() {
        return _inner.billingInformation;
    }
    service.getPackages = function() {
        return _inner.packages;
    }
    service.onLoadUserBilling = function(params) {
        return new Promise((resolve, reject)=>{
            if(typeof params !== 'object'){
                reject();
                return;
            }
            let url = '/api/user/billing?';
            for(let k in params)
                url += ('&'+k+'='+params[k]);
            networkI.get({url: url})
            .then((res)=>{
                if(!res || Array.isArray(res.errors) || res.status==500 || res.success===false) {
                    let message = networkI.getErrorMessageFromArr(res? res.errors: '');
                    if(!message)
                        message = res && res.message? res.message: langI.get('BILLING_INFO_ERR_UNK');
                    reject(message);
                    return;
                }
                if(res.balance)
                    _inner.billingInformation.balance = res.balance;
                if(res.remainingMinutes)
                    _inner.billingInformation.remainingMinutes = res.remainingMinutes;
                if(res.consumption)
                    _inner.billingInformation.consumption = Array.isArray(res.consumption)? res.consumption: [];
                if(Array.isArray(res.usage))
                    _inner.billingInformation.usage = res.usage.sort((a1, a2)=>{ return a1.endTime<a2.endTime? 1: a1.endTime>a2.endTime? -1: 0});
                if(res.package)
                    _inner.billingInformation.package = res.package;
                resolve(res);
            }, (err)=>{
                reject(langI.get('BILLING_INFO_ERR_UNK'));
            });
        });
    }
    service.onLoadBillingPackages = function() {
        return new Promise((resolve, reject)=>{
            networkI.get({url: '/api/user/billing/package'})
            .then((res)=>{
                if(!res || Array.isArray(res.errors) || !Array.isArray(res.packages)) {
                    let message = networkI.getErrorMessageFromArr(res? res.errors: '');
                    if(!message)
                        message = res && res.message? res.message: langI.get('BILLING_INFO_ERR_UNK');
                    reject(message);
                    return;
                }
                _inner.packages = res.packages;
                resolve(res);
            }, (error)=>{
                reject(langI.get('BILLING_INFO_ERR_UNK'));
            });
        });
    }
    return service;
}
export const billingI = BillingI();