import { environment } from "src/environments/environment";
import { busI } from "./busI";
import { callI } from "./callI";
import { participantI } from "./participantI";
import { screenI } from "./screenI";
import { toastI } from "./toastI";
import { transcriptionI } from "./transcriptionI";
import { duration } from "moment";
import { streamI } from "./streamI";
import { langI } from "./langI";
import { speakerI } from "./speakerI";
import { modalI } from "./modals";
import { livekitI } from "./livekitI";
import { clipboardI } from "./clipboardI";
import { debugI } from "./debugI";
const VideoShareI=()=>{
    let _inner = {
        API_URL:environment.TMU_RECORD_API_URL,
        videoJs:window["videojs"],
        targetContainerId:'MainCallsContainer',
        currentTargetContainerId:'',
        onNewVideo:null,
        onDestroy:null,
        participant:null,
        DISABLE_PARTICIPANTS_CONTROLS:true,
        players:{},
        isValidURL:false,
        isLoading:{
            video_link:false,
            video_file:false
        },
        selectedURL:null,
        DEBUG:false,
        captions:{},
        lastCaption:null,
        captionsTimeoutId:null,
        captionsHandler:null,
        captionsInterval:100,
        defaultCaptionsLang:null,
        enableCaptions:false,
        DOM_CONTAINER_EL_PREFIX: 'shared-video-',
        connection:null,
        localMedia:null,
        blobUrl:null,
        hasFileShare:false
    };
    _inner.DOM_VIDEO_FILE_SHARE_ID_PREFIX = _inner.DOM_CONTAINER_EL_PREFIX + 'file-';
    _inner.DOM_VIDEO_LINK_SHARE_ID_PREFIX = _inner.DOM_CONTAINER_EL_PREFIX + 'link-';
    _inner.utils = {
        isValidURL(url){
            if(!url) return false;
            if(url) return true;
            return /^[(http(s)?):\/\/(www\.):(127.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(url);
        },
        isYoutubeURL(url){
            if(!url) return false
            return /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/.test(url) ? true : false;
        },
        getYoutubeVideoId(url){
            if(!url) return
            var regExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/
            var match = url.match(regExp);
            return match&&match[1]? match[1] : ''
        },
        factory(tag,attrs={},style={}){
            if(!tag) return;
            let element = document.createElement(tag);
            for(let key in attrs){
                element.setAttribute(key,attrs[key])
            }
            for(let key in style){
                element.style[key] = style[key];
            }

            return element;
        }
    }
    let service = {};
    class Player{
        video=null
        id=null
        platform=null
        captionHandler = null;
        currentVolume=1;
        isFileShare=false
        constructor(player,id,platform,isFileShare=false){
            if(!player || !id) return;
            this.video = player;
            this.id = id;
            this.platform = platform ? platform : null;
            this.initEventListeners();
            this.isFileShare = isFileShare ? true : false;
            this.selectedLanguageCode = participantI.getCurrentUserLanguageCode();
        }
        initMetaData() {
            try {
                let durationSeconds = duration(this.video.duration(), 'seconds');
                this.duration = durationSeconds.format("hh:mm:ss", {trim: false});
            } catch (error) {}
        }
        isYoutubePlayer(){
            return this.platform === "youtube" ? true : false;
        }
        isPaused(){
            return this.video ? this.video.paused() : false;
        }
        isPlaying(){
            return this.video ? !this.video.paused() : false;
        }
        getCurrentTime(){
            return this.video ? this.video.currentTime() : 0 ;
        }
        setCurrentTime(time){
            if(!time || !this.video || +time !== +time) return
            this.video.currentTime(time);
        }
        play(){
            if(!this.video) return
            debugI.debug('PLAYER: play()')
            try {
                this.video.play();
            } catch(err) {
                debugI.debug('ERROR',err);
            }
        }
        pause(){
            if(!this.video) return
            debugI.debug('PLAYER: pause()')
            try {
                this.video.pause();
            } catch(err) { debugI.debug('ERROR',err); }
        }
        setMuted(isMuted){
            if(!this.video || typeof isMuted !== "boolean") return;
            debugI.debug('PLAYER: setMuted()');
            this.video.muted(isMuted);
            if(this.video.streamTools && this.video.streamTools.muteButton)
                this.video.streamTools.muteButton.mute(isMuted);
        }
        isMuted() {
            return this.video && this.video.muted()? true: false;
        }
        stopCaptionsHandler(){
            if(!this.isYoutubePlayer()) return
            _inner.lastCaption = null;
            clearTimeout(_inner.captionsTimeoutId);
            _inner.captionsTimeoutId = null;
        }
        initEventListeners(){
            if(!this.video || this.isFileShare) return
            debugI.debug('PLAYER: initEventListeners()')
            this.video.on('play',()=>{
                if(this.video.streamTools && this.video.streamTools.togglePlayButton)
                    this.video.streamTools.togglePlayButton.play();
                if(livekitI.isOwner(this.id) && this.isFileShare) {
                    setTimeout(()=>{
                        busI.notifyEvent(busI.EVENTS.TRANSCRIPTION_INSTANCE_STATE_CHANGED, {id: this.id, active: true});
                    });
                }
                if(_inner.DISABLE_PARTICIPANTS_CONTROLS && [service.getSharedVideoId()].indexOf(this.id)==-1) {
                    return
                };
                let payload = {
                    id:this.id,
                    state:"play",
                    isFileShare: this.isFileShare,
                    currentTime:this.getCurrentTime()
                }
                service.send(payload);
            })
            this.video.on('pause',()=>{
                if(this.video.streamTools && this.video.streamTools.togglePlayButton)
                    this.video.streamTools.togglePlayButton.pause();
                if(service.isOwner())
                    busI.notifyEvent(busI.EVENTS.TRANSCRIPTION_INSTANCE_STATE_CHANGED, {id: this.id, active: false});
                if(_inner.DISABLE_PARTICIPANTS_CONTROLS &&
                    [service.getSharedVideoId()].indexOf(this.id)==-1) {
                        return
                    };
                this.stopCaptionsHandler();
                let payload = {
                    id:this.id,
                    state:"pause"
                }
                service.send(payload);
            })
            this.video.on('seeked',(event)=>{
                if(_inner.DISABLE_PARTICIPANTS_CONTROLS && this.id !== service.getSharedVideoId()) return;
                debugI.debug('EVENTS: seeking')
                this.stopCaptionsHandler();
                let payload = {
                    id:this.id,
                    state:"seeking",
                    isPlaying:this.isPlaying(),
                    currentTime:this.getCurrentTime()
                }
                service.send(payload)
            })
        }
        dispose(){
            _inner.captions = {};
            this.stopCaptionsHandler();
            if(!this.video) return
            debugI.debug('PLAYER: dispose()')
            try{
                this.video.dispose()
                this.video = null;
            }catch(err){ debugI.debug('ERROR',err); }
        }
    }
    window.HELP_IMPROVE_VIDEOJS = false;
    service.init=(options)=>{
        if(typeof options !== "object") return
        if(options.callbacks && typeof options.callbacks.onNewVideo === "function"){
            _inner.onNewVideo = options.callbacks.onNewVideo;
        }
        if(options.callbacks && typeof options.callbacks.onDestroy === "function"){
            _inner.onDestroy = options.callbacks.onDestroy;
        }
        let _participant =  callI.getActiveParticipant();
        _inner.participant = { name:_participant.name , account_profile_id:_participant.account_profile_id , mre_call_participant_id:_participant.mre_call_participant_id}
        _inner.players = {};
        _inner.isLoading.video_file = false;
        _inner.isLoading.video_link = false;
        window.clearTimeout(_inner.captionsTimeoutId);
        _inner.captionsHandler = null;
        _inner.notifyUI();
    }
    service.getStreamTools = (id, wrapper) => {
        if(!id || !wrapper)
            return;
        let streamTools = document.createElement("div");
        streamTools.classList.add("stream-tools", id);

        let topRow =  document.createElement("div");
        topRow.classList.add("stream-tool", "showonhover", "tool-btns");

        const pinButton = document.createElement("button");
        pinButton.setAttribute('containerId', id);
        pinButton.classList.add('if-show-pin');
        pinButton.innerHTML = `<span class="material-icons">push_pin</span>`;
        pinButton.addEventListener('click',()=>{
            if(streamI.isElementPinned(wrapper)){
                service.onUnpinWrapper(wrapper)
            }else{
                streamI.pinView(wrapper)
            }
        })
        topRow.appendChild(pinButton)

        let transcriptionButton = service.getTranscriptionButton();
        transcriptionButton.classList.add('display-none');
        topRow.appendChild(transcriptionButton);

        if(id === service.getSharedVideoId() || id === service.getSharedFileId()) {
            const stopButton = service.getStopButton(id);
            streamTools.stopButton = stopButton;
            topRow.appendChild(stopButton);

            const togglePlayButton = service.getTogglePlayButton(id);
            streamTools.togglePlayButton = togglePlayButton;
            topRow.appendChild(togglePlayButton);
        }


        const muteButton = service.getMuteButton(id);

        topRow.appendChild(muteButton);

        const fullScreenButton = service.getFullscreenButton(wrapper);
        topRow.appendChild(fullScreenButton);

        let bottomRowWrapper = document.createElement("div");
        bottomRowWrapper.classList.add("flex-col");
        let bottomRow =  document.createElement("div");
        bottomRow.classList.add("stream-tool", "call-btns", "hide-onfullscreen");

        streamTools.appendChild(topRow);

        bottomRowWrapper.appendChild(bottomRow);

        streamTools.bottomRowWrapper = bottomRowWrapper;
        streamTools.bottomRowWrapper.isSharedVideo = true;

        streamTools.appendChild(bottomRowWrapper);

        streamTools.muteButton = muteButton;

        streamTools['onfullscreenchange'] = ()=>{
            if(!transcriptionButton)
                return
            if (screenI.isOnFullScreen()) {
                let el = screenI.getFullScreenElement();
                if(el && el.bottomRowWrapper && el.bottomRowWrapper.isSharedVideo)
                    transcriptionButton.classList.remove('display-none');
            } else {
                transcriptionButton.classList.add('display-none');
            }
          }
        busI.unregisterEvent(busI.EVENTS.ON_DISPATCH_FULL_SCREEN_CHANGE, streamTools['onfullscreenchange']);
        busI.registerEvent(busI.EVENTS.ON_DISPATCH_FULL_SCREEN_CHANGE, streamTools['onfullscreenchange']);

        return streamTools;
    }

    service.getFullscreenButton = (container) => {
        let fullScreenButton = _inner.utils.factory('button',{},{padding:'8px',cursor:'pointer'});
        fullScreenButton.innerHTML = `<i class='bx bx-fullscreen'></i>`;
        fullScreenButton.handleClick = ()=>{
            if(!screenI.isOnFullScreen())
                screenI.requestFullscreen(container)
                .then(()=>{
                    streamI.onExitPictureInPicture().then(()=>{}, ()=>{});
                }, ()=>{});
            else
                screenI.exitFullscreen();
        }
        fullScreenButton.removeEventListener('click', fullScreenButton.handleClick);
        fullScreenButton.addEventListener('click', fullScreenButton.handleClick);
        return fullScreenButton;
    }

    service.getTranscriptionButton = () => {
        let btn = _inner.utils.factory('button',{},{padding:'8px',cursor:'pointer'});
        btn.innerHTML = `<i class="bx bx-text"></i>`;
        btn.handleClick = ()=>{
            let transcription = transcriptionI.getUIBind();
            transcription.toggle();
        }
        btn.removeEventListener('click', btn.handleClick);
        btn.addEventListener('click', btn.handleClick);
        return btn;
    }

    service.getTogglePlayButton = (playerId)=>{
        let button = _inner.utils.factory('button',{},{padding:'8px',cursor:'pointer'});
        button.play = ()=>{
            button.innerHTML = `<i class='bx bx-pause'></i>`;
        }
        button.pause = ()=>{
            button.innerHTML = `<i class='bx bx-play'></i>`;
        }
        button.handleClick = (e)=>{
            e.stopImmediatePropagation();
            let player = service.getPlayerById(playerId);
            if(!player)
                return;
            if(player.isPaused()) {
                player.play();
            } else {
                player.pause();
            }
        }
        button.pause();
        button.removeEventListener('click', button.handleClick);
        button.addEventListener('click', button.handleClick);
        return button;
    }

    service.getStopButton = (playerId)=>{
        let stopButton = _inner.utils.factory('button',{},{padding:'8px',cursor:'pointer'});
        stopButton.innerHTML=`<i class='bx bx-stop'></i>`;
        stopButton.handleStop = ()=>{
            service.destroy(playerId)
        }
        stopButton.removeEventListener('click', stopButton.handleStop);
        stopButton.addEventListener('click', stopButton.handleStop);
        return stopButton;
    }
    service.onMuteChange = (playerId,isMuted)=>{
        if(!playerId) return
        busI.notifyEvent(busI.EVENTS.TRANSCRIPTION_INSTANCE_STATE_CHANGED, {id: playerId, active: isMuted? false: true});
    }
    service.getMuteButton = (playerId)=>{
        if(!playerId) return
        let muteButton = _inner.utils.factory('button',{},{padding:'8px',cursor:'pointer'});
        muteButton.mute = (mute)=>{
            muteButton.innerHTML = mute? `<i class='bx bxs-volume-mute'></i>`: `<i class='bx bxs-volume-full'></i>`;
            muteButton.isMuted = mute? true: false;
        }
        muteButton.handleClick = (e)=>{
            e.stopImmediatePropagation();
            let isMuted = !muteButton.isMuted;
            if(livekitI.isOwner(playerId)){
                const container = document.getElementById(playerId)
                if(container) {
                    const video = container.querySelector('video');
                    if(video) {
                        video.muted = isMuted;
                    }
                }
            }
            livekitI.getOrSetParticipantMuted(playerId,isMuted)
            muteButton.mute(isMuted);
            service.onMuteChange(playerId, isMuted);
            service.updatePlayerDueToSpeakerChange(service.getUIPlayerById(playerId), isMuted);
        }
        muteButton.mute(false);
        muteButton.removeEventListener('click', muteButton.handleClick);
        muteButton.addEventListener('click', muteButton.handleClick);
        return muteButton;
    }
    service.send=(payload)=>{
        if(typeof payload !== "object" || payload && !payload.id) return
        if(payload.isFileShare) return;
        debugI.debug('SEND',payload);
        payload.participant = callI.getActiveParticipant()
        payload.videoShare = true;
        payload.event = 'videoshare';
        livekitI.sendData(null,payload)
    }
    service.onreceive=(payload)=>{
        if(typeof payload !=="object")
            return;

        debugI.debug('ONRECEIVE',payload);
        if(payload.isFileShare){
            // add player object in case of shared video file
            const isOwner = livekitI.isOwner(payload.id);
            if(!isOwner && !_inner.players[payload.id]){
                _inner.players[payload.id] = {
                    player: null,
                    wrapper: null,
                    participant:payload.participant? payload.participant : null,
                    payload:payload,
                    streamTools:null
                }
                _inner.notifyUI();
            }
            return
        }
        if(payload.sync && payload.id) {
            //it means the owner of the player
            if([service.getSharedVideoId(), service.getSharedFileId()].indexOf(payload.id)>-1) {
                const mPlayer = service.getPlayerById(payload.id);
                if(!mPlayer) {
                    service.destroy(payload.id);
                    return;
                }
                service.send({sync: true, currentTime: mPlayer.getCurrentTime(), playing: mPlayer.isPlaying()});
                return;
            } else {
                const player = service.getPlayerById(payload.id);
                if(!player) {
                    return;
                }
                if(+payload.currentTime === +payload.currentTime)
                    player.setCurrentTime(payload.currentTime);
                if(player.isPlaying() && !payload.playing) {
                    player.pause();
                } else if(!player.isPlaying() && payload.playing) {
                    player.play();
                }
            }
            return;
        }
        if(livekitI.isOwner(payload.id)) return
        // if(payload.id === service.getSharedVideoId()) {
        //     return;
        // }
        if(payload.destroy){
            service.destroy(payload.id);
            return
        }
        if(payload.newVideo && !service.getPlayerById(payload.id)){
            service.onRenderVideo(payload).then(()=>{}, (err)=>{})
            .finally(()=>{
                livekitI.onSpeakerStateChange();
                streamI.MainCallsContainer();
            })
            return;
        }
        if(payload.state){
            service.onstatechange(payload);
            return;
        }
    }
    service.onstatechange=(payload)=>{
        if(!payload) return;
        debugI.debug('ONSTATECHANGE',payload)
        const player = service.getPlayerById(payload.id);
        if(!player) return
        switch(payload.state){
            case "pause":
                player.pause();
                break;
            case "play":
                if(+payload.currentTime === +payload.currentTime)
                    player.setCurrentTime(payload.currentTime)
                player.play();
                break;
            case "seeking":
                if(+payload.currentTime === +payload.currentTime)
                    player.setCurrentTime(payload.currentTime)
                if(payload.isPlaying && player.isPaused()){
                    player.play();
                };
            break;
        }
    }
    service.onAppendToTargetContainer = (wrapper) => {
        return new Promise((resolve, reject)=>{
            if(!wrapper) {
                reject();
                return;
            }
            let attempt = 0;
            function append(time) {
                setTimeout(()=>{
                    attempt++;
                    if(attempt > 5) {
                        reject()
                        return;
                    }
                    let targetContainer = document.getElementById(_inner.currentTargetContainerId? _inner.currentTargetContainerId: _inner.targetContainerId);
                    if(!targetContainer) {
                        append(time + 100);
                        return;
                    }
                    targetContainer.appendChild(wrapper);
                    streamI.unpinAllViews()
                    streamI.pinView(wrapper)
                    resolve(targetContainer);
                }, time)
            }
            append(0);
        });
    }
    service.onRenderVideo = (payload) => {
        return new Promise(async (resolve, reject)=>{
            if(!payload || !payload.id) {
                reject();
                return;
            }
            debugI.debug('RENDERVIDEO',payload)
            if(service.getPlayerById(payload.id))
                service.destroy(payload.id);

            setTimeout(()=>{
                const isMuted = speakerI.isEnabled() ? false : true;
                const isAutoPlay = payload.resume&&payload.autoplay? true: false;
                let options = {
                    preload: "auto",
                    muted: isMuted
                };

                options.autoplay = isAutoPlay;

                options.controlBar = {
                    fullscreenToggle: false,
                    playToggle: false
                }
                options.bigPlayButton = false;
                const isOwner = livekitI.isOwner(payload.id);
                if(_inner.DISABLE_PARTICIPANTS_CONTROLS && !isOwner){
                    options.controlBar.progressControl = false;
                }
                if(payload.isYoutube){
                    options.techOrder = ['youtube'];
                    let params = '?controls=0&disablekb=1&fs=0&iv_load_policy=3&modestbranding=1&rel=0&showinfo=0';
                    params = '?controls=0';
                    options.sources = [{ type: 'video/youtube', src: `https://www.youtube-nocookie.com/embed/${payload.youtubeId}` + params}];
                    options.loadingSpinner = false;
                    options.youtube = { "iv_load_policy": 3, "controls": 0, "disablekb": 1, "modestbranding": 1, "rel": 0, "showinfo": 0};
                    options.youtube = { "controls": 0};
                }else{
                    const kinds = ['mp3','wav','aac','wma','mp4','webm'];
                    const kind = payload.url? kinds.find(k => payload.url.indexOf(k) > -1): '';
                    const type = ['mp3','wav','aac','wma'].indexOf(kind) > -1 ? 'audio' : 'video';
                    options.sources = [{ type:`${type}/${kind?kind:'mp4'}`, src: payload.url}]
                }

                let wrapper = _inner.utils.factory('div',{
                    id: _inner.DOM_CONTAINER_EL_PREFIX + payload.id,
                    class: streamI.getVideoCssClass() + " shared-video"
                },{
                    position:'relative',
                    overflow:'hidden'
                })
                wrapper.setAttribute('data-video-share',"")
                debugI.debug('OPTIONS',options)
                let video = _inner.utils.factory('video',{
                    id:payload.videoId,
                    controls:"controls",
                    class:"video-js vjs-theme-sea"
                },{
                    width:'100%',
                    height:'100%'
                });
                video.style.pointerEvents = !isOwner ? "none" : "all"
                let streamTools = service.getStreamTools(payload.id, wrapper);

                if(!streamTools) {
                    service.destroy(payload.id);
                    reject();
                    return;
                }
                if(payload.isAudio){
                    const audio = service.getAudioIcon(true);
                    wrapper.appendChild(audio)
                }
                wrapper.appendChild(video);

                wrapper.bottomRowWrapper = wrapper;
                wrapper.bottomRowWrapper.isSharedVideo = true;

                wrapper.appendChild(streamTools);

                wrapper.addEventListener('mouseleave', ()=>{
                    streamTools.classList.add('visibility-hidden');
                });
                wrapper.addEventListener('mouseenter', ()=>{
                    streamTools.classList.remove('visibility-hidden');
                });

                service.onAppendToTargetContainer(wrapper)
                .then((parentElement)=>{
                    let player = _inner.videoJs(document.getElementById(payload.videoId),options);
                    if(!isOwner){
                        player.handleTechClick_ = function() {};
                    }
                    if(isOwner) {
                        player.bufferedEnd = (e)=>{
                            let mPlayer = service.getPlayerById(payload.id);
                            if(mPlayer && !payload.isFileShare)
                                service.send({sync: true, currentTime: mPlayer.getCurrentTime(), playing: mPlayer.isPlaying()});
                        }
                    }else{
                        player.boundHandleTechClick_ = (e)=>{}
                        player.boundHandleTechTap_ = (e)=>{}
                        player.boundUpdateCurrentBreakpoint_ = (e)=>{}
                        player.boundHandleTechTouchStart_ = (e)=>{}
                        player.boundHandleTechTouchEnd_ = (e)=>{}
                        player.boundHandleTechTouchMove_ = (e)=>{}
                    }
                    player.streamTools = streamTools;
                    player.parentContainer = parentElement;
                    player.pause();
                    let nowBeforeLoadMeta = Date.now();
                    let handled = false;
                    function ok() {
                        handled = true;
                        if(!isAutoPlay){
                            player.pause();
                        }else{
                            player.play();
                        }
                        //ask for sync
                        if(payload && [service.getSharedFileId(),service.getSharedVideoId()].indexOf(payload.id) === -1){
                            service.send({sync: true, id: payload.id});
                        }
                        _inner.notifyUI();
                        resolve();
                    }
                    player.on('ready', (res) => {
                        if(!_inner.players[payload.id]) {
                            _inner.players[payload.id] = {
                                player: new Player(player, payload.id, payload.isYoutube? 'youtube' : null,payload.isFileShare?true:false),
                                wrapper: wrapper,
                                participant:payload.participant? payload.participant : null,
                                payload:payload,
                                streamTools: player.streamTools
                            }
                            if(typeof _inner.onNewVideo === "function"){
                                _inner.onNewVideo();
                            }
                            player.muted(isMuted);

                            if(+payload.currentTime === +payload.currentTime) {
                                let mPlayer = service.getPlayerById(payload.id);
                                payload.currentTime = payload.currentTime + ((Date.now() - nowBeforeLoadMeta) / 1000);
                                mPlayer.setCurrentTime(payload.currentTime);
                            }
                            _inner.notifyUI();
                        }
                    });
                    player.on('canplay', (res) => {
                        setTimeout(()=>{
                            if(!handled)
                                ok();
                        }, 500);
                    });
                    player.on('canplaythrough', (res) => {
                        setTimeout(()=>{
                            if(!handled)
                                ok();
                        });
                    });
                    player.on('loadeddata', (res) => {
                        setTimeout(()=>{
                            if(!handled)
                                ok();
                        }, 500);
                    });
                    player.on('loadedmetadata', (res) => {
                        let player = service.getPlayerById(payload.id);
                        if(player)
                            player.initMetaData();
                        setTimeout(()=>{
                            if(!handled)
                                ok();
                        }, 500);
                    });
                    player.on('error', (res) => {
                        service.destroy(payload.id);
                        toastI.error("Error while loading video");
                        service.enableSharing();
                        reject();
                    });
                    player.on('playerresize', (res) => {
                        if(!res.target)
                            return;
                        let mPlayer = service.getPlayerById(payload.id);
                        if(!mPlayer)
                            return;
                        let container = document.querySelector('#'+_inner.DOM_CONTAINER_EL_PREFIX + mPlayer.id);
                        if(!container)
                            return;
                        let newParent = container.parentElement;
                        if(!newParent || newParent === player.parentContainer)
                            return;
                        player.parentContainer = newParent;
                        _inner.currentTargetContainerId = player.parentContainer.id;
                        let currentTime = player.currentTime();
                        let isPlaying = mPlayer.isPlaying();
                        if(mPlayer.isYoutubePlayer()) {
                            setTimeout(()=>{
                                try {
                                    service.destroy(mPlayer.id, false);
                                } catch (error) {
                                }
                                setTimeout(()=>{
                                    service.onSetupVideo(payload.url, payload, {autoplay: isPlaying, resume: true, currentTime: currentTime})
                                    .then(() => {}, ()=>{});
                                });
                            });
                        }
                    });
                }, ()=>{
                    service.destroy(payload.id);
                    reject();
                });
            });
        })
    }
    service.onSetupVideo = (url, payload, extras = {}) => {
        return new Promise((resolve, reject) => {
            if(!url) {
                reject();
                return;
            }
            for(let k in extras)
                payload[k] = extras[k];
            service.onRenderVideo(payload)
            .then(()=>{
                resolve(payload);
            }, ()=>{
                reject();
            }).finally(()=>{
                livekitI.onSpeakerStateChange();
                streamI.MainCallsContainer();
            })
        });
    }
    service.shareVideo=(url=_inner.selectedURL) => {
        if(!url) return;
        const isYoutube = _inner.utils.isYoutubeURL(url);
        _inner.isLoading.video_link = true;
        _inner.notifyUI();
        const payload = {
            url:url,
            isYoutube:isYoutube,
            newVideo:true
        }
        if(isYoutube)
            payload.youtubeId = _inner.utils.getYoutubeVideoId(url);
        // service.onLoadCaptions(payload,null).then(()=>{},()=>{})
        payload.participant = _inner.participant;
        payload.id = service.getSharedVideoId();
        payload.videoId = service.getSharedVideoId();
        payload.videoShare = true;
        service.onSetupVideo(url, payload)
        .then((payload)=>{
            _inner.selectedURL = null;
            service.send(payload);
            callI.onSendAttachment({Url: url}, 'videoshare').then(()=>{}, ()=>{});
        }, (err)=>{
            debugI.debug(err)
        }).finally(()=>{
            _inner.isLoading.video_link = false;
            _inner.notifyUI();
        });
    }
    service.onUnpinWrapper = (wrapper)=>{
        if(!wrapper) return
        streamI.unpinView(wrapper)
        livekitI.handlePinParticipant(wrapper.id)
    }
    service.destroy = (id, update = true) => {
        if(!id) return
        debugI.debug('DESTROY',id);
        const isOwner = livekitI.isOwner(id);
        const playerInfo = _inner.players[id] ? _inner.players[id] : null;
        if(!playerInfo) return
        _inner.captions = {};
        const player = playerInfo&&playerInfo.player ? playerInfo.player : null;
        const payload = playerInfo&&playerInfo.payload&&playerInfo.payload ? playerInfo.payload : {}
        const wrapper = playerInfo&&playerInfo.wrapper ? playerInfo.wrapper : document.querySelector('#'+_inner.DOM_CONTAINER_EL_PREFIX + id);
        if(streamI.isElementPinned(wrapper)) service.onUnpinWrapper(wrapper)
        const isFileShare = playerInfo.isFileShare || payload.isFileShare;
        if(isFileShare && id === service.getSharedFileId()){
            service.clearSharedFileInfo();
        }
        if(player && typeof player.dispose === "function")
            try{ player.dispose(); }catch(err){ debugI.debug('ERROR',err); }
        if(wrapper) {
            if(wrapper==screenI.getFullScreenElement())
                screenI.exitFullscreen();
            try {
                wrapper.remove();
            } catch(err){ debugI.debug('ERROR',err); }
        }

        delete _inner.players[id];
        if(typeof _inner.onDestroy === "function")
            _inner.onDestroy();
        if(service.isActive()){
            busI.notifyEvent(busI.EVENTS.TRANSCRIPTION_INSTANCE_STATE_CHANGED, {id: id, active: false});
            service.enableSharing();
        }
        if(update && isOwner){
            service.send({id:id,destroy:true});
        }
        _inner.notifyUI();
    }
    service.enableSharing = () => {
        _inner.selectedURL = null;
        service.onURLChange(_inner.selectedURL);
        _inner.isLoading.video_link = false;
        _inner.isLoading.video_file = false;
        setTimeout(()=>{
            _inner.notifyUI();
        })
    }
    service.clear = ()=>{
        for(let id in _inner.players){
            try{ service.destroy(id) }catch(err){ debugI.debug('ERROR',err); }
        }
        _inner.selectedURL = null;
        _inner.players = {};
        service.clearSharedFileInfo();
        _inner.ui = null;
    }
    service.clearSharedFileInfo=()=>{
        if(_inner.stream instanceof MediaStream){
            livekitI.onTogglePublishFileShareStream(false,_inner.stream).then(()=>{},()=>{})
            _inner.stream = null
        }
        if(_inner.audioContext){
            _inner.audioContext.close();
            _inner.audioContext = null;
        }
        if(_inner.blobUrl){
            window.URL.revokeObjectURL(_inner.blobUrl)
        }
        _inner.blobUrl = null;
        _inner.localMedia = null;
        _inner.connection = null;
        _inner.hasFileShare = false;
        _inner.notifyUI();
    }
    service.getSharedFileInfo = ()=> ({localMedia:_inner.localMedia,connection:_inner.connection,blobUrl:_inner.blobUrl})
    service.isActive = ()=>{
        let check = false;
        for(let id in _inner.players){
            check = livekitI.isOwner(id);
            if(check) break
        }
        return check;
    }
    service.getUIBind = ()=>{
        if(_inner.ui) return _inner.ui;
        _inner.ui = {
            isActive:service.isActive(),
            isDrawerOpen: false,
            isEnabled: true,
            isValidURL:_inner.isValidURL,
            selectedURL:_inner.selectedURL,
            toggleDrawer: ()=>{
                _inner.ui.isDrawerOpen = !_inner.ui.isDrawerOpen;
                if(_inner.ui.isDrawerOpen) {
                    setTimeout(()=>{
                        let enable = true;
                        if(_inner.players)
                            for(let id in _inner.players) {
                                const isOwner = livekitI.isOwner(id);
                                //we avoid muting here if owner
                                if(isOwner) {
                                    enable = false;
                                    break;
                                }
                            }
                        if(enable) {
                            service.enableSharing();
                        }
                    })
                }
            },
            closeDrawer: ()=>{
                _inner.ui.isDrawerOpen = false;
            },
            destroy:service.destroy,
            selectPlatform:service.selectPlatform,
            onURLChange:service.onURLChange,
            shareVideo:service.shareVideo,
            isLoading:_inner.isLoading,
            destroy:service.destroy,
            loadLanguageModal: false,
            players: [],
            onChangeVideoLanguage: (p, code) => {
                if(!p || !p.id || !code)
                    return;
                let player = service.getPlayerById(p.id);
                if(!player)
                    return;
                player.selectedLanguageCode = code;
                if(transcriptionI.isInstanceActiveById(player.id))
                    transcriptionI.onRestartInstance(player.id);
                _inner.notifyUI();
            },
            openSelectLanguagePlayer: (player) => {
                if(!player)
                  return;
                modalI.registerData({
                  id: langI.MODALS.SELECT_LANGUAGE_MODAL_ID,
                  data: {
                    languages: langI.getActiveUserLanguages()
                  }
                });
                _inner.ui.loadLanguageModal = true;
                setTimeout(()=>{
                  modalI.init();
                  modalI.openModal({
                    id: langI.MODALS.SELECT_LANGUAGE_MODAL_ID,
                    onClose: (data) => {
                        setTimeout(()=>{
                            if(_inner.ui)
                              _inner.ui.loadLanguageModal = false;
                          }, 250);
                        if(data && data.selectedLang && data.selectedLang.code) {
                            _inner.ui.onChangeVideoLanguage(player, data.selectedLang.code);
                        }
                    }
                  })
                });
            },
            updatePlayerVolume: (obj, val)=>{
                if(+val!==+val || !obj)
                    return;

                obj.currentVolume = +val;

                if(obj.isFileShare){
                    livekitI.getOrSetParticipantVolume(obj.id,obj.currentVolume)
                }
                let player = service.getPlayerById(obj.id);
                if(player) {
                    player.currentVolume = obj.currentVolume;
                    if(player && player.video) {
                        player.video.volume(obj.currentVolume);
                    }
                }
                if(Array.isArray(_inner.ui.players)) {
                    for(let i=0; i<_inner.ui.players.length; i++) {
                        if(_inner.ui.players[i].id===obj.id) {
                            _inner.ui.players[i].currentVolume = obj.currentVolume;
                            break;
                        }
                    }
                }
            },
            runUpload: service.runUpload,
            hasFileShare:_inner.hasFileShare
        }
        return _inner.ui;
    }
    _inner.notifyUI = ()=>{
        service.getUIBind();
        _inner.ui.isActive = service.isActive()
        _inner.ui.isValidURL = _inner.isValidURL;
        _inner.ui.selectedURL = _inner.selectedURL;
        _inner.ui.isLoading = _inner.isLoading;
        _inner.ui.players = service.getPlayersForUI();
        _inner.ui.hasFileShare = _inner.hasFileShare;
        _inner.ui.isEnabled = true;
    }
    service.getPlayerById = (id)=>{
        if(!id) return;
        return _inner.players[id]&&_inner.players[id].player ? _inner.players[id].player : null;
    }
    service.getUIPlayerById = (id)=>{
        if(!id || !_inner.ui || !Array.isArray(_inner.ui.players))
            return;
        for(let i=0; i<_inner.ui.players.length; i++) {
            if(_inner.ui.players[i].id === id) {
                return _inner.ui.players[i];
            }
        }
    }
    service.getPlayersForUI = ()=>{
        let players = [];
        try {
            for(let key in _inner.players) {
                let player = _inner.players[key];
                let obj = {};
                const id = livekitI.extractId(key);
                if(!player.payload || !id) {
                    continue;
                }
                let participant = player.participant? player.participant: service.getParticipantByPayloadId(player.payload.participantId? player.payload.participantId: player.payload.id);
                if(!participant || !participant.name)
                    continue;
                obj.participantName = participant.name;
                obj.poster = player.player && player.player.video ?  player.player.video.poster_ : '';
                obj.isOwner = livekitI.isOwner(key)
                obj.url = !player.payload.isFileShare ? player.payload.url : ''
                obj.duration =player.player && player.player.duration?  player.player.duration : 'n/a';
                obj.currentVolume = player.player && player.player.currentVolume?   player.player.currentVolume: 1;
                obj.id = player.payload && player.payload.id ? player.payload.id : key;
                obj.selectedLanguageCode = player.player && player.player.selectedLanguageCode? player.player.selectedLanguageCode: player.selectedLanguageCode? player.selectedLanguageCode: participantI.getCurrentUserLanguageCode();
                obj.isFileShare = player.isFileShare || player.payload.isFileShare ? true : false
                players.push(obj);
            }
        } catch (error) {}
        return players;
    }
    service.getParticipantByPayloadId = (id) => {
        if(!id || typeof id !== 'string')
            return;
        let p = id && id.indexOf('-')>-1? participantI.getParticipantById(id): participantI.getParticipantByIdWithoutHyphen(id);
        return p;
    }
    service.getId = ()=>{
        return _inner.participant && _inner.participant.mre_call_participant_id ? _inner.participant.mre_call_participant_id.split('-').join('') : '';
    }
    service.getNormalId = ()=>{
        if(!_inner.participant){
            _inner.participant = callI.getActiveParticipant();
        }
        return _inner.participant && _inner.participant.mre_call_participant_id ? _inner.participant.mre_call_participant_id : ''
    }
    service.setPlayerOnError = (id) => {
        if(!id) return;
        if(_inner.players[id])
            _inner.players[id].onError = true;
    }
    service.isPlayerOnError = (id)=>{
        if(!id) return;
        return _inner.players[id] && _inner.players[id].onError? true: false;
    }
    service.isOwner = () => {
        let currentParticipantId = callI.getActiveParticipantId();
        if(!currentParticipantId)
            return false;
        return (service.getSharedVideoId().indexOf(currentParticipantId) > -1 ||  service.getSharedFileId().indexOf(currentParticipantId) > -1 ) ? true: false;
    }
    service.getPlayersCount = ()=>{
        return Object.keys(_inner.players).length;
    }
    service.resume = ()=>{
        if(!service.isActive()) return
        for(let id in _inner.players){
            const isOwner = livekitI.isOwner(id)
            if(!isOwner) continue;
            let player = _inner.players[id] && _inner.players[id].player ? _inner.players[id].player : null;
            let obj = _inner.players[id] && _inner.players[id].payload ? _inner.players[id].payload : null;
            if(!player || !obj)
                return;
            if(obj.isFileShare) continue;
            let payload = {
                id:id,
                resume: true,
                videoId: obj.videoId,
                targetId: id,
                isYoutube: obj.isYoutube,
                autoplay: player.isPlaying() || !player.isPaused() ? true : false,
                currentTime: player.isPlaying() ? Math.round(player.getCurrentTime()) : player.getCurrentTime(),
                newVideo: true,
                videoShare: true,
                isFileShare: obj.isFileShare? true : false
            }
            if(!payload.isFileShare)
                payload.url = obj.url;

            if(obj.isYoutube)
                payload.youtubeId = _inner.utils.getYoutubeVideoId(obj.url);
            service.send(payload)
        }
    }
    service.onURLChange = (url)=>{
        clearTimeout(_inner.changeUrlTimer);
        _inner.changeUrlTimer = setTimeout(()=>{
            _inner.selectedURL = url && typeof url === 'string' && url.trim()? url: '';
            _inner.isValidURL = _inner.utils.isValidURL(_inner.selectedURL);
            _inner.notifyUI();
        }, 250);
    }
    service.updatePlayerDueToSpeakerChange = (player, isMuted)=>{
        if(!player || !_inner.ui)
            return;
        let previousCurrentVolume = player.previousCurrentVolume;
        if(+previousCurrentVolume !== +previousCurrentVolume)
                previousCurrentVolume = 1;
        if(isMuted) {
            //save previous value to set it back when speaker is enabled again
            previousCurrentVolume = player.currentVolume;
            player.previousCurrentVolume = player.currentVolume;
        }
        _inner.ui.updatePlayerVolume(player, isMuted? 0: previousCurrentVolume);
        let playerObj = _inner.players[player.id];
        if(playerObj && playerObj.streamTools) {
            if(playerObj.streamTools.muteButton
                && typeof playerObj.streamTools.muteButton.mute==='function') {
                    playerObj.streamTools.muteButton.mute(isMuted);
            }
        }
    }
    service.handleSetSpeakerMuted = (isMuted)=>{
        if(service.getPlayersCount() === 0 || typeof isMuted !== "boolean") return;
        for(let id in _inner.players) {
            const isOwner = livekitI.isOwner(id);
            //we avoid muting here if owner
            if(isOwner)
                continue;
            if(_inner.ui)
                if(Array.isArray(_inner.ui.players)) {
                    for(let i=0; i<_inner.ui.players.length; i++) {
                        if(!_inner.ui.players[i])
                            continue;
                        service.updatePlayerDueToSpeakerChange(_inner.ui.players[i], isMuted);
                    }
                }
        }
    }
    service.onLoadCaptions = (payload)=>{
        return new Promise((resolve,reject)=>{
            if(!payload || !payload.isYoutube || !payload.youtubeId || !_inner.API_URL || !_inner.enableCaptions){
                reject();
                return
            }
            _inner.captions = [];
            fetch(`${_inner.API_URL.endsWith('/') ? _inner.API_URL.slice(0,-1) : _inner.API_URL}/captions`,{
                method:"GET",
                headers:{
                    "Content-Type":"application/json",
                    "x-video-id":payload.youtubeId,
                    'x-video-lang': _inner.defaultCaptionsLang ? _inner.defaultCaptionsLang : null
                }
            })
            .then(res=>res.json())
            .then(res=>{
                if(!res || !typeof res.captions === "object"){
                    _inner.captions = {}
                    reject();
                    return
                }
                _inner.captions = res.captions;
                resolve(res);
            },(err)=>{
                reject(err);
            })
        })
    }
    service.getActiveCaptions = ()=>{
        const lang =  participantI.getCurrentUserLanguageCode();
        return Array.isArray(_inner.captions[lang]) ? _inner.captions[lang] : [];
    }
    service.runUpload = (event) => {
        if(_inner.hasFileShare) return
        let file = event.target.files[0];
        if(!file) return
        _inner.isLoading.video_file = true;
        _inner.notifyUI();
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async ()=>{
            event.target.value = null;
            const isAudio = clipboardI.getFileInfo(file.name).type === "audio"
            service.clearSharedFileInfo();
            let buffer = reader.result;
            let videoBlob = new Blob([new Uint8Array(buffer)], { type:'video/mp4' });
            let url = window.URL.createObjectURL(videoBlob);
            _inner.blobUrl = url
            const payload = {
                url:_inner.blobUrl,
                isYoutube:false,
                newVideo:true,
                isAudio
            }
            payload.participant = _inner.participant;
            payload.id = service.getSharedFileId();
            payload.videoId = service.getSharedFileId();
            payload.videoShare = true;
            payload.isFileShare = true;
            service.onRenderVideo(payload).then(()=>{},(err)=>{})
            .finally(()=>{
                livekitI.onSpeakerStateChange();
                service.send(payload)
                setTimeout(service.streamFile,50)
                _inner.hasFileShare = true;
                _inner.isLoading.video_file = false;
                _inner.notifyUI();
                streamI.MainCallsContainer();
            })
        };
        reader.onerror = ()=>{
            _inner.isLoading.video_file = false;
            _inner.notifyUI();
            event.target.value = null;
        };
    }
    service.getDomVideoFileShareId = ()=>_inner.DOM_VIDEO_FILE_SHARE_ID_PREFIX;
    service.getSharedFileId = ()=> {
        return callI.getActiveParticipantId()+livekitI.getConstants().FILE_SHARE_ID_SUFFIX
    }
    service.getSharedVideoId = ()=> `${_inner.DOM_VIDEO_LINK_SHARE_ID_PREFIX}${service.getNormalId()}`;

    service.streamFile = (id = null)=>{
        const player = id && _inner.players[id]? _inner.players[id]: _inner.players[service.getSharedFileId()]
        if(typeof player !== "object") return
        const video = player.wrapper ? player.wrapper.querySelector('video') : null;
        let isReplay = false
        video.addEventListener('ended',()=>{
            isReplay = true;
        })
        video.addEventListener('play',()=>{
            if(isReplay) livekitI.replaceFileShareTracks(video.captureStream())
            isReplay = false
        })
        if(!video) return;
        if(!player.player)
            player.player = {};
        if(_inner.localMedia) {
            _inner.localMedia.stop().then(()=>{}, ()=>{});
            if(_inner.connection)
                _inner.connection.close().then(()=>{}, ()=>{})
        }
        let stream;
        if(player?.payload?.isAudio){
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const source = audioContext.createMediaElementSource(video);
            source.connect(audioContext.destination);
            const destination = audioContext.createMediaStreamDestination();
            source.connect(destination);
            stream = destination.stream;
            _inner.audioContext = audioContext;
        }else{
            stream = video.captureStream();
        }
        if(!stream) return
        player.player.stream = stream;
        livekitI.onTogglePublishFileShareStream(true,stream).then(()=>{},()=>{})
        _inner.stream = stream;
    }
    service.reshareStreamFileForTranscription = (id) => {
        if(!id)
            return;
        service.streamFile(id);
        setTimeout(()=>{
            busI.notifyEvent(busI.EVENTS.TRANSCRIPTION_INSTANCE_STATE_CHANGED, {id: id, active: true});
        });
    }
    service.onNewVideoFileShare = (id)=>{
        if(typeof id !== "string") return
        const participant = participantI.getParticipantById(livekitI.extractId(id));
        if(!participant) return
        _inner.players[id] = {
            player: null,
            wrapper: null,
            participant:participant,
            payload:{},
            streamTools:null,
            isFileShare:true,
            isOwner:livekitI.isOwner(id)
        }
        _inner.notifyUI();
    }
    service.addVideoControls = (id,container)=>{
        if(!id || !container) return
        const streamTools = service.getStreamTools(id,container);
        if(!streamTools) return
        container.appendChild(streamTools);
        if(!_inner.players)
            _inner.players = {};
        if(!_inner.players[id])
            _inner.players[id] = {};
        _inner.players[id].streamTools = streamTools;
        if(_inner.players && _inner.players[id]) {

        }
    }
    service.getAudioIcon = (withContainer=false)=>{
        const icon = document.createElement('i')
        icon.classList.add('material-icons','shared-media-placeholder-icon')
        icon.innerHTML = "music_note" // "volume_up"
        if(!withContainer) return icon;
        const container = document.createElement('div')
        container.classList.add('shared-media-placeholder')
        container.appendChild(icon)
        return container
    }
    if(debugI.canDebug()){
        service.getInner = ()=> _inner;
        window.videoShareI = service;
    }
    service.getPlayers = ()=> _inner.players
    return service;
}
export const videoShareI = VideoShareI();