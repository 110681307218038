import { userI } from "src/assets/js/userI";
import { serviceProviderI } from "src/assets/js/serviceProviderI";
import { callI } from "src/assets/js/callI";
import { endpointI } from "./endpointI";
import { busI } from "./busI";
import { participantI } from "./participantI";
import { transcriptionI } from "./transcriptionI";
import { notificationI } from "./notificationI";
import { navbarI } from "./navbarI";
import { langI } from "./langI";
import { toastI } from "./toastI";
import { networkI } from "./networkI";
import { messagingI } from "./messagingI";
import { smartExpertiseI } from "./smartExpertiseI";
import { utilI } from "./utilI";
let ChatI = function () {
    let service = {};
    service.INTERNAL_CHAT_CONTENT = '***INTERNAL***';
    service.SYSTEM_MESSAGE_PARTICIPANT_NAME = '{{TMUPN}}'
    let _inner = {};
    _inner.chats = {};
    _inner.open = {};
    _inner.MAX_PARTICIPANTS_LENGTH = 2;
    _inner.selectedFriend = null;
    _inner.onNotifyLastSeenTimer = 'onNotifyLastSeenTimer';
    _inner.TMP_ACTIVE_CHAT_ID = "TMU.WebApp.TMPActiveChatId"
    _inner.lastParticipantActiveState = {};
    _inner.postMessageTimeout = null;

    service.openChat = function(id) {
      if(!id)
        return;
      if(!_inner.open)
        _inner.open = {};
      _inner.open[id] = {};
      let participant = service.getActiveParticipantByChatId(id);
      if(participant && participant.is_active) {
        service.connectSocket(id, participant.mre_call_participant_id);
      }
    }

    service.updateUnreadChatCounters = () => {
      clearTimeout(_inner.updateUnreadConversationsCounterTimer);
      _inner.updateUnreadConversationsCounterTimer = setTimeout(()=>{
        let counter = 0;
        for(let c in _inner.chats) {
          if(+_inner.chats[c].unreadMessagesCounter>0)
            counter++;
        }
        if(!_inner.ui)
          service.getUIBind();
        _inner.ui.unreadConversationsCounter = +counter>=0? counter: 0;
        const chatsUnreadMessagesCounter = {};
        for(let key in _inner.chats){
          chatsUnreadMessagesCounter[key] = _inner.chats[key].unreadMessagesCounter || 0;
        }
        _inner.ui.chatsUnreadMessagesCounter = chatsUnreadMessagesCounter;
        _inner.ui.chats = _inner.chats
        service.syncUi();
      }, 250);
    }
    service.closeChat = function(id) {
      if(!id)
        return;
      service.disconnectConnectedSocket();
      if(_inner.open[id])
        delete _inner.open[id];
    }
    service.isOpen = function(id) {
      return _inner.open && _inner.open[id]? true: false;
    }
    service.getActiveChat = function() {
      const tmpChat = service.getTmpActiveChat();
      if(tmpChat)
        return tmpChat;
      let id = '';
      if(_inner.open) {
        for(let k in _inner.open) {
          id = k;
          break;
        }
      }
      return service.getChatById(id);
    }
    service.getActiveParticipantByChatId = function(id) {
      if(!id || !_inner.chats || !_inner.chats[id])
        return;
      let participant = null;
      let participants = _inner.chats[id].allParticipants;
      if(Array.isArray(participants)) {
        for(let i=0; i<participants.length; i++) {
          if(participants[i].account_profile_id === userI.getAccountProfileId()) {
            participant = participants[i];
            break;
          }
        }
      }
      return participant;
    }
    service.getActiveParticipantIdByChatId = function(id) {
      if(!id || !_inner.chats || !_inner.chats[id])
        return;
      let participant = service.getActiveParticipantByChatId(id);
      return participant? participant.mre_call_participant_id: '';
    }
    service.onLoadChat = function(id) {
      return new Promise((resolve, reject)=>{
        if(!id || typeof id  !== 'string') {
          reject();
          return;
        }
        networkI.get({url: '/api/chat/'+id})
        .then((res)=>{
          if(Array.isArray(res.errors)) {
            reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
          } else {
            service.updateChats(res.Chats);
            resolve(res);
          }
        }, (error)=>{
            reject(error);
        });
      })
    }
    service.onSendMreCallChat = function(obj, uniqueId = null) {
        return new Promise((resolve, reject)=>{
            if(!obj) {
                reject();
                return;
            }
            let chatId = obj.MreCallID;
            if(!chatId) {
              reject();
              return;
            }
            let payload = {};
            let url = '/api/chat/'+chatId+'/message';
            let headers = {};
            let opt = {};

            if(obj.MreCallParticipantID)
              payload.MreCallParticipantID = obj.MreCallParticipantID;
            payload.MreCallProvider = serviceProviderI.getServiceProviderForApi();

            if(!obj.file) {
              for(let k in obj)
                payload[k] = obj[k];
              if(obj.MreCall)
                payload.MreCall = obj.MreCall;
              if(obj.MreCallID)
                payload.MreCallID = obj.MreCallID;
              if(obj.message) {
                payload.Content = obj.message;
                delete payload.message;
              }
              if(obj.speech_to_text) payload.SpeechToText = obj.speech_to_text;
              if(typeof obj.CanTranslate === 'boolean' && +obj.CanTranslate===0)
                payload.CanTranslate = 0;
              else
                delete payload.CanTranslate;

            } else {
              let fileName = (obj.fileName)? obj.fileName: '';
              //if file already encoded we send it part of the JSON
              if(typeof obj.file === 'string') {
                payload.File = btoa(obj.file);
                payload.FileName = fileName;
                if(obj.message)
                  payload.Content = obj.message;
              } else {
                headers['Content-Type'] = 'application/octet-stream';

                opt.isFile = true;

                payload = obj.file;

                let params = [];
                let extension = '';
                if(fileName.indexOf('.')>-1) {
                  let info = fileName.split('.');
                  if(info.length==2)
                    extension = info[1];
                }
                url += '/file?';
                if(fileName)
                  params.push('fileName='+fileName);
                if(extension)
                  params.push('extension='+extension);

                url += params.join('&');
              }
            }
            opt.data = payload;
            opt.url = url;
            opt.headers = headers;
            networkI.post(opt)
            .then((res)=>{
              if(Array.isArray(res.errors)) {
                reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
              } else {
                service.deleteChatMessageById(chatId, uniqueId);
                if(Array.isArray(res.ChatMessages) && res.ChatMessages.length==1) {
                  res.ChatMessages[0].uniqueId = uniqueId;
                }
                service.updateChats([res]);
                resolve(res);
              }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onTextToSpeech = function(msg) {
        return new Promise((resolve, reject) => {
            if(!msg || !serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            let message = service.getChatMessageById(msg.mre_call_id, msg.mre_call_chat_id? msg.mre_call_chat_id: msg.uniqueId);
            if(!message || !message.mre_call_chat_id) {
              reject();
              return;
            }

            let payload = {
              MreCallProvider: serviceProviderI.getServiceProviderForApi(),
              MreCallChatID: message.mre_call_chat_id
            };

            networkI.post({url: '/api/texttospeech', data: payload})
            .then((res)=>{
              if (Array.isArray(res.errors) || !res.ChatAttachment) {
                reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
              } else {
                service.updateAttachmentMessage(res.ChatAttachment)
                let audio = res.ChatAttachment.attachment_url? res.ChatAttachment.attachment_url:
                            'data:audio/wav;base64,' + res.ChatAttachment.filebytes;
                let a = new Audio(audio);
                a.play().then(() => {}, ()=>{}).finally(()=>{
                    resolve();
                });
              }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.getChats = function() {
      let filtered = [];
      for(let c in _inner.chats) {
        if(!_inner.chats[c].isChatOnly)
          continue;
        filtered.push(_inner.chats[c]);
      }
      return filtered;
    }
    service.getChatById = function(id) {
      if(!id)
        return;
      for(let c in _inner.chats)
        if(c===id)
          return _inner.chats[c];
    }
    service.isChatStopped = function(id) {
      let chat = service.getChatById(id);
      return chat && chat.stop_time? true: false;
    }
    service.updateChats = function(arr) {
      if(!Array.isArray(arr))
        return;
      for(let i=0; i<arr.length; i++) {
        let id = arr[i].MreCallID? arr[i].MreCallID: arr[i].Chat && arr[i].Chat.mre_call_id? arr[i].Chat.mre_call_id: null;
        if(!id)
          continue;
        if(arr[i].Chat && !arr[i].Chat.is_active) {
          service.deleteChatById(id);
          continue;
        }
        let shouldUpdateParticipants = true;
        if(!_inner.chats[id]) {
          _inner.chats[id] = {};
          _inner.chats[id].id = id;
          _inner.chats[id].mre_call_id = id;
          _inner.chats[id].messages = {};
          _inner.chats[id].attachments = {};
          _inner.chats[id].participants = [];
          _inner.chats[id].limitedParticipants = [];
        }
        if(arr[i].Chat) {
          _inner.chats[id].isChatOnly = arr[i].Chat && arr[i].Chat.is_chat_only? true: false;
          _inner.chats[id].icon_url = arr[i].Chat.icon_url;
          _inner.chats[id].name = arr[i].Chat.call_subject;
          _inner.chats[id].description = arr[i].Chat.description;
          _inner.chats[id].LastMessageAt = arr[i].LastMessageAt
        }
        _inner.chats[id].updated_at = arr[i].Chat && arr[i].Chat.updated_at? arr[i].Chat.updated_at: _inner.chats[id].updated_at? _inner.chats[id].updated_at: 0;
        if(Array.isArray(arr[i].MreCallParticipants) && arr[i].MreCallParticipants.length>0) {
          let participants = arr[i].MreCallParticipants;
          participantI.updateParticipants(participants);
          // call updateChatParticipants only the chat object is new
          shouldUpdateParticipants && service.updateChatParticipants(id,participants)
          _inner.chats[id].currentParticipant = participantI.extractParticipantByAccountProfileId(userI.getAccountProfileId(), participants);
          let otherParticipant = participants.find(p=>p.account_profile_id !== userI.getAccountProfileId());
          if(!_inner.chats[id].currentParticipant || (_inner.chats[id].currentParticipant.became_inactive&&!_inner.chats[id].currentParticipant.is_active)) {
            service.deleteChatById(id);
            continue;
          }
          _inner.chats[id].otherParticipant = otherParticipant? participantI.extractParticipantByAccountProfileId(otherParticipant.account_profile_id, participants): {};
        }
        if(Array.isArray(arr[i].ChatMessages) && arr[i].ChatMessages.length>0) {
          for(let j=0; j<arr[i].ChatMessages.length; j++) {
            service.updateChatMessage(arr[i].ChatMessages[j]);
          }
        }
        if(Array.isArray(arr[i].ChatAttachments) && arr[i].ChatAttachments.length>0) {
          for(let j=0; j<arr[i].ChatAttachments.length; j++) {
            service.updateAttachmentMessage(arr[i].ChatAttachments[j]);
          }
        }
      }
    }
    service.sortChatMessages = function(arr) {
      if(!Array.isArray(arr))
        return [];
      return arr.sort((c1, c2)=>{
        return c1.time_sent<c2.time_sent? -1: c1.time_sent>c2.time_sent? 1: 0;
      });
    }
    service.getChatMessages = function(id) {
        if(!id || !_inner.chats[id] || !_inner.chats[id].messages)
          return [];
        let filtered = [];
        for(let k in _inner.chats[id].messages) {
          filtered.push(_inner.chats[id].messages[k]);
        }
        return service.sortChatMessages(filtered);
    }
    service.hasAlreadyChatMessages = (id) => {
      if(!id || !_inner.chats[id] || (!_inner.chats[id].messages&&!_inner.chats[id].attachments))
        return false;
      for(let k in _inner.chats[id].messages){
        if(!_inner.chats[id].messages[k].is_system_message){
          return true;
        }
      }
      for(let k in _inner.chats[id].attachments)
        return true;
      return false;
    }
    service.getChatMessageById = (chatId, messageId) => {
      if(!chatId || !messageId)
        return;
      if(!_inner.chats[chatId] || !_inner.chats[chatId].messages || !_inner.chats[chatId].messages[messageId])
        return;
      return _inner.chats[chatId].messages[messageId];
    }
    service.clearChatMessages = (id)=>{
      if(!id || !_inner.chats[id] || !_inner.chats[id].messages)
        return;
      _inner.chats[id].messages = {};
    }
    service.updateChatUnreadCounter = function(id, counter) {
      if(!id)
        return;
      let chat = service.getChatById(id);
      if(!chat)
        return;
      chat.unreadMessagesCounter = +counter>=0? counter: 0;
      service.updateUnreadChatCounters();
    }
    service.getDateStrFromMessage = (message)=>{
      if(!message || !message.time_sent)
        return '';
      return message.fromatted_date? message.fromatted_date: message.time_sent.toDateString();
    }
    service.updateChatMessage = function(message) {
      if(!message || !message.mre_call_id || (!message.mre_call_chat_id && !message.uniqueId))
        return;
      if(!_inner.chats[message.mre_call_id])
        return;
      if(!_inner.chats[message.mre_call_id].messages)
        _inner.chats[message.mre_call_id].messages = {};
      let id = message.mre_call_chat_id? message.mre_call_chat_id: message.uniqueId;
      if(!id)
        return;

      if(message.time_sent) {
        message.time_sent = new Date(message.time_sent);
        message.fromatted_date = service.getDateStrFromMessage(message);

      }

      let participant = participantI.getParticipantById(message.mre_call_participant_id);
      if(participant) {
        message.avatar_url = participant.avatar_url;
        message.name = participant.name;
        message.participant = participant;
      }
      if(message.is_system_message) {
        message.name = '';
        message.avatar_url = '';
        message.hideHeader = true;
      }
      _inner.chats[message.mre_call_id].messages[id] = message;
      if(message.uniqueId && message.mre_call_chat_id && _inner.chats[message.mre_call_id].messages[message.uniqueId]) {
        _inner.chats[message.mre_call_id].messages[message.uniqueId].mre_call_chat_id = message.mre_call_chat_id;
        _inner.chats[message.mre_call_id].messages[message.uniqueId].updated_at = message.updated_at;
      }
    }
    service.updateAttachmentMessage = function(attachment) {
      if(!attachment || !attachment.mre_call_id)
        return;
      let id = attachment.mre_call_id;
      if(_inner.chats[id] && _inner.chats[id].messages[attachment.mre_call_chat_id]) {
        _inner.chats[id].messages[attachment.mre_call_chat_id].attachment_url = attachment.attachment_url;
        _inner.chats[id].messages[attachment.mre_call_chat_id].is_text_to_speech = attachment.is_text_to_speech;
        _inner.chats[id].messages[attachment.mre_call_chat_id].message_text = attachment.name;
        service.checkAttachmentType(_inner.chats[id].messages[attachment.mre_call_chat_id]);
        _inner.chats[id].attachments[attachment.mre_call_chat_id] = attachment;
      }
    }
    service.getLastMessageFromMessages = function(messages) {
      if(!messages || typeof messages!=='object')
        return 0;
      let message = null;
      for(let k in messages) {
        if(!message || +messages[k].updated_at > +message.updated_at) {
          message = messages[k];
        }
      }
      return message;
    }
    service.getAllChatMessagesAfterUpdatedAt = function(id, updatedAt) {
      if(!id || !_inner.chats || !_inner.chats[id] || !_inner.chats[id].messages)
        return [];
      if(+!(updatedAt>0))
        return service.getChatMessages(id);
      let messages = [];
      for(let k in _inner.chats[id].messages) {
        if(+_inner.chats[id].messages[k].updated_at > +updatedAt) {
          messages.push(_inner.chats[id].messages[k]);
        }
      }
      return service.sortChatMessages(messages);
    }
    service.getMaxUpdatedAtFromMessages = function(messages) {
      let message = service.getLastMessageFromMessages(messages);
      return message && message.updated_at? message.updated_at: 0;
    }
    service.getMaxLastUpdate = function() {
      if(!_inner.chats || !_inner.chats)
        return 0 ;
      let max = 0;
      for(let k in _inner.chats) {
        let chat = _inner.chats[k];
        if(chat.LastReadAt && chat.LastReadAt>max) {
          max = chat.LastReadAt;
        }
        let val = chat.updated_at? chat.updated_at: chat.Chat && chat.Chat.updated_at? chat.Chat.updated_at: 0;
        if(!max || val>max)
          max = val;
        val = chat.messages? service.getMaxUpdatedAtFromMessages(chat.messages): null;
        if(!max || val>max)
          max = val;
      }
      return max>0? max: 0;
    }
    service.isChatMessageExists = function(msg) {
      if(!msg || !msg.mre_call_id)
        return;
      return _inner.chats[msg.mre_call_id] && _inner.chats[msg.mre_call_id].messages
            && (_inner.chats[msg.mre_call_id].messages[msg.uniqueId] || _inner.chats[msg.mre_call_id].messages[msg.mre_call_chat_id])? true: false;
    }
    service.deleteChatById = function(id) {
      if(!id)
        return;
      if(_inner.chats)
        delete _inner.chats[id];
      setTimeout(()=>{
        service.updateUnreadChatCounters();
      });
    }
    service.deleteChatMessageById = function(chatId, id) {
      if(!chatId || !id || !_inner.chats[chatId])
        return;
      if(_inner.chats[chatId].messages && _inner.chats[chatId].messages[id]) {
        delete _inner.chats[chatId].messages[id];
      }
    }
    service.onGetChats = function() {
      return new Promise((resolve, reject)=>{
        let chats = service.getChats();
        if(Array.isArray(chats) && chats.length>0) {
          resolve();
          return;
        }
        service.onLoadMyChats()
        .then((res)=>{
          resolve(res);
        }, (error)=>{
          reject(error);
        });
      });
    }
    service.onGetChatWithFriend = (friend) => {
      return new Promise((resolve, reject)=>{
        if(!friend || !friend.account_profile_id) {
          resolve(null);
        }
        service.onGetChats().then(()=>{
          let chats = service.getChats() || [];
          chats = chats.filter(chat=>!chat.isGroupChat)
          let chat = null;
          for(let i = 0; i < chats.length; i++) {
            if(chat)
              break;
            if(!Array.isArray(chats[i].allParticipants))
              continue;
            let profiles = chats[i].allParticipants.map(item=>item.account_profile_id);
            profiles = profiles.filter((item, index)=> profiles.indexOf(item)===index);
            if(profiles.length!=2)
              continue;
            for (let j = 0; j < profiles.length; j++) {
              if (profiles[j] == friend.account_profile_id) {
                chat = chats[i];
                break;
              }
            }
          }
          resolve(chat);
        }, ()=>{
          resolve(null);
        });
      });
    }
    service.onLoadMyChats = function(max = 0) {
        return new Promise((resolve, reject)=>{
            if(!serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }

            let url = '/api/chat';
            if(+max>0)
              url += '?maxLastUpdate='+max;

            networkI.get({url: url})
            .then((res)=>{
              if(Array.isArray(res.errors)) {
                reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                return;
              }
              service.updateChats(res.Chats);
              if(Array.isArray(res.UnreadMessages)) {
                for(let i=0; i<res.UnreadMessages.length; i++) {
                  if(service.getChatById(res.UnreadMessages[i].MreCallID) && res.UnreadMessages[i].Counter) {
                    service.updateChatUnreadCounter(res.UnreadMessages[i].MreCallID, res.UnreadMessages[i].Counter);
                  }
                }
              }
              if(res.Chats && res.Chats.length>0) {
                busI.notifyEvent(busI.EVENTS.ON_CHATS_UPDATED);
              }
              clearTimeout(_inner.onUpdateMyChatsUpdateTimer)
              _inner.onUpdateMyChatsUpdateTimer = setTimeout(()=>{
                service.onLoadMyChats(service.getMaxLastUpdate()).then(()=>{}, ()=>{});
              }, 35000);
              resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onLoadChatMessages = function(callId) {
      return new Promise((resolve, reject) => {
          if(!callId || typeof callId  !== 'string') {
              reject();
              return;
          }
          setTimeout(()=>{
            networkI.get({url: '/api/chat/'+callId+'/message'})
            .then((res)=>{
              if(Array.isArray(res.errors)) {
                reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
              } else {
                service.updateChats([res]);
                resolve(res);
              }
            }, (error)=>{
                reject(error);
            });
          }, 100);
      })
    }
    service.onStartChat = function(data) {
      return new Promise((resolve, reject)=>{
        let profiles = Array.isArray(data) ? data : [data];
        if(!profiles.length === 0 || !profiles.every(p=>p.account_profile_id)) {
          reject();
          return;
        }

        let payload = {
          MreCallProvider: serviceProviderI.getServiceProviderForApi(),
          MreCall: {
            call_subject: '',
            description: '',
          },
          RecordCall: false,
          IsPrivate: true,
          IsChannel: false,
          IsChatOnly: true,
          AccountProfiles: userI.prepareAccountProfilesForApi(profiles),
          EmailAddresses: null
        };

        networkI.post({url: '/api/chat/start', data: payload})
        .then((res)=>{
          if(Array.isArray(res.errors)) {
            reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
            return;
          }
          service.updateChats(res.Chats);
          resolve(res);
        }, (error)=>{
            reject(error);
        });
      });
    }
    service.notifyNewChatReceived = function(id,msg) {
      if(!id)
        return;
      let chat = service.getChatById(id);
      if(!chat)
        return;
      if(msg && msg.is_system_message) return
      notificationI.playAudio(notificationI.AUDIO_KEYS.NEW_MESSAGE);
      if(!chat.unreadMessagesCounter)
        chat.unreadMessagesCounter = 0;
      service.updateChatUnreadCounter(id, chat.unreadMessagesCounter + 1);
    }
    service.notifyLastSeen = function(id, participantId) {
      if(!id) {
        return;
      }
      let timer = _inner.onNotifyLastSeenTimer+id;
      clearTimeout(timer);
      timer = setTimeout(()=>{
        let chat = service.getChatById(id);
        if(!chat || !Array.isArray(chat.allParticipants) || !chat.messages)
          return;
        let exists = false;
        for(let i=0; i<chat.allParticipants.length; i++) {
          if(chat.allParticipants[i].mre_call_participant_id===participantId) {
            exists = true;
            break;
          }
        }
        if(!exists)
          return;

        let maxChatMessage = service.getMaxUpdatedAtFromMessages(chat.messages);
        if(+maxChatMessage!==+maxChatMessage || maxChatMessage<1 || chat.LastReadAt === maxChatMessage) {
          return;
        }

        let payload = {
          MreCallID: id,
          MreCallParticipantID: participantId,
          LastReadAt: maxChatMessage
        }

        networkI.put({url: '/api/chat/lastseen', data: payload})
        .then((res)=>{
          if(Array.isArray(res.errors)) {
            reject(networkI.getErrorMessageFromArr(res.errors));
            return;
          }
          if(res.MreCallChatRead) {
            chat.LastReadAt = res.MreCallChatRead.last_read_at;
            chat.unreadMessagesCounter = 0;
            service.updateUnreadChatCounters();
          }
        }, (error)=>{
            reject(error);
        });
      }, 250);
    }
    service.onRemoveChat = function(id) {
        return new Promise((resolve, reject)=>{
          let chat = service.getChatById(id);
          if(!chat) {
            reject();
            return;
          }
          if(!chat.isCurrentParticipantAdmin){
            service.onSendMreCallChat({MreCallID: id, MreCallParticipantID: service.getActiveParticipantIdByChatId(id), message: langI.get('CONVERSATION_PARTICIPANT_LEFT',[service.SYSTEM_MESSAGE_PARTICIPANT_NAME])})
            .then(()=>{}, ()=>{});
          }
          let payload = {
            MreCallProvider: serviceProviderI.getServiceProviderForApi(),
            MreCallParticipant: {mre_call_participant_id: service.getActiveParticipantIdByChatId(id)}
          };
          networkI.post({url: '/api/chat/'+id+'/leave', data: payload})
          .then((res)=>{
            if(Array.isArray(res.errors)) {
              reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
            } else {
              service.updateChats(res.Chats);
              resolve(res);
            }
          }, (error)=>{
              reject(error);
          });
      });
  }

  service.onHandleNewChat = function(id) {
    return new Promise((resolve, reject)=>{
      if(!id) {
        reject();
        return;
      }
      if(service.getChatById(id)) {
        resolve();
        return;
      }
      service.onLoadChat(id).then(()=>{
        service.onLoadChatMessages(id)
        .then((res)=>{
          resolve(res);
        },(err)=>{
          reject(err);
        });
      }, (err)=>{
        reject(err);
      });
    });
  }
    service.clear = function() {
      _inner.chats = {
          data: {}
      };
    }
    service.setSelectedFriend = function(friend) {
      _inner.selectedFriend = friend;
    }
    service.getSelectedFriend = function() {
      return _inner.selectedFriend;
    }
    service.treatChatReceived = function(body) {
      if(!body)
        return;
        if(serviceProviderI.hasMissionForm() && body.ChatAttachment && body.FileName === smartExpertiseI.getFileName()){
          busI.notifyEvent(busI.EVENTS.ON_SIGNATURE_RECEIVED, body);
        }
      if(body.ChatMessage || body.ChatAttachment) {
          let chatId = body.ChatMessage && body.ChatMessage.mre_call_id? body.ChatMessage.mre_call_id: null;

          if(!chatId)
            return;
          body.ChatMessage = {
            ...body.ChatMessage,
            participant:body.MreCallParticipant
          }
          if(service.isChatMessageExists(body.ChatMessage)) {
            busI.notifyEvent(busI.EVENTS.ON_CHAT_MSG_RECEIVED, body.ChatMessage);
            return;
          }
          if(body.ChatMessage && body.MreCallParticipant) {
            let chat = service.getChatById(chatId);
            service.onHandleNewChat(chatId).then(()=>{
              if(!chat || chatId !== callI.getActiveCallId())
                busI.notifyEvent(busI.EVENTS.ON_CHATS_UPDATED);
            }, ()=>{}).finally(()=>{
              service.updateChatMessage(body.ChatMessage);
              service.updateAttachmentMessage(body.ChatAttachment);
              busI.notifyEvent(busI.EVENTS.ON_CHAT_MSG_RECEIVED, body.ChatMessage);
              if(callI.getActiveCallId()===chatId) {
                  if(!callI.isChatOpen() && body.ChatMessage.name) {
                    callI.notifyNewChatReceived(chatId);
                  }
              }
              if(chat && chat.isChatOnly) {
                let onChatsScreen = navbarI.getActivePage() === "chats"? true: false;
                if(!onChatsScreen || !service.isOpen(chatId))
                  service.notifyNewChatReceived(chatId,body.ChatMessage);
              }
            });
          }
      } else if(body.MreCallTranscription) {
        transcriptionI.onTranscriptionReceived(body);
      }
    }
    service.handleChatReceived = function(body) {
      if(!body)
        return;
      let message = body.ChatMessage? body.ChatMessage: body.MreCallTranscription? body.MreCallTranscription: body.ChatAttachment;
      if(!message)
        return;
      let participant = participantI.getParticipantByAccountProfileAndCallId(userI.getAccountProfileId(), message.mre_call_id);
      let selectedLanguageId = participantI.getCurrentUserLanguageId(participant);
      if(!(
        ((message.language_id === selectedLanguageId && ((!message.parent_id && !message.parent_mre_call_chat_id) || message.is_translation))
        || (!body.MreCallTranscription && !message.can_translate && !message.is_translation && message.language_id !== selectedLanguageId))
        || body.ChatAttachment)) {
          if(body.MreCallTranscription) {
            //for transcription we allow languages in exception to show up
            if(langI.areLanguageInExceptionForTranslation(message.language_id, selectedLanguageId)) {
              service.treatChatReceived(body);
              return;
            }
          }
          return;
        }
      //ignore message that are not matching user's language and won't translate if user has language legit for translation
      if(body.ChatMessage && message.language_id !== selectedLanguageId && message.parent_mre_call_chat_id) {
        if(!message.can_translate && !message.is_translation) {
          if(!langI.areLanguageInExceptionForTranslation(message.language_id, selectedLanguageId)
          && langI.isLanguageHasTranslation(selectedLanguageId)
          && langI.isLanguageHasTranslation(message.language_id)) {
            //ignore this message
            return;
          }
        }
      }
      service.treatChatReceived(body);
    }
    service.getUIBind = () => {
      if(_inner.ui) {
          _inner.notifyUI();
          return _inner.ui;
      }
      _inner.ui = {
          default_avatar: userI.getDefaultAvatar(),
          unreadConversationsCounter: 0,
          MAX_PARTICIPANTS_LENGTH:_inner.MAX_PARTICIPANTS_LENGTH,
          chats:_inner.chats
      }
      return _inner.ui;
    }
    _inner.notifyUI=()=>{
        if(!_inner.ui)
          service.getUIBind();
          _inner.ui.chats = _inner.chats
    }
    service.isSocketOnChatId = function(id) {
      return id && _inner.socket && _inner.socket.url && _inner.socket.url.indexOf(id)>-1? true: false;
    }
    service.connectSocket = function(id, participantId) {
      if(!id || !participantId || service.isSocketOnChatId(id) || !userI.getAccessToken()) {
        return;
      }
      if(_inner.socket && _inner.socket.url) {
        service.disconnectConnectedSocket();
      }
      let url = endpointI.getWSHost();
      _inner.socket = new WebSocket(url + '/api/chat/ws/'+ id + '/' +participantId, ['Authorization', userI.getAccessToken()]);
      _inner.socket.onmessage = (wsdata) => {
          if (wsdata && wsdata.data) {
              try {
                service.handleChatReceived(JSON.parse(wsdata.data));
              } catch (error) {
              }
          }
      };
      _inner.socket.onerror = (error) =>{
          clearTimeout(_inner.socketReconnectTimer);
          _inner.socketReconnectTimer = setTimeout(()=>{
              if(service.isOpen(id) && participantId===service.getActiveParticipantIdByChatId(id)) {
                service.disconnectConnectedSocket();
                service.connectSocket(id, participantId);
              }
          }, 1000);
      }
      _inner.socket.onopen = (res) =>{
        clearTimeout(_inner.socketReconnectTimer);
      }
      _inner.socket.onclose = (res) =>{
          clearTimeout(_inner.socketReconnectTimer);
          _inner.socketReconnectTimer = setTimeout(()=>{
              if(service.isOpen(id) && participantId===service.getActiveParticipantIdByChatId(id)) {
                service.disconnectConnectedSocket();
                service.connectSocket(id, participantId);
              }
          }, 1000);
      }
    }
    service.disconnectConnectedSocket = function() {
      if(!_inner.socket || !_inner.socket.url)
        return;
      if(_inner.socket.readyState !== _inner.socket.CLOSED && _inner.socket.readyState !== _inner.socket.CLOSING) {
        _inner.socket.close();
      }
      _inner.socket = null;
    }
    service.downloadFile = function(message) {
      if(!message)
        return;
      let link = document.createElement("a");
      try{
        link.href = message.file ? URL.createObjectURL(new Blob([message.file], { type: message.file.type })) : message.attachment_url
      }catch(err){}
      if(!link.href) return
      link.download = message.message_text;
      link.target = "__blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setTimeout(()=>{
        window.URL.revokeObjectURL(link.href)
      },500)
    }
    service.checkAttachmentType = (message)=>{
      if(!message || !message.attachment_url) return
      let extensionMatch = [];
      if(message.fileName){
        extensionMatch = message.fileName.match(/\.[0-9a-z]+$/i);
      }else{
        extensionMatch = message.attachment_url.match(/\.[0-9a-z]+$/i);
      }
      if(message.is_text_to_speech)
        return;
      const extension = Array.isArray(extensionMatch)&&extensionMatch[0] ? extensionMatch.toString().toLowerCase() : "";
      if (['.png','.jpg','.jpeg', '.jpe','.gif','.svg'].indexOf(extension) > -1) {
        message.is_image = true;
      } else if (['.mp3','.m3a','.wav','.oog','.aac'].indexOf(extension) > -1) {
        message.is_audio = true;
      } else if (['.f4v','.m4a','.mp4','.webm','.flv','.mov','.mkv','.avi','.mov','.mpg'].indexOf(extension) > -1) {
        message.is_video = true;
      } else {
        message.is_file = true;
      }
    }
    service.generateTmpMessage=(participant, extraKeys={})=>{
      if(!participant || !participant.mre_call_participant_id)
        return;
      let tmpMessage =  {
        uniqueId: Date.now(),
        "mre_call_participant_id": participant.mre_call_participant_id,
        "time_sent": userI.getCurrentDate(),
        "name": participant.name,
        "language_id": participant.language_id || participantI.getCurrentUserLanguageId(),
        "avatar_url": participant.avatar_url,
        "MreCallParticipant": {
            "mre_call_participant_id": participant.mre_call_participant_id,
            "name": participant.name,
            "avatar_url": participant.avatar_url,
        },
        "mre_call_id": participant.mre_call_id,
        "isFailed":false,
      }
      if(typeof extraKeys === "object"){
        for(let key in extraKeys){
          tmpMessage[key] = extraKeys[key]
        }
      }
      service.checkAttachmentType(tmpMessage);
      return tmpMessage;
    }
    service.getChatParticipantsById = (id)=>{
      return service.getChatById(id)?.participants || [];
    }
    service.onAddNewChatParticipants = (data,chatId)=>{
      return new Promise((resolve,reject)=>{
        let profiles = Array.isArray(data) ? data : [data];
        if(!profiles.length === 0 || !profiles.every(p=>p.account_profile_id) || !chatId) {
          reject();
          return;
        }
        let payload = {
          MreCallProvider: serviceProviderI.getServiceProviderForApi(),
          AccountProfiles: userI.prepareAccountProfilesForApi(profiles)
        };
        networkI.post({url: `/api/chat/${chatId}/participant`, data: payload})
        .then((res)=>{
          if(Array.isArray(res.errors)) {
            reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
            return;
          }
          let message = langI.get('CONVERSATION_PARTICIPANT_ADDED',[service.SYSTEM_MESSAGE_PARTICIPANT_NAME])
          const participant = res.Chats?.[0]?.MreCallParticipants?.find(p=>p.account_profile_id === profiles[0]?.account_profile_id);
          chatI.onSendMreCallChat({MreCallID: chatId, MreCallParticipantID: participant?.mre_call_participant_id, message: message})
          .then(()=>{}, ()=>{})
          .finally(()=>{
            resolve(res)
          })
        }, (error)=>{
            reject(error);
        });
      })
    }
    service.onRemoveChatParticipants = (data,chatId)=>{
      return new Promise((resolve,reject)=>{
        let profiles = Array.isArray(data) ? data : [data];
        if(!profiles.length === 0 || !profiles.every(p=>p.account_profile_id) || !chatId) {
          reject();
          return;
        }
        const participant = profiles[0];
        let payload = {
          MreCallProvider: serviceProviderI.getServiceProviderForApi(),
          ParticipantsToRemove: profiles
        };
        networkI.post({url: `/api/chat/${chatId}/participant`, data: payload})
        .then((res)=>{
          if(Array.isArray(res.errors)) {
            reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
            return;
          }
          let message = langI.get('CONVERSATION_PARTICIPANT_KICKED',[service.SYSTEM_MESSAGE_PARTICIPANT_NAME])
          chatI.onSendMreCallChat({MreCallID: chatId, MreCallParticipantID: participant?.mre_call_participant_id, message: message})
          .then(()=>{}, ()=>{})
          .finally(()=>{
            resolve(res)
          })
        }, (error)=>{
            reject(error);
        });
      })
    }
    service.updateChatParticipants = (mre_call_id,_participants,)=>{
      return new Promise((resolve,reject)=>{
        if(!mre_call_id || !Array.isArray(_participants)) return reject();
        let chat = service.getChatById(mre_call_id);
        if(!chat) return reject();
        let participants = Array.isArray(chat.allParticipants) ? [...chat.allParticipants,..._participants] : [..._participants];
        // removed duplicated values
        participants = removeDuplicatesWithPriority(participants,'mre_call_participant_id')
        chat.allParticipants = participants;
        let currentParticipant = service.getActiveParticipantByChatId(mre_call_id);
        chat.isCurrentParticipantAdmin = currentParticipant?.role_id === userI.ROLE_HOST_PARTICIPANT? true : false;
        if(!chat.isCurrentParticipantAdmin){
          participants.forEach(p=>{
            p.is_active = _inner.lastParticipantActiveState[mre_call_id] && typeof _inner.lastParticipantActiveState[mre_call_id][p.mre_call_participant_id] === "boolean" ? _inner.lastParticipantActiveState[mre_call_id][p.mre_call_participant_id] : p.is_active;
          })
        }
        let activeParticipantId = currentParticipant?currentParticipant.mre_call_participant_id: '';
        chat.participants = participants.filter(p=>p.mre_call_participant_id !== activeParticipantId && p.account_profile_id !== userI.getAccountProfileId());
        // chat.participants = participants.filter(p=>p.mre_call_participant_id);
        chat.activeParticipants = chat.participants.filter(p=>p.is_active)
        chat.inactiveParticipants = chat.participants.filter(p=>!p.is_active)
        chat.limitedParticipants = chat.activeParticipants.slice(0,_inner.MAX_PARTICIPANTS_LENGTH);
        chat.isGroupChat = chat.participants.length >= 2;
        chat.hostParticipant = participants.find(p=>p.role_id === userI.ROLE_HOST_PARTICIPANT);
        chat.participantsNames = chat.activeParticipants.map(p=>p.name || p.screen_name).join(', ')
        return resolve();
      })
    }
    service.clearChat = (mre_call_id)=>{
      if(!mre_call_id) return
      service.updateChatUnreadCounter(mre_call_id,0);
      delete _inner.open[mre_call_id];
      delete _inner.chats[mre_call_id];
    }
    service.onParticipantRemoved = (mre_call_id,mre_call_participant_id)=>{
      return new Promise((resolve,reject)=>{
        if(!mre_call_id || !mre_call_participant_id) return reject();
        const chat = service.getChatById(mre_call_id);
        if(!chat) return reject();
        let participants = chat.allParticipants ? chat.allParticipants.map(p=>{
          if(p.mre_call_participant_id === mre_call_participant_id) return {...p,is_active:false}
          return p;
        }): [];
        service.updateChatParticipants(mre_call_id,participants).then(()=>{},()=>{})
        .finally(()=>{
          busI.notifyEvent(busI.EVENTS.ON_CHATS_UPDATED)
          resolve();
        })
      })
    }
    function removeDuplicatesWithPriority(array, key) {
      const trackingObj = {};
      const uniqueArray = [];
      for (let i = array.length - 1; i >= 0; i--) {
        const obj = array[i];
        const value = obj[key];
        if (!trackingObj.hasOwnProperty(value)) {
          trackingObj[value] = true;
          uniqueArray.unshift(obj);
        }
      }
      return uniqueArray.reverse();
    }
    service.onParticipantAdded = (mre_call_id,participant)=>{
      return new Promise((resolve,reject)=>{
        if(!mre_call_id || !participant) return reject();
        const chat = service.getChatById(mre_call_id);
        if(!chat) return reject();
        let participants = chat.allParticipants ? chat.allParticipants : [];
        participants.push({
          ...participant,
          is_active:true
        })
        service.updateChatParticipants(mre_call_id,participants).then(()=>{},()=>{})
        .finally(()=>{
          busI.notifyEvent(busI.EVENTS.ON_CHATS_UPDATED)
          resolve();
        })
      })
    }
    service.handleChatParticipantAddedOrRemoved = (mre_call_id,participant)=>{
      if(!mre_call_id||!participant) return
      let chat = service.getChatById(mre_call_id);
      const isCurrentUser = participant.mre_call_participant_id === service.getActiveParticipantIdByChatId(mre_call_id) || participant.account_profile_id === userI.getAccountProfileId()? true : false;
      const isChatExist = service.getChatById(mre_call_id)? true : false;
      let promise = isChatExist ? Promise.resolve(1) : service.onGetChats();
      if(!_inner.lastParticipantActiveState[mre_call_id]) _inner.lastParticipantActiveState[mre_call_id] = {}
      _inner.lastParticipantActiveState[mre_call_id][participant.mre_call_participant_id] = participant.is_active? true : false;
      promise.then(()=>{},()=>{})
      .finally(()=>{
        if(participant.is_active){
          if(isCurrentUser){
            if(!isChatExist){
              chat = service.getChatById(mre_call_id);
              toastI.info(langI.get('CONVERSATION_CHAT_STARTED', [chat?.hostParticipant?.name || chat?.hostParticipant?.screen_name]));
            }
          }else{
            service.onParticipantAdded(mre_call_id,participant).then(()=>{},()=>{})
          }
        }else{
          if(isCurrentUser){
            toastI.info(langI.get('CONVERSATION_CHAT_STOPPED', [chat?.hostParticipant?.name || chat?.hostParticipant?.screen_name]));
            service.clearChat(mre_call_id);
          }else{
            service.onParticipantRemoved(mre_call_id,participant.mre_call_participant_id).then(()=>{},()=>{})
          }
        }
        busI.notifyEvent(busI.EVENTS.ON_CHATS_UPDATED)
      })
    }
    service.syncUi = ()=>{
      service.getUIBind();
      window.clearTimeout(_inner.postMessageTimeout);
      _inner.postMessageTimeout = setTimeout(()=>{
        messagingI.postMessageToServiceWorker({
          target:'chatI',
          ui:_inner.ui
        })
      },50)
    }
    service.onGetOriginalChatMessage = (message)=>{
      return new Promise((resolve,reject)=>{
        if(typeof message !== "object") return reject();
        const url = `/api/chat/${message.mre_call_id}/message/${message.mre_call_chat_id}`
        networkI.get({url: url})
        .then((res)=>{
          if(Array.isArray(res.errors) || !res.OriginalChatMessage) {
            let message = networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK');
            toastI.error(message);
            reject(message);
            return;
          }
          resolve(res.OriginalChatMessage);
        }, (error)=>{
          toastI.error(langI.get('ERROR_UNK'));
          reject(error);
        });
      })
    }
    service.onLoadAttachment = function(id) {
      return new Promise((resolve, reject) => {
          let url = '/attachments/'+id;
          networkI.get({url: url})
          .then((res)=>{
              res.blob()
              .then((blob)=>{
                  resolve(blob)
              }, (error)=>{
                  reject()
              });
          }, (error)=>{
              reject(error);
          });
      });
    }
    service.setTmpActiveChatId = (mre_call_id)=>{
      if(!mre_call_id) return
      utilI.updateLocalStorage(_inner.TMP_ACTIVE_CHAT_ID, mre_call_id);
    }
    service.getTmpActiveChatId = () => {
      return utilI.getFromLocalStorage(_inner.TMP_ACTIVE_CHAT_ID);
    }
    service.clearTmpActiveChat = () => {
      utilI.removeFromLocalStorage(_inner.TMP_ACTIVE_CHAT_ID);
    }
    service.getTmpActiveChat = () => {
      return service.getChatById(service.getTmpActiveChatId());
    }
    service.onUpdateGroupAvatar = (chatId,file)=>{
      return new Promise((resolve,reject)=>{
        if(!chatId || !service.getChatById(chatId)) return reject();
        if(file instanceof File || file === ""){
          let opt = {};
          if(file instanceof File){
            opt.isFile = true;
            const formData = new FormData();
            formData.append('file',file)
            opt.formData = formData;
            opt.url = `/api/call/${chatId}/icon`
            opt.headers = {};
            // opt.headers['Content-Type'] = 'application/octet-stream';
          }else{
            opt.url = `/api/call/${chatId}`
            opt.data = {
              MreCallProvider:serviceProviderI.getServiceProviderForApi(),
              IconUrl: ''
            }
          }
          networkI.put(opt)
          .then((res)=>{
            if(Array.isArray(res.errors)) {
              reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
              return;
            }
            service.updateChat(res?.MreCall)
            resolve();
          }, (error)=>{
              reject(error);
          });
        }else{
          reject()
        }
      })
    }
    service.onUpdateGroupInfo = (chatId,name,description)=>{
      return new Promise((resolve,reject)=>{
        if(!chatId) return reject();
        networkI.put({url: '/api/call/'+chatId, data:{
          MreCallProvider: serviceProviderI.getServiceProviderForApi(),
          CallSubject: name || '',
          CallDescription: description || ''
        }})
        .then((res)=>{
          if(Array.isArray(res.errors)) {
            reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
            return;
          }
          service.updateChat(res?.MreCall)
          resolve();
        }, (error)=>{
            reject(error);
        });
      });
    }
    service.onStopTyping = function(id, participantId) {
      return new Promise((resolve, reject)=>{
          if(!id || !participantId) {
              reject();
              return;
          }
          let payload = {
              MreCallProvider: serviceProviderI.getServiceProviderForApi(),
              MreCallParticipant: {mre_call_participant_id: participantId}
          }
          networkI.post({url: '/api/call/'+id+'/participant/stoptyping', data: payload})
          .then((res)=>{
              if (Array.isArray(res.errors)) {
                  reject(networkI.getErrorMessageFromArr(res.errors));
                  return;
              }
              resolve(res);
          }, (error)=>{
              reject(error);
          });
      });
    }
    service.onStartTyping = function(id, participantId) {
        return new Promise((resolve, reject)=>{
            if(!id || !participantId) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallParticipant: {mre_call_participant_id: participantId}
            }
            networkI.post({url: '/api/call/'+id+'/participant/starttyping', data: payload})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.updateChat = (MreCall) =>{
      if(typeof MreCall !== "object") return
      const {mre_call_id,icon_url,call_subject,description} = MreCall;
      if(!_inner.chats[mre_call_id]) return
      _inner.chats[mre_call_id].icon_url = icon_url;
      _inner.chats[mre_call_id].name = call_subject;
      _inner.chats[mre_call_id].description = description;
      _inner.notifyUI();
      busI.notifyEvent(busI.EVENTS.ON_CHATS_UPDATED)
    }
    service.getChatLastTimestamp = (chat)=>{
      if(!chat) return
      let timestamps = [chat.updated_at,chat.LastMessageAt];
      const messages = chatI.getChatMessages(chat.id);
      if(messages.length > 0){
        timestamps.push(...messages.map(message=>message.updated_at))
      }
      timestamps = timestamps.filter(value=>typeof value === "number" && +value !== Infinity && !isNaN(value))
      if(timestamps.length === 0) return 0;
      const max = Math.max(...timestamps);
      return max;
    }
    service.getLastActiveChat = function(chats,withIndex=false){
      if(!Array.isArray(chats) || chats.length === 0) return
      if(chats.length === 1){
        return withIndex ? {chat:chats[0],index:0} : chats[0]
      }
      const tmpChats = [...chats]
      const sorted = [...tmpChats].sort((chatA,chatB)=>{
        return service.getChatLastTimestamp(chatB) - service.getChatLastTimestamp(chatA)
      })
      const firstResult = sorted[0];
      const firstResultIndex = chats.findIndex(chat=>chat.id === firstResult.id)
      if(firstResult && firstResultIndex > -1){
        return withIndex ? {chat:firstResult,index:firstResultIndex} : firstResult;
      }
    }
    return service;
}
export const chatI = ChatI();