import { mediaI } from "./mediaI";
import { busI } from "./busI";
import { widgetI } from "./widgetI";
import { speakerI } from "./speakerI";
import { streamI } from "./streamI";
import { livekitI } from "./livekitI";
import { navbarI } from "./navbarI";
import { debugI } from "./debugI";
import { createLocalAudioTrack , Track, LocalAudioTrack} from 'livekit-client'
var MicI = function () {
    let _inner = {};
    _inner.selected = {};
    _inner.devices = [];
    _inner.isOpen = false;
    _inner.isWaiting = false;
    _inner.hasPermission = false;
    _inner.keyDevice = 'audioinput';
    _inner.stream = null;
    _inner.permissionObj = { mic: true, alreadyPrompt: false };
    _inner.lastDeviceLabelInUseBeforeStop = null;
    _inner.maxTimeToRestoreDevice = 10000;
    _inner.testAudioInputId = 'test-audio-input';
    _inner.onDeviceChange = ()=>{
        _inner.onEnumerateDevices().then(()=>{}, ()=>{}).finally(()=>{
            _inner.notifyUI();
            if(service.isEnabled()&&_inner.selected&&_inner.selected.identifierLabel) {
                let device = _inner.devices.filter(item=>item.identifierLabel==_inner.selected.identifierLabel);
                if(_inner.noLabels) {
                    //we always consider our device not available if we are on a configuration where we don't have labels
                    device = [];
                }
                //it means our device is not available anymore, we notify for stop
                if(device.length===0) {
                    _inner.lastDeviceLabelInUseBeforeStop = _inner.selected.identifierLabel;
                    busI.notifyEvent(busI.EVENTS.ON_REQUEST_STOP_DEVICE, _inner.selected);
                    clearTimeout(_inner.onDeviceRestoreTimer);
                    _inner.onDeviceRestoreTimer = setTimeout(()=>{
                        _inner.attemptToRestoreLastInUseDevice();
                    }, _inner.maxTimeToRestoreDevice);
                }
            }
        });
    }
    _inner.attemptToRestoreLastInUseDevice = () => {
        if(!_inner.lastDeviceLabelInUseBeforeStop || service.isEnabled())
            return;
        _inner.onEnumerateDevices().then(()=>{
            let devices = _inner.devices.filter(item=>item.identifierLabel==_inner.lastDeviceLabelInUseBeforeStop);
            if(devices.length !== 1)
                return;
            busI.notifyEvent(busI.EVENTS.ON_REQUEST_RESTORE_DEVICE, {device: devices[0], key: _inner.keyDevice});
            _inner.lastDeviceLabelInUseBeforeStop = null;
        }, ()=>{});
    }
    let service = {};
    service.isRelatedDeviceKey = (key)=>{
        return key === _inner.keyDevice? true: false;
    }
    service.init = ()=>{
        _inner.onInitDevices().then(()=>{}, ()=>{});
        busI.unregisterEvent(busI.EVENTS.ON_MEDIA_DEVICE_CHANGE, _inner.onDeviceChange);
        busI.registerEvent(busI.EVENTS.ON_MEDIA_DEVICE_CHANGE, _inner.onDeviceChange);
    }
    service.getSelectedDevice = ()=>{
        return _inner.selected;
    }
    service.getSelectedDeviceByKey = (key)=>{
        return _inner.selected&&_inner.selected[key? key: _inner.uniqueKeyDevice]? _inner.selected[key? key: _inner.uniqueKeyDevice]: '';
    }
    service.getSelectedDeviceId = ()=>{
        return _inner.selected&&_inner.selected.deviceId? _inner.selected.deviceId: 'default';
    }
    service.isEnabled = ()=>{
        return _inner.isOpen || (_inner.ui && _inner.ui.isOpen)? true: false;
    }
    _inner.setSelectedDevice = (device)=>{
        if(!device || !device.deviceId || !device.identifierLabel)
            return;
        _inner.selected = device;
    }
    _inner.onEnumerateDevices = ()=>{
        return new Promise((resolve, reject)=>{
            if(!navigator.mediaDevices) {
                reject();
                return;
            }
            navigator.mediaDevices.enumerateDevices()
            .then((res)=>{
                _inner.noLabels = true;
                for(let i=0; i<res.length; i++) {
                    if(res[i].label) {
                        _inner.noLabels = false;
                        break;
                    }
                }
                if(res.length>0) {
                    let inc = 0;
                    for(let i=0; i<res.length; i++) {
                        let device = res[i];
                        if(device.kind.toLowerCase() == _inner.keyDevice.toLowerCase())
                            inc++;
                        device.identifierLabel = device.label? device.label: (device.kind + ' ' + inc);
                    }
                }
                _inner.devices = res.filter(elem => elem.kind.toLowerCase() == _inner.keyDevice.toLowerCase() && elem.deviceId && elem.identifierLabel);
                resolve(res);
            }, (error)=>{
                _inner.devices = [];
                reject();
            });
        });
    }
    service.getDevices = ()=> Array.isArray(_inner.devices) ? _inner.devices : []
    service.getSelected = ()=>{
        if(typeof _inner.selected === "object" && _inner.selected.deviceId){
            const check = service.getDevices().find(item=>item.deviceId === _inner.selected.deviceId);
            if(check) return _inner.selected
            _inner.selected = null
        }
    }
    _inner.onInitDevices = () => {
        return new Promise((resolve, reject)=>{
            _inner.onEnumerateDevices()
            .then((res)=>{
                if(service.getDevices().length > 0) {
                    _inner.setSelectedDevice(service.getSelected() || service.getDevices().find(item=>item.deviceId.toLowerCase() === "default") || service.getDevices()[0]?.deviceId);
                }
                _inner.notifyUI();
                if(service.getDevices().length)
                    resolve();
                else
                    reject();
            }, ()=>{
                reject();
            });
        });
    }
    _inner.refresh = ()=>{
        _inner.onInitDevices().then(()=>{}, ()=>{});
    }
    _inner.onRefresh = ()=>{
        return new Promise((resolve, reject)=>{
            _inner.onInitDevices()
            .then(()=>{
                resolve();
            }, ()=>{
                reject();
            });
        });
    }
    _inner.onRequestPermission = ()=>{
        return new Promise((resolve, reject)=>{
            _inner.permissionObj.alreadyPrompt = _inner.permissionObj.alreadyPrompt? true: false;
            mediaI.requestPermission(_inner.permissionObj)
            .then((res) => {
                //refresh speaker permission if enabled - because they request same permission
                speakerI.init();
                _inner.onRefresh().then(()=>{}, ()=>{}).finally(()=>{
                    _inner.hasPermission = true;
                    _inner.notifyUI();
                    resolve(res);
                });
            }, (err) => {
                _inner.hasPermission = false;
                _inner.notifyUI();
                _inner.permissionObj.alreadyPrompt = true;
                reject();
            });
        });
    }
    _inner.onLoadDevice = (id) => {
        return new Promise((resolve, reject) => {
            _inner.onStopStream()
            .then(()=>{}, ()=>{})
            .finally(()=>{
                let constraint = mediaI.audioOptions;
                constraint.deviceId = id;
                let constraints = {
                    video: false,
                    audio: (id)? constraint: true,
                }
                navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    if(stream) {
                        setTimeout(()=>{
                            resolve(stream);
                        })
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject();
                })
            });
        });
    }
    _inner.onStopStream = ()=>{
        return new Promise((resolve, reject)=>{
            if(!_inner.stream || !_inner.stream.stream) {
                resolve();
                return;
            }
            service.disableAudioPreview();
            let stream = _inner.stream.stream;
            let dom = navbarI.getActivePage() === "call" ? null : _inner.stream.dom;
            let tracks = stream.getAudioTracks();
            tracks.forEach(track => {
                track.stop();
            });
            if(!dom) {
                _inner.stream = null;
                livekitI.onTogglePublishTrack('audio',false,service.getTrack(),livekitI.getSources().AUDIO)
                .then(()=>{},()=>{})
                .finally(resolve)
                return;
            }
            clearInterval(dom.volumeCallback);
            dom.style.setProperty('--volume', '0%');
            setTimeout(()=>{
                _inner.stream = null;
                resolve();
            });
        });
    }
    _inner.onStartDeviceStream = (stream, containerId) => {
        return new Promise((resolve, reject) => {
            if(!stream) {
                reject();
                return;
            }
            // _inner.onStopStream().then(()=>{}, ()=>{})
            // .finally(()=>{
                let dom = document.getElementById(containerId) || service.getAudioDom();
                _inner.stream = {stream: stream, dom: dom? dom: null};
                if(!dom) {
                    livekitI.onTogglePublishTrack('audio',true,service.getTrack(),livekitI.getSources().AUDIO)
                    .then(()=>{},()=>{})
                    .finally(resolve)
                    return;
                }
                setTimeout(()=>{
                    clearInterval(dom.volumeCallback);
                    const context = new AudioContext();
                    const source = context.createMediaStreamSource(stream);
                    const analyser = context.createAnalyser();
                    analyser.fftSize = 512;
                    analyser.minDecibels = -127;
                    analyser.maxDecibels = 0;
                    analyser.smoothingTimeConstant = 0.4;
                    source.connect(analyser);
                    const volumes = new Uint8Array(analyser.frequencyBinCount);
                    dom.volumeCallback = setInterval(() => {
                        analyser.getByteFrequencyData(volumes);
                        let volumeSum = 0;
                        for (const volume of volumes)
                            volumeSum += volume;
                        const averageVolume = volumeSum / volumes.length;
                        dom.style.setProperty('--volume', (averageVolume * 100 / 127) + '%');
                    }, 100);
                })
                resolve();
            });
        // });
    }
    service.onStopDeviceStream = () => {
        return new Promise((resolve, reject) => {
            _inner.onStopStream().then(()=>{}, ()=>{})
            .finally(()=>{
                resolve();
            });
        });
    }
    _inner.changeDevice = (deviceId, containerId) => {
        return new Promise((resolve, reject) => {
            if (!deviceId) {reject(); return;}
            // reload device
            _inner.onStopStream().then(()=>{}, ()=>{})
            .finally(()=>{
                _inner.onLoadDevice(deviceId).then((stream)=>{
                    _inner.onStartDeviceStream(stream, containerId)
                    .then(() => {
                        resolve();
                    }, (err) => {
                        reject();
                    });
                }, (err)=>{
                    reject('Cannot access new device');
                });
            });
        });
    }
    _inner.onSelectDevice = (device, containerId)=>{
        return new Promise((resolve, reject)=>{
            if(!device || !device.deviceId || !device.identifierLabel) {
                reject();
                return;
            }
            _inner.changeDevice(device.deviceId, containerId).then(()=>{
                _inner.setSelectedDevice(device);
                _inner.notifyUI();
                resolve();
            }, (err)=>{
                _inner.notifyUI();
                reject();
            });
        });
    }
    _inner.onOpenDevice = !widgetI.isWidget ? (containerId)=>{
        return new Promise((resolve, reject)=>{
            if(!_inner.hasPermission || _inner.devices.length<1) {
                _inner.isOpen = false;
                _inner.notifyUI();
                reject();
                return;
            }
            _inner.onLoadDevice(service.getSelectedDeviceId()).then((stream) => {
                _inner.isOpen = true;
                _inner.onStartDeviceStream(stream, containerId).then(() => {
                    _inner.notifyUI();
                    resolve(stream);
                }, () => { reject(); });
            }, (err) => {
                _inner.isOpen = false;
                _inner.notifyUI();
                reject();
            })
        });
    } : (containerId)=>{
            return new Promise((resolve, reject)=>{
                widgetI.getMicPermission((isSuccess) => {
                    if(!isSuccess || !_inner.hasPermission || _inner.devices.length<1) {
                        _inner.isOpen = false;
                        _inner.notifyUI();
                        reject();
                        return;
                    }
                    _inner.onLoadDevice(service.getSelectedDeviceId()).then((stream) => {
                            _inner.isOpen = true;
                            _inner.onStartDeviceStream(stream, containerId).then(() => {
                                    _inner.notifyUI();
                                    resolve(stream);
                                }, () => { reject(); });
                        }, (err) => {
                            _inner.isOpen = false;
                            _inner.notifyUI();
                            reject();
                        })
                })
            });
        }
    _inner.onStopDevice = ()=>{
        return new Promise((resolve, reject)=>{
            _inner.isOpen = false;
            _inner.onStopStream()
            .then(() => {}, ()=>{}).finally(()=>{
                if(widgetI.isWidget){ widgetI.stopMicPermission(() => {}); }
                _inner.notifyUI();
                resolve();
            });
        });
    }
    _inner.notifyUI = ()=>{
        if(!_inner.ui)
            return;
        _inner.ui.devices = _inner.devices;
        _inner.ui.noLabelsAvailable = _inner.noLabels;
        _inner.ui.selected = _inner.selected;
        _inner.ui.selectedDeviceId = _inner.selected&&_inner.selected.deviceId? _inner.selected.deviceId: '';
        _inner.ui.isOpen = _inner.isOpen;
        _inner.hasPermission = _inner.devices.length>0 || _inner.isOpen || _inner.hasPermission? true: false;
        _inner.ui.hasPermission = _inner.hasPermission;
        _inner.ui.isWaiting = _inner.isWaiting;
    }
    _inner.open = (clearIsWaiting)=>{
        if(widgetI.isWidget){
            widgetI.getMicPermission((isSuccess) => {
                if(isSuccess){
                    _inner.isOpen = true;
                    _inner.notifyUI();
                }
            })
        } else {
            _inner.isOpen = true;
            _inner.notifyUI();
        }
        if(clearIsWaiting){
            _inner.isWaiting = false;
            _inner.notifyUI();
        }
    }
    _inner.stop = (clearIsWaiting)=>{
        _inner.isOpen = false;
        if(clearIsWaiting){
            _inner.isWaiting = false
            _inner.notifyUI();
        }
        if(widgetI.isWidget){ widgetI.stopMicPermission(() => {}); }
        else _inner.notifyUI();
    }
    _inner.setIsWaiting = (value)=>{
        _inner.isWaiting = +value===1? true: false;
        _inner.notifyUI();
    }
    service.getUIBind = ()=>{
        if(_inner.ui)
            return _inner.ui;
        _inner.ui = {
            refresh: _inner.refresh,
            devices: _inner.devices,
            noLabelsAvailable: _inner.noLabels,
            selected: _inner.selected,
            selectedDeviceId: _inner.selected&&_inner.selected.deviceId? _inner.selected.deviceId: '',
            onSelect: _inner.onSelectDevice,
            onRefresh: _inner.onRefresh,
            onRequestPermission: _inner.onRequestPermission,
            hasPermission: _inner.devices.length>0 || _inner.isOpen || _inner.hasPermission? true: false,
            isOpen: _inner.isOpen,
            isWaiting: _inner.isWaiting,
            onOpen: _inner.onOpenDevice,
            open: _inner.open,
            onStop: _inner.onStopDevice,
            stop: _inner.stop,
            setIsWaiting: _inner.setIsWaiting,
            isDropdownOpen: false,
            isModalOpen: false,
            toggle: ()=>{
                streamI.toggleMic();
            },
            selectInput: (input)=>{
                streamI.selectMicInput(input);
            }
        };
        return _inner.ui;
    }
    service.enableAudioPreview = ()=>{
        if(!speakerI.isEnabled()) {
            return
        }
        //TODO - enable back based on user input
        // const stream = _inner.stream && _inner.stream.stream ? _inner.stream.stream : null
        // if(!stream) return
        // const element = document.getElementById(_inner.testAudioInputId);
        // if(!element) return
        // element.autoplay = true;
        // element.srcObject = stream;
        // element.volume = 1;
    }
    service.disableAudioPreview = ()=>{
        const element = document.getElementById(_inner.testAudioInputId);
        if(!element) return
        element.srcObject = null
        element.volume = 0
    }
    service.getTestAudioInputId = ()=> _inner.testAudioInputId;
    service.getTrack = ()=>{
        if(!service.isEnabled()) return
        const lastActiveTrack = livekitI.getActiveLocalAudioTrack();
        if(lastActiveTrack) return lastActiveTrack;
        const track = new LocalAudioTrack(service.getStream(false)?.getAudioTracks()[0]);
        track.source = Track.Source.Microphone;
        return track
    }
    if(debugI.canDebug()){
        service.getInner = ()=> _inner;
        window.micI = service;
    }
    service.getAudioDom = ()=> document.getElementById(_inner.testAudioInputId)
    service.getStream = (clone)=> _inner.stream && _inner.stream.stream && _inner.stream.stream instanceof MediaStream ? ( +clone === 1 ? _inner.stream.stream.clone() : _inner.stream.stream) : null;
    return service;
}

export const micI = MicI();
