import { mediaI } from "./mediaI";
import { busI } from "./busI";
import {widgetI} from "./widgetI";
import { streamI } from "./streamI";
import { backgroundEffectI } from "./backgroundEffectI";
import { livekitI } from "./livekitI";
import { debugI } from "./debugI";
import { navbarI } from "./navbarI";

var CameraI = function () {
    let _inner = {};
    _inner.selected = {};
    _inner.devices = [];
    _inner.isOpen = false;
    _inner.isWaiting = false;
    _inner.hasPermission = false;
    _inner.keyDevice = 'videoinput';
    _inner.stream = null;
    _inner.permissionObj = { camera: true, alreadyPrompt: false };
    _inner.lastDeviceLabelInUseBeforeStop = null;
    _inner.maxTimeToRestoreDevice = 5000;
    _inner.containerIds = {
       makeCall:"make-call-test-video",
       preCall:"precall-login-test-video"
    }

    _inner.onDeviceChange = ()=>{
        _inner.onEnumerateDevices().then(()=>{}, ()=>{}).finally(()=>{
            _inner.notifyUI();
            if(service.isEnabled()&&_inner.selected&&_inner.selected.identifierLabel) {
                let device = _inner.devices.filter(item=>item.identifierLabel==_inner.selected.identifierLabel);
                if(_inner.noLabels) {
                    //we always consider our device not available if we are on a configuration where we don't have labels
                    device = [];
                }
                //it means our device is not available anymore, we notify for stop
                if(device.length===0) {
                    _inner.lastDeviceLabelInUseBeforeStop = _inner.selected.identifierLabel;
                    busI.notifyEvent(busI.EVENTS.ON_REQUEST_STOP_DEVICE, _inner.selected);
                    clearTimeout(_inner.onDeviceRestoreTimer);
                    _inner.onDeviceRestoreTimer = setTimeout(()=>{
                        _inner.attemptToRestoreLastInUseDevice();
                    }, _inner.maxTimeToRestoreDevice);
                }
            }
        });
    }
    _inner.attemptToRestoreLastInUseDevice = () => {
        if(!_inner.lastDeviceLabelInUseBeforeStop || service.isEnabled())
            return;
        _inner.onEnumerateDevices().then(()=>{
            let devices = _inner.devices.filter(item=>item.identifierLabel==_inner.lastDeviceLabelInUseBeforeStop);
            if(devices.length !== 1)
                return;
            busI.notifyEvent(busI.EVENTS.ON_REQUEST_RESTORE_DEVICE, {device: devices[0], key: _inner.keyDevice});
            _inner.lastDeviceLabelInUseBeforeStop = null;
        }, ()=>{});
    }
    let service = {};
    service.isRelatedDeviceKey = (key)=>{
        return key === _inner.keyDevice? true: false;
    }
    service.init = ()=>{
        _inner.onInitDevices().then(()=>{}, ()=>{});
        busI.unregisterEvent(busI.EVENTS.ON_MEDIA_DEVICE_CHANGE, _inner.onDeviceChange);
        busI.registerEvent(busI.EVENTS.ON_MEDIA_DEVICE_CHANGE, _inner.onDeviceChange);
    }
    service.getSelectedDevice = ()=>{
        return _inner.selected;
    }
    service.getSelectedDeviceId = ()=>{
        return _inner.selected&&_inner.selected.deviceId? _inner.selected.deviceId: 'default';
    }
    service.isEnabled = ()=>{
        return _inner.isOpen|| (_inner.ui && _inner.ui.isOpen)? true: false;
    }
    service.getDeviceInputById = (id) => {
        if(!id)
            return;
        let input =  _inner.devices.filter(item=>item.deviceId==id);
        return input.length===1? input: null;
    }
    _inner.setSelectedDevice = (device)=>{
        if(!device || !device.deviceId || !device.identifierLabel)
            return;
        _inner.selected = device;
    }
    _inner.onEnumerateDevices = ()=>{
        return new Promise((resolve, reject)=>{
            if(!navigator.mediaDevices) {
                reject();
                return;
            }
            navigator.mediaDevices.enumerateDevices()
            .then((res)=>{
                _inner.noLabels = true;
                for(let i=0; i<res.length; i++) {
                    if(res[i].label) {
                        _inner.noLabels = false;
                        break;
                    }
                }
                if(res.length>0) {
                    let inc = 0;
                    for(let i=0; i<res.length; i++) {
                        let device = res[i];
                        if(device.kind.toLowerCase() == _inner.keyDevice.toLowerCase())
                            inc++;
                        device.identifierLabel = device.label? device.label: (device.kind + ' ' + inc);
                    }
                }
                _inner.devices = res.filter(elem => elem.kind.toLowerCase() == _inner.keyDevice.toLowerCase() && elem.deviceId && elem.identifierLabel);
                resolve(res);
            }, (error)=>{
                _inner.devices = [];
                reject();
            });
        });
    }
    _inner.onInitDevices = () => {
        return new Promise((resolve, reject)=>{
            _inner.onEnumerateDevices()
            .then((res)=>{
                if(_inner.devices.length>0) {
                    let selected = _inner.devices.filter(item=>item.deviceId===_inner.selected.deviceId);
                    if(selected.length<1)
                        _inner.setSelectedDevice(_inner.devices[0]);
                }
                _inner.notifyUI();
                if(_inner.devices.length>0)
                    resolve();
                else
                    reject();
            }, ()=>{
                reject();
            });
        });
    }
    _inner.refresh = ()=>{
        _inner.onInitDevices().then(()=>{}, ()=>{});
    }
    _inner.onRefresh = ()=>{
        return new Promise((resolve, reject)=>{
            _inner.onInitDevices()
            .then(()=>{
                resolve();
            }, ()=>{
                reject();
            });
        });
    }
    _inner.onRequestPermission = ()=>{
        return new Promise((resolve, reject)=>{
            _inner.permissionObj.alreadyPrompt = _inner.permissionObj.alreadyPrompt? true: false;
            mediaI.requestPermission(_inner.permissionObj)
            .then((res) => {
                _inner.onRefresh().then(()=>{}, ()=>{}).finally(()=>{
                    _inner.hasPermission = true;
                    _inner.notifyUI();
                    resolve(res);
                });
            }, (error) => {
                _inner.hasPermission = false;
                _inner.notifyUI();
                _inner.permissionObj.alreadyPrompt = true;
                reject();
            });
        });
    }
    _inner.onLoadDevice = (id) => {
        return new Promise((resolve, reject) => {
            _inner.onStopStream()
            .then(()=>{}, ()=>{})
            .finally(()=>{
                let constraints = {
                    audio: false,
                    // video: (id)? {deviceId: id}: true,
                }
                if(id){
                    constraints.video = {
                        deviceId:id,
                        // ...livekitI.getCameraResolution()
                    }
                }else{
                    constraints.video = {
                        // ...livekitI.getCameraResolution()
                    }
                }
                navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then((stream) => {
                        if(stream) {
                            setTimeout(()=>{
                                resolve(stream);
                            })
                        } else {
                            reject();
                        }
                    }, (error) => {
                        reject();
                    })
            });
        });
    }
    _inner.onStopStream = ()=>{
        return new Promise((resolve, reject)=>{
            if(!_inner.stream || !_inner.stream.stream) {
                resolve();
                return;
            }
            let stream = _inner.stream.stream;
            let dom = navbarI.getActivePage() === "call" ? null : _inner.stream.dom;
            let tracks = stream.getTracks();
            tracks.forEach((track) => {
                try{
                    track.stop();
                }catch(err){}
            })
            if(!dom) {
                _inner.stream = null;
                livekitI.onTogglePublishTrack('video',false,service.getTrack())
                .then(()=>{},()=>{})
                .finally(resolve)
                return;
            };
            _inner.setIsWaiting(false)
            dom.srcObject = null;
            setTimeout(()=>{
                _inner.stream = null;
                resolve();
            });
        });
    }
    _inner.onStartDeviceStream = (stream, containerId) => {
        return new Promise((resolve, reject) => {
            if(!stream) {
                reject();
                return;
            }
            _inner.onStopStream().then(()=>{}, ()=>{})
            .finally(()=>{
                let dom = containerId ? document.getElementById(containerId) : service.getDomVideo();
                _inner.stream = {stream: stream, dom: dom? dom: null};
                if(!dom) {
                    livekitI.onTogglePublishTrack('video',true,service.getTrack())
                    .then(()=>{},()=>{})
                    .finally(resolve)
                    return;
                }
                _inner.setIsWaiting(false)
                dom.srcObject = stream;
                let handled = false;
                function ok() {
                    handled = true;
                    dom.play();
                    resolve();
                }
                function ko() {
                    let dom = containerId ? document.getElementById(containerId) : service.getDomVideo();
                    if(dom) {
                        _inner.onStopStream().then(()=>{}, ()=>{});
                        reject();
                    }
                }
                try {
                    dom.onloadeddata = (event)=>{
                        setTimeout(()=>{
                            if(!handled)
                                ok();
                        }, 500);
                    }
                    dom.oncanplay = (event)=>{
                        setTimeout(()=>{
                            if(!handled)
                                ok();
                        }, 500);
                    }
                    dom.oncanplaythrough = (event)=>{
                        if(!handled)
                            ok();
                    }
                    dom.onsuspend = (event)=>{
                        // ko();
                    }
                    dom.onstalled = (event)=>{
                        // ko();
                    }
                    dom.onemptied = (event)=>{
                        // ko();
                    }
                    dom.onloadedmetadata = (event) => {
                        setTimeout(()=>{
                            if(!handled)
                                ok();
                        }, 500);
                    };
                    let counterCheck = 4;
                    function runCheck() {
                        if(handled)
                            return;
                        setTimeout(()=>{
                            if(handled)
                                return;
                            if(counterCheck<0) {
                                reject();
                                return;
                            }
                            counterCheck--;
                            runCheck();
                        }, 500);
                    }
                    runCheck();
                } catch (error) {
                    reject(error);
                }
            });
        });
    }
    service.onStopDeviceStream = () => {
        return new Promise((resolve, reject) => {
            _inner.onStopStream().then(()=>{}, ()=>{})
            .finally(()=>{
                resolve();
            });
        });
    }
    _inner.changeDevice = (deviceId, containerId) => {
        return new Promise((resolve, reject) => {
            if (!deviceId) {reject(); return;}
            // reload device
            _inner.onStopStream().then(()=>{}, ()=>{})
            .finally(()=>{
                _inner.onLoadDevice(deviceId).then((stream)=>{
                    _inner.onStartDeviceStream(stream, containerId)
                    .then(() => {
                        resolve();
                    }, (err) => {
                        reject();
                        reject("Cannot launch new test device");
                    });
                }, (err)=>{
                    reject('Cannot access new device');
                });
            });
        });
    }
    _inner.onSelectDevice = (device, containerId)=>{
        return new Promise((resolve, reject)=>{
            if(!device || !device.deviceId || !device.identifierLabel) {
                reject();
                return;
            }
            _inner.changeDevice(device.deviceId, containerId).then(()=>{
                _inner.setSelectedDevice(device);
                _inner.notifyUI();
                resolve();
            }, (err)=>{
                _inner.notifyUI();
                reject();
            });
        });
    }
    _inner.onOpenDevice = !widgetI.isWidget ? (containerId)=>{
        return new Promise((resolve, reject)=>{
            if(!_inner.hasPermission || _inner.devices.length<1) {
                _inner.isOpen = false;
                _inner.notifyUI();
                reject();
                return;
            }
            _inner.onLoadDevice(service.getSelectedDeviceId()).then((stream) => {
                _inner.isOpen = true;
                _inner.onStartDeviceStream(stream, containerId).then(() => {
                    if(backgroundEffectI.isVideoEffectSelected()){
                        setTimeout(()=>{
                          backgroundEffectI.onSelectVideoBackgroundEffect().then(()=>{},()=>{})
                        })
                    }
                    resolve(stream);
                }, (error) => {
                    reject();
                }).finally(()=>{
                    _inner.notifyUI();
                });
            }, (err) => {
                _inner.isOpen = false;
                _inner.notifyUI();
                reject();
            })
        });
    } : (containerId)=>{
        return new Promise((resolve, reject)=>{
            widgetI.getCamPermission((isSuccess) => {
                if(!isSuccess || !_inner.hasPermission || _inner.devices.length<1) {
                    _inner.isOpen = false;
                    _inner.notifyUI();
                    reject();
                    return;
                }
                _inner.onLoadDevice(service.getSelectedDeviceId()).then((stream) => {
                    _inner.isOpen = true;
                    _inner.onStartDeviceStream(stream, containerId).then(() => {}, () => {}).finally(()=>{
                        _inner.notifyUI();
                        resolve(stream);
                    });
                }, (err) => {
                    _inner.isOpen = false;
                    _inner.notifyUI();
                    reject();
                })
            })
        });
    }
    _inner.onStopDevice = ()=>{
        return new Promise((resolve, reject)=>{
            backgroundEffectI.stop(false,true);
            _inner.isOpen = false;
            _inner.onStopStream()
            .then(() => {}, ()=>{}).finally(()=>{
                if(widgetI.isWidget){ widgetI.stopCamPermission(() => {}); }
                resolve();
            });
        });
    }
    _inner.notifyUI = ()=>{
        if(!_inner.ui)
            return;
        _inner.ui.devices = _inner.devices;
        _inner.ui.noLabelsAvailable = _inner.noLabels;
        _inner.ui.selected = _inner.selected;
        _inner.ui.selectedDeviceId = _inner.selected&&_inner.selected.deviceId? _inner.selected.deviceId: '';
        _inner.ui.isOpen = _inner.isOpen;
        _inner.hasPermission = _inner.devices.length>0 || _inner.isOpen || _inner.hasPermission? true: false;
        _inner.ui.hasPermission = _inner.hasPermission;
        _inner.ui.isWaiting = _inner.isWaiting;
    }
    _inner.open = (clearIsWaiting)=>{
        if(widgetI.isWidget){
            widgetI.getCamPermission((isSuccess) => {
                if(isSuccess){
                    _inner.isOpen = true;
                    _inner.notifyUI();
                }
            })
        } else {
            _inner.isOpen = true;
            _inner.notifyUI();
        }
        if(clearIsWaiting){
            _inner.isWaiting = false;
            _inner.notifyUI();
        }
    }
    _inner.stop = (clearIsWaiting)=>{
        _inner.isOpen = false;
        if(clearIsWaiting){
            _inner.isWaiting
            _inner.notifyUI();
        }
        if(widgetI.isWidget){ widgetI.stopCamPermission(() => {}); }
        else _inner.notifyUI();
    }
    _inner.setIsWaiting = (value)=>{
        _inner.isWaiting = +value===1? true: false;
        _inner.notifyUI();
    }
    service.getUIBind = ()=>{
        if(_inner.ui)
            return _inner.ui;
        _inner.ui = {
            refresh: _inner.refresh,
            devices: _inner.devices,
            noLabelsAvailable: _inner.noLabels,
            selected: _inner.selected,
            selectedDeviceId: _inner.selected&&_inner.selected.deviceId? _inner.selected.deviceId: '',
            onSelect: _inner.onSelectDevice,
            onRefresh: _inner.onRefresh,
            onRequestPermission: _inner.onRequestPermission,
            hasPermission: _inner.devices.length>0 || _inner.isOpen || _inner.hasPermission? true: false,
            isOpen: _inner.isOpen,
            isWaiting: _inner.isWaiting,
            onOpen: _inner.onOpenDevice,
            open: _inner.open,
            onStop: _inner.onStopDevice,
            stop: _inner.stop,
            setIsWaiting: _inner.setIsWaiting,
            isDropdownOpen: false,
            isModalOpen: false,
            toggle: ()=>{
                streamI.toggleCamera();
            },
            selectInput: (input)=>{
                streamI.selectCameraInput(input);
            }
        };
        return _inner.ui;
    }
    service.getTrack = ()=>{
        if(!service.isEnabled()) return
        return service.getStream(false)?.getVideoTracks()[0]
    }
    if(debugI.canDebug()){
        service.getInner = ()=> _inner;
        window.cameraI = service;
    }
    service.getDomVideo = ()=> {
        return document.getElementById(_inner.containerIds.makeCall) || document.getElementById(_inner.containerIds.preCall)
    }
    service.getStream = (clone)=> _inner.stream && _inner.stream.stream && _inner.stream.stream instanceof MediaStream ? ( +clone === 1 ? _inner.stream.stream.clone() : _inner.stream.stream) : null;
    return service;
}

export const cameraI = CameraI();
