import { ActivatedRouteSnapshot, DetachedRouteHandle , RouteReuseStrategy } from '@angular/router';
import { callI } from 'src/assets/js/callI';
import { navbarI } from 'src/assets/js/navbarI';
export class RouteCacheStrategy implements RouteReuseStrategy {
  private handlers = new Map();
  private routes = ['*'];
  private ignoredRoutes = ['home', 'contacts','loading','login','register','guest-login','upgrade-profile','settings','calls', 'chats', 'bot', 'eco123','forgot-password','profile'];
  private dashboardRouteName = "app";
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    let path = this.getPath(route);
    if(path === "call" && !callI.getActiveCall()) return false;
    return (this.routes.includes(path) || this.routes.includes('*')) && !this.ignoredRoutes.includes(path);
  }

  store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
    this.handlers.set(this.getPath(route),handler);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!this.handlers.get(this.getPath(route)) && !!route.component;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.component) return null;
    let path = this.getPath(route);
    navbarI.setActiveRoute(path);
    navbarI.setActivePage(path);
    return this.handlers.get(path);
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  private getPath(route: ActivatedRouteSnapshot) {
    const path = route['_routerState']&&route['_routerState'].url ? route['_routerState'].url : null;
    if(!path) return;
    if(path.indexOf(this.dashboardRouteName) == -1) return path.replace('/','');
    return path.replace(`/${this.dashboardRouteName}/`,'')
  }
}