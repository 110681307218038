import { domSanitizerI } from './domSanitizerI'
function ClipboardI(){
    const DEBUG = ['localhost','127.0.0.1','192.168'].some(item=>window.origin.includes(item))
    const _inner = {};
    _inner.ICON_SIZE = '2.5rem';
    _inner.IMAGE_FALLBACK = {
        ICON_SIZE:"5rem",
        cssClass:"fa-image"
    }
    _inner.FILE_TYPES = {
        IMAGE:"image",
        IMAGE_FALLBACK:"image fallback",
        AUDIO:"audio",
        VIDEO:"video",
        PDF_DOCUMENT:"pdf",
        WORD_DOCUMENT:"word",
        EXCEL_DOCUMENT:"excel",
        POWERPOINT_DOCUMENT:"powerpoint",
        COMPRESSED_FILE:"compressed",
        TEXT_DOCUMENT:"text",
        CSV_FILE:"csv",
        SVG_FILE:"svg",
        CODE_FILE:"code",
        DATABASE_FILE:"database",
        EXECUTABLE_FILE:"executable",
        CONFIGURATION_FILE:"configuration",
        UNKNOWN:"unknown"
    }
    _inner.EXTENSIONS = {
        PDF_DOCUMENT: ['.pdf'],
        WORD_DOCUMENT: ['.doc', '.docx', '.dot', '.dotx', '.docm', '.dotm'],
        EXCEL_DOCUMENT: ['.xls', '.xlsx', '.xlsm', '.xlsb', '.xlt', '.xltx', '.xltm'],
        POWERPOINT_DOCUMENT: ['.ppt', '.pptx', '.pptm', '.pot', '.potx', '.potm'],
        COMPRESSED_FILE: ['.zip', '.rar', '.7z', '.tar', '.gz', '.bz2', '.xz', '.lzma', '.zstd'],
        CODE_FILE: ['.js', '.html', '.css', '.jsx', '.scss', '.sass', '.tsx', '.json', '.py', '.go', '.java', '.c', '.cpp', '.cs', '.rb', '.swift', '.php', '.md', '.sh'],
        DATABASE_FILE: ['.sqlite', '.db', '.mysql', '.sql', '.pgsql', '.mdf', '.ldf', '.dbf', '.dmp'],
        EXECUTABLE_FILE: ['.exe', '.msi', '.bat', '.app', '.command', '.tool', '.sh'],
        CONFIGURATION_FILE: ['.yaml', '.yml', '.conf', '.ini', '.cfg', '.env'],
        SVG_FILE: ['.svg'],
        CSV_FILE: ['.csv'],
        IMAGE: ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.tiff', '.webp', '.svg'],
        AUDIO: ['.mp3', '.wav', '.ogg', '.aac', '.flac', '.wma', '.m4a'],
        VIDEO: ['.mp4', '.mkv', '.avi', '.mov', '.wmv', '.flv', '.webm'],
        TEXT_DOCUMENT: ['.txt']
    };
    _inner.result = [];
    const service = {};
    const getId = (length = 8) => Array.from({ length }, () => Math.random().toString(36)[2]).join('');
    _inner.notifyUI = ()=>{
        service.getUIBind();
        _inner.ui.result = _inner.result
    }
    service.getUIBind = ()=>{
        if(_inner.ui) return _inner.ui
        _inner.ui = {
            result:_inner.result,
            remove:service.remove,
            FILE_TYPES:_inner.FILE_TYPES,
            ICON_SIZE:_inner.ICON_SIZE,
            IMAGE_FALLBACK:_inner.IMAGE_FALLBACK,
            handleImageLoadingError:_inner.handleImageLoadingError
        }
        return _inner.ui
    }
    _inner.handleImageLoadingError = (result)=>{
        if(typeof result !== "object") return
        const match = _inner.result.find(obj=>obj.id === result.id);
        if(!match) return
        match.type =_inner.FILE_TYPES.IMAGE_FALLBACK
        match.cssClass = _inner.IMAGE_FALLBACK.cssClass
        _inner.notifyUI();
    }
    _inner.handler = (event)=>{
        if (!event?.clipboardData?.files?.length) return
        _inner.debug(event)
        const files = Array.from(event.clipboardData.files);
        if(files.length === 0) return
        event.preventDefault();
        files.forEach(file=>{
            let info = service.getFileInfo(file.name);
            const id = info.type+'_'+getId();
            const fileReader = new FileReader();
            fileReader.onload = ()=>{
                if(!service.hasFile(file)){
                    const obj = {
                        id,
                        file
                    };
                    obj.type = info.type;
                    obj.cssClass = info.cssClass;
                    obj.blob_url = window.URL.createObjectURL(file)
                    obj.safe_blob_url = domSanitizerI.bypassSecurityTrustResourceUrl(obj.blob_url)
                    _inner.result.push(obj)
                    _inner.notifyUI();
                }
            }
            fileReader.onerror = ()=>{}
            fileReader.readAsArrayBuffer(file);
        })
    }
    service.checkExtensions = (filename,arr)=>{
        if(!Array.isArray(arr) || typeof filename !== "string" || filename.length === 0) return false
        return arr.some(ext=>filename.endsWith(ext))
    }
    service.getFileInfo = (filename) => {
        if(service.checkExtensions(filename,_inner.EXTENSIONS.PDF_DOCUMENT))
            return {type: _inner.FILE_TYPES.PDF_DOCUMENT, cssClass: 'fa-file-pdf file-color-red'}
        if(service.checkExtensions(filename,_inner.EXTENSIONS.WORD_DOCUMENT))
            return {type: _inner.FILE_TYPES.WORD_DOCUMENT, cssClass: 'fa-file-word file-color-blue'}
        if(service.checkExtensions(filename,_inner.EXTENSIONS.EXCEL_DOCUMENT))
            return {type: _inner.FILE_TYPES.EXCEL_DOCUMENT, cssClass: 'fa-file-excel file-color-green'}
        if(service.checkExtensions(filename,_inner.EXTENSIONS.POWERPOINT_DOCUMENT))
            return {type: _inner.FILE_TYPES.POWERPOINT_DOCUMENT, cssClass: 'fa-file-powerpoint file-color-red'}
        if (service.checkExtensions(filename,_inner.EXTENSIONS.COMPRESSED_FILE))
            return {type: _inner.FILE_TYPES.COMPRESSED_FILE, cssClass: 'fa-file-archive file-color-orange'}
        if (service.checkExtensions(filename,_inner.EXTENSIONS.CODE_FILE))
            return {type: _inner.FILE_TYPES.CODE_FILE, cssClass: 'fa-file-code'}
        if (service.checkExtensions(filename,_inner.EXTENSIONS.DATABASE_FILE))
            return {type: _inner.FILE_TYPES.DATABASE_FILE, cssClass: 'fa-database'}
        if (service.checkExtensions(filename,_inner.EXTENSIONS.EXECUTABLE_FILE))
            return {type: _inner.FILE_TYPES.EXECUTABLE_FILE, cssClass: 'fa-cogs file-color-blue'}
        if (service.checkExtensions(filename,_inner.EXTENSIONS.CONFIGURATION_FILE))
            return {type: _inner.FILE_TYPES.CONFIGURATION_FILE, cssClass: 'fa-file'}
        if(service.checkExtensions(filename,_inner.EXTENSIONS.SVG_FILE))
            return {type: _inner.FILE_TYPES.SVG_FILE, cssClass: ''}
        if(service.checkExtensions(filename,_inner.EXTENSIONS.SVG_FILE))
            return {type: _inner.FILE_TYPES.CSV_FILE, cssClass: 'fa-file-csv file-color-green'}
        if(service.checkExtensions(filename,_inner.EXTENSIONS.IMAGE))
            return {type: _inner.FILE_TYPES.IMAGE, cssClass: ''}
        if(service.checkExtensions(filename,_inner.EXTENSIONS.AUDIO))
            return {type: _inner.FILE_TYPES.AUDIO, cssClass: 'fa-file-audio'}
        if(service.checkExtensions(filename,_inner.EXTENSIONS.VIDEO))
            return {type: _inner.FILE_TYPES.VIDEO, cssClass: 'fa-file-video'}
        if(service.checkExtensions(filename,_inner.EXTENSIONS.TEXT_DOCUMENT))
            return {type: _inner.FILE_TYPES.TEXT_DOCUMENT, cssClass: 'fa-file-text'}
        return {type: _inner.FILE_TYPES.UNKNOWN, cssClass: 'fa-file'}
    }
    _inner.debug = function(){
        DEBUG && console.log(`[+] ClipboardI:`,...arguments)
    }
    service.init = (element)=>{
        _inner.debug("init() ",element)
        if(!(element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement)) return
        element.removeEventListener('paste',_inner.handler)
        element.addEventListener('paste',_inner.handler)
    }
    service.hasFile = (file)=>{
        return file && typeof file === "object" && _inner.result.find(obj=>['name','size','lastModified'].every(key=>obj.file[key] === file[key]))
    }
    service.clear = ()=>{
        _inner.result.forEach(obj=>{
            service.remove(obj.id)
        })
        _inner.files = []
        _inner.notifyUI();
    }
    service.remove = (id)=>{
        const match = _inner.result.find(obj=>obj.id === id);
        if(!match) return
        window.URL.revokeObjectURL(match.blob_url);
        _inner.result = _inner.result.filter(obj=>obj.id !== id);
        _inner.notifyUI();
    }
    service.getResult = ()=> _inner.result
    service.hasResult = ()=> service.getResult().length > 0
    if(DEBUG){
        service.getInner = ()=> _inner;
        window.clipboardI = service;
    }
    return service;
}
export const clipboardI = ClipboardI();