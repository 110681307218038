import { Component, NgZone } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { loadingI } from "./../assets/js/loadingI";
import { serviceProviderI } from "../assets/js/serviceProviderI";
import { themeI } from "../assets/js/themeI";
import { busI } from "../assets/js/busI";
import { navbarI } from "../assets/js/navbarI";
import { guestI } from "../assets/js/guestI";
import { endpointI } from "../assets/js/endpointI";
import { toastI } from "../assets/js/toastI";
import { DeviceDetectorService } from "ngx-device-detector";
import { screenI } from "../assets/js/screenI";
import { widgetI } from "../assets/js/widgetI";
import {NavigationService} from '../app/services/navigation-service/navigation.service'
import {BroadCastService} from '../app/services/broadcast-service/broadcast-service.service'
import { userI } from "src/assets/js/userI";
import { callI } from "src/assets/js/callI";
import { langI } from "src/assets/js/langI";
import { pwaI } from "src/assets/js/pwaI";
import { streamI } from "src/assets/js/streamI";
import { utilI } from "src/assets/js/utilI";
import { chatI } from "src/assets/js/chatI";
import { domSanitizerI } from "src/assets/js/domSanitizerI";
import emoji from 'src/assets/js/emoji.js'
import { roomI } from "src/assets/js/roomI";
import { modalI } from "../assets/js/modals";
declare var FB: any;
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  gapiSetup: any;
  authInstance: any;
  loadingUi: any = loadingI;
  spUI: any = serviceProviderI.getUIBind();
  pwaUI: any = pwaI.getUIBind();
  isLoggedIn: boolean = false;
  isWidget: boolean = false;
  callInviteCode: string = '';
  modalUI: any = {};

  constructor(
    protected titleService: Title,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected deviceService: DeviceDetectorService,
    public navigationService:NavigationService,
    protected broadCastService:BroadCastService,
    private ngZone:NgZone,
    public sanitizer: DomSanitizer
  ) {
    domSanitizerI.init(this.sanitizer)
    this.spUI = serviceProviderI.getUIBind();
    this.modalUI = modalI.getUIBind();
    let favicon = document.createElement('link');
    favicon['rel'] = 'icon';
    favicon['type'] = 'image/x-icon';
    favicon['href'] = serviceProviderI.getFavicon();
    document.head.append(favicon);
    this.initializeApp();
    this.subscribeToTitle();
    window.onload = () => {
      setTimeout(()=>{
        if(callI.isTestingBrowser()) {
          callI.exposeIForTesting();
          serviceProviderI.exposeIForTesting();
        }
      }, 1000);
      this.checkInvite();
      let fb_linked = false;
      // FB.init({
      //   appId: "433836910568007",
      //   cookie: true,
      //   xfbml: true,
      //   version: "v4.0",
      // });
      // FB.getLoginStatus((response) => {
      //   if (response.status === "connected")
      //     fb_linked = true;
      // });
      emoji.onInit().then(()=>{},()=>{})
      pwaI.initPwa();
      themeI.init();
      toastI.init();
      chatI.syncUi();
      serviceProviderI.syncUi();
      endpointI.syncUi();
      document.body.addEventListener("click", (event) => {
        clearTimeout(document.body['clickTimer']);
        document.body['clickTimer'] = setTimeout(()=>{
          let parentEl = event.target['parentElement'];
          let cnt = 0;
          while (parentEl) {
            if (parentEl.classList.contains('dropdown-holder')) {
              parent = parentEl;
              break;
            }
            parentEl = parentEl['parentElement'];
            cnt++;
            if (cnt == 10) {
              document.querySelectorAll('.dropdown-holder').forEach(elem => elem.classList.remove('active'));
              break;
            }
          }
        }, 100)
      });
      if(!this.isWidget) {
        // NO TICKET - disable for now
        // screenI.addCookieConsent();
        // screenI.addGoogleAnalytics();
      }
    };
  }

  checkInvite() {
    if(this.callInviteCode) {
      setTimeout(()=>{
        let opt = {containerRef: {}};
        toastI.info('Sending...', 10000, opt);
        callI.onAcceptInvite({code: this.callInviteCode})
        .then(()=>{
          if(opt.containerRef)
            toastI.closeToast(opt.containerRef);
          setTimeout(()=>{
            toastI.success(langI.get('ATTENDANCE_CONFIRMED'))
          }, 500);
        }, (error)=>{
          if(opt.containerRef)
            toastI.closeToast(opt.containerRef);
          setTimeout(()=>{
            toastI.error(error && typeof error==='string'? error: langI.get('ERROR_UNK'));
          }, 500);
        }).finally(()=>{
          this.callInviteCode = null;
        });
      }, 500);
    }
  }

  initializeApp() {
    this.isWidget = +endpointI.getQueryStringValue('widget')===1? true: false;
    if(!this.isWidget) {
      this.setupApp();
    } else {
      loadingI.showLoading();
      widgetI.init();
      widgetI.onReady(() => {
          // might not be needed, but keeping it to handle events from the widget
          this.setupApp();
          loadingI.hideLoading();
      });
    }
  }
  setupApp() {
    if(location.href.indexOf('/enjoy')>-1 || location.href.indexOf('/error')>-1)
      return;
    navbarI.init(this.navigationService, this.ngZone);
    endpointI.init();
    if(location.href.indexOf('invite')) {
      let code = endpointI.getQueryStringValue("code");
      if(code && endpointI.getQueryStringValue("endpoint") === endpointI.getSelectedEndpointName())
        this.callInviteCode = code;
    }
    busI.registerEvent(busI.EVENTS.ON_LOGIN, this.onLogin);
    busI.registerEvent(busI.EVENTS.ON_LOGOUT, this.onLogout);
    busI.registerEvent(busI.EVENTS.ON_GUEST_CONNECTED, this.onGuestConnected);
    this.broadCastService.init();
    this.broadCastService.registerEvent(this.broadCastService.EVENTS.USER_LOGIN,()=>{
      if(navbarI.getActivePage() === "loading" || this.isLoggedIn) return
      location.replace('/loading');
    })
    this.broadCastService.registerEvent(this.broadCastService.EVENTS.USER_LOGOUT,()=>{
      if(navbarI.getActivePage() === "loading" || !this.isLoggedIn) return
      location.replace('/loading');
    })

    if(!this.isWidget) {
      busI.unregisterEvent(busI.EVENTS.ON_REQUEST_CHECK_EXISTING_CALL, this.onRequestCheckExistingCall);
      busI.registerEvent(busI.EVENTS.ON_REQUEST_CHECK_EXISTING_CALL, this.onRequestCheckExistingCall);
      busI.registerEvent(busI.EVENTS.ON_OPEN_EXISTING_CALL_MODAL, this.onOpenExistingCallModal);
      window.removeEventListener('focus', this.onRequestCheckExistingCall);
      window.addEventListener('focus', this.onRequestCheckExistingCall);
      window.removeEventListener('blur', this.onDocumentBlur);
      window.addEventListener('blur', this.onDocumentBlur);
      this.broadCastService.registerEvent(this.broadCastService.EVENTS.CHECK_ON_CALL,(obj)=>{
        let onCall = streamI.isOnOnGoingCall();
        if(onCall)
          callI.setOnCall();
        this.broadCastService.postMessage(this.broadCastService.EVENTS.CHECK_ON_CALL_ANSWER, {onCall: streamI.isOnOnGoingCall(), checkOnCallData: obj});
      })
      this.broadCastService.registerEvent(this.broadCastService.EVENTS.CHECK_ON_CALL_ANSWER,(obj)=>{
        clearTimeout(this['onWaitResponseOtherCallTabTimer']);
        if(obj && obj.onCall)
          return;
        if(obj && obj.checkOnCallData && obj.checkOnCallData.needRejoin && obj.checkOnCallData.call) {
          modalI.displayExistingCallModal(obj.checkOnCallData.call);
        }
      })
    }
    guestI.init();

  }

  onRequestCheckExistingCall = () => {
    this.broadCastService.postMessage(this.broadCastService.EVENTS.CHECK_ON_CALL);
  }

  onDocumentBlur = () => {
    callI.clearOnCall();
  }

  onLogin = () =>{
    this.isLoggedIn = true;
    clearTimeout(this['onLoginTimer']);
    this['onLoginTimer'] = setTimeout(()=>{
      userI.connectSocket();
    }, 100);
    if(userI.isGuest()) return;
    utilI.updateLocalStorage('TMU.WebApp.Authenticated', '1');
    setTimeout(()=>{
      roomI.handleMreCallRoom();
    })
    this.broadCastService.postMessage(this.broadCastService.EVENTS.USER_LOGIN);
  }
  onLogout = () =>{
    this.isLoggedIn = false;
    utilI.removeFromLocalStorage('TMU.WebApp.Authenticated');
    this.broadCastService.postMessage(this.broadCastService.EVENTS.USER_LOGOUT);
    userI.disconnectSocket();
  }
  onOpenExistingCallModal = (call) => {
    if(!call)
      return;
    loadingI.hideLoading();
    this.broadCastService.postMessage(this.broadCastService.EVENTS.CHECK_ON_CALL, {needRejoin: true, call: call});
    clearTimeout(this['onWaitResponseOtherCallTabTimer']);
    this['onWaitResponseOtherCallTabTimer'] = setTimeout(()=>{
      modalI.displayExistingCallModal(call);
    }, 1500);
  }
  onGuestConnected = () => {
    setTimeout(()=>{
      roomI.handleMreCallRoom();
    })
    let deviceInfo = this.deviceService.getDeviceInfo();
    screenI.setIsDesktop(!this.deviceService.isMobile());
  }

  subscribeToTitle() {
    this.router.events.subscribe((event) => {
      let isEnded = event && (event instanceof NavigationEnd || event['routerEvent'] instanceof NavigationEnd? true: false);
      if (isEnded) {
        let active_page = this.getTitle(this.router.routerState, this.router.routerState.root).join("-");
        let page = active_page.slice(0, active_page.length / 2);
        page = page=='enjoy'||page=='error'? 'calls': page;
        navbarI.setActivePage(page);
        let ignoredRoutes = ['loading','enjoy','upgrade-profile', 'error'];
        if (ignoredRoutes.indexOf(navbarI.getActivePage()) === -1) {
          navbarI.storeActivePage(navbarI.getActivePage());
        }
        this.titleService.setTitle(serviceProviderI.getName());
      }
    });
  }

  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }
    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  async showAlert(title, msg, task) {
    // const alert = await this.alertCtrl.({
    //   header: title,
    //   subHeader: msg,
    //   buttons: [
    //     {
    //       text: `Action: ${task}`,
    //       handler: () => {
    //         // E.g: Navigate to a specific screen
    //       },
    //     },
    //   ],
    // });
    // alert.present();
  }
}
