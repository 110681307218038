import { Component } from "@angular/core";
import { callI } from "src/assets/js/callI";
import { streamI } from "src/assets/js/streamI";
import { modalI } from "../../../assets/js/modals";
@Component({
  selector: "app-recording-confirmation-modal",
  templateUrl: "./recording-confirmation-modal.html",
  styleUrls: ["./recording-confirmation-modal.scss"],
})
export class RecordingConfirmationModal {
  containerId: string = '';
  constructor() {
  }

  ngOnInit() {
    this.containerId = modalI.ID.CONSENT_RECORDING_MODAL;
  }

  consentRecording() {
    callI.onConsentRecording(callI.getActiveCallId(), callI.getActiveParticipant()).then(()=>{
      streamI.sendDataChannel({event: 'record', action: 'consent'});
    },()=>{});
    this.dismiss(true);
  }

  declineRecording() {
    callI.onDeclineRecording(callI.getActiveCallId(), callI.getActiveParticipant()).then(()=>{
      streamI.sendDataChannel({event: 'record', action: 'decline'});
    }, ()=>{});
    this.dismiss(false);
  }

  public dismiss(ok) {
    modalI.closeModal(modalI.ID.CONSENT_RECORDING_MODAL, true, {consent: ok});
  }
}
