import { ActivatedRouteSnapshot } from "@angular/router";
import { navbarI } from "src/assets/js/navbarI";
import { utilI } from "src/assets/js/utilI";
import { userI } from "../../assets/js/userI";
export class ConnectionGuard {
    canActivate(route: ActivatedRouteSnapshot):any{
      let path = route && Array.isArray(route.url) && route.url.length>0 && route.url[0].path? route.url[0].path: '';
      if(path=='login')
        return true;
      if(+utilI.getFromLocalStorage('TMU.WebApp.Authenticated') === 1 && path !== "loading" || userI.getAccessToken()) {
        navbarI.goToLastActivePage('calls');
        return false;
      }
      return true;
    }
}