import { Component, OnInit } from '@angular/core';
import { callI } from 'src/assets/js/callI';
import { endpointI } from 'src/assets/js/endpointI';
import { navbarI } from 'src/assets/js/navbarI';
import { recorderI } from 'src/assets/js/recorderI';
import { roomI } from 'src/assets/js/roomI';

@Component({
  selector: 'app-dashboard',
  templateUrl: './app-dashboard.component.html',
  styleUrls: ['./app-dashboard.component.scss']
})
export class AppDashboardComponent implements OnInit {

  isWidget: boolean = false;
  navUI: any = navbarI.getUIBind();

  recorder:any = recorderI.getUIBind();
  isOnActiveRoom: boolean = roomI.isOnActiveRoom();
  callUI: any = callI.getUIBind();
  constructor() {
    this.isWidget = +endpointI.getQueryStringValue('widget')===1? true: false;
  }

  ngOnInit(): void {}

}
