import { busI } from "./busI";
import { modalI } from "./modals";
var MediaI = function () {
    let service = {};
    let _inner = {};
    service.audioOptions = {
        echoCancellationType: 'system',
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl:true
    }
    service.addOnchangeEventListener = ()=>{
        if(navigator.mediaDevices)
            navigator.mediaDevices.ondevicechange = ()=>{
                clearTimeout(_inner.onDeviceChangeTimer);
                _inner.onDeviceChangeTimer = setTimeout(()=>{
                    busI.notifyEvent(busI.EVENTS.ON_MEDIA_DEVICE_CHANGE);
                }, 1000);
            }
    }
    ////// Permissions Related
    /// Check if you have the requested permission
    service.checkPermissions = (obj) => {
        return new Promise((resolve, reject)=>{
            if(!obj) {
                reject();
                return;
            }
            let video = obj.camera? true: false;
            let audio = obj.mic? true: false;
            if(!video && !audio) {
                reject();
                return;
            }
            if(navigator.permissions && typeof navigator.permissions.query==='function') {
                if(video) {
                    navigator.permissions.query({name: 'camera'})
                    .then((response) => {
                        if(response && response.state=='granted')
                            resolve(response);
                        else
                            reject(response);
                    }, (err)=>{
                        reject(err);
                    });
                    return;
                }
                if(audio) {
                    navigator.permissions.query({name: 'microphone'})
                    .then((response) => {
                        if(response && response.state=='granted')
                            resolve(response);
                        else
                            reject(response);
                    }, (err)=>{
                        reject(err);
                    });
                    return;
                }
            } else {
                navigator.mediaDevices
                .getUserMedia({ video: video, audio: audio ? (service.audioOptions ? service.audioOptions : true) : false
                })
                .then((stream) => {
                    resolve();
                }, (msg)=>{
                    reject();
                });
            }
        });
    }

    /// This will change the current requested  permissions and call the modal and return the answer.
    service.requestPermission = (request) => {
        return new Promise((resolve, reject) => {
            if(!request) {
                reject();
                return;
            }
            let camera = request.camera? true: false;
            let mic = request.mic? true: false;
            if(!camera && !mic) {
                resolve();
                return;
            }
            navigator.mediaDevices
            .getUserMedia({ video: camera, audio: mic? (service.audioOptions ? service.audioOptions : true) : false })
            .then((stream) => {
                try {
                    const tracks = stream.getTracks();
                    for(let i = 0 ; i < tracks.length; i++){
                        tracks[i].stop();
                    }
                } catch(e) {}
                resolve();
            }, (msg)=>{
                if(request.alreadyPrompt) {
                    let modalId = 'modal-perm-blocked';
                    modalI.registerData({
                        id: modalId,
                        data: {
                            permissions: {camera: camera, mic: mic}
                        }
                    });
                    modalI.openModal({
                        id: modalId,
                        onClose: () => {
                            service.checkPermissions({camera: request.camera, mic: request.mic})
                            .then((response)=>{
                                modalI.closeModal(modalId);
                                resolve();
                            }, (err)=>{
                                reject(err);
                            });
                        },
                        onSubmit: () => {
                            service.checkPermissions({camera: request.camera, mic: request.mic})
                            .then((response)=>{
                                modalI.closeModal(modalId);
                                resolve();
                            }, (err)=>{
                                modalI.invalidate(modalId);
                            });
                        }
                    })
                } else {
                    reject();
                }
            });
        });
    }
    /////// Toogle States
    service.init = () => {
        service.addOnchangeEventListener();
    }
    //init interface
    service.init();
    return service;
}

export const mediaI = MediaI();
