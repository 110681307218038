<div class="nav_bar" [ngClass]="themeUi.sidebarTheme">
    <div class="space_items_container">
        <div id="nav-top-container" class="space_items">
            <div class="logo-wrapper" (click)="home()">
                <div class="item_wrapper round logo">
                    <div class="item_content">
                        <img [src]="spUI.logo" alt="Logo">
                    </div>
                </div>
                <span class="tmu_name">{{spUI.acronym}}</span>
            </div>
            <hr class="spacer">
        </div>
        <div id="nav-content-container" class="space_items_scrollable">
            <div class="item_wrapper" data-tippy-content="Calls" data-tippy-placement='right'
            [ngClass]="activeRoute === 'calls' || activeRoute === 'call' ? 'active' : '' || isGuest && !isOnCall ? 'locked' : '' " (click)="routeTo('calls')">
                <div class="item_notification" *ngIf="callUI.unreadMessagesCounter > 0">{{callUI.unreadMessagesCounter}}</div>
                <div class="item_content">
                    <i class="fas fa-phone-alt"></i>
                </div>
                <div class="item_lock" *ngIf="isGuest && !isOnCall">
                    <i class="fas fa-lock"></i>
                </div>
            </div>
            <div *ngIf="!sp.has_direct_chat_disabled" class="item_wrapper" data-tippy-content="Messages" data-tippy-placement='right'
            [ngClass]="activeRoute == 'chats' ? 'active' : ''|| isGuest ? 'locked' : ''" fill="clear" (click)="routeTo('chats')">
                <div class="item_notification" *ngIf="chatUI.unreadConversationsCounter > 0">{{chatUI.unreadConversationsCounter}}</div>
                <div class="item_content">
                    <i class="fas fa-comments"></i>
                </div>
                <div class="item_lock" *ngIf="isGuest">
                    <i class="fas fa-lock"></i>
                </div>
            </div>
            <div *ngIf="!sp.has_contacts_disabled" class="item_wrapper" data-tippy-content="Contacts" data-tippy-placement='right'
            [ngClass]="activeRoute == 'contacts' ? 'active' : ''|| isGuest ? 'locked' : ''" fill="clear" (click)="routeTo('contacts')">
                <div class="item_notification" *ngIf="friendRequestsCounter>0">{{friendRequestsCounter}}</div>
                <div class="item_content">
                    <i class="fas fa-address-book"></i>
                </div>
                <div class="item_lock" *ngIf="isGuest">
                    <i class="fas fa-lock"></i>
                </div>
            </div>
            <div *ngIf="navUI.isCalendarEnabled" class="item_wrapper" data-tippy-content="Calendar" data-tippy-placement='right'
            [ngClass]="activeRoute == 'calendar' ? 'active' : ''|| isGuest ? 'locked' : ''" fill="clear" (click)="routeTo('calendar')">
                <div class="item_content">
                    <i class="fa fa-calendar"></i>
                </div>
                <div class="item_lock" *ngIf="isGuest">
                    <i class="fas fa-lock"></i>
                </div>
            </div>
            <div class="item_wrapper" data-tippy-content="Settings" data-tippy-placement='right'
            [ngClass]="activeRoute == 'settings' ? 'active' : ''|| isGuest ? 'locked' : ''" fill="clear" (click)="routeTo('settings')">
                <div class="item_content">
                    <i class="fas fa-cog"></i>
                </div>
                <div class="item_lock" *ngIf="isGuest">
                    <i class="fas fa-lock"></i>
                </div>
            </div>
            <div class="item_wrapper h-danger" data-tippy-content="Sign out" data-tippy-placement='right'
            [ngClass]="activeRoute == 'log-out' ? 'active' : ''" fill="clear" (click)="logout();">
                <div class="item_content">
                    <i class="fas fa-sign-out-alt"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="space_items_container">
        <div id="nav-bottom-container" class="space_items">
            <hr class="spacer">
            <div class="avatar_w_name" (click)="profileRoute()">
                <div class="item_wrapper" data-tippy-content="Account"
                [ngClass]="activeRoute == 'profile' ? 'active' : ''" data-tippy-placement='right'>
                    <div class="item_content">
                        <img [src]="account && account.avatar_url ? account.avatar_url : ui.default_avatar">
                    </div>
                </div>
                <p>{{account? account.screen_name: ''}}</p>
            </div>
        </div>
    </div>
</div>
<div class="mobile-nav-drawer" [ngClass]="themeUi.sidebarTheme" id="sidemenu-drawer">
    <div class="drawer">
        <div class="items-collection">
            <div class="item" (click)="home()">
                <div class="item-icon">
                    <img [src]="spUI.logo" alt="Logo" class="max-width-48">
                </div>
                <div class="item-content">
                    <span>{{spUI.name}}</span>
                </div>
            </div>
            <hr class="spacer">
            <div class="item" [ngClass]="activeRoute === 'calls' || activeRoute === 'call' ? 'active' : ''" (click)="routeTo('calls')">
                <div class="item-icon">
                    <i class="fas fa-phone-alt"></i>
                </div>
                <div class="item-content">
                    <span>Calls</span>
                </div>
                <div class="item-notification" *ngIf="callUI.unreadMessagesCounter > 0">
                    <span class="notif">{{callUI.unreadMessagesCounter}}</span>
                </div>
            </div>
            <div *ngIf="!sp.has_direct_chat_disabled" class="item" [ngClass]="activeRoute == 'chats' ? 'active' : '' || isGuest ? 'disabled' : ''" (click)="routeTo('chats')">
                <div class="item-icon">
                    <i class="fas fa-comments"></i>
                </div>
                <div class="item-content">
                    <span>Chats</span>
                </div>
                <div class="item-notification" *ngIf="chatUI.unreadConversationsCounter > 0 && !isGuest">
                    <span class="notif">{{chatUI.unreadConversationsCounter}}</span>
                </div>
                <div class="item-lock" *ngIf="isGuest">
                    <span class="notif lock"><i class="fas fa-lock"></i></span>
                </div>
            </div>
            <div *ngIf="!sp.has_contacts_disabled"  class="item" [ngClass]="activeRoute == 'contacts' ? 'active' : ''|| isGuest ? 'disabled' : ''" (click)="routeTo('contacts')">
                <div class="item-icon">
                    <i class="fas fa-address-book"></i>
                </div>
                <div class="item-content">
                    <span>Contacts</span>
                </div>
                <div class="item-notification" *ngIf="friendRequestsCounter>0 && !isGuest">
                    <span class="notif">{{friendRequestsCounter}}</span>
                </div>
                <div class="item-lock" *ngIf="isGuest">
                    <span class="notif lock"><i class="fas fa-lock"></i></span>
                </div>
            </div>
            <div *ngIf="navUI.isCalendarEnabled" class="item" [ngClass]="activeRoute == 'calendar' ? 'active' : ''|| isGuest ? 'disabled' : ''" (click)="routeTo('calendar')">
                <div class="item-icon">
                    <i class="fa fa-calendar"></i>
                </div>
                <div class="item-content">
                    <span>Calendar</span>
                </div>
                <div class="item-lock" *ngIf="isGuest">
                    <span class="notif lock"><i class="fas fa-lock"></i></span>
                </div>
            </div>
            <div class="item" [ngClass]="activeRoute == 'settings' ? 'active' : ''|| isGuest ? 'disabled' : ''" (click)="routeTo('settings')">
                <div class="item-icon">
                    <i class="fas fa-cog"></i>
                </div>
                <div class="item-content">
                    <span>Settings</span>
                </div>
                <div class="item-lock" *ngIf="isGuest">
                    <span class="notif lock"><i class="fas fa-lock"></i></span>
                </div>
            </div>
            <div class="item danger" (click)="logout()">
                <div class="item-icon">
                    <i class="fas fa-sign-out-alt"></i>
                </div>
                <div class="item-content">
                    <span>Sign out</span>
                </div>
            </div>
        </div>
        <div class="items-collection">
            <div class="item" (click)="profileRoute()" [ngClass]="activeRoute == 'profile' ? 'active' : ''">
                <div class="item-icon">
                    <img class="avatar" [src]="account && account.avatar_url ? account.avatar_url : ui.default_avatar">
                </div>
                <div class="item-content">
                    <span>{{account? account.screen_name: ''}}</span>
                </div>
            </div>
        </div>
    </div>
</div>