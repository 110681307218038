import { busI } from "./busI";
import { utilI } from "./utilI";
import { whiteBoardI } from "./whiteBoardI";

let ThemeI = function () {
    let service = {};
    let isDEBUG = false;

    let _inner =  {
        sidebarThemeKey: "tmu.WebApp.sidebarTheme",
        mainappThemeKey: "tmu.WebApp.mainappTheme"
    }

    service.sidebarTheme = 'light'
    service.mainappTheme = 'light'

    service.sidebarThemes = [
        {
            id: 'light',
            label: 'Light',
            image: '/assets/images/side-light.png'
        },
        {
            id: 'dark',
            label: 'Dark',
            image: '/assets/images/side-dark.png'
        },
    ]

    service.mainappThemes = [
        {
            id: 'light',
            label: 'Light',
            image: '/assets/images/main-light.png'
        },
        {
            id: 'dark',
            label: 'Dark',
            image: '/assets/images/main-dark.png'
        },
    ]

    service.init = () => { // setup the theme if not found set the default theme and return the current themes
        let sidebar = utilI.getFromLocalStorage(_inner.sidebarThemeKey)
        let mainapp = utilI.getFromLocalStorage(_inner.mainappThemeKey)
        if(service.sidebarThemes.filter(e => e.id == sidebar).length > 0){
            service.sidebarTheme = sidebar;
        } else {
            utilI.updateLocalStorage(_inner.sidebarThemeKey, service.sidebarThemes[0].id);
        }
        if(service.mainappThemes.filter(e => e.id == mainapp).length > 0){
            document.documentElement.setAttribute('data-theme', mainapp);
            service.mainappTheme = mainapp;
        } else {
            utilI.updateLocalStorage(_inner.mainappThemeKey, service.mainappThemes[0].id);
        }
        return {
            sidebar: service.sidebarTheme,
            mainapp: service.mainappTheme,
        } 
    }

    service.getSidebarTheme = () => {
        return service.sidebarTheme;
    }
    service.setSidebarTheme = (theme) => {
        if (service.sidebarThemes.filter(e => e.id == theme).length > 0){
            service.sidebarTheme = theme;
            utilI.updateLocalStorage(_inner.sidebarThemeKey, theme);
        } else {
            if (isDEBUG) { console.log('%cError: %cInvalid theme', 'color:red;font-weight:bold;', 'color:black;font-wight:regular;'); }
        }
        busI.notifyEvent(busI.EVENTS.ON_THEME_CHANGE)
    }

    service.getMainappTheme = () => {
        return service.mainappTheme;
    }
    service.setMainappTheme = (theme) => {
        if (service.mainappThemes.filter(e => e.id == theme).length > 0){
            service.mainappTheme = theme;
            document.documentElement.setAttribute('data-theme', theme);
            utilI.updateLocalStorage(_inner.mainappThemeKey, theme);
        } else {
            if (isDEBUG) { console.log('%cError: %cInvalid theme', 'color:red;font-weight:bold;', 'color:black;font-wight:regular;'); }
        }
        whiteBoardI.sync();
        busI.notifyEvent(busI.EVENTS.ON_THEME_CHANGE)
    }

    return service;
}

export const themeI = ThemeI();