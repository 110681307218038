import { busI } from "./busI";
import { callI } from "./callI";
import { chatI } from "./chatI";
import { integrationI } from "./integrationI";
import { langI } from "./langI";
import { messagingI } from "./messagingI";
import { navbarI } from "./navbarI";
import { networkI } from "./networkI";
import { participantI } from "./participantI";
import { recorderI } from "./recorderI";
import { roomI } from "./roomI";
import { serviceProviderI } from "./serviceProviderI";
import { toastI } from "./toastI";
import { userI } from "./userI";
import { utilI } from "./utilI";
import { widgetI } from "./widgetI";

let EndpointI = function () {
    let service = {};
    service.URLParameters = {};
    let _inner = {};
    _inner.endpoints = [];
    _inner.selectedEndpoint = null;
    _inner.ui = { configured:false , selectedEndpoint:null, selectedEndpointName:null }
    _inner.prepareEndpoint = function(endpoint, username, password, displayName, apiEndpoint) {
        let obj = {
          endpoint: endpoint,
          apiEndpoint: apiEndpoint,
          username: username,
          password: password,
          name: displayName.toLowerCase(),
          displayName: displayName,
        };
        return obj;
    }
    service.getAuthHeaders = function() {
      return { Authorization: 'Bearer '+ userI.getAccessToken() };
    }
    service.getMyAccountEndpoint = function() {
      return '/api/user/me';
    }
    service.getQueryStringValue = function(key) {
        return decodeURIComponent(
          window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1")
        );
    }
    service.isOnDev = function() {
      let url = window.location.href;
      return url.indexOf("localhost") > 0 || url.indexOf("192.168") > -1 || url.indexOf("127.0") > -1? true: false;
    }
    service.isOnDevLink = function() {
      return window.location.href.indexOf("dev.") > 0? true: false;
    }
    service.isProspectIntnt = function() {
      let url = window.location.href;
      return url.indexOf("intnt.") > 0 || url.indexOf("d2trjpq2y6x5j5") > -1? true: false;
    }
    service.isMorshid = function() {
      let url = window.location.href;
      return url.indexOf("morshid.") > -1 || url.indexOf('d3ueyst31zyejh')>-1 || url.indexOf('d1vjbpij413ypm')>-1? true: false;
    }
    service.isECO123 = function() {
      let url = window.location.href;
      return url.indexOf("eco123.") > -1 || url.indexOf('d1ya6jcmt5ia33')>-1? true: false;
    }
    service.isOnDevEndpoint = function() {
      let url = window.location.href;
      return service.isOnDevLink() || url.indexOf("smart-expertise.") > 0 || url.indexOf("dwcp59lltxyxu") > -1 || url.indexOf("d1ya6jcmt5ia33") > -1 || service.isProspectIntnt()? true: false;
    }
    service.getWidgetScriptUrl = function() {
      let url = window.location.href;
      return !service.isOnDev() && url.indexOf("dev.")>0 && !service.isECO123() && !service.isMorshid() && !serviceProviderI.hasBotExperience()? 'https://d396gyepmvo64y.cloudfront.net/tmu-widget.js': '';
    }
    service.init = function(match = null) {
        _inner.endpoints = [];
        _inner.endpoints.push(_inner.prepareEndpoint("wss://alpha-sassy-mq.mresence.com:15673/ws", "mresence", "mresence123", "Alpha", "https://alpha-rest-api.mresence.com"));
        _inner.endpoints.push(_inner.prepareEndpoint("wss://dev-sassy-mq.mresence.com:15673/ws", "mresence", "mresence123", "Dev", "https://dev-rest-api.mresence.com"));
        callI.setInternalKey(service.getQueryStringValue("internalKey"))
        let localHost = service.isOnDev() || callI.getIsInternal() ? location.hostname: 'localhost';
        _inner.endpoints.push(_inner.prepareEndpoint("ws://"+localHost+":15674/ws", "admin", "admin", "Local", "http://"+localHost+":8000"));
        let query = window.location.search.substring(1);
        let endpointByUrl = query.length? service.getQueryStringValue("endpoint"): '';
        if(endpointByUrl || (typeof match==='string' && match)) {
          _inner.endpoints.forEach((endpoint) => {
            if((endpoint.name && endpoint.name === endpointByUrl)
              || (endpoint.name && endpoint.name === match)) {
              service.URLParameters.endpoint = endpoint;
              try {
                utilI.updateLocalStorage("tmu.WebApp.Endpoint", JSON.stringify(endpoint));
              } catch (error) {
              }
            }
          });
        }
        let tempEndpoint = '';
        try {
          tempEndpoint = JSON.parse(utilI.getFromLocalStorage("tmu.WebApp.Endpoint"));
        } catch (error) {
        }
        if (!tempEndpoint) {
          if (service.isOnDevEndpoint() || service.isOnDev()) {
            _inner.selectedEndpoint = _inner.endpoints[1];
          } else {
            _inner.selectedEndpoint = _inner.endpoints[0];
          }
        } else {
            _inner.endpoints.forEach((endpoint) => {
            if (endpoint.name == tempEndpoint.name) {
                _inner.selectedEndpoint = endpoint;
                try {
                  utilI.updateLocalStorage("tmu.WebApp.Endpoint", JSON.stringify(endpoint));
                } catch (error) {
                }
            }
          });
        }
        let endpoint = endpointI.getSelectedEndpoint();
        _inner.ui.selectedEndpoint = service.getSelectedEndpoint();
        _inner.ui.selectedEndpointName = service.getSelectedEndpointName();
        service.syncUi();
        window["rabbitEndpoint"] = endpoint.endpoint;
    }
    service.get = function() {
        return _inner.endpoints;
    }
    service.getSelectedEndpoint = function() {
        return _inner.selectedEndpoint;
    }
    service.getSelectedEndpointName = function() {
        return _inner.selectedEndpoint&&_inner.selectedEndpoint.name? _inner.selectedEndpoint.name: '';
    }
    service.getSelectedEndpointApi = function() {
        return _inner.selectedEndpoint&&_inner.selectedEndpoint.apiEndpoint? _inner.selectedEndpoint.apiEndpoint: '';
    }
    service.getSelectedEndpointEndpoint = function() {
        return _inner.selectedEndpoint&&_inner.selectedEndpoint.endpoint? _inner.selectedEndpoint.endpoint: '';
    }
    service.isSelectedEndpointDev = function() {
        return (service.getSelectedEndpointName() === 'dev')? true: false;
    }
    service.isSelectedEndpointLocal = function() {
        return (service.getSelectedEndpointName() === 'local')? true: false;
    }
    service.configure = function(obj) {
      obj = typeof obj === 'object'? obj: {};
      _inner.ui.configured = false;
      service.URLParameters = {
        page: "",
        call_token: "",
      };
      service.URLParameters = {
        page: obj.token? 'pre-call': '',
        call_token: obj.token? obj.token: '',
      };
      let query = window.location.search.substring(1);
      let chatId = service.getQueryStringValue("chatId");
      const contactAction = service.getQueryStringValue("contactAction");
      if(!service.URLParameters.call_token && query.length>0 && !callI.isOncall()) {
        let token = service.getQueryStringValue("token");
        if (token) {
          let guestName = service.getQueryStringValue("guestname");
          service.URLParameters.call_token = token;
          service.URLParameters.guestName = guestName&&guestName.trim()? guestName.trim(): '';
          service.URLParameters.page = (service.URLParameters.guestName)? 'guest-login': 'pre-call';
        }
        const isRecorderService = service.getQueryStringValue("isRecorderService");
        recorderI.setIsRecorderService(+isRecorderService === 1?true:false);
        let providerByUrl = service.getQueryStringValue("provider");
        if(!providerByUrl)
          providerByUrl = service.getQueryStringValue("providerId");
        let providers = serviceProviderI.getProviders();
        if (providerByUrl) {
          let existingProviderId = serviceProviderI.getServiceProviderId();
          if(!widgetI.isWidget && userI.getStoredAuth() && existingProviderId && providerByUrl !== existingProviderId) {
            toastI.error(langI.get('ERROR_INVALID_PROVIDER_URL'));
            navbarI.goToLastActivePage('calls');
            return;
          }
          for(let i=0; i<providers.length; i++) {
            if(providers[i].mre_call_provider_id === providerByUrl) {
              service.URLParameters.provider = providers[i];
              serviceProviderI.updateServiceProvider(providers[i]);
              serviceProviderI.onLoadProviderDetails(existingProviderId).then(()=>{}, ()=>{});
              break;
            }
          }
        }
        let endpointByUrl = service.getQueryStringValue("endpoint");
        let endpoints = service.get();
        if (endpoints && endpointByUrl) {
          endpoints.forEach((endpoint) => {
            if (endpoint.name === endpointByUrl) {
              service.URLParameters.endpoint = endpoint;
              utilI.updateLocalStorage("tmu.WebApp.Endpoint", JSON.stringify(endpoint));
            }
          });
        }
        let integrationToken = service.getQueryStringValue("integrationToken");
        if(integrationToken) {
          let pName = service.getQueryStringValue("name");
          //ivalid request we block here
          if(!serviceProviderI.getServiceProviderId() || !pName) {
            return;
          }
          let params = {}
          if(window.location.search) {
            let searchParams = new URLSearchParams(document.location.search);
            searchParams.forEach((value, key) => {
              params[key] = value;
            });
          } else {
            params = {name: pName, providerId: serviceProviderI.getServiceProviderId()};
          }
          callI.onStartEmbedCall(params, integrationToken)
          .then((res)=>{
            userI.setTempScreenName(pName);
            navbarI.navForward('call');
          }, (error)=>{
            toastI.error(error && typeof error === 'string'? error: langI.get('ERROR_INVALID_INTEGRATION_URL'));
          });
          return;
        }
        if(!service.URLParameters.page) {
          let pageByUrl = service.getQueryStringValue("page");
          service.URLParameters.page = pageByUrl? pageByUrl: '';
          if(service.URLParameters.page)
            integrationI.requireIntegration(service.getQueryStringValue("integration"));
        }
      }

      if(service.URLParameters.call_token && service.URLParameters.page) {
        if(callI.getInternalKey()){
          callI.onLoadCallByIdOrToken(service.URLParameters.call_token)
          .then(async (call)=>{
            if(!call || !call.is_active) {
              toastI.warning(langI.get('CALL_END_BACK_LOGIN'));
              userI.logout();
              return;
            }
            if(callI.getInternalKey()) {
              userI.setTempScreenName(participantI.getInternalParticipantName());
              callI.setActive({call:call});
              setTimeout(()=>{
                navbarI.navForward("/call");
              })
              busI.notifyEvent(busI.EVENTS.ON_LOGIN);
              return
            }
          }, (error)=>{
            userI.logout();
            toastI.error(error);
          })
          .finally(()=>{
            callI.clearStoredActiveCall();
          })
          return
        }
        callI.clearStoredActiveCall();
        navbarI.navForward(service.URLParameters.page);
        return;
      }
      if(obj.loggedIn && userI.getStoredAuth()) {
        busI.notifyEvent(busI.EVENTS.ON_LOGIN);
        callI.onCheckExistingCallOnStorage()
        .then((call)=>{
          if(roomI.isOnActiveRoom()) return
          navbarI.navForward(userI.isGuest()? 'upgrade-profile': (navbarI.getNextPage() || 'calls'));
          busI.notifyEvent(busI.EVENTS.ON_OPEN_EXISTING_CALL_MODAL, call);
        }, (error)=>{
          if(service.URLParameters.page){
            navbarI.navForward(service.URLParameters.page);
          }
          else{
            if(contactAction && userI.isValidContactAction(contactAction)){
              userI.setContactAction(contactAction);
              navbarI.navForward('contacts')
              return
            }
            let page = serviceProviderI.isECO123() || serviceProviderI.isMorshid() || serviceProviderI.hasBotExperience()? navbarI.getLandingPage(): 'calls';
            if(serviceProviderI.isECO123() || serviceProviderI.isMorshid() || serviceProviderI.hasBotExperience()) {
              navbarI.setLastActivePage(page);
            }
            if(!chatId)
              chatId = chatI.getTmpActiveChatId();
            if(chatId && chatId.length>0) {
              chatI.setTmpActiveChatId(chatId);
              navbarI.navForward('chats')
            }else{
              navbarI.goToLastActivePage(page)
            }
          }
        });
      } else {
        let path = '';
        if(endpointI.URLParameters && endpointI.URLParameters.provider)
          path = 'login';
        else if(userI.isWhoAmiGuest())
          path = 'guest-login';
        else {
          let storedActivePage = navbarI.getStoredActivePage();
          if(['login', 'register'].indexOf(storedActivePage)>-1)
            path = storedActivePage;
        }
        if(!path)
          path = navbarI.getLandingPage();
        navbarI.navBack(path);
      }
      _inner.ui.configured = true;
    }
    service.getUIBind=()=>{
      return _inner.ui;
    }
    service.getWSHost = ()=>{
      let url = '';
      if(service.isSelectedEndpointLocal()) {
          let localHost = service.isOnDev()? location.hostname: 'localhost';
          url = 'ws://'+localHost+':8000';
      } else if(service.isSelectedEndpointDev()) {
          url = 'wss://dev-rest-api.mresence.com';
      } else {
          url = 'wss://alpha-rest-api.mresence.com';
      }
      return url;
    }
    service.syncUi = ()=>{
      messagingI.postMessageToServiceWorker({
        target:'endpointI',
        ui:_inner.ui
      })
    }
    return service;
}
export const endpointI = EndpointI();