import { endpointI } from "./endpointI";
import { userI } from "./userI";

let NetworkI = function () {
    let service = {};
    let _inner = {};
    service.getErrorMessageFromArr = function(arr) {
        if(!Array.isArray(arr))
            return '';
        return arr.filter(item=>item.key==='client').map(item=>item.message).join('<br/>');
    }
    service.isInternalError = function(arr) {
        if(!Array.isArray(arr))
            return false;
        return arr.filter(item=>item.key==='internal').length>0;
    }
    _inner.process = function(req) {
        return new Promise(function(resolve, reject) {
            if(!req || typeof req.url!=="string" || typeof req.verb!=="string") {
                reject();
                return;
            }

            let run = (obj) => {
                if(!obj || typeof obj.url!=="string" || typeof obj.verb!=="string") {
                    reject();
                    return;
                }

                let headers = {};
                let url = '';
                if(['http://','https://'].some(protocol=>obj.url.startsWith(protocol))){
                    req.isExternal = true
                }
                if(!req.isExternal) {
                    if(userI.getAccessToken() && !headers.Authorization)
                        headers.Authorization = endpointI.getAuthHeaders().Authorization;
                    if(obj.headers && typeof obj.headers==='object')
                        for(let k in obj.headers)
                            headers[k] = obj.headers[k];
                    url = endpointI.getSelectedEndpointApi() + obj.url;
                } else {
                    url = obj.url;
                    headers ={
                        'Content-Type': 'application/json'
                    }
                }

                let opt  = {
                    method: obj.verb,
                    headers: headers
                }

                if(obj.data)
                    opt.body = obj.data;
                if(obj.formData) {
                    opt.body = obj.formData;
                }
                fetch(url, opt).then((response)=>{
                    if(response.status == 200 || response.status == 204 || +response.ok === 1) {
                        if(response.headers.get('Content-Type')?.indexOf('json') > -1){
                            response.json()
                            .then((res)=>{
                                resolve(res);
                            }, (error)=>{
                                resolve(response);
                            });
                        }else{
                            resolve(response);
                        }
                    } else {
                        if(response.status == 401 && url.indexOf('admin')===-1 && url.indexOf('manager')===-1 && url.indexOf('embed')===-1) {
                            if(userI.getRefreshToken()) {
                                networkI.post({url: '/api/refreshtoken', headers: {'Authorization': userI.getRefreshToken()}})
                                .then((res)=>{
                                    if(res && res.AccessToken && res.RefreshToken) {
                                        userI.setAccessToken(res.AccessToken);
                                        userI.setRefreshToken(res.RefreshToken);
                                        userI.saveAuth();
                                        run(obj);
                                    } else {
                                        userI.completeLogout();
                                    }
                                }, ()=>{
                                    userI.completeLogout();
                                });
                            } else
                                reject();
                        } else {
                            reject();
                        }
                    }
                }, (error)=>{
                    reject(error);
                });
            }
            run(req);
        });
    };
    service.get = function(obj) {
        return new Promise(function(resolve, reject) {
            if(typeof obj !== 'object' || !obj.url) {
                reject();
                return;
            }
            obj.verb = 'GET';
            _inner.process(obj).then(function(response) {
                resolve(response);
            }, function(error) {
                reject(error);
            });
        });
    }
    service.post = function(obj) {
        return new Promise(function(resolve, reject) {
            if(typeof obj !== 'object' || !obj.url) {
                reject();
                return;
            }
            obj.verb = 'POST';
            if(obj.data)
                obj.data = typeof obj.data==='object' && !obj.data.query && !obj.isFile? JSON.stringify(obj.data): obj.data;
            _inner.process(obj).then(function(response) {
                resolve(response);
            }, function(error) {
                reject(error);
            });
        });
    }
    service.put = function(obj) {
        return new Promise(function(resolve, reject) {
            if(typeof obj !== 'object' || !obj.url) {
                reject();
                return;
            }
            obj.verb = 'PUT';
            if(obj.data)
                obj.data = !obj.isFile && typeof obj.data==='object' && !obj.data.query? JSON.stringify(obj.data): obj.data;
            _inner.process(obj).then(function(response) {
                resolve(response);
            }, function(error) {
                reject(error);
            });
        });
    }
    service.delete = function(obj) {
        return new Promise(function(resolve, reject) {
            if(typeof obj !== 'object' || !obj.url || typeof obj.data !== 'object') {
                reject();
                return;
            }
            obj.verb = 'DELETE';
            if(obj.data)
                obj.data = typeof obj.data==='object' && !obj.data.query? JSON.stringify(obj.data): obj.data;
            _inner.process(obj).then(function(response) {
                resolve(response);
            }, function(error) {
                reject(error);
            });
        });
    }
    return service;
}
export const networkI = NetworkI();