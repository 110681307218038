import { Component, Input } from "@angular/core";
import { busI } from "src/assets/js/busI";
import { callI } from "src/assets/js/callI";
import { modalI } from "src/assets/js/modals";
@Component({
  selector: "app-exit-call-page",
  templateUrl: "./exit-call.html",
  styleUrls: ["./exit-call.scss"],
})
export class ExitCallModalPage {
  containerId: string = '';
  isHost: boolean = false;

  constructor() {
    this.containerId = modalI.ID.EXIT_CALL_MODAL;
    this.isHost = callI.isActiveCallByHost() || (callI.isOncall()&&callI.isOncall()["isActiveCallByHost"])? true: false;
  }

  ngOnInit() {}

  async leave() {
    let obj = {isStop: false};
    modalI.closeModal(modalI.ID.EXIT_CALL_MODAL, true, obj);
    busI.notifyEvent(busI.EVENTS.ON_REQUEST_LEAVE_CALL, obj);
    callI.clearOnCall();
  }

  async stop() {
    let obj = {isStop: true};
    modalI.closeModal(modalI.ID.EXIT_CALL_MODAL, true, obj);
    busI.notifyEvent(busI.EVENTS.ON_REQUEST_LEAVE_CALL, obj);
    callI.clearOnCall();
  }

  public dismiss() {
    modalI.closeModal(modalI.ID.EXIT_CALL_MODAL, false);
  }
}
