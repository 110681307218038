import { langI } from "./langI";
import { networkI } from "./networkI";
import { streamI } from "./streamI";

let CompanionI = function () {
    let service = {};
    let _inner = {};
    _inner.summary = null;
    _inner.ui = null;
    service.notifyEvent = function(evt, data){
        if(!service.EVENTS[evt] || !_inner.events[evt] || _inner.events[evt].length<1)
            return;
        for(let i=0; i<_inner.events[evt].length; i++) {
            if(typeof _inner.events[evt][i].fct !== 'function')
                continue;
            setTimeout(()=>{
                if(_inner.events[evt][i] && typeof _inner.events[evt][i].fct === 'function')
                    _inner.events[evt][i].fct(data);
            });
        }
    }
    _inner.notifyUI= () => {
        if(!_inner.ui)
            service.getUIBind();
        streamI.updateIsDrawerOpen();
    }
    service.isDrawerOpen = () => {
        return _inner.ui && _inner.ui.isDrawerOpen? true: false;
    }
    service.closeDrawer = ()=>{
        service.getUIBind().closeDrawer();
    }
    service.openDrawer = ()=>{
        service.getUIBind().openDrawer();
    }
    service.toggleDrawer = ()=>{
        service.getUIBind().toggleDrawer();
    }
    service.getCallSummaries = function(id) {
        return _inner.summary && _inner.summary[id] && Array.isArray(_inner.summary[id])? _inner.summary[id]: [];
    }
    service.onGenerateCallNotes = function(id) {
        return new Promise((resolve, reject) => {
            if(!id) {
                reject();
                return;
            }
            networkI.post({url: '/api/call/'+id+'/generate/note', data: {}})
            .then((response)=>{
                if(Array.isArray(response.errors)) {
                    reject(networkI.getErrorMessageFromArr(response.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                resolve(response);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onRequestCallSummary = function(id) {
        return new Promise((resolve, reject) => {
            if(!id) {
                reject();
                return;
            }
            networkI.post({url: '/api/call/'+id+'/summary', data: {MreCallID: id}})
            .then((response)=>{
                if(Array.isArray(response.errors)) {
                    reject(networkI.getErrorMessageFromArr(response.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                try {
                    response.json()
                    .then((res)=>{
                        resolve(res);
                    }, (error)=>{
                        reject(error)
                    })
                } catch (error) {
                    reject(error)
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onRunSummary = function(id) {
        return new Promise((resolve, reject)=>{
            if(!id) {
                reject();
                return;
            }
            service.onGenerateCallNotes(id)
            .then((response)=>{
                service.onRequestCallSummary(id)
                .then((res)=>{
                    if(res && res.summary) {
                        let txt = res.summary;
                        if(!_inner.summary)
                            _inner.summary = {};
                        if(!_inner.summary[id])
                            _inner.summary[id] = [];
                        if(Array.isArray(_inner.summary[id])) {
                            _inner.summary[id] = _inner.summary[id].filter((item=> item!==txt));
                            _inner.summary[id].push(txt);
                        }
                        resolve();
                    } else {
                        reject();
                    }
                }, (error)=>{
                    reject(error);
                });
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.getUIBind = () => {
        if(_inner.ui) {
            _inner.notifyUI();
            return _inner.ui;
        }
        _inner.ui = {
            isDrawerOpen: false,
            toggleDrawer: ()=>{
                if(service.isDrawerOpen())
                    service.closeDrawer();
                else
                    service.openDrawer();
            },
            closeDrawer: ()=>{
                _inner.ui.isDrawerOpen = false;
                _inner.notifyUI();
            },
            toggleDrawer: ()=>{
                _inner.ui.isDrawerOpen = !_inner.ui.isDrawerOpen;
                setTimeout(()=>{
                    _inner.notifyUI();
                })
            }
        }
        return _inner.ui;
    }
    return service;
}
export const companionI = CompanionI();