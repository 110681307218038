import * as VideoProcessors from '@twilio/video-processors';
import Twilio from 'twilio-video';
import { callI } from './callI';
import { cameraI } from './cameraI';
import { streamI } from './streamI';
import { userI } from "./userI";
import { navbarI } from './navbarI';
import { livekitI } from './livekitI';

const BackgroundEffectI = function(){
    const _inner = {};
    _inner.localMedia = null;
    const service = {};
    const DEBUG = 'localhost,192.168,127.0'.split(',').some(item=>window.location.href.indexOf(item) > -1);
    _inner.isDrawerOpen = false;
    const IMAGE_ASSETS_PATH = '/assets/background-effects'
    const ENABLE_CUSTOM_EFFECTS = true;
    const BACKGROUND_EFFECTS_ASSETS_URL = "https://s3.us-east-2.amazonaws.com/web-resources.mresence.com/background-effects";
    const CLEAR_CUSTOM_EFFECT_WHEN_CAMERA_STOPPED = true;
    _inner.effects = [
      {
        id:2,
        label:'Effect',
        description:'Select a background as an effect.',
        type:'blur',
        items:[
            {
              id:1,
              label: 'None',
              type: 'none',
              isDefault:true
            },
            {
              id:2,
              label: 'Light Blur',
              type: 'blur',
              processor: {
                  maskBlurRadius: 5,
                  blurFilterRadius: 5,
              }
            },
            {
              id:3,
              label: 'Heavy Blur',
              type: 'blur',
              processor: {
                  maskBlurRadius: 5,
                  blurFilterRadius: 10,
              }
            }
        ]
      },
      {
        id:3,
        label:'Image',
        description:'Select a background image as an effect.',
        type:'image',
        items:[
          {
            id:1,
            label: 'House',
            type:'image',
            src:`${IMAGE_ASSETS_PATH}/house.png`,
            processor: {
                maskBlurRadius: 10,
            }
          },
          {
            id:2,
            label: 'Classroom',
            type:'image',
            src:`${IMAGE_ASSETS_PATH}/classroom.jpeg`,
            processor: {
                maskBlurRadius: 10,
            }
          },
          {
            id:3,
            label: 'Corporate',
            type:'image',
            src:`${IMAGE_ASSETS_PATH}/corporate.jpg`,
            processor: {
                maskBlurRadius: 10,
            }
          },
          {
            id:4,
            label: 'Lounge',
            type:'image',
            src:`${IMAGE_ASSETS_PATH}/lounge.webp`,
            processor: {
                maskBlurRadius: 10,
            }
          },
          {
            id:5,
            label: 'Ops room',
            type:'image',
            src:`${IMAGE_ASSETS_PATH}/opsroom.webp`,
            processor: {
                maskBlurRadius: 10,
            }
          }
        ]
      }
    ]
    if(ENABLE_CUSTOM_EFFECTS){
      _inner.effects.push(
        {
          id: 4,
          label:'Custom image',
          description:'Use custom background image as an effect.',
          type:'custom',
          items:[]
        }
      )
    }
    _inner.stream = null;
    _inner.isLoading = false;
    _inner.localVideoTrack = null;
    _inner.consumedTracks = [];
    _inner.testVideoContainerId = null;
    service.getDefaultEffect = ()=> Array.from(_inner.effects.map(effect=>effect.items)).flat().find(item=>item.isDefault);
    _inner.selectedEffect = service.getDefaultEffect();
    _inner.ui = {};
    _inner.hasUI = ()=> typeof _inner.ui === "object" && Object.keys(_inner.ui).length
    _inner.isOnActiveCall = ()=>{
        return navbarI.getActiveRoute() === "app/call" || navbarI.getActiveRoute() === "call" ? true : false;
    }
    service.openDrawer = ()=>{
        _inner.isDrawerOpen = true;
        _inner.notifyUI();
    }
    service.closeDrawer = ()=>{
        _inner.isDrawerOpen = false;
        _inner.notifyUI();
    }
    service.toggleDrawer = ()=>{
        if(_inner.isDrawerOpen){
            service.closeDrawer();
        }else{
            service.openDrawer();
        }
    }
    service.getUIBind = ()=>{
        if(_inner.hasUI()) return _inner.ui;
        _inner.ui = {
            effects:_inner.effects,
            selectedEffect:_inner.selectedEffect,
            isDrawerOpen:_inner.isDrawerOpen,
            toggleDrawer:service.toggleDrawer,
            isLoading:_inner.isLoading
        }
        return _inner.ui;
    }
    _inner.notifyUI = ()=>{
        if(!_inner.hasUI()) service.getUIBind();
        for(let key in _inner.ui){
            _inner.ui[key] = _inner[key] || service[key]
        }
    }
    service.applyNoneBackground = () => {
      if(_inner.selectedEffect && _inner.selectedEffect.type === "none") {
        service.stop();
        service.updateStream();
        setTimeout(()=>{
            if(_inner.isOnActiveCall()){
              livekitI.onTogglePublishTrack('video',false,livekitI.getSources().CAMERA)
              .then(()=>{},()=>{})
              .finally(()=>{
                livekitI.onTogglePublishTrack('video',true,_inner.stream.getVideoTracks()[0],livekitI.getSources().CAMERA)
                .then(()=>{},()=>{})
              })
            }else{
              const target = service.getTestVideoElement();
              if(target) target.srcObject = _inner.stream;
            }
        });
        return
      }
    }
    service.onLoadEffectProcessor = ()=>{
        return new Promise(async (resolve, reject) => {
          if(!BACKGROUND_EFFECTS_ASSETS_URL) {
            service.debug.error('onLoadEffectProcessor : Rejected (reason missing assets url)')
            reject();
            return;
          }
          if(typeof _inner.selectedEffect !== "object"){
            service.debug.error('onLoadEffectProcessor : Rejected (reason selectedEffect is not an object)')
            reject();
            return
          }
          if(_inner.localVideoTrack && _inner.localVideoTrack.processor){
            _inner.localVideoTrack.removeProcessor(_inner.localVideoTrack.processor)
          }

          service.updateStream();
          if(!(_inner.stream instanceof MediaStream)){
            service.debug.error('onLoadEffectProcessor : Rejected (reason camera stream not found)')
            reject();
            return
          }
          _inner.localVideoTrack = new Twilio.LocalVideoTrack(_inner.stream.getVideoTracks()[0],DEBUG ? { logLevel:"warn" }:{})
          service.addConsumedTrack(_inner.localVideoTrack.mediaStreamTrack)
          var processor;
          if (_inner.selectedEffect.type == 'blur') {
            processor = new VideoProcessors.GaussianBlurBackgroundProcessor({
                  assetsPath: BACKGROUND_EFFECTS_ASSETS_URL,
                  ..._inner.selectedEffect.processor
              });
              await processor.loadModel();
              setTimeout(()=>{
               try{
                _inner.localVideoTrack.addProcessor(processor,{
                  inputFrameBufferType:typeof window.OffscreenCanvas === "undefined" ? "canvas" : "offscreencanvas"
                })
                service.debug.info('onLoadEffectProcessor : Resolved')
                resolve();
               }catch{
                reject();
               }
              })
          } else if (_inner.selectedEffect.type == 'image' || _inner.selectedEffect.type === 'custom') {
              let img = new Image();
              img.src = _inner.selectedEffect.src;
              img.onload = async () => {
                  const processor = new VideoProcessors.VirtualBackgroundProcessor({
                      assetsPath: BACKGROUND_EFFECTS_ASSETS_URL,
                      backgroundImage: img,
                      maskBlurRadius: 5,
                      fitType: VideoProcessors.ImageFit.Fill,
                  });
                  await processor.loadModel();
                  try{
                    _inner.localVideoTrack.addProcessor(processor,{
                      inputFrameBufferType:typeof window.OffscreenCanvas === "undefined" ? "canvas" : "offscreencanvas"
                    })
                    service.debug.info('onLoadEffectProcessor : Resolved')
                    resolve();
                  }catch{
                    reject();
                  }
              }
              img.onerror = (err)=>{
                reject(err);
              };
          } else {
            service.debug.error(`onLoadEffectProcessor : Rejected (unknown selectedEffect type = ${_inner.selectedEffect.type})`)
            reject()
          }
      });
      }
    service.setTestVideoContainerId = (containerId)=>{
        if(!containerId) return
        _inner.testVideoContainerId = containerId;
    }
    service.getTestVideoElement = ()=>{
        return document.getElementById(_inner.testVideoContainerId);
    }
    _inner.applyLocalTrack = () => {
      if(!_inner.localVideoTrack)
        return false;
      const el = _inner.localVideoTrack.attach();
      el.style.width = '100%';
      el.style.height = '100%';
      el.style.objectFit = 'cover';
      el.id = _inner.testVideoContainerId;
      if(_inner.isOnActiveCall()) {
        service.updateVideoLocalMediaStream();
      } else {
          let video = service.getTestVideoElement();
          if(video) video.replaceWith(el);
      }
      return true;
    }
    _inner.onRun = ()=>{
      return new Promise((resolve, reject) => {
        if(!_inner.selectedEffect || !_inner.selectedEffect.id) {
          reject();
          return;
        }
        _inner.isLoading = true;
        _inner.notifyUI();
        let ok = false;
        service.onLoadEffectProcessor()
        .then(()=>{
          ok = true;
          service.debug.info('onSelectVideoBackgroundEffect() : Resolved')
          resolve();
        },err=>{
          reject(err);
        }).finally(()=>{
          _inner.isLoading = false;
          _inner.notifyUI();
          if(ok) {
            resolve();
          } else {
            reject();
          }
        })
      });
    }
    service.onSelectVideoBackgroundEffect = (item = _inner.selectedEffect)=>{
        return new Promise((resolve,reject)=>{
          if(_inner.isLoading || !item) {
            reject();
            return
          }
          if(typeof item !== "object"){
            service.debug.error('onSelectVideoBackgroundEffect() : Rejected (reason typeof item is not an object)')
            reject();
            return
          }
          _inner.selectedEffect = item;
          const isNone = item && item.type === "none" ? true : false;
          if(isNone) {
            service.applyNoneBackground();
            resolve();
            return;
          }
          if(!cameraI.isEnabled()) {
            if(_inner.testVideoContainerId && !_inner.isOnActiveCall()) {
              let camera = cameraI.getUIBind();
              camera.onOpen(_inner.testVideoContainerId? _inner.testVideoContainerId: '')
              .then(()=>{
                _inner.onRun().then(()=>{
                  if(_inner.applyLocalTrack())
                    resolve();
                  else
                    reject();
                }, ()=>{
                  reject();
                });
              }, ()=>{
                service.debug.error('onSelectVideoBackgroundEffect : Rejected (reason camera not enabled)')
                reject();
              });
            } else {
              streamI.onOpenCamera().then(()=>{}, ()=>{});
            }
          } else {
            _inner.onRun().then(()=>{
              if(_inner.applyLocalTrack())
                resolve();
              else
                reject();
            }, ()=>{
              reject();
            });
          }
        })
      }
      service.updateVideoLocalMediaStream = ()=>{
        if(_inner.localVideoTrack && _inner.localVideoTrack.processedTrack){
          const cameraUI = cameraI.getUIBind();
          cameraUI.setIsWaiting(true)
          livekitI.onTogglePublishTrack('video',false,livekitI.getSources().CAMERA)
          .then(()=>{},()=>{})
          .finally(()=>{
            livekitI.onTogglePublishTrack('video',true,service.getProcessedStream().getVideoTracks()[0],livekitI.getSources().CAMERA)
            .then(()=>{},()=>{})
            .finally(()=>{
              cameraUI.setIsWaiting(false)
            })
          })
        }
        if(_inner.localVideoTrack)
          service.addConsumedTrack(_inner.localVideoTrack.processedTrack)
      }
      service.addConsumedTrack = (track)=>{
        if(!track || _inner.consumedTracks.find(t=>t.id === track?.id)) return
        _inner.consumedTracks.push(track)
      }
      service.stop = (clear=false,reset=false)=>{
        service.debug.info('stop()')
        _inner.consumedTracks.forEach(track=>{
          try{
            track.stop?.();
          }catch{}
        })
        if(clear || (CLEAR_CUSTOM_EFFECT_WHEN_CAMERA_STOPPED && _inner.selectedEffect.type === "custom" && reset)) _inner.selectedEffect = service.getDefaultEffect();
        _inner.consumedTracks = [];
        service.stopTracks();
        _inner.localVideoTrack = null;
        setTimeout(()=>{
          if(_inner.isOnActiveCall()) return
          const el = service.getTestVideoElement();
          if(el) el.srcObject = cameraI.isEnabled() ? _inner.stream : null;
        })
        _inner.isLoading = false;
        _inner.notifyUI();
      }
    service.stopTracks = ()=>{
      if(_inner.localVideoTrack) _inner.localVideoTrack.stop();
      let tracks = _inner.stream ? _inner.stream.getTracks() : []
      for(let i = 0 ; i < tracks.length; i++){
        try{
          tracks[i].stop();
        }catch{}
      }
    }
    service.updateStream = ()=>{
      _inner.stream = cameraI.getStream(true);
    }
    service.isVideoEffectSelected = ()=> _inner.selectedEffect && _inner.selectedEffect.type !== "none" ? true : false;
    service.debug = {};
    service.debug.title = 'Background Effect Interface'
    service.debug.error = function(){
        if(!DEBUG) return
        console.log(`[!] ${service.debug.title}: `,...arguments)
    }
    service.debug.info = function(){
        if(!DEBUG) return
        console.log(`[+] ${service.debug.title}: `,...arguments)
    }
    if(DEBUG){
        service.getInner = ()=> _inner;
        window.backgroundEffectI = service;
    }
    service.onAddCustomVideoBackgroundEffect = async ()=>{
        if(typeof window.showOpenFilePicker !== "function") return
        try{
          let files = await window.showOpenFilePicker({
            types: [
                {
                    description: 'İmage Files',
                    accept: {
                        'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
                    },
                },
            ],
            excludeAcceptAllOption: true,
            multiple: true,
          });
          [...files].forEach(async item=>{
            try{
              const file = await item.getFile();
              const reader = new FileReader();
              reader.readAsDataURL(file)
              reader.onload = ()=>{
                const customEffectsRef = _inner.effects.find(item=>item.type === "custom");
                if(!customEffectsRef) return
                if(!Array.isArray(customEffectsRef.items)) customEffectsRef.items = [];
                const id = customEffectsRef.items.length+1;
                customEffectsRef.items.push({
                    id:id,
                    label:`Custom effect ${id}`,
                    type:'custom',
                    src:reader.result,
                    processor: {
                        maskBlurRadius: 10,
                    }
                })
                _inner.notifyUI();
              }
              reader.onerror = ()=>{}
            }catch{}
          })
        }catch{}
    }
    service.onStart = ()=>{
      return new Promise((resolve,reject)=>{
        _inner.isLoading = false;
        _inner.notifyUI();
        if(!service.isVideoEffectSelected()){
          reject();
          return
        }
        backgroundEffectI.onSelectVideoBackgroundEffect()
        .then(()=>{
          resolve();
        }, ()=>{
          reject();
        });
      })
    }
    service.getStream = ()=> _inner.stream;
    service.getTrack = ()=>{
      return service.getProcessedStream()?.getVideoTracks()[0]
    }
    service.getProcessedStream = ()=>{
      return _inner.localVideoTrack && _inner.localVideoTrack.processedTrack ? new MediaStream([_inner.localVideoTrack.processedTrack]) : new MediaStream([]);
    }
    service.isEnabled = ()=> _inner.localVideoTrack && _inner.localVideoTrack.isEnabled && _inner.localVideoTrack.isStarted ? true : false;
    service.onRestart = ()=>{
      return new Promise((resolve,reject)=>{
        if(!service.isEnabled()) return reject();
        service.updateStream();
        service.stop(false,false)
        service.onStart().then(()=>{},()=>{})
        .finally(()=>{
          resolve();
        })
      })
    }
    service.getTestVideoContainerId = ()=> _inner.testVideoContainerId;
    return service;
}
export const backgroundEffectI = BackgroundEffectI();