import { utilI } from "./utilI";
import { callI } from './callI'

var PwaI = function () {

    let service = {};
    service.promptEvent = null;
    service.dismissed = false;

    let _inner = {}
    _inner.PWA_LAST_DISMISS_TIMESTAMP_STORAGE_KEY = "TMU.WebApp.PWALastDismissTimestamp"
    _inner.SHOW_PROMPT_AFTER_HOURS_COUNT = 48;

    function onbeforeinstallprompt(e) {
        e.preventDefault();
        service.promptEvent = e;
        _inner.notifyUI();
    }

    function onappinstalled(e){
        service.removePwaEvents();
        service.promptEvent = null;
        _inner.notifyUI();
    }

    service.initPwa = function () {
        service.removePwaEvents();
        window.addEventListener('beforeinstallprompt', onbeforeinstallprompt);
        window.addEventListener('appinstalled', onappinstalled);
    }

    service.removePwaEvents = function (){
        window.removeEventListener('beforeinstallprompt', onbeforeinstallprompt)
        window.removeEventListener('appinstalled', onappinstalled)
    }

    service.installPWA = function () {
        if(service.promptEvent)
            service.promptEvent.prompt();
    }

    service.shouldInstall = ()=> {
        if(callI.getIsInternal()) return false
        let dismissCheck = true;
        const lastDismiss = utilI.getFromLocalStorage(_inner.PWA_LAST_DISMISS_TIMESTAMP_STORAGE_KEY);
        if(lastDismiss && +lastDismiss >= 0){
            if((new Date().getTime() - lastDismiss) >= (_inner.SHOW_PROMPT_AFTER_HOURS_COUNT * 1000 * 60 * 60)){
                utilI.removeFromLocalStorage(_inner.PWA_LAST_DISMISS_TIMESTAMP_STORAGE_KEY);
                dismissCheck = false;
            }else{
                dismissCheck = true;
            }
        }else{
            utilI.removeFromLocalStorage(_inner.PWA_LAST_DISMISS_TIMESTAMP_STORAGE_KEY);
            dismissCheck = false;
        }
        return !service.isRunningStandalone() && !dismissCheck && service.promptEvent && service.dismissed == false? true: false;
    }
    service.isRunningStandalone = function () {
        return (window.matchMedia('(display-mode: standalone)').matches);
    }

    service.dismissInstall = function () {
        utilI.updateLocalStorage(_inner.PWA_LAST_DISMISS_TIMESTAMP_STORAGE_KEY,new Date().getTime());
        service.dismissed = true;
        _inner.notifyUI();
    }

    _inner.notifyUI = function() {
        if(!_inner.ui)
            service.getUIBind();
        _inner.ui.showPrompt = service.shouldInstall();
    }

    service.getUIBind = function() {
        if(_inner.ui) {
            _inner.notifyUI();
            return _inner.ui;
        }
        _inner.ui = {
            dismissed: service.dismissed,
            showPrompt: service.shouldInstall()
        }
        return _inner.ui;
    }

    return service;
}

export const pwaI = PwaI();