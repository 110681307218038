<div class="PwaNotification" [class.open]="show">
  <div class="grid">
    <div class="col-12 flex-col">
      <span class="text-xl mb-2 fw-500">Install {{spUI.name}}</span>
      <span class="op-7">Install the app on your device to easily access it anytime. No app store. No download. No hassle.</span>
    </div>
    <div class="col-12 flex-col gap-10">
      <button class="button primary large" (click)="install()">Install</button>
      <button class="button large" (click)="dismissInstall();">Not now</button>
    </div>
  </div>
</div>