let LoadingI = function () {
    let service = {};

    service.isVisible = false;
    service.hasMessage = false;
    service.message = null;

    service.showLoading = (msg) => {
        service.isVisible = true;
        if(msg) {
            service.hasMessage = true;
            if(typeof msg == 'string'){
                service.message = msg;
            }
        } else {
            service.hasMessage = false;
            service.message = null;
        }
    }

    service.hideLoading = () => {
        service.isVisible = false;
        service.hasMessage = false;
        service.message = null;
    }
    return service;
}

export const loadingI = LoadingI();