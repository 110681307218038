import { serviceProviderI } from "./serviceProviderI";
import { callI } from "./callI";
import { companionI } from "./companionI";
import { toastI } from "./toastI";
import { chatI } from "./chatI";
import { participantI } from "./participantI";
import { dropdownI } from "./dropdownI";
import { cameraI } from "./cameraI";
import { micI } from "./micI";
import tippy from "tippy.js";
import { recorderI } from "./recorderI";
import { screenI } from "./screenI";
import { userI } from "./userI";
import { transcriptionI } from "./transcriptionI";
import { speakerI } from "./speakerI";
import { langI } from "./langI";
import { modalI } from "./modals";
import { busI } from "./busI";
import { notificationI } from "./notificationI";
import { widgetI } from "./widgetI";
import { duration } from "moment";
import "moment-duration-format";
import { videoShareI } from "./videoShare";
import { handTrackerI } from "./handTrackerI";
import { pollI } from "./pollI";
import { sipI } from "./sipI";
import { backgroundEffectI } from './backgroundEffectI'
import { wakeLockI } from './wakeLockI'
import { networkI } from "./networkI";
import { smartExpertiseI } from "./smartExpertiseI";
import { whiteBoardI } from "./whiteBoardI";
import { streamerI } from "./streamerI";
import { roomI } from "./roomI";
import { livekitI } from "./livekitI";

let StreamI = function (hammer) {
    const DEBUG = ["127.0.0.1",'localhost','192.168'].some(value=>window.origin.includes(value))
    let service = {};
    service.EVENTS = {
        MIC_CHANGE: 'micChange',
        CAMERA_CHANGE: 'cameraChange',
        SYNC: 'sync',
        // SETUP: 'setup',
        RAISE_HAND: 'raiseHand',
        LOWER_HAND: 'lowerHand',
        REACTION: 'reaction',
        MOVE_TO_WAITING_ROOM:'moveToWaitingRoom',
        CHANGE_PARTICIPANT_LANGUAGE: 'changelanguage',
        TOGGLE_TRANSCRIPTION:"toggleTranscription",
        TOGGLE_SPEECH_TO_SPEECH:"toggleSpeechToSpeech",
        CHANGE_ROLE_PARTICIPANT: "promote"
    }
    let _inner = {};
    _inner.displayLangImage = true;
    _inner.streamKeyPrefix = 'eco';
    _inner.videoCssClass = _inner.streamKeyPrefix+'-video';
    _inner.videoCssClassSelector = '.'+_inner.videoCssClass;
    _inner.reconnectTimerKey = 'reconnectTimer';
    _inner.streams = {};
    _inner.MreCallStream = null;
    _inner.usersAlreadyJoined = {};
    _inner.ui = null;
    _inner.isLeavingCall = false;
    _inner.elements = null;
    _inner.SCREEN_SHARE_ID_SUFFIX = '_screen_share';
    _inner.selfRaiseHandStatus = false;
    _inner.raisedhandCount = 0;
    _inner.reactions = {
      isDropdownOpen : false,
      list: [
        {
          id: 'like',
          label: 'Thumbs Up',
          emoji: '👍',
        },
        {
          id: 'clap',
          label: 'Clap',
          emoji: '👏',
        },
        {
          id: 'laugh',
          label: 'Laugh',
          emoji: '😀',
        },
        {
          id: 'surprised',
          label: 'Surprised',
          emoji: '😮',
        },
        {
          id: 'sad',
          label: 'Sad',
          emoji: '🙁',
        },
      ]
    }
    _inner.VIDEO_CONTAINER_ID_PREFIX = 'shared-video-';
    _inner.pins = new Map();
    _inner.lastPinedScreenElement = null;
    const CONTAINERS = {
      MAIN:"MainCallsContainer",
      SECONDARY:"SecondaryCallsContainer-wrapper"
    }
    const PARTICIPANT_PROPERTY = {
      TRANSCRIPTION:1,
      SPEECH_TO_SPEECH:2
    }
    service.getVideoShareIdPrefix = function() {
      return _inner.VIDEO_CONTAINER_ID_PREFIX;
    }
    service.getVideoCssClass = function() {
      return _inner.videoCssClass;
    }
    service.getCallStream = function() {
        return _inner.MreCallStream;
    }
    service.getCallStreamId = function() {
        let stream = service.getCallStream();
        return stream && stream.mre_call_stream_id? stream.mre_call_stream_id: null;
    }
    service.isUserOnDisconnecting = (id)=>{
      if(!id)
        return;
      let view = document.getElementById(id);
      if(!view || !view.classList.contains('op-5')) {
        return false;
      }
      return true;
    }
    service.clearUser = (id) => {
      if(!id)
        return;

      clearTimeout(id+_inner.reconnectTimerKey);
      service.updateParticipantRaiseHand(id, false);
      _inner.pins.delete(id);
      videoShareI.destroy(id.split('-').join(''));
      _inner.notifyUI();
    }
    service.onUserLeft = (id)=>{
      if(!id)
        return;
      let participant = participantI.getParticipantById(id);
      let isSip = livekitI.isSipParticipant(id);
      if((participant && participant.name) || isSip) {
        clearTimeout(_inner.onUserLeftSoundTimer);
        _inner.onUserLeftSoundTimer = setTimeout(()=>{
          if(participant)
            userI.setFriendOutOfCall(participant.account_profile_id);
        }, 500);
        if(isSip) {
          sipI.kickSipConnection(id);
        }
      }
      service.clearUser(id);
      setTimeout(()=>{
        service.MainCallsContainer();
      })
    }
    service.getActiveParticipants = function() {
      let participants = participantI.getParticipantsByCallId(callI.getActiveCallId()).filter(p=>!participantI.isInternalParticipant(p))
      let openIds = livekitI.getParticipants().map(p=>p.identity)
      return participants.filter(item=> openIds.indexOf(item.mre_call_participant_id)>-1);
    }
    service.requestStopDevice = (device) => {
      if(!device || !device.identifierLabel)
        return;
      let mic = micI.getUIBind();
      if(mic.selected && device.identifierLabel===mic.selected.identifierLabel && mic.isOpen) {
        mic.onStop().then(()=>{}, ()=>{});
        return;
      }
      let camera = cameraI.getUIBind();
      if(camera.selected && device.identifierLabel===camera.selected.identifierLabel && camera.isOpen) {
        camera.onStop().then(()=>{}, ()=>{});
        return;
      }
      let speaker = speakerI.getUIBind();
      if(speaker.selected && device.identifierLabel===speaker.selected.identifierLabel && speaker.isOpen) {
        speaker.onStop().then(()=>{}, ()=>{});
        return;
      }
    }
    service.requestRestoreDevice = (device, containerId) => {
      if(!device)
        return;
      let mic = micI.getUIBind();
      if(mic.selected && device.identifierLabel===mic.selected.identifierLabel && !mic.isOpen) {
        mic.onOpen(containerId).then(()=>{}, ()=>{});
        return;
      }
      let camera = cameraI.getUIBind();
      if(camera.selected && device.identifierLabel===camera.selected.identifierLabel && !camera.isOpen) {
        camera.onOpen(containerId).then((stream)=>{}, ()=>{});
        return;
      }
      let speaker = speakerI.getUIBind();
      if(speaker.selected && device.identifierLabel===speaker.selected.identifierLabel && !speaker.isOpen) {
        if(livekitI.isRoomActive())
          service.openSpeaker();
        else
          speaker.onOpen().then((stream)=>{}, ()=>{});
      }
    }
    service.updateParticipantRaiseHand = function(participantId, status){
      if(!participantId) return;
      let participant = participantI.getParticipantById(participantId);
      if(!participant)
        return;
      participant.is_raisehand = status? true: false;
      let obj = service.getElementById(participantId);
      if(obj && obj.raisehand_status) {
        service.updateElementRaiseHandStatus(obj, status ?? false);
      }
      if(participantId === callI.getActiveParticipantId())
        _inner.selfRaiseHandStatus = status;
      _inner.notifyUI();
    }
    service.toggleParticipantCamera = function(id) {
      if(!id)
          return;
      if(!callI.isActiveCallByHost()) {
          toastI.warning(langI.get('ACTION_UNAUTHORISED'));
          return;
      }
      service.sendDataUser(id, {event: service.EVENTS.CAMERA_CHANGE});
    }
    service.updateStreams = function(streams) {
        if(!Array.isArray(streams))
          return;
        for(let i=0; i<streams.length; i++) {
          if(!streams[i].mre_call_stream_id)
            continue;
          service.updateStream(streams[i]);
        }
    }
    service.sendDataChannel = function(payload) {
        livekitI.sendData(null,payload)
    }
    service.sendDataUser = function(id, payload) {
      livekitI.sendData(id,payload);
    }
    service.updateStream = function(stream) {
        if(!stream || !stream.mre_call_stream_id || !service.canUpdateStream(stream))
            return;
        if(typeof stream.is_muted !== "boolean"){
            stream.is_muted = true;
        }
        if(typeof stream.is_paused !== "boolean"){
            stream.is_paused = true;
        }
        _inner.streams[stream.mre_call_stream_id] = stream;
    }
    service.isScreenShareConnection = function(id) {
        return id && typeof id==="string" && id.indexOf(_inner.SCREEN_SHARE_ID_SUFFIX)>-1? true: false;
    }
  service.isVideoFileShareConnection = function(id) {
    return id && typeof id==="string" && id.indexOf(videoShareI.getDomVideoFileShareId())>-1? true: false;
}
    service.removeStream = function(id) {
        delete _inner.streams[id];
    }
    service.getStreams = function() {
        let filtered = [];
        for(let s in _inner.streams)
            filtered.push(_inner.streams[s]);
        return filtered;
    }
    service.clearParticipantsInWaitingRoom = ()=>{
      if(!_inner.ui) return
      _inner.ui.participantsInWaitingRoom = [];
    }
    service.clear = function() {
        clearTimeout(_inner.onPingTimer);
        clearInterval(_inner.durationTimer);
        clearTimeout(_inner.sanitizeUsersTimer);
        window.removeEventListener("beforeunload", service.beforeUnloadNotification);
        window.removeEventListener("unload", service.onUnloadNotification);
        window.removeEventListener("resize", service.onResize);
        videoShareI.clear();
        sipI.clear();
        callI.resetGlobalCounter();
        wakeLockI.stop();
        service.clearParticipantsInWaitingRoom();
        service.onExitPictureInPicture().then(()=>{}, ()=>{});
        _inner.isSetup = false
        _inner.streams = {};
        _inner.usersAlreadyJoined = {};
        _inner.elements = null;
        _inner.MreCallStream = null;
        _inner.isLeavingCall = false;
        _inner.pins.clear();
        _inner.lastPinedScreenElement = null;
        if(_inner.ui) {
          _inner.ui.langShortcuts = [];
          _inner.ui.callDuration = null;
        }
        service.fullMode()
        _inner.notifyUI();
    }
    service.canUpdateStream = function(stream) {
        if(!stream || !_inner.streams || !_inner.streams[stream.mre_call_stream_id] || !stream.updated_at)
            return true;
        return !_inner.streams[stream.mre_call_stream_id].updated_at || +_inner.streams[stream.mre_call_stream_id].updated_at <= +stream.updated_at? true: false;
    }
    service.onStartMreStream = function() {
        return new Promise((resolve, reject)=>{
            let participant = callI.getActiveParticipant();
            if(!callI.getActiveCallId() || !participant) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallParticipant: participant
            };
            networkI.post({url: '/api/call/'+callI.getActiveCallId()+'/startstream', data: payload})
            .then((res)=>{
              if (Array.isArray(res.errors)) {
                reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
              } else {
                  _inner.MreCallStream = res.MreCallStream;
                  resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.onResumeCameraStream = function() {
        return new Promise((resolve, reject)=>{
            if(!service.getCallStream()) {
                reject();
                return;
            }
            let participant = callI.getActiveParticipant();
            if(!callI.getActiveCallId() || !participant) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallParticipant: participant,
                MreCallStream: service.getCallStream()
            };
            networkI.post({url: '/api/call/'+callI.getActiveCallId()+'/resumestream', data: payload})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                  reject(networkI.getErrorMessageFromArr(res.errors));
                } else {
                    resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.onPauseCameraStream = function() {
        return new Promise((resolve, reject)=>{
            if(!service.getCallStream()) {
                reject();
                return;
            }
            let participant = callI.getActiveParticipant();
            if(!callI.getActiveCallId() || !participant) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallParticipant: participant,
                MreCallStream: service.getCallStream()
            };
            networkI.post({url: '/api/call/'+callI.getActiveCallId()+'/pausestream', data: payload})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                  reject(networkI.getErrorMessageFromArr(res.errors));
                } else {
                    resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.onPauseParticipantCamera = function(id) {
      return new Promise((resolve, reject)=>{
          if(!id || !callI.getActiveCallId() || !serviceProviderI.getServiceProviderId()) {
              reject();
              return;
          }
          let payload = {
              MreCallProvider: serviceProviderI.getServiceProviderForApi(),
              MreCallParticipantID: callI.getActiveParticipantId(),
              TargetMreCallParticipantID: id,
          };
          networkI.post({url: '/api/call/'+callI.getActiveCallId()+'/mutecamera', data: payload})
          .then((res)=>{
              if (Array.isArray(res.errors)) {
                reject(networkI.getErrorMessageFromArr(res.errors));
              } else {
                  resolve(res);
              }
          }, (error)=>{
              reject(error);
          });
      });
    }
    service.onResumeMicStream = function() {
        return new Promise((resolve, reject) => {
            if(!service.getCallStream() || !callI.getActiveCall() || !callI.getActiveParticipant()) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallParticipant: callI.getActiveParticipant(),
                MreCallStream: {mre_call_stream_id: service.getCallStreamId()}
            };
            networkI.post({url: '/api/call/'+callI.getActiveCallId()+'/unmutemic', data: payload})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                  reject(networkI.getErrorMessageFromArr(res.errors));
                } else {
                    resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onPauseMicStream = function() {
        return new Promise((resolve, reject) => {
            if(!service.getCallStream() || !callI.getActiveCall() || !callI.getActiveParticipant()) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallParticipant: callI.getActiveParticipant(),
                MreCallStream: service.getCallStream()
            };
            networkI.post({url: '/api/call/'+callI.getActiveCallId()+'/mutemic', data: payload})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                  reject(networkI.getErrorMessageFromArr(res.errors));
                } else {
                    resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onMuteParticipantMic = function(id) {
        return new Promise((resolve, reject)=>{
            if(!id || !callI.getActiveCallId() || !serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallParticipantID: callI.getActiveParticipantId(),
                TargetMreCallParticipantID: id,
            };
            networkI.post({url: '/api/call/'+callI.getActiveCallId()+'/muteparticipant', data: payload})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                } else {
                    resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onUnMuteParticipantMic = function(id) {
        return new Promise((resolve, reject)=>{
            if(!id || !callI.getActiveCallId() || !serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallParticipantID: callI.getActiveParticipantId(),
                TargetMreCallParticipantID: id,
            };
            networkI.post({url: '/api/call/'+callI.getActiveCallId()+'/unmuteparticipant', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                  reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                } else {
                  resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onStartScreenShareStream = function() {
        return new Promise((resolve, reject) => {
            if(!callI.getActiveCallId() || !callI.getActiveParticipantId()) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallParticipant: callI.getActiveParticipant()
            };
            networkI.post({url: '/api/call/'+callI.getActiveCallId()+'/startscreensharestream', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                  reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                } else {
                    resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onStopScreenShareStream = function(stream) {
        return new Promise((resolve, reject) => {
            if(!stream) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallStream: stream,
                MreCallParticipant: callI.getActiveParticipant()
            };
            networkI.post({url: '/api/call/'+callI.getActiveCallId()+'/stopscreensharestream', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                  reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                } else {
                    resolve(res);
                }
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onLoadCallParticipants = function(participantId) {
      return new Promise((resolve, reject) => {
          if(!callI.getActiveCallId()) {
              reject();
              return;
          }
          let url = '/api/call/'+callI.getActiveCallId()+'/participants';
          if(participantId)
            url += '/'+participantId;
          networkI.get({url: url})
          .then((res)=>{
            if (Array.isArray(res.errors)) {
              reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
              return;
            }
            resolve(res);
          }, (error)=>{
              reject(error);
          });
      });
    }
    service.isUserAlreadyDisplayed = function(id) {
      if(!id)
        return false;
      let view = document.getElementById(id);
      if(!view)
        return false;
      return id && service.getElementById(id) && view && !view.classList.contains('op-5')? true: false;
    }
    service.runSanitizeUsers = function() {
      if(!callI.getActiveCallId() || callI.isRoom(callI.getActiveCall()))
        return;
      let timer = _inner.sanitizeUsersTimer? 10000: 0;
      timer = 15000;
      clearTimeout(_inner.sanitizeUsersTimer);
      _inner.sanitizeUsersTimer = setTimeout(()=>{
        if(callI.isRoom(callI.getActiveCall()))
          return;
        let response = {};
        callI.onLoadExpandedCall(callI.getActiveCallId(), {participants: 1})
        .then((res)=>{
          response = res;
        }, (error)=>{}).finally(()=>{
          service.onLoadCallUsers().then((res)=>{
            if(res) {
              if(response.MreCall)
                callI.updateActiveCall(response.MreCall);
              let users = Array.isArray(res.participants)? res.participants: [];
              let participants = Array.isArray(response.MreCallParticipants)? response.MreCallParticipants: [];
              let activeParticipantsIdsToUpdate = [];
              for(let i=0; i<users.length; i++) {
                let user = users[i];
                if(user.identity)
                  activeParticipantsIdsToUpdate.push(user.identity);
              }
              let activeParticipants = participants.filter(item=> item.mre_call_participant_id && item.mre_call_participant_id.indexOf(activeParticipantsIdsToUpdate)>-1);
              let waitingRoomCandidates = participants.filter(item=> item.mre_call_participant_id && item.mre_call_participant_id.indexOf(activeParticipantsIdsToUpdate)==-1);
              participantI.updateParticipants(participants);
              if(activeParticipants.length>0) {
                // we still need to handle placeholders here
                transcriptionI.updateStateBaseOnParticipants();
              }
              if(waitingRoomCandidates.length>0) {
                service.updateWaitingRoomParticipants(waitingRoomCandidates);
              }
              _inner.notifyUI();
            }
          }, ()=>{}).finally(()=>{
            service.runSanitizeUsers();
          })
        });
      }, timer);
    }
    service.onLoadCallUsers = function() {
      return new Promise((resolve, reject) => {
          if(!livekitI.isRoomActive() || !callI.getActiveCallId()) {
              reject();
              return;
          }
          let url = '/api/call/'+callI.getActiveCallId()+'/users?participants=1';
          networkI.get({url: url})
          .then((res)=>{
            if(res && Array.isArray(res.MreCallParticipants)) {
              let sipParticipants = [];
              for(let i=0; i<res.MreCallParticipants.length; i++) {
                if(!res.MreCallParticipants[i].MreCallParticipantSip
                  || res.MreCallParticipants[i].mre_call_id !== callI.getActiveCallId())
                  continue;
                sipParticipants.push(res.MreCallParticipants[i].MreCallParticipantSip);
              }
              if(sipParticipants.length>0)
                sipI.updateParticipant(sipParticipants);
            }
            resolve(res);
          }, (error)=>{
            reject(error);
          });
      });
    }
    service.getTargetContainerId = ()=> service.isHalfMode() ? CONTAINERS.SECONDARY : CONTAINERS.MAIN
    service.getMainContainer = function(container) {
      const targetContainerId = service.getTargetContainerId()
      let el = document.getElementById(targetContainerId);
      if(el) return el
      if(!container) return
      el = document.createElement('div');
      el.style.position = 'relative';
      el.id = targetContainerId;
      el.setAttribute('data-'+_inner.streamKeyPrefix+'-webrtc-inner-container', 'true');
      container.appendChild(el)
      return  el
    }
    //Media
    service.onSetupMedia = function() {
      return new Promise((resolve, reject)=>{
        service.onSetupSpeaker(()=>{}, ()=>{})
        .finally(()=>{
          resolve();
        });
      });
    }
    //Speaker
    service.onInitSpeaker = function() {
        return new Promise((resolve, reject)=>{
            let speaker = speakerI.getUIBind();
            speaker.onRefresh().then(()=>{}, ()=>{}).finally(()=>{
                let deviceId = speakerI.getSelectedDeviceId();
                if(!speaker.hasPermission || !deviceId) {
                  reject();
                  return;
                }
                resolve();
            });
        });
    }
    service.selectSpeakerInput = function(input) {
        if(!input || !input.deviceId)
            return;
        let speaker = speakerI.getUIBind();
        speaker.onSelect(input, null)
        .then(()=>{
            service.onInitSpeaker().then(()=>{
            }, ()=>{
                toastI.error(langI.get('MEDIA_ERROR_CHANGE_SPEAKER'));
            });
        }, ()=>{})
    }
    service.openSpeaker = function() {
        service.onOpenSpeaker().then(()=>{}, ()=>{})
        .finally(()=>{
          livekitI.onSpeakerStateChange();
        })
    }
    service.stopSpeaker = function() {
        service.onStopSpeaker().then(()=>{}, ()=>{})
        .finally(()=>{
          livekitI.onSpeakerStateChange();
        })
    }
    service.onOpenSpeaker = function() {
        return new Promise((resolve, reject)=>{
            let speaker = speakerI.getUIBind();
            speaker.onOpen().then(()=>{
                videoShareI.handleSetSpeakerMuted(false);
                resolve(1);
            }, ()=>{
                service.onStopSpeaker().then(()=>{}, ()=>{});
                reject();
            });
        });
    }
    service.onStopSpeaker = function() {
        return new Promise((resolve, reject)=>{
            let speaker = speakerI.getUIBind();
            speaker.onStop().then(()=>{
                resolve(1);
            }, ()=>{reject()})
        });
    }
    service.toggleSpeaker = function() {
        let speaker = speakerI.getUIBind();
        if(speaker.isOpen) {
            service.onStopSpeaker().then(()=>{}, ()=>{})
            .finally(()=>{
              livekitI.onSpeakerStateChange();
            })
        } else {
            if(speaker.hasPermission) {
                service.openSpeaker();
            } else {
                speaker.onRequestPermission().then(()=>{
                    service.onInitSpeaker().then(()=>{
                        setTimeout(() => {
                            service.openSpeaker();
                        }, 500);
                    }, ()=>{});
                }, ()=>{});
            }
        }
    }
    service.onToggleSpeaker = function() {
      return new Promise((resolve, reject)=>{
        let speaker = speakerI.getUIBind();
        if(speaker.isOpen) {
            service.onStopSpeaker().then(()=>{}, ()=>{}).finally(()=>{
              resolve();
            });
        } else {
            if(speaker.hasPermission) {
              service.onOpenSpeaker().then(()=>{}, ()=>{}).finally(()=>{
                resolve();
              });
            } else {
              speaker.onRequestPermission().then(()=>{
                  service.onInitSpeaker().then(()=>{
                      setTimeout(() => {
                        service.onOpenSpeaker().then(()=>{}, ()=>{}).finally(()=>{
                          resolve();
                        });
                      }, 500);
                  }, ()=>{});
              }, ()=>{});
            }
        }
      });
    }
    //Mic
    service.onInitMic = function() {
        return new Promise((resolve, reject)=>{
            let mic = micI.getUIBind();
          let deviceId = micI.getSelectedDeviceId();
          if(!mic.hasPermission) {
            reject();
            return;
          }
          resolve();
        });
    }
    service.toggleMic = function() {
        let mic = micI.getUIBind();
        if(mic.isWaiting) return
        if(micI.isEnabled()) {
            service.stopMic();
        } else {
            if(mic.hasPermission) {
                service.openMic();
            } else {
              mic.onRequestPermission().then(()=>{
                service.onInitMic().then(()=>{
                    service.openMic();
                }, ()=>{});
              }, ()=>{});
            }
        }
    }
    service.selectMicInput = function(input) {
        if(!input || !input.deviceId)
            return;
        let mic = micI.getUIBind();
        mic.setIsWaiting(false);
        mic.onSelect(input, null)
        .then(()=>{
          mic.onRequestPermission().then(()=>{
            service.onInitMic().then(()=>{
            }, ()=>{}).finally(()=>{
              setTimeout(() => {
                if(!micI.isEnabled()) return
                service.openMic();
              }, 500);
              if(!transcriptionI.shouldRunInstanceById(micI.getSelectedDeviceId())) {
                return;
              }
              transcriptionI.restartMicInstances();
            })
          }, ()=>{});
        }, ()=>{});
    }
    service.openMic = function() {
        let mic = micI.getUIBind();
        if(micI.isEnabled() || mic.isWaiting) return
        mic.setIsWaiting(true);
        service.onOpenMic().then(()=>{
        }, ()=>{
        })
    }
    service.onOpenMic = function() {
        return new Promise((resolve, reject)=>{
            let mic = micI.getUIBind();
            mic.onOpen()
            .then((res)=>{
              service.onResumeMicStream().then(()=>{}, (msg)=>{});
              if(!transcriptionI.shouldRunInstanceById(micI.getSelectedDeviceId())) {
                resolve(1);
                return;
              }
              transcriptionI.restartMicInstances();
              resolve(1);
            }, ()=>{
              service.stopMic();
              reject();
            });
        });
    }
    service.stopMic = function() {
        let mic = micI.getUIBind();
        if(!micI.isEnabled() || mic.isWaiting) return
        mic.setIsWaiting(true);
        service.onStopMic().then(()=>{
        }, ()=>{
        })
    }
    service.onStopMic = function() {
        return new Promise((resolve, reject)=>{
            let mic = micI.getUIBind();
            mic.onStop().then(()=>{
              transcriptionI.stopMicInstances();
              service.onPauseMicStream().then(()=>{}, ()=>{});
              resolve(1);
          }, (msg)=>{
            toastI.error(msg);
            reject();
          });
        });
    }
    //Camera
    service.selectCameraInput = function(input) {
        if(!input || !input.deviceId)
            return;
        let camera = cameraI.getUIBind();
        camera.isDropdownOpen = false;
        camera.setIsWaiting(false);
        camera.onSelect(input, null)
        .then(()=>{
          camera.onRequestPermission().then(()=>{
            service.onInitCamera().then(()=>{
            }, ()=>{})
            .finally(()=>{
              setTimeout(() => {
                service.openCamera();
              }, 500);
            })
          }, ()=>{});
        }, ()=>{});
    }
    service.onOpenCamera = function() {
        return new Promise((resolve, reject)=>{
            let camera = cameraI.getUIBind();
            camera.onOpen().then(()=>{
            backgroundEffectI.onStart().then(()=>{},()=>{})
            .finally(()=>{
              resolve();
            })
          }, ()=>{
            service.onStopCamera().then(()=>{}, ()=>{});
            reject();
          });
        });
    }
    service.onStopCamera = function() {
        return new Promise((resolve, reject)=>{
            let camera = cameraI.getUIBind();
            camera.onStop().then(()=>{}, ()=>{}).finally(()=>{
            if(screenI.isOnFullScreen()) {
              let containers = document.querySelectorAll(_inner.videoCssClassSelector+'-local');
              for(let i=0; i<containers.length; i++) {
                if(containers[i]==screenI.getFullScreenElement()) {
                  screenI.exitFullscreen();
                  break;
                }
              }
            };
            service.onPauseCameraStream().then(()=>{}, ()=>{})
            resolve();
          });
        });
    }
    service.onSetupSpeaker = function() {
      return new Promise((resolve, reject)=>{
        let speaker = speakerI.getUIBind();
        if(!speaker.isOpen) {
          service.onStopSpeaker().then(()=>{}, ()=>{}).finally(()=>{
            resolve();
          });
        } else {
          service.onOpenSpeaker().then(()=>{}, ()=>{}).finally(()=>{
            resolve();
          });
        }
      });
    }
    service.onSetupMic = function() {
      return new Promise((resolve, reject)=>{
        let mic = micI.getUIBind();
        if(!mic.isOpen) {
          service.onStopMic().then(()=>{}, ()=>{}).finally(()=>{
            resolve();
          });
        } else {
          service.onOpenMic().then(()=>{}, ()=>{}).finally(()=>{
            resolve();
          });
        }
      });
    }
    service.onSetupCamera = function() {
      return new Promise((resolve, reject)=>{
        let camera = cameraI.getUIBind();
        if(!camera.isOpen) {
          service.onStopCamera().then(()=>{}, ()=>{}).finally(()=>{
            resolve();
          });
        } else {
          service.onOpenCamera().then(()=>{}, ()=>{}).finally(()=>{
            resolve();
          });
        }
      });
    }
    service.openCamera = function() {
        let camera = cameraI.getUIBind();
        camera.setIsWaiting(true);
        service.onOpenCamera().then(()=>{}, ()=>{})
    }
    service.stopCamera = function() {
        let camera = cameraI.getUIBind();
        camera.setIsWaiting(true);
        service.onStopCamera().then(()=>{}, ()=>{})
    }
    service.toggleCamera = function() {
        handTrackerI.stopHandTracking()
        let camera = cameraI.getUIBind();
        if(camera.isOpen) {
          service.stopCamera();
        } else {
          if(camera.hasPermission) {
            service.openCamera();
          } else {
            camera.onRequestPermission().then(()=>{
              service.onInitCamera().then(()=>{
                setTimeout(() => {
                  service.openCamera();
                }, 500);
              }, ()=>{});
            }, ()=>{});
          }
        }
    }
    service.onInitCamera = function() {
        return new Promise((resolve, reject)=>{
          let deviceId = cameraI.getSelectedDeviceId();
          let camera = cameraI.getUIBind();
          if(!camera.hasPermission) {
            reject();
            return;
          }
          if(backgroundEffectI.isVideoEffectSelected() && backgroundEffectI.isEnabled()){
            backgroundEffectI.onRestart().then(()=>{},()=>{})
            .finally(()=>{
              resolve()
            })
          }else{
            resolve()
          }
        });
    }
    service.onSilentLeave = () => {
        return new Promise((resolve, reject)=>{
          let user = service.getOnWaitingRoom();
          if(!user || !user.id || !userI.isGuest()) {
            reject();
            return;
          }
          let participant = participantI.getParticipantById(user.id);
          if(!participant) {
            reject();
            return;
          }
          callI.onLeaveParticipantFromCall(participant, user.accessToken)
          .then((res)=>{
            resolve(1);
          }, (error)=>{
            reject();
          });
        })
    }
    //run call
    _inner.isSetup = false
    service.setupCall = async () => {
      if(_inner.isSetup) return
      _inner.isSetup = true
      let stream = service.getUIBind();
      stream.closeDrawer();
      streamerI.closeDrawer();
      service.setUserOnWaitingRoom(null);
      callI.clearMessages();
      callI.clearInvitedProfiles();
      callI.setTempAvatar();
      callI.initTempAvatarEventListener();
      service.clearParticipantsInWaitingRoom();
      service.setIsLeavingCall(false);
      callI.unloading = false;
      if (!langI.getUserSelectedLanguageName())
          langI.initSelectedLanguage();
      videoShareI.init({
        onNewVideo:service.MainCallsContainer,
        onDestroy:service.MainCallsContainer
      });
      try{
        const result = await livekitI.onConnectRoom({retries:4});
      }catch(err){
        toastI.error(langI.get('SERVER_CONNECTION_ERROR'))
        return busI.notifyEvent(busI.EVENTS.ON_REQUEST_LEAVE_CALL)
        // if(participantI.isParticipantLegitForWaitingRoom(callI.getActiveParticipant())){
        //   service.setIsOnWaitingRoom(true);
        //   return
        // }
        // toastI.error(msg);
        // service.onDoneSetup();
        // busI.notifyEvent(busI.EVENTS.ON_REQUEST_LEAVE_CALL, 'leave');
      }
      service.onSetup()
      .then(()=>{
        service.initMainContainerObserver();
        if(callI.isActiveCallRecording()&& callI.isActiveCallByHost() && callI.isActiveCallScheduled() && !livekitI.isRecording() && !callI.getIsInternal()){
          livekitI.startRecording()
        }
        livekitI.start();
        service.runPeriodicPing();
        service.isEntering = false;
        setTimeout(()=>{
          service.startCallDuration();
          service.runSanitizeUsers();
          roomI.runCheckRooms();
          if(sipI.getPhoneNumberToCall()) {
            setTimeout(()=>{
              sipI.onInvitePhoneNumber(sipI.getPhoneNumberToCall()).then(()=>{}, ()=>{}).finally(()=>{
                sipI.setPhoneNumberToCall(null);
              });
            }, 4500);
          }
          transcriptionI.updateStateBaseOnParticipants();
        });
      }, (msg)=>{
        toastI.error(msg);
        busI.notifyEvent(busI.EVENTS.ON_REQUEST_LEAVE_CALL, 'leave');
      }).finally(()=>{
        service.onDoneSetup();
      })
    }
    service.onDoneSetup = ()=>{
      _inner.isSetup = false;
      _inner.notifyUI();
    }
    service.onSetup = () => {
        return new Promise((resolve, reject)=>{
          service.onSetupMedia().then(()=>{},()=>{})
          .finally(resolve)
        });
    }
    service.runPeriodicPing = ()=>{
      clearTimeout(_inner.onPingTimer);
      if(!callI.getActiveCallId())
        return;
      _inner.onPingTimer = setTimeout(()=>{
        userI.onPing().then(()=>{}, ()=>{}).finally(()=>{
          service.runPeriodicPing();
        });
      }, 30000);
    }
    ///Events
    service.beforeUnloadNotification = (event) => {
      if(widgetI.isWidget) return true;
      if (!service.isOnWaitingRoom() && !callI.downloading) {
        event.preventDefault();
        event.returnValue = "Are you sure you want to leave/stop this call?";
      }
    }
    service.onUnloadNotification=($event)=>{
      callI.clearOnCall();
      service.sendDataChannel({event: 'leave', streamId: service.getCallStreamId()});
      service.onSilentLeave().then(()=>{}, ()=>{});
      if (!callI.getActiveCall()) {
        if (!callI.downloading) {
          if(service.getCallStream() && !callI.unloading) {
            busI.notifyEvent(busI.EVENTS.ON_REQUEST_LEAVE_CALL, 'leave');
          }
          callI.unloading = false;
        }
      }
    }
    service.isOnOnGoingCall = ()=>{
      return callI.getActiveCallId() && livekitI.isRoomActive()? true: false;
    }
    service.onMessage = (participantId, message) => {
        let obj = message;
        setTimeout(() => {
          if(obj.event) {
            switch(obj.event) {
              case whiteBoardI.getWhiteBoardEventName():
                whiteBoardI.handleEvent(obj)
              break;
              case smartExpertiseI.getPayloadEventName():
                smartExpertiseI.handlePayload(obj)
                break;
              case 'stop':
                if(participantId === callI.getActiveParticipantId())
                  return;
                busI.notifyEvent(busI.EVENTS.ON_REQUEST_LEAVE_CALL, 'leave');
                break;
              case 'leave':
                if(participantId === callI.getActiveParticipantId())
                  return;
                recorderI.onParticipantLeaveCall(participantId);
                setTimeout(()=>{
                  service.onUserLeft(participantId);
                  service.onResize();
                });
                service.removeStream(obj.mre_call_stream_id);
                break;
              case service.EVENTS.CHANGE_ROLE_PARTICIPANT:
                if(!obj.targetId || !callI.getActiveCallId())
                  return;
                let participant = participantI.getParticipantById(obj.targetId);
                if(!participant)
                  return;
                participantI.updateParticipantHost(participant, obj.isHost);
                participantI.updateParticipants([participant]);
                if(obj.isHost && recorderI.isRecorderService())
                  recorderI.onParticipantBecameHost(participant.mre_call_participant_id).then(()=>{}, ()=>{});
                toastI.info(langI.get(obj.isHost? 'PARTICIPANT_PROMOTED': 'PARTICIPANT_DEMOTED', [participant.name]));
                callI.updateCallHost(obj.targetId)
                if(obj.targetId === callI.getActiveParticipantId()) {
                  _inner.ui.isHost = +obj.isHost===1? true: false;
                  let elements = service.getElements();
                  if(elements)
                    for(let id in elements)
                      service.renderToggleButtons(elements[id]["div"], id, true);
                } else{
                  service.removeToggleButtons();
                }
                _inner.notifyUI();
                break;
              case 'record':
                if(!obj.action)
                  return;
                switch(obj.action) {
                  case recorderI.EVENTS.ON_REQUEST_START_RECORDER_SERVICE:
                    recorderI.initRecorderService();
                    break;
                  case recorderI.EVENTS.ON_REQUEST_STOP_RECORDER_SERVICE:
                      if(!recorderI.isRecorderService()) return;
                        recorderI.stopRecorderService(false);
                    break;
                  case recorderI.EVENTS.ON_CONSENT_RECORDING:
                      if(!callI.getActiveCallId() || !participantId || !participantI.getActiveParticipantById(participantId))
                        return;
                      const isResumed = obj.isResumed?true:false;
                      const consentRecording = obj.consentRecording?true:false;
                      recorderI.setConsentRecording(participantId,consentRecording);
                      if(recorderI.isRecorderService()){
                        const callback = ()=>{
                          if(isResumed && consentRecording){
                            setTimeout(()=>{
                              recorderI.onResumeParticipant(participantId).then(()=>{},()=>{})
                            })
                            return
                          }
                          let participant = participantI.getParticipantById(participantId);
                          recorderI.onParticipantCanBeRecorded(participant)
                          .then((p)=>{
                            p.onStartRecording().then(()=>{},()=>{})
                          },()=>{
                            recorderI.onRecordParticipantStep(participantId).then(()=>{},()=>{})
                          })
                          if(recorderI.getScreenShareById(participantId)){
                            recorderI.onScreenShareCanBeRecorded(participantId)
                            .then(p=>{
                              if(p)
                                p.onStartRecording().then(()=>{},()=>{})
                            },()=>{})
                          }
                        }
                      }else{
                        if(participantId === callI.getActiveParticipantId() && !consentRecording) {
                          toastI.warning("You decline being recorded, but your presence in the call is still saved.", 0, {fontsize: '.9rem'});
                          return;
                        }
                        if(recorderI.isHost()){
                          if(!consentRecording){
                            toastI.warning(`${participantI.getParticipantNameById(participantId)} has declined being recorded.`, 0, {fontsize: '.9rem'});
                          }
                          const button = document.querySelector(`[toggle-recording='${participantId}']`)
                          if(!button) return
                          button["recording"] = consentRecording ? true : false;
                          button["loading"] = false;
                          button.dispatchEvent(new Event('update'))
                          button.style.display = 'inherit'
                        }
                      }
                    break;
                  case recorderI.EVENTS.ON_RECORDER_OPEN:
                    if(recorderI.isRecorderService()) return
                    recorderI.setIsRady(true);
                    if(callI.isActiveCallByHost()) {
                      let recorder = recorderI.getUIBind();
                      recorder.state.isUserBeingRecorded = true;
                      recorderI.exec(recorderI.EVENTS.ON_RECORDER_OPEN);
                      recorderI.onConsentRecording(true);
                    }else {
                      modalI.onDisplayRecordingConfirmationModal()
                      .then((consentRecording)=>{
                        let recorder = recorderI.getUIBind();
                        recorder.state.isUserBeingRecorded = consentRecording? true: false;
                        recorderI.onConsentRecording(consentRecording).then(()=>{},()=>{});
                        recorder.state.isOpen = true;
                        if(recorder.state.isUserBeingRecorded) {
                          recorderI.exec(recorderI.EVENTS.ON_RECORDER_OPEN);
                        }
                      }, ()=>{});
                    }
                    break;
                  case recorderI.EVENTS.ON_RECORDER_CLOSE:
                      if(recorderI.isRecorderService()) return
                        recorderI.setIsOpen(false);
                        recorderI.exec(recorderI.EVENTS.ON_RECORDER_CLOSE)
                      break;
                  case recorderI.EVENTS.ON_TOGGLE_PARTICIPANT_RECORDING:
                        const mre_call_participant_id = obj.mre_call_participant_id;
                        const recording = obj.recording;
                        if(recorderI.isRecorderService()){
                          if(!recording){
                            recorderI.onPauseParticipant(mre_call_participant_id).then(()=>{},()=>{})
                          }
                          return
                        }
                        if(callI.getActiveParticipantId() === mre_call_participant_id){
                          if(recording) {
                            modalI.onDisplayRecordingConfirmationModal()
                            .then((consentRecording)=>{
                              let recorder = recorderI.getUIBind();
                              recorder.state.isUserBeingRecorded = consentRecording? true: false;
                              recorderI.onConsentRecording(consentRecording, true).then(()=>{},()=>{});
                              recorder.state.isOpen = true;
                              if(recorder.state.isUserBeingRecorded) {
                                toastI.info(langI.get('RECORDING_PARTICIPANT_RESUMED'));
                                recorderI.exec(recorderI.EVENTS.ON_RECORDER_OPEN);
                              }
                            }, ()=>{});
                          } else {
                            let recorder = recorderI.getUIBind();
                            recorder.state.isUserBeingRecorded = false;
                            recorder.state.isOpen = false;
                            toastI.info(langI.get('RECORDING_PARTICIPANT_PAUSED'))
                          }
                        }
                    break;
                  default: break;
                }
                break;
              case 'videoshare':
                if(obj.identity === callI.getActiveParticipantId())
                  return;
                videoShareI.onreceive(obj);
                break;
              case service.EVENTS.RAISE_HAND:
                if(participantId === callI.getActiveParticipantId())
                  return;
                service.updateParticipantRaiseHand(participantId, true);
                notificationI.playAudio(notificationI.AUDIO_KEYS.RAISE_HAND);
                toastI.info(langI.get('PARTICIPANT_RAISE_HAND', [participantI.getParticipantNameById(participantId)]), null, {closable: true});
                break;
              case service.EVENTS.LOWER_HAND:
                if(obj.targetId) {
                  service.updateParticipantRaiseHand(obj.targetId, false);
                }
                break;
              case service.EVENTS.REACTION:
                service.handleReactionReceived(participantId, obj?.reaction)
                break;
              case service.EVENTS.MOVE_TO_WAITING_ROOM:
                if(!obj.id) return
                if(obj.id === callI.getActiveParticipantId()) {
                  service.onChangeParticipantWaitingRoom(obj.id, true)
                  .then(()=>{
                    busI.notifyEvent(busI.EVENTS.ON_REQUEST_MOVE_TO_WAITING_ROOM);
                  },()=>{});
                } else {
                  let participant = participantI.getParticipantById(obj.id)
                  if(participant) {
                    participant.sent_to_waiting_room = true;
                    participant.isLoadingChangeWaitingRoom = false;
                  }
                  _inner.notifyUI();
                  toastI.info(langI.get('PARTICIPANT_MOVED_INTO_WAITING_ROOM'));
                  setTimeout(()=>{
                    service.MainCallsContainer();
                  })
                }
                break;
              default: break;
            }
          }
        });
    }
    service.handleToggleProperty = (property,obj)=>{
      if(typeof obj !== "object" || !obj.participant) return
      let sender = obj.participant;
      let me = participantI.getParticipantById(callI.getActiveParticipantId());
      switch(property){
        case PARTICIPANT_PROPERTY.TRANSCRIPTION:
          if(!me.has_stt_active) {
            toastI.display(langI.get('PARTICIPANT_ASK_OPEN_TRANSCRIPTION',[sender.name]),null,{
              state:'info',
              action:"Enable ?"
            }).then(()=>{
              if(!transcriptionI.isOpen()){
                transcriptionI.getUIBind()?.toggle?.();
              }
            },()=>{});
          } else {
            toastI.info(langI.get('PARTICIPANT_ASK_CLOSE_TRANSCRIPTION',[sender.name]))
            if(transcriptionI.isOpen()){
              transcriptionI.getUIBind()?.toggle?.();
             }
          }
          break;
        case PARTICIPANT_PROPERTY.SPEECH_TO_SPEECH:
          if(!me.has_sts_active) {
            toastI.info(langI.get('PARTICIPANT_ASK_OPEN_SPEECH_TO_SPEECH',[sender.name]))
          } else {
            toastI.info(langI.get('PARTICIPANT_ASK_CLOSE_SPEECH_TO_SPEECH',[sender.name]))
          }
          callI.toggleSTS(!!!me.has_sts_active);
          break;
      }
    }
    service.onUserMessage = (participantId, message) => {
        if(!participantId || !message) return
        let obj = message;
        obj.participant = participantI.getParticipantById(participantId)
        setTimeout(()=>{
          if(obj.event) {
            switch(obj.event) {
              case service.EVENTS.TOGGLE_TRANSCRIPTION:
                service.handleToggleProperty(PARTICIPANT_PROPERTY.TRANSCRIPTION,obj)
                break;
              case service.EVENTS.TOGGLE_SPEECH_TO_SPEECH:
                service.handleToggleProperty(PARTICIPANT_PROPERTY.SPEECH_TO_SPEECH, obj)
                break;
              case service.EVENTS.CAMERA_CHANGE:
                if(!cameraI.isEnabled())
                  toastI.info(langI.get('PARTICIPANT_ASK_OPEN_CAMERA', [participantI.getParticipantNameById(participantId)]));
                else {
                    let camera = cameraI.getUIBind();
                    camera.toggle();
                }
                break;
              case service.EVENTS.MIC_CHANGE:
                if(!micI.isEnabled())
                  toastI.info(langI.get('PARTICIPANT_ASK_OPEN_MIC', [participantI.getParticipantNameById(participantId)]));
                else {
                    let mic = micI.getUIBind();
                    mic.toggle();
                }
                break;
              case service.EVENTS.CHANGE_PARTICIPANT_LANGUAGE:
                if(participantId===callI.getActiveParticipantId())
                  return;
                let participant = participantI.getParticipantById(callI.getActiveParticipantId());
                if(!participant || participant.is_host)
                  return;
                let language = langI.getUserLanguageById(obj.language_id);
                if(!language)
                  return;
                participantI.onUpdateParticipant({callId: callI.getActiveCallId(), participantId: callI.getActiveParticipantId(), data: {language_id: language.language_id}})
                .then((res)=>{
                    if(Array.isArray(res.errors)) {
                        reject(networkI.getErrorMessageFromArr(res.errors));
                        return;
                    }
                    busI.notifyEvent(busI.EVENTS.CHANGE_LANGUAGE, {isChanged: true});
                    toastI.info(langI.get('PARTICIPANT_CHANGING_LANGUAGE_NOTIFY_RECEIVER', [participantI.getParticipantNameById(participantId), language.name]));
                }, (error)=>{})
                break;
              case 'exit':
                if(service.isLeavingCall())
                  return;
                toastI.warning(langI.get('CALL_KICKED'));
                setTimeout(()=>{
                  busI.notifyEvent(busI.EVENTS.ON_REQUEST_LEAVE_CALL, 'leave');
                  setTimeout(()=>{
                    callI.clearStoredActiveCall();
                    callI.removeCall(callI.getActiveCallId());
                    busI.notifyEvent(busI.EVENTS.ON_CALLS_UPDATED);
                  })
                }, 3000);
                break;
              case service.EVENTS.SYNC:
                if(+obj.raiseHand === 1){
                  service.updateParticipantRaiseHand(participantId, obj.raiseHand)
                }
                break;
              default: break;
            }
          }
        });
    }
    ///Recording
    service.removeToggleButtons = () => {
      document.querySelectorAll("[toggle-recording]")
      .forEach(button=>{
        button.parentNode.removeChild(button)
      })
    }
    service.renderToggleButtons = function(div, id, isHost = callI.isActiveCallByHost()) {
      if(!id || !div || !id || !callI.getActiveParticipantId() || callI.getActiveParticipantId() === id  || !isHost || service.isScreenShareConnection(id)) return
      if(!div) return
      setTimeout(()=>{
        const topRow = div.querySelector('.tool-btns')
        if(!topRow) return
        let recorder = recorderI.getUIBind();
        let toggleRecordingButton = document.createElement("button");
        toggleRecordingButton.innerHTML = `<i class='material-icons'>radio_button_checked</i>`
        toggleRecordingButton.style["display"] = recorder.state.isOpen ? 'inherit' : 'none';
        toggleRecordingButton["recording"] = recorderI.isConsentRecording(id);
        toggleRecordingButton["loading"] = typeof recorderI.isConsentRecording(id) !== "boolean" ? true : false;
        toggleRecordingButton.addEventListener('update',()=>{
          toggleRecordingButton.style.color = toggleRecordingButton["recording"] ? '#ff3f34' :  '#000';
          if(!toggleRecordingButton["loading"]){
            toggleRecordingButton.classList.remove('bx-flashing')
          }else{
            toggleRecordingButton.classList.add('bx-flashing')
          }
          if(toggleRecordingButton["loading"]){
            toggleRecordingButton.title = `waiting for participant permission`
            return
          }
          if(toggleRecordingButton["recording"]){
            toggleRecordingButton.title = `Pause recording`
          }else{
            toggleRecordingButton.title = `Resume recording`
          }
        })
        toggleRecordingButton.dispatchEvent(new Event('update'))
        toggleRecordingButton.addEventListener("click",()=>{
          const recording = toggleRecordingButton["recording"];
          if(toggleRecordingButton["loading"]) return
          toggleRecordingButton["loading"] = !recording;
          if(!recording){
            toggleRecordingButton.classList.add('bx-flashing');
          }
          recorderI.onToggleParticipantRecording(id,!recording).then(()=>{},()=>{})
          if(recording){
            toggleRecordingButton["recording"] = false;
            toggleRecordingButton["loading"] = false
          }
          toggleRecordingButton.dispatchEvent(new Event('update'))
        })
        toggleRecordingButton.setAttribute("toggle-recording",id);
        topRow.insertBefore(toggleRecordingButton,topRow.firstChild);
      })
    }
    //end recording
    service.addVideoControls = function(container, id) {
        if(!container)
          return;
        if(service.isVideoFileShareConnection(id)){
          videoShareI.addVideoControls(id,container);
          return
        }
        let streamTools = document.createElement("div");
        streamTools.classList.add("stream-tools", id);

        let topRow =  document.createElement("div");
        topRow.classList.add("stream-tool", "showonhover", "tool-btns");
        let bottomRowWrapper = document.createElement("div");
        bottomRowWrapper.classList.add("flex-col");
        let bottomRow =  document.createElement("div");
        bottomRow.classList.add("stream-tool", "call-btns", "hide-onfullscreen");

        let fullScreenButton = document.createElement("button");
        fullScreenButton.innerHTML = `<i class='bx bx-fullscreen'></i>`
        let PicInPicButton = document.createElement("button");
        PicInPicButton.classList.add('pip-btn');
        PicInPicButton.style.display = "none";
        PicInPicButton.innerHTML = `<span class="material-icons">picture_in_picture_alt</span>`
        let transcriptionButton = document.createElement("button");
        transcriptionButton.innerHTML = `<i class="bx bx-text"></i>`;
        let muteVideoButton = document.createElement("button");
        muteVideoButton.innerHTML = `<span class="material-icons">videocam</span>`;
        let muteMicButton = document.createElement("button");
        muteMicButton.innerHTML = `<span class="material-icons">mic</span>`;
        let speakerBtn = document.createElement("button");
        speakerBtn.innerHTML = `<span class="material-icons">volume_up</span>`;
        let raisehandBtn = document.createElement("button");
        raisehandBtn.innerHTML = `<i class='bx bxs-hand'></i>`;
        let pinButton = document.createElement("button");
        pinButton.setAttribute('containerId', id);
        pinButton.classList.add('if-show-pin');
        pinButton.innerHTML = `<span class="material-icons">push_pin</span>`;

        let screenshotButton = document.createElement("button");
        screenshotButton.classList.add('screenshot-btn');
        screenshotButton.title = "Take a screenshot";
        screenshotButton.style.display = "none";
        screenshotButton.innerHTML = `<i class='bx bx-screenshot'></i>`
        screenshotButton.addEventListener("click", ()=>{
            service.takeScreenShot(service.getElementById(id));
        });

        const dotsButton = document.createElement('button');
        dotsButton.innerHTML = `<i class='bx bx-dots-vertical-rounded'></i>`;
        dotsButton.classList.add('drop-down-button')
        function closeDropDown() {
          busI.unregisterEvent(busI.EVENTS.ON_PARTICIPANT_CONTROLS_STATE_CHANGE, dotsButton.onParticipantControlsUpdated);
          document.removeEventListener('click', closeDropDown)
          document.querySelectorAll('.place-holder-dropdown-ul')
          .forEach(element=>{
            element.classList.remove('active');
          })
        }

        const dropdown = document.createElement('div');
        dropdown.classList.add('place-holder-dropdown');
        //mute
        let participantMuteWrapper = document.createElement('label');
        let muteId = "dropdown-mute-input-"+id;
        participantMuteWrapper.setAttribute('for',muteId)
        participantMuteWrapper.classList.add('participant-card-button');
        participantMuteWrapper.appendChild((()=>{
            let muteText = document.createElement('span');
            muteText.innerText = 'Mute';
            return muteText;
        })())
        let muteCheckBox = document.createElement('input');
        muteCheckBox.type = "checkbox";
        muteCheckBox.id = muteId;
        participantMuteWrapper.input = muteCheckBox;
        participantMuteWrapper.appendChild(muteCheckBox);
        // deafen
        let participantDeafenWrapper = document.createElement('label');
        let deafenId = 'dropdown-deafen-input-'+id;
        participantDeafenWrapper.classList.add('participant-card-button');
        participantDeafenWrapper.setAttribute('for',deafenId)
        participantDeafenWrapper.appendChild((()=>{
            let deafenText = document.createElement('span');
            deafenText.innerText = 'Inaudible';
            return deafenText;
        })())
        let deafenCheckBox = document.createElement('input');
        deafenCheckBox.type = "checkbox";
        deafenCheckBox.id = deafenId;
        participantDeafenWrapper.input = deafenCheckBox;
        participantDeafenWrapper.appendChild(deafenCheckBox);
        // pauseCamera
        let participantpauseCameraWrapper = document.createElement('label');
        let pauseId = 'dropdown-pause-input-'+id;
        participantpauseCameraWrapper.classList.add('participant-card-button');
        participantpauseCameraWrapper.setAttribute('for',pauseId);
        participantpauseCameraWrapper.appendChild((()=>{
            let pauseCameraText = document.createElement('span');
            pauseCameraText.innerText = 'Pause Camera';
            return pauseCameraText;
        })())
        let pauseCameraCheckBox = document.createElement('input');
        pauseCameraCheckBox.type = "checkbox";
        pauseCameraCheckBox.id = pauseId;
        participantpauseCameraWrapper.appendChild(pauseCameraCheckBox);
        participantpauseCameraWrapper.input = pauseCameraCheckBox;

        let muteParticipantObj = {
          innerElement: participantMuteWrapper,
          init: ()=>{
            participantMuteWrapper.input.disabled = false;
            participantMuteWrapper.input.checked = !livekitI.isMicEnabled(id)
          },
          onClick: (event)=>{
            event.stopImmediatePropagation();
            participantMuteWrapper.input.disabled = true;
            livekitI.toggleMute(id);
          }
        }
        let deafenParticipantObj = {
          innerElement: participantDeafenWrapper,
          init: ()=>{
            participantDeafenWrapper.input.disabled = false;
            participantDeafenWrapper.input.checked = livekitI.isInaudible(id)
          },
          onClick: (event) => {
            event.stopImmediatePropagation();
            participantDeafenWrapper.input.disabled = true;
            livekitI.toggleInaudible(id)
          }
        };
        let cameraParticipantObj = {
          innerElement: participantpauseCameraWrapper,
          init: ()=>{
            participantpauseCameraWrapper.input.disabled = false;
            participantpauseCameraWrapper.input.checked = !livekitI.isCameraEnabled(id)
          },
          onClick: (event)=>{
            event.stopImmediatePropagation();
            participantpauseCameraWrapper.input.disabled = true;
            livekitI.togglePause(id)
          }
        }
        // User volume
        let volumeParticipantObj = null;
        if(id !== callI.getActiveParticipantId()) {
          let participantVolumeWrapper = document.createElement('label');
          participantVolumeWrapper.classList.add('user-volume');
          participantVolumeWrapper.appendChild((()=>{
              let volumeText = document.createElement('span');
              volumeText.innerText = 'User volume';
              return volumeText;
          })())
          let userVolumeRange = document.createElement('input');
          userVolumeRange.type = "range";
          userVolumeRange.max = 1;
          userVolumeRange.min = 0;
          userVolumeRange.step = 0.1;
          participantVolumeWrapper.appendChild(userVolumeRange);
          userVolumeRange.onInput =()=>{
            livekitI.getOrSetParticipantVolume(id,userVolumeRange.value)
          }
          userVolumeRange.removeEventListener('input', userVolumeRange.onInput);
          userVolumeRange.addEventListener('input', userVolumeRange.onInput);
          volumeParticipantObj = {
            init: ()=>{
              //IMPORTANT to remove the input listener here before updating
              userVolumeRange.removeEventListener('input', userVolumeRange.onInput);
              userVolumeRange.value = livekitI.getOrSetParticipantVolume(id)
              userVolumeRange.addEventListener('input', userVolumeRange.onInput);
            },
            innerElement: participantVolumeWrapper,
            onClick: (event)=>{
              event.stopImmediatePropagation();
            },
          };
          volumeParticipantObj.init();
        }

        dotsButton.onParticipantControlsUpdated = (participantId)=>{
          muteParticipantObj.init();
          cameraParticipantObj.init();
          deafenParticipantObj.init();
          if(volumeParticipantObj)
            volumeParticipantObj.init();
        }
        dotsButton.addEventListener('click',(e)=>{
          const ul = document.getElementById(`place-holder-dropdown-${id}`);
          if(!ul) return
          if(ul.children.length<1)
            return;
          let elements = ul.querySelectorAll('[data-swistwit]');
          if(elements)
            for(let i=0; i<elements.length; i++) {
              elements[i].textContent = handTrackerI.getTargetId() && handTrackerI.getTargetId() === id? handTrackerI.TEXT.STOP: handTrackerI.TEXT.START;
            }
          ul.classList.toggle('active');
          if(!ul.classList.contains('active')) {
            busI.unregisterEvent(busI.EVENTS.ON_PARTICIPANT_CONTROLS_STATE_CHANGE, dotsButton.onParticipantControlsUpdated);
            return;
          } else
            document.removeEventListener('click', closeDropDown);
          ul.style.position = 'fixed';
          ul.style.left = e.clientX - (dotsButton.clientWidth/2) - (ul.clientWidth) +'px';
          ul.style.top = e.clientY + (dotsButton.clientHeight/2) +'px';
          ul.style.zIndex = 12;
          ul.style.maxHeight = 'auto';
          if(container && dotsButton) {
            ul.style.maxHeight = container.clientHeight - dotsButton.clientHeight + 'px';
            ul.style.overflowY = 'auto';
          }
          busI.unregisterEvent(busI.EVENTS.ON_PARTICIPANT_CONTROLS_STATE_CHANGE, dotsButton.onParticipantControlsUpdated);
          busI.registerEvent(busI.EVENTS.ON_PARTICIPANT_CONTROLS_STATE_CHANGE, dotsButton.onParticipantControlsUpdated);
          for(let i=0; i<ul.children.length; i++) {
            if(ul.children[i].obj && typeof ul.children[i].obj.init==='function') {
              ul.children[i].obj.init();
              if(ul.children[i].obj.isHidden) {
                ul.children[i].classList.add('display-none');
              } else {
                ul.children[i].classList.remove('display-none');
              }
            }
          }
          setTimeout(()=>{
            document.removeEventListener('click', closeDropDown);
            document.addEventListener('click', closeDropDown);
          })
        });

        const dropdownItems = [];

        if(!livekitI.isSipParticipant(id)) {
          let swistwitObj = {
            innerHTML:`<i class="bx bxs-hand"></i><span data-swistwit='${id}'>${handTrackerI.TEXT.START}</span>`,
            onClick:(event)=>{
              closeDropDown();
              if(!cameraI.getUIBind().isOpen){
                toastI.warning(langI.get('MEDIA_CAM_REQUIRED'));
                return
              }
              if(backgroundEffectI.isEnabled() && !handTrackerI.ALLOW_WITH_BACKGROUND_EFFECT){
                toastI.warning(langI.get('SWISTWIT_NO_BACKGROUND_EFFECT'));
                return
              }
              if(handTrackerI.isLoading())
                return;
              handTrackerI.onReady()
              .then(()=>{
                handTrackerI.toggle(id);
              }, (error)=>{
                handTrackerI.stopHandTracking(id);
              });
            }
          }
          let newRoomCallObj = {
            participantId: id,
            innerHTML:`<i class='bx bxs-phone-call'></i><span>Open a private call</span>`,
            onClick: function() {
              if(this.participantId === callI.getActiveParticipantId()) return
              roomI.onOpenRoomWithParticipant(this.participantId).then(()=>{
              },err=>{
                toastI.error(err);
              })
            }
          }
          let lowerHandObj = {
            participantId: id,
            innerHTML:`<i class='bx bxs-hand'></i><span>Lower hand</span>`,
            isHidden: true,
            onClick: function() {
              if(!callI.isActiveCallByHost() && id !== callI.getActiveParticipantId()) return;
              let participant = participantI.getParticipantById(id);
              if(participant) {
                service.runRaiseHandChange(id, false);
                this.isHidden = true;
              }
            },
            init: function() {
              let participant = participantI.getParticipantById(id);
              this.isHidden = participant && participant.is_raisehand && (callI.isActiveCallByHost() || id === callI.getActiveParticipantId())? false: true;
            }
          }
          dropdownItems.push(lowerHandObj);
          dropdownItems.push(swistwitObj);
          if(id !== callI.getActiveParticipantId()) {
            dropdownItems.push(newRoomCallObj);
          }
          dropdownItems.push({isSeparator: true});
          dropdownItems.push(muteParticipantObj);
          dropdownItems.push(deafenParticipantObj);
          dropdownItems.push(cameraParticipantObj);
        }

        if(volumeParticipantObj)
          dropdownItems.push(volumeParticipantObj);
        if(!_inner.elements[id]){
          _inner.elements[id] = {}
        }
        _inner.elements[id].dropdownItems = dropdownItems;

        if(livekitI.isSipParticipant(id) && callI.isActiveCallByHost()) {
          let stopEl = document.createElement('label');
          stopEl.classList.add('participant-card-button');
          stopEl.textContent = 'Stop';
          let stopObj = {
            innerElement: stopEl,
            onClick:(event)=>{
              closeDropDown();
              sipI.stopAllOngoingInvites();
              sipI.kickSipConnection(id);
            }
          }
          dropdownItems.push(stopObj);
        }

        const ul = document.createElement('ul')
        ul.classList.add('place-holder-dropdown-ul');
        ul.id = `place-holder-dropdown-${id}`;
        dropdownItems.forEach(item=>{
          if(item.isSeparator){
            const hr = document.createElement('hr');
            ul.appendChild(hr);
            return;
          }
          const li = document.createElement('li');
          if(item.innerElement){
            li.appendChild(item.innerElement);
          } else {
            li.innerHTML = item.innerHTML ? item.innerHTML : "";
            li.classList.add('inline');
          }
          const wrappedFunction = (event)=>{
            if(typeof item.onClick !== "function") return
              item.onClick(event);
          }
          li.addEventListener('click', wrappedFunction);
          li.classList.add('place-holder-dropdown-li');
          li.obj = item;
          ul.appendChild(li);
        })
        dropdown.appendChild(ul);

        //add language element
        let languageEl = service.constructLanguageEl(id);
        [languageEl, screenshotButton,PicInPicButton, pinButton].forEach( button => {
          topRow.appendChild(button);
        });
        //Add fullscreen button only when available
        if(screenI.isFullScreenAvailable())
          topRow.appendChild(fullScreenButton);
        topRow.appendChild(dotsButton);
        [muteVideoButton, muteMicButton, speakerBtn, transcriptionButton, raisehandBtn].forEach( button => {
          bottomRow.appendChild(button);
        })
        if(!callI.getIsInternal()) streamTools.appendChild(topRow);
        streamTools.appendChild(dropdown)
        bottomRowWrapper.appendChild(bottomRow);

        //add hang up button for sip connection
        if(livekitI.isSipParticipant(id)) {
          let limitInSeconds = sipI.getLimitInSecondsByPhone(livekitI.getParticipantNameById(id));
          let hangUpRow =  document.createElement("div");
          hangUpRow.classList.add('stream-tool', 'justify-end', 'align-center');
          let hangUpButton = document.createElement("button");
          hangUpButton.classList.add('hangup-btn');
          hangUpButton.init = () =>{
            hangUpButton.isConfirmShowing = false;
            hangUpButton.innerHTML = `<span class='material-icons'>call_end</i>`;
          }
          hangUpButton.confirm = () =>{
            hangUpButton.isConfirmShowing = true;
            hangUpButton.innerHTML = `<span class=''>Sure?</i>`;
          }
          hangUpButton.init();
          let counterLabel = null;
          if(+limitInSeconds>0) {
            counterLabel = document.createElement('label');
            counterLabel.limit = limitInSeconds;
            counterLabel.update = ()=>{
              if(counterLabel.limit>=0)
                counterLabel.textContent = (counterLabel.limit+'s');
            }
            counterLabel.init = () => {
              clearTimeout(counterLabel.initTimer);
              counterLabel.initTimer = setTimeout(()=>{
                if(!livekitI.isSipParticipant(id))
                  return;
                if(!callI.getActiveCallId() || !livekitI.getParticipantNameById(id)
                || !sipI.isPhoneConnected(livekitI.getParticipantNameById(id))) {
                  hangUpButton.hang();
                  return;
                }
                let phoneConnectedAt = sipI.getPhoneConnectedAt(livekitI.getParticipantNameById(id));
                if(!(phoneConnectedAt>0)) {
                  container.init();
                  return;
                }

                let duration = Math.floor((Math.abs(userI.getCurrentTimestamp() - phoneConnectedAt) / 1000));
                counterLabel.limit = limitInSeconds - duration;
                if(+counterLabel.limit !== +counterLabel.limit)
                  counterLabel.limit = 0;
                counterLabel.update();
                if(counterLabel.limit<15) {
                  let phone = livekitI.isSipParticipant(id)? livekitI.getParticipantNameById(id): '';
                  if(!phone)
                    return;
                  let sentence = 'Dial call '+ (phone? ('with '+phone): '') + (' is ending in '+counterLabel.limit+'s');
                  if(!counterLabel.relatedToast) {
                    let opt = {containerRef: {}, updateMessage: ()=>{}};
                    counterLabel.relatedToast = opt;
                    sipI.storeToastWarning(phone, counterLabel.relatedToast);
                    toastI.warning(sentence, counterLabel.limit*1000, opt);
                  } else {
                    if(typeof counterLabel.relatedToast.updateMessage === 'function')
                      counterLabel.relatedToast.updateMessage(sentence);
                  }
                }
                if(counterLabel.limit<0) {
                  hangUpButton.hang();
                  return;
                }
                counterLabel.init();
              }, 1000);
            }
            sipI.registerCounterSipInit(livekitI.getParticipantNameById(id), counterLabel.init);
            hangUpRow.appendChild(counterLabel);
          }
          hangUpButton.hang = () => {
            sipI.stopAllOngoingInvites();
            sipI.kickSipConnection(id);
          }
          hangUpRow.appendChild(hangUpButton);
          hangUpButton.addEventListener("click", () => {
            if(!hangUpButton.isConfirmShowing) {
              hangUpButton.confirm();
              setTimeout(()=>{
                hangUpButton.init();
              }, 5000);
              return;
            }
            hangUpButton.hang();
          });
          bottomRowWrapper.appendChild(hangUpRow);
        }

        container.bottomRowWrapper = bottomRowWrapper;
        streamTools.appendChild(bottomRowWrapper);
        container.appendChild(streamTools);

        if (!document.pictureInPictureEnabled){
          PicInPicButton.style.display = 'none';
        }

        transcriptionButton.addEventListener("click", () => {
            let transcription = transcriptionI.getUIBind();
            transcription.toggle()
        })

        muteVideoButton.addEventListener("click", () => {
            let camera = cameraI.getUIBind();
            muteVideoButton.innerHTML = camera.isOpen? `<span class="material-icons">videocam_off</span>`: `<span class="material-icons">videocam</span>`;
            camera.toggle();
        });
        muteMicButton.addEventListener("click", () => {
            let mic = micI.getUIBind();
            muteMicButton.innerHTML = mic.isOpen? `<span class="material-icons">mic_off</span>`: `<span class="material-icons">mic</span>`;
            mic.toggle();
        });

        speakerBtn.addEventListener("click", () => {
            let speaker = speakerI.getUIBind();
            speaker.toggle();
        });

        raisehandBtn.addEventListener("click", () => {
          service.toggleRaiseHand()
        });

        if(screenI.isFullScreenAvailable())
          fullScreenButton.addEventListener("click", () => {
              if(!screenI.isOnFullScreen())
                  screenI.requestFullscreen(container)
                  .then(()=>{
                      service.onExitPictureInPicture().then(()=>{}, ()=>{});
                  }, ()=>{});
              else
                  screenI.exitFullscreen();
              let mic = micI.getUIBind();
              if(mic.isOpen){
                  muteMicButton.innerHTML = `<span class="material-icons">mic</span>`;
              } else {
                  muteMicButton.innerHTML = `<span class="material-icons">mic_off</span>`;
              }
              let camera = cameraI.getUIBind();
              if(camera.isOpen) {
                  muteVideoButton.innerHTML = `<span class="material-icons">videocam</span>`;
              } else {
                  muteVideoButton.innerHTML = `<span class="material-icons">videocam_off</span>`;
              }
          });

        PicInPicButton.addEventListener("click", () => service.togglePictureInPicture(container));

        pinButton.addEventListener("click", (event) => {
          let parentEl = event.target['parentElement'];
          let parent = null;
          while (parentEl){
            if (parentEl.classList.contains(_inner.videoCssClass)) {
              parent = parentEl;
              break;
            }
            parentEl = parentEl['parentElement'];
          }
          if(parentEl&&parentEl.parentElement) {
            if(parentEl.hasAttribute('ispinned') && parentEl.getAttribute('ispinned') == 'true') {
              service.unpinView(parent);
            } else
              service.pinView(parent);
          }
        })
        let videoContainer = container.querySelector('video');
        if(videoContainer) {
          videoContainer.addEventListener("leavepictureinpicture", () => {
            if(PicInPicButton)
              PicInPicButton.style.display = "inherit";
            setTimeout(()=> {
              if(videoContainer.paused)
                videoContainer.play()
                .then(() => {
                })
                .catch(() => {});
            }, 200)
          });
          videoContainer.addEventListener("enterpictureinpicture", () => {
            PicInPicButton.style.display = "none";
          });
        }

        bottomRow['onfullscreenchange'] = ()=>{
          if (screenI.isOnFullScreen()) {
            bottomRow.classList.add('display-onfullscreen')
            bottomRow.classList.remove('hide-onfullscreen')
          } else {
            bottomRow.classList.add('hide-onfullscreen')
            bottomRow.classList.remove('display-onfullscreen')
          }
        }
        busI.unregisterEvent(busI.EVENTS.ON_DISPATCH_FULL_SCREEN_CHANGE, bottomRow['onfullscreenchange']);
        busI.registerEvent(busI.EVENTS.ON_DISPATCH_FULL_SCREEN_CHANGE, bottomRow['onfullscreenchange']);

        if(service.isScreenShareConnection(id)) {
          if(container) {
            let pipbtn = container.querySelector('.pip-btn');
            if (pipbtn) {
              pipbtn['style'].display = 'inherit';
            }
            let screenshotBtn = container.querySelector('.screenshot-btn');
            if (screenshotBtn) {
              screenshotBtn['style'].display = 'inherit';
            }
          }
        }
    }
    ////////DOM elements
    service.startCallDuration = () => {
      let call = callI.getActiveCall();
      if(!call)
        return;
      let startTime = new Date(call.start_time);
      let currentTime = new Date();
      let diff = Math.round(currentTime - startTime);
      let interval = 1;
      let sec = duration(interval, "s");
      let allowedDuration = callI.getAllowedDuration();
      //TODO to remove
      if(!userI.isOnFreeBilling())
        diff = 0;
      let durationValue = userI.isOnFreeBilling()? diff: allowedDuration * 60 * 1000 - (diff>0? diff: 0);
      let mAllowedDuration = duration(durationValue);
      let initialDurationInSeconds = mAllowedDuration.asSeconds();
      let alerts = {
        10: false,
        2: false
      }
      clearInterval(_inner.durationTimer);
      _inner.durationTimer = setInterval(()=>{
        if(!_inner.ui)
          service.notifyUI();
        if(userI.isOnFreeBilling()) {
          mAllowedDuration = mAllowedDuration.add(sec);
          if(_inner.ui)
            _inner.ui.callDuration = mAllowedDuration.format("hh:mm:ss", {trim: false});
          return;
        }
        mAllowedDuration = mAllowedDuration.subtract(sec);
        let seconds = mAllowedDuration.asSeconds();
        let minutesLeftToAlert = 2;
        let secondsLimitAlert = minutesLeftToAlert * 60;
        //we show remaining duration at first 5 seconds or when 2min left
        if(initialDurationInSeconds - seconds < 5 || (seconds < secondsLimitAlert-1)) {
          let remaining = mAllowedDuration.format("hh:mm:ss", {trim: false});
          if(_inner.ui)
            _inner.ui.callDuration = remaining;
        } else {
          let arr = new Date().toLocaleTimeString().split(" ");
          for(let i=0; i<arr.length; i++) {
            if(arr[i].indexOf(':')>-1) {
              let elArr = arr[i].split(":");
              if(elArr.length>2)
                arr[i] = elArr.splice(0, 2).join(":");
                break;
            }
          }
          if(_inner.ui)
            _inner.ui.callDuration = arr.join(" ");
        }
        let minute = Math.ceil(mAllowedDuration.asMinutes());
        for(let k in alerts) {
          if(minute == +k && !alerts[k]) {
            alerts[k] = true;
            toastI.warning(langI.get('CALL_REMAINING_MINUTES', [minute]));
            break;
          }
        }
        //leave call when duration left
        if(seconds < 1) {
          busI.notifyEvent(busI.EVENTS.ON_REQUEST_LEAVE_CALL, 'leave');
        }
      }, interval * 1000);
    }
    service.initMainContainerObserver = () => {
      let ctrl = document.querySelector('#MainCallsContainer-wrapper');
      if(!ctrl)
        return;
      // create an observer instance
      let timeout;
      let observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          clearTimeout(timeout)
          timeout = setTimeout(()=>{
            livekitI.updateAllMicStatus()
            service.MainCallsContainer();
          },100)
        });
      });
      let config = {
        attributes: true,
        childList: true,
        characterData: true
      };
      observer.observe(ctrl, config);
    }
    _inner.onDocumentClickListener = (event)=>{
        clearTimeout(document.body['clickCallBtnTimer']);
        document.body['clickCallBtnTimer'] = setTimeout(()=>{
          let parentEl = event.target['parentElement'];
          let cnt = 0;
          while (parentEl) {
            if (parentEl.classList.contains('call-btn-wrapper') || parentEl.classList.contains('btn-drp') || parentEl.classList.contains('btn-drp-menu')) {
              parent = parentEl;
              break;
            }
            parentEl = parentEl['parentElement'];
            cnt++;
            if (cnt == 10) {
                let mic = micI.getUIBind();
                mic.isDropdownOpen = false;
                let speaker = speakerI.getUIBind();
                speaker.isDropdownOpen = false;
              break;
            }
          }
        }, 100)
    }
    service.addContainerClickListener = ()=>{
        document.body.removeEventListener('click', _inner.onDocumentClickListener);
        document.body.addEventListener('click', _inner.onDocumentClickListener);
    }
    service.updateElementMicLevel = (el, level, vol) => {
        if(!el)
          return;
        if(el.mic_status && typeof el.mic_status.updateStatus==='function') {
          el.mic_status.updateStatus(level, vol);
        }
    }
    service.updateElementRaiseHandStatus = (el, status) => {
      if(!el || status == null || status == undefined) return;
      if(el.raisehand_status) {
        if(status){
          el.raisehand_status.classList.add('active');
        } else {
          el.raisehand_status.classList.remove('active');
        }
      }
    }
    service.addElement = function(obj) {
        if(!obj || !obj.id)
            return;
        if(!_inner.elements)
            _inner.elements = {}
        _inner.elements[obj.id] = obj;
        if(!service.isScreenShareConnection(obj.id)) {
          let participant = participantI.getParticipantById(obj.id);
          if(participant) {
            service.updateParticipantRaiseHand(obj.id, participant.is_raisehand);
          }
        }
    }
    service.getElementById = function(id) {
        return id && _inner.elements && _inner.elements[id]? _inner.elements[id]: null;
    }
    service.getElements = function() {
        return _inner.elements;
    }
    service.configureElements = function(id, container, screen_name) {
        return new Promise((resolve, reject)=>{
          if (!id || !container || !screen_name || participantI.isInternalParticipant(participantI.getParticipantById(id)) ) {
            reject();
            return;
          }
          if(document.getElementById(id)) return reject();
          setTimeout(()=>{
            let div = container;
            let name = document.createElement("h4");
            name.classList.add("video-overlay");
            let status_div = document.createElement("div");
            status_div.classList.add("status-bar-div");
            let mic_status;
            name.textContent = screen_name;
            mic_status = service.contructMicStatusEl();
            status_div.appendChild(mic_status);
            let raisehand_status = service.constructRaiseHandStatusEl();
            status_div.appendChild(raisehand_status);
            name["participantName"]  = screen_name;
            if(name.textContent)
              status_div.appendChild(name);

            if(div && name.textContent)
              div.appendChild(status_div);
            const video = document.createElement('video');
            const hasVideo = livekitI.hasVideoById(id);
            video.style.display = hasVideo? "block" : "none"
            video.style.position = 'absolute';
            video.setAttribute('autoplay', 'autoplay');
            video.setAttribute('playsinline', 'playsinline');
            video.setAttribute('webkit-playsinline', 'webkit-playsinline'); // legacy
            video.id = livekitI.getVideoElementId(id);
            video.style.width = video.style.height = "100%"
            video.style.objectFit = "cover"
            video.autoplay = true;
            video.srcObject = livekitI.getOrInitStream(id)
            const isMuted = livekitI.getOrSetParticipantMuted(id);
            const inaudible = livekitI.getOrSetParticipantInaudible(id)
            if(isMuted || inaudible || !speakerI.isEnabled()){
              video.muted = true
            }else{
              video.muted = false
            }
            const volume = livekitI.getOrSetParticipantVolume(id);
            if(volume > -1) video.volume = volume
            const avatar = livekitI.isSipParticipant(id) ? service.getSipAvatarEl() : service.getAvatarEl(id);
            avatar.style.display = hasVideo? "none" : "flex"
            container.appendChild(video)
            container.appendChild(avatar)
            let el = {
              id: id,
              name: name,
              div: div,
              video: video,
              status_div: status_div,
              mic_status: mic_status,
              raisehand_status: raisehand_status,
              avatar: avatar
            };
            service.addElement(el);
            service.addVideoControls(container,id);
            livekitI.updateMicStatus(id);
            resolve(el);
          });
        })
    }
    service.runCheckOnUnconfiguredElements = function() {
        setTimeout(()=>{
          service.onResize();
        })
    }
    service.constructLanguageEl = function(participantId) {
      let container = document.createElement("div");
      container.classList.add('language-info-container', 'participant-lang');
      let img = document.createElement('img');
      img.style.width = '18px';
      img.setAttribute('alt', '');
      container.appendChild(img);
      container.relatedImg = img;

      let text = document.createElement('span');
      text.style.fontSize = '.7rem';
      text.textContent = '';
      container.relatedText = text;
      container.appendChild(text);
      container.show = function() {
        container.classList.remove('display-none');
      }
      container.hide = function() {
        container.classList.add('display-none');
      }
      container.update = function(id) {
        if(id !== participantId)
          return;
        let participant = participantI.getParticipantById(id);
        if(!participant)
          return;
        if(participant.canShowLanguageCode) {
          container.relatedImg.src = participant.languageIcon? participant.languageIcon: '';
          container.relatedText.textContent = participant.languageCode? participant.languageCode: '';
          container.show();
        } else {
          container.hide();
        }
      }
      busI.unregisterEvent(busI.EVENTS.ON_PARTICIPANT_LANGUAGE_UPDATED, container.update);
      busI.registerEvent(busI.EVENTS.ON_PARTICIPANT_LANGUAGE_UPDATED, container.update);
      container.hide();
      container.update(participantId);
      return container;
    }
    service.contructMicStatusEl = function() {
        let container = document.createElement("div");
        container.classList.add('mic-status-container');

        let mic = document.createElement('DIV');
        mic.classList.add('mic-status');
        mic.innerHTML = "<i class='bx bxs-microphone'></i><i class='bx bxs-microphone-off'></i>";

        let micVolume = document.createElement('DIV');
        micVolume.classList.add('mic-volume');
        micVolume.innerHTML = "<i class='bx bxs-volume-low volume-icon low-volume'></i>";

        container.mic = mic;
        container.micVolume = micVolume;

        container.appendChild(mic);
        container.appendChild(micVolume);

        container.cssClasses = {
          disabled: 'disabled',
          showVolume: 'show-volume',
          lowVolume: 'low-volume',
          active: 'active'
        }

        container.updateVolume = function(volume) {
          if(this.mic.classList.contains(container.cssClasses.disabled)) {
            if(this.micVolume.classList.contains(container.cssClasses.showVolume))
              this.micVolume.classList.remove(container.cssClasses.showVolume);
            return;
          }
          if(volume>=.4) {
            if(this.micVolume.classList.contains(container.cssClasses.showVolume))
              this.micVolume.classList.remove(container.cssClasses.showVolume);
            if(this.micVolume.classList.contains(container.cssClasses.lowVolume))
              this.micVolume.classList.remove(container.cssClasses.lowVolume);
            return;
          }
          if(!this.micVolume.classList.contains(container.cssClasses.showVolume)) {
            this.micVolume.classList.add(container.cssClasses.showVolume);
          }
          if(!this.micVolume.classList.contains(container.cssClasses.lowVolume))
              this.micVolume.classList.add(container.cssClasses.lowVolume);
        }
        container.reset = function() {
          if(this.mic.classList.contains(container.cssClasses.active))
            this.mic.classList.remove(container.cssClasses.active);
          if(this.mic.classList.contains(container.cssClasses.disabled))
            this.mic.classList.remove(container.cssClasses.disabled);
        }
        container.disable = function() {
          if(!this.mic.classList.contains(container.cssClasses.disabled))
            this.mic.classList.add(container.cssClasses.disabled);
          if(this.micVolume.classList.contains(container.cssClasses.showVolume))
            this.micVolume.classList.remove(container.cssClasses.showVolume);
        }
        container.active = function() {
          if(this.mic.classList.contains(container.cssClasses.active))
            return;
          this.mic.classList.add(container.cssClasses.active);
        }
        container.inactive = function() {
          if(!this.mic.classList.contains(container.cssClasses.active))
            return;
          this.mic.classList.remove(container.cssClasses.active);
        }
        container.updateStatus = function(level, volume) {
          if(this.mic.classList.contains(container.cssClasses.disabled))
            return;
          if(level > 0.004) {
            this.active();
          } else {
            this.inactive();
          }
          if(+volume===+volume) {
            container.updateVolume(volume);
          }
        }
        return container;
    }
    service.constructRaiseHandStatusEl = function() {
      let container = document.createElement("div");
      container.classList.add('raisehand-status');
      container.innerHTML = "<i class='bx bxs-hand'></i>";
      return container;
    }
    service.getAvatarEl = function(id) {
        if(!id)
            return;
        let img = document.createElement("IMG");
        img.setAttribute("src", participantI.getParticipantAvatar(id));
        let container = document.createElement("DIV");
        container.classList.add("user-avatar-container");
        const imgWrapper = document.createElement('div');
        imgWrapper.classList.add('user-avatar-wrapper');
        const imgOverlay = document.createElement('div');
        imgOverlay.classList.add('profile-picture-overlay');
      if(callI.getActiveParticipantId() === id){
        img.classList.add('clickable')
        let hiddenFileInput = document.createElement('input');
        hiddenFileInput.type = 'file';
        hiddenFileInput.accept = 'image/x-png,image/gif,image/jpeg'
        hiddenFileInput.style.display = 'none';
        hiddenFileInput.addEventListener('change',()=>{
          callI.onHandleChangeTempAvatar(hiddenFileInput.files[0])
          .then((url)=>{
          },(err)=>{
            toastI.error(err)
          })
          .finally(()=>{
            hiddenFileInput.value = null;
          })
        })
        imgWrapper.addEventListener('click',()=>{
          if(callI.isTempAvatarUploading()) return
          hiddenFileInput.click();
        })
        container.appendChild(hiddenFileInput);
      }else{
        imgOverlay.style.display = 'none'
      }
      const uploadIcon = document.createElement('i');
      'fa fa-image overlay-upload-icon'.split(' ')
      .forEach(c=>uploadIcon.classList.add(c))
      const loadingIcon = document.createElement('i');
      'fa fa-circle-o-notch fa-spin overlay-loading-icon'.split(' ')
      .forEach(c=>loadingIcon.classList.add(c))
        imgOverlay.appendChild(uploadIcon);
        imgOverlay.appendChild(loadingIcon);
        imgWrapper.appendChild(img)
        imgWrapper.appendChild(imgOverlay)
        container.appendChild(imgWrapper);
        return container;
    }
    service.getSipAvatarEl = function() {
      let img = document.createElement("IMG");
      img.setAttribute("src", userI.getSipDefaultAvatar());
      let container = document.createElement("DIV");
      container.classList.add("user-avatar-container");
      let subContainer = document.createElement("DIV");
      subContainer.classList.add('user-avatar-wrapper');
      subContainer.appendChild(img);
      container.appendChild(subContainer);
      return container;
  }
    service.togglePictureInPicture = function(div) {
        if(!div)
          return;
        let video = div.querySelector('video');
        if(!video)
          return;
        service.onExitPictureInPicture().then(()=>{}, ()=>{}).finally(()=>{
          service.onRequestPictureInPicture(video).then(()=>{}, ()=>{});
        });
    }
    service.onExitPictureInPicture = function() {
        return new Promise((resolve, reject) => {
            if(document.pictureInPictureElement) {
                document.exitPictureInPicture()
                .then(() => {}, ()=>{}).finally(()=>{
                    resolve();
                });
            } else
                resolve();
        });
    }
    service.onRequestPictureInPicture = function(el) {
        return new Promise((resolve, reject) => {
            if(!el || !el.requestPictureInPicture) {
                reject();
                return;
            }
            if (document.pictureInPictureEnabled) {
                el.requestPictureInPicture()
                .then(() => {})
                .catch((err) => {});
            } else
                resolve();
        });
    }
    service.takeScreenShot = function(element) {
        if(!element)
          return;
        let video = element.video;
        if(!video)
          return;
        let canvas = document.createElement("canvas");
        const ratio = video.videoWidth / video.videoHeight;
        const width = video.videoWidth - 100;
        const height = width / ratio;
        const format = "jpeg";
        canvas.width = width
        canvas.height = height
        let context = canvas.getContext("2d")
        context.drawImage(video,0,0,width,height);
        canvas.toBlob(blob=>{
          if(blob){
            let user = element.name&&element.name.participantName? element.name.participantName.trim(): '';
            const name = `screenshot_${user}_${Date.now()}.${format}`;
            const file = new File([blob], name , { type: `image/${format}` });
            try {
              toastI.info(langI.get('TAKING_SCREENSHOT'));
              chatI.onSendMreCallChat({MreCallID: callI.getActiveCallId(), MreCallParticipantID: callI.getActiveParticipantId(), message: name, fileName: name, file: file, speech_to_text: null})
              .then(()=>{
                toastI.success(langI.get("TAKING_DONE"));
              }, (msg)=>{
                toastI.error(msg);
              });
            } catch (error) {
              toastI.error(langI.get('ERROR_UNK'))
            }
          }else{
            toastI.error(langI.get('ERROR_UNK'))
          }
        },`image/${format}`)
    }
    // Set Width and Margin
    service.setWidth = function(width, margin) {
        let MIN_WIDTH = 400;
        let Cameras = document.querySelectorAll('#MainCallsContainer '+_inner.videoCssClassSelector);
        if(Cameras)
            for (var s = 0; s < Cameras.length; s++) {
            const calculatedWidth =  (width < MIN_WIDTH ? MIN_WIDTH : width);
            const isPinned = Cameras[s].hasAttribute('ispinned');
            Cameras[s].style.position = "auto";
            Cameras[s].style.width = (isPinned ? calculatedWidth : width) + "px"
            Cameras[s].style.margin = margin + "px";
            Cameras[s].style.height = width * 0.56 + "px"
            if(isPinned){
              Cameras[s].style.minHeight = calculatedWidth * 0.56 + "px";
            }else{
              Cameras[s].style.maxHeight = "100%";
            }
            }

        let SecondaryCameras = document.querySelectorAll('#SecondaryCallsContainer-wrapper '+_inner.videoCssClassSelector);
        if(SecondaryCameras)
            for (var s = 0; s < SecondaryCameras.length; s++) {
                let camwidth = SecondaryCameras[s].clientWidth;
                SecondaryCameras[s].style.height = (camwidth * 0.56) + "px";
                SecondaryCameras[s].style.minHeight = (camwidth * 0.56) + "px";
            }
    }
    service.Area = function(Increment, Count, Width, Height, Margin = 10) {
        let w = 0
        let i = 0;
        let h = Increment * 0.56 + (Margin * 2);
        while (i < (Count)) {
            if ((w + Increment) > Width) {
                w = 0;
                h = h + (Increment * 0.56) + (Margin * 2);
            }
            w = w + Increment + (Margin * 2);
            i++;
        }
        if (h > Height) return false;
        else return Increment;
    }
    service.MainCallsContainer = function() {
        // variables:
            let Margin = 5;
            let Scenary = document.getElementById('MainCallsContainer');
            const callToolsDrawer = document.getElementById('callToolsDrawer');
            if(!Scenary)
              return;
            let Width = Scenary.offsetWidth - (Margin * 2);
            let Height = ((callToolsDrawer ? callToolsDrawer.offsetHeight : 0) + Scenary.offsetHeight) - (Margin * 2);
            let Cameras = Scenary.getElementsByClassName(_inner.videoCssClass);
            let max = 0;

        // loop
            let i = 1;
            while (i < 5000) {
                let w = service.Area(i, Cameras.length, Width, Height, Margin);
                if (w === false) {
                    max =  i - 1;
                    break;
                }
                i++;
            }

        // set styles
            max = max - (Margin * 2);
            service.setWidth(max, Margin);
        setTimeout(()=>{
          transcriptionI.sanitizeContainerPosition();
        }, 1500);
    }
    service.addDrawerRecognizer = () => {
        if(!hammer)
            return;
        if(!_inner.callToolsDrawerContainer)
            _inner.callToolsDrawerContainer = document.querySelector('#callToolsDrawer');
        if(!_inner.callToolsDrawerContainer)
            return;
        if(!_inner.hammerPan) {
            _inner.hammerPan = new Hammer(_inner.callToolsDrawerContainer);
            _inner.hammerPan.get('pan').set({ direction: Hammer.DIRECTION_ALL });
            _inner.hammerPan.on("panup pandown", (ev) => {
                if(ev){
                    if(ev.type == 'panup'){
                        _inner.ui.isMobileDrawerOpen = true;
                    }else if(ev.type == 'pandown') {
                        _inner.ui.isMobileDrawerOpen = false;
                    }
                }
            });
        }
    }
    service.cleanPins = () => {
        let secContainer = document.querySelector('#SecondaryCallsContainer-wrapper');
        let mainContainer = document.querySelector('#MainCallsContainer');
        if (secContainer && secContainer['children'].length < 1) {
          service.fullMode();
        }
        if (mainContainer && mainContainer['children'].length < 1) {
          let unPinned = document.querySelectorAll(_inner.videoCssClassSelector+':not(.pinned)');
          if (unPinned.length > 0) {
            unPinned.forEach(elem => {
              mainContainer.appendChild(elem);
            })
            service.fullMode();
          }
        }
    }
    service.unpinAllViews = ()=>{
      const pinned = Array.from(document.querySelectorAll('[ispinned="true"]'))
      for(let i = 0 ; i < pinned.length; i++){
          streamI.unpinView(pinned[i])
      }
    }
    service.unpinView = (view, withForce = false) => {
        if (!view) return;
        // if(view.id) _inner.pins.delete(view.id)
        let secondaryCallContainer = document.querySelector('#SecondaryCallsContainer-wrapper');
        if (!secondaryCallContainer) return;
        view.removeAttribute('ispinned');
        view.removeAttribute('style');
        let leftPinned_count = document.querySelectorAll(_inner.videoCssClassSelector+'[ispinned="true"]').length
        if (leftPinned_count == 0) {
          service.fullMode();
          let mainCallContainer = document.querySelector('#MainCallsContainer');
          document.querySelectorAll(_inner.videoCssClassSelector).forEach(elem => {
            if(livekitI.getParticipant(elem.id,true)) mainCallContainer.appendChild(elem);
          });
        } else {
          if(livekitI.getParticipant(view.id,true)) secondaryCallContainer.appendChild(view);
          service.halfMode();
        }
        if(window.innerWidth < 650 && !withForce) {
            service.getActiveParticipants().forEach((participant => {
              if(participant && participant.is_host)
                service.pinView(document.querySelector(_inner.videoCssClassSelector+'[id="'+participant.mre_call_participant_id+'"]'));
            }))
        }
        setTimeout(()=>{
          service.cleanPins();
          setTimeout(()=>{
            service.MainCallsContainer();
          })
        })
    }
    service.pinView = (view) => {
        if (!view || view && !document.contains(view)) return;
        if(view.id) _inner.pins.set(view.id,view);
        let mainCallContainer = document.querySelector('#MainCallsContainer');
        let secondaryCallContainer = document.querySelector('#SecondaryCallsContainer-wrapper');
        if (!mainCallContainer || !secondaryCallContainer) return;
        view.setAttribute('ispinned', 'true');
        if(window.innerWidth < 650 && service.isHalfMode() && mainCallContainer.querySelectorAll(_inner.videoCssClassSelector).length > 0) {
          mainCallContainer.querySelectorAll(_inner.videoCssClassSelector).forEach(elem => {
            service.unpinView(elem, true);
          })
        }
        setTimeout(()=>{
          mainCallContainer.appendChild(view);
          service.halfMode();
        })
        setTimeout(()=>{
          document.querySelectorAll(_inner.videoCssClassSelector+':not([ispinned="true"])').forEach(elem => {
            elem.removeAttribute('style');
            secondaryCallContainer.appendChild(elem);
          })
        })
        setTimeout(()=>{
          service.cleanPins();
          service.MainCallsContainer();
        })
    }
    service.onResize = () => {
        clearTimeout(_inner.screenResizeMediaTimer);
        _inner.screenResizeMediaTimer = setTimeout(() => {
          if (window.innerWidth < 650) {
            let pinned_count = document.querySelectorAll(_inner.videoCssClassSelector+'[ispinned="true"]').length;
            if (pinned_count == 0 || pinned_count > 1){
              let mainCallContainer = document.querySelector('#MainCallsContainer');
              let secondaryCallContainer = document.querySelector('#SecondaryCallsContainer-wrapper');
              if (!mainCallContainer || !secondaryCallContainer) return;
              service.getActiveParticipants().forEach((participant => {
                if (participant.is_host) {
                  service.pinView(document.querySelector(_inner.videoCssClassSelector+'[id="'+participant.mre_call_participant_id+'"]'));
                } else {
                  service.unpinView(document.querySelector(_inner.videoCssClassSelector+'[id="'+participant.mre_call_participant_id+'"]'));
                }
              }))
            }
          }
          service.MainCallsContainer();
        }, 250);
    }
    service.isLeavingCall = ()=>{
        return (_inner.ui && _inner.ui.isLeavingCall) || _inner.isLeavingCall? true: false;
    }
    service.setIsLeavingCall = (val) => {
        _inner.isLeavingCall = +val===1? true: false;
        _inner.notifyUI()
    }
    service.setIsOnWaitingRoom = (val) => {
        _inner.isOnWaitingRoom = +val===1? true: false;
        clearTimeout(_inner.onPingTimer);
        _inner.notifyUI()
    }
    service.notifyActiveParticipantToEnterCall = ()=>{
      if(!callI.getActiveParticipantId())
        return;
      clearTimeout(_inner.enterCallTimer);
      _inner.enterCallTimer = setTimeout(()=>{
        service.setIsOnWaitingRoom(false);
        busI.notifyEvent(busI.EVENTS.ON_RUN_CALL);
      }, 250);
    }
    service.isOnWaitingRoom = () => {
        return (_inner.ui && _inner.ui.isOnWaitingRoom) || _inner.isOnWaitingRoom? true: false;
    }
    service.setUserOnWaitingRoom = (obj) => {
        _inner.userOnWaitingRoom = obj;
    }
    service.getUserOnWaitingRoom = () => {
        return _inner.userOnWaitingRoom;
    }
    service.onParticipantsUpdate = ()=>{
      if(!_inner.ui) service.getUIBind();
    _inner.ui.participants = service.getActiveParticipants();
    }
    _inner.notifyUI = () => {
        if(!_inner.ui)
          service.getUIBind();

        service.onParticipantsUpdate();
        for(let i=0; i<_inner.ui.participants.length; i++) {
          const participant = _inner.ui.participants[i];
          participant.isStreamEnabled = !participant.is_guest || serviceProviderI.isServiceProviderAllowsParticipantstoStream()? true: false;
        }
        _inner.ui.participantsInWaitingRoom = participantI.getParticipantsOnWaitingRoomByCallId(callI.getActiveCallId());
        _inner.ui.displayLangImage = _inner.displayLangImage
        _inner.ui.currentParticipantId = callI.getActiveParticipantId();
        _inner.ui.isStreamEnabled = true;
        _inner.ui.isHost = callI.isActiveCallByHost();
        _inner.ui.isLeavingCall = _inner.isLeavingCall;
        _inner.ui.isOnWaitingRoom = _inner.isOnWaitingRoom;
        _inner.ui.selfRaiseHandStatus = _inner.selfRaiseHandStatus;
        _inner.raisedhandCount = service.getActiveParticipants().filter(x => x?.is_raisehand == true).length;
        _inner.ui.raisedhandCount = _inner.raisedhandCount;
        service.updateIsDrawerOpen();
        callI.updateGlobalCounter();
    }
    service.updateIsDrawerOpen = ()=>{
      clearTimeout(_inner.updateDrawerOpenTimer);
      _inner.updateDrawerOpenTimer = setTimeout(()=>{
        let call = callI.getUIBind();
        let videoShare = videoShareI.getUIBind();
        let poll = pollI.getUIBind();
        _inner.ui.isDrawerOpen = _inner.ui.isParticipantDrawerOpen || _inner.ui.isChatDrawerOpen || call.isChatOpen || videoShare.isDrawerOpen || poll.isDrawerOpen || streamerI.getIsDrawerOpen() || companionI.isDrawerOpen() || transcriptionI.isDrawerOpen()? true: false;
      }, 50);
    }
    service.addRemoveShortcutLanguage = (lang)=>{
      if(!_inner.ui || !lang)
        return;
      if(!_inner.ui.langShortcuts)
        _inner.ui.langShortcuts = [];
      for(let i=0; i<_inner.ui.langShortcuts.length; i++)
        if(_inner.ui.langShortcuts[i].language_id === lang.language_id) {
          _inner.ui.langShortcuts.splice(i, 1);
          return;
        }
      _inner.ui.langShortcuts.push(lang);
    }
    service.getShortcutLanguages = () => {
      return _inner.ui && Array.isArray(_inner.ui.langShortcuts)? _inner.ui.langShortcuts: [];
    }
    service.getUIBind = ()=>{
        if(_inner.ui) {
          _inner.notifyUI();
          return _inner.ui;
        }
        _inner.ui = {
          transcriptionDrawerContainerId: 'transcription-call-drawer-container',
          mainCallsContainerId: 'MainCallsContainer',
          callContentContainerId: 'call-content-container',
          default_avatar: userI.getDefaultAvatar(),
          reactions: _inner.reactions,
          participants: [],
          participantsInWaitingRoom: [],
          currentParticipantId: callI.getActiveParticipantId(),
          isMobileDrawerOpen: false,
          isParticipantDrawerOpen: false,
          isChatDrawerOpen: false,
          isDrawerOpen: false,
          isLeavingCall: false,
          isOnWaitingRoom: false,
          isStreamEnabled: true,
          isHost: callI.isActiveCallByHost(),
          selfRaiseHandStatus: false,
          raisedhandCount: 0,
          langShortcuts: [],
          toggleMobileDrawer: () => {
              streamerI.closeDrawer();
              companionI.closeDrawer();
              _inner.ui.isMobileDrawerOpen = !_inner.ui.isMobileDrawerOpen;
          },
          isSecondaryContainerActive: false,
          toogledrpdwn: (index, event) => {
            dropdownI.toogleDropdown(index, event);
          },
          notifyOpenTranscriptionDrawer: () => {
            streamerI.closeDrawer();
            companionI.closeDrawer();
            _inner.ui.isParticipantDrawerOpen = false;
            let videoShare = videoShareI.getUIBind();
            videoShare.closeDrawer();
            let call = callI.getUIBind();
            call.closeChat();
            let poll = pollI.getUIBind();
            poll.closeDrawer();
            _inner.notifyUI();
          },
          notifyCloseTranscriptionDrawer: () => {
            _inner.notifyUI();
          },
          openChat: () => {
            streamerI.closeDrawer();
            companionI.closeDrawer();
            _inner.ui.isParticipantDrawerOpen = false;
            let videoShare = videoShareI.getUIBind();
            videoShare.closeDrawer();
            let call = callI.getUIBind();
            call.toggleChat();
            let poll = pollI.getUIBind();
            poll.closeDrawer();
            _inner.notifyUI();
          },
          toggleCompanionDrawer: ()=>{
            streamerI.closeDrawer();
            let call = callI.getUIBind();
            call.closeChat();
            _inner.ui.isParticipantDrawerOpen = false;
            let videoShare = videoShareI.getUIBind();
            videoShare.closeDrawer();
            let poll = pollI.getUIBind();
            poll.closeDrawer();
            companionI.toggleDrawer();
            _inner.notifyUI();
          },
          toggleStreamerDrawer: ()=>{
            companionI.closeDrawer();
            let call = callI.getUIBind();
            call.closeChat();
            _inner.ui.isParticipantDrawerOpen = false;
            let videoShare = videoShareI.getUIBind();
            videoShare.closeDrawer();
            let poll = pollI.getUIBind();
            poll.closeDrawer();
            streamerI.toggleDrawer();
            _inner.notifyUI();
          },
          openVideoShareDrawer: () => {
            streamerI.closeDrawer();
            companionI.closeDrawer();
            let call = callI.getUIBind();
            call.closeChat();
            _inner.ui.isParticipantDrawerOpen = false;
            let videoShare = videoShareI.getUIBind();
            videoShare.toggleDrawer();
            let poll = pollI.getUIBind();
            poll.closeDrawer();
            _inner.notifyUI();
          },
          toggleParticipantsDrawer: ()=>{
            streamerI.closeDrawer();
            companionI.closeDrawer();
            let call = callI.getUIBind();
            call.closeChat();
            let videoShare = videoShareI.getUIBind();
            videoShare.closeDrawer();
            _inner.ui.isParticipantDrawerOpen = !_inner.ui.isParticipantDrawerOpen;
            let poll = pollI.getUIBind();
            poll.closeDrawer();
            _inner.notifyUI();
          },
          openPoll: () => {
            streamerI.closeDrawer();
            companionI.closeDrawer();
            let call = callI.getUIBind();
            call.closeChat();
            let videoShare = videoShareI.getUIBind();
            videoShare.closeDrawer();
            _inner.ui.isParticipantDrawerOpen = false;
            let poll = pollI.getUIBind();
            poll.toggleDrawer();
            _inner.notifyUI();
          },
          closeDrawer: ()=>{
            let call = callI.getUIBind();
            call.closeChat();
            let videoShare = videoShareI.getUIBind();
            videoShare.closeDrawer();
            let poll = pollI.getUIBind();
            poll.closeDrawer();
            _inner.ui.isParticipantDrawerOpen = false;
            streamerI.closeDrawer();
            companionI.closeDrawer();
            _inner.notifyUI();
          },
          runDebug: () => {
            let el = document.createElement('textarea');
              try {
                let obj = {};
                obj['call'] = callI.getActiveCall();
                obj['participants'] = service.getActiveParticipants();
                el.value = JSON.stringify(obj);
                document.body.appendChild(el);
                el.select();
                if(navigator.clipboard)
                  navigator.clipboard.writeText(el.value);
                else
                  document.execCommand('copy');
                document.body.removeChild(el);
              } catch (error) {
                if(el&&el.parentElement==document.body)
                  document.body.removeChild(el);
              }
          },
          removeUser: (id)=>{
            let participant = participantI.getParticipantById(id);
            if(!participant || participant.isLoadingKicking)
              return;
            if(participant.is_sip) {
              sipI.kickSipConnection(participant.mre_call_participant_id);
              return;
            }
            let payload = {
                call: callI.getActiveCall(),
                participant: callI.getActiveParticipant(),
                target: participant
            };
            participant.isLoadingKicking = true;
            callI.onRemoveParticipant(payload)
            .then(()=>{
              toastI.info(langI.get('NOTIFY_USER_KICK_RESULT'));
              service.sendDataUser(participant.mre_call_participant_id, {event: 'exit'});
            },(msg)=>{
              toastI.error(typeof msg=='string'? msg: langI.get('ERROR_UNK'));
            }).finally(()=>{
              participant.isLoadingKicking = false;
            });
          },
          admitUser: (id)=>{
            let participant = participantI.getParticipantById(id);
            if(!participant || participant.isLoadingChangeWaitingRoom)
              return;
            participant.isLoadingChangeWaitingRoom = true;
            service.onChangeParticipantWaitingRoom(id, false).then(()=>{},()=>{})
            .finally(()=>{
              let participant = participantI.getParticipantById(id);
              if(participant)
                participant.isLoadingChangeWaitingRoom = false;
            });
          },
          declineUser: (id)=>{
            let participant = participantI.getParticipantById(id);
            if(!participant || participant.isLoadingChangeWaitingRoom)
              return;
            participant.isLoadingChangeWaitingRoom = true;
            const obj = {};
            obj.participantId = participant.mre_call_participant_id;
            obj.callId = callI.getActiveCallId();
            obj.data = {
              is_declined:true
            };
            participantI.onUpdateParticipant(obj)
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    toastI.error(networkI.getErrorMessageFromArr(res.errors))
                    return;
                }
                participantI.updateParticipants([res.MreCallParticipant]);
                let participant = participantI.getParticipantById(id);
                if(participant)
                  participant.isJustDeclined = true;
                _inner.notifyUI();
                setTimeout(()=>{
                  if(participant)
                    participant.isJustDeclined = false;
                });
            }, (error)=>{
              toastI.error(langI.get('ERROR_UNK'))
            }).finally(()=>{
              participant.isLoadingChangeWaitingRoom = false;
            })
          },
          togglePromoteUser: (id)=>{
            let participant = participantI.getParticipantById(id);
            if(!participant || participant.isLoadingPromote)
              return;
            let payload = {
              call: callI.getActiveCall(),
              participant: callI.getActiveParticipant(),
              target: participant,
              isHost: participant.is_host? false: true
            };
            participant.isLoadingPromote = true;
            callI.onTogglePromoteParticipantHost(payload)
            .then((res)=>{
              if(res && res.TargetMreCallParticipant)
                service.sendDataChannel({targetId: res.TargetMreCallParticipant.mre_call_participant_id, isHost: participantI.isParticipantHost(res.TargetMreCallParticipant), event: service.EVENTS.CHANGE_ROLE_PARTICIPANT});
            },(msg)=>{
              toastI.error(typeof msg=='string'? msg: langI.get('ERROR_UNK'));
            }).finally(()=>{
              participant.isLoadingPromote = false;
            });
          },
          lowerHandUser: (participantId)=>{
            if(!participantId)
              return;
            service.runRaiseHandChange(participantId, false);
          },
          toggleParticipantCard: (participant)=>{
            if(!participant)
              return;
            participant.isCardOpen = !participant.isCardOpen;
          },
          toggleParticipantMic: (id)=>{
            if(!id)
                return;
            let participant = participantI.getParticipantById(id);
            if(!participant || participant.isLoadingMute)
              return;
            participant.isLoadingMute = true;
            if(id === callI.getActiveParticipantId()) {
              let mic = micI.getUIBind();
              mic.toggle();
              return;
            }
            if(!callI.isActiveCallByHost()) {
                toastI.warning(langI.get('ACTION_UNAUTHORISED'));
                return;
            }
            if(participant && !participant.is_muted)
                service.onMuteParticipantMic(id).then(()=>{}, ()=>{});
            else {
                service.onUnMuteParticipantMic(id).then(()=>{}, ()=>{});
                toastI.info(langI.get('PARTICIPANT_ASK_OPEN_MIC_NOTIFY_SENDER', [participant.name]));
            }
            service.sendDataUser(id, {event: service.EVENTS.MIC_CHANGE});
            setTimeout(()=>{
              participant.isLoadingMute = false;
            }, 2000);
          },
          toggleParticipantCamera: (id)=>{
            if(!id)
                return;
            let participant = participantI.getParticipantById(id);
            if(!participant || participant.isLoadingCamera)
              return;
            participant.isLoadingCamera = true;
            if(id === callI.getActiveParticipantId()) {
              let camera = cameraI.getUIBind();
              camera.toggle();
              return;
            }
            if(!callI.isActiveCallByHost()) {
                toastI.warning(langI.get('ACTION_UNAUTHORISED'));
                return;
            }
            if(participant && !participant.is_paused) {
              service.onPauseParticipantCamera(id).then(()=>{}, ()=>{});
            }
            else
              toastI.info(langI.get('PARTICIPANT_ASK_OPEN_CAMERA_NOTIFY_SENDER', [participant.name]));
            service.sendDataUser(id, {event: service.EVENTS.CAMERA_CHANGE});
            setTimeout(()=>{
              participant.isLoadingCamera = false;
            }, 2000);
          }
        };
        return _inner.ui;
    }
    service.admitParticipant = (id)=>{
      if(_inner.ui)
        _inner.ui.admitUser(id);
    }
    service.declineParticipant = (id)=>{
      if(_inner.ui)
        _inner.ui.declineUser(id);
    }
    service.updateWaitingRoomParticipants = function(participants) {
      if(!Array.isArray(participants))
          return;
      for(let i = 0 ; i < participants.length; i++){
          if(participantI.isParticipantLegitForWaitingRoom(participants[i]))
              service.onNewParticipantInWaitingRoom(participants[i]);
      }
    }
    service.onNewParticipantInWaitingRoom = (participant,increment=true)=>{
      if(!participant || !participant.is_in_waiting_room || !participant.joined)
          return;
      if(participant){
          participant.is_checked = +increment === 0 ? true : false;
          participant.isLoadingChangeWaitingRoom = false;
          let exist = service.isParticipantInWaitingRoom(participant.mre_call_participant_id);
          if(!exist && _inner.ui){
              _inner.ui.participantsInWaitingRoom.push(participant)
          }
      }
      callI.updateGlobalCounter();
    }
    service.removeParticipantFromWaitingRoom = (id)=>{
      if(!id || !_inner.ui) return
      _inner.ui.participantsInWaitingRoom = _inner.ui.participantsInWaitingRoom.filter(p=>p.mre_call_participant_id !== id)
      callI.updateGlobalCounter();
    }
    service.isParticipantInWaitingRoom = (id)=>{
      if(!id || !_inner.ui) return
        return _inner.ui.participantsInWaitingRoom.find(p=>p.mre_call_participant_id === id)? true : false;
    }
    service.onParticipantLeaveWaitingRoom = (id)=>{
      if(!id || !_inner.ui) return
        _inner.ui.participantsInWaitingRoom = _inner.ui.participantsInWaitingRoom.filter(p=>p.mre_call_participant_id !== id);
        callI.updateGlobalCounter();
    }
    service.onChangeParticipantWaitingRoom = (id, IsInWaitingRoom)=>{
      return new Promise((resolve,reject)=>{
          if(!id) {
              reject();
              return
          }
          let payload = {
            IsInWaitingRoom: +IsInWaitingRoom === 1? true: false
          }
          let url = `/api/participant/${id}/waitingroom`;
          networkI.put({url: url, data: payload})
          .then((res)=>{
            if (Array.isArray(res.errors)) {
                reject(networkI.getErrorMessageFromArr(res.errors));
                return;
            }
            if(res && res.MreCallParticipant) {
              participantI.updateParticipants([res.MreCallParticipant]);
              if(res.MreCallParticipant.mre_call_participant_id === callI.getActiveParticipantId()) {
                callI.setActive({participant: res.MreCallParticipant});
              }
              _inner.notifyUI();
              resolve(res);
            } else
              reject();
          }, (error)=>{
              reject(error);
          });
      })
    }
    service.fullMode = ()=>{
        _inner.ui.isSecondaryContainerActive = false;
    }
    service.halfMode = ()=>{
        _inner.ui.isSecondaryContainerActive = true;
    }
    service.isHalfMode = ()=>{
        return _inner.ui.isSecondaryContainerActive
    }
    service.initTooltips = () =>{
        if(typeof tippy !== 'object')
            return;
        if(_inner.tippyInst && _inner.tippyInst.length > 0){
            _inner.tippyInst.forEach(elem => {
            elem.destroy();
          })
          _inner.tippyInst = [];
        }
        _inner.tippyInst = tippy('.desktop_list .call-btn', {
          content: (reference) => {
            return reference.getAttribute('data-tippy-content');
          },
          onShow: (instance) => {
            instance.setContent(instance.reference.getAttribute('data-tippy-content'));
          },
          allowHTML: true,
          theme: 'light-border',
          trigger: "mouseenter",
          delay: [500,200],
        });
    }
    service.runRaiseHandChange = (participantId, status)=>{
      if(!participantId)
        return;
      service.updateParticipantRaiseHand(participantId, status);
      let evt = status? service.EVENTS.RAISE_HAND: service.EVENTS.LOWER_HAND;
      service.sendDataChannel({event: evt, targetId: participantId});
    }
    service.toggleRaiseHand = () =>{
      if(callI.getActiveParticipantId()){
        _inner.selfRaiseHandStatus = !_inner.selfRaiseHandStatus;
        service.runRaiseHandChange(callI.getActiveParticipantId(), _inner.selfRaiseHandStatus);
        _inner.notifyUI();
      }
    }
    service.sendReaction = (reactionId, emoji) => {
      if(!reactionId) return;
      service.sendDataChannel({event: service.EVENTS.REACTION, reaction:{
        reactionId
      }})
      if(!emoji) return;
      busI.notifyEvent(busI.EVENTS.ON_REQUEST_SEND_MESSAGE, {mre_call_id: callI.getActiveCallId(), message: emoji, isEmoji: true});
    }
    service.handleReactionReceived = (participantId, obj) => {
      if(!obj || !obj.reactionId) return;
      let reactionContainer = document.querySelector('.reactions-container');
      if(!reactionContainer)
        return;
      let reaction = _inner.reactions.list.filter(x => x.id == obj.reactionId);
      if(reaction.length==0)
        return;
      reaction = reaction[0];
      if(!reaction) return;

      let reactionItem = document.createElement('div')
      reactionItem.classList.add('reaction-item');
      reactionItem.innerText = reaction.emoji;
      reactionContainer.appendChild(reactionItem);
      setTimeout(()=>{
        if(reactionItem)
          reactionItem.remove();
      }, 2000)
    }
    service.getParticipantsOnWaitingRoomCounter = ()=> _inner.ui ? _inner.ui.participantsInWaitingRoom.length : 0;
    if(DEBUG){
      service.getInner = ()=> _inner;
      window.streamI = service;
    }
    const applyStyles = (target,styles)=>{
      if(!(target instanceof HTMLElement) || typeof styles !== "object") return
      Object.keys(styles).forEach(key=>target.style[key] = styles[key])
    }
    const debug = function(){
      DEBUG && console.log(`[+] StreamI: `,...arguments)
    }
    service.isElementPinned = (element)=> element instanceof HTMLElement && element.getAttribute('ispinned') === "true"
    return service;
}
export const streamI = StreamI(Hammer);