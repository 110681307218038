import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { busI } from 'src/assets/js/busI';
import { navbarI } from 'src/assets/js/navbarI';
import { userI } from 'src/assets/js/userI';
import { serviceProviderI } from '../../../assets/js/serviceProviderI';
@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private callbacks ={
    onEnter:new Map(),
    onLeave:new Map(),
  }
  private currentPath = null;
  private dashboardRoutes = ['call','calls','profile','settings','contacts','chats','integrations','upgrade-profile'];
  private ignoredRoutes = ["upgrade-profile", "home"]
  private dashboardRouteName = "app";
  private isNavOngoing = false;
  constructor(private router:Router) {}

  public navigateBack(path){
    if(this.isNavOngoing)
      return;
    if(!path) location.replace('/loading')
    path = path[0] === "/" ? path.slice(1) : path;
    this._onLeave(this.currentPath,path);
    path = this.getPath(path);
    this.isNavOngoing = true;
    this.router.navigate([path]).then(()=>{
      this.isNavOngoing = false;
      busI.notifyEvent(busI.EVENTS.ON_ACTIVE_ROUTE_CHANGED,path);
    },()=>{
      this.isNavOngoing = false;
    });
    this.currentPath=path;
    this._onEnter(this.currentPath);
    if(this.ignoredRoutes.indexOf(path) > -1) return
    navbarI.setActiveRoute(path)
  }

  public navigateForward(path) {
    if(this.isNavOngoing)
      return;
    if(!path) location.replace('/loading')
    path = path[0] === "/" ? path.slice(1) : path;
    this._onLeave(this.currentPath,path);
    path = this.getPath(path);
    this.isNavOngoing = true;
    this.router.navigate([path]).then((res)=>{
      this.isNavOngoing = false;
      busI.notifyEvent(busI.EVENTS.ON_ACTIVE_ROUTE_CHANGED,path);
    },(error)=>{
      this.isNavOngoing = false;
    });
    this.currentPath=path;
    this._onEnter(this.currentPath);
    if(this.ignoredRoutes.indexOf(path) > -1) return
    navbarI.setActiveRoute(path);
  }

  public onLeave(path,callback){
    if(!path || typeof callback !== "function") return;
    path = path[0] === "/" ? path.slice(1) : path;
    this.callbacks.onLeave.set(path,callback);
  }

  private _onLeave(path,to){
    if(!path) return
    path = this.replaceDashboardRouteName(path)
    path = path[0] === "/" ? path.slice(1) : path;
    const callback = this.callbacks.onLeave.get(path);
    if(typeof callback !== "function") return;
    callback(to);
  }

  public onEnter(path,callback){
    if(!path || typeof callback !== "function") return;
    path = path[0] === "/" ? path.slice(1) : path;
    this.callbacks.onEnter.set(path,callback);
  }
  private replaceDashboardRouteName = (path)=>{
    if(typeof path !== "string") return
    if(path.startsWith(this.dashboardRouteName)){
      path = path.split(this.dashboardRouteName)[1]
    }
    return path.trim()
  }
  private _onEnter(path){
    if(typeof path !== "string") return
    path = this.replaceDashboardRouteName(path)
    path = path[0] === "/" ? path.slice(1) : path;
    const callback = this.callbacks.onEnter.get(path);
    if(typeof callback !== "function") return;
    callback();
  }
  private getPath(path){
    if(!path) return;
    path = path[0] === "/" ? path.slice(1) : path;
    if((path.indexOf('contacts')>-1 && serviceProviderI.hasContactsDisabled())
    || (path.indexOf('chats')>-1 && serviceProviderI.hasDirectChatDisabled()))
      path = 'calls';
    if(this.dashboardRoutes.indexOf(path) > -1) return `${this.dashboardRouteName}/${path}`
    if(!this.router || !Array.isArray(this.router.config))
      return path;
    let paths = this.router.config;
    let found = false;
    for(let i=0; i<paths.length; i++) {
      if(paths[i].path == path) {
        found = true;
        break;
      }
    }
    if(found)
      return path;
    return userI.getStoredAuth()? 'calls': navbarI.getLandingPage();
  }
  getDashboardRouteName(){
    return this.dashboardRouteName
  }
}
