let UtilI = function (window) {
    let service = {};
    let _inner = {};
    _inner.localStorage = {};
    _inner.sessionStorage = {};
    service.updateLocalStorage = function(key, value) {
        if(!key)
            return;
        try {
            if(typeof window.localStorage==='object' && key)
                window.localStorage.setItem(key, value);
            else
                _inner.localStorage[key] = value;
        } catch(e){
            _inner.localStorage[key] = value;
        }
    }
    service.getFromLocalStorage = function(key) {
        if(!key)
            return;
        try {
            if(typeof window.localStorage==='object' && key)
                return window.localStorage.getItem(key);
            else
                return _inner.localStorage[key];
        } catch (error) {
            return _inner.localStorage[key];
        }
    }
    service.removeFromLocalStorage = function(key) {
        if(!key)
            return;
        try {
            if(key && typeof window.localStorage==='object')
                window.localStorage.removeItem(key);
            else
                delete _inner.localStorage[key];
        } catch (error) {
            delete _inner.localStorage[key];
        }
    }
    service.updateSessionStorage = function(key, value) {
        if(!key)
            return;
        try {
            if(typeof window.sessionStorage==='object' && key)
                window.sessionStorage.setItem(key, value);
            else
                _inner.sessionStorage[key] = value;
        } catch(e){
            _inner.sessionStorage[key] = value;
        }
    }
    service.getFromSessionStorage = function(key) {
        if(!key)
            return;
        try {
            if(typeof window.sessionStorage==='object' && key)
                return window.sessionStorage.getItem(key);
            else
                return _inner.sessionStorage[key];
        } catch (error) {
            return _inner.sessionStorage[key];
        }
    }
    service.removeFromSessionStorage = function(key) {
        if(!key)
            return;
        try {
            if(key && typeof window.sessionStorage==='object')
                window.sessionStorage.removeItem(key);
            else
                delete _inner.sessionStorage[key];
        } catch (error) {
            delete _inner.sessionStorage[key];
        }
    }
    service.getEmailPattern = (regex=false)=>{
        if(regex){
            return /^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+$/
        }
        return "^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+$";
    }
    service.isValidEmail = (email)=>{
        if(!email) return false;
        const regex = service.getEmailPattern(true);
        return regex.test(email)
    }
    return service;
}
export const utilI = UtilI(window);