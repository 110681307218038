import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SideMenuComponent } from "./side-menu.component";

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [SideMenuComponent],
    exports: [SideMenuComponent]
})
export class SideMenuComponentModule {}
