import { networkI } from "./networkI";
import { serviceProviderI } from "./serviceProviderI";
import { toastI } from "./toastI";
import { userI } from "./userI";

let LangI = function () {
    let _inner = {
        locale: 'eng',
        empty_data: '',
        default_lang: 'eng'
    };
    _inner.languages = {};
    _inner.adminLanguages = {};
    _inner.providerLanguages = {};
    _inner.userSelectedLanguage = null;
    let service = {};

    service.MODALS = {
        SELECT_LANGUAGE_MODAL_ID: 'select-language',
        EDIT_LANGUAGE_OPTIONS_MODAL_ID: 'edit-language-options'
    }

    service.langs = ['eng', 'fr', 'es'];

    _inner.eng = {
        // USERS - FRIENDS - ACCOUNT
        USER_SENT_INVITE: "{1} sent you a friend request.",
        USER_FRIEND_INVITE_ADDED: "{1} is added as a friend.",
        USER_FRIEND_INVITE_ACCEPTED: "{1} has accepted your friendship request.",
        USER_FRIEND_INVITE_CANCELED: "{1} cancelled the friend request.",
        USER_FRIEND_INVITE_DECLINED: "{1} has declined your friendship request.",
        USER_FRIEND_INVITE_REDUNDANT: "{1} is already added.",
        USER_FRIEND_REMOVED: "{1} is removed from your friends.",
        USER_CALL_INVITE_SENT: "{1} is successfully invited.",
        USER_SMS_INVITE_SENT: "SMS sent to {1} successfully.",
        USER_VERIFY_ACCOUNT: "You need to verify your email address to keep using this account",
        USER_VERIFY_ACCOUNT_INVITE: "Your email address is not verified. Please complete the verification.",
        USER_VERIFY_ACCOUNT_PASSWORD: "Your password was changed successfully. but you need to verify your email address to keep using this account.",

        USER_CONNECTED: "{1} became online.",
        USER_DISCONNECTED: "{1} became offline.",
        ACCOUNT_SUSPENDED: "Your account has been suspended.",
        EMAIL_INVITED: "Your friend request sent to  {1}",
        ERROR_SAME_USER: "Sorry, this user is either your account or an unexpected error occurred.",

        ACCOUNT_UPDATE_SUCCESS: "Profile updated succesfully.",
        ACCOUNT_DELETE_SUCCESS: "The account has been successfully deactivated",
        ACCOUNT_UPDATE_FAIL_VERIFY:"Email verification is not successful. Please verify your email address to update your profile.",
        ACCOUNT_LOGIN_FAIL_VERIFY:"Email verification is not successful. Please verify your account to login.",
        ACCOUNT_CALL_NUMBER:"Please enter the call number to join with your account.",
        AVATAR_UPDATE_SUCCESS: "Image updated succesfully.",
        TAKING_NOTES_ENABLED: "Notes taking is enabled now.",
        TAKING_NOTES_DISABLED: "Notes taking is disabled now.",

        PASSWORD_CHANGE_SUCCESS: "Your password was changed successfully.",
        PASSWORDS_DONT_MATCH: "Passwords do not match.",
        ERROR_PASSWORD_IDENTICAL: "New Password cannot be the same as Current Password.",
        INCORRECT_PASSWORD: "Incorrect password, please try again",

        //CHAT
        CONVERSATION_CHAT_STOPPED: "Conversation with {1} has been deleted.",
        CONVERSATION_CHAT_STARTED: "Conversation with {1} has been created.",
        CONVERSATION_PARTICIPANT_KICKED: "{1} kicked by host.",
        CONVERSATION_PARTICIPANT_ADDED: "{1} added by host.",
        CONVERSATION_PARTICIPANT_LEFT: "{1} has left the chat.",
        GROUP_CHAT_INFO_UPDATED: "Group chat info updated successfully",


        // CALL
        CALL_INVITE_DECLINED: "{1} has declined the call invite {2}.",
        CALL_INVITE_ACCEPTED: "{1} has accepted the call invite {2}.",
        CALL_INVITE_CANCELLED: "{1} has cancelled your invite to the call {2}.",
        CALL_UPDATED: "Call successfully updated",
        CALL_SCHEDULE_PAST: "Sorry, you can't schedule a call in the past.",
        NO_MORE_CALLS: "No More Calls.",
        ATTENDANCE_CONFIRMED: "Thank you, your attendance has been confirmed",
        ATTENDANCE_DECLINED: "Your attendance has been declined successfully",
        MOVED_INTO_WAITING_ROOM: "Host has moved you into waiting room",

        ERROR_JOIN_CALL: "Sorry, an error occurred while joining the call.",
        ERROR_INVALID_TOKEN: "This call is not active anymore.",
        ERROR_CALL_INACTIVE: "Please enter a valid Token.",
        ERROR_CALL_NOTFOUND: "No Call found.",
        ERROR_INACTIVE_CALL: "This call is not active anymore.",
        ERROR_RECORDING_DECLINED: "Sorry, an error occurred while joining the call.",
        ERROR_PARTICIPANT_NOTFOUND_HOST: "Sorry, participant other than host does not exist anymore on this call.",

        CONNECTION_FAIL: "Failed opening connection.",
        CONNECTION_SUCCESS: "Connected.",

        TAKING_SCREENSHOT: "Taking your screenshot, please wait ...",
        TAKING_DONE: "Please find your screenshot in the chat area.",
        PREPARING_SWISTWIT: "Preparing, please wait...",
        SWISTWIT_READY: "SWISTWIT ready.",
        SWISTWIT_STARTED: "{1} started SWISTWIT.",
        SWISTWIT_STOPPED: "{1} stopped SWISTWIT.",
        SWISTWIT_NO_BACKGROUND_EFFECT: 'Please stop background effect to continue',


        PARTICIPANT_PROMOTED: "{1} has been promoted to be the host for this call.",
        PARTICIPANT_DEMOTED: "{1} is no longer the host for this call.",
        PARTICIPANT_ASK_OPEN_MIC: "{1} is asking to enable your mic.",
        PARTICIPANT_ASK_OPEN_MIC_NOTIFY_SENDER: "We are asking {1} to enable his mic.",
        PARTICIPANT_CHANGING_LANGUAGE_NOTIFY_SENDER: "We are changing {1}\'s language",
        PARTICIPANT_CHANGING_LANGUAGE_NOTIFY_RECEIVER: "{1} has set your language to {2}",
        PARTICIPANT_ASK_OPEN_CAMERA: "{1} is asking to enable your camera.",
        PARTICIPANT_ASK_OPEN_CAMERA_NOTIFY_SENDER: "We are asking {1} to enable his camera.",
        PARTICIPANT_RAISE_HAND: "{1} raised his hand.",
        PARTICIPANT_LOWER_HAND: "{1} lowered his hand.",
        CALL_KICKED: "You have been removed from this call by host.",
        NOTIFY_USER_KICK_RESULT: "Participant will be removed in few seconds.",
        CALL_REMAINING_MINUTES: "{1} minutes remaining in this call.",
        PARTICIPANT_MOVED_INTO_WAITING_ROOM: "Participant will be moved into waiting room.",
        PARTICIPANT_DECLINED: "Sorry, the host did not allow you to enter",
        PARTICIPANT_ASK_OPEN_TRANSCRIPTION: "{1} is suggesting that you open transcription",
        PARTICIPANT_ASK_CLOSE_TRANSCRIPTION: "{1} has closed transcription for you",
        PARTICIPANT_ASK_OPEN_SPEECH_TO_SPEECH: "{1} has enabled speech to speech for you",
        PARTICIPANT_ASK_CLOSE_SPEECH_TO_SPEECH: "{1} has disabled speech to speech for you",

        SCREENSHARE_STARTED: "{1} started sharing the screen.",
        SCREENSHARE_STOPPED: "{1} stopped sharing the screen.",
        SCREENSHARE_PCONLY: "Screen share is only available for PC version.",

        RECORDING_START: "This call is being recorded.",
        RECORDING_STOP: "Recording has ended.",
        RECORDING_ACCEPT: "You have to accept recording request to stay in this call.",
        RECORDING_DECLINED: "You can't join to this call since you declined recording.",
        RECORDING_PARTICIPANT_PAUSED: "Your recording is paused by the host.",
        RECORDING_PARTICIPANT_RESUMED: "Your recording is resumed by the host.",
        RECORDING_SCREENSHARE_PAUSED: "Your screen share recording is paused by the host.",
        RECORDING_SCREENSHARE_RESUMED: "Your screen share recording is resumed by the host.",
        TRY_AGAIN_OR_CONTACT: "Please try again or contact an administrator.",
        RECORDING_PASS_CODE_CHANGED: "Call recording pass policy updated successfully.",

        WHITEBOARD_STARTED: "{1} started using the Whiteboard.",
        WHITEBOARD_CLEARED: "{1} cleared the Whiteboard.",
        WHITEBOARD_SWITCH: "{1} has switched to the whiteboard {2}",
        DELETE: "Delete",
        DELETE_CONFIRM: "Sure ?",
        NO_DESCRIPTION: "no description",
        WHITEBOARD_DELETED_BY: "{1} deleted the Whiteboard",
        WHITEBOARD_CREATED: "Whiteboard created successfully",
        WHITEBOARD_DELETED: "Whiteboard deleted successfully",
        WHITEBOARD_UPDATED: "Whiteboard updated successfully",
        WHITEBOARD_CREATED_ERROR: "Error while creating the Whiteboard",
        WHITEBOARD_DELETED_ERROR: "Error while deleting the Whiteboard",
        WHITEBOARD_UPDATED_ERROR: "Error while updating the Whiteboard",

        ACTION_UNAUTHORISED: "You do not have permission to use this action.",

        // MEDIA
        MEDIA_OUTPUT_NOTFOUND: "Couldn't find any output device.",
        MEDIA_ERROR_CHANGE_SPEAKER: "Sorry an error occurred while changing speaker",
        MEDIA_MIC_NOTFOUND: "Couldn't find any microphone device.",
        MEDIA_ERROR_CHANGE_MIC: "Couldn't find any microphone device.",
        MEDIA_ERROR_OPEN_MIC: "Couldn't open your microphone device.",
        MEDIA_CAM_NOTFOUND: "Couldn't find any camera device.",
        MEDIA_ERROR_CHANGE_CAM: "Couldn't find any camera device.",
        MEDIA_ERROR_OPEN_CAM: "Couldn't open your camera device.",
        MEDIA_ACCESS_ERROR: "Please check device permission.",
        MEDIA_CAM_REQUIRED: "Please enable your camera first",

        // TRANSCRIPTION - LANGUAGES
        TRANSCRIPION_LANG_UNSUPPORTED: "{1} is not available for text to speech feature yet.",
        TRANSCRIPTION_ERROR: "Sorry an error occurred while preparing transcription. Please close and open again.",
        TRANSCRIPTION_WARN_VIDEO: "Please note that we are unable to get transcribes for the shared video link.",
        ADVICE_OPEN_MIC: "If you want others to hear you, please consider enabling your mic",
        LANG_CHANGED: "Your language is set to {1}.",
        TTS_LANG_UNSUPPORTED: "{1} is not available for text to speech feature yet.",
        ERROR_UNK : "Sorry, an error occurred",
        ERROR_RECORDING_UNK : "Sorry, an error occurred with recording.",
        SUCCESS: "Success.",

        // AUTH
        FAILED_LOGIN: 'Login cancelled or did not fully authorize.',
        FAILED_REGISTER: 'Register cancelled or did not fully authorize.',
        FORGOT_PASSWORD: 'Sorry, an error occurred.',
        VERIFICATION_SUCCESSFUL: 'Verification was successful.',
        SEND_VERIFICATION_CODE: 'Sorry, verification code failed',
        LOAD_LANGUAGES: 'Error while loading languages',
        UPDATE_LANGUAGE_ERROR: 'Error while updating language',
        FAILED_JOIN_CALL: 'Error while joining call',
        FAILED_LOGIN: 'Error while login',
        FAILED_AUTHORISING_LOGIN: "Login cancelled or did not fully authorize.",

        ERROR_SERVICE_PROVIDER_LOAD: "Sorry, an error occurred while loading service providers",
        ERROR_INVALID_PROVIDER_URL: "Please verify the service provider you are already using.",
        ERROR_INVALID_INTEGRATION_URL: "Invalid integration, please contact admins",
        INCOMPLETE_RESPONSE: 'Incomplete response',
        LOGIN_REQUIRED: "Login required to continue",
        SERVER_CONNECTION_ERROR: "Sorry, we have issues connecting to the server",
        SERVER_CONNECTION_RESTORED: "All services back to normal again",

        // FORMS
        CALL_SUBJECT: "call subject",

        FIELD_REQUIRED: "The {1} is required",
        FIELD_INVALID: "Invalid {1}",
        ACTION_CANCEL_SUCCESS: "{1} successfully cancelled",

        FEEDBACK_MSG: "Our experts will contact with you as soon as possible to schedule your demo. Thank you for your feedback!",
        FEEDBACK_SUCCESS: "Thank you for your feedback!",

        // NAV
        CALL_END_BACK_LOGIN: "This call is not active anymore.",
        CALL_ALREADY_ON_DIFF_TAB: "You already have a call open.",
        RECORDING_DECLINED_BACK_LOGIN: "You can't join to this call since you declined recording.",

        //BILLING
        BILLING_SELECT_PLAN: "Please select a billing plan to register.",
        BILLING_INFO_ERR_UNK: 'Unexpected error occurred while loading billing information.',

        JOINING_CALL: "Joining Call",
        PREPARING_CALL: "Preparing Call",
        STARTING_CALL: "Starting Call",
        LEAVING_CALL: "Leaving Call",
        STOPPING_CALL: "Stopping Call",

        //INTEGRATION
        INTEGRATION_ASK_ACCOUNT_TO_CONTINUE: "Please login, or register, to continue {1} integration.",

        //SIP
        SIP_CALLING: "Calling ",
        SIP_INVITATION_CANCELLED_SUCCESSFULLY: "Call cancelled ",
        SIP_ITEM_CREATED: "Item created successfully",
        SIP_ITEM_SAVED: "Item saved successfully",
        SIP_ITEM_DELETED: "Item deleted successfully",

        // Streamer
        STREAM_URL_SHARED_SUCCESSFULLY: "Stream has been shared successfully",
        STREAM_URL_NOT_SHARED_ERROR: "Error while sharing the stream",
        STREAM_URL_STOPPED:"Stream stopped successfully",
        STREAM_URL_STOP_ERROR:"Error while stopping the Stream",
        PARTICIPANT_SHARED_STREAM_URL: "{1} shared a stream",
        PARTICIPANT_STOPPED_STREAM_URL: "{1} stopped the stream",
        STREAM_OUT_PUBLISHED: "the stream has been published successfully",
        STREAM_OUT_PUBLISH_ERROR: "Error while publishing the stream",
        STREAM_OUT_STOPPED: "the stream has been stopped successfully",
        STREAM_OUT_STOP_ERROR: "Error while stopping the stream"
    }

    _inner.fr = {
        USER_SENT_INVITE : "{1} vous a envoyé une demande d'ami.",
        USER_FRIEND_INVITE_ADDED : "{1} est ajouté en tant qu'ami",
        USER_FRIEND_INVITE_ACCEPTED : "{1} a accepté votre demande d'amitié.",
        USER_FRIEND_INVITE_CANCELED : "{1} a annulé la demande d'amitié.",
        USER_FRIEND_INVITE_DECLINED : "{1} a refusé votre demande d'amitié.",
        USER_FRIEND_INVITE_REDUNDANT : "{1} est déjà ajouté.",
        USER_FRIEND_REMOVED : "{1} a été retiré de la liste de vos amis",
        USER_CALL_INVITE_SENT : "{1} a été invité avec succès.",
        USER_SMS_INVITE_SENT: "SMS envoyé à {1} avec succès.",
        USER_VERIFY_ACCOUNT : "Vous devez vérifier votre adresse e-mail pour continuer à utiliser ce compte",
        USER_VERIFY_ACCOUNT_INVITE : "Votre adresse e-mail n'est pas vérifiée. Veuillez compléter la vérification",
        USER_VERIFY_ACCOUNT_PASSWORD : "Votre mot de passe a été modifié avec succès mais vous devez vérifier votre adresse e-mail pour continuer à utiliser ce compte",

        USER_CONNECTED : "{1} s'est connecté.",
        USER_DISCONNECTED : "{1} s'est déconnecté.",
        ACCOUNT_SUSPENDED : "Votre compte a été suspendu.",
        EMAIL_INVITED : "Votre demande d'ami a été envoyée à {1}.",
        ERROR_SAME_USER : "Désolé, cet utilisateur est soit votre compte, soit une erreur inattendue s'est produite.",

        ACCOUNT_UPDATE_SUCCESS : "Les modifications de profil ont été enregistrées avec succès.",
        ACCOUNT_DELETE_SUCCESS: "Le compte a été désactivé avec succès.",
        ACCOUNT_UPDATE_FAIL_VERIFY : "La vérification de l'adresse électronique n'a pas abouti. Vous devrez vérifier votre adresse e-mail pour mettre à jour votre profil.",
        ACCOUNT_LOGIN_FAIL_VERIFY : "La vérification de l'adresse électronique n'a pas abouti. Vous devez vérifier votre compte pour vous connecter.",
        ACCOUNT_CALL_NUMBER : "Veuillez entrer le numéro d'appel à joindre à votre compte",
        AVATAR_UPDATE_SUCCESS: "Image mise à jour avec succès",
        TAKING_NOTES_ENABLED: "La prise de notes est désormais activée",
        TAKING_NOTES_DISABLED: "La prise de notes est désormais désactivée",

        PASSWORD_CHANGE_SUCCESS : "Votre mot de passe a été modifié avec succès",
        PASSWORDS_DONT_MATCH : "Les mots de passe ne correspondent pas",
        ERROR_PASSWORD_IDENTICAL : "Le nouveau mot de passe ne peut pas être le même que le mot de passe actuel",
        INCORRECT_PASSWORD: '"Mot de passe incorrect, veuillez réessayer',

        // CHAT
        CONVERSATION_CHAT_STOPPED: "La conversation avec {1} a été supprimée.",
        CONVERSATION_CHAT_STARTED: "La conversation avec {1} a été créé.",
        CONVERSATION_PARTICIPANT_KICKED: "{1} supprimé par l'hôte",
        CONVERSATION_PARTICIPANT_ADDED: "{1} ajouté par l'hôte",
        CONVERSATION_PARTICIPANT_LEFT: "{1} à quitté la discussion.",
        GROUP_CHAT_INFO_UPDATED: "Les informations de discussion de groupe ont été mises à jour avec succès",


        // APPEL
        CALL_INVITE_DECLINED : "{1} a refusé l'invitation à l'appel {2}.",
        CALL_INVITE_ACCEPTED: "{1} a accepté l'invitation à l'appel {2}.",
        CALL_INVITE_CANCELLED: "{1} a annulé votre invitation à l'appel {2}.",
        CALL_UPDATED : "L'appel a été mis à jour avec succès",
        CALL_SCHEDULE_PAST : "Désolé, vous ne pouvez pas programmer un appel dans le passé",
        NO_MORE_CALLS: "Pas Plus d'appels",
        ATTENDANCE_CONFIRMED: "Merci, votre présence est confirmée",
        ATTENDANCE_DECLINED: "Votre participation a été refusée avec succès",
        MOVED_INTO_WAITING_ROOM: "L'hôte vous a transféré dans la salle d'attente",

        ERROR_JOIN_CALL : "Désolé, une erreur s'est produite lorsque vous avez rejoint l'appel",
        ERROR_INVALID_TOKEN : "Cet appel n'est plus actif",
        ERROR_CALL_INACTIVE : "Veuillez entrer un jeton valide.",
        ERROR_CALL_NOTFOUND : "Aucun appel trouvé",
        ERROR_INACTIVE_CALL : "Cet appel n'est plus actif",
        ERROR_RECORDING_DECLINED : "Désolé, une erreur s'est produite lors de l'enregistrement de l'appel.",
        ERROR_PARTICIPANT_NOTFOUND_HOST : "Désolé, le participant autre que l'hôte n'existe plus sur cet appel.",

        CONNECTION_FAIL : "L'ouverture de la connexion a échoué.",
        CONNECTION_SUCCESS: "Connecté.",

        TAKING_SCREENSHOT : "Réalisation d'une capture d'écran, veuillez patienter...",
        TAKING_DONE : "Veuillez trouver votre capture d'écran dans la zone de discussion.",
        PREPARING_SWISTWIT: "En préparation, veuillez patienter...",
        SWISTWIT_READY: "SWISTWIT prêt.",
        SWISTWIT_STARTED: "{1} a commencé SWISTWIT.",
        SWISTWIT_STOPPED: "{1} a arrêté SWISTWIT.",
        SWISTWIT_NO_BACKGROUND_EFFECT: "Veuillez arrêter l'effet d'arrière-plan pour continuer",

        PARTICIPANT_PROMOTED : "{1} a été promu pour être l'hôte de cet appel.",
        PARTICIPANT_DEMOTED : "{1} n'est plus l'hôte de cet appel.",
        PARTICIPANT_ASK_OPEN_MIC: "{1} vous demande d'activer votre microphone.",
        PARTICIPANT_ASK_OPEN_MIC_NOTIFY_SENDER: "Nous demandons à {1} d'activer son micro.",
        PARTICIPANT_CHANGING_LANGUAGE_NOTIFY_SENDER: "Nous changeons la langue de {1}",
        PARTICIPANT_CHANGING_LANGUAGE_NOTIFY_RECEIVER: "{1} a défini votre langue sur {2}",
        PARTICIPANT_ASK_OPEN_CAMERA: "{1} vous demande d'activer votre camera.",
        PARTICIPANT_ASK_OPEN_CAMERA_NOTIFY_SENDER: "Nous demandons à {1} d'activer sa caméra.",
        PARTICIPANT_RAISE_HAND: "{1} a levé la main.",
        PARTICIPANT_LOWER_HAND: "{1} a baissé sa main.",
        CALL_KICKED : "Vous avez été retiré de cet appel par l'hôte.",
        NOTIFY_USER_KICK_RESULT: "Le participant sera supprimé dans quelques secondes.",
        CALL_REMAINING_MINUTES: "{1} minutes restant pour cet appel.",
        PARTICIPANT_MOVED_INTO_WAITING_ROOM : "Le participant sera transféré dans la salle d'attente.",
        PARTICIPANT_DECLINED: "Désolé, l'hôte ne vous a pas autorisé à participer",
        PARTICIPANT_ASK_OPEN_TRANSCRIPTION: "{1} vous suggère d'ouvrir la transcription",
        PARTICIPANT_ASK_CLOSE_TRANSCRIPTION: "{1} a fermé la transcription pour vous",
        PARTICIPANT_ASK_OPEN_SPEECH_TO_SPEECH: "{1} a activé STS pour vous",
        PARTICIPANT_ASK_CLOSE_SPEECH_TO_SPEECH: "{1} a désactivé STS pour vous",

        SCREENSHARE_STARTED : "{1} a commencé à partager l'écran.",
        SCREENSHARE_STOPPED : "{1} a arrêté de partager l'écran.",
        SCREENSHARE_PCONLY : "Le partage d'écran n'est disponible que pour la version PC.",

        RECORDING_START : "Cet appel est en cours d'enregistrement.",
        RECORDING_STOP : "L'enregistrement est terminé.",
        RECORDING_ACCEPT : "Vous devez accepter la demande d'enregistrement pour rester dans cet appel.",
        RECORDING_DECLINED : "Vous ne pouvez pas vous joindre à cet appel car vous avez refusé l'enregistrement.",
        RECORDING_PARTICIPANT_PAUSED : "Votre enregistrement est mis en pause par l'hôte.",
        RECORDING_PARTICIPANT_RESUMED : "Votre enregistrement est repris par l'hôte.",
        RECORDING_SCREENSHARE_PAUSED : "L'enregistrement de votre partage d'écran est suspendu par l'hôte.",
        RECORDING_SCREENSHARE_RESUMED : "Votre enregistrement de partage d'écran est repris par l'hôte.",
        TRY_AGAIN_OR_CONTACT : "Veuillez réessayer ou contacter un administrateur.",
        RECORDING_PASS_CODE_CHANGED: "La politique de pass d'enregistrement des appels a été mise à jour avec succès.",

        WHITEBOARD_STARTED : "{1} a commencé à utiliser le Tableau blanc.",
        WHITEBOARD_CLEARED : "{1} a effacé le tableau blanc.",
        WHITEBOARD_SWITCH: "{1} a basculé sur le tableau blanc {2}",
        DELETE: "Supprimer",
        DELETE_CONFIRM: "bien sûr ?",
        NO_DESCRIPTION: "Pas de description",
        WHITEBOARD_DELETED_BY: "{1} supprimé le tableau blanc",
        WHITEBOARD_CREATED: "le tableau blanc créé avec succès",
        WHITEBOARD_DELETED: "le tableau blanc supprimé avec succès",
        WHITEBOARD_UPDATED: "le tableau blanc mis à jour avec succès",
        WHITEBOARD_CREATED_ERROR: "Erreur lors de la création du tableau blanc",
        WHITEBOARD_DELETED_ERROR: "Erreur lors de la suppression du tableau blanc",
        WHITEBOARD_UPDATED_ERROR: "Erreur lors de la mise à jour du tableau blanc",


        ACTION_UNAUTHORISED : "Vous n'avez pas la permission d'utiliser cette action.",

        // MEDIA
        MEDIA_OUTPUT_NOTFOUND : "Impossible de trouver un périphérique de sortie.",
        MEDIA_ERROR_CHANGE_SPEAKER : "Sorry an error occurred while changing speaker",
        MEDIA_MIC_NOTFOUND : "Impossible de trouver un périphérique de microphone",
        MEDIA_ERROR_CHANGE_MIC : "Impossible de trouver un dispositif de microphone.",
        MEDIA_ERROR_OPEN_MIC : "Impossible d'ouvrir votre microphone",
        MEDIA_CAM_NOTFOUND : "Impossible de trouver un périphérique caméra.",
        MEDIA_ERROR_CHANGE_CAM : "Impossible de trouver un appareil photo.",
        MEDIA_ERROR_OPEN_CAM : "Impossible d'ouvrir votre appareil photo.",
        MEDIA_ACCESS_ERROR: "Veuillez vérifier les permission du media.",
        MEDIA_CAM_REQUIRED: "Veuillez d'abord activer votre appareil photo",

        // TRANSCRIPTION - LANGUES
        TRANSCRIPION_LANG_UNSUPPORTED : "{1} n'est pas encore disponible pour la fonction de synthèse vocale.",
        TRANSCRIPTION_ERROR : "Désolé, une erreur s'est produite",
        TRANSCRIPTION_WARN_VIDEO: "Veuillez noter que nous ne sommes pas en mesure d'obtenir des transcriptions pour le lien vidéo partagé.",
        ADVICE_OPEN_MIC : "Si vous voulez que les autres vous entendent, pensez à activer votre micro",
        LANG_CHANGED : "Votre langue est réglée sur {1}.",
        TTS_LANG_UNSUPPORTED : "{1} n'est pas encore disponible pour la fonction de synthèse vocale",
        ERROR_UNK : "Désolé, une erreur s'est produite",
        ERROR_RECORDING_UNK : "Désolé, une erreur s'est produite avec l'enregistrement.",
        SUCCESS : "Success.",

        FAILED_LOGIN : 'Login cancelled or did not fully authorize.',
        FAILED_REGISTER : 'L\'enregistrement a été annulé ou n\'a pas été entièrement autorisé',
        FORGOT_PASSWORD : 'Sorry, an error occurred.',
        VERIFICATION_SUCCESSFUL: 'Vérification réussi.',
        SEND_VERIFICATION_CODE : 'Sorry, verification code failed',
        LOAD_LANGUAGES : 'Erreur lors du chargement des langues',
        UPDATE_LANGUAGE_ERROR: 'Erreur lors de la mise à jour de la langue',
        FAILED_JOIN_CALL : 'Erreur lors de la connexion à un appel',
        FAILED_LOGIN : 'Erreur lors de la connexion',
        FAILED_AUTHORISING_LOGIN : "La connexion a été annulée ou n'a pas été entièrement autorisée",
        ERROR_SERVICE_PROVIDER_LOAD : "Sorry, an error occurred while loading service providers",
        ERROR_INVALID_PROVIDER_URL: "Veuillez vérifier le fournisseur de services que vous utilisez déjà",
        ERROR_INVALID_INTEGRATION_URL: "Intégration non valide, veuillez contacter les admins",
        INCOMPLETE_RESPONSE : "Réponse incomplète",
        LOGIN_REQUIRED : "Connexion requise pour continuer",
        SERVER_CONNECTION_ERROR: "Désolé, nous avons des problèmes de connexion au serveur",
        SERVER_CONNECTION_RESTORED: "Tous les services reviennent à la normale",

        // FORMES
        CALL_SUBJECT : "Objet de l'appel",

        FIELD_REQUIRED : "Le champ {1} est obligatoire",
        FIELD_INVALID : "Invalide {1}",
        ACTION_CANCEL_SUCCESS : "{1} annulé avec succès",

        FEEDBACK_MSG : "Nos experts vous contacteront dès que possible pour planifier votre démonstration. Merci pour vos commentaires !",
        FEEDBACK_SUCCESS : "Merci pour vos commentaires !",

        // NAV
        CALL_END_BACK_LOGIN : "Cet appel n'est plus actif.",
        CALL_ALREADY_ON_DIFF_TAB: "Vous avez déjà un appel ouvert.",
        RECORDING_DECLINED_BACK_LOGIN : "Vous ne pouvez pas vous joindre à cet appel car vous avez refusé l'enregistrement.",

        //FACTURE
        BILLING_SELECT_PLAN : "Veuillez sélectionner un plan de facturation pour vous enregistrer",
        BILLING_INFO_ERR_UNK: "Une erreur inattendue s'est produite lors du chargement des informations de facturation",

        JOINING_CALL : "rejoindre l'appel",
        PREPARING_CALL : "Préparation de l'appel",
        STARTING_CALL : "Démarrage de l'appel",
        LEAVING_CALL : "Quitter l'appel",
        STOPPING_CALL: "Arrêt d'appel",

        //INTEGRATION
        INTEGRATION_ASK_ACCOUNT_TO_CONTINUE: "Veuillez vous authentifier, ou vous inscrire, pour continuer l\'intégration {1}.",

        //SIP
        SIP_CALLING: "Appel pour ",
        SIP_INVITATION_CANCELLED_SUCCESSFULLY: "Appel annulé ",
        SIP_ITEM_CREATED: "L'objet créé avec succès",
        SIP_ITEM_SAVED: "L'objet enregistré avec succès",
        SIP_ITEM_DELETED: "L'objet supprimé avec succès",

        // Streamer
        STREAM_URL_SHARED_SUCCESSFULLY: "Le flux a été partagé avec succès",
        STREAM_URL_NOT_SHARED_ERROR: "Erreur lors du partage du flux",
        STREAM_URL_STOPPED:"Le flux s'est arrêté avec succès",
        STREAM_URL_STOP_ERROR:"Erreur lors de l'arrêt du Stream",
        PARTICIPANT_SHARED_STREAM_URL: "{1} a partagé un flux",
        PARTICIPANT_STOPPED_STREAM_URL: "{1} a arrêté le flux",
        STREAM_OUT_PUBLISHED: "le flux a été publié avec succès",
        STREAM_OUT_PUBLISH_ERROR: "Erreur lors de la publication du flux",
        STREAM_OUT_STOPPED: "le flux a été arrêté avec succès",
        STREAM_OUT_STOP_ERROR: "Erreur lors de l'arrêt du flux"

    }

    _inner.es = {
        // USUARIOS - AMIGOS - CUENTA
        USER_SENT_INVITE: "{1} te ha enviado una solicitud de amistad",
        USER_FRIEND_INVITE_ADDED: "{1} se añade como amigo",
        USER_FRIEND_INVITE_ACCEPTED: "{1} ha aceptado tu solicitud de amistad",
        USER_FRIEND_INVITE_CANCELED: "{1} ha cancelado la solicitud de amistad",
        USER_FRIEND_INVITE_DECLINED: "{1} ha rechazado tu solicitud de amistad",
        USER_FRIEND_INVITE_REDUNDANT: "{1} ya está agregado",
        USER_FRIEND_REMOVED: "{1} ha sido eliminado de tus amigos",
        USER_CALL_INVITE_SENT: "{1} ha sido invitado con éxito",
        USER_SMS_INVITE_SENT: "SMS enviado a {1} con éxito.",
        USER_VERIFY_ACCOUNT: "Necesitas verificar tu dirección de correo electrónico para seguir usando esta cuenta",
        USER_VERIFY_ACCOUNT_INVITE: "Su dirección de correo electrónico no está verificada. Por favor, complete la verificación",
        USER_VERIFY_ACCOUNT_PASSWORD: "Su contraseña ha sido cambiada con éxito pero necesita verificar su dirección de correo electrónico para seguir usando esta cuenta",

        USER_CONNECTED: "{1} se conectó",
        USER_DISCONNECTED: "{1} se desconectó",
        ACCOUNT_SUSPENDED: "Su cuenta ha sido suspendida",
        EMAIL_INVITED: "Tu solicitud de amistad enviada a {1}",
        ERROR_SAME_USER: "Lo sentimos, este usuario es su cuenta o se ha producido un error inesperado",
        ACCOUNT_UPDATE_SUCCESS: "Los cambios de perfil se han guardado con éxito",
        ACCOUNT_DELETE_SUCCESS: "La cuenta ha sido desactivada exitosamente.",
        ACCOUNT_UPDATE_FAIL_VERIFY: "La verificación del correo electrónico no se ha realizado correctamente. Deberá verificar su dirección de correo electrónico para actualizar su perfil",
        ACCOUNT_LOGIN_FAIL_VERIFY: "La verificación del correo electrónico no se ha realizado correctamente. Deberá verificar su cuenta para iniciar sesión",
        ACCOUNT_CALL_NUMBER: "Por favor, introduzca el número de llamada para unirse a su cuenta.",
        AVATAR_UPDATE_SUCCESS: "Imagen actualizada con éxito",
        TAKING_NOTES_ENABLED: "Tomar notas está habilitado ahora",
        TAKING_NOTES_DISABLED: "Tomar notas está deshabilitado ahora.",

        PASSWORD_CHANGE_SUCCESS: "Su contraseña ha sido cambiada con éxito",
        PASSWORDS_DONT_MATCH: "Las contraseñas no coinciden",
        ERROR_PASSWORD_IDENTICAL: "La nueva contraseña no puede ser la misma que la actual",
        INCORRECT_PASSWORD: "Contraseña incorrecta, inténtelo de nuevo",

        //CHAT
        CONVERSATION_CHAT_STOPPED: "Se ha eliminado la conversación con {1}.",
        CONVERSATION_CHAT_STARTED: "Se ha creado una conversación con {1}",
        CONVERSATION_PARTICIPANT_KICKED: "{1} eliminado por el anfitrión.",
        CONVERSATION_PARTICIPANT_ADDED: "{1} añadido por el anfitrión",
        CONVERSATION_PARTICIPANT_LEFT: "{1} ha dejado el chat.",
        GROUP_CHAT_INFO_UPDATED: "La información del chat grupal se actualizó correctamente",

        // CALL
        CALL_INVITE_DECLINED: "{1} ha rechazado la invitación de llamada {2}.",
        CALL_INVITE_ACCEPTED: "{1} ha aceptado la invitación de llamada {2}.",
        CALL_INVITE_CANCELLED: "{1} ha cancelado tu invitación a la llamada {2}.",
        CALL_UPDATED: "Llamada actualizada con éxito",
        CALL_SCHEDULE_PAST: "Lo sentimos, no se puede programar una llamada en el pasado",
        NO_MORE_CALLS: "No más llamadas",
        ATTENDANCE_CONFIRMED: "Gracias, su asistencia ha sido confirmada",
        ATTENDANCE_DECLINED: "Su asistencia ha sido rechazada con éxito",
        MOVED_INTO_WAITING_ROOM: "El anfitrión te ha trasladado a la sala de espera.",

        ERROR_JOIN_CALL: "Lo sentimos, se ha producido un error al unirse a la llamada",
        ERROR_INVALID_TOKEN: "Esta llamada ya no está activa",
        ERROR_CALL_INACTIVE: "Por favor, introduzca un Token válido",
        ERROR_CALL_NOTFOUND: "No se ha encontrado ninguna llamada",
        ERROR_INACTIVE_CALL: "Esta llamada ya no está activa",
        ERROR_RECORDING_DECLINED: "Lo sentimos, se ha producido un error al entrar en la llamada",
        ERROR_PARTICIPANT_NOTFOUND_HOST: "Lo sentimos, el participante que no es el anfitrión ya no existe en esta llamada",

        CONNECTION_FAIL: "Fallo al abrir la conexión",
        CONNECTION_SUCCESS: "Conectado.",

        TAKING_SCREENSHOT: "Realizando su captura de pantalla, por favor espere...",
        TAKING_DONE: "Por favor, encuentre su captura de pantalla en el área de chat",
        PREPARING_SWISTWIT: "En preparación, por favor espere...",
        SWISTWIT_READY: "SWISTWIT listo.",
        SWISTWIT_STARTED: "{1} ha comenzado SWISTWIT.",
        SWISTWIT_STOPPED: "{1} ha dejado SWISTWIT.",
        SWISTWIT_NO_BACKGROUND_EFFECT: 'Detenga el efecto de fondo para continuar',


        PARTICIPANT_PROMOTED: "{1} ha sido promovido para ser el anfitrión de esta convocatoria",
        PARTICIPANT_DEMOTED: "{1} ya no es el anfitrión de esta llamada",
        PARTICIPANT_ASK_OPEN_MIC: "{1} le pide que active su micrófono.",
        PARTICIPANT_ASK_OPEN_MIC_NOTIFY_SENDER: "Le estamos pidiendo a {1} que habilite su micrófono.",
        PARTICIPANT_CHANGING_LANGUAGE_NOTIFY_SENDER: "estamos cambiando el idioma de {1}",
        PARTICIPANT_CHANGING_LANGUAGE_NOTIFY_RECEIVER: "{1} ha configurado su idioma en {2}",
        PARTICIPANT_ASK_OPEN_CAMERA: "{1} le pide que active su cámara.",
        PARTICIPANT_ASK_OPEN_CAMERA_NOTIFY_SENDER: "Le estamos pidiendo a {1} que habilite su cámara.",
        PARTICIPANT_RAISE_HAND: "{1} ha levantado la mano",
        PARTICIPANT_LOWER_HAND: "{1} ha bajado la mano",
        CALL_KICKED: "Has sido eliminado de esta llamada por el anfitrión",
        NOTIFY_USER_KICK_RESULT: "El participante será eliminado en unos segundos.",
        CALL_REMAINING_MINUTES: "{1} minutos restantes en esta llamada",
        PARTICIPANT_MOVED_INTO_WAITING_ROOM: "El participante será trasladado a la sala de espera",
        PARTICIPANT_DECLINED: "Lo siento, el anfitrión no te permitió ingresar",
        PARTICIPANT_ASK_OPEN_TRANSCRIPTION: "{1} te sugiere que abras la transcripción",
        PARTICIPANT_ASK_CLOSE_TRANSCRIPTION: "{1} ha cerrado la transcripción para ti",
        PARTICIPANT_ASK_OPEN_SPEECH_TO_SPEECH: "{1} ha permitido STS para ti",
        PARTICIPANT_ASK_CLOSE_SPEECH_TO_SPEECH: "{1} ha deshabilitado STS para ti",

        SCREENSHARE_STARTED: "{1} ha comenzado a compartir la pantalla",
        SCREENSHARE_STOPPED: "{1} ha dejado de compartir la pantalla",
        SCREENSHARE_PCONLY: "Compartir pantalla sólo está disponible para la versión de PC",

        RECORDING_START: "Esta llamada se está grabando",
        RECORDING_STOP: "La grabación ha finalizado.",
        RECORDING_ACCEPT: "Tiene que aceptar la solicitud de grabación para permanecer en esta llamada",
        RECORDING_DECLINED: "No puede unirse a esta llamada ya que ha rechazado la grabación",
        RECORDING_PARTICIPANT_PAUSED: "El anfitrión detuvo la grabación.",
        RECORDING_PARTICIPANT_RESUMED: "El anfitrión reanuda la grabación.",
        RECORDING_SCREENSHARE_PAUSED: "El anfitrión detuvo la grabación de tu pantalla compartida.",
        RECORDING_SCREENSHARE_RESUMED: "El host reanuda la grabación de su pantalla compartida.",
        TRY_AGAIN_OR_CONTACT: "Inténtelo de nuevo o comuníquese con un administrador.",
        RECORDING_PASS_CODE_CHANGED: "La política de pases de grabación de llamadas se actualizó correctamente.",

        WHITEBOARD_STARTED: "{1} ha empezado a usar la pizarra",
        WHITEBOARD_CLEARED: "{1} borró la pizarra",
        WHITEBOARD_SWITCH: "{1} cambia a la pizarra {2}",
        DELETE: "Borrar",
        DELETE_CONFIRM: "Seguro ?",
        NO_DESCRIPTION: "Sin descripción",
        WHITEBOARD_DELETED_BY: "{1} borró la pizarra",
        WHITEBOARD_CREATED: "el pizarrón creado exitosamente",
        WHITEBOARD_DELETED: "el pizarrón eliminado exitosamente",
        WHITEBOARD_UPDATED: "el pizarrón actualizado exitosamente",
        WHITEBOARD_CREATED_ERROR: "Error al crear el pizarrón",
        WHITEBOARD_DELETED_ERROR: "Error al eliminar el pizarrón",
        WHITEBOARD_UPDATED_ERROR: "Error al actualizar el pizarrón",

        ACTION_UNAUTHORISED: "No tienes permiso para usar esta acción",

        // MEDIA
        MEDIA_OUTPUT_NOTFOUND: "No se ha podido encontrar ningún dispositivo de salida",
        MEDIA_ERROR_CHANGE_SPEAKER: "Se ha producido un error al cambiar de altavoz",
        MEDIA_MIC_NOTFOUND: "No se pudo encontrar ningún dispositivo de micrófono",
        MEDIA_ERROR_CHANGE_MIC: "No se ha podido encontrar ningún dispositivo de micrófono",
        MEDIA_ERROR_OPEN_MIC: "No se ha podido abrir el dispositivo de micrófono",
        MEDIA_CAM_NOTFOUND: "No se ha podido encontrar ningún dispositivo de cámara",
        MEDIA_ERROR_CHANGE_CAM: "No se ha podido encontrar ningún dispositivo de cámara",
        MEDIA_ERROR_OPEN_CAM: "No se pudo abrir el dispositivo de la cámara",
        MEDIA_ACCESS_ERROR: "Verifique los permisos de los medios.",
        MEDIA_CAM_REQUIRED:"Habilite su cámara primero",

        // TRANSCRIPCIÓN - IDIOMAS
        TRANSCRIPION_LANG_UNSUPPORTED: "{1} aún no está disponible para la función de texto a voz",
        TRANSCRIPTION_ERROR: "Lo sentimos, se ha producido un error al preparar la transcripción",
        TRANSCRIPTION_WARN_VIDEO: "Tenga en cuenta que no podemos obtener transcripciones para el enlace de video compartido.",
        ADVICE_OPEN_MIC: "Si quieres que los demás te escuchen, considera habilitar tu micrófono",
        LANG_CHANGED: "Su idioma está establecido en {1}",
        TTS_LANG_UNSUPPORTED: "{1} aún no está disponible para la función de texto a voz",
        ERROR_UNK: "Lo sentimos, se ha producido un error",
        ERROR_RECORDING_UNK : "Lo sentimos, se ha producido un error con la grabación",
        SUCCESS: "Éxito",

        FAILED_LOGIN: 'Inicio de sesión cancelado o sin autorización completa',
        FAILED_REGISTER: 'Registro cancelado o no autorizado completamente',
        FORGOT_PASSWORD: 'Lo sentimos, se ha producido un error',
        VERIFICATION_SUCCESSFUL: 'La verificación fue exitosa.',
        SEND_VERIFICATION_CODE: 'Lo sentimos, el código de verificación ha fallado',
        LOAD_LANGUAGES: 'Error al cargar los idiomas',
        UPDATE_LANGUAGE_ERROR: 'Error al actualizar idioma',
        FAILED_JOIN_CALL: 'Error durante la llamada de adhesión',
        FAILED_LOGIN: 'Error al iniciar sesión',
        FAILED_AUTHORISING_LOGIN: "Inicio de sesión cancelado o sin autorización completa",

        ERROR_SERVICE_PROVIDER_LOAD: "Lo sentimos, se ha producido un error al cargar los proveedores de servicios",
        ERROR_INVALID_PROVIDER_URL: "Verifique el proveedor de servicios que ya está utilizando.",
        ERROR_INVALID_INTEGRATION_URL: "Integración no válida, comuníquese con los administradores.",

        INCOMPLETE_RESPONSE: "Respuesta incompleta",
        LOGIN_REQUIRED: "Es necesario iniciar sesión para continuar",
        SERVER_CONNECTION_ERROR: "Lo sentimos, tenemos problemas para conectarnos al servidor",
        SERVER_CONNECTION_RESTORED: "Todos los servicios vuelven a la normalidad",

        // FORMAS
        CALL_SUBJECT: "Asunto de la llamada",

        FIELD_REQUIRED: "El {1} es obligatorio",
        FIELD_INVALID: "El {1} no es válido",
        ACTION_CANCEL_SUCCESS: "{1} se ha cancelado con éxito",

        FEEDBACK_MSG: "Nuestros expertos se pondrán en contacto con usted lo antes posible para programar su demostración. Gracias por su comentario",
        FEEDBACK_SUCCESS: "¡Gracias por su comentario!",

        // NAV
        CALL_END_BACK_LOGIN: "Esta llamada ya no está activa.",
        CALL_ALREADY_ON_DIFF_TAB: "Tiene abierta una llamada.",
        RECORDING_DECLINED_BACK_LOGIN: "No puede unirse a esta llamada porque ha rechazado la grabación.",

        //FACTURACIÓN
        BILLING_SELECT_PLAN: "Por favor, seleccione un plan de facturación para registrarse",
        BILLING_INFO_ERR_UNK: "Ocurrió un error inesperado al cargar la información de facturación",

        JOINING_CALL: "Unirse a la llamada",
        PREPARING_CALL: "Preparando la llamada",
        STARTING_CALL: "Iniciando la llamada",
        LEAVING_CALL: "Abandonar la llamada",
        STOPPING_CALL: "Detener la llamada",

        //INTEGRATION
        INTEGRATION_ASK_ACCOUNT_TO_CONTINUE: "Inicie sesión o regístrese para continuar con la {1} integración.",

        //SIP
        SIP_CALLING: "Llama ",
        SIP_INVITATION_CANCELLED_SUCCESSFULLY: "Llama cancelada ",
        SIP_ITEM_CREATED: "Objeto creado con éxito",
        SIP_ITEM_SAVED: "Objeto guardado con éxito",
        SIP_ITEM_DELETED: "Objeto eliminado con éxito",

        // Streamer
        STREAM_URL_SHARED_SUCCESSFULLY: "La transmisión se ha compartido exitosamente",
        STREAM_URL_NOT_SHARED_ERROR: "Error al compartir la transmisión",
        STREAM_URL_STOPPED:"La transmisión se detuvo exitosamente",
        STREAM_URL_STOP_ERROR:"Error al detener la transmisión",
        PARTICIPANT_SHARED_STREAM_URL: "{1} compartió una transmisión",
        PARTICIPANT_STOPPED_STREAM_URL: "{1} detuvo la transmisión",
        STREAM_OUT_PUBLISHED: "la transmisión se ha publicado con éxito",
        STREAM_OUT_PUBLISH_ERROR: "Error al publicar la transmisión",
        STREAM_OUT_STOPPED: "la transmisión se ha detenido con éxito",
        STREAM_OUT_STOP_ERROR: "Error al detener la transmisión"
    }

    service.setLocale = (locale) => {
        if(!locale){
            return;
        }
        if(service.langs.includes(locale.toLowerCase())){
            _inner.locale = locale.toLowerCase();
            return true;
        }else {
            return false;
        }
    }
    service.isLocal = (locale) => {
        return _inner.locale == locale ? true : false;
    }
    service.getLocal = () => {
        return _inner.locale ? _inner.locale : null;
    }

    service.get = (index, data) => {
        if (!index || data && typeof data != 'object') {
            return false;
        }
        let msg = _inner[_inner.locale][index] ? _inner[_inner.locale][index] : _inner[_inner.default_lang][index];
        if(!msg) return false
        if (msg.match(/{(\d)}/g) && data) {
            msg = msg.replaceAll(/{(\d+)}/g, (_, n) => data[+n - 1] ? data[+n - 1] : _inner.empty_data);
        }
        if (msg) {
            return msg;
        } else {
            return false;
        }
    }
    service.onLoadUserLanguages = function() {
        return new Promise((resolve, reject) => {
            if(!userI.getAccessToken()) {
                reject();
                return;
            }
            let url = '/api/user/language';
            _inner.onLoadLanguages(url)
            .then((res)=>{
                if(Array.isArray(res.errors) || !res.LanguageList) {
                    toastI.error(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    //resolve even on error
                    resolve();
                    return;
                }
                _inner.updateUserLanguages(res.LanguageList);
                resolve(res);
            }, (error)=>{
                resolve();
            });
        });
    }
    _inner.updateUserLanguages = function(languages) {
        if(!Array.isArray(languages))
            return;
        if(!_inner.languages)
            _inner.languages = {};
        for(let i=0; i<languages.length; i++) {
            let lang = languages[i];
            if(!lang.language_id)
                continue;
            _inner.decorateLanguage(lang);
            if(_inner.languages[lang.language_id]) {
                for(let k in lang) {
                    _inner.languages[lang.language_id][k] = lang[k];
                }
            } else
                _inner.languages[lang.language_id] = lang;
        }
        setTimeout(()=>{
            _inner.notifyUI();
        });
    }
    service.getActiveUserLanguages = function() {
        let filtered = [];
        for(let k in _inner.languages)
            if(_inner.languages[k].is_active)
                filtered.push(_inner.languages[k]);
        return filtered.sort((l1, l2)=>{ return l1.name<l2.name? -1: l1.name>l2.name? 1: 0});
    }
    service.getActiveUserLanguagesWithTranslation = function() {
        return service.getActiveUserLanguages().filter(item=>item.hasTranscription);
    }
    service.getUserLanguageById = function(id) {
        if(!id)
            return;
        return _inner.languages && _inner.languages[id]? _inner.languages[id]: null;
    }
    service.getUserLanguageIdByCode = function(code) {
        if(!code)
            return;
        for(let k in _inner.languages)
            if(_inner.languages[k].code===code)
                return _inner.languages[k].language_id;
        return null;
    }
    service.getUserLanguageCodeById = function(id) {
        if(!id)
            return;
        let lang = service.getUserLanguageById(id);
        return lang && lang.code? lang.code: '';
    }
    service.isLanguageHasTTS = function(id) {
        if(!id)
            return;
        let lang = service.getUserLanguageById(id);
        return lang && lang.hasTTS? true: false;
    }
    service.isLanguageHasSTS = function(id) {
        if(!id)
            return;
        let lang = service.getUserLanguageById(id);
        return lang && lang.hasSTS? true: false;
    }
    service.isLanguageHasTranslation = function(id) {
        if(!id)
            return;
        let lang = service.getUserLanguageById(id);
        return lang && lang.hasTranslation? true: false;
    }
    service.areLanguageInExceptionForSTS = function(fromId, toId) {
        if(!fromId || !toId)
            return true;
        let fromLang = service.getUserLanguageById(fromId);
        let toLang = service.getUserLanguageById(toId);
        if(!fromLang || !fromLang.code || !toLang || !toLang.code)
            return true;

        if(Array.isArray(fromLang.stsExceptions) && fromLang.stsExceptions.length>0) {
            if(fromLang.stsExceptions.indexOf(toLang.code)>-1)
                return true;
        }
        if(Array.isArray(toLang.stsExceptions) && toLang.stsExceptions.length>0) {
            if(toLang.stsExceptions.indexOf(fromLang.code)>-1)
                return true;
        }
        return false;
    }
    service.areLanguageInExceptionForTranslation = function(fromId, toId) {
        if(!fromId || !toId)
            return true;
        let fromLang = service.getUserLanguageById(fromId);
        let toLang = service.getUserLanguageById(toId);
        if(!fromLang || !fromLang.code || !toLang || !toLang.code)
            return true;

        if(Array.isArray(fromLang.translationExceptions) && fromLang.translationExceptions.length>0) {
            if(fromLang.translationExceptions.indexOf(toLang.code)>-1)
                return true;
        }
        if(Array.isArray(toLang.translationExceptions) && toLang.translationExceptions.length>0) {
            if(toLang.translationExceptions.indexOf(fromLang.code)>-1)
                return true;
        }
        return false;
    }
    service.setUserSelectedLanguage = function(language) {
        _inner.decorateLanguage(language);
        _inner.userSelectedLanguage = language;
        _inner.notifyUI();
    }
    service.initSelectedLanguage = function() {
        if(!_inner.languages)
            return;
        let id = userI.getWhoAmILangId();
        if(_inner.languages[id])
            service.setUserSelectedLanguage(_inner.languages[id]);
        else {
            id = '0f716b65-7fff-421d-839d-b85fc3f53273';
            if(_inner.languages[id])
                service.setUserSelectedLanguage(_inner.languages[id]);
        }
    }
    service.getUserSelectedLanguage = function() {
        return _inner.userSelectedLanguage? _inner.userSelectedLanguage: null;
    }
    service.getUserSelectedLanguageName = function() {
        return _inner.userSelectedLanguage&&_inner.userSelectedLanguage.name? _inner.userSelectedLanguage.name: '';
    }
    service.getUserSelectedLanguageId = function() {
        return _inner.userSelectedLanguage&&_inner.userSelectedLanguage.language_id? _inner.userSelectedLanguage.language_id: '';
    }
    service.getUserSelectedLanguageCode = function() {
        return _inner.userSelectedLanguage&&_inner.userSelectedLanguage.code? _inner.userSelectedLanguage.code: '';
    }
    service.isUserSelectedLanguageHasTts = function() {
        return _inner.userSelectedLanguage&&_inner.userSelectedLanguage.hasTTS? true: false;
    }
    _inner.updateAdminLanguages = function(languages) {
        if(!Array.isArray(languages))
            return;
        if(!userI.isAdmin()) {
            _inner.adminLanguages = {};
            return;
        }
        if(!_inner.adminLanguages)
            _inner.adminLanguages = {};
        for(let i=0; i<languages.length; i++) {
            let lang = languages[i];
            if(!lang.language_id)
                continue;
            if(_inner.adminLanguages[lang.language_id]) {
                for(let k in lang) {
                    _inner.adminLanguages[lang.language_id][k] = lang[k];
                }
            } else
                _inner.adminLanguages[lang.language_id] = lang;
        }
    }
    service.getAdminLanguages = () => {
        let filtered = [];
        for(let k in _inner.adminLanguages)
            if(_inner.adminLanguages[k].is_active)
                filtered.push(_inner.adminLanguages[k])
        return filtered.sort((l1, l2)=>{ return l1.name<l2.name? -1: l1.name>l2.name? 1: 0});
    }
    service.getAdminLanguageById = (id)=>{
        if(!id)
            return;
        return _inner.adminLanguages && _inner.adminLanguages[id]? _inner.adminLanguages[id]: null;
    }
    _inner.decorateLanguage = function(lang) {
        if(!lang || !lang.config)
            return;
        try {
            let config = JSON.parse(lang.config);
            lang.hasTranslation = config && config.translation && config.translation.service? true: false;
            lang.hasTranscription = config && config.transcription && config.transcription.service? true: false;
            lang.hasTTS = config && config.tts && config.tts.service? true: false;
            lang.hasSTS = config && config.sts && config.sts.service? true: false;
            lang.stsExceptions = lang.hasSTS && config.sts? config.sts.codeLangExceptions: [];
            lang.translationExceptions = lang.hasTranslation && config.translation.codeLangExceptions? config.translation.codeLangExceptions: [];
        } catch (error) {
            lang.hasTranslation = false;
            lang.hasTranscription = false;
            lang.hasTTS = false;
            lang.hasSTS = false;
        }
    }
    _inner.updateProviderLanguages = function(languages) {
        if(!Array.isArray(languages))
            return;
        if(!userI.isAdmin()) {
            _inner.providerLanguages = {};
            return;
        }
        if(!_inner.providerLanguages)
            _inner.providerLanguages = {};
        for(let i=0; i<languages.length; i++) {
            let lang = languages[i];
            if(!lang.language_id || !lang.config)
                continue;
            _inner.decorateLanguage(lang);
            if(_inner.providerLanguages[lang.language_id]) {
                for(let k in lang) {
                    _inner.providerLanguages[lang.language_id][k] = lang[k];
                }
            } else
                _inner.providerLanguages[lang.language_id] = lang;
        }
        _inner.updateUserLanguages(languages);
    }
    service.getActiveProviderLanguages = ()=>{
        let filtered = [];
        for(let k in _inner.providerLanguages)
            if(_inner.providerLanguages[k].is_active)
                filtered.push(_inner.providerLanguages[k])
        return filtered.sort((l1, l2)=>{ return l1.name<l2.name? -1: l1.name>l2.name? 1: 0});
    }
    service.getActiveProviderLanguageById = (id)=>{
        if(!id)
            return;
        return _inner.providerLanguages && _inner.providerLanguages[id]? _inner.providerLanguages[id]: null;
    }
    _inner.onLoadLanguages = function(url) {
        return new Promise((resolve, reject) => {
            if(!userI.getAccessToken() || !url || typeof url !== 'string') {
                reject();
                return;
            }
            networkI.get({url: url})
            .then((res)=>{
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.onLoadAdminLanguages = function(params) {
        return new Promise((resolve, reject)=>{
            if(!userI.getAccessToken() || !userI.isAdmin()) {
                reject();
                return;
            }
            let loaded = false;
            for(let k in _inner.providerLanguages) {
                for(let j in _inner.adminLanguages) {
                    loaded = true;
                    break;
                }
                if(loaded)
                    break;
            }
            if(loaded) {
                resolve();
                return;
            }
            if(_inner.ui) {
                if(!_inner.providerLanguages || _inner.providerLanguages.length<1)
                    _inner.ui.isProviderLanguagesLoading = true;
            }
            let url = '/api/admin/language';
            if(params && typeof params==='object') {
                url += '?';
                for(let k in params)
                    url += (k+'='+params[k]);
            }
            _inner.onLoadLanguages(url)
            .then((response)=>{
                if(Array.isArray(response.errors) || !response.LanguageList) {
                    toastI.error(networkI.getErrorMessageFromArr(response.errors) || langI.get('ERROR_UNK'));
                    reject();
                    return;
                }
                if(response && Array.isArray(response.LanguageList)) {
                    if(params && params['providerId'])
                        _inner.updateProviderLanguages(response.LanguageList);
                    else
                        _inner.updateAdminLanguages(response.LanguageList);
                }
                resolve();
            }, (error)=>{
                reject(error);
            }).finally(()=>{
                if(_inner.ui) {
                    if(params && params['providerId'])
                        _inner.ui.isProviderLanguagesLoading = false;
                }
            });
        });
    }
    _inner.onUpdateLanguage = (language)=>{
        return new Promise((resolve,reject)=>{
            if(!userI.isAdmin()) {
                reject();
                return;
            }
            if(!language || typeof language !== "object"){
                reject(langI.get('UPDATE_LANGUAGE_ERROR'));
                return
            }
            let obj = {};
            for(let k in language)
                obj[k] = language[k];
            const payload = {
                LanguageList: [obj],
                MreCallProviderID: serviceProviderI.getServiceProviderId()
            }
            networkI.put({url: '/api/admin/language', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('UPDATE_LANGUAGE_ERROR'));
                    return;
                }
                if(Array.isArray(res.LanguageList)) {
                    resolve(res.LanguageList);
                } else {
                    reject(langI.get('UPDATE_LANGUAGE_ERROR'));
                }
            }, (error)=>{
                reject(langI.get('UPDATE_LANGUAGE_ERROR'));
            });
        })
    }
    service.onSaveLanguageConfig = (lang,languageFeatures)=>{
        return new Promise((resolve,reject)=>{
            if(!lang || !Array.isArray(languageFeatures)){
                reject();
                return
            }
            let config = {};
            languageFeatures.filter(item=>{
                return item.id && typeof item.selectedValues == "object" ? true : false
            }).map(item=>{
                return {
                    [item.id]:{
                        ...item.selectedValues
                    }
                }
            })
            .forEach(item=>{
                config = {
                    ...config,
                    ...item
                }
            });
            try {
                config = JSON.stringify(config);
            } catch (error) {
                reject();
                return;
            }
            let obj = {
                language_id: lang.language_id,
                config: config,
                is_active:true
            };
            _inner.onUpdateLanguage(obj)
            .then((arr)=>{
                _inner.updateProviderLanguages(arr);
                resolve();
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.onRemoveLanguage = (id)=>{
        return new Promise((resolve,reject)=>{
            if(!userI.isAdmin() || !id) {
                reject();
                return;
            }
            // remove the lang from the providerLanguages array
            _inner.onUpdateLanguage({language_id: id, is_active: false})
            .then((arr)=>{
                if(_inner.providerLanguages && Array.isArray(arr)) {
                    for(let i=0; i<arr.length; i++)
                        delete _inner.providerLanguages[arr[i].language_id];
                }
                resolve();
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.onEnableLanguage = (obj)=>{
        return new Promise((resolve,reject)=>{
            if(!userI.isAdmin() || !obj || !obj.language_id) {
                reject();
                return;
            }
            if(_inner.providerLanguages && _inner.providerLanguages[obj.language_id]
                && _inner.providerLanguages[obj.language_id].is_active) {
                    resolve();
                    return;
                }

            if(!obj.is_active) {
                obj.is_active = true;
            }
            _inner.onUpdateLanguage(obj)
            .then((arr)=>{
                _inner.updateProviderLanguages(arr);
                resolve();
            }, (error)=>{
                reject(error);
            });
        })
    }
    _inner.getLanguageServicesOrModels = (name,service)=>{
        if(!name || !service) return [];
        let setting = userI.getAppSettings();
        return setting && setting.config && setting.config.language && setting.config.language[name] && setting.config.language[name][service] ?  setting.config.language[name][service] : [];
    }
    service.getTranscriptionServices = ()=>{
        return _inner.getLanguageServicesOrModels('transcription','services')
    }
    service.getTranslationServices = ()=>{
        return _inner.getLanguageServicesOrModels('translation','services')
    }
    service.getTranscriptionModels = ()=>{
        return _inner.getLanguageServicesOrModels('transcription','models')
    }
    service.getTranslationModels = ()=>{
        return _inner.getLanguageServicesOrModels('translation','models')
    }
    service.getTTSServices = ()=>{
        return _inner.getLanguageServicesOrModels('tts','services')
    }
    service.getSTSServices = ()=>{
        return _inner.getLanguageServicesOrModels('sts','services')
    }
    service.initLanguageFeatures = ()=>{
        if(!_inner.ui)
            service.getUIBind();
        _inner.ui.features = [
            {
                id:'translation',
                label:'Translation',
                description: 'Enable Translation for this language.',
                isEnabled: false,
                isVisible: true,
                selectedValues:{
                  service:"",
                  model:"",
                  codeLangExceptions:[]
                },
                services: service.getTranslationServices(),
                models: service.getTranslationModels()
            },
            {
              id:'transcription',
              label:'Speech to Text',
              description: 'Enable STT for this language.',
              isEnabled: false,
              isVisible: true,
              selectedValues:{
                service:"",
                model:""
              },
              services: service.getTranscriptionServices(),
              models: service.getTranscriptionModels()
            },
            {
                id:'tts',
                label:'Text to Speech',
                description: 'Enable TTS for this language.',
                isEnabled: false,
                isVisible: true,
                selectedValues:{
                    service:"",
                    model:"",
                    codeLangExceptions: []
                },
                services: service.getTTSServices(),
                models: [],
            },
            {
              id:'sts',
              label:'Speech-to-speech translation',
              description: 'Enable S2S for this language.',
              isEnabled: false,
              isVisible: true,
              selectedValues:{
                service:"",
                model:"",
                codeLangExceptions:[]
              },
              services: service.getSTSServices(),
              models: []
            },
          ]
    }
    service.getUIBind = ()=>{
        if(_inner.ui)
            return _inner.ui;
        _inner.ui = {
            isProviderLanguagesLoading: true,
            selectedLangCode: service.getUserSelectedLanguageCode(),
            isSTSAvailable: service.isLanguageHasSTS(service.getUserSelectedLanguageId())
        };
        return _inner.ui;
    }
    _inner.notifyUI = ()=>{
        if(!_inner.ui)
            service.getUIBind();
        _inner.ui.isSTSAvailable = service.isLanguageHasSTS(service.getUserSelectedLanguageId());
        _inner.ui.selectedLangCode = service.getUserSelectedLanguageCode();
    }
    return service;
}
export const langI = LangI();