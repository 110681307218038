import { ActivatedRouteSnapshot } from "@angular/router";
import { userI } from "../../assets/js/userI";
import { navbarI } from "../../assets/js/navbarI";
export class UserGuard {
    canActivate(route: ActivatedRouteSnapshot):any{
        if(!userI.getAccessToken()){
            const path = route.url[0].path
            const ignoredPaths = ["enjoy","loading","upgrade-profile", "home", "eco123", "bot"];
            if(ignoredPaths.indexOf(path) === -1){
               navbarI.setNextPage(path)
               navbarI.setLastActivePage(path);
            }
            navbarI.navBack(`loading`)
            return false
        }
        return true;
    }
}