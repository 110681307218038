import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { CallGuard } from "./router-guards/call.guard";
import { UserGuard } from "./router-guards/user.guard";
import { ConnectionGuard } from "./router-guards/connection.guard";
import { AppDashboardComponent } from "./app-dashboard/app-dashboard.component";
import { GuestGuard } from "./router-guards/guest.guard";
const routes: Routes = [
  { path: "", redirectTo: "loading", pathMatch: "full" },
  { path: "loading", data: { title: "loading" }, loadChildren: () => import('./loading/loading.module').then(m => m.LoadingPageModule) , canActivate:[ConnectionGuard] },
  { path: "login", data: { title: "login" }, loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) , canActivate:[ConnectionGuard] },
  { path: "register", data: { title: "register" }, loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule) , canActivate:[ConnectionGuard] },
  { path: "guest-login", data: { title: "guest-login" }, loadChildren: () => import('./guest-login/guest-login.module').then(m => m.GuestLoginPageModule) },
  { path: "change-password", data: { title: "change-password" }, loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordPageModule) },
  { path: "forgot-password", data: { title: "forgot-password" }, loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
  { path: "pre-call", data: { title: "call" }, loadChildren: () => import('./pre-call/pre-call.module').then(m => m.PreCallPageModule) },
  { path: "enjoy", data: { title: "enjoy" }, loadChildren: () => import('./enjoy/enjoy.module').then(m => m.EnjoyPageModule)},
  { path: "home", data: { title: "home" }, loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)},
  { path: "eco123", data: { title: "ECO123" }, loadChildren: () => import('./eco123/eco123.module').then(m => m.ECO123PageModule)},
  { path: "error", data: { title: "error" }, loadChildren: () => import('./error-page/error-page.module').then(m => m.ErrorPageModule)},
  { path: "app" , component:AppDashboardComponent ,
    children:[
      {path:"" , pathMatch:"full" , redirectTo:"calls"},
      { path: "calls", data: { title: "calls" }, loadChildren: () => import('./calls/calls.module').then(m => m.CallsPageModule) , canActivate:[UserGuard] },
      { path: "call", data: { title: "call" }, loadChildren: () => import('./call/call.module').then(m => m.CallPageModule) , canActivate:[CallGuard]},
      { path: "profile", data: { title: "profile" }, loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule) , canActivate:[UserGuard] },
      { path: "calendar", data: { title: "calendar" }, loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarPageModule) , canActivate:[UserGuard] },
      { path: "settings", data: { title: "settings" }, loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule) , canActivate:[UserGuard] },
      { path: "contacts", data: { title: "contacts" }, loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsPageModule) , canActivate:[UserGuard] },
      { path: "chats", data: { title: "chats" }, loadChildren: () => import('./chats/chats.module').then(m => m.ChatsModule) , canActivate:[UserGuard] },
      { path: "integrations", data: { title: "integrations" }, loadChildren: () => import('./integrations/integrations.module').then(m => m.IntegrationsPageModule) , canActivate:[UserGuard]},
      { path: "upgrade-profile", data: { title: "upgrade-profile" }, loadChildren: () => import('./upgrade-profile/upgrade-profile.module').then(m => m.UpgradeProfilePageModule) , canActivate:[GuestGuard] },
      { path: "bot", data: { title: "GPT+" }, loadChildren: () => import('./bot/bot.module').then(m => m.BotPageModule)},
    ]
  },
  { path: "calls", redirectTo:"app/calls"},
  { path: "call", redirectTo:"app/call"},
  { path: "profile", redirectTo:"app/profile"  },
  { path: "calendar", redirectTo:"app/calendar"},
  { path: "settings", redirectTo:"app/settings"},
  { path: "contacts", redirectTo:"app/contacts"},
  { path: "chats",  redirectTo:"app/chats"},
  { path: "integrations", redirectTo:"app/integrations"},
  { path: "upgrade-profile", redirectTo:"app/upgrade-profile"},
  { path: "bot", redirectTo:"app/bot"},
  { path: "**" , redirectTo:"loading" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() {}
}
