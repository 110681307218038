let DropdownI = function () {
    let _inner = {}
    let service = {};
    let isDEBUG = false;

    service.toogleDropdown = (index, event) => {
        if(index){
            if(event){
                event.stopImmediatePropagation();
            }
            let dropdownWrapper = document.querySelector('.dropdown-holder[data-index="'+index+'"]');
            if(dropdownWrapper)
              dropdownWrapper.classList.toggle('active');
        }
    }
    service.closeDropdown = (index,event)=>{
        if(!index) return
        if(event){
            event.stopImmediatePropagation();
        }
        let dropdownWrapper = document.querySelector('.dropdown-holder[data-index="'+index+'"]');
        if(!dropdownWrapper) return
        dropdownWrapper.classList.remove('active')
    }

    service.getUIBind = ()=>{
        if(_inner.ui) return _inner.ui
        _inner.ui = {
            toogleDropdown:service.toogleDropdown,
            closeDropdown:service.closeDropdown
        }
        return _inner.ui;
    }
    return service;
}

export const dropdownI = DropdownI();