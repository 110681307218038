
import { callI } from "../../assets/js/callI";
import { navbarI } from "../../assets/js/navbarI";
export class CallGuard {
    canActivate():any{
         if(!callI.getActiveCall()){
             navbarI.navBack('loading')
             return false
         }
         return true
    }
}