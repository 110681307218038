import { userI } from "src/assets/js/userI";
import { serviceProviderI } from "src/assets/js/serviceProviderI";
import { endpointI } from "./endpointI";
import { guestI } from "./guestI";
import { busI } from "./busI";
import { recorderI } from "./recorderI";
import { participantI } from "./participantI";
import { integrationI } from "./integrationI";
import { chatI } from "./chatI";
import { streamI } from "./streamI";
import { toastI } from "./toastI";
import { langI } from "./langI";
import { pollI } from "./pollI";
import { transcriptionI } from "./transcriptionI";
import { notificationI } from "./notificationI";
import { speakerI } from "./speakerI";
import { utilI } from "./utilI";
import { networkI } from "./networkI";
import { messagingI } from "./messagingI";
import { whiteBoardI } from "./whiteBoardI";
import { modalI } from "./modals";
import { roomI } from "./roomI";
import { livekitI } from "./livekitI";

let CallI = function () {
    let isDEBUG = true;

    let service = {};
    service.EVENTS = {
        PHONE_INVITE: 39,
        PHONE_INVITE_START: 47,
        PHONE_INVITE_END: 40
    }
    let _inner = {
        callInfo: null,
        attachments: null,
        invites: [],
        invitedProfiles: [],
        messages: [],
        join_code:'',
        activeCallsComponent:'all-calls',
        passCode: '',
        isChatOpen: false,
        KEY_STORAGE_ON_CALL: "TMU.WebApp.OnCall",
        KEY_STORAGE_ACTIVE_CALL: "tmu.WebApp.ActiveCall",
        KEY_STORAGE_ACTIVE_CALLS_COMPONENT:'TMU.WebApp.ActiveCallsComponent',
        isOnActiveCall:false,
        globalCounter:0,
        isCallInviteNotificationClicked:{},
        isInternal:false,
        internalKey:null,
        JOIN_PATH: '/meet/'
    }
    _inner.tmp_avatar_url = null
    _inner.is_tmp_avatar_uploading = false;
    _inner.postMessageTimeout = null;
    _inner.savePassCode = function(code) {
        _inner.passCode = code && typeof code==='string' && code.trim()? code.trim(): '';
    }
    service.getPassCode = function() {
        return _inner.passCode? _inner.passCode: '';
    }
    _inner.reminderOptions = [{metric: 'min', label: 'minutes'}, {metric: 'h', label: 'hours'}, {metric: 'd', label: 'days'}];
    service.getJoinPath = ()=>{
        return _inner.JOIN_PATH;
    }
    service.getReminderOptions = function() {
        return _inner.reminderOptions;
    }
    service.isValidReminderMetric = function(val) {
        return val && typeof val==='string' && _inner.reminderOptions.map(item=>item.metric).indexOf(val)>-1? true: false;
    }
    service.extractReminderFromCallObj = function(call) {
        let obj = {value: 10, metric: _inner.reminderOptions[0].metric, label: _inner.reminderOptions[0].label};
        let val = call && call.reminder_before_str? call.reminder_before_str: '';
        if(!val)
            return obj;
        for(let i=0; i<_inner.reminderOptions.length; i++) {
            let metric = _inner.reminderOptions[i].metric;
            let index = val.indexOf(metric);
            if(!(index>-1))
                continue;
            let arr = [val.substring(0, index), val.substring(index, val.length)];
            if(arr.length==2 && arr[1]===metric && +arr[0]>0 && +arr[0]===+arr[0]) {
                obj.value = arr[0];
                obj.metric = arr[1];
                obj.label = _inner.reminderOptions[i].label;
            }
        }
        return obj;
    }
    service.getUIBind=()=>{
        if(_inner.ui) {
            _inner.notifyUI();
            return _inner.ui;
        }
        let call = service.getActiveCall();
        _inner.ui = {
            isInternal:service.getIsInternal(),
            active:_inner.active,
            tmp_avatar_url:_inner.tmp_avatar_url,
            default_avatar: userI.getDefaultAvatar(),
            subject: call && call.call_subject? call.call_subject: 'Call',
            description: call && call.description? call.description: '',
            allow_join_without_admit: call && call.allow_join_without_admit ? true : false,
            has_note_enabled: call && call.has_note_enabled? true: false,
            join_code:_inner.join_code,
            activeCallsComponent: _inner.activeCallsComponent,
            isChatOpen: _inner.isChatOpen,
            isRoom: service.isRoom(call),
            unreadMessagesCounter: 0,
            closeChat: ()=>{
                _inner.isChatOpen = false;
                _inner.notifyUI();
            },
            toggleChat: ()=>{
                _inner.isChatOpen = !_inner.isChatOpen;
                if(_inner.isChatOpen)
                    busI.notifyEvent(busI.EVENTS.ON_LOAD_CALL_CHAT, service.getActiveCallId());
                if(_inner.isChatOpen)
                    _inner.ui.unreadMessagesCounter = 0;
                setTimeout(()=>{
                    _inner.notifyUI();
                    streamI.MainCallsContainer();
                })
            },
            settings: {
                isTextToSpeechForTranscriptionEnabled: false,
                isSpeechToSpeechEnabled: false,
                isTTSSynthesisEnabled: false,
                currentTab: 'captions',
                toggleTextToSpeechForTranscription: ()=>{
                    if(_inner.ui.settings.isTextToSpeechForTranscriptionEnabled) {
                        if(_inner.ui.settings.isSpeechToSpeechEnabled) {
                            let shouldRestart = _inner.ui.settings.isSpeechToSpeechEnabled;
                            _inner.ui.settings.isSpeechToSpeechEnabled = false;
                            if(shouldRestart)
                                transcriptionI.onRestartTranscription().then(()=>{}, ()=>{});
                        }
                    }
                },
                updateSTS: ()=>{
                    let isEnabled = _inner.ui.settings.isSpeechToSpeechEnabled && langI.isLanguageHasSTS(participantI.getCurrentUserLanguageId())? true: false;
                    if(!isEnabled)
                        _inner.ui.settings.isSpeechToSpeechEnabled = false;
                    participantI.onUpdateSts({callId: service.getActiveCallId(), participantId: service.getActiveParticipantId(), isActive: _inner.ui.settings.isSpeechToSpeechEnabled})
                    .finally(()=>{
                        transcriptionI.updateStateBaseOnParticipants();
                        setTimeout(()=>{
                            let participants = streamI.getActiveParticipants();
                            if(Array.isArray(participants)) {
                                for(let i=0; i<participants.length; i++) {
                                    if(participants[i].mre_call_participant_id === callI.getActiveParticipantId())
                                        continue;
                                    transcriptionI.updateParticipantVolumeBasedOnSTS(participants[i]);
                                }
                            }
                            _inner.notifyUI();
                        });
                        livekitI.updateMetadata();
                    });
                },
                toggleSpeechToSpeech: ()=>{
                    if(_inner.ui.settings.isSpeechToSpeechEnabled) {
                        _inner.ui.settings.isTextToSpeechForTranscriptionEnabled = false;
                    }
                    _inner.ui.settings.updateSTS();
                },
                toggleNotesTaking(){
                    if(!service.isActiveCallByHost()) return
                    service.onEditCall({
                        MreCallID: service.getActiveCallId(),
                        HasNoteEnabled: _inner.ui.has_note_enabled? true : false
                    })
                    .then((res)=>{
                        if(res.MreCall && res.MreCall.mre_call_id === service.getActiveCallId()) {
                            service.updateActiveCall(res.MreCall);
                            if(_inner.ui.has_note_enabled) {
                                toastI.success(langI.get('TAKING_NOTES_ENABLED'));
                            } else {
                                toastI.warning(langI.get('TAKING_NOTES_DISABLED'));
                            }
                            transcriptionI.updateTranscriptionStateBasedOnCurrentParticipant(res);
                        }
                    },(error)=>{
                        toastI.error(error && typeof error==='string'? error: langI.get('ERROR_UNK'));
                    })
                },
                toggleAllowJoinWithoutAdmit(){
                    if(!service.isActiveCallByHost()) return
                    service.onEditCall({
                        MreCallID: service.getActiveCallId(),
                        AllowJoinWithoutAdmit: _inner.ui.allow_join_without_admit? true : false
                    })
                    .then((res)=>{
                        if(res.MreCall && res.MreCall.mre_call_id === service.getActiveCallId())
                            service.updateActiveCall(res.MreCall);
                    },(error)=>{
                        toastI.error(error && typeof error==='string'? error: langI.get('ERROR_UNK'));
                    })
                }
            },
            settingsTabs: [
                {id: 'general', title: 'General', isDisabled: false , isForHostOnly:false},
                {id: 'captions', title: 'Captions', isDisabled: false, isForHostOnly:false},
                {id: 'recording', title: 'Recording', isDisabled: false, isForHostOnly:true},
                {id: 'audio', title: 'Audio', isDisabled: true, isForHostOnly:false},
                {id: 'video', title: 'Video', isDisabled: true, isForHostOnly:false},
                {id: 'swistwit', title: 'SWISTWIT', isDisabled: false, isForHostOnly:false},
                // {id: 'background-effect', title: 'Virtual Background', isDisabled: true}
            ],
            globalCounter:_inner.globalCounter
        }
        service.checkActiveCallsComponent();
        return _inner.ui;
    }
    service.checkActiveCallsComponent = ()=>{
        if(!_inner.ui) return
        const activeCallsComponent = utilI.getFromLocalStorage(_inner.KEY_STORAGE_ACTIVE_CALLS_COMPONENT);
        if(activeCallsComponent){
            _inner.ui.activeCallsComponent = _inner.activeCallsComponent = activeCallsComponent;
        }
        utilI.removeFromLocalStorage(_inner.KEY_STORAGE_ACTIVE_CALLS_COMPONENT);
    }
    service.setActiveCallsComponent = (component)=>{
        if(typeof component !== "string") return;
        utilI.updateLocalStorage(_inner.KEY_STORAGE_ACTIVE_CALLS_COMPONENT,component);
        _inner.activeCallsComponent = component;
        _inner.notifyUI();
    }
    _inner.notifyUI=()=>{
        if(!_inner.ui)
            service.getUIBind();
        let call = service.getActiveCall();
        _inner.ui.active = _inner.active
        _inner.ui.subject = call && call.call_subject? call.call_subject: 'Call';
        _inner.ui.description = call && call.description? call.description: '';
        _inner.ui.join_code = _inner.join_code
        _inner.ui.activeCallsComponent = _inner.activeCallsComponent;
        _inner.ui.isChatOpen = _inner.isChatOpen;
        _inner.ui.allow_join_without_admit = call && call.allow_join_without_admit? true : false;
        _inner.ui.has_note_enabled = call && call.has_note_enabled? true : false;
        _inner.ui.globalCounter = _inner.globalCounter
        _inner.ui.tmp_avatar_url = _inner.tmp_avatar_url;
        _inner.ui.is_tmp_avatar_uploading = _inner.is_tmp_avatar_uploading;
        _inner.ui.isRoom = service.isRoom(call);
        _inner.ui.isInternal = service.getIsInternal();
        service.syncUi();
    }
    service.isTextToSpeechForTranscriptionEnabled = () => {
        return _inner.ui && _inner.ui.settings && _inner.ui.settings.isTextToSpeechForTranscriptionEnabled? true: false;
    }
    service.isSpeechToSpeechEnabled = () => {
        return _inner.ui && _inner.ui.settings && _inner.ui.settings.isSpeechToSpeechEnabled? true: false;
    }
    service.isTTSSynthesisEnabled = ()=>{
        return _inner.ui && _inner.ui.settings && _inner.ui.settings.isTTSSynthesisEnabled? true: false;
    }
    service.isChatOpen = ()=>{
        return _inner.ui && _inner.ui.isChatOpen || _inner.isChatOpen;
    }
    service.setJoinCode = (join_code)=>{
        _inner.join_code = join_code ? join_code : '';
        _inner.notifyUI();
    }
    service.clearJoinCode = ()=>{
        service.setJoinCode('');
    }
    service.currentCall = null;
    service.currentExpandedCall = null;
    service.downloading = false;
    service.unloading = false;

    service.setNavToDefault = () => {
        service.setActiveCallsComponent('all-calls');
    }
    service.JoinCall = () => {
        _inner.activeCallsComponent = 'join-call';
        _inner.notifyUI();
        busI.notifyEvent(busI.EVENTS.ON_REQUEST_CHECK_EXISTING_CALL);
    }
    service.bringActiveCallsComponent = ()=>{

    }
    service.storeActiveCall = () => {
        try {
            utilI.updateLocalStorage("RateCall", JSON.stringify(service.getActiveCall()));
        } catch (error) {}
    }
    service.getSoredActiveCall = () => {
        let call = null;
        try {
            call = JSON.parse(utilI.getFromLocalStorage("RateCall"));
        } catch (error) {}
        return call;
    }
    service.clearStoredActiveCall = ()=>{
        try {
            utilI.removeFromLocalStorage('RateCall');
            utilI.removeFromLocalStorage(_inner.KEY_STORAGE_ACTIVE_CALL);
            utilI.removeFromLocalStorage(_inner.KEY_STORAGE_ON_CALL);
        } catch (error) {
        }
    }
    service.setOnCall = ()=>{
        if(!service.getActiveCallId() || !livekitI.isRoomActive())
            return;
        service.setIsOnActiveCall(true);
        try {
            let obj = {isActiveCallByHost:service.isActiveCallByHost(), callId: service.getActiveCallId()};
            utilI.updateLocalStorage(_inner.KEY_STORAGE_ON_CALL, JSON.stringify(obj));
        } catch (error) {
            service.clearOnCall();
        }
    }
    service.setIsOnActiveCall = (value)=>{
        _inner.isOnActiveCall = +value === 1 ? true : false;
    }
    service.isOnActiveCall = ()=> _inner.isOnActiveCall;
    service.isOncall = ()=>{
        try {
            let value = utilI.getFromLocalStorage(_inner.KEY_STORAGE_ON_CALL);
            if(!value) return
            value = JSON.parse(utilI.getFromLocalStorage(_inner.KEY_STORAGE_ON_CALL));
            return value;
        } catch (error) {
            return false;
        }
    }
    service.clearOnCall = ()=>{
        _inner.isOncall = false;
        try {
            utilI.removeFromLocalStorage(_inner.KEY_STORAGE_ON_CALL);
        } catch (error) {
        }
    }

    service.clearQueue = () => {
        service.currentCall = null;
    }
    service.isLegitForWaitingRoom = () => {
        if(service.isActiveCallByHost())
            return false;
        let call = service.getActiveCall();
        return call && ((!service.allowsJoinWithoutHost() && !service.isHostJoined()) || call.has_password)? true: false;
    }
    service.allowsJoinWithoutHost = () => {
        if(service.isActiveCallByHost())
            return true;
        let call = service.getActiveCall();
        return call && call.is_scheduled && !call.allow_join_without_host? false: true;
    }
    service.isHostJoined = () => {
        let call = service.getActiveCall();
        return call && call.host_active? true: false;
    }
    service.requiresPassword = () => {
        let call = service.getActiveCall();
        return call && call.has_password && !service.isActiveCallByHost()? true: false;
    }
    service.getActiveCallJoinCode = () => {
        let call = service.getActiveCall();
        return call && call.join_code? call.join_code: null;
    }
    service.getJoinCode = () => {
        return service.getActiveCallJoinCode()? service.getActiveCallJoinCode(): endpointI.URLParameters.call_token;
    }
    service.setCallInvites = (invites)=>{
        _inner.invites = invites? invites: [];
    }
    service.getCallInvites = ()=>{
        return _inner.invites? _inner.invites: [];
    }
    service.addInvite = (invite)=>{
        if(!invite)
            return;
        if(!_inner.invites)
            _inner.invites = [];
        _inner.invites.push(invite);
    }
    service.getInvitedProfiles = ()=>{
        return Array.isArray(_inner.invitedProfiles)? _inner.invitedProfiles: [];
    }
    service.addInvitedProfile = (profile)=>{
        if(!profile || !profile.account_profile_id)
            return;
        if(!_inner.invitedProfiles)
            _inner.invitedProfiles = [];
        service.removeInvitedProfile(profile);
        profile.is_invited = true;
        _inner.invitedProfiles.push(profile);
    }
    service.removeInvitedProfile = (profile)=>{
        if(!profile || !Array.isArray(_inner.invitedProfiles))
            return;
        for(let i=0; i<_inner.invitedProfiles.length; i++) {
            if (_inner.invitedProfiles[i].account_profile_id == profile.account_profile_id) {
                _inner.invitedProfiles.splice(i, 1);
                break;
            }
        }
    }
    service.clearInvitedProfiles = ()=>{
        _inner.invitedProfiles = [];
    }
    service.clearMessages = (id)=>{
        _inner.ui.unreadMessagesCounter = 0;
        chatI.clearChatMessages(id);
        chatI.deleteChatById(id);
    }
    service.notifyNewChatReceived = function(id) {
        if(!id)
          return;
        let call = service.getCallById(id);
        if(!call)
          return;
        if(speakerI.isEnabled())
            notificationI.playAudio(notificationI.AUDIO_KEYS.NEW_MESSAGE);
        if(!_inner.ui.unreadMessagesCounter)
            _inner.ui.unreadMessagesCounter = 0;
        _inner.ui.unreadMessagesCounter++;
        service.updateGlobalCounter();
    }
    service.DEBUG = () => {
        if (isDEBUG) {
            console.log('CALL Interface', _inner);
        }
    }
    service.prepareCallForDetails = (info) => {
        if(!info || !info.ExpandedMreCall)
            return {};
        let expandedCall = info.ExpandedMreCall;
        let hasStarted = false;
        let details = Array.isArray(expandedCall.MreCallDetails)? expandedCall.MreCallDetails: [];
        let mreParticipants = Array.isArray(info.MreCallParticipants)? info.MreCallParticipants: Array.isArray(expandedCall.MreCallParticipants)? expandedCall.MreCallParticipants: [];
        hasStarted = expandedCall.stop_time? true: details.filter(item=>{return item.had_active_participants}).length>0 || mreParticipants.filter((item)=>{return item.joined}).length>0? true: false;

        for(let i=0; i<mreParticipants.length; i++)
            mreParticipants[i].avatar_url = mreParticipants[i].avatar_url? mreParticipants[i].avatar_url: userI.getDefaultAvatar();

        let hostId = expandedCall.hostProfile && expandedCall.hostProfile.account_profile_id? expandedCall.hostProfile.account_profile_id: '';

        if(!hostId) {
            for(let i=0; i<mreParticipants.length; i++) {
                if(mreParticipants[i].role_id === userI.ROLE_HOST_PARTICIPANT) {
                    hostId = mreParticipants[i].account_profile_id;
                    break;
                }
            }
        }

        let existingCall = service.getCallById(expandedCall.mre_call_id);

        let obj = {
            mre_call_id: expandedCall.mre_call_id,
            is_active: expandedCall.is_active,
            subject: expandedCall.call_subject || 'Call',
            description: expandedCall.description || '',
            is_scheduled: expandedCall.is_scheduled,
            scheduled_date: expandedCall.scheduled_date || null,
            start_time: expandedCall.start_time || null,
            stop_time: expandedCall.stop_time || null,
            host_id: hostId,
            join_code: expandedCall.join_code,
            duration: expandedCall.estimated_run_time || null,
            events: expandedCall.MreCallEvents,
            attachments: expandedCall.ChatAttachments,
            chats: existingCall && existingCall.chats? existingCall.chats: [],
            whiteboards: expandedCall.WhiteBoards,
            passCode: expandedCall.has_password&&info.Password? info.Password: '',
            allow_join_without_host: expandedCall.allow_join_without_host? true: false,
            showMyEmail: expandedCall.show_my_email_on_invite? true : false,
            has_recording: expandedCall.has_recording? true: false,
            has_note_enabled: expandedCall.has_note_enabled? true: false,
            hasStarted: hasStarted,
            allow_join_without_admit:expandedCall.allow_join_without_admit? true : false,
            playbackURL: typeof info.PlaybackURL==='string' && info.PlaybackURL? info.PlaybackURL: '',
            recording_pass: expandedCall.recording_pass || '',
            is_recording_public: expandedCall.is_recording_public? true: false,
            extra_data: expandedCall.extra_data ? expandedCall.extra_data : null,
            reminder: service.extractReminderFromCallObj(expandedCall)
        }
        let profiles = {};
        let arr = (mreParticipants && mreParticipants.length>0)? mreParticipants: info.AccountProfiles;
        if(arr)
            for(let i=0; i<arr.length; i++) {
                profiles[arr[i].account_profile_id] = arr[i];
            }
        let attendees = {};
        for(let i=0; i<mreParticipants.length; i++) {
            if(mreParticipants[i].last_active_time && mreParticipants[i].account_profile_id != obj.host_id && profiles[mreParticipants[i].account_profile_id])
                attendees[mreParticipants[i].account_profile_id] = profiles[mreParticipants[i].account_profile_id];
        }
        obj.participants = [];
        for(let k in attendees) {
            attendees[k].avatar_url = attendees[k].avatar_url? attendees[k].avatar_url: userI.getDefaultAvatar();
            if(attendees[k].email_address && attendees[k].email_address.indexOf('+')>-1)
                attendees[k].email_address = '';
            obj.participants.push(attendees[k]);
        }

        let invitees = {};
        // MreCallInvites
        if(Array.isArray(expandedCall.MreCallInvites)) {
            for(let i=0; i<expandedCall.MreCallInvites.length; i++) {
                let invite = expandedCall.MreCallInvites[i];
                if(!invite || !invite.identifier || invite.account_profile_id===obj.host_id)
                    continue;
                let invitee = {
                    id: invite.mre_call_invite_id,
                    identifier: invite.identifier,
                    email_address: invite.identifier,
                    is_registered: false,
                    screen_name: '',
                    accepted: +invite.accepted === 1? true: +invite.accepted === 0? false: null,
                }
                if(profiles[invite.account_profile_id]) {
                    let profile = profiles[invite.account_profile_id];
                    invitee.account_profile_id = profile.account_profile_id;
                    invitee.avatar_url = profile.avatar_url? profile.avatar_url: userI.getDefaultAvatar();
                    invitee.is_registered = true;
                    invitee.screen_name = profile.screen_name;
                } else
                    invitee = userI.decorateUnregistredUser(invitee);
                invitees[invite.identifier] = invitee;
            }
        }

        obj.invitees = [];
        for(let k in invitees)
            obj.invitees.push(invitees[k]);

        let host = null;
        let accountProfileHosts = arr.filter(participant => participant.account_profile_id == obj.host_id);
        if(accountProfileHosts.length>0)
            host = accountProfileHosts[0];
        obj.host = host;
        let mineParticipants = mreParticipants.filter(participant => participant.account_profile_id == userI.getAccountProfileId());
        obj.myParticipant = mineParticipants.length>0? mineParticipants[0]: {};
        obj.mreCall = info.MreCall;
        return obj;
    }
    service.onLoadExpandedCall = function(id, params) {
        return new Promise((resolve, reject) => {
            if(!id) {
                reject();
                return;
            }
            if(!serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            let url = '/api/call/'+id+'?providerId='+serviceProviderI.getServiceProviderId();
            if(params && typeof params == 'object')
                for(let k in params)
                    if(params[k])
                        url += ('&'+k+'='+params[k])
            networkI.get({url: url})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                if(res.Password && res.MreCall)
                    res.MreCall.Password = res.Password;
                if(res.Password && res.ExpandedMreCall)
                    res.ExpandedMreCall.Password = res.Password;
                if(res.ExpandedMreCall)
                    service.updateCall(res.ExpandedMreCall, res);
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.isActiveCallByHost = function() {
        let isHost = false;
        let participant = participantI.getParticipantById(service.getActiveParticipantId());
        isHost = (participant && participant.is_host)? true: false;
        if(!isHost) {
            let call = _inner.calls && _inner.calls.data && _inner.calls.data[service.getActiveCallId()];
            if(call && call.account_profile_id === userI.getAccountProfileId()) {
                isHost = true;
            }
        }
        return isHost;
    }
    service.updateCallHost = (id)=>{
        if(!id) return
        const participant = participantI.getParticipantById(id);
        if(!participant) return
        const call = _inner.calls && _inner.calls.data && _inner.calls.data[service.getActiveCallId()] ? _inner.calls.data[service.getActiveCallId()] : null;
        if(!call) return
        call.hostProfile = participant;
    }
    service.handleStartCallResponse = function(response) {
        if(!response)
            return;
        // if(Array.isArray(response.errors) || !response.Billing || !(response.Billing.duration>0)) {
        //     return;
        // }
        callI.updateCall(response.MreCall, response);
        if(response.AuthToken || response.AccessToken) {
            userI.setAccessToken(response.AuthToken || response.AccessToken);
            userI.setRefreshToken(response.RefreshToken);
        }
        userI.updateAppConfig(response.AppConfig);
        service.setActive({call: response.MreCall, participant: response.MreCallParticipant});
        participantI.updateParticipants(Array.isArray(response.MreCallParticipants)? response.MreCallParticipants.filter(p=>p.joined): null);
        streamI.updateStreams(response.MreCallStreams);
        service.setCredits(response.Billing);
        userI.setIsOnFreeBilling(response.BillingFreeDebugEnabled);
        let obj = {
            MreCall: response.MreCall,
            MreCallParticipant: response.MreCallParticipant,
        };
        service.storeActiveCallInStorage(obj);

        transcriptionI.updateTranscriptionStateBasedOnCurrentParticipant(obj);

        let whoAmIObj = {};
        if(response.AccountProfile) {
            userI.updateAccountProfile(response.AccountProfile);
            whoAmIObj.AccountProfile = response.AccountProfile;
        }
        if(response.Roles)
            whoAmIObj.Roles = response.Roles;
        if(response.Language)
            whoAmIObj.Language = response.Language;
        if(response.IsAdmin)
            whoAmIObj.IsAdmin = response.IsAdmin;
        userI.setWhoAmI(whoAmIObj);
        userI.saveAuth();
        userI.setRole();
        _inner.notifyUI();
    }
    service.onStartCall = function(payload) {
        return new Promise((resolve, reject)=>{
            if(!payload || typeof payload !== 'object') {
                reject();
                return;
            }
            payload.MreCallProvider = serviceProviderI.getServiceProviderForApi();
            if(payload.UseCustomJoinCode && !userI.getJoinCode())
                delete payload.UseCustomJoinCode;

            networkI.post({url: '/api/call/start', data: payload})
            .then((response)=>{
                if(Array.isArray(response.errors) || !response.Billing || !(response.Billing.duration>0)) {
                    reject(networkI.getErrorMessageFromArr(response.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                userI.setCurrentDate(response.CurrentDate);
                service.handleStartCallResponse(response);
                resolve(response);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onStartEmbedCall =function(params, integrationToken) {
        return new Promise((resolve, reject)=>{
            if(!params || typeof params !== 'object' || (!integrationToken&&!userI.getAccessToken())) {
                reject();
                return;
            }
            let url = '/api/embed/call/start?';
            let paramsArr = [];
            for(let k in params)
                paramsArr.push(k+'='+params[k]);
            url += paramsArr.join('&');
            let headers = {}
            if(!userI.getAccessToken()) {
                headers = {'X-Integration-Key': integrationToken};
            }
            networkI.post({url: url, headers: headers})
            .then((response)=>{
                if(Array.isArray(response.errors)) {
                    reject(networkI.getErrorMessageFromArr(response.errors) || langI.get('ERROR_INVALID_INTEGRATION_URL'));
                    return;
                }
                service.handleStartCallResponse(response);
                resolve(response);
            }, (error)=>{
                reject(langI.get('ERROR_INVALID_INTEGRATION_URL'))
            });
        });
    }
    service.onStartRecording = function() {
        return new Promise((resolve, reject)=>{
            if(!service.getActiveCallId()) {
                reject();
                return;
            }
            networkI.put({url: '/api/call/'+service.getActiveCallId()+'/recording/start'})
            .then((response)=>{
                if(Array.isArray(response.errors)) {
                    reject(networkI.getErrorMessageFromArr(response.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                service.setActive({call: response.MreCall});
                resolve(response);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onStopRecording = function() {
        return new Promise((resolve, reject)=>{
            if(!service.getActiveCallId()) {
                reject();
                return;
            }
            networkI.put({url: '/api/call/'+service.getActiveCallId()+'/recording/stop'})
            .then((response)=>{
                if(Array.isArray(response.errors)) {
                    reject(networkI.getErrorMessageFromArr(response.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                service.setActive({call: response.MreCall});
                resolve(response);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onStartCallWithFriends = function(obj){
        return new Promise((resolve,reject)=>{
            if(!obj || (!Array.isArray(obj.friends) && !Array.isArray(obj.emails))) return reject();
            let info = {
                call_subject: obj.subject? obj.subject: null,
                description: obj.description? obj.description: null,
            };

            const payload = {
                MreCall: info,
                RecordCall: false,
                IsPrivate: true,
                IsChannel: null,
                IsChatOnly: null,
                AccountProfiles: userI.prepareAccountProfilesForApi(obj.friends),
                EmailAddresses: Array.isArray(obj.emails)? obj.emails: [],
                ShowMyEmailOnInvite:false,
                UseCustomJoinCode:false,
                AllowJoinWithoutAdmit:true
              };
              service.onStartCall(payload).then(resolve,reject)
        })
    }
    service.onStartInstantCall = function() {
        return new Promise((resolve, reject)=>{
            service.onStartCall({})
            .then(()=>{
                resolve();
            }, ()=>{
                reject();
            });
        })
    }
    service.storeActiveCallInStorage = function(obj) {
        if(!obj || service.isRoom(obj.MreCall) || service.isRoom(obj))
            return;
        try {
            utilI.updateLocalStorage(_inner.KEY_STORAGE_ACTIVE_CALL, JSON.stringify(obj));
        } catch (error) {}
    }
    service.getActiveCallInStorage = function() {
        return utilI.getFromLocalStorage(_inner.KEY_STORAGE_ACTIVE_CALL);
    }
    service.updateOnlyActiveCallObjectInStorage = function(call) {
        let callInStorage = service.getActiveCallInStorage();
        let obj = null;
        try {
            obj = JSON.parse(callInStorage);
            if(obj.MreCall.mre_call_id === call.mre_call_id) {
                obj.MreCall = call;
                service.storeActiveCallInStorage(obj);
            }
        } catch (error) {}
    }
    service.onStopCall = function(id) {
        return new Promise((resolve, reject)=>{
            if(!id) {
                reject();
                return;
            }

            if(service.isActiveCallByHost() && recorderI.isOpen())
                service.onStopRecording().then(()=>{}, ()=>{});

            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
            };
            networkI.post({url: '/api/call/'+id+'/stop', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                service.updateCall(res.MreCall, res);
                if(res && res.MreCall && !res.MreCall.is_chat_only && !endpointI.isOnDev()) {
                    integrationI.onGetConfig(integrationI.SLACK.ROUTE)
                    .then(()=>{
                        if(integrationI.SLACK.ENABLED)
                            integrationI.onRemoveSlackChannel(res.MreCall.mre_call_id).then(()=>{}, ()=>{});
                    }, ()=>{});
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onStopCallStream = function() {
        return new Promise((resolve, reject)=>{
            if(!streamI.getCallStream() || !service.getActiveCallId()) {
                resolve();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallParticipant: service.getActiveParticipant(),
                MreCallStream: streamI.getCallStream()
            };
            networkI.post({url: '/api/call/'+service.getActiveCallId()+'/stopstream', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onScheduleCall = function(call) {
        return new Promise((resolve, reject)=>{
            if(!call || !serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }

            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                Endpoint: endpointI.getSelectedEndpointName(),
                CallSubject: call.subject || "N/A",
                CallDescription: call.description || "N/A",
                EmailAddresses: call.emailAddresses,//tmp should remove
                AccountProfiles: userI.prepareAccountProfilesForApi(call.accountProfiles), //tmp should remove
                ParticipantRoles: call.Participants,
                ScheduleDate: call.scheduleDate,
                RecordCall: call.RecordCall,
                TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                Duration: +call.duration===+call.duration? call.duration: null,
                ShowMyEmailOnInvite: call.showMyEmail? true: false,
                AllowJoinWithoutHost:true,
                AllowJoinWithoutAdmit:call.allowJoinWithoutAdmit? true : false
            };

            if(call.reminder && +call.reminder.value>0 && service.isValidReminderMetric(call.reminder.metric)) {
                payload.ReminderBeforeStr = call.reminder.value+call.reminder.metric;
            }

            if(+call.enableTakingNotes===1) {
                payload.HasNoteEnabled = true;
            }
            if(call.RecordingPass && call.RecordingPass.trim()){
                payload.RecordingPass = call.RecordingPass.trim();
            }
            if(call.passCode && call.passCode.trim())
                payload.Password = call.passCode.trim();
            if(call.useCustomJoinCode && userI.getJoinCode())
                payload.UseCustomJoinCode = true;

            networkI.post({url: '/api/call/schedule', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onEditCall = function(obj) {
        return new Promise((resolve, reject)=>{
            if(!obj || !obj.MreCallID) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi()
            };
            if(obj.reminder && +obj.reminder.value>0 && service.isValidReminderMetric(obj.reminder.metric)) {
                obj.ReminderBeforeStr = obj.reminder.value+obj.reminder.metric;
                delete obj.reminder;
            }
            for(let key in obj){
                let value = obj[key];
                payload[key] = ['CallSubject','CallDescription'].indexOf(key) > -1 ? (value || 'N/A') : (typeof value === "string" && value.trim ? value.trim() : value);
            }
            networkI.put({url: '/api/call/'+obj.MreCallID, data: payload})
            .then((res) => {
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                if(!res.MreCall) {
                    reject();
                    return;
                }
                if(res.MreCall.mre_call_id === callI.getActiveCallId())
                    service.updateOnlyActiveCallObjectInStorage(res.MreCall);
                service.onLoadExpandedCall(res.MreCall.mre_call_id)
                .then((response)=>{
                    service.updateCall(response.ExpandedMreCall, res);
                    busI.notifyEvent(busI.EVENTS.ON_CALLS_UPDATED);
                }, ()=>{}).finally(()=>{
                    setTimeout(()=>{
                        resolve(res);
                    }, 500);
                });
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onSaveChannelId = function(callId, channelId) {
        return new Promise((resolve, reject)=>{
            if(!callId || !channelId) {
                reject();
                return;
            }
            let payload = {
                EcoChannelID: channelId,
                MreCallID: callId
            };
            service.onEditCall(payload)
            .then((res)=>{
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onDeclineRecording = function(id, participant) {
        return new Promise((resolve, reject)=>{
            if(!id || !participant) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallParticipant: participant
            };
            networkI.post({url: '/api/call/'+id+'/declinerecording', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onConsentRecording = function(id, participant) {
        return new Promise((resolve, reject)=>{
            if(!id || !participant) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallParticipant: participant
            };
            networkI.post({url: '/api/call/'+id+'/consentrecording', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onRemoveParticipant = function(obj) {
        return new Promise((resolve, reject)=>{
            if(!obj || !obj.target || !obj.participant || !obj.call || !obj.call.mre_call_id) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallParticipant: obj.participant,
                TargetMreCallParticipant: obj.target
            };
            networkI.post({url: '/api/call/'+obj.call.mre_call_id+'/removeparticipant', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onTogglePromoteParticipantHost = function(obj) {
        return new Promise((resolve, reject)=>{
            if(!obj || !obj.target || !obj.participant || !obj.call || !obj.call.mre_call_id) {
                reject();
                return;
            }
            let payload = {
                MreCallParticipant: obj.participant,
                TargetMreCallParticipant: obj.target
            };
            let action = obj.isHost? 'promoteparticipant': 'demoteparticipant';
            networkI.post({url: '/api/call/'+obj.call.mre_call_id+'/'+action, data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onInvitePeople = function(obj) {
        return new Promise((resolve, reject)=>{
            if(!obj || !service.getActiveParticipant() || !service.getActiveCall()) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCall: service.getActiveCall(),
                MreCallParticipant: service.getActiveParticipant()
            };
            payload.AccountProfiles = userI.prepareAccountProfilesForApi(obj.accountProfiles);
            payload.EmailAddresses = obj.emailAddresses? obj.emailAddresses: [];
            payload.PhoneNumbers = obj.phoneNumbers? obj.phoneNumbers: [];

            networkI.post({url: '/api/call/'+service.getActiveCallId()+'/invite', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onDeclineInvite = function(obj) {
        return new Promise((resolve, reject)=>{
            if(!obj || (!obj.id && !obj.code) || !serviceProviderI.getDefaultProviderId()) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi()
            };

            if(obj.id)
                payload.MreCallID = obj.id;
            if(obj.code)
                payload.Code = obj.code;
            if(obj.invite_id)
                payload.MreCallInvite = {mre_call_invite_id: obj.invite_id};

            networkI.post({url: '/api/call/invite/decline', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onAcceptInvite = function(obj) {
        return new Promise((resolve, reject)=>{
            if(!obj || (!obj.id && !obj.code) || !serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi()
            };

            if(obj.id)
                payload.MreCallID = obj.id;
            if(obj.code)
                payload.Code = obj.code;
            if(obj.invite_id)
                payload.MreCallInvite = {mre_call_invite_id: obj.invite_id};

            networkI.post({url: '/api/call/invite/accept', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.convertCallMs = function(ms) {
        return (ms)? new Date(ms).toISOString().slice(11, 16): '';
    }
    _inner.calls = {
        data: {},
        lastStartIndex: 0
    };
    _inner.activeCalls ={
        data: {},
        lastStartIndex: 0
    }
    _inner.historyCalls ={
        data: {},
        lastStartIndex: 0
    }
    _inner.active = null;
    service.setActive = function(obj) {
      if(!obj || (!obj.participant && !obj.call))
        return;
      if(roomI.isOnActiveRoom() && obj.call.mre_call_id !== roomI.getActiveRoomId()) return
      if(!_inner.active)
        _inner.active = {};
      if(obj.participant) {
          _inner.active.participant = obj.participant;
          participantI.updateParticipants([obj.participant]);
      }
      if(obj.call)
        service.updateActiveCall(obj.call);
    }
    service.updateActiveCall = function(call){
        if(!call || !call.mre_call_id || !call.is_active)
            return;
        if(!_inner.active || !_inner.active.call) {
            if(!_inner.active)
                _inner.active = {};
            _inner.active.call = call;
        }
        _inner.active.call.is_recording_public = +call.is_recording_public === 1;
        _inner.active.call.recording_pass = call.recording_pass;
        _inner.active.call.call_subject = call.call_subject? call.call_subject: '';
        _inner.active.call.description = call.description? call.description: '';
        _inner.active.call.allow_join_without_admit = call.allow_join_without_admit? true: false;
        _inner.active.call.has_note_enabled = call.has_note_enabled? true: false;
        _inner.active.call.extra_data = call.extra_data ? call.extra_data : JSON.stringify({});
        _inner.active.call.start_time = call.start_time;
        busI.notifyEvent(busI.EVENTS.ON_ACTIVE_CALL_UPDATED);
        _inner.notifyUI();
    }
    service.setCredits = function(obj) {
        if(!_inner.active)
            return;
        _inner.active.credits = service.getActiveCallId() && obj && obj.duration? obj: null;
    }
    service.getAllowedDuration = function() {
        if(!_inner.active || !_inner.active.credits)
            return 2;
        // check if we need to use remaining_minutes, or keep duration
        return _inner.active.credits.remaining_minutes? _inner.active.credits.remaining_minutes: _inner.active.credits.duration? _inner.active.credits.duration: 2;
    }
    service.clearActive = function() {
        _inner.active = null;
        _inner.passCode = '';
        _inner.notifyUI();
    }
    service.getActiveCallId = function() {
        return _inner.active&&_inner.active.call&&_inner.active.call.is_active&&_inner.active.call.mre_call_id? _inner.active.call.mre_call_id: null;
    }
    service.getActiveCall = function() {
        return _inner.active&&_inner.active.call&&_inner.active.call.mre_call_id? _inner.active.call: null;
    }
    service.getActiveCallAccountId = function() {
        return _inner.active&&_inner.active.call&&_inner.active.call.account_profile_id? _inner.active.call.account_profile_id: null;
    }
    service.isActiveCallRecording = function() {
        let call = service.getActiveCall();
        return call&&call.has_recording? true: false;
    }
    service.setIsActiveCallRecording = function(val) {
        let call = service.getActiveCall();
        if(call) {
            call.has_recording = +val===1? true: false;
        }
    }
    service.isActiveCallHasNoteEnabled = function() {
        let call = service.getActiveCall();
        return call && call.has_note_enabled? true: false;
    }
    service.getActiveParticipant = function() {
        return _inner.active&&_inner.active.participant? _inner.active.participant: null;
    }
    service.getActiveParticipantId = function() {
        return _inner.active&&_inner.active.participant&&_inner.active.participant.mre_call_participant_id? _inner.active.participant.mre_call_participant_id: null;
    }
    service.getActiveParticipantAccountId = function() {
        return _inner.active&&_inner.active.participant&&_inner.active.participant.account_profile_id? _inner.active.participant.account_profile_id: null;
    }
    service.getActiveParticipantName = function() {
        return _inner.active&&_inner.active.participant&&_inner.active.participant.name? _inner.active.participant.name: '';
    }
    service.isActiveParticipantInternal = function() {
        return _inner.active&&_inner.active.participant&&_inner.active.participant.is_internal? true: false;
    }

    service.getCalls = function() {
        let filtered = [];
        for(let c in _inner.calls.data)
            filtered.push(_inner.calls.data[c]);
        return filtered;
    }
    service.getCallById = function(id) {
        if(!id)
            return;
        for(let c in _inner.calls.data)
            if(c===id)
                return _inner.calls.data[c];
        return null;
    }
    service.isCallStoppedById = function(id) {
        if(!id)
            return;
        let call = service.getCallById(id);
        return call && call.stop_time? true: false;
    }
    service.isCallStopped = function(call) {
        if(!call)
            return;
        return call && call.stop_time? true: false;
    }
    service.updateCall = function(call, res) {
        if(!call || !call.mre_call_id)
            return;
        if(res.MreCallParticipants)
            participantI.updateParticipants(res.MreCallParticipants);
        if(res&&res.AccountProfile) {
            call.hostProfile = res.AccountProfile;
        } else
            call.hostProfile = {};

        if (call.account_profile_id == userI.getAccountProfileId()) {
            call.started_call = true;
            call.hostProfile = userI.getAccountProfile();
        } else {
            if(res && Array.isArray(res.AccountProfiles))
                res.AccountProfiles.forEach((profile) => {
                    if (profile.account_profile_id == call.account_profile_id)
                        call.hostProfile = profile;
                });
            call.joined_call = true;
        }

        call.hostProfile.avatar_url = call.hostProfile.avatar_url? call.hostProfile.avatar_url: userI.getDefaultAvatar();

        if (!call.is_scheduled) {
            if (!call.is_active && call.stop_time) {
                let duration = new Date(call.stop_time).valueOf() - new Date(call.start_time).valueOf();
                call.call_length = service.convertCallMs(duration);
            } else if (call.is_active && !call.stop_time) {
                call.call_length = "In Progress";
            }
        } else {
            if (!call.is_active && call.stop_time) {
                let duration = new Date(call.stop_time).valueOf() - new Date(call.start_time).valueOf();
                call.call_length = service.convertCallMs(duration);
            } else {
                if (call.host_active) {
                    call.scheduled_time_left = "Host started this scheduled call";
                } else {
                    if (new Date(call.scheduled_date).valueOf() > new Date().valueOf()) {
                        let duration = new Date(call.scheduled_date).valueOf() - new Date().valueOf();
                        call.scheduled_time_left = service.convertCallMs(duration) + " left to start.";
                    } else {
                        call.scheduled_time_left = "Scheduled time has passed / Waiting for host to join";
                    }
                }
            }
        }

        service.decorateCall(call);

        _inner.calls.data[call.mre_call_id] = call;
        if(!_inner.activeCalls.data)
            _inner.activeCalls.data = {};
        if(!_inner.historyCalls.data)
            _inner.historyCalls.data = {};
        if(call.is_active) {
            _inner.activeCalls.data[call.mre_call_id] = call;
            delete _inner.historyCalls.data[call.mre_call_id];
        } else {
            delete _inner.activeCalls.data[call.mre_call_id];
            _inner.historyCalls.data[call.mre_call_id] = call;
        }
        if(service.getActiveCallId() === call.mre_call_id)
            service.updateActiveCall(call);
    }
    service.removeCall = function(id) {
        if(!id)
            return;
        if(id) {
            if(_inner.calls.data)
                delete _inner.calls.data[id];
            if(_inner.activeCalls.data)
                delete _inner.activeCalls.data[id];
            if(_inner.historyCalls.data)
                delete _inner.historyCalls.data[id];
        }
    }
    service.isRoom = function(obj) {
        if(!obj)
            return;
        return obj.mre_call_room_id && obj.mre_call_room_id===obj.mre_call_id? true: false;
    }
    service.getActiveCalls=function() {
        let filter = [];
        for(let c in _inner.activeCalls.data) {
            const call = _inner.activeCalls.data[c];
            if(service.isRoom(call))
                continue;
            filter.push(call);
        }
        return filter;
    }
    service.decorateCall = (call)=>{
        if(typeof call !== "object") return null;
        call.startTimestamp = typeof call.start_time === "number" ? call.start_time : new Date(call.start_time).getTime();
        call.scheduleTimestamp = typeof call.scheduled_date === "number" ? call.scheduled_date : new Date(call.scheduled_date).getTime();
        call.stopTimestamp = typeof call.stop_time === "number" ? call.stop_time : new Date(call.stop_time).getTime();
        call.isCancelled = call.stop_time && !call.start_time? true : false;
        return call;
    }
    service.getMaxLastUpdate = function() {
        if(!_inner.activeCalls || !_inner.activeCalls.data)
            return 0 ;
        let max = 0;
        for(let k in _inner.activeCalls.data) {
            let call = _inner.activeCalls.data[k];
            if(call.updated_at && call.updated_at>max) {
                max = call.updated_at;
            }
        }
        return max>0? max: 0;
    }
    service.getHistoricalCalls=function() {
        return _inner.historyCalls.data ? Object.values(_inner.historyCalls.data) : [];
    }
    service.onGetHistoricalCalls = function(payload={}) {
        return new Promise((resolve, reject)=>{
            let options = {
                StartIndex : typeof payload.StartIndex=='number' ? payload.StartIndex : _inner.historyCalls.lastStartIndex,
                FetchAmount: payload.FetchAmount || 20,
                FromDate:payload.FromDate ? payload.FromDate : null,
                ToDate:payload.ToDate ? payload.ToDate : null
            }
            service.onLoadMyCalls(options)
            .then((res)=>{
                if(res.MreCalls){
                    let inc = 0;
                    if(+payload.ClearOldCalls === 1){
                        service.clearHistoricalCalls();
                    }
                    res.MreCalls.forEach((call)=>{
                        if(!call.is_active) {
                            service.decorateCall(call);
                            _inner.historyCalls.data[call.mre_call_id] = call;
                        }
                        inc++;
                    })
                    if(options.StartIndex >= _inner.historyCalls.lastStartIndex || !options.StartIndex){
                        _inner.historyCalls.lastStartIndex = parseInt(_inner.historyCalls.lastStartIndex) + inc;
                    } else if (inc < options.FetchAmount) {
                        _inner.historyCalls.lastStartIndex = parseInt(_inner.historyCalls.lastStartIndex) + (_inner.historyCalls.lastStartIndex - options.StartIndex)*-1 + inc;
                    } else {
                        _inner.historyCalls.lastStartIndex = parseInt(_inner.historyCalls.lastStartIndex) + (_inner.historyCalls.lastStartIndex - options.StartIndex)*-1 + options.FetchAmount;
                    }
                }
                resolve(res.MreCalls ? res.MreCalls.length : 0);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onLoadCalls = function(params={}) {
        return new Promise((resolve, reject)=>{
            if(!serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            let url = '/api/call?';
            if(params)
                for(let k in params)
                    url += ('&'+k+'='+params[k]);

            networkI.get({url: url})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                if(res.MreCalls && res.MreCalls.length>0) {
                    for(let i=0; i<res.MreCalls.length; i++) {
                        if(!res.MreCalls[i].mre_call_id)
                          continue;
                        service.updateCall(res.MreCalls[i], res);
                    }
                    setTimeout(()=>{
                        busI.notifyEvent(busI.EVENTS.ON_CALLS_UPDATED);
                    });
                }
                clearTimeout(_inner.onUpdateActiveCallsTimer)
                _inner.onUpdateActiveCallsTimer = setTimeout(()=>{
                    if(!params)
                        params = {};
                    params.maxLastUpdate = service.getMaxLastUpdate();
                    service.onLoadCalls(params).then(()=>{}, ()=>{});
                }, 55000);
                resolve(res)
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onLoadMyCalls = function(options={}) {
        return new Promise((resolve, reject)=>{
            if(!serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                AccessToken: userI.getAccessToken(),
                V2: true
            };
            payload.StartIndex = typeof options.StartIndex === "number" ? options.StartIndex : (_inner.calls.lastStartIndex>0)? _inner.calls.lastStartIndex: 0;
            payload.FetchAmount = options.FetchAmount ? options.FetchAmount : 20;

            if(options.FromDate){ payload.FromDate = options.FromDate }
            if(options.ToDate){ payload.ToDate = options.ToDate }

            networkI.post({url: '/api/mymrecalls', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                _inner.calls.lastStartIndex = res.StartIndex>0? res.StartIndex: _inner.calls.lastStartIndex;
                if(res.MreCalls) {
                    for(let i=0; i<res.MreCalls.length; i++) {
                        if(!res.MreCalls[i].mre_call_id)
                          continue;
                        service.updateCall(res.MreCalls[i], res);
                        _inner.calls.lastStartIndex++;
                    }
                }
                resolve(res)
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onReachUserCallByToken = function(code) {
        return new Promise((resolve, reject) => {
            if(!code || typeof code!=='string' || !serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            if(!userI.getAccessToken() || userI.isGuest()) {
                reject();
                return;
            }
            networkI.get({url: '/api/user/call?code='+code})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onLoadCallByIdOrToken = function(token, callId) {
        return new Promise((resolve, reject) => {
            if((!token&&!callId) || (typeof token!=='string' && typeof callId != 'string') || !serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            let url = '/api/guest/call?providerId='+serviceProviderI.getServiceProviderId();
            if(callId)
                url += '&callId='+callId;
            else if(token)
                url += '&code='+token;
            networkI.get({url: url})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                if(res.MreCall&&res.MreCall.is_active&&!res.MreCall.is_chat_only) {
                    busI.notifyEvent(busI.EVENTS.ON_NOTIFY_ACTIVE_CALL, res.MreCall);
                } else
                    service.clearActive();
                resolve(res.MreCall);
            }, (error)=>{
                service.clearActive();
                reject(error || langI.get('ERROR_UNK'));
            });
        });
    }
    service.onLoadCallByLink = function(link) {
        return new Promise((resolve, reject) => {
            if(!link || typeof link!=='string') {
                reject();
                return;
            }
            networkI.get({url: '/api'+link})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                if(res.MreCall&&res.MreCall.is_active&&!res.MreCall.is_chat_only) {
                    resolve(res.MreCall);
                } else
                    reject()
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.joinAsGuest = function(token, name, code) {
        return new Promise((resolve, reject) => {
            if(!token || !name) {
                reject();
                return;
            }
            if(!serviceProviderI.getServiceProviderId() || !name || !token) {
                reject();
                return;
            }

            let payload = {
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                MreCallJoinCode: token,
                GuestName: name
            };
            let headers = {}

            if(callI.getInternalKey()){
                payload.IsInternal = true;
                headers['Authorization'] = callI.getInternalKey();
            }

            if(service.getActiveCallJoinCode() === token)
                payload.MreCallID = service.getActiveCallId();

            if(code && code.trim())
                payload.Password = code;
            else if(_inner.passCode)
                payload.Password = _inner.passCode;

            let email = userI.getGuestEmail();
            if(email && typeof email === 'string')
                payload.GuestEmail = email;

            if(userI.getAccessToken() && service.getActiveParticipantId()) {
                payload.MreCallParticipantID = service.getActiveParticipantId();
                payload.AccessToken = userI.getAccessToken();
            }


            _inner.savePassCode(payload.Password);

            networkI.post({url: '/api/guest/call/join', data: payload, headers})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                userI.updateAppConfig(res.AppConfig);
                service.setActive({participant: res.MreCallParticipant, call: res.MreCall});
                participantI.updateParticipants(res.MreCallParticipants? res.MreCallParticipants: null);
                streamI.updateStreams(res.MreCallStreams);
                userI.setIsOnFreeBilling(res.BillingFreeDebugEnabled);
                service.setCredits(res.Billing);
                if(res.AuthToken || res.AccessToken) {
                    userI.setAccessToken(res.AuthToken || res.AccessToken);
                    userI.setRefreshToken(res.RefreshToken);
                }
                userI.updateAccountProfile(res.AccountProfile);
                userI.setWhoAmI({AccountProfile: res.AccountProfile, Roles: res.Roles, Language: res.Language, IsAdmin: res.IsAdmin});
                userI.saveAuth();
                userI.setRole();
                langI.onLoadUserLanguages()
                .then(()=>{
                    langI.initSelectedLanguage();
                    let obj = {
                        MreCall: res.MreCall,
                        MreCallParticipant: res.MreCallParticipant,
                    };
                    service.storeActiveCallInStorage(obj);
                    transcriptionI.updateTranscriptionStateBasedOnCurrentParticipant(obj);
                    busI.notifyEvent(busI.EVENTS.ON_LOGIN);
                    resolve(res);
                }, (error)=>{
                    reject(error);
                });
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onJoin = function(token, call, code = '') {
        return new Promise((resolve, reject) => {
            if(!token&&!call) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: serviceProviderI.getServiceProvider(),
                AccessToken: userI.getAccessToken(),
                AccountProfile: userI.getAccountProfile()
            };
            if(token)
                payload.MreCall = {
                    join_code: token
                }
            if(call)
                payload.MreCall = call;
            if(code && code.trim())
                payload.Password = code.trim();
            else if(_inner.passCode)
                payload.Password = _inner.passCode;

            _inner.savePassCode(payload.Password);

            networkI.post({url: '/api/call/join', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                userI.updateAppConfig(res.AppConfig);
                langI.onLoadUserLanguages()
                .then(()=>{
                    langI.initSelectedLanguage();
                    if(res.MreCall && !res.MreCall.is_chat_only) {
                        streamI.updateWaitingRoomParticipants(res.MreCallParticipants);
                        service.setActive({participant: res.MreCallParticipant, call: res.MreCall});
                        participantI.updateParticipants(res.MreCallParticipants? res.MreCallParticipants: null);
                        streamI.updateStreams(res.MreCallStreams);
                        userI.setIsOnFreeBilling(res.BillingFreeDebugEnabled);
                        service.setCredits(res.Billing);
                        let obj = {
                            MreCall: res.MreCall,
                            MreCallParticipant: res.MreCallParticipant
                        };
                        service.storeActiveCallInStorage(obj);
                        transcriptionI.updateTranscriptionStateBasedOnCurrentParticipant(obj);
                        resolve(res);
                    } else
                        resolve(res);
                }, (error)=>{
                    reject();
                });
            }, (error)=>{
                reject(error? error: guestI.MESSAGES.FAILED_JOIN_CALL);
            });
        });
    }
    service.onLeaveCall = function(id) {
        return new Promise((resolve, reject) => {
            if(!service.getActiveParticipantAccountId() || !id) {
              resolve();
              return;
            }
            let payload = {
              MreCallProvider: serviceProviderI.getServiceProviderForApi(),
              MreCallParticipant: service.getActiveParticipant()
            };
            networkI.post({url: '/api/call/'+id+'/leave', data: payload})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                service.updateCall(res.MreCall, res);
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onLeaveParticipantFromCall = function(participant, accessToken) {
        return new Promise((resolve, reject) => {
            if(!participant || !accessToken || !service.getActiveCallId()) {
                resolve();
                return;
            }
            let payload = {
              MreCallProvider: serviceProviderI.getServiceProviderForApi(),
              MreCallParticipant: participant
            };
            networkI.post({url: '/api/call/'+service.getActiveCallId()+'/leave', data: payload})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.clean = function()  {
        _inner.passCode = '';
        _inner.tmp_avatar_url = null;
        service.setIsTempAvatarUploading(false)
        busI.unregisterEvent(busI.EVENTS.ON_CHANGE_TMP_AVATAR,_inner.changeTempAvatarCallback)
        if(_inner.ui && _inner.ui.settings) {
            _inner.ui.settings.isTextToSpeechForTranscriptionEnabled = false;
            _inner.ui.settings.isSpeechToSpeechEnabled = false;
            _inner.ui.settings.isTTSSynthesisEnabled = false;
        }
    }
    service.clear = function() {
        _inner.calls = {
            data: {},
            lastStartIndex: 0
        };
        service.clearStoredActiveCall();
        service.clean();
        _inner.notifyUI();
    }
    service.clearHistoricalCalls= function(){
        _inner.historyCalls.data = {};
        _inner.historyCalls.lastStartIndex = 0;
    }
    service.clearActiveCalls = function(){
        _inner.activeCalls.data = {};
        _inner.activeCalls.lastStartIndex = 0;
    }
    service.bring = function(component) {
        if (component == "all-calls" && _inner.activeCallsComponent != "all-calls") {
            _inner.activeCallsComponent = component;
        } else if (component == "make-call" && _inner.activeCallsComponent != "make-call") {
            _inner.activeCallsComponent = component;
        } else if (component == "join-call" && _inner.activeCallsComponent != "join-call") {
            _inner.activeCallsComponent = component;
        } else if (component == "schedule-call" && _inner.activeCallsComponent != "schedule-call") {
            _inner.activeCallsComponent = component;
        } else {
            _inner.activeCallsComponent = component;
        }
        _inner.notifyUI();
    }
    service.isMakeCallTheActiveComponent = function() {
        return _inner.activeCallsComponent==='make-call'? true: false;
    }
    service.setCallAttachments = function(attachments) {
        _inner.attachments = Array.isArray(attachments)? attachments: null;
    }
    service.onSendAttachment = function(obj, type=null) {
        return new Promise((resolve, reject) => {
            if(!obj || typeof obj !== 'object' || !callI.getActiveCallId() || !serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }

            let payload = {
                MreCallParticipant: callI.getActiveParticipant()
            };

            for(let k in obj)
                payload[k] = obj[k];

            let url = '/api/call/'+callI.getActiveCallId()+'/attachment/';
            if(typeof type !== 'string')
                type = '';

            networkI.post({url: url + type, data: payload})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onCheckExistingCallOnStorage = function() {
        return new Promise((resolve, reject) => {
            let callInStorage = service.getActiveCallInStorage();
            if(!callInStorage || roomI.isOnActiveRoom()) {
                reject();
                return;
            }
            let obj = null;
            try {
                obj = JSON.parse(callInStorage);
            } catch (error) {
                service.clearStoredActiveCall();
                service.clearActive();
                reject();
                return;
            }
            if(obj.MreCall) {
                if(service.isRoom(obj.MreCall)) {
                    reject();
                    return;
                }
                service.onLoadExpandedCall(obj.MreCall.mre_call_id, {participants: 1})
                .then((res)=>{
                    let call = res.MreCall;
                    if(call&&call.is_active&&!call.is_chat_only) {
                        service.setActive({participant: obj.MreCallParticipant, call: obj.MreCall});
                        resolve(call);
                    } else {
                        service.clearStoredActiveCall();
                        service.clearActive();
                        reject();
                    }
                }, (error)=>{
                    service.clearStoredActiveCall();
                    service.clearActive();
                    reject();
                });
            }
        })
    }
    _inner.reconnectOnFocusDocument = () => {
        service.reconnectSocket();
    }
    _inner.onVisibilityChange = function() {
        clearTimeout(_inner.documentFocusTimer);
        _inner.documentFocusTimer = setTimeout(()=>{
            if(_inner.hasFocus() && _inner.reconnectOnFocusDocument && typeof _inner.reconnectOnFocusDocument === 'function')
                _inner.reconnectOnFocusDocument();
        }, 500);
    }
    _inner.hasFocus = function() {
        return !document.visibilityState || document.visibilityState!=='hidden'? true: false;
    }
    service.reconnectSocket = function() {
        service.disconnectSocket(callI.getActiveCallId());
        setTimeout(()=>{
            service.connectSocket();
        }, 300);
    }
    service.connectSocket = function(callId, participantId) {
        if(!callId || !participantId || !userI.getAccessToken())
            return;
        if(streamI.isOnWaitingRoom()) {
            return;
        }
        let url = endpointI.getWSHost();
        _inner.socket = new WebSocket(url + '/api/chat/ws/'+ callId + '/' +participantId, ['Authorization', userI.getAccessToken()]);
        _inner.socket.onmessage = (wsdata) => {
            if (wsdata && wsdata.data) {
                try {
                    let result = JSON.parse(wsdata.data);
                    if(Array.isArray(result.WhiteBoards)){
                        whiteBoardI.handleSocketMessage(result.WhiteBoards)
                        return
                    }
                    if(result && (result.ChatMessage || result.MreCallTranscription))
                        chatI.handleChatReceived(result);
                    else if(result && result.Poll)
                        pollI.handlePollReceived(result);
                } catch (error) {
                }
            }
        };
        _inner.socket.onerror = (error) =>{
            clearTimeout(_inner.callSocketReconnectTimer);
            _inner.callSocketReconnectTimer = setTimeout(()=>{
                if(!streamI.isLeavingCall() && service.getActiveCallId()===callId && participantId===service.getActiveParticipantId())
                    service.connectSocket(callId, participantId);
            }, 1000);
        }
        _inner.socket.onopen = (res) =>{
            document.removeEventListener('visibilitychange', _inner.onVisibilityChange);
            document.addEventListener('visibilitychange', _inner.onVisibilityChange);
            clearTimeout(_inner.callSocketReconnectTimer);
        }
        _inner.socket.onclose = (res) =>{
            clearTimeout(_inner.callSocketReconnectTimer);
            _inner.callSocketReconnectTimer = setTimeout(()=>{
                if(!streamI.isLeavingCall() && service.getActiveCallId()===callId && participantId===service.getActiveParticipantId())
                    service.connectSocket(callId, participantId);
            }, 1000);
        }
    }
    service.disconnectSocket = function(callId) {
        if(!callId || !_inner.socket || !_inner.socket.url || _inner.socket.url.indexOf(callId)==-1)
            return;
        if(_inner.socket.readyState !== _inner.socket.CLOSED && _inner.socket.readyState !== _inner.socket.CLOSING)
            _inner.socket.close();
        _inner.socket = null;
    }
    service.sendSocketMessage = function(obj) {
        if(typeof obj !== 'object')
            return;
        if (_inner.socket && _inner.socket.readyState == _inner.socket.OPEN) {
            _inner.socket.send(JSON.stringify(obj));
        }
    }
    service.onFetchDialCodes = (code)=>{
        return new Promise((resolve,reject)=>{
            if(!code || typeof code !=='string') {
                reject();
                return
            }
            let url = '/api/user/phone/dialcode';
            url += '?code='+code;
            networkI.get({url: url})
            .then((res)=>{
                if(res && Array.isArray(res.PhoneDialCodes)) {
                    resolve(res.PhoneDialCodes);
                } else
                    reject();
            }, (error)=>{
                reject(error);
            });
        })
    }
    service.updateGlobalCounter = ()=>{
        _inner.globalCounter = 0;
        if(_inner.ui && _inner.ui.unreadMessagesCounter > 0) _inner.globalCounter++;
        if(streamI.getParticipantsOnWaitingRoomCounter() > 0) _inner.globalCounter++;
        if(pollI.getCounter() > 0) _inner.globalCounter++;
        _inner.notifyUI();
    }
    service.resetGlobalCounter = ()=>{
        _inner.globalCounter = 0;
        _inner.notifyUI();
    }
    service.syncUi = ()=>{
        window.clearTimeout(_inner.postMessageTimeout)
        _inner.postMessageTimeout = window.setTimeout(()=>{
            messagingI.postMessageToServiceWorker({
                target:'callI',
                ui:_inner.ui
            })
        },50)
    }
    service.setCallInviteNotificationClicked = (mre_call_id) => {
        if(!mre_call_id) return
        _inner.isCallInviteNotificationClicked[mre_call_id] = true;
    }
    service.isCallInviteNotificationClicked = (mre_call_id) => mre_call_id ? _inner.isCallInviteNotificationClicked[mre_call_id] : false;
    service.onHandleChangeTempAvatar = (file)=>{
        return new Promise((resolve,reject)=>{
            if(!file) return reject();
            const overlay = streamI.getElementById(callI.getActiveParticipantId())?.div?.querySelector('.profile-picture-overlay')
            if(overlay){
                ["active","loading"].forEach(c=>overlay.classList.add(c))
            }
            service.setIsTempAvatarUploading(true)
            _inner.notifyUI();
            let formData = new FormData();
            formData.append('file', file);
            networkI.put({url: `/api/participant/${callI.getActiveParticipantId()}/avatar`, formData: formData})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                participantI.updateParticipants([res.MreCallParticipant]);
                resolve(res.MreCallParticipant && res.MreCallParticipant.avatar_url? res.MreCallParticipant.avatar_url: _inner.tmp_avatar_url);
            }, (error)=>{
                reject(error);
            }).finally(()=>{
                if(overlay){
                    ["active","loading"].forEach(c=>overlay.classList.remove(c))
                }
                service.setIsTempAvatarUploading(false)
            });
        })
    }
    service.setTempAvatar = (value)=>{
        _inner.tmp_avatar_url = value? value: '';
        if(!_inner.tmp_avatar_url) {
            let activeParticipant = service.getActiveParticipant();
            if(activeParticipant && activeParticipant.avatar_url)
                _inner.tmp_avatar_url = activeParticipant.avatar_url;
        }
        _inner.notifyUI();
    }
    _inner.changeTempAvatarCallback = (obj)=>{
        if(typeof obj !== "object" || !obj.id || !obj.url) return
        let div = streamI.getElementById(obj.id)?.div
        if(!div) return
        let img = div.querySelector('.user-avatar-container img');
        if(!img) return
        img.onload = ()=>{
            if(obj.id === callI.getActiveParticipantId()){
                toastI.success(langI.get('AVATAR_UPDATE_SUCCESS'))
                livekitI.updateMetadata()
                service.setIsTempAvatarUploading(false);
                service.setTempAvatar(obj.url)
                const overlay = streamI.getElementById(callI.getActiveParticipantId())?.div?.querySelector('.profile-picture-overlay')
                if(overlay){
                    ["active","loading"].forEach(c=>overlay.classList.remove(c))
                }
            }
        }
        img.src = obj.url;
      }
    service.initTempAvatarEventListener = ()=>{
        busI.unregisterEvent(busI.EVENTS.ON_CHANGE_TMP_AVATAR,_inner.changeTempAvatarCallback)
        busI.registerEvent(busI.EVENTS.ON_CHANGE_TMP_AVATAR,_inner.changeTempAvatarCallback);
    }
    service.isTempAvatarUploading = ()=> _inner.is_tmp_avatar_uploading;
    service.setIsTempAvatarUploading  = (value)=>{
        _inner.is_tmp_avatar_uploading = +value === 1 ? true : false;
        _inner.notifyUI();
    }
    service.isTestingBrowser = ()=> window.TESTING_BROWSER? true : false;
    service.exposeIForTesting = () => {
        service.getTestJoinLink = ()=>{
            let call = service.getActiveCall();
            let callToken = call&&call.token? call.token: call&&call.join_code? call.join_code: '';
            return `${window.origin}/?token=${callToken}&endpoint=${endpointI.getSelectedEndpointName()}&provider=${serviceProviderI.getServiceProviderId()}`
        }
        window.callI = service;
    }
    service.getRecordingPassById = (callId) => {
        if(!callId)
            return '';
        const call = callId===service.getActiveCallId()? service.getActiveCall(): service.getCallById(callId);
        if(!call)
            return '';
        return call && call.recording_pass ? call.recording_pass: userI.getAccountCallRecordingPass()
    }
    service.onStoreEvent = function(id, payload) {
        return new Promise((resolve, reject)=>{
            if(!id || !payload || typeof payload !== 'object') {
                reject();
                return;
            }
            networkI.post({url: '/api/call/'+id+'/event', data: payload})
            .then((response)=>{
                if(Array.isArray(response.errors)) {
                    reject(networkI.getErrorMessageFromArr(response.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                resolve(response);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.openCallDetails = (call)=>{
        if(typeof call !== "object") return
        modalI.init();
        setTimeout(()=>{
            modalI.openModal({
                id: 'call-details',
                onClose:()=>{
                  transcriptionI.clearCallTranscriptions(call?.mre_call_id);
                }
              })
              service.onLoadExpandedCall(call.mre_call_id)
              .then((response)=>{
                modalI.registerData({
                  id: 'call-details',
                  data: {
                    call: response
                  }
                });
                modalI.notifyEvent({
                  id: 'call-details',
                  name: 'loadData',
                });
              }, (msg)=>{
                modalI.closeModal('call-details', true);
                toastI.error(msg && typeof msg=='string'? msg: langI.get('ERROR_UNK'));
              });
        })
    }
    service.toggleSTS = (enable)=>{
        service.getUIBind();
        _inner.ui.settings.isSpeechToSpeechEnabled = +enable === 1;
        _inner.ui.settings.updateSTS?.();
    }
    service.setIsInternal = (value)=>{
        _inner.isInternal = +value === 1;
    }
    service.setInternalKey = (value)=>{
        _inner.internalKey = value
    }
    service.getInternalKey = ()=> _inner.internalKey
    service.getIsInternal = () => participantI.isInternalParticipant(callI.getActiveParticipant()) || callI.getInternalKey() ? true : false
    service.getActiveCallTicketPayload = ()=>{
        const call = service.getActiveCall();
        return {
            call_id:call?.mre_call_id || "",
            origin:window.origin,
            token:call&&call.token? call.token: call&&call.join_code? call.join_code: '',
            endpoint:endpointI.getSelectedEndpointName(),
            provider:serviceProviderI.getServiceProviderId()
        }
    }
    service.CALL_KIND = {
        HISTORY:'history',
        UPCOMING:'upcoming'
    }
    service.sortCalls = (calls,kind)=>{
        if(!Array.isArray(calls) || typeof kind !== "string" || !kind.length || calls.length === 0) return []
        return calls.sort((a,b)=>{
            if(kind === service.CALL_KIND.UPCOMING){
                return a.scheduleTimestamp<b.scheduleTimestamp? -1: (a.scheduleTimestamp>b.scheduleTimestamp)? 1: (a.startTimestamp<b.startTimestamp)? -1: a.startTimestamp>b.startTimestamp? 1: 0;
            } else {
                return a.stopTimestamp<b.stopTimestamp? 1: (a.stopTimestamp>b.stopTimestamp)? -1: (a.startTimestamp<b.startTimestamp)? 1: a.startTimestamp>b.startTimestamp? -1: 0;
            }
        })
    }
    service.isActiveCallScheduled = ()=> service.getActiveCall()?.is_scheduled
    if(isDEBUG){
        service.getInner = ()=> _inner;
        window.callI = service;
    }
    return service;
}

export const callI = CallI();