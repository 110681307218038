import { busI } from "./busI.js";
import { endpointI } from "./endpointI.js";
import { serviceProviderI } from "./serviceProviderI.js";
import { userI } from "./userI.js";
import { toastI } from "./toastI.js";
import { navbarI } from "./navbarI.js";
import {widgetI} from "./widgetI";
import {environment} from 'src/environments/environment'
import { langI } from "./langI.js";
import { networkI } from "./networkI.js";
import { callI } from "./callI.js";

let GuestI = function () {
    let service = {};
    let _inner = {};
    _inner.accessToken = null;
    service.MESSAGES = {
        FAILED_LOGIN: 'Login cancelled or did not fully authorize.',
        FAILED_JOIN_CALL: 'Error while joining call',
        FAILED_LOGIN: 'Error while login'
    }
    service.onForgotPassword = function(payload) {
        return new Promise((resolve, reject)=>{
            if(!payload) {
                reject();
                return;
            }
            networkI.post({url: '/api/forgotpassword', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onRequestEmailVerificationCode = function(email) {
        return new Promise((resolve, reject)=>{
            if(!email || !serviceProviderI.getServiceProviderId()) {
                reject();
                return;
            }
            networkI.get({url: '/api/email/verificationcode?email='+email+'&providerId='+serviceProviderI.getServiceProviderId()})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onSubmitVerificationCode = function(email, code) {
        return new Promise((resolve, reject)=>{
            if(!email || !code) {
                reject();
                return;
            }
            let payload = {
                EmailAddress: email,
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                Code: code
            };
            networkI.post({url: '/api/email/verificationcode', data: payload})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors));
                    return;
                }
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onRegister = function(info, account) {
        return new Promise((resolve, reject) => {
            if(!info || !account) {
                reject();
                return;
            }
            let payload = {
                Credentials: info,
                MreCallProvider: serviceProviderI.getServiceProviderForApi(),
                AccountProfile: account
            };
            networkI.post({url: '/api/register', data: payload})
            .then((res)=>{
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onCheckUsernameAvailable = function(username) {
        return new Promise((resolve, reject) => {
            if(!username) {
                reject();
                return;
            }
            networkI.get({url: '/api/guest/usernameavailability?username='+username+'&providerId='+serviceProviderI.getServiceProviderId()})
            .then((res)=>{
                if(Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                resolve(res.Available? true: false);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.onLogin = function(payload) {
        return new Promise((resolve, reject) => {
            if(!payload || typeof payload !== 'object') {
                reject();
                return;
            }
            payload.MreCallProvider = serviceProviderI.getServiceProviderForApi();
            let path = payload.AccessToken? 'relogin': 'login';
            let opt = {url: '/api/'+path, data: payload};
            if(payload.AccessToken) {
                opt.headers = {'Authorization': payload.AccessToken};
                delete payload.AccessToken;
            }
            networkI.post(opt)
            .then((res)=>{
                userI.updateAppConfig(res.AppConfig);
                resolve(res);
            }, (error)=>{
                reject(error);
            });
        });
    }
    service.isConnected = function() {
        return true;
    }

    service.onInit = (link) => {
        return new Promise((resolve, reject)=>{
            link = link && typeof link==='string'? link: location.href;
            let path = callI.getJoinPath();
            if(link.indexOf(path)==-1) {
                resolve();
                return;
            }
            let env = '';
            if(environment.TMU_ENV) {
                env = environment.TMU_ENV=='prod'? 'alpha': environment.TMU_ENV;
            }
            if(!env) {
                env = endpointI.isOnDev()? 'local': endpointI.isOnDevEndpoint()? 'dev': 'alpha';
            }
            endpointI.init(env);
            link = link.substring(link.indexOf(path), link.length);
            callI.onLoadCallByLink(link)
            .then((res)=>{
                resolve(res && res.join_code? res.join_code: '');
            }, ()=>{
                resolve();
            });
        });
    }

    service.init = function() {
        let link = location.href;
        clearTimeout(_inner.onGuestConnectedTimer);
        _inner.onGuestConnectedTimer = setTimeout(()=>{
            service.onInit(link).then((callToken)=>{
                serviceProviderI.onLoadProviders()
                .then((response)=>{
                    busI.notifyEvent(busI.EVENTS.ON_GUEST_CONNECTED);
                    let authToken = null;
                    try {
                        authToken = JSON.parse(userI.getStoredAuth());
                    } catch (error) {}
                    if (
                        authToken &&
                        authToken.Endpoint == endpointI.getSelectedEndpointName() &&
                        authToken.MreCallProvider == serviceProviderI.getServiceProviderId() &&
                        !widgetI.isWidget
                    ) {
                        userI.setAccessToken(authToken.AccessToken);
                        userI.setRefreshToken(authToken.RefreshToken);
                        userI.onLogin(null, null, userI.getAccessToken())
                        .then(()=>{
                            busI.notifyEvent(busI.EVENTS.ON_LOGIN);
                            userI.setRole();
                            endpointI.configure({loggedIn: true, token: callToken});
                        }, (msg)=>{
                            toastI.error(msg);
                            userI.logout();
                        });
                    } else {
                        //we make sure we clear auth stored if different provider or endpoint
                        if(authToken && ((authToken.Endpoint
                        && endpointI.getSelectedEndpointName() !== authToken.Endpoint)
                        ||
                        authToken.MreCallProvider !== serviceProviderI.getServiceProviderId()
                        )) {
                            userI.clearAuth();
                        }
                        endpointI.configure({loggedIn: false, token: callToken});
                    }
                    let providerId = serviceProviderI.getServiceProviderId();
                    if(providerId) {
                        serviceProviderI.onLoadProviderDetails(providerId).then(()=>{}, ()=>{});
                    } else {
                        navbarI.navForward('error');
                    }
                },()=>{
                    navbarI.navForward('error');
                });
            }, ()=>{
                navbarI.navForward('error');
            });
        }, 250);
    }
    return service;
}
export const guestI = GuestI();
