import { busI } from "src/assets/js/busI";
import { endpointI } from "./endpointI";
import { integrationI } from "./integrationI";
import { permissionI } from "./permissionI";
import { utilI } from "./utilI";
import { navbarI } from "./navbarI";
import { callI } from "./callI";
import { messagingI } from "./messagingI";
import { networkI } from "./networkI";
let ServiceProviderI = function () {
    let service = {};
    let _inner = {};
    _inner.MorshidName = 'Morshid';
    _inner.Eco123Name = 'ECO123';
    _inner.spStoredKey = 'tmu.WebApp.MreCallProvider';
    _inner.provider = null;
    _inner.providers = {};
    service.getDefaultProviderId = function() {
        return endpointI.isECO123()? service.getEco123ProviderId(): endpointI.isMorshid()?  service.getMorshidProviderId(): '6c989b08-935e-4175-8c8c-0dbbeccca1d5';
    }
    service.getPropectIntntProviderId = function() {
        return '337a788b-a74d-4888-9481-f5bea724d8ad';
    }
    service.getEco123ProviderId = function() {
        for(let k in _inner.providers) {
            if(_inner.providers[k].name.toLowerCase().trim()===_inner.Eco123Name.toLowerCase().trim())
                return _inner.providers[k].mre_call_provider_id;
        }
        return null;
        // return 'c5b8075b-88ea-4799-9683-7e938f071ebf';
    }
    service.getMorshidProviderId = function() {
        for(let k in _inner.providers) {
            if(_inner.providers[k].name.toLowerCase().trim()===_inner.MorshidName.toLowerCase().trim())
                return _inner.providers[k].mre_call_provider_id;
        }
        return null;
        // return '2ab43522-e29a-4db4-a1a1-6dcad3f5235b';
    }
    service.decorateProvider = function(sp) {
        if(!sp || !sp.mre_call_provider_id)
            return
        //for now all providers should display login screen
        sp.allow_login = 1;
        sp.allow_register = 1;
        sp.allow_anonymous_users = 1;
        sp.call_start_name = 'Start';
        sp.mre_call_name_singular = 'Call';
        if(sp.config) {
            try {
                let config = JSON.parse(sp.config);
                sp.icon_url = config.iconUrl? config.iconUrl: sp.icon_url;
                sp.botURL = config.botURL? config.botURL: '';
                sp.bot = config.bot? config.bot: '';
                for(let k in config)
                    sp[k] = config[k];
            } catch (error) {}
        }
        if(!sp.hasChangeProviderDisabled && (endpointI.isECO123() || endpointI.isMorshid()))
            sp.hasChangeProviderDisabled = true;
        if(!_inner.providers)
            _inner.providers = {};
        _inner.providers[sp.mre_call_provider_id] = sp;
    }
    service.updateProviders = function(providers) {
        if(!Array.isArray(providers))
            return;
        for(let i=0; i<providers.length; i++) {
            if(!providers[i].mre_call_provider_id)
                continue;
            service.decorateProvider(providers[i]);
        }
        busI.notifyEvent(busI.EVENTS.ON_SERVICE_PROVIDERS_UPDATED);
    }
    service.getProviders = function() {
        let filtered = [];
        if(_inner.providers)
            for(let p in _inner.providers)
                filtered.push(_inner.providers[p]);
        return filtered;
    }
    service.updateServiceProvider = function(sp) {
        if(!sp || !sp.name || !sp.mre_call_provider_id || typeof sp !== 'object')
            return;
        service.decorateProvider(sp);
        _inner.provider = sp;
        utilI.updateLocalStorage(_inner.spStoredKey, JSON.stringify(sp));
        busI.notifyEvent(busI.EVENTS.ON_SP_CHANGED);
        permissionI.init(sp);
        navbarI.clearNextPage();
        callI.setNavToDefault();
        navbarI.setIsWebMode(!service.isECO123() && !service.isMorshid() && !service.hasBotExperience()? 1: 0);
        _inner.notifyUI();
    }
    service.getById = function(id) {
        return id&&_inner.providers[id]? _inner.providers[id]: null;
    }
    service.getServiceProvider = function() {
        if(!_inner.provider) {
            try {
                let sp = JSON.parse(utilI.getFromLocalStorage(_inner.spStoredKey));
                if(sp.mre_call_provider_id)
                    _inner.provider = sp;
            } catch (error) {}
        }
        return _inner.provider;
    }
    service.getServiceProviderForApi = function() {
        return {mre_call_provider_id: service.getServiceProviderId()}
    }
    service.getServiceProviderId = function() {
        let sp = service.getServiceProvider();
        return sp? sp.mre_call_provider_id: null;
    }
    service.getServiceProviderName = function() {
        let sp = service.getServiceProvider();
        return sp? sp.name: '';
    }
    service.getServiceProviderIcon = function() {
        let sp = service.getServiceProvider();
        return sp? sp.icon_url: '';
    }
    service.isServiceProviderAllowsParticipantstoStream = function() {
        let sp = service.getServiceProvider();
        return sp && sp.allow_participants_to_stream? true: false;
    }
    service.hasSIP = function() {
        let sp = service.getServiceProvider();
        return sp && sp.has_sip? true: false;
    }
    service.hasMissionForm = function() {
        let sp = service.getServiceProvider();
        return sp && sp.has_mission_form? true: false;
    }
    service.hasIntegrationsSettings = function() {
        let sp = service.getServiceProvider();
        return sp && sp.has_integrations_disabled? false: true;
    }
    service.hasAdvancedSettings = function() {
        let sp = service.getServiceProvider();
        return sp && sp.has_advanced_settings_disabled? false: true;
    }
    service.hasDirectChatDisabled = function() {
        let sp = service.getServiceProvider();
        return sp && sp.has_direct_chat_disabled? true: false;
    }
    service.hasContactsDisabled = function() {
        let sp = service.getServiceProvider();
        return sp && sp.has_contacts_disabled? true: false;
    }
    service.providersAvailable = function() {
        for(let k in _inner.providers)
            return true;
        return false;
    }
    service.onLoadProviders = function() {
        return new Promise((resolve, reject) => {
            if(service.getProviders().length>0) {
                resolve({MreCallProviders: service.getProviders()});
                return;
            }
            networkI.get({url: '/api/providers'})
            .then((res)=>{
                if (Array.isArray(res.errors)) {
                    reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                    return;
                }
                service.updateProviders(res.MreCallProviders);
                let sp = service.getServiceProvider();
                let providers = service.getProviders();
                if (
                    sp &&
                    providers.find((provider) => provider.name == sp.name)
                ) {
                    providers.forEach((provider) => {
                        if (provider.mre_call_provider_id == sp.mre_call_provider_id)
                            service.updateServiceProvider(provider);
                    });
                } else {
                    providers.forEach((provider) => {
                        if (provider.mre_call_provider_id == serviceProviderI.getDefaultProviderId())
                            service.updateServiceProvider(provider);
                    });
                }
                if(!service.getServiceProviderId()) {
                    if(providers.length>0)
                        service.updateServiceProvider(providers[0]);
                }
                resolve(res);
            }, (err)=>{
                reject(err);
            });
        });
    }
    service.onLoadProviderDetails = function(providerId) {
        return new Promise((resolve, reject) => {
            if(!providerId) {
                reject();
                return;
            }
            setTimeout(()=>{
                networkI.get({url: '/api/provider/'+providerId})
                .then((res)=>{
                    if (Array.isArray(res.errors)) {
                        reject(networkI.getErrorMessageFromArr(res.errors) || langI.get('ERROR_UNK'));
                        return;
                    }
                    service.updateProviders([res.MreCallProvider]);
                    if(res.Integrations)
                        integrationI.updateIntegrations(res.Integrations);
                    resolve(res);
                }, (err)=>{
                    reject(err);
                });
            }, 50);
        });
    }
    service.hasBotExperience = (id = null)=> {
        id = id || service.getServiceProviderId();
        let info = service.getProviderBotInfo(id);
        return info && info.hasBotExperience? true: false;
    }
    service.isMorshid = ()=> {
        return endpointI.isMorshid() || (service.getServiceProviderId() && service.getServiceProviderId()===service.getMorshidProviderId()) || serviceProviderI.getServiceProviderName().toLowerCase().trim().indexOf(_inner.MorshidName.toLowerCase().trim())>-1? true: false;
    }
    service.isECO123 = ()=> {
        return endpointI.isECO123() || (service.getServiceProviderId() && service.getServiceProviderId()===service.getEco123ProviderId()) || serviceProviderI.getServiceProviderName().toLowerCase().trim().indexOf(_inner.Eco123Name.toLowerCase().trim())>-1? true: false;
    }
    service.getLogo = ()=>{
        let icon = service.getServiceProviderIcon();
        if((service.isECO123() || service.isMorshid()) && !icon)
            icon = '/assets/eco123/eco123_logo.png';
        if(!icon)
            icon = '/assets/icons/tmu-logo-2.svg';
        return icon;
    }
    service.getProviderBotUrl = (id)=>{
        let provider = service.getById(id);
        return provider && provider.botURL? provider.botURL: '';
    }
    service.getProviderBotInfo = (id = null)=>{
        id = id || service.getServiceProviderId();
        let provider = service.getById(id);
        return provider && provider.bot? provider.bot: {};
    }
    service.isAnimBotEnabled = (id = null)=>{
        let info = service.getProviderBotInfo(id);
        return info && info.isAnimBotEnabled? true: false;
    }
    service.getName = ()=>{
        let name = service.getServiceProviderName();
        if(service.isECO123() && !name)
            name = _inner.Eco123Name;
        if(service.isMorshid() && !name)
            name = _inner.MorshidName;
        if(!name)
            name = 'TeleMeetUp';
        return name;
    }
    service.getAcronym = ()=>{
        return service.isECO123()? _inner.Eco123Name: service.isMorshid()? _inner.MorshidName: 'TMU';
    }
    service.getFavicon = ()=>{
        return service.isECO123() || service.isMorshid()? 'assets/eco123/favicon.ico': 'assets/favicon.ico';
    }
    service.hasInstantStart = ()=>{
        return service.isECO123() || service.isMorshid() || service.hasBotExperience()? true: false;
    }
    service.hasChangeProviderDisabled = () => {
        let sp = service.getServiceProvider();
        return sp && sp.hasChangeProviderDisabled? true: false;
    }
    _inner.notifyUI = function() {
        clearTimeout(_inner.notityUITimer);
        _inner.notityUITimer = setTimeout(()=>{
            if(!_inner.ui)
                service.getUIBind();
            _inner.ui.logo = service.getLogo();
            _inner.ui.name = service.getName();
            _inner.ui.acronym = service.getAcronym();
            _inner.ui.instantStartCall = service.hasInstantStart();
            _inner.ui.hasChangeProviderDisabled = service.hasChangeProviderDisabled();
            service.syncUi();
        }, 50);
    }
    service.getUIBind = function() {
        if(_inner.ui) {
            _inner.notifyUI();
            return _inner.ui;
        }
        _inner.ui = {
            logo: service.getLogo(),
            name: service.getName(),
            acronym: service.getAcronym(),
            link: navbarI.getLandingPage(),
            instantStartCall: service.hasInstantStart(),
            hasChangeProviderDisabled: service.hasChangeProviderDisabled()
        }
        return _inner.ui;
    }
    service.syncUi = ()=>{
        messagingI.postMessageToServiceWorker({
            target:'serviceProviderI',
            ui:_inner.ui
        })
    }
    service.selectById = (mre_call_provider_id)=>{
        if(!mre_call_provider_id) return
        const provider = service.getById(mre_call_provider_id);
        if(!provider) return
        serviceProviderI.updateServiceProvider(provider);
        serviceProviderI.onLoadProviderDetails(serviceProviderI.getServiceProviderId()).then(()=>{}, ()=>{});
    }
    service.exposeIForTesting = () => {
        window.serviceProviderI = service;
    }
    service.getSmartExpertiseProviderId = function() {
        return '3d1808e6-bf03-4261-b06c-e1ed8837f2b0';
    }
    _inner.DEBUG = true;
    service.isSmartExpertise = ()=> service.getServiceProviderId() === service.getSmartExpertiseProviderId() || _inner.DEBUG;
    return service;
}
export const serviceProviderI = ServiceProviderI();
