var NotificationI = function () {
    let _inner = {
        audio: null,
        sounds: {}
    }
    let service = {};
    service.AUDIO_KEYS = {
        NEW_MESSAGE: 'newMessage',
        JOINED_CALL: 'joinedCall',
        LEFT_CALL: 'leftCall',
        INCOMING_CALL: 'incomingCall',
        RAISE_HAND: 'raiseHand',
        WAITING_ROOM:'waitingRoom'
    }

    service.initAudio = function() {
        _inner.sounds[service.AUDIO_KEYS.NEW_MESSAGE] = '../assets/audio/message.mp3';
        _inner.sounds[service.AUDIO_KEYS.JOINED_CALL] = '../assets/audio/joined.mp3';
        _inner.sounds[service.AUDIO_KEYS.LEFT_CALL] = '../assets/audio/left.mp3';
        _inner.sounds[service.AUDIO_KEYS.INCOMING_CALL] = '../assets/audio/incoming-call.mp3';
        _inner.sounds[service.AUDIO_KEYS.RAISE_HAND] = '../assets/audio/raise-hand.wav';
        _inner.sounds[service.AUDIO_KEYS.WAITING_ROOM] = '../assets/audio/message.mp3';
    }
    service.playAudio = function(key) {
        if(!_inner.sounds[key])
            return;
        if(!_inner.audio)
            _inner.audio = new Audio();
        _inner.audio.src = _inner.sounds[key];
        _inner.audio.play().then(()=>{}, ()=>{});
    }
    service.stopAudio = function(key) {
        if(!_inner.audio || !_inner.sounds[key])
            return;
        _inner.audio.src = _inner.sounds[key];
        _inner.audio.pause();
        _inner.audio.currentTime = 0;
        setTimeout(()=>{
            if(_inner.audio)
                _inner.audio.src = '';
            _inner.audio = null;
        });
    }
    return service;
}

export const notificationI = NotificationI();