import { busI } from "./busI";
import { networkI } from "./networkI";
import { toastI } from "./toastI";
import { userI } from "./userI";
let AlertI = function () {
    let service = {};
    let _inner = {};
    _inner.connected = true;
    _inner.internetToastOpt = {};
    _inner.internetPopupReconnectOpt = {};
    _inner.onOnlineStatusChanged = () => {
        _inner.connected = navigator.onLine? true: false;
        if(service.isNetworkAvailable()) {
            service.removeInternetLostToast();
            service.removeInternetLostPopup();
        }
        clearTimeout(_inner.onNetworkStateChangeTimer);
        _inner.onNetworkStateChangeTimer = setTimeout(()=>{
            if(typeof navigator === 'object') {
                _inner.connected = navigator.onLine? true: false;
                service.removeInternetLostToast();
                if(service.isNetworkAvailable())
                    service.removeInternetLostPopup();
                _inner.internetToastOpt = {icon: '+_+', containerRef: {}};
                if(!service.isNetworkAvailable())
                    toastI.error('Network missing', 'infinit', _inner.internetToastOpt);
                else
                    toastI.success('Network is back.');
                busI.notifyEvent(busI.EVENTS.ON_NETWORK_STATE_CHANGE, navigator.onLine? true: false);
            }
        }, 1500);
    }

    service.init = () => {
        window.removeEventListener('online',  _inner.onOnlineStatusChanged);
        window.removeEventListener('offline', _inner.onOnlineStatusChanged);
        window.addEventListener('online',  _inner.onOnlineStatusChanged);
        window.addEventListener('offline', _inner.onOnlineStatusChanged);
    }
    service.isNetworkAvailable = () => {
        return _inner.connected? true: false;
    }
    service.onIsNetworkRecover = ()=>{
        return new Promise((resolve, reject)=>{
            networkI.get({url: userI.getDefaultAvatar()+'?'+Date.now(), isExternal: true})
            .then(()=>{
                resolve();
            }, ()=>{
                reject();
            });
        });
    }
    service.isReconnectingToastShowing = ()=>{
        return _inner.reconnectingToastOpt && _inner.reconnectingToastOpt.containerRef? true: false;
    }
    service.showReconnectingToast = ()=>{
        if(service.isReconnectingToastShowing())
            return;
        _inner.reconnectingToastOpt = {containerRef: {}};
        toastI.info('We are reconnecting...', 'infinit', _inner.reconnectingToastOpt);
    }
    service.removeReconnectingToast = ()=>{
        if(_inner.reconnectingToastOpt && _inner.reconnectingToastOpt.containerRef)
            toastI.closeToast(_inner.reconnectingToastOpt.containerRef);
        _inner.reconnectingToastOpt = null;
    }
    service.removeInternetLostToast = ()=>{
        if(_inner.internetToastOpt && _inner.internetToastOpt.containerRef)
            toastI.closeToast(_inner.internetToastOpt.containerRef);
    }
    service.removeInternetLostPopup = ()=>{
        if(_inner.internetPopupReconnectOpt && _inner.internetPopupReconnectOpt.container)
            toastI.closeToast(_inner.internetPopupReconnectOpt.container);
    }
    service.showInternetLostPopupOnCall = () => {
        service.removeInternetLostToast();
        service.removeInternetLostPopup();
        setTimeout(()=>{
            _inner.internetPopupReconnectOpt = {name: ' We seem to have network issues ?', status: 'Trying reconnecting...', container: {}};
            toastI.displayPopup(_inner.internetPopupReconnectOpt, 'infinit', {cancel: 'Leave Call'})
            .then(()=>{
            }, ()=>{
                busI.notifyEvent(busI.EVENTS.ON_REQUEST_LEAVE_CALL, 'leave');
            });
        }, 500);
    }
    return service;
}
export const alertI = AlertI();