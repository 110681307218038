import { callI } from "./callI";
import { roomI } from "./roomI";
import { serviceProviderI } from "./serviceProviderI";
import { userI } from "./userI";
import { utilI } from "./utilI";
var NavbarI = function () {
    let isDEBUG = false;

    let _inner = {
        isSideMenuOpen: false,
        ctrl: null,
        zone: null,
        lastActivePage: '',
        activeRoute: '',
        activePage: '',
        nextPage:'',
        isWebMode: true
    }

    let service = {};
    service.PATHS = {
        LOGIN: 'login',
        CALLS: 'calls',
        UPGRADE_PROFILE: '/upgrade-profile'
    }

    service.init = function(ctrl, zone) {
        _inner.ctrl = ctrl;
        _inner.zone = zone;
    }
    service.isCtrlAvailable = function() {
        return _inner.ctrl? true: false;
    }
    service.setOrigin = function() {
        _inner.origin = window.origin;
    }
    service.getOrigin = function() {
        return _inner.origin? _inner.origin: window.origin;
    }

    service.navForward = function(path) {
        if(_inner.zone) {
            _inner.zone.run(()=> {
                if(_inner.ctrl)
                _inner.ctrl.navigateForward(path);
            });
        } else {
            if(_inner.ctrl)
                _inner.ctrl.navigateForward(path);
        }
    }
    service.navBack = function(path) {
        if(_inner.zone) {
            if(_inner.ctrl)
                _inner.ctrl.navigateBack(path);
        } else {
            if(_inner.ctrl)
                _inner.ctrl.navigateBack(path);
        }
    }
    service.getDashboardRouteName = ()=>{
        let route = _inner.ctrl?.getDashboardRouteName?.()
        if(typeof route === "string"){
            if(!route.endsWith('/')){
                route = route+"/"
            }
        }
        return route
    }
    service.getStoredActivePage = function() {
        let path = utilI.getFromLocalStorage("tmu.WebApp.ActivePage");
        return path && typeof path==='string' && path.trim() && path!=='error' && path!=='enjoy'? path: '';
    }
    service.storeActivePage = function(path) {
        if(roomI.isOnActiveRoom()) return
        utilI.updateLocalStorage("tmu.WebApp.ActivePage", path);
    }
    service.goToLastActivePage = function(defaultPage) {
        let lastActivePage;
        if (_inner.lastActivePage) {
          lastActivePage = _inner.lastActivePage;
          delete _inner.lastActivePage;
        } else if (service.getStoredActivePage()) {
          lastActivePage = service.getStoredActivePage();
          if(utilI.getFromLocalStorage('tmu.resetPassword') != "true" && window.location.pathname.includes('change-password')
            || lastActivePage == 'call' && !callI.getActiveCallInStorage()) {
            lastActivePage = "calls";
          }
        } else {
            lastActivePage = userI.isGuest()? "upgrade-profile": defaultPage;
        }
        if (lastActivePage == "login") lastActivePage = defaultPage;
        if (lastActivePage == "register") {
            lastActivePage = defaultPage;
        }
        if (lastActivePage == "guest-login") lastActivePage = defaultPage;
        if (lastActivePage == "loading" || lastActivePage == "login" ) lastActivePage = "calls";
        service.navForward(lastActivePage);
    }
    service.setActiveRoute = function(path) {
        if(typeof path==='string')
            // _inner.activeRoute = path.replace('app/', '');
            _inner.activeRoute = path;
    }
    service.getActiveRoute = function() {
        return _inner.activeRoute;
    }
    service.isLoading = ()=> _inner.activeRoute === "loading"
    service.setLastActivePage = function(path) {
        _inner.lastActivePage = path;
    }
    service.getLastActivePage = function() {
        return _inner.lastActivePage;
    }
    service.setNextPage = function(path){
        utilI.updateLocalStorage('tmu.WebApp.NextPage',path);
        _inner.nextPage = path
    }
    service.getNextPage=function(){
        return _inner.nextPage || utilI.getFromLocalStorage('tmu.WebApp.NextPage');
    }
    service.clearNextPage = function(){
        utilI.removeFromLocalStorage('tmu.WebApp.NextPage');
        _inner.nextPage = ''
    }
    service.setActivePage = function(path) {
        _inner.activePage = path;
    }
    service.getActivePage = function() {
        return _inner.activePage;
    }
    service.setSettingsTab = function(tab) {
        if(!tab)
            utilI.removeFromLocalStorage('tmu.WebApp.SettingsTab');
        else
            utilI.updateLocalStorage('tmu.WebApp.SettingsTab', tab);
    }
    service.getSettingsTab = function() {
        let tab = utilI.getFromLocalStorage('tmu.WebApp.SettingsTab');
        return tab? tab: '';
    }
    service.isSideMenuOpen = () => {return _inner.isSideMenuOpen};

    var bindCloseFromOutside = (event)=>{
        let sideMenu = document.querySelector('#sidemenu-drawer');
        if(sideMenu){
            if (event.target == sideMenu){
                service.closeSideMenu();
            }
        }
    }
    service.toogleSideMenu = () => {
        let sideMenu = document.querySelector('#sidemenu-drawer');
        if(sideMenu){
            sideMenu.classList.toggle('active');
            if(sideMenu.classList.contains('active')) {
                sideMenu.removeEventListener("click", bindCloseFromOutside);
                sideMenu.addEventListener("click", bindCloseFromOutside);
                _inner.isSideMenuOpen = true;
            } else {
                _inner.isSideMenuOpen = false;
            }
        } else {
            if (isDEBUG) { console.log('%cError: %cCannot find side menu', 'color:red;font-weight:bold;', 'color:black;font-wight:regular;'); }
        }
    }

    service.closeSideMenu = () => {
        let sideMenu = document.querySelector('#sidemenu-drawer');
        if(sideMenu){
            sideMenu.classList.remove('active');
            if(sideMenu.classList.contains('active')) {
                _inner.isSideMenuOpen = true;
            } else {
                _inner.isSideMenuOpen = false;
            }
        } else {
            if (isDEBUG) { console.log('%cError: %cCannot find side menu', 'color:red;font-weight:bold;', 'color:black;font-wight:regular;'); }
        }
    }
    service.hasLandingPage = ()=> {
        return !serviceProviderI.isECO123() && !serviceProviderI.isMorshid() && !serviceProviderI.hasBotExperience()? true: false;
    }
    service.getLandingPage = ()=>{
        if(service.hasLandingPage())
            return 'home';
        return serviceProviderI.isECO123() || serviceProviderI.isMorshid() || serviceProviderI.hasBotExperience()? 'eco123': 'home';
    }
    service.setIsWebMode = function(val) {
        _inner.isWebMode = +val===1? true: false;
        _inner.notifyUI();
    }
    service.getIsWebMode = function() {
        return _inner.isWebMode? true: false;
    }
    service.getUIBind = ()=>{
        if(_inner.ui) {
            _inner.notifyUI();
            return _inner.ui;
        }
        _inner.ui = {
            isWebMode: _inner.isWebMode,
            toogleSideMenu: service.toogleSideMenu,
            isCalendarEnabled: false
        }
        return _inner.ui;
    }
    _inner.notifyUI=()=>{
        if(!_inner.ui)
            service.getUIBind();
        _inner.ui.isWebMode = _inner.isWebMode;
    }
    return service;
}

export const navbarI = NavbarI();